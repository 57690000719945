import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  cameraPlanningService: {
    index: async (camera_id) => {
      const result = await getMethod('/cameraPlannings/?camera_id=' + camera_id);
      return result.data;
    },

    store: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await postMethod('/cameraPlannings', newData);
      return result.data;
    },

    edit: async (id) => {
      const result = await getMethod('/cameraPlannings/' + id);
      return result.data;
    },

    update: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await putMethod('/cameraPlannings/' + data.id, newData);
      return result.data;
    },

    destroy: async (id) => {
      const result = await deleteMethod('/cameraPlannings/' + id);
      return result.data;
    },

  },
};