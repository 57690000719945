import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import terrainTimesService from '../../services/terrainTimesService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingTerrainTimes: ['data'],

  storeRequestTerrainTimes: ['payload'],
  storeResponseTerrainTimes: ['data'],

  editRequestTerrainTimes: ['id'],
  editResponseTerrainTimes: ['data'],

  updateRequestTerrainTimes: ['payload'],
  updateResponseTerrainTimes: ['data'],
});

// Initial state
const INITIAL_STATE = {
  terrainTimes: null,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
};

//store
function* store(action) {
  try {
    yield put(Creators.loadingTerrainTimes({ loading: true }));
    const data = yield call(terrainTimesService.terrainTimesService.store, action.payload);
    yield put(Creators.storeResponseTerrainTimes(data));
    yield put(Creators.loadingTerrainTimes({ loading: false }));
    yield history.push('/configuration/terrains');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingTerrainTimes({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingTerrainTimes({ editLoading: true }));
    const data = yield call(terrainTimesService.terrainTimesService.edit, action.payload);
    yield put(Creators.editResponseTerrainTimes(data));

    yield put(Creators.loadingTerrainTimes({ editLoading: false }));
  } catch (e) {
    yield put(Creators.editResponseTerrainTimes(null));
    yield put(Creators.loadingTerrainTimes({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingTerrainTimes({ loading: true }));
    const data = yield call(terrainTimesService.terrainTimesService.update, action.payload);
    yield put(Creators.updateResponseTerrainTimes(data));
    yield put(Creators.loadingTerrainTimes({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/terrains');
  } catch (e) {
    yield put(
      Creators.loadingTerrainTimes({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.STORE_REQUEST_TERRAIN_TIMES, store);
  yield takeLatest(Types.EDIT_REQUEST_TERRAIN_TIMES, edit);
  yield takeLatest(Types.UPDATE_REQUEST_TERRAIN_TIMES, update);
}

// Reducer handlers
//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    terrainTimes: { ...action.data.data },
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const terrainTimesReducer = createReducer(INITIAL_STATE, {
  [Types.STORE_RESPONSE_TERRAIN_TIMES]: store_response,
  [Types.EDIT_RESPONSE_TERRAIN_TIMES]: edit_response,
  [Types.UPDATE_RESPONSE_TERRAIN_TIMES]: update_response,
  [Types.LOADING_TERRAIN_TIMES]: loading,
});
