import React, { useEffect } from 'react';
import "./SideTansition.css";
import OverlayComponent from '../OverlayComponent/OverlayComponent';



const handlePosition =(position,size)=>{
    switch(position.toUpperCase()){
      case "LEFT":
        return ({
          width:size,
          position:'fixed',
          minHeight:"100vh",
          left:0
        });
      case "RIGHT":
        return ({
          width:size,
          position:'fixed',
          minHeight:"100vh",
          right:0
        });

      case "TOP":
        return ({
          minWidth:"100vw",
          position:'fixed',
          height:size,
          top:0
        });

      case "BOTTOM":
        return ({
          minWidth:"100vw",
          position:'fixed',
          height:size,
          bottom:0
        });

      default:
        return ({
          width:size,
          position:'fixed',
          minHeight:"100vh",
          right:0
        });
    }
};



const getPosition=(position)=>{

      switch(position.toUpperCase()){
        case "LEFT": return "left" ;
        case "TOP": return "top" ;
        case "BOTTOM": return "bottom" ;
        default: return "right" ;
      }
};


const SideTransition=(props)=>{

    useEffect(()=>{
      if(props.show)
        document.body.classList.add("no-overflow");
      else
        document.body.classList.remove("no-overflow");

    },[props.show]);

  return (
        <div className={ " "+(props.show ?  getPosition(props.position)+"-show-transition" : getPosition(props.position)+"-hide-transition")}>
          {props.show &&
          <div className={"side-transition-overlay" }/>}

            <OverlayComponent onClose={props.onClose}>



                  <div className={"side-transition-container "+props.className }
                       style={handlePosition(props.position,props.size)}>
                        <div className={"side-transition-main"}>
                          {props.show && props.children}
                        </div>
                  </div>

            </OverlayComponent>

        </div>





  );


};


SideTransition.defaultProps={
  show:false,
  className:"",
  position:"right",
  size:300,
  onClose:()=>{}
};









export default SideTransition;


