import { getMethod, postMethod } from '../helpers';

export default {
  authService: {
    login: (data) => {
      return postMethod('/login', data);
    },
    logout: async (data) => {
      const result = await getMethod('/logout');
      //localStorage.setItem("token","");

      return result.data;
    },
    me: () => {
      return getMethod('/me');
    },
    refresh: () => {
      return getMethod('/refresh');
    },
    change: (data) => {
      return postMethod('/resetPassword', data);
    },
    recover: (data) => {
      return postMethod('/sendPasswordResetLink', data);
    },
  },
};
