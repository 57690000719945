import React, { useCallback, useEffect, useRef, useState } from "react";
import "./PlanningStats.css";
import Image from "../ImageComponent/Image";
import { imgUrl } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "../../store/ducks/reporting";
import { roundOnlyIfAvailable } from "../../helpers/formatting";
import { conclusionColor } from "../../helpers/reservationFormatting";
import moment from "moment";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const isWhite = (color) => {
  if (
    color &&
    (color.toUpperCase() === "#FFF" || color.toUpperCase() === "#FFFFFF")
  )
    return true;

  return false;
};

const PlanningStats = (props) => {
  const refScroll = useRef(null);

  const [t] = useTranslation();

  const [show, setShow] = useState(props.isVisible);
  const dispatch = useDispatch();
  const report = useSelector((it) => it.reporting.report);

  const getStats = useCallback(
    (date) =>
      dispatch(
        Creators.getReportingRequest({
          search_date: date,
          show_next: true,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    if ((props.date || props.updated) && !props.isVisible) {
      getStats(moment(props.date).format("YYYY-MM-DD"));
    }
  }, [props.date, props.updated, props.isVisible]);

  const renderTotal = () => {
    return (
      <div className="ca-main ca-total" style={{ background: "#8C97B2" }}>
        {
          <span className={"ca-icon-container"}>
            <span className={"ca-activity-name"}>{t("total")}</span>
          </span>
        }
        <div className={"ca-block-container"}>
          <span className={"ca-block-row"}>
            <span className="ca-block-title">{report?.total}</span>
            <span className="ca-block-title-sub">
              {parseInt(report?.total) > 1 ? t("resas") : t("resa")}
            </span>
          </span>
          <span className={"ca-block-row"}>
            <span className="ca-block-value">
              {roundOnlyIfAvailable(report?.ca_total.toString())}
            </span>
            <span className="ca-block-value-sub">
              {localStorage.getItem("currency")}
            </span>
          </span>
        </div>
      </div>
    );
  };
  const renderNotVenu = () => {
    return (
      <div
        className="ca-main ca-total"
        style={{ background: conclusionColor("Pas Venu") }}
      >
        {
          <span className={"ca-icon-container"}>
            <span className={"ca-activity-name"}>{t("not-come")}</span>
          </span>
        }
        <div className={"ca-block-container"}>
          <span className={"ca-block-row"}>
            <span className="ca-block-title">{report?.total_not_come}</span>
            <span className="ca-block-title-sub">
              {parseInt(report?.total) > 1 ? t("resas") : t("resa")}
            </span>
          </span>
          <span className={"ca-block-row"}>
            <span className="ca-block-value">
              {roundOnlyIfAvailable(report?.ca_total_not_come.toString())}
            </span>
            <span className="ca-block-value-sub">
              {localStorage.getItem("currency")}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderStat = (it) => {
    let total = it.reservations.reduce(function (previousValue, currentValue) {
      return previousValue + parseFloat(currentValue.ca);
    }, 0);

    let qte = it.reservations.reduce(function (previousValue, currentValue) {
      return previousValue + parseFloat(currentValue.total);
    }, 0);
    return (
      <div
        className="ca-main"
        key={"activity-" + it.id}
        style={
          isWhite(it.activity_type?.color)
            ? {
                background: it.activity_type?.color,
                color: "#333",
              }
            : { background: it.activity_type?.color }
        }
      >
        {
          <span className={"ca-icon-container"}>
            {it.icon && (
              <span
                className={"ca-icon-b"}
                style={
                  isWhite(it.activity_type?.color)
                    ? {
                        background: "#33333399",
                      }
                    : {}
                }
              >
                <Image
                  type={"item"}
                  src={
                    imgUrl +
                    "/activities/" +
                    localStorage.getItem("complex_id") +
                    "/" +
                    it.icon
                  }
                />
              </span>
            )}
            <span className={"ca-activity-name"}>{it.name}</span>
          </span>
        }
        <div className={"ca-block-container"}>
          <span className={"ca-block-row"}>
            <span className="ca-block-title">{qte}</span>
            <span className="ca-block-title-sub">
              {qte > 1 ? t("resas") : t("resa")}
            </span>
          </span>
          <span className={"ca-block-row"}>
            <span className="ca-block-value">
              {roundOnlyIfAvailable(total ? total.toString() : 0)}
            </span>
            <span className="ca-block-value-sub">
              {localStorage.getItem("currency")}
            </span>
          </span>
        </div>
      </div>
    );
  };

  if (props.isVisible || !report) return <span />;

  return (
    <div id={"stats-element"} className={"stats-container"}>
      {/*<div className={'stats-row'}>*/}
      {/*  <h3>Stats</h3>*/}
      {/*  <Button*/}
      {/*    className={"round-min"}*/}
      {/*    onClick={() => setShow(!show)}*/}
      {/*    icon={'caret ' + (!show ? 'down' : 'up')}/>*/}
      {/*</div>*/}

      <div className={"stats-main-container"}>
        <div className={"stats-row-container"}>
          {renderTotal()}
          {!!report?.total_not_come && renderNotVenu()}
        </div>
        {report.hasOwnProperty("ca") &&
          report.ca.filter((it) => it.reservations.length > 0).length > 0 && (
            <div className={"stats-icon"}>
              <Button
                circular
                icon={"angle left"}
                onClick={() => {
                  if (refScroll.current) {
                    refScroll.current.scrollLeft =
                      refScroll.current.scrollLeft -
                      refScroll.current.clientWidth /
                        report.ca.filter((it) => it.reservations.length > 0)
                          .length;
                  }
                }}
              />
              <div
                ref={refScroll}
                className={"stats-row-container stats-with-scroll"}
              >
                {report.hasOwnProperty("ca") &&
                  report.ca
                    .filter((it) => it.reservations.length > 0)
                    .map((it) => renderStat(it))}
              </div>
              <Button
                circular
                icon={"angle right"}
                onClick={() => {
                  if (refScroll.current) {
                    let p = refScroll.current.scrollTop;
                    let pos =
                      refScroll.current.scrollWidth -
                      refScroll.current.clientWidth;
                    if (p / pos < 1)
                      refScroll.current.scrollLeft =
                        refScroll.current.scrollLeft +
                        refScroll.current.clientWidth /
                          report.ca.filter((it) => it.reservations.length > 0)
                            .length;
                  }
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

PlanningStats.defaultProps = {
  isVisible: false,
  updated: false,
  selected: null,
  date: null,
};

export default PlanningStats;
