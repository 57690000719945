import React from 'react';
import { Route } from 'react-router-dom';
import LazyLoading from '../LazyLoading/LazyLoading';

export default (props) => {
  return (
    <Route
      path={props.path}
      exact={true}
      render={(p) => {
        return (
          <LazyLoading
            fn={() => import('@/pages' + props.import)}
            customProps={{ ...props, ...p }}
          />
        );
      }}
    />
  );
};
