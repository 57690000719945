import moment from "moment";
import localization, * as fr from "moment/locale/fr";
import {
  cumulAmountHTFromArticles,
  cumulPayedAmountFromPaymentTypes,
  cumulVatAmountFromArticles,
  getArticleDiscount,
  getArticleTotalDiscount,
  getNextTicketId,
  getParticipantDiscount,
} from "./ticket";
import { requestNoteFormatting } from "./caisse/noteFormatting";
import { valeur } from "../pages/CaissePage/CaissePage";
import {
  reservationInformationState,
  reservationRequest,
  reservationResponse,
} from "./reservationFormatting";
import { customRound, nullString, nullStringNumber } from "./formatting";
import { getHT } from "./caisse/tvaCalcul";
import { exactDivision } from "./operation";

export const caisseResponseToTicket = async (response) => {
  try {
    if (!response.article) return null;

    let nb = response.reservation.match.participants_nb;
    let selectedArticleDetail =
      response.article.details.length > 0
        ? response.article.details.filter((it) => it.participant_nb === nb)
        : null;

    // no article detail was found
    if (
      response.reservation.is_leisure &&
      (selectedArticleDetail === null || selectedArticleDetail.length === 0)
    )
      return null;
    if (selectedArticleDetail === null || selectedArticleDetail.length === 0) {
      let defaultDetail = response.article.details.filter(
        (it) => it.is_default
      );
      let defaultArticle = {};

      if (defaultDetail.length > 0) defaultArticle = defaultDetail[0];
      else
        defaultArticle = {
          id: null,
          price_per_participant: response.article.price_per_participant,
          price_per_participant_ht: response.article.price_per_participant_ht,
          participant_nb: response.article.default_participants_nb,
          total_price: response.article.total_price,
          amount_ht: response.article.amount_ht,
          vat_amount: response.article.vat_amount,
        };
      selectedArticleDetail[0] = defaultArticle;
    }
    selectedArticleDetail[0].vat_id = response.article.vat_id;

    //first item get excess of division (exact division)
    let excess = exactDivision(
      selectedArticleDetail[0].total_price,
      selectedArticleDetail[0].price_per_participant,
      selectedArticleDetail[0].participant_nb
    );

    let excess_ht = exactDivision(
      selectedArticleDetail[0].amount_ht,
      selectedArticleDetail[0].price_per_participant_ht,
      selectedArticleDetail[0].participant_nb
    );

    let participants = [];
    let sizes = selectedArticleDetail[0].participant_nb + 1;
    for (let index = 1; index < sizes; index++) {
      let participant = {};
      let participant_amount =
        index === 1
          ? parseFloat(selectedArticleDetail[0].price_per_participant) + excess
          : parseFloat(selectedArticleDetail[0].price_per_participant);
      let participant_amount_ht =
        index === 1
          ? parseFloat(selectedArticleDetail[0].price_per_participant_ht) +
            excess_ht
          : parseFloat(selectedArticleDetail[0].price_per_participant_ht);

      participant["user_id"] = "";
      participant["user_index"] =
        response.article.id.toString() + "-" + index.toString();
      participant["article_id"] = response.article.id;
      participant["amount"] = parseFloat(participant_amount).toFixed(2);
      participant["payed_amount"] = 0;
      participant["amount_ht"] = parseFloat(participant_amount_ht).toFixed(3);
      participant["vat_amount"] = (
        parseFloat(participant_amount) - parseFloat(participant_amount_ht)
      ).toFixed(3);
      participant["vat_id"] = selectedArticleDetail[0].vat_id;
      participant["status"] = null;
      participant["subscriber_id"] = null;
      participant["note_index"] = null;
      participants.push(participant);
    }

    for (let i = 0; i < response.reservation.participants.length; i++) {
      let selectedParticipant = response.reservation.participants[i];
      if (i < participants.length) {
        participants[i].user_id = selectedParticipant.id;
        participants[i].firstName = selectedParticipant.firstName;
        participants[i].lastName = selectedParticipant.lastName;
      }
    }

    let d = { ...selectedArticleDetail[0] };
    delete d.is_default;
    delete d.id;

    let article = {
      ...response.article,
      ...d,
      default_participants_nb: selectedArticleDetail[0].participant_nb,
      article_detail_id: selectedArticleDetail[0].id,
      article_nb: 1,
      payed_amount: 0,
      status: null,
    };

    let number = await getNextTicketId();

    return {
      owner_id: response.owner_id,
      reservation_id: response.reservation.id,
      user_id: response.reservation.user_id,
      amount: selectedArticleDetail[0].total_price,
      payed_amount: 0,
      // participants_nb: response.default_participants_nb,
      participants_nb: participants.length,
      articles_nb: 1,
      date: moment(new Date()).format("DD-MM-YYYY"),
      hour: moment(new Date()).locale("fr", localization).format("LT"),
      number,
      complex_id: localStorage.getItem("complex_id"),
      discount: 0,
      status: 1,
      articles: [article],
      participants,
      payments: {
        types: [],
        articles: [],
        participants: [],
      },
      discountTypes: {
        discounts: [],
        articles: [],
        participants: [],
      },
      amount_ht: parseFloat(selectedArticleDetail[0].amount_ht).toFixed(3),
      vat_amount: parseFloat(selectedArticleDetail[0].vat_amount).toFixed(3),
    };
  } catch (e) {
    console.log("error from caisseResponseToTicket", e);
  }
};

export const filterDot = (data) => {
  let oldDots = data.toString().split(".");
  let dots = oldDots.filter((it) => it !== "");

  let result = [
    oldDots[0] === "" ? "0." : "",
    dots[0],
    oldDots[0] !== "" && oldDots.length > 1 ? "." : "",
  ].concat(dots.splice(1));
  return result.join("");
};

export const useAdvanceInReservation = (currentTicket, payments) => {
  let paymentTypes = currentTicket.payments;

  if (paymentTypes.hasOwnProperty("types")) {
    paymentTypes.types = [...paymentTypes.types, ...payments.types];
    paymentTypes.participants = [
      ...paymentTypes.participants,
      ...payments.participants,
    ];
    paymentTypes.articles = [...paymentTypes.articles, ...payments.articles];
  }
  //article status
  currentTicket.articles[currentTicket.articles.length - 1].payed_amount =
    payments.types.length > 0
      ? payments.types[payments.types.length - 1].amount
      : 0;
  currentTicket.articles[currentTicket.articles.length - 1].status =
    parseFloat(
      currentTicket.articles[currentTicket.articles.length - 1].payed_amount
    ) <
    parseFloat(
      currentTicket.articles[currentTicket.articles.length - 1].total_price
    )
      ? "partial"
      : "payed";
  for (let i = 0; i < payments.participants.length; i++) {
    //let n = ((currentTicket.participants.length) - (payments.participants.length)) + i;
    currentTicket.participants[i].payed_amount =
      payments.participants[i].amount;
    currentTicket.participants[i].status =
      parseFloat(currentTicket.participants[i].amount) >
      parseFloat(currentTicket.participants[i].payed_amount)
        ? "partial"
        : "payed";
  }

  currentTicket["payed_amount"] = cumulPayedAmountFromPaymentTypes(
    currentTicket
  );

  return { ...currentTicket, payments: paymentTypes };
};

export const TicketRequest = (data) => {
  let res_price = 0;
  let new_data = Object.assign({}, data);
  delete new_data.number;
  let articles = [];
  let paymentsArticles = [];
  let discountsArticles = [];
  let newDiscountArticles = [];
  let paymentParticipants = [];
  let discountParticipants = [];
  if (
    new_data.hasOwnProperty("articles") &&
    Object.entries(new_data.articles).length
  ) {
    calculVatForTicket(new_data);
    articles = new_data.articles.map((item, index) => {
      return {
        article_id: item.id.toString().split(valeur)[0],
        amount: (
          parseFloat(item.total_price) * parseInt(item.article_nb)
        ).toFixed(2),
        payed_amount: parseFloat(item.payed_amount).toFixed(2),
        article_nb: parseInt(item.article_nb),
        status: item.status,
        amount_ht: item.amount_ht,
        vat_amount: item.vat_amount,
        vat_id: item.vat_id,
        article_detail_id:
          item.article_detail_id === undefined ? "" : item.article_detail_id,
        note_index: item.note_index,
        is_activity: item.parent.is_activity,
      };
    });

    //filter duplicate article
    let filterArticles = [];
    for (let index = 0; index < articles.length; index++) {
      let article_id = articles[index].article_id.toString().split(valeur)[0];
      let note_index = articles[index].note_index;
      let exist = [];
      let ind = 0;
      filterArticles.forEach((it, i) => {
        if (
          it.article_id.toString().split(valeur)[0] === article_id.toString() &&
          it.note_index === note_index
        ) {
          ind = i;
          exist.push(it);
        }
      });
      let article = null;
      if (exist.length) {
        article = articles[index];
        article.article_id = article.article_id.toString();
        article.amount = (
          parseFloat(article.amount) + parseFloat(filterArticles[ind].amount)
        ).toFixed(2);
        article.article_nb =
          parseInt(article.article_nb) +
          parseInt(filterArticles[ind].article_nb);
        article.payed_amount = (
          parseFloat(article.payed_amount) +
          parseFloat(filterArticles[ind].payed_amount)
        ).toFixed(2);

        article.amount_ht = (
          parseFloat(article.amount_ht) +
          parseFloat(filterArticles[ind].amount_ht)
        ).toFixed(3);
        article.vat_amount = (
          parseFloat(article.vat_amount) +
          parseFloat(filterArticles[ind].vat_amount)
        ).toFixed(3);

        if (!filterArticles[ind].status && !article.status) {
          article.status = null;
        } else if (
          filterArticles[ind].status === article.status &&
          article.status === "payed"
        ) {
          article.status = "payed";
        } else {
          article.status = "partial";
        }

        filterArticles[ind] = article;
      } else {
        filterArticles.push(articles[index]);
      }
    }
    filterArticles.forEach(
      (it) => (it.article_id = it.article_id.toString().split(valeur)[0])
    );
    new_data.articles = filterArticles;

    new_data.amount_ht = cumulAmountHTFromArticles(new_data);
    new_data.vat_amount = cumulVatAmountFromArticles(new_data);

    // reset article_id in payments
    let paymentArticles = new_data.payments.articles.map((it) => ({
      ...it,
      article_id: it.article_id.toString().split(valeur)[0],
    }));
    for (let index = 0; index < paymentArticles.length; index++) {
      let article_id = paymentArticles[index].article_id
        .toString()
        .split(valeur)[0];
      let payment_id = paymentArticles[index].payment_type_id;
      let exist = paymentsArticles.filter(
        (it) =>
          it.article_id.toString().split(valeur)[0] === article_id &&
          it.payment_type_id.toString() === payment_id.toString() &&
          (!!paymentArticles[index].credit_id
            ? it.credit_id === paymentArticles[index].credit_id
            : true) &&
          (!!paymentArticles[index].subscriber_id
            ? it.subscriber_id === paymentArticles[index].subscriber_id
            : true) &&
          (!!paymentArticles[index].subscriber_detail_id
            ? it.subscriber_detail_id ===
              paymentArticles[index].subscriber_detail_id
            : true) &&
          (!!paymentArticles[index].credit_user_id
            ? it.credit_user_id === paymentArticles[index].credit_user_id
            : true) &&
          it.note_index === paymentArticles[index].note_index &&
          (!!paymentArticles[index].credit_account_id
            ? it.credit_account_id === paymentArticles[index].credit_account_id
            : true)
      );
      let article = null;
      if (exist.length) {
        let articlesFilter = new_data.payments.articles.filter(
          (it) =>
            it.article_id.toString().split(valeur)[0] ===
              article_id.toString() &&
            it.payment_type_id.toString() === payment_id.toString() &&
            (!!paymentArticles[index].credit_id
              ? it.credit_id === paymentArticles[index].credit_id
              : true) &&
            (!!paymentArticles[index].subscriber_id
              ? it.subscriber_id === paymentArticles[index].subscriber_id
              : true) &&
            (!!paymentArticles[index].subscriber_detail_id
              ? it.subscriber_detail_id ===
                paymentArticles[index].subscriber_detail_id
              : true) &&
            (!!paymentArticles[index].credit_user_id
              ? it.credit_user_id === paymentArticles[index].credit_user_id
              : true) &&
            it.note_index === paymentArticles[index].note_index &&
            (!!paymentArticles[index].credit_account_id
              ? it.credit_account_id ===
                paymentArticles[index].credit_account_id
              : true)
        );
        article = articlesFilter[0];
        article.article_id = article.article_id.toString().split(valeur)[0];
        for (let j = 1; j < articlesFilter.length; j++) {
          article.amount = (
            parseFloat(article.amount) + parseFloat(articlesFilter[j].amount)
          ).toFixed(2);

          article.amount_ht = (
            parseFloat(article.amount_ht) +
            parseFloat(articlesFilter[j].amount_ht)
          ).toFixed(3);
          article.vat_amount = (
            parseFloat(article.vat_amount) +
            parseFloat(articlesFilter[j].vat_amount)
          ).toFixed(3);
        }
        let ind = 0;
        paymentsArticles.forEach((it, i) => {
          if (
            it.article_id.toString().split(valeur)[0] ===
              article_id.toString() &&
            it.payment_type_id.toString() === payment_id.toString() &&
            (!!paymentArticles[index].credit_id
              ? it.credit_id === paymentArticles[index].credit_id
              : true) &&
            (!!paymentArticles[index].subscriber_id
              ? it.subscriber_id === paymentArticles[index].subscriber_id
              : true) &&
            (!!paymentArticles[index].subscriber_detail_id
              ? it.subscriber_detail_id ===
                paymentArticles[index].subscriber_detail_id
              : true) &&
            (!!paymentArticles[index].credit_user_id
              ? it.credit_user_id === paymentArticles[index].credit_user_id
              : true) &&
            it.note_index === paymentArticles[index].note_index &&
            (!!paymentArticles[index].credit_account_id
              ? it.credit_account_id ===
                paymentArticles[index].credit_account_id
              : true)
          ) {
            ind = i;
          }
        });
        paymentsArticles[ind] = article;
      } else {
        paymentsArticles.push(paymentArticles[index]);
      }
    }
    paymentParticipants = new_data.payments.participants.map((it) => ({
      ...it,
      article_id: it.article_id.toString().split(valeur)[0],
    }));

    // Discount
    let discountArticles = new_data.discountTypes.articles.map((it) => ({
      ...it,
      article_id: it.article_id.toString().split(valeur)[0],
    }));
    if (discountArticles.length)
      for (let index = 0; index < discountArticles.length; index++) {
        let article_id = discountArticles[index].article_id
          .toString()
          .split(valeur)[0];
        let discount_id = discountArticles[index].discount_id;
        let exist = discountsArticles.filter(
          (it) =>
            it.article_id.toString().split(valeur)[0] ===
              article_id.toString() &&
            it.note_index === discountArticles[index].note_index &&
            it.discount_id.toString() === discount_id.toString()
        );
        let article = null;
        if (exist.length) {
          let articlesFilter = new_data.discountTypes.articles.filter(
            (it) =>
              it.article_id.toString().split(valeur)[0] ===
                article_id.toString() &&
              it.note_index === discountArticles[index].note_index &&
              it.discount_id.toString() === discount_id.toString()
          );
          article = articlesFilter[0];
          article.article_id = article.article_id.toString().split(valeur)[0];
          for (let j = 1; j < articlesFilter.length; j++) {
            article.amount = (
              parseFloat(article.amount) + parseFloat(articlesFilter[j].amount)
            ).toFixed(2);
          }
          newDiscountArticles.push(article);
        } else {
          newDiscountArticles.push(discountArticles[index]);
        }
      }

    discountParticipants = new_data.discountTypes.participants.map((it) => ({
      ...it,
      article_id: it.article_id.toString().split(valeur)[0],
    }));

    if (new_data.reservation_id && new_data.article_reservation) {
      let article = new_data.articles.filter(
        (itm) =>
          itm.article_id.toString() === new_data.article_reservation.toString()
      );
      if (article[0] && article[0].status === "payed") {
        res_price = parseFloat(article[0].amount).toFixed(2);
      }
    }
  }

  delete new_data.created_reservation;
  delete new_data.noteInProgress;

  let response = {
    ...new_data,
    checkout_session_id: localStorage.getItem("session_id"),
    payments: {
      ...new_data.payments,
      articles: paymentsArticles,
      participants: paymentParticipants,
    },
    discountTypes: {
      ...new_data.discountTypes,
      articles: newDiscountArticles,
      participants: discountParticipants,
    },
    article_reservation_id: new_data.article_reservation,
    res_price,
    notes: new_data.notes,
  };

  if (new_data.hasOwnProperty("notes") && new_data.notes.length > 0) {
    let cloneResponse = JSON.parse(JSON.stringify(response)); //copy of table without reference (deep copy)
    let notes = requestNoteFormatting(cloneResponse);
    response.notes = notes;
  } else {
    response.notes = [];
  }
  if (response.articles) {
    response.articles.forEach((article) => {
      delete article.is_activity;
    });
  }

  // handle updating reservation participants
  let reservationCreated = null;
  if (data.hasOwnProperty("created_reservation")) {
    let reservation_request = reservationResponse(
      reservationInformationState(),
      data.created_reservation
    );
    reservationCreated = reservationRequest(reservation_request);
    response.created_reservation = reservationCreated;
  }

  return response;
};

export const addDiscountToTicket = (currentTicket, reservation) => {
  let newCurrentTicket = { ...currentTicket };
  let articles = [...newCurrentTicket.discountTypes.articles];
  let discounts = [...newCurrentTicket.discountTypes.discounts];

  // check if participants have discount
  if (!reservation) return newCurrentTicket;

  let discountsParticipants = [...newCurrentTicket.discountTypes.participants];

  for (let i = 0; i < reservation.participants.length; i++) {
    // check if participant exist in discountsParticipants
    let isMatch = discountsParticipants.filter(
      (it) =>
        it.user_id === reservation.participants[i].id &&
        currentTicket.articles[0].id === it.article_id
    );

    if (!isMatch.length && reservation.participants[i].pivot.discount) {
      let data = {
        user_id: reservation.participants[i].id,
        user_index: currentTicket.articles[0].id + "-" + (i + 1),
        article_id: currentTicket.articles[0].id.toString(),
        amount: reservation.participants[i].pivot.discount.value
          ? customRound(
              getPercentage(
                parseFloat(currentTicket.participants[i].amount) -
                  parseFloat(currentTicket.participants[i].payed_amount),
                reservation.participants[i].pivot.discount.value
              )
            )
          : customRound(reservation.participants[i].pivot.discount.amount),
        percentage: reservation.participants[i].pivot.discount.value,
        discount_id: reservation.participants[i].pivot.discount.id,
      };
      discountsParticipants.push(data);
      addDiscountParticipantToArticle(data, articles);
      addDiscountParticipantToDiscount(data, discounts);
    } else if (reservation.participants[i].pivot.discount && isMatch.length) {
      for (let j = 0; j < isMatch.length; j++) {
        if (
          isMatch[j].discount_id &&
          reservation.participants[i].pivot.discount &&
          isMatch[j].discount_id.toString() !==
            reservation.participants[i].pivot.discount.id.toString()
        ) {
          discountsParticipants = discountsParticipants.filter(
            (it) =>
              it.discount_id.toString() !== isMatch[j].discount_id.toString() &&
              it.user_id.toString() !== isMatch[j].user_id.toString()
          );
          removeDiscountParticipantToArticle(isMatch[j], articles);
          removeDiscountParticipantToDiscount(isMatch[j], discounts);
        }
      }
    }
  }

  let discountTypes = {
    articles,
    discounts,
    participants: discountsParticipants,
  };

  let totalDiscount = articles.reduce(
    (acc, art) => parseFloat(acc) + parseFloat(art.amount),
    0
  );

  return { ...newCurrentTicket, discountTypes, discount: totalDiscount };
};

const addDiscountParticipantToArticle = (part, articles) => {
  let found = false;
  for (let i = 0; i < articles.length; i++) {
    if (
      part.article_id === articles[i].article_id &&
      part.discount_id === articles[i].discount_id
    ) {
      found = true;
      let amount = parseFloat(articles[i].amount) + parseFloat(part.amount);
      articles[i].amount = amount.toFixed(2);
    }
  }
  if (!found)
    articles.push({
      article_id: part.article_id ? part.article_id.toString() : "",
      amount: part.amount,
      percentage: part.percentage,
      discount_id: part.discount_id,
    });
};

const addDiscountParticipantToDiscount = (part, discounts) => {
  let found = false;
  for (let i = 0; i < discounts.length; i++) {
    if (part.discount_id === discounts[i].discount_id) {
      found = true;
      let amount = parseFloat(discounts[i].amount) + parseFloat(part.amount);
      discounts[i].amount = amount.toFixed(2);
    }
  }
  if (!found)
    discounts.push({
      amount: part.amount,
      percentage: part.percentage,
      discount_id: part.discount_id,
    });

  // add to discounts
};

const removeDiscountParticipantToArticle = (part, articles) => {
  let found = false;
  for (let i = 0; i < articles.length; i++) {
    if (
      part.article_id === articles[i].article_id &&
      part.discount_id === articles[i].discount_id
    ) {
      found = true;
      let amount = parseFloat(articles[i].amount) - parseFloat(part.amount);
      articles[i].amount = amount.toFixed(2);
    }
  }
  // if (!found)
  //   articles.push({
  //     article_id: part.article_id ? part.article_id.toString() : '',
  //     amount: part.amount,
  //     percentage: part.percentage,
  //     discount_id: part.discount_id,
  //   });
};

const removeDiscountParticipantToDiscount = (part, discounts) => {
  let found = false;
  for (let i = 0; i < discounts.length; i++) {
    if (part.discount_id === discounts[i].discount_id) {
      found = true;
      let amount = parseFloat(discounts[i].amount) - parseFloat(part.amount);
      discounts[i].amount = amount.toFixed(2);
    }
  }
  // if (!found)
  //   articles.push({
  //     article_id: part.article_id ? part.article_id.toString() : '',
  //     amount: part.amount,
  //     percentage: part.percentage,
  //     discount_id: part.discount_id,
  //   });
};

const getPercentage = (amount, percentage) => {
  return (
    parseFloat(amount) *
    (parseFloat(percentage) / parseFloat(100))
  ).toFixed(2);
};

export const getActivityAmount = (ticket, article_id, note_index) => {
  let total = 0;

  total = ticket.participants.reduce((prevValue, currentValue) => {
    if (
      currentValue.article_id.toString() === article_id.toString() &&
      currentValue.note_index === note_index
    )
      prevValue += parseFloat(currentValue.amount);
    return prevValue;
  }, 0);

  return parseFloat(total.toFixed(2));
};

export const getActivityVat = (ticket, article_id, note_index) => {
  let total = { amount_ht: 0, vat_amount: 0 };
  total = ticket.participants.reduce(
    (prevValue, currentValue) => {
      if (
        currentValue.article_id === article_id &&
        currentValue.note_index === note_index
      )
        prevValue.amount_ht += parseFloat(currentValue.amount_ht);
      prevValue.vat_amount += parseFloat(currentValue.vat_amount);

      return prevValue;
    },
    { amount_ht: 0, vat_amount: 0 }
  );

  total.vat_amount = total.vat_amount.toFixed(3);
  total.amount_ht = total.amount_ht.toFixed(3);

  return total;
};

export const getTotalAmountForNote = (ticket, note_index) => {
  let total = 0;

  total = ticket.articles.reduce((prevValue, currentValue) => {
    if (currentValue.note_index === note_index)
      prevValue +=
        parseFloat(currentValue.total_price) *
        parseInt(currentValue.article_nb);
    else if (currentValue.parent?.is_activity)
      prevValue += getActivityAmount(ticket, currentValue.id, note_index);

    return prevValue;
  }, 0);

  return total;
};

/**
 *
 * @param ticket
 */
export const calculVatForTicket = (ticket) => {
  ticket.articles.forEach((it) => {
    let vat_value = 1;
    // in cas article has no tva
    if (it.vat) vat_value = it.vat.value;
    // price total
    let discount =
      it.parent && it.parent.is_activity === 1
        ? getArticleTotalDiscount(ticket, it.id)
        : getArticleDiscount(ticket, it.id, it.note_index);

    let total = parseFloat(it.total_price) * parseInt(it.article_nb) - discount;

    it.amount_ht = getHT(total, vat_value);
    it.vat_amount = (total - it.amount_ht).toFixed(3);

    // if article is activity
    if (it.parent && it.parent.is_activity === 1) {
      ticket.participants.forEach((part) => {
        if (part.article_id === it.id) {
          let participant_total =
            parseFloat(part.amount) -
            parseFloat(getParticipantDiscount(ticket, part.user_index));
          let amount_ht = getHT(participant_total, vat_value);
          part.amount_ht = amount_ht;
          part.vat_amount = (
            parseFloat(participant_total) - parseFloat(amount_ht)
          ).toFixed(3);
        }
      });
    }
  });
};

/**
 * return participant firstName + lastNam from either the user object or direct
 * @param participant
 * @returns {string|null}
 */
export const getParticipantName = (participant) => {
  if (participant.user_id) {
    if (participant.user) {
      return (
        nullString(participant.user.firstName) +
        " " +
        nullString(participant.user.lastName)
      );
    } else {
      return (
        nullString(participant.firstName) +
        " " +
        nullString(participant.lastName)
      );
    }
  }
  return null;
};

/***
 *
 * @param ticket
 * @param article_id
 * @param note_index
 * @param isActivity
 * @returns status : payed/null/partial
 */
export const getStatusOfArticleByNote = (
  ticket,
  article_id,
  note_index,
  isActivity
) => {
  let status = undefined;
  if (!ticket) return null;

  if (isActivity) {
    ticket.participants.forEach((it) => {
      if (it.article_id === article_id && it.note_index === note_index) {
        if (it.status === "payed" && it.status === status) status = "payed";
        else if (it.status !== status && status !== undefined)
          status = "partial";
        else if (status === undefined) status = it.status;
      }
    });
  } else {
    ticket.articles.forEach((it) => {
      if (it.id === article_id) {
        status = it.status;
      }
    });
  }

  return status;
};

// { key: 'ticket', text: 'Ticket', value: 'ticket' },
// { key: 'credit_account', text: 'Remboursement crédit', value: 'credit_account' },
// { key: 'avoir', text: 'Avoir', value: 'credit' },
// { key: 'advance', text: 'Acompte', value: 'advance' },
// { key: 'reload', text: 'Recharge', value: 'reload' },
export const getTicketType = (currentTicket) => {
  if (currentTicket.advance_number) return "advance";
  if (nullStringNumber(currentTicket.is_reload)) return "reload";
  if (currentTicket.credit_number) return "credit";
  if (nullStringNumber(currentTicket.is_subscription)) return "subscription";

  return "ticket";
};

/**
 {
  discountTypes: {
    discounts: [
      {
        discount_id: 21,
        amount: '2.97',
        percentage: '33.00'
      }
    ],
    articles: [
      {
        article_id: '15',
        amount: '2.97',
        percentage: '33.00',
        discount_id: 21
      }
    ],
    participants: [
      {
        user_id: 53,
        user_index: '15-1',
        article_id: '15',
        amount: '2.97',
        percentage: '33.00',
        discount_id: 21
      }
    ]
  }
}
 * */
