import React from "react";
import MainMenu from "../../components/CustomCaisse/Menu/MainMenu";
import ChildMenu from "../../components/CustomCaisse/Menu/ChildMenu";
import HourMenu from "../../components/CustomCaisse/Menu/HourMenu";
import ContentMenu from "@/components/CustomCaisse/Content/ContentMenu/ContentMenu";
import Payment from "@/components/CustomCaisse/Content/Payment/PaymentContent";
import NoteDivision from "@/components/CustomCaisse/Content/Payment/NoteDivision";
import Discount from "@/components/CustomCaisse/Content/Payment/DiscountContent";
import Ticket from "@/components/CustomCaisse/Ticket/Ticket";
import ClientList from "@/components/CustomCaisse/ClientList/ClientList";
import TicketList from "@/components/CustomCaisse/TicketList/TicketList";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Image, Loader } from "semantic-ui-react";
import PlanningPage from "../PlanningPage/PlanningPage";
import "./CaissePage.css";
import "./Responsive.css";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import ReservationCaisseModal from "../../components/CustomCaisse/ReservationCaisseModal/ReservationCaisseModal";
import { Creators as teamsCreators } from "../../store/ducks/team";
import { creditPrint, PrintPopup } from "../../helpers/print";
import history from "../../helpers/history";
import { nullString } from "../../helpers/formatting";
import FullLayout from "../../Layout/FullLayout/FullLayout";
import SubscribersPage from "../SubscribersPage/SubscribersPage";
import SubscriberDetails from "../SubscribersPage/SubscriberDetails";
import {
  isAdvance,
  cumulPayedAmountFromPaymentTypes,
} from "../../helpers/ticket";
import { sub } from "../../helpers/operation";

// this is what we add to article to allow multi types of the same articles
export const valeur = "-";

class CaissePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      printModal: true,
      payed_amount: 0,
      discount: 0,
      creditModalOpen: false,
      decrementModalOpen: false,
      reservationModal: false,
      showReservationModalConfirm: false,
      reservationRejected: false,
      cancelMethod: null,
      printCreditModalOpen: false,
      // prevent getting multiple ticket
      autoLoad: false,
    };
  }

  redirectToHomePage = () => {
    let paramsExtra = "";
    let params = new URLSearchParams(this.props.history.location.search);
    if (params.get("auto-load")) {
      paramsExtra += "?auto-load=true";
    }
    history.push({ pathname: "/checkout", search: paramsExtra });
  };

  componentDidMount() {
    let session_id = localStorage.getItem("session_id");

    if (!session_id) this.redirectToHomePage();

    const getCaisseDataPromise = new Promise((resolve) =>
      resolve(this.props.getData())
    );
    const getPaymentTypesPromise = new Promise((resolve) =>
      resolve(this.props.getPaymentTypes(1, "", [0, 2]))
    );
    const getTeamsPromise = new Promise((resolve) =>
      resolve(this.props.getTeams(1, 2, { is_default: 1 }))
    );
    const getRolesPromise = new Promise((resolve) =>
      resolve(this.props.getRoles(1, 100))
    );
    const getClientPermissionPromise = new Promise((resolve) =>
      resolve(this.props.getClientPermission())
    );

    Promise.all([
      getCaisseDataPromise,
      getPaymentTypesPromise,
      getTeamsPromise,
      getRolesPromise,
      getClientPermissionPromise,
    ]);

    window.addEventListener("storage", () => {
      let id = localStorage.getItem("session_id");
      if (!id) {
        if (this.props.session) this.props.resetSession();
        this.redirectToHomePage();
      }
      //for slow changes
      this.loadCurrentTicketFromStorage();
    });

    //for fast changes
    this.loadCurrentTicketFromStorage();
    // navigte to ticket;
    this.navigateToTicket();
  }

  /**
   * Ticket detail
   * @param {*} id
   * @param {*} note_index
   */
  getTicketDetail = async (id, note_index = null) => {
    await this.props.getTicketById(id, note_index);
    this.props.article_to_pay([], 0, 0);
  };

  loadCurrentTicketFromStorage = () => {
    try {
      let params = new URLSearchParams(this.props.history.location.search);
      let ticket = localStorage.getItem("ticket");
      if (
        params.get("auto-load") &&
        !this.state.autoLoad &&
        nullString(ticket) &&
        !this.props.currentTicket
      ) {
        let convertedTicket = JSON.parse(ticket);
        let newTicket = {
          selectFamily: convertedTicket.selectFamily,
          selectSubFamily: convertedTicket.selectSubFamily,
          selectHourType: convertedTicket.selectHourType,
          currentTicket: convertedTicket.currentTicket,
          reservationTicket: convertedTicket.reservationTicket,
          ...convertedTicket.others,
        };
        this.props.updateAll({ ...newTicket });
        this.setState({ autoLoad: true });
        console.log("delete ticket from storage");
        localStorage.removeItem("ticket");
      }
    } catch (e) {
      //
    } finally {
    }
  };

  navigateToTicket = async () => {
    try {
      //(value, status = null, type = null, date = null)
      const ticketStringify = localStorage.getItem("goToTicket", null);
      if (!ticketStringify) return;
      const ticket = JSON.parse(ticketStringify);
      if (!ticket.is_credit_account) await this.getTicketDetail(ticket.id);
      this.props.updateCurrentNote(null);
    } catch (e) {
      console.log("error from navigateToTicket", e);
    } finally {
      localStorage.removeItem("goToTicket");
    }
  };

  handleClose = () => {
    this.setState({
      creditModalOpen: false,
      decrementModalOpen: false,
      printCreditModalOpen: false,
    });
    this.props.restored_credit_list(null);
  };

  createCredit(credit_amount, cancelMethod = null) {
    let currentTicket = { ...this.props.currentTicket };
    let credit = {};
    credit.date = currentTicket.date;
    credit.hour = currentTicket.hour;
    credit.amount = parseFloat(credit_amount).toFixed(2);
    credit.is_credit = 1;
    credit.payed_amount = 0;
    credit.status = 0;
    credit.owner_id = this.props.user.id;
    credit.parent_id = currentTicket.id ? currentTicket.id : null; //null : return article case
    credit.complex_id = currentTicket.complex_id;
    credit.articles = [];
    credit.participants = [];
    if (credit_amount > 0) {
      this.props.storeTicket(credit);
      if (cancelMethod) {
        this.setState({ cancelMethod: cancelMethod });
      }
    }
  }

  renderRestoredCreditList() {
    if (this.props.restoredCreditList) {
      return this.props.restoredCreditList.map((itm, i) => {
        return (
          <p key={i}>
            L'avoir n° {itm.credit_number} d'un montant de{" "}
            {parseFloat(itm.credit_value).toFixed(2)}
            {localStorage.getItem("currency")} a été restauré
          </p>
        );
      });
    }
  }

  renderConfirmCreditPrintModal() {
    const { t } = this.props;
    return (
      <ModalConfirm
        open={this.state.printCreditModalOpen}
        onClose={this.handleClose}
        title={t("print")}
        message={<p>{t("print-avoir")}</p>}
        okText={t("yes")}
        ok={async () => {
          let receipt = creditPrint(this.props.credit);
          await PrintPopup(receipt);
          this.setState({ printCreditModalOpen: false });
        }}
        cancelText={t("no")}
        cancel={() => this.setState({ printCreditModalOpen: false })}
      />
    );
  }

  renderCreditModal() {
    const { t } = this.props;
    return (
      <ModalConfirm
        open={this.state.creditModalOpen}
        onClose={this.handleClose}
        title={t("avoir")}
        message={
          <>
            <p>
              {t("an-avoir")}{" "}
              {this.props.credit && this.props.credit.credit_number}{" "}
              {t("has-amount")}{" "}
              {this.props.credit &&
                parseFloat(this.props.credit.amount).toFixed(2)}
              {localStorage.getItem("currency")} {t("has-generate")}
            </p>
            {this.renderRestoredCreditList()}
          </>
        }
        ok={() => {
          this.setState({ creditModalOpen: false });
          this.props.restored_credit_list(null);
          if (this.state.cancelMethod) {
            this.setState({ printCreditModalOpen: true });
          }
        }}
      />
    );
  }

  cancelDecrementOccNb() {
    let currentTicket = { ...this.props.currentTicket };
    currentTicket.articles.map((item, index) => {
      if (item.id === this.props.decrementOccNb?.article_id) {
        item.article_nb =
          parseInt(item.article_nb) + this.props.decrementOccNb?.nbOcc;
        currentTicket.amount =
          parseFloat(currentTicket.amount) +
          parseFloat(item.total_price) * this.props.decrementOccNb?.nbOcc;
      }
    });
    currentTicket.articles_nb =
      parseInt(currentTicket.articles_nb) + this.props.decrementOccNb?.nbOcc;
    this.props.decrement_occ_nb(null);
    this.props.redirection("home");
    this.props.updateCurrentTicket(currentTicket);
  }

  renderCancelDecrementOccNbModal() {
    const { t } = this.props;
    return (
      <ModalConfirm
        open={this.state.decrementModalOpen}
        onClose={() => {
          this.setState({ decrementModalOpen: false });
          this.props.resetRedirection();
        }}
        title={t("cancel-decrement")}
        message={<p>{t("wanna-cancel-decrement")}</p>}
        okText={t("cancel-decrement")}
        ok={() => {
          if (this.props.decrementOccNb) {
            this.cancelDecrementOccNb();
          }
          this.handleClose();
        }}
        cancelText={t("close")}
        cancel={() => {
          this.handleClose();
          this.props.resetRedirection();
        }}
      />
    );
  }

  renderConfirmReservationModal(article) {
    const { t } = this.props;
    return (
      <ModalConfirm
        open={this.state.showReservationModalConfirm}
        onClose={async () => {
          await this.deleteArticleInReservation(article);
          this.setState({ showReservationModalConfirm: false });
        }}
        title={t("create-reservation")}
        message={<p>{t("wanna-create-reservation")}</p>}
        okText={t("create-reservation")}
        ok={() => {
          this.setState({
            showReservationModalConfirm: false,
            reservationModal: true,
          });
        }}
        cancelText={t("close")}
        cancel={async () => {
          await this.deleteArticleInReservation(article);
          this.setState({ showReservationModalConfirm: false });
        }}
      />
    );
  }

  componentDidUpdate(prevProps, prevState) {
    //check if user add an article
    if (
      (this.props.currentTicket &&
        this.props.currentTicket.status &&
        this.props.currentTicket.status !== 2 &&
        !this.props.currentTicket.reservation_id &&
        !this.props.currentTicket.reservation &&
        !prevProps.currentTicket &&
        this.props.currentTicket.articles.length > 0) ||
      (prevProps.currentTicket &&
        this.props.currentTicket &&
        !this.props.currentTicket.reservation_id &&
        this.props.currentTicket.status &&
        this.props.currentTicket.status !== 2 &&
        !this.props.currentTicket.reservation &&
        this.props.currentTicket.articles.length &&
        this.props.currentTicket.articles.length !==
          prevProps.currentTicket.articles.length)
    ) {
      let { articles } = this.props.currentTicket;
      let filterResult = articles[articles.length - 1];
      if (filterResult.parent.is_activity && filterResult.child.is_res) {
        this.setState({ showReservationModalConfirm: true });
      }
    }

    if (this.props.currentTicket !== prevProps.currentTicket) {
      if (!this.props.currentTicket) {
        this.props.updateCurrentNote(null);
      }
      this.setState({
        payed_amount: this.props.currentTicket
          ? this.props.currentTicket.payed_amount
          : 0,
        discount:
          this.props.currentTicket && this.props.currentTicket.discount
            ? this.props.currentTicket.discount
            : 0,
      });
    }
    //get credit number
    if (this.props.credit && this.props.credit !== prevProps.credit) {
      this.setState({ creditModalOpen: true });
    }
    //cancel decrement action
    if (
      this.props.decrementOccNb !== null &&
      this.props.isRedirection &&
      !this.state.decrementModalOpen
      // this.props.isRedirection !== prevProps.isRedirection
    ) {
      this.setState({ decrementModalOpen: true });
    }

    if (this.props.loading !== prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }

    if (
      !this.state.printCreditModalOpen &&
      this.state.printCreditModalOpen !== prevState.printCreditModalOpen
    ) {
      if (this.state.cancelMethod === "ticket") {
        this.props.updateCurrentTicket(null);
        this.props.caisseRedirection("ticket", 2, "ticket");
        this.props.getTicket({ page: 1, size: 10, status: [2] });
      }
      if (this.state.cancelMethod === "advance") {
        this.props.updateCurrentTicket(null);
        this.props.caisseRedirection("ticket", 2, "advance");
        this.props.getTicket({ page: 1, size: 10, status: [2], is_advance: 1 });
      }
      this.setState({ cancelMethod: null });
    }
  }

  deleteArticleInReservation = async (article) => {
    if (!this.props.currentTicket) return;

    let currentTicket = { ...this.props.currentTicket };
    currentTicket.articles = currentTicket.articles.filter((item) => {
      return nullString(item.id) !== nullString(article.id);
    });
    currentTicket.participants = currentTicket.participants.filter(
      (itm) => nullString(itm.article_id) !== nullString(article.id)
    );
    currentTicket.amount =
      currentTicket.amount - parseFloat(article.total_price);
    currentTicket.participants_nb =
      currentTicket.participants_nb -
      (article.default_participants_nb ? article.default_participants_nb : 0);
    currentTicket.articles_nb = parseInt(currentTicket.articles_nb) - 1;
    await this.props.updateCurrentTicket(currentTicket);
  };

  render() {
    const { t } = this.props;
    let article = null;
    let data = null;
    let { selectFamily, menusData, selectSubFamily } = this.props;
    if (
      this.props.currentTicket &&
      this.props.currentTicket.hasOwnProperty("articles") &&
      this.props.currentTicket.articles.length > 0
    ) {
      let filterResult = this.props.currentTicket.articles.filter(
        (it) => it.parent.is_activity
      );
      if (filterResult.length > 0)
        article = filterResult[filterResult.length - 1];
    }
    let filterMenu = null;
    let childSelected = null;
    if (menusData !== null) {
      filterMenu = menusData.filter((it) => it.id === selectFamily);
      if (filterMenu.length > 0) {
        data = filterMenu[0].children.filter((it) => it.id === selectSubFamily);
      }
    }
    if (data && Array.isArray(data) && data.length > 0) childSelected = data[0];

    return this.state.loading ||
      this.props.loading ||
      this.props.sessionLoading ||
      this.props.loadingPaymentType ? (
      <div className={"load-div"} style={{ width: "100vw", height: "100vh" }}>
        <Loader active={true} inline={"centered"} />
      </div>
    ) : (
      <FullLayout className={"caisse-main"}>
        {
          <ReservationCaisseModal
            open={this.state.reservationModal}
            t={this.props.t}
            article={article}
            // allowedHours={}
            deleteArticleInReservation={() =>
              this.deleteArticleInReservation(article)
            }
            onClose={() => this.setState({ reservationModal: false })}
          />
        }
        {this.renderCreditModal()}
        {this.renderCancelDecrementOccNbModal()}
        {this.renderConfirmCreditPrintModal()}
        {this.renderConfirmReservationModal(article)}

        <>
          <MainMenu t />
          {this.props.caisse_redirection.value !== "client" &&
            this.props.caisse_redirection.value !== "subscriber" &&
            this.props.caisse_redirection.value !== "subscriberDetails" &&
            this.props.caisse_redirection.value !== "invoice" &&
            this.props.caisse_redirection.value !== "ticket" &&
            this.props.caisse_redirection.value !== "reservation" && (
              <>
                <div className="caisse-content">
                  <div className="content-main">
                    {this.props.caisse_redirection.value === "home" && (
                      <>
                        <ChildMenu t />
                        {filterMenu &&
                          filterMenu.length > 0 &&
                          childSelected &&
                          childSelected.is_res === 1 && <HourMenu t />}
                        <ContentMenu
                          t
                          switchReservation={() =>
                            this.setState((prev) => ({
                              reservationModal: !prev.reservationModal,
                            }))
                          }
                        />
                      </>
                    )}

                    {this.props.caisse_redirection.value === "payment" && (
                      <div
                        className={[
                          "caisse-main-body",
                          this.props.currentTicket ? "with-margin" : " ",
                        ].join(" ")}
                      >
                        <Payment
                          createCredit={(credit_amount) =>
                            this.createCredit(credit_amount)
                          }
                        />
                      </div>
                    )}
                    {this.props.caisse_redirection.value ===
                      "note_division" && (
                      <div className={"caisse-main-body"}>
                        <NoteDivision />
                      </div>
                    )}
                    {this.props.caisse_redirection.value === "discount" && (
                      <div
                        className={[
                          "caisse-main-body",
                          this.props.currentTicket ? "with-margin" : " ",
                        ].join(" ")}
                      >
                        <Discount t={t} />
                      </div>
                    )}
                    {(this.props.caisse_redirection.value === "pending" ||
                      this.props.caisse_redirection.value ===
                        "ticket details" ||
                      this.props.caisse_redirection.value === "invoice" ||
                      this.props.caisse_redirection.value ===
                        "edit ticket") && (
                      <div
                        className={[
                          "caisse-main-body",
                          this.props.currentTicket ? "with-margin" : " ",
                        ].join(" ")}
                      >
                        <TicketList t={t} type={"pending"} />
                      </div>
                    )}
                  </div>

                  {this.props.currentTicket &&
                    this.props.currentTicket.articles_nb !== 0 && (
                      <div className="stat-nav-bar">
                        <span
                          className="stat-bloc"
                          style={{ background: "#7EB5E5" }}
                        >
                          {t("total-discount")}
                          <br />
                          {parseFloat(this.state.discount).toFixed(2)}
                          {localStorage.getItem("currency")}
                        </span>
                        <span
                          className="stat-bloc"
                          style={{ background: "#00B876" }}
                        >
                          {t("total-payed")}
                          <br />
                          {parseFloat(this.state.payed_amount).toFixed(2)}{" "}
                          {localStorage.getItem("currency")}
                        </span>
                        <span
                          className="stat-bloc"
                          style={{ background: "#FF7070" }}
                        >
                          {t("still-un-payed")}
                          <br />
                          {}
                          {isAdvance(this.props.currentTicket)
                            ? parseFloat(this.props.currentTicket.amount) -
                              cumulPayedAmountFromPaymentTypes(
                                this.props.currentTicket
                              )
                            : sub(
                                parseFloat(this.props.currentTicket.amount),
                                parseFloat(
                                  this.props.currentTicket.payed_amount
                                ) +
                                  parseFloat(this.props.currentTicket.discount)
                              )}
                          {localStorage.getItem("currency")}
                        </span>
                        <span className="stat-client">
                          <Image src={require("@/assets/client.svg")} />
                          {this.props.currentTicket.participants &&
                          Object.entries(this.props.currentTicket.participants)
                            .length
                            ? Object.entries(
                                this.props.currentTicket.participants
                              ).length
                            : 1}{" "}
                          Client
                          {this.props.currentTicket.participants &&
                            Object.entries(
                              this.props.currentTicket.participants
                            ).length > 1 && <>s</>}
                        </span>
                        <span className="stat-article">
                          {this.props.currentTicket.articles_nb}
                          <br />
                          {this.props.currentTicket.articles_nb > 1
                            ? t("articles")
                            : t("article")}
                        </span>
                      </div>
                    )}
                </div>
                <div className={"caisse-main-menu-right"}>
                  <Ticket
                    createCredit={(credit_amount, cancelMethod) =>
                      this.createCredit(credit_amount, cancelMethod)
                    }
                  />
                </div>
              </>
            )}
          {this.props.caisse_redirection.value === "client" && (
            <div className={"caisse-main-content"}>
              <ClientList t={t} />
            </div>
          )}
          {this.props.caisse_redirection.value === "ticket" && (
            <div className={["caisse-main-content"].join(" ")}>
              <TicketList t={t} type="ticket" />
            </div>
          )}
          {this.props.caisse_redirection.value === "invoice" && (
            <div className={["caisse-main-content"].join(" ")}>
              <TicketList t={t} type="invoice" />
            </div>
          )}
          {this.props.caisse_redirection.value === "reservation" && (
            <div className={"caisse-main-content"}>
              <PlanningPage t={(s) => this.props.t(s)} type={"caisse"} />
            </div>
          )}

          {this.props.caisse_redirection.value === "subscriber" && (
            <div className={"caisse-main-content"}>
              <SubscribersPage t={(s) => this.props.t(s)} type={"caisse"} />
            </div>
          )}

          {this.props.caisse_redirection.value === "subscriberDetails" && (
            <div className={"caisse-main-content"}>
              <SubscriberDetails
                t={(s) => this.props.t(s)}
                type={"caisse"}
                subscriberId={this.props.caisse_redirection.status}
              />
            </div>
          )}
        </>
      </FullLayout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTeams: (page, size, search) =>
    dispatch(teamsCreators.indexRequestTeam({ page, size, search })),
  getData: () => dispatch({ type: "GET_DATA_REQUEST" }),
  updateAll: (payload) => dispatch({ type: "UPDATE_ALL", payload }),
  getTicket: () => dispatch({ type: "GET_TICKETS_REQUEST" }),
  getRoles: (page, size) =>
    dispatch({ type: "INDEX_REQUEST_ROLE", payload: { page, size } }),
  getClientPermission: () => dispatch({ type: "CLIENT_PERMISSION_REQUEST" }),
  getPaymentTypes: (page, size, status) =>
    dispatch({
      type: "INDEX_REQUEST_PAYMENT_METHOD",
      payload: { page, size, status },
    }),
  decrement_occ_nb: (value) =>
    dispatch({ type: "DECREMENT_OCC_NB_REQUEST", payload: value }),
  updateCurrentTicket: (data) =>
    dispatch({ type: "UPDATE_CURRENT_TICKET_REQUEST", payload: data }),
  resetRedirection: () => dispatch({ type: "CAISSE_REDIRECTION_RESET" }),
  storeTicket: (data) =>
    dispatch({ type: "STORE_TICKET_REQUEST", payload: data }),
  updateTicket: (data) =>
    dispatch({ type: "UPDATE_TICKET_REQUEST", payload: data }),
  redirection: (value) =>
    dispatch({ type: "CAISSE_REDIRECTION_REQUEST", payload: { value } }),
  getSession: (payload) =>
    dispatch({
      type: "GET_SESSION_REQUEST",
      payload,
    }),
  resetSession: (payload) =>
    dispatch({
      type: "RESET_SESSION_REQUEST",
      payload,
    }),
  restored_credit_list: (data) =>
    dispatch({ type: "RESTORED_CREDIT_LIST_REQUEST", payload: data }),
  updateCurrentNote: (id) =>
    dispatch({ type: "CURRENT_NOTE_REQUEST", payload: id }),
  caisseRedirection: (value, status = null, type = null, date = null) =>
    dispatch({
      type: "CAISSE_REDIRECTION_REQUEST",
      payload: { value, status, type, date },
    }),
  getTicketById: (id, note_index = null) =>
    dispatch({ type: "GET_TICKET_REQUEST", payload: { id, note_index } }),
  article_to_pay: (article, amount, rest) =>
    dispatch({
      type: "ARTICLE_TO_PAY_REQUEST",
      payload: { article, amount, rest },
    }),
});

const mapStateToProps = (state) => {
  return {
    teamsLoading: state.team.loading,
    session: state.session.session,
    currentTicket: state.caisse.currentTicket,
    menusData: state.caisse.menusData,
    selectFamily: state.caisse.selectFamily,
    selectSubFamily: state.caisse.selectSubFamily,
    caisse_redirection: state.caisse.caisse_redirection,
    loading: state.caisse.loading,
    loadingPaymentType: state.paymentMethod.loading,
    sessionLoading: state.session.loading,
    credit: state.caisse.credit,
    isRedirection: state.caisse.isRedirection,
    decrementOccNb: state.caisse.decrementOccNb,
    restoredCreditList: state.caisse.restoredCreditList,
    user: state.auth.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CaissePage)
);
