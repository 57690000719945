import { createActions, createReducer } from 'reduxsauce';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from '../../services/notificationService';


export const { Types, Creators } = createActions({
  getNotificationsRequest: ['payload'],
  getUnreadNotificationsRequest: ['payload'],
  getMoreNotificationsRequest: ['payload'],
  markAsReadRequest: ['payload'],
  notificationMoreRequest: ['payload'],
  notificationRequest: ['payload'],
  deleteNotificationRequest: ['payload'],
  notificationResponse: ['payload'],
  addNotificationResponse: ['payload'],
  resetNotificationRequest: [''],
});


const INITIAL_STATE = {
  notifications: [],
  page: 0,
  size: 10,
  total: 0,
  last: 1,
  errors: false,
  loading: false,
  marked: false,
  deleted: false,
  totalUnread: 0,
  loadingMark: false,
  loadingDelete: false,
};

const getNotifications = (state) => state.notification.notifications;

function* search(action) {
  try {
    yield put(Creators.notificationResponse({ loading: true, notifications: [] }));

    // @ts-ignore
    let data = yield call(service.index, action.payload);

    let formattedResult = {
      notifications: data.data.data,
      size: data.data.per_page,
      page: data.data.current_page,
      total: data.data.total,
      last: data.data.last_page,
    };

    yield put(Creators.notificationResponse({ ...formattedResult }));
    yield put(Creators.notificationResponse({ loading: false }));
    // yield put(Creators.changeTheme({ ...action.payload }))
  } catch (e) {
    yield put(Creators.notificationResponse({ loading: false }));
  }
}

function* more(action) {
  try {
    yield put(Creators.notificationResponse({ loading: true }));

    // @ts-ignore
    let data = yield call(service.index, action.payload);
    let notifications = yield select(getNotifications);
    let formattedResult = {
      notifications: [...notifications, ...data.data.data],
      size: data.data.per_page,
      page: data.data.current_page,
      total: data.data.total,
      last: data.data.last_page,
    };

    yield put(Creators.notificationResponse({ ...formattedResult }));
    yield put(Creators.notificationResponse({ loading: false }));
    // yield put(Creators.changeTheme({ ...action.payload }))
  } catch (e) {
    yield put(Creators.notificationResponse({ loading: false }));
  }
}

function* mark(action) {
  try {
    yield put(Creators.notificationResponse({ loadingMark: true, marked: false }));
    let notifications = yield select(getNotifications);
    let formattedResult = notifications.map(it => {
      it.read_at = true;
      return it;
    });

    // let data = yield call(service.markAsRead, action.payload);
    yield put(Creators.notificationResponse({
      notifications: [...formattedResult],
      loadingMark: false,
      totalUnread: 0,
      marked: true,
    }));
    // yield put(Creators.changeTheme({ ...action.payload }))
  } catch (e) {
    yield put(Creators.notificationResponse({ loadingMark: false, marked: false }));
  }
}

function* unread(action) {
  try {
    yield put(Creators.notificationResponse({ loadingMark: true }));

    // @ts-ignore
    let data = yield call(service.unRead, action.payload);
    let total = data.data.data.total;
    yield put(Creators.notificationResponse({ loadingMark: false, totalUnread: total }));
    // yield put(Creators.changeTheme({ ...action.payload }))
  } catch (e) {
    yield put(Creators.notificationResponse({ loadingMark: false }));
  }
}

function* remove(action) {
  try {
    yield put(Creators.notificationResponse({ loadingDelete: true, deleted: false }));
    let notifications = yield select(getNotifications);
    let id = action.payload;
    if (notifications.length >= id) {
      yield call(service.remove, notifications[id].id_inc);
    }
    //filter notification
    let filterNotifications = notifications.filter((it, i) => i !== id);

    yield put(Creators.notificationResponse({
      loadingDelete: false,
      deleted: true,
      notifications: filterNotifications,
    }));
    // yield put(Creators.changeTheme({ ...action.payload }))
  } catch (e) {
    yield put(Creators.notificationResponse({ loadingDelete: false, deleted: false }));
  }
}

export default function* mySaga() {
  // @ts-ignore
  yield takeLatest(Types.GET_NOTIFICATIONS_REQUEST, search);
  yield takeLatest(Types.GET_MORE_NOTIFICATIONS_REQUEST, more);
  yield takeLatest(Types.MARK_AS_READ_REQUEST, mark);
  yield takeLatest(Types.GET_UNREAD_NOTIFICATIONS_REQUEST, unread);
  yield takeLatest(Types.DELETE_NOTIFICATION_REQUEST, remove);
}

// Reducers Handler :

const notificationResponse = (state = INITIAL_STATE, action) => {
  // @ts-ignore
  return {
    ...state,
    ...action.payload,
  };
};
const addNotificationResponse = (state = INITIAL_STATE, action) => {
  // @ts-ignore
  if (state.notifications.length > 0) {
    return {
      ...state,
      //  notifications: [...action.payload, ...state.notifications],
      totalUnread: state.totalUnread + action.payload.length,
    };
  } else {
    return {
      ...state,
      //notifications: [...action.payload],
      totalUnread: state.totalUnread + action.payload.length,
    };
  }
};

const notificationReset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...INITIAL_STATE,
    errors: false,
    loading: false,
    marked: false,
    deleted: false,
    totalUnread: 0,
  };
};


export const notificationReducer = createReducer(INITIAL_STATE, {
  [Types.NOTIFICATION_RESPONSE]: notificationResponse,
  [Types.RESET_NOTIFICATION_REQUEST]: notificationReset,
  [Types.ADD_NOTIFICATION_RESPONSE]: addNotificationResponse,
});
