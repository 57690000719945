import { getMethod } from "./index";
import { bookingHourType } from "../services/bookingService";
import { nullString, nullStringNumber } from "./formatting";
import { bookingDefault } from "../pages/ComplexPage/FormComplex";


export const formattingReservationWithLogin = async data => {
  return await getMethod("/teams?page=1&size=2&is_default=1&complex_id=" + data.complex).then(async (response) => {
    let hourTypes = await bookingHourType({
      date: data.date,
      complex_id: data.complex,
      start_hour: data.start_hour,
      end_hour: data.end_hour,
      activity_id: data.activity,
      duration_id: data.duration
    });

    hourTypes = hourTypes.data.data;
    let price = "";
    let hourType = "";
    let price_per_participant = "";

    if (hourTypes && hourTypes.length > 0) {
      hourType = nullString(hourTypes[0].id);
      price = nullString(hourTypes[0].article_amount);
      if (nullString(hourTypes[0].article_amount)) {
        price_per_participant = parseFloat(hourTypes[0].article_amount) / nullStringNumber(data.participants_nb);
      }
    }
    let teams = response.data.data;
    let result = {
      email: data.email,
      password: data.password,
      complex_id: data.complex,
      reservation: {
        created_from: "booking",
        activity_id: data.activity,
        duration_id: data.duration,
        date: data.date,
        start_hour: data.start_hour,
        end_hour: data.end_hour,
        hour_type_id: hourType,
        price: price,
        competition: {
          competition_id: "",
          competition_day: "",
          competition_day_date: "",
          round: "",
          team_a_id: teams.length > 0 ? teams[0].id : 0,
          team_b_id: teams.length > 1 ? teams[1].id : 0,
          price_per_participant,
          participants_nb: data.participants_nb,
          real_missing_participants_nb: data.participants_nb - 1,
          missing_participants_nb: data.participants_nb - 1
        }
      }
    };
    return result;
  });
};


export const formattingReservationWithRegister = async data => {
  return await getMethod("/teams?page=1&size=2&is_default=1&complex_id=" + data.complex).then(async (response) => {
    let hourTypes = await bookingHourType({
      date: data.date,
      complex_id: data.complex,
      start_hour: data.start_hour,
      end_hour: data.end_hour,
      activity_id: data.activity,
      duration_id: data.duration
    });

    hourTypes = hourTypes.data.data;
    let price = "";
    let hourType = "";
    let price_per_participant = "";

    if (hourTypes && hourTypes.length > 0) {
      hourType = nullString(hourTypes[0].id);
      price = nullString(hourTypes[0].article_amount);
      if (nullString(hourTypes[0].article_amount)) {
        price_per_participant = parseFloat(hourTypes[0].article_amount) / nullStringNumber(data.participants_nb);
      }
    }

    let teams = response.data.data;
    let result = {
      code: data.code,
      complex_id: data.complex,
      id: data.id,
      reservation: {
        created_from: "booking",
        activity_id: data.activity,
        duration_id: data.duration,
        date: data.date,
        start_hour: data.start_hour,
        end_hour: data.end_hour,
        hour_type_id: hourType,
        price: price,
        competition: {
          competition_id: "",
          competition_day: "",
          competition_day_date: "",
          round: "",
          team_a_id: teams.length > 0 ? teams[0].id : 0,
          team_b_id: teams.length > 1 ? teams[1].id : 0,
          price_per_participant,
          participants_nb: data.participants_nb,
          real_missing_participants_nb: data.participants_nb - 1,
          missing_participants_nb: data.participants_nb - 1
        }
      }
    };
    return result;
  });
};

export const validateRegisterForm = registerform => {
  let errorForm = {
    email: null,
    password: null,
    password_confirmation: null,
    firstName: null,
    lastName: null,
    mobile: null,
    date: null,
    start_hour: null,
    activity: null,
    accept: null
  };

  if (!(registerform.firstName !== "" || registerform.firstName !== "")) {
    if (registerform.firstName === "")
      errorForm.firstName = "Le champ prénom est obligatoire";
    else errorForm.firstName = "Le champ nom est obligatoire";
  }

  if (registerform.email === "") {
    errorForm.email = "Le champ email est obligatoire";
  } else if (!validateEmail(registerform.email))
    errorForm.email = "Le champ email est invalide";

  if (registerform.mobile === "")
    errorForm.mobile = "Le champ mobile est obligatoire";

  if (registerform.password === "")
    errorForm.password = "Le champ mot de pass est obligatoire";

  if (registerform.password_confirmation === "")
    errorForm.password_confirmation =
      "Le champ confirmation mot de passe est obligatoire";

  if (registerform.password_confirmation !== registerform.password)
    errorForm.password_confirmation =
      "Le champ confirmation mot de passe est obligatoire";

  return errorForm;
};

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const setupBookingTheme = (theme) => {
  console.log("theme", theme);
  let rootStyle = document.querySelector(":root");
  let defaultThemeStructure = {
    "--booking-header-background": "header_background",
    "--booking-header-title": "header_color",

    "--booking-tab-color": "tab_color",
    "--booking-tab-active": "tab_active",

    "--booking-btn-background": "btn_background",
    "--booking-btn-color": "btn_color",

    "--booking-selected-slot-background": "selected_slot_background",
    "--booking-selected-slot-color": "selected_slot_color",

    "--booking-login-background": "login_background",
    "--booking-login-color": "login_color",

    "--booking-modal-background": "modal_background",
    "--booking-modal-color": "modal_color"
  };

  Object.keys(defaultThemeStructure).forEach(it => {
    let color = theme && nullString(theme[defaultThemeStructure[it]]) ?
      nullString(theme[defaultThemeStructure[it]]) :
      bookingDefault[defaultThemeStructure[it]];
    rootStyle.style.setProperty(it, color);
  });

};