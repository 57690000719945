import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  sensorService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/sensors?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_code ? '&search_code=' + search.search_code : '') +
        (search && search.search_uid ? '&search_uid=' + search.search_uid : '') +
        (search && search.search_serial_number
          ? '&search_serial_number=' + search.search_serial_number
          : '') +
        '&complex_id=' +
        localStorage.getItem('complex_id'),
      );
      return result.data;
    },
    store: async (data) => {
      const result = await postMethod('/sensors', data);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/sensors/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/sensors/' + data.id, data);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/sensors/' + id);
      return result.data;
    },
  },
};
