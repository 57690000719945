import React from "react";
import { Button } from "semantic-ui-react";
import "../../CustomCaisse.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import localization, * as fr from "moment/locale/fr";
import { valeur } from "../../../../pages/CaissePage/CaissePage";
import { imgUrl } from "@/helpers/index";
import Image from "../../../ImageComponent/Image";
import i18n from "@/i18n";
import { ticketHasActivity } from "@/helpers/ticket";
import { exactDivision } from "@/helpers/operation";
import { infoToast } from '@/helpers/customToast';

class ContentMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTicket: {
        id: null,
        reservation_id: null,
        user_id: null,
        amount: 0,
        payed_amount: 0,
        participants_nb: 0,
        articles_nb: 0,
        date: moment(new Date()).format("DD-MM-YYYY"),
        hour: moment(new Date()).locale("fr", localization).format("LT"),
        number: null,
        complex_id: localStorage.getItem("complex_id"),
        discount: 0,
        status: 1,
        is_credit: 0,
        is_advance: 0,
        is_credit_account: 0,
        is_reload: 0,
        owner_id: this.props.user.id,
        articles: [],
        participants: [],
        payments: {
          types: [],
          articles: [],
          participants: []
        },
        discountTypes: {
          discounts: [],
          articles: [],
          participants: []
        },
        amount_ht: 0,
        vat_amount: 0,
        noteInProgress: false,
        notes: [],
        pendingAdvance: null //in case of edit participant number & the activity is payed by advance => we have to save this advance
      }
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.number && this.state.number !== prevState.number) {
      let data = this.props.currentTicket && this.props.currentTicket.status !== 0
        ? { ...this.props.currentTicket }
        : { ...this.state.currentTicket };
      if (data.is_advance === 0 && data.is_reload === 0) {
        data.number = this.state.number;
        this.props.updateCurrentTicket(data);
      }
    }
  }


  update(data, article, note = null) {
    let exist = [];
    let i = 0;
    let ticket = { ...data };

    exist = ticket.articles.filter((it) => {
      return it.id.toString().split(valeur)[0] === article.id.toString() && it.note_index === note && (it.status !== "payed" || parseFloat(it.amount) === 0);
    });
    if (Object.entries(data.articles).length) {
      i = data.articles.reduce((curr, it) => {
        if (it.id.toString().split(valeur)[0] === article.id.toString()) return curr + 1;
        else return curr;
      }, 0);
    }
    article.article_id = article.id;
    //article selected unique
    if (exist.length === 0) {
      article.article_nb = 1;
      article.amount = parseFloat(article.total_price);
      article.payed_amount = 0;
      article.status = parseFloat(article.total_price) === 0 ? "payed" : null;
      if (article.parent.is_activity === 0 || (article.parent.is_activity === 1 && article.child.is_res === 0)) {
        article.id = article.id + "" + valeur + (i).toString();
      } else {
        article.id = article.id.toString();

      }
      article.note_index = note;
      ticket.articles = [...ticket.articles, article];
      ticket.participants_nb = ticket.participants ? parseInt(ticket.participants.length) : 1;
      ticket.amount = (parseFloat(ticket.amount) + parseFloat(article.total_price)).toFixed(2);
      ticket.articles_nb = parseInt(ticket.articles_nb) + 1;

      //participants
      let participants = [];
      if (ticket && Object.entries(ticket.participants).length) {
        participants = [...ticket.participants];
      }
      if (article.parent.is_activity === 1 && article.child.is_res === 1) {
        //first item get excess of division (exact division)
        let excess = exactDivision(article.total_price,article.price_per_participant,article.default_participants_nb);
        let excess_ht = exactDivision(article.amount_ht,article.price_per_participant_ht,article.default_participants_nb);
        for (let index = 1; index < article.default_participants_nb + 1; index++) {
          let amount = (index === 1) ?
            parseFloat(article.price_per_participant) + excess : parseFloat(article.price_per_participant);
          let amount_ht = (index === 1) ?
            parseFloat(article.price_per_participant_ht) + excess_ht : parseFloat(article.price_per_participant_ht);
          let participant = {};
          participant["user_id"] = "";
          participant["user_index"] = article.id + "-" + index;
          participant["article_id"] = article.id;
          participant["subscriber_id"] = null;
          participant["amount"] = parseFloat(amount).toFixed(2);
          participant["payed_amount"] = 0;
          participant["amount_ht"] = parseFloat(amount_ht).toFixed(3);
          participant["vat_amount"] = (parseFloat(amount) - parseFloat(amount_ht)).toFixed(3);
          participant["vat_id"] = article.vat_id;
          participant["status"] = null;
          participant["note_index"] = note;
          participants.push(participant);
        }
      }
      ticket.participants = participants;


    } else {
      if (article.parent.is_activity === 0 || (article.parent.is_activity === 1 && article.child.is_res === 0)) {
        ticket.articles.forEach((item, index) => {
          if (item.id === exist[0].id && (item.status !== "payed" || parseFloat(item.amount) === 0)) {
            item.article_nb = parseInt(item.article_nb) + 1;
            ticket.amount = (parseFloat(ticket.amount) + parseFloat(article.total_price)).toFixed(2);
            ticket.articles_nb = parseInt(ticket.articles_nb) + 1;
          }
        });
      }
    }
    ticket.articles = [...ticket.articles];
    return ticket;
  }

  addArticleToTicket = async () => {
    let data =
      this.props.currentTicket && this.props.currentTicket.status !== 0
        ? { ...this.props.currentTicket }
        : { ...this.state.currentTicket };
    let article = { ...this.props.article };

    if (this.props.currentTicket && this.props.currentTicket.status === 0)
      await this.props.updateCurrentTicket(null);

    if (data && data.hasOwnProperty("articles")) {
      //select only one article (advance case)
      if ((article.parent.is_advance === 1 || article.parent.is_reload === 1 || article.parent.is_credit === 1) && data.articles.length) {
        return;
      }
      // check if its an  article we dont add it again
      if ((article.parent.is_activity === 1 && article.child.is_res === 1 &&
        data.articles.filter((it) => it.id.toString().split(valeur)[0] === article.id.toString()).length)
      ) {
        return;
      }

      // check if ticket has already an activity
      if (ticketHasActivity(data) && article.parent && article.parent.is_activity === 1) {
        infoToast(i18n.t("ticket_has_already_an_activity"));
        return;
      }

      data = this.update(data, { ...article }, this.props.currentNote);
      let ticketAfter = { ...data, articles: [...data.articles], notes: [...data.notes] };
      this.props.updateCurrentTicket({ ...ticketAfter });
      this.props.article_to_edit_payment(null);
      this.props.participant_to_edit_payment(null);
      if (data.status === 0) {
        await this.props.duplicateTicket(1);
      }
    }

  };

  render() {
    const { t } = this.props;
    let background = this.props.article.avatar ?
      imgUrl + "/articles/" + this.props.article.complex_id + "/" + this.props.article.avatar : "";
    let style = { color: this.props.article.color ? this.props.article.color : "#7EB5E5"};
    return (
      <Button
        className="content-menu-item"
        style={style}
        onClick={() => this.addArticleToTicket()}
      >

        {background && <Image
          type={"item"}
          src={background ? background : ""}
          className={"content-menu-item-avatar"}
        />}
        <span className={"content-menu-item-name"}>
          {this.props.article.name}{" "}
          {this.props.article.duration &&
          " - " + this.props.article.duration.duration.replace(":", "H")}
          <br/>
          {this.props.article.info !== null && this.props.article.info + " - "}
          {this.props.article.default_participants_nb !== null &&
          this.props.article.default_participants_nb + " " + i18n.t("pers")}
        </span>
        <span className={"content-menu-item-price"}>
          <span>
           {this.props.article.total_price + " " + localStorage.getItem("currency")}
          </span>
        </span>
      </Button>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTicket: (data) => dispatch({ type: "UPDATE_CURRENT_TICKET_REQUEST", payload: data }),
  article_to_pay: (article, amount, rest) =>
    dispatch({ type: "ARTICLE_TO_PAY_REQUEST", payload: { article, amount, rest } }),
  article_to_edit_payment: (value) =>
    dispatch({ type: "ARTICLE_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  participant_to_edit_payment: (value) =>
    dispatch({ type: "PARTICIPANT_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  duplicateTicket: (id) => dispatch({ type: "DUPLICATE_TICKET_REQUEST", payload: id }),
  updateCurrentNote: (id) => dispatch({ type: "CURRENT_NOTE_REQUEST", payload: id })
});

const mapStateToProps = (state) => {
  return {
    currentTicket: state.caisse.currentTicket,
    articleToPay: state.caisse.articleToPay,
    amountToPay: state.caisse.amountToPay,
    restAmountToPay: state.caisse.restAmountToPay,
    user: state.auth.user,
    currentNote: state.caisse.currentNote
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentMenuItem));
