import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import compTypeService from '@/services/compTypeService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';
// Action types and creators
export const { Types, Creators } = createActions({
  loadingCompType: ['data'],

  indexRequestCompType: ['payload'],
  indexResponseCompType: ['data'],

  storeRequestCompType: ['payload'],
  storeResponseCompType: ['data'],

  editRequestCompType: ['id'],
  editResponseCompType: ['data'],

  updateRequestCompType: ['payload'],
  updateResponseCompType: ['data'],

  destroyRequestCompType: ['id'],
  destroyResponseCompType: ['data'],
});

// Initial state
const INITIAL_STATE = {
  compTypes: [],
  compType: null,
  loading: false,
  errors: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingCompType({ loading: true, compTypes: [] }));
    const data = yield call(compTypeService.compTypeService.index, action.payload);
    yield put(Creators.indexResponseCompType(data));
    yield put(Creators.loadingCompType({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingCompType({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingCompType({ loading: true }));
    const data = yield call(compTypeService.compTypeService.store, action.payload);
    yield put(Creators.storeResponseCompType(data));
    yield put(Creators.loadingCompType({ loading: false }));
    yield history.push('/configuration/compTypes');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingCompType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingCompType({ editLoading: true }));
    const data = yield call(compTypeService.compTypeService.edit, action.payload);
    yield put(Creators.editResponseCompType(data));
    yield put(Creators.loadingCompType({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingCompType({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingCompType({ loading: true }));
    const data = yield call(compTypeService.compTypeService.update, action.payload);
    yield put(Creators.updateResponseCompType(data));
    yield put(Creators.loadingCompType({ loading: false }));
    yield history.push('/configuration/compTypes');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(
      Creators.loadingCompType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingCompType({ deleteLoading: true }));
    const data = yield call(compTypeService.compTypeService.destroy, action.payload);
    yield put(Creators.destroyResponseCompType(data));
    yield put(Creators.loadingCompType({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    yield put(Creators.loadingCompType({ deleteLoading: false, successDestroy: false }));
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_COMP_TYPE, index);
  yield takeLatest(Types.STORE_REQUEST_COMP_TYPE, store);
  yield takeLatest(Types.EDIT_REQUEST_COMP_TYPE, edit);
  yield takeLatest(Types.UPDATE_REQUEST_COMP_TYPE, update);
  yield takeLatest(Types.DESTROY_REQUEST_COMP_TYPE, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    compTypes: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    compType: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const compTypeReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_COMP_TYPE]: index_response,
  [Types.STORE_RESPONSE_COMP_TYPE]: store_response,
  [Types.EDIT_RESPONSE_COMP_TYPE]: edit_response,
  [Types.UPDATE_RESPONSE_COMP_TYPE]: update_response,
  [Types.DESTROY_REQUEST_COMP_TYPE]: destroy_request,
  [Types.DESTROY_RESPONSE_COMP_TYPE]: destroy_response,
  [Types.LOADING_COMP_TYPE]: loading,
});
