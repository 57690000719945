import { deleteMethod, getMethod } from '../helpers';

export const index = (data) => {
  return getMethod(
    '/notifications?' +
    (`notifiable_id=${data.notifiable_id}`) +
    (`&complex_id=${data.complex_id}`) +
    (`&app_name=ineo`) +
    (`&size=${data.size}`) +
    (`&page=${data.page}`),
  );
};

export const markAsRead = (user) => {
  return getMethod(
    `/notifications/read/${user}?complex_id=${localStorage.getItem('complex_id')}`,
  );
};

export const unRead = (user) => {
  return getMethod(
    `/notifications/unread/${user}?complex_id=${localStorage.getItem('complex_id')}`,
  );
};


export const remove = (user) => {
  return deleteMethod(
    `/notifications/${user}?complex_id=${localStorage.getItem('complex_id')}`,
  );
};

