import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Icon, Image, Modal, Popup } from 'semantic-ui-react';
import { imgUrl } from '@/helpers';
import ModalConfirm from '@/components/ModalConfirm/ModalConfirm';
import { convertTicket, getParentIdIsAdvance } from '@/helpers/ticket';
import { reservationInformationState, reservationResponse } from '@/helpers/reservationFormatting';
import moment from 'moment';
import localization from 'moment/locale/fr';
import i18n from '../../../i18n';
import CustomDropdown from '../../CustomDropdown/CustomDropdown';
import { getArticleReservation } from '@/services/reservationService';
import { caisseResponseToTicket } from '../../../helpers/caisseFormatting';
import { errorToast } from '../../../helpers/customToast';
import { getPayments, getPaymentsOfExistTicket } from '../../../helpers/reservationFormatting';
import { valeur } from '../../../pages/CaissePage/CaissePage';
import { formatCodeCountry, nullString, phoneNumber } from '../../../helpers/formatting';
import caisseService from '../../../services/caisseService';
import {
  cumulArticlePayedAmountFromPaymentArticle ,
  setArticleWithoutNoteStatus,
  setArticleStatus
} from '@/helpers/ticket';

class AdvanceListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successAdvanceModalOpen: false,
      confirmTranferModalOpen: false,
      modalMsg: '',
      affectAdvanceToReservationModalOpen: false,
      confirmAffectAdvanceModalOpen: false,
      reservation_id: null,
      reservation: null,
      loading: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      this.setState({ affectAdvanceToReservationModalOpen: false, loading: false });
      // this.props.caisseRedirection('ticket', 0, 'advance', null);
      // this.props.getTickets({ page: 1, size: 10, status: [0], is_advance: 1, date: null });
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  renderConfirmTransferModal() {
    return (
      <ModalConfirm
        open={this.state.confirmTranferModalOpen}
        onClose={this.handleClose}
        title={'Confirmer le transfère'}
        message={
          <p>Êtes-vous sur de vouloir transférer le montant restant vers le crédit du compte client ? </p>
        }
        ok={() => {
          this.transferAdvance();
          this.setState({ confirmTranferModalOpen: false });
        }}
        cancel={() => this.setState({ confirmTranferModalOpen: false })}
      />
    );
  }

  renderSuccessAdvanceModal() {
    return (
      <Modal
        open={this.state.successAdvanceModalOpen}
        onClose={this.handleClose}
        size="mini"
        className="advance-modal withOpacity"
      >
        <span>{this.state.modalMsg}</span>
      </Modal>
    );
  }

  validateAdvance = async () => {
    let advance = convertTicket(this.props.advance);
    advance.status = 0;
    this.props.updateTicket(advance);

    //reset
    await this.setState({ successAdvanceModalOpen: true, modalMsg: 'Acompte validé' });
    setTimeout(
      function() {
        this.setState({ successAdvanceModalOpen: false });
      }.bind(this),
      1500,
    );
  };

  payAdvance = async () => {
    this.props.article_to_pay([], 0, 0);
    let advance = convertTicket(this.props.advance);
    await this.props.updateCurrentTicket(advance);
    this.props.caisse_redirection('home');
    this.props.select_family(getParentIdIsAdvance(this.props.menusData));
  };
  transferAdvance = async () => {
    let advance = convertTicket(this.props.advance);
    let detail = {
      user_id: advance.user_id,
      credit: parseFloat(advance.amount) - parseFloat(advance.payed_amount),
      is_advance: 1,
      complex_id: localStorage.getItem('complex_id'),
    };
    await this.props.storeCreditDetails(detail);
    advance.payed_amount = parseFloat(advance.amount);
    await this.props.updateTicket(advance);
    //reset
    await this.setState({ successAdvanceModalOpen: true, modalMsg: 'Crédit transféré' });
    setTimeout(
      function() {
        this.setState({ successAdvanceModalOpen: false });
      }.bind(this),
      1500,
    );
  };

  getAdvanceDetail = async (id) => {
    await this.props.getTicket(id);
    this.props.article_to_pay([], 0, 0);
    // let advance = convertTicket(this.props.advance);
    //  if (advance.status === 0) {
    //    this.props.caisseRedirection('ticket details', 0, 'advance', null);
    //  }
    //  if (advance.status === 1) {
    //    this.props.caisseRedirection('edit ticket', 1, 'advance', null);
    //  }
    //await this.props.updateCurrentTicket(advance);
    //await this.props.caisse_redirection('ticket details', null, 'advance', null);
  };

  renderConfirmAffectAdvanceModal() {
    return (
      <ModalConfirm
        open={this.state.confirmAffectAdvanceModalOpen}
        onClose={() => this.setState({ confirmAffectAdvanceModalOpen: false })}
        title={'Confirmer l\'affectation'}
        message={
          <p>Cet acompte est déjà utilisé, êtes-vous sur de l'affecter à une réservation? </p>
        }
        ok={() => {
          this.setState({ confirmAffectAdvanceModalOpen: false, affectAdvanceToReservationModalOpen: true });
        }}
        cancel={() => this.setState({ confirmAffectAdvanceModalOpen: false })}
      />
    );
  }

  generateATicket = async (response) => {
    try {
      let data = null;

      if (!response.reservation.ticket)
        data = await caisseResponseToTicket(response);
      else {
        let ticketById = await caisseService.caisseService.getTicket(response.reservation.ticket.id);
        if (ticketById.data)
          data = convertTicket(ticketById.data);
      }

      if (!data) errorToast(i18n.t('article_not_found'));

      let reservation = { ...response.reservation };

      if (data) {
        reservation.article_id = response.article ? response.article.id : null;
        let payments = data.hasOwnProperty('id') ? await getPaymentsOfExistTicket(response.article, reservation, data) : await getPayments(response.article, reservation);
        let currentTicket = {
          ...data,
          user: reservation.user,
          payed_amount: payments.types.length > 0 ? payments.types.reduce((acc, it) => parseFloat(it.amount) + acc, 0) : 0,
          payments: payments,
          article_reservation: response.article ? response.article.id : null,
          is_credit: 0,
          is_advance: 0,
          is_credit_account: 0,
          owner_id: reservation.owner_id,
          reservation,
        };

        // partial amount > payed //=> payed
        // articles
        if (reservation.advance_ticket) {
          //article status
          //select the article by id
          // for (let i = 0; i < currentTicket.articles.length; i++) {
          //   if (currentTicket.articles[i].id === response.article.id.toString() + valeur + '0') {
          //     let amounts = payments.articles.reduce((acc, it) => {
          //       if (it.article_id.toString() === response.article.id.toString()) {
          //         return (acc + parseFloat(it.amount));
          //       } else
          //         return acc;
          //     }, 0);
          //     currentTicket.articles[i].payed_amount = parseFloat(amounts).toFixed(2);
          //     currentTicket.articles[i].status =
          //       parseFloat(amounts) <
          //       parseFloat(response.article.total_price)
          //         ? 'partial'
          //         : 'payed';
          //   }

          // }

          currentTicket.articles.forEach(item => {
            if (item.id.toString() === response.article.id.toString()) {
              item.payed_amount = cumulArticlePayedAmountFromPaymentArticle(currentTicket, item.id);
              item.status = (item.parent.is_activity === 1 && item.child.is_res === 1) ? 
                setArticleWithoutNoteStatus(currentTicket, item) : 
                setArticleStatus(currentTicket, item);
            }
          });


          for (let i = 0; i < currentTicket.participants.length; i++) {
            if (currentTicket.participants[i].article_id.toString() === response.article.id.toString()) {
              let amounts = payments.participants.reduce((acc, it) => {
                if (it.user_index.toString() === currentTicket.participants[i].user_index.toString()) {
                  return (acc + parseFloat(it.amount));
                } else
                  return acc;
              }, 0);
              currentTicket.participants[i].payed_amount = parseFloat(amounts).toFixed(2);
              currentTicket.participants[i].status =
                parseFloat(currentTicket.participants[i].amount) >
                parseFloat(amounts)
                  ? amounts === 0 ? null : 'partial'
                  : 'payed';
            }
          }
        }

        if (reservation.advance_ticket) {
          delete reservation.advance_ticket.reservation;
          let convertedAdvance = convertTicket(reservation.advance_ticket);

          let responseAdvance = await caisseService.caisseService.updateTicket({
            ...convertedAdvance,
            status: 0,
           //  payed_amount: payments.types[payments.types.length - 1].amount,
          });


          if (currentTicket.id)
            this.props.updateTicket(currentTicket);
          else
            this.props.storeTicket(currentTicket);
        }
      }
    } catch (e) {
      console.log('error - AdvanceListItem: ', e);
      this.setState({ loading: false });
      errorToast(i18n.t('reservation_failed_updating'));
      throw new Error(e.message);
    }
  };

  affectAdvanceToReservation = async () => {
    try {
      this.setState({ loading: true });
      let advance = convertTicket({ ...this.props.advance });
      let reservation = reservationResponse(reservationInformationState(this.props.user.id), this.state.reservation);
      let date = reservation.information.date;
      reservation.information.advance_ticket = { ...advance };
      reservation.information.is_advance = 1;
      reservation.information.advance_id = advance.id;
      reservation.information.advance = advance.amount;
      let articleReservationPrice = await getArticleReservation({ id: this.state.reservation.id });

      if (articleReservationPrice.data.data.article) {
        // articleReservationPrice = articleReservationPrice.data.data.article.total_price;
        let data = articleReservationPrice.data.data;
        reservation.information.article_id = articleReservationPrice.data.data.article.id;

        data.reservation.advance_ticket = { ...this.props.advance };
        data.reservation.is_advance = 1;
        data.reservation.advance_id = this.props.id;
        data.reservation.advance = this.props.amount;
        data.reservation.ticket = this.state.reservation.ticket;
        await this.generateATicket(data);
        // advance.payed_amount = parseFloat(advance.amount) > parseFloat(articleReservationPrice) ?
        //   parseFloat(articleReservationPrice) : parseFloat(advance.amount);
        await this.props.updateReservation({ ...reservation, date });
        // this.props.updateTicket(advance);
        //reset


      } else {
        this.setState({ loading: false });
        errorToast(i18n.t('article_not_found'));
      }
    } catch (e) {
      this.setState({ loading: false });
      console.log('error from affectAdvanceToReservation', e);
    }
  };

  renderAffectAdvanceToReservationModal() {
    return (
      <Modal
        open={this.state.affectAdvanceToReservationModalOpen}
        onClose={() => !this.props.loading && this.setState({ affectAdvanceToReservationModalOpen: false })}
        size="small"
        className="modal-ticket"
      >
        <Modal.Content className="modal-content">
          <h3 className="modal-content-header">Affectation de l'acompte {this.props.advance.advance_number} </h3>
          <div className="block-credit-modal">
            <h5>Choisir une réservation de la liste</h5>
            <div className="btn-group">
              <CustomDropdown
                className={'date-dropdown regulation-input link-to-reservation-dropdown'}
                url={'/reservations'}
                param={//'&search_date=' + moment().format('YYYY-MM-DD') +
                  '&search_user=' + this.props.advance.user_id +
                  '&is_advance=0' +
                  '&ticket=false'+
                  '&search_activity=' +
                  (this.props.advance && this.props.advance.article ? this.props.advance.article.child.activity_id : '')
                }
                // condition={(itm) => (itm.advance_id === null)}
                onChange={(value, data) => this.setState({ reservation_id: value, reservation: data })}
                value={this.state.reservation_id}
                render={it => (
                  <>
                    <Image className="ticket-avatar avatar-cover"
                           src={it.user.avatar ? imgUrl + '/users/' + it.user.avatar : require('../../../assets/avatar.svg')}/>
                    <span>{it.user.firstName} {nullString(it.user.lastName).toUpperCase()} - {it.activity.name} <br/>
                  Le {moment(it.date).locale(i18n.language, localization).format('DD MMMM YYYY')} de {it.start_hour.split(':').join('H')} à {it.end_hour.split(':').join('H')}</span>
                  </>
                )}
              />
            </div>
          </div>
          <div className="modal-content-body">
            <Button icon="close" color="red" className="modal-btn-close" circular size="tiny"
                    onClick={() => this.setState({ affectAdvanceToReservationModalOpen: false })}
            />
            <span className="btn-group">
              <Button
                className="modal-delete-btn"
                loading={this.props.loading || this.props.loadingReservation || this.state.loading}
                disabled={!this.state.reservation_id}
                onClick={async () => this.affectAdvanceToReservation()}
              >
                Valider
              </Button>
              <Button className="modal-cancel-btn"
                      onClick={() => this.setState({ affectAdvanceToReservationModalOpen: false })}
              >
                Annuler
              </Button>
            </span>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    let color = 'rgba(11, 108, 255, 0.65)';
    let colorType = {backgroundColor: 'rgba(11, 108, 255, 0.65)'};
    if(this.props.type === 0) {
      colorType = {backgroundColor: 'rgb(0, 184, 118)'};
    }
    else if(this.props.type === 2){
      colorType = {backgroundColor: '#FF7070'};
    }
    return (
      <div
        className={this.props.caisse_redirection.value === 'ticket' ? 'advance-list-item' : 'advance-list-item-details'}
        style={{ color: color }}>
        {this.renderSuccessAdvanceModal()}
        {this.renderConfirmTransferModal()}
        {this.renderAffectAdvanceToReservationModal()}
        {this.renderConfirmAffectAdvanceModal()}
        <div className="ticket-family" style={colorType}/>
        <div className="ticket-information" style={{  position : 'relative',borderRight: '1px dotted ' + color }}>
            <div><b>N° {this.props.advance.advance_number}</b>
            <input id={this.props.advance.advance_number} defaultValue={this.props.advance.advance_number ? this.props.advance.advance_number : ''}
              style={{height : '0px' , zIndex : -1 , position : 'absolute'}} />
            <Popup
              content='Copié!'
              on='click'
              pinned
              trigger={
                <Icon name="copy" onClick={()=>{
                  let textToBeCopied = document.getElementById(this.props.advance.advance_number);
                  textToBeCopied.select();
                  document.execCommand('copy');
                }}/>
              }
            />
            </div>
            <span>{i18n.t('createdAt')} {moment(this.props.advance.created_at).locale(i18n.language, localization).format('DD-MM-YYYY à HH:mm')}</span>
            {this.props.advance.owner &&
              <span>
                {i18n.t('createdBy')} {this.props.advance.owner.firstName + ' ' + nullString(this.props.advance.owner.lastName).toUpperCase()}
              </span>
            }
        </div>
        {this.props.advance.user &&
          <div className="ticket-sub-family" style={{ borderRight: '1px dotted ' + color }}>
            <Image className="ticket-avatar avatar-cover"
                  src={this.props.advance.user.avatar ?
                    imgUrl + '/users/' + this.props.advance.user.avatar :
                    require('../../../assets/avatar.svg')} avatar/>
            <span>
              {this.props.advance.user_id && this.props.advance.user && (
                <>
                  {this.props.advance.user.firstName}<br/>
                  {nullString(this.props.advance.user.lastName).toUpperCase()}
                  <br/>
                  {formatCodeCountry(this.props.advance.user.country_code)  + phoneNumber(this.props.advance.user.mobile)}
                </>
              )}
            </span>
          </div>
        }
        {this.props.caisse_redirection.value === 'ticket' &&
        <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
          <b>Activité</b>
          <span>
              {this.props.advance.articles.length > 0 ? this.props.advance.articles[0].name : '-'}
            </span>
        </div>
        }
        <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
          <b>Montant</b>
          <span>
            {parseFloat(this.props.advance.amount).toFixed(2)}{localStorage.getItem('currency')}
          </span>
        </div>
        <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
          <b>Montant utilisé</b>
          <span>
            {parseFloat(this.props.advance.payed_amount).toFixed(2)}{localStorage.getItem('currency')}
          </span>
        </div>
        <div className="ticket-buttons">
          {this.props.type !== 2 &&
          <Button className="ticket-btn-detail" onClick={() => this.getAdvanceDetail(this.props.advance.id)}>
            Détail
          </Button>
          }
          {this.props.type === 0 &&
          <Button className="ticket-btn-edit"
            onClick={() => {
              if (parseFloat(this.props.advance.payed_amount) === 0) {
                this.setState({ affectAdvanceToReservationModalOpen: true });
              } else {
                this.setState({ confirmAffectAdvanceModalOpen: true });
              }
            }}
            disabled={!this.props.advance.show_reservation ||
              (parseFloat(this.props.advance.amount) === parseFloat(this.props.advance.payed_amount))}
          >
            Réservation
          </Button>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  storeTicket: (data) => dispatch({ type: 'STORE_TICKET_REQUEST', payload: data }),
  updateTicket: (data) => dispatch({ type: 'UPDATE_TICKET_REQUEST', payload: data }),
  storeCreditDetails: (data) => dispatch({ type: 'STORE_REQUEST_CREDIT', payload: data }),
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
  article_to_pay: (article, amount, rest) => dispatch({
    type: 'ARTICLE_TO_PAY_REQUEST',
    payload: { article, amount, rest },
  }),
  caisseRedirection: (value, status = null, type = null, date = null) => dispatch({
    type: 'CAISSE_REDIRECTION_REQUEST',
    payload: { value, status, type, date },
  }),
  updateReservation: (data) => dispatch({ type: 'UPDATE_REQUEST_RESERVATION', payload: data }),
  getArticleReservation: (id) => dispatch({ type: 'GET_RESERVATION_ARTICLE', payload: id }),
  getTickets: (data) => dispatch({ type: 'GET_TICKETS_REQUEST', payload: { ...data } }),
  select_family: (value) => dispatch({ type: 'SELECT_FAMILY_REQUEST', payload: value }),
  getTicket: (id, note_index = null) => dispatch({ type: 'GET_TICKET_REQUEST', payload: { id, note_index } }),
});

const mapStateToProps = (state) => {
  return {
    menusData: state.caisse.menusData,
    caisse_redirection: state.caisse.caisse_redirection,
    loading: state.caisse.appLoading,
    loadingReservation: state.reservation.loading,
    user: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvanceListItem));
