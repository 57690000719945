import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Image } from 'semantic-ui-react';
import { imgUrl } from '@/helpers';
import { formatCodeCountry, nullString, phoneNumber } from '../../../helpers/formatting';
import moment from 'moment';
import i18n from '@/i18n';
import localization from 'moment/locale/fr';

class ReloadListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  getReloadDetail = async (id) => {
    await this.props.getTicket(id);
    this.props.article_to_pay([], 0, 0);
  };

  renderDefaultPaymentType(payments, payed_amount) {
    let other = parseFloat(payed_amount);
    if (this.props.paymentTypes) {
      let default_types = this.props.paymentTypes.map((item, index) => {
        if (item.is_show === 1) {
          let payment_type_list = Object.entries(payments).length
            ? payments.filter((itm) => itm.pivot ? itm.pivot.payment_type_id.toString() === item.id.toString() :
              itm.payment_type_id.toString() === item.id.toString(),
            )
            : [];
          let amount = Object.entries(payment_type_list).length ?
            (payment_type_list[0].pivot ? payment_type_list[0].pivot.amount : payment_type_list[0].amount) : 0;
          other = parseFloat(other) - parseFloat(amount) > 0 ? parseFloat(other) - parseFloat(amount) : 0;

          return (
            <div
              key={index}
              className="ticket-price"
              style={{ borderRight: '1px dotted rgba(11, 108, 255, 0.65)' }}
            >
              <Image
                style={{ filter: 'invert(80%)' }}
                src={item.icon ? imgUrl + '/payments/' + item.icon : require('@/assets/other.svg')}
              />
              <span>
                {amount}
                {localStorage.getItem('currency')}
              </span>
            </div>
          );
        }
      });
      let other_types = (
        <div
          key={'other'}
          className="ticket-price"
          style={{ borderRight: '1px dotted rgba(11, 108, 255, 0.65)' }}
        >
          <Image src={require('../../../assets/other_white.svg')} style={{ filter: 'invert(80%)' }}/>
          <span>
            {other.toFixed(2)}
            {localStorage.getItem('currency')}
          </span>
        </div>
      );
      return [default_types, other_types];
    }
  }

  render() {
    let color = 'rgba(11, 108, 255, 0.65)';
    let colorType = {backgroundColor: 'rgba(11, 108, 255, 0.65)'};
    if(this.props.type === 0) {
      colorType = {backgroundColor: 'rgb(0, 184, 118)'};
    }
    else if(this.props.type === 2){
      colorType = {backgroundColor: '#FF7070'};
    }
    let { ticket } = this.props;
    return (
      <div className={this.props.caisse_redirection.value === 'ticket' ?
          'reload-list-item' : 'reload-list-item-details'
        }
        style={{ color: color }}>
        <div className="ticket-family" style={colorType}>
        </div>
        <div className="ticket-information" style={{ position: 'relative', borderRight: '1px dotted ' + color }}>
          <b>N° {ticket.number}</b>
          <span>{i18n.t('createdAt')} {moment(ticket.created_at).locale(i18n.language, localization).format('DD-MM-YYYY à HH:mm')}</span>
          {ticket.owner &&
          <span>
                  {i18n.t('createdBy')} {ticket.owner.firstName + ' ' + nullString(ticket.owner.lastName).toUpperCase()}
            </span>
          }
        </div>
        {ticket.user &&
        <div className="ticket-sub-family" style={{ borderRight: '1px dotted ' + color }}>
          <Image className="ticket-avatar avatar-cover" style={{ margin: '5px 0px 5px 15px' }}
                 src={ticket.user.avatar ?
                   imgUrl + '/users/' + ticket.user.avatar :
                   require('../../../assets/avatar.svg')} avatar/>
          <span>
                    {ticket.user_id && ticket.user && (
                      <>
                        {ticket.user.firstName}<br/>
                        {nullString(ticket.user.lastName).toUpperCase()}
                        <br/>
                        {formatCodeCountry(ticket.user.country_code) + phoneNumber(ticket.user.mobile)}
                      </>
                    )}
              </span>
        </div>
        }

        {this.props.caisse_redirection.value === 'ticket' &&
        <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
          {ticket.is_reload === 1 ? <b>Recharge</b> : <b>Abonnement</b>}
          <span>
            {parseFloat(ticket.amount).toFixed(2)}{localStorage.getItem('currency')}
          </span>
        </div>}
        <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
          {ticket.is_reload === 1 ? <b>Carte prépayée</b> : <b>Abonnement classique</b>}
          <span>
            {ticket.credit_subscriber_detail &&
            ticket.credit_subscriber_detail.subscriber &&
            ticket.credit_subscriber_detail.subscriber.subscription &&
            ticket.credit_subscriber_detail.subscriber.subscription.name}
          </span>
        </div>
        {this.props.caisse_redirection.value === 'ticket' && this.renderDefaultPaymentType(ticket.payments.types, ticket.payed_amount)}
        <div className="ticket-buttons">
          <Button className="ticket-btn-detail"
                  onClick={() => this.getReloadDetail(ticket.id)}>
            Détail
          </Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  caisseRedirection: (value, status = null, type = null, date = null) =>
    dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: { value, status, type, date } }),
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
  getTicket: (id, note_index = null) => dispatch({ type: 'GET_TICKET_REQUEST', payload: { id, note_index } }),
  article_to_pay: (article, amount, rest) => dispatch({
    type: 'ARTICLE_TO_PAY_REQUEST',
    payload: { article, amount, rest },
  }),
});

const mapStateToProps = (state) => {
  let paymentTypes = state.paymentMethod.paymentMethods;
  paymentTypes.sort(function(a, b) {
    return a.id - b.id;
  });
  return {
    paymentTypes: paymentTypes,
    caisse_redirection: state.caisse.caisse_redirection,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReloadListItem));
