import React, { useEffect } from "react";
import RightContainer from "@/components/RightContainer/RightContainer";
import "./MainLayout.css";
import useTitleNotification from "../../hooks/useTitleNotification";

/**
 * this layout contain the sidebar and the Navbar
 *how to use
 * <MainLayout>
 *     <YourComponent/>
 * </MainLayout>
 *
 * */
const MainLayout = React.memo((props) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const notification = useTitleNotification();
  const children = props.children;
  return (
    <RightContainer
      withMenu={props.type ? false : props.withMenu}
      toggleDarkMode={() => props.toggleDarkMode()}
      toggleSidemenu={() => {
        props.toggleSidemenu();
      }}
    >
      {children}
    </RightContainer>
  );
});

MainLayout.defaultProps = {
  withMenu: true,
  type: undefined,
};

export default MainLayout;
