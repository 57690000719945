import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import hourConfigsService from '../../services/hourConfigsService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingHourConfigs: ['data'],

  storeRequestHourConfigs: ['payload'],
  storeResponseHourConfigs: ['data'],

  editRequestHourConfigs: ['id'],
  editResponseHourConfigs: ['data'],

  updateRequestHourConfigs: ['payload'],
  updateResponseHourConfigs: ['data'],
});

// Initial state
const INITIAL_STATE = {
  hourConfigs: null,
  loading: false,
  addLoading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
};

//store
function* store(action) {
  try {
    yield put(Creators.loadingHourConfigs({ addLoading: true }));
    const data = yield call(hourConfigsService.hourConfigsService.store, action.payload);
    yield put(Creators.storeResponseHourConfigs(data));
    yield put(Creators.loadingHourConfigs({ addLoading: false }));
    yield history.push('/configuration/hourConfigs');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingHourConfigs({
        addLoading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingHourConfigs({ editLoading: true }));
    const data = yield call(hourConfigsService.hourConfigsService.edit, action.payload);
    yield put(Creators.editResponseHourConfigs(data));

    yield put(Creators.loadingHourConfigs({ editLoading: false }));
  } catch (e) {
    yield put(Creators.editResponseHourConfigs(null));
    yield put(Creators.loadingHourConfigs({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingHourConfigs({ addLoading: true }));
    const data = yield call(hourConfigsService.hourConfigsService.update, action.payload);
    yield put(Creators.updateResponseHourConfigs(data));
    yield put(Creators.loadingHourConfigs({ addLoading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/hourConfigs');
  } catch (e) {
    yield put(
      Creators.loadingHourConfigs({
        addLoading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.STORE_REQUEST_HOUR_CONFIGS, store);
  yield takeLatest(Types.EDIT_REQUEST_HOUR_CONFIGS, edit);
  yield takeLatest(Types.UPDATE_REQUEST_HOUR_CONFIGS, update);
}

// Reducer handlers
//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    hourConfigs: { ...action.data },
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const hourConfigsReducer = createReducer(INITIAL_STATE, {
  [Types.STORE_RESPONSE_HOUR_CONFIGS]: store_response,
  [Types.EDIT_RESPONSE_HOUR_CONFIGS]: edit_response,
  [Types.UPDATE_RESPONSE_HOUR_CONFIGS]: update_response,
  [Types.LOADING_HOUR_CONFIGS]: loading,
});
