import { call, put, takeLatest } from "redux-saga/effects";
import { createActions, createReducer } from "reduxsauce";
import creditService from "@/services/creditService";
import { errorToast , validToast } from "../../helpers/customToast";
import i18n from "../../i18n/index";

// Action types and creators
export const { Types, Creators } = createActions({
  loadingCredit: ["payload"],

  indexRequestCredit: ["payload"],
  indexResponseCredit: ["data"],

  storeRequestCredit: ["payload"],
  storeResponseCredit: ["data"],
});

// Initial state
const INITIAL_STATE = {
  credits: [],
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  successStore: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingCredit({ loading: true }));
    const data = yield call(creditService.creditService.index, action.payload);
    yield put(Creators.indexResponseCredit(data));
    yield put(Creators.loadingCredit({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingCredit({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingCredit({ successStore: false, loading: true }));
    const data = yield call(creditService.creditService.store, action.payload);
    yield put(Creators.storeResponseCredit(data));
    yield put(Creators.loadingCredit({ loading: false }));
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingCredit({ loading: false, errors: e.response.data.errors })
    );
    errorToast(i18n.t("failed_store"));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CREDIT, index);
  yield takeLatest(Types.STORE_REQUEST_CREDIT, store);
}

// Reducer handlers
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    credits: action.data.data,
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

const laoding_credit = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const creditReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_CREDIT]: index_response,
  [Types.STORE_RESPONSE_CREDIT]: store_response,
  [Types.LOADING_CREDIT]: laoding_credit,
});
