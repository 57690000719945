import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import MainMenuItem from './MainMenuItem';
import '../CustomCaisse.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from '@/i18n';
import ModalOpenCloseSession from '@/components/ModalConfirm/ModalOpenCloseSession';
import ModalListTicket from '../../ModalConfirm/ModalListTicket';
import moment from 'moment';
import { concludeSessionPrint, PrintPopup } from '../../../helpers/print';
import history from '../../../helpers/history';


//this array is  used to order the main menu by slug
let menuOrder = ['activites', 'location', 'boissons', 'snacking', 'restauration', 'acompte', 'credit', 'recharge'];

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      soldTh: 0,
      soldS: 0,
      ticketListModalOpen: false,
      endConcludeSessionModalOpen: false,
    };
  }

  componentDidMount() {
    //this.props.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.session &&
      this.props.session.session === 1 &&
      prevProps.session !== this.props.session &&
      prevState.endConcludeSessionModalOpen
    )
      this.setState({
        endConcludeSessionModalOpen: false,
        ticketListModalOpen: true,
        soldS: 0,
      });
  }

  renderFamily() {
    if (this.props.menusData) {
      let orderedMenu = Array.isArray(this.props.menusData) ? [...this.props.menusData] : [];
      let filterMenu = orderedMenu.filter(it => it.is_reload);
      orderedMenu.sort((a, b) => (menuOrder.indexOf(a.slug) - menuOrder.indexOf(b.slug)));
      orderedMenu = orderedMenu.filter(it => (!it.is_reload));
      orderedMenu = [...orderedMenu, ...filterMenu];

      return orderedMenu.map((item, index) => {
        return <MainMenuItem key={index}
                             id={item.id}
                             name={item.name}
                             image={item.avatar}
                             is_advance={item.is_advance === 1}
                             is_credit_account={item.is_credit === 1}
                             is_reload={item.is_reload === 1}
        />;
      });
    }
  }

  renderEndConcludeSessionModal() {
    return (
      <ModalOpenCloseSession
        open={this.state.endConcludeSessionModalOpen}
        onClose={() => this.setState({ endConcludeSessionModalOpen: false })}
        soldTh={this.state.soldTh}
        soldS={this.state.soldS}
        isSold={true}
        loading={this.props.loading}
        conclude={() => {
          this.props.closeSession({
            id: this.props.session.id,
            entered_amount: this.state.soldS,
            complex_id: localStorage.getItem('complex_id'),
            status: 2,
          });
        }}
        onChangeCalculator={(value) => this.setState({ soldS: value })}
      />
    );
  }

  renderTicketListModal() {
    return (
      <ModalListTicket
        open={this.state.ticketListModalOpen}
        onClose={() => this.setState({ ticketListModalOpen: false })}
        date={moment(new Date()).format('DD-MM-YYYY')}
        session={this.props.session}
        print={async () => {
          let data = this.props.session;
          let receipt = await concludeSessionPrint(data);
          PrintPopup(receipt);
        }}
      />
    );
  }

  render() {
    let style = { backgroundColor: 'transparent', color: '#FFFFFF' };
    return (
      <div className="caisse-main-menu-first">
        {this.renderEndConcludeSessionModal()}
        {this.renderTicketListModal()}
        <div className={'caisse-main-menu-top'}>{this.renderFamily()}</div>
        <div className={'caisse-main-menu-bottom'}>
          <Button
            className="main-menu-item main-menu-item-bottom"
            style={style}
            onClick={() => {
              this.props.decrementOccNb === null && this.props.updateCurrentTicket(null);
              this.props.caisse_redirection('ticket', 1, 'ticket', moment().format('YYYY-MM-DD'));
            }}
          >
            <Image src={require('../../../assets/ticket.svg')}/>
            <span>{i18n.t('tickets')}</span>
          </Button>
          <Button
            className="main-menu-item main-menu-item-bottom"
            style={style}
            onClick={() => this.props.caisse_redirection('reservation')}
          >
            <Image src={require('../../../assets/reservation.svg')}/>
            <span>{i18n.t('reservation')}</span>
          </Button>
          <Button
            className="main-menu-item main-menu-item-bottom"
            style={style}
            onClick={() => this.props.caisse_redirection('client')}
          >
            <Image src={require('../../../assets/client.svg')}/>
            <span>{i18n.t('client')}</span>
          </Button>
          <Button
            className="main-menu-item main-menu-item-bottom"
            style={style}
            onClick={() => {
              history.push('/closure');
            }}
          >
            <Image src={require('../../../assets/power.svg')}/>
            <span>{i18n.t('closure')}</span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
  caisse_redirection: (value, status = null, type = null, date = null) =>
    dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: { value, status, type, date } }),

  storeSession: (payload) =>
    dispatch({
      type: 'START_SESSION_REQUEST',
      payload,
    }),
  closeSession: (payload) =>
    dispatch({
      type: 'CLOSE_SESSION_REQUEST',
      payload,
    }),
  getSession: (payload) =>
    dispatch({
      type: 'GET_SESSION_REQUEST',
      payload,
    }),
});

const mapStateToProps = (state) => {
  return {
    menusData: state.caisse.menusData,
    selectFamily: state.caisse.selectFamily,
    selectSubFamily: state.caisse.selectSubFamily,
    selectArticle: state.caisse.selectArticle,
    session: state.session.session,
    loading: state.session.loading,
    decrementOccNb: state.caisse.decrementOccNb,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainMenu));
