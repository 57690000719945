import { call, put, takeLatest } from "redux-saga/effects";
import { createActions, createReducer } from "reduxsauce";
import * as UserService from "../../services/userService";
import { errorToast, validToast } from "../../helpers/customToast";
import history from "../../helpers/history";
import i18n from "../../i18n/index";

// Action types and creators
export const { Types, Creators } = createActions({
  loadingUser: ["payload"],

  storeRequestUser: ["payload"],
  indexRequestUser: ["payload"],

  searchRequestUser: ["payload"],
  searchResponseUser: ["payload"],

  checkAvailableRequestUser: ["payload"],
  joinRequestUser: ["payload"],

  editRequestUser: ["payload"],
  updateRequestUser: ["payload"],
  destoryRequestUser: ["payload"],

  storeClientRequestUser: ["payload"],
  storeClientResponse: ["data"],
  updateClientRequestUser: ["payload"],
  clientPermissionRequest: [],

  responseUser: ["payload"],
  responseEditUser: ["payload"],
  errorUser: ["payload"]

});

// Initial state
const INITIAL_STATE = {
  users: [],
  user: null,
  firstName: "",
  lastName: "",
  selected: {},
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  client: null,
  clientPermission: [],
  deleteSuccess: false,
  reset: false,
  added: null,
  loadingPermission: false,
  loading: false,
  loadingCheck: false,
  loadingJoin: false,
  editLoading: false,
  updateLoading: false,
  deleteLoading: false,
  joined: false,
  error: null,
  check: null,
  updateCLientSuccess: false,
};

function* store(action) {
  try {
    yield put(Creators.loadingUser({ loading: true, error: null, added: null }));
    const data = yield call(UserService.store, action.payload);
    yield put(Creators.loadingUser({ loading: false }));
    validToast(i18n.t("successful_store"));
    history.push("/configuration/users");
  } catch (e) {
    yield put(Creators.loadingUser({ loading: false }));
    yield put(Creators.errorUser(e.response.data.errors));
    errorToast(i18n.t("failed_store"));
  }
}

function* update(action) {
  try {
    yield put(Creators.loadingUser({ updateLoading: true, error: null }));
    const data = yield call(UserService.update, action.payload);
    yield put(Creators.loadingUser({ updateLoading: false }));
    validToast(i18n.t("successful_update"));
    history.push("/configuration/users");
  } catch (e) {
    yield put(Creators.loadingUser({ updateLoading: false }));
    yield put(Creators.errorUser(e.response.data?.errors));
    errorToast(i18n.t("failed_update"));
  }
}

function* edit(action) {
  try {
    yield put(Creators.loadingUser({ editLoading: true }));
    const response = yield call(UserService.edit, action.payload);
    yield put(Creators.responseEditUser({ selected: response.data.data }));
    yield put(Creators.loadingUser({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingUser({ editLoading: false, editError: false }));
    history.push("/error-404");
  }
}

function* destory(action) {
  try {
    yield put(Creators.loadingUser({ deleteLoading: true, deleteSuccess: false }));
    const data = yield call(UserService.destroy, action.payload);
    yield put(Creators.loadingUser({ deleteLoading: false, deleteSuccess: true }));
    validToast(i18n.t("successful_delete"));
  } catch (e) {
    errorToast(i18n.t("failed_delete"));
    yield put(
      Creators.loadingUser({
        deleteLoading: false,
        deleteSuccess: true,
        error: true
      })
    );
  }
}

function* index(action) {
  try {
    yield put(Creators.loadingUser({ loading: true }));
    const data = yield call(UserService.index, action.payload);
    yield put(Creators.responseUser(data));
    yield put(Creators.loadingUser({ loading: false }));
  } catch (e) {
    yield put(Creators.errorUser());
    yield put(Creators.loadingUser({ loading: false }));
  }
}

function* search(action) {
  try {
    yield put(Creators.loadingUser({ loading: true }));
    const data = yield call(UserService.search, action.payload);
    yield put(Creators.searchResponseUser(data));
    yield put(Creators.loadingUser({ loading: false }));
  } catch (e) {
    yield put(Creators.errorUser());
    yield put(Creators.loadingUser({ loading: false }));
  }
}


function* clientPermission(action) {
  try {
    yield put(Creators.loadingUser({ loadingPermission: true }));
    const data = yield call(UserService.clientPermission);
    yield put(
      Creators.loadingUser({
        loadingPermission: false,
        clientPermission: data.data.data
      })
    );
  } catch (e) {
    yield put(Creators.loadingUser({ loadingPermission: false }));
  }
}

function* storeClient(action) {
  try {
    yield put(Creators.loadingUser({ loading: true, error: null, client: null }));
    const data = yield call(UserService.storeClient, action.payload);
    yield put(Creators.loadingUser({ loading: false }));
    yield put(Creators.storeClientResponse(data));
    validToast(i18n.t("successful_store"));
    if (!action.payload.hasOwnProperty("checkout") && !action.payload.hasOwnProperty("redirection")) {
      history.push("/clients");
    }

  } catch (e) {
    yield put(Creators.loadingUser({ loading: false, client: null }));
    yield put(Creators.errorUser(e.response.data.errors));
    errorToast(i18n.t("failed_store"));
  }
}

function* updateClient(action) {
  try {
    yield put(Creators.loadingUser({ loading: true, error: null, updateClientSuccess: false }));
    const data = yield call(UserService.updateClient, action.payload);
    yield put(Creators.loadingUser({ loading: false, updateCLientSuccess: true }));
    validToast(i18n.t("successful_update"));
    if (!action.payload.hasOwnProperty("checkout") && !action.payload.hasOwnProperty("redirection")) {
      history.push("/clients");
    }
  } catch (e) {
    yield put(Creators.loadingUser({ loading: false }));
    yield put(Creators.errorUser(e.response.data.errors));
    errorToast(i18n.t("failed_update"));
  }
}

function* check(action) {
  try {
    yield put(Creators.loadingUser({ loadingCheck: true, check: null }));
    const data = yield call(UserService.checkIsAvailable, action.payload);
    yield put(Creators.loadingUser({ loadingCheck: false, check: data.data }));
    //validToast(i18n.t('successful_update'));
    //history.push('/configuration/users');
  } catch (e) {
    yield put(Creators.errorUser({ loadingCheck: false, check: null }));
    errorToast(i18n.t("failed_check"));
  }
}

function* join(action) {
  try {
    yield put(Creators.loadingUser({ loadingJoin: true, joined: false }));
    const data = yield call(UserService.joinToComplex, action.payload);
    yield put(Creators.loadingUser({ loadingJoin: false, joined: true }));
    validToast(i18n.t("successful_join"));
    if (action.payload.isClient)
      history.push("/crm");
    else if(action.payload.isClient === false)
      history.push("/configuration/users");
  } catch (e) {
    yield put(Creators.errorUser({ loadingJoin: false }));
    yield put(Creators.errorUser());
    errorToast(i18n.t("failed_join"));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_USER, index);
  yield takeLatest(Types.SEARCH_REQUEST_USER, search);
  yield takeLatest(Types.STORE_REQUEST_USER, store);
  yield takeLatest(Types.EDIT_REQUEST_USER, edit);
  yield takeLatest(Types.UPDATE_REQUEST_USER, update);
  yield takeLatest(Types.DESTORY_REQUEST_USER, destory);
  yield takeLatest(Types.CLIENT_PERMISSION_REQUEST, clientPermission);
  yield takeLatest(Types.STORE_CLIENT_REQUEST_USER, storeClient);
  yield takeLatest(Types.UPDATE_CLIENT_REQUEST_USER, updateClient);
  yield takeLatest(Types.CHECK_AVAILABLE_REQUEST_USER, check);
  yield takeLatest(Types.JOIN_REQUEST_USER, join);
}

// Reducer

const user_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    users: [...action.payload.data.data],
    last: action.payload.data.last_page,
    page: action.payload.data.current_page,
    total: action.payload.data.total,
    size: action.payload.data.per_page,
    from: action.payload.data.from
  };
};

const search_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    users: [...action.payload.data.data],
    last: action.payload.data.last_page,
    page: action.payload.data.current_page,
    total: action.payload.data.total,
    size: action.payload.data.per_page,
    from: action.payload.data.from
  };
};

const user_response_edit = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    ...action.payload
  };
};

const store_client = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    client: action.data.data.data
  };
};

const user_failed = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: action.payload
  };
};

const user_loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
    error: null
  };
};

export const userReducer = createReducer(INITIAL_STATE, {
  [Types.RESPONSE_USER]: user_response,
  [Types.SEARCH_RESPONSE_USER]: search_response,
  [Types.RESPONSE_EDIT_USER]: user_response_edit,
  [Types.LOADING_USER]: user_loading,
  [Types.ERROR_USER]: user_failed,
  [Types.STORE_CLIENT_RESPONSE]: store_client
});
