import React, { useEffect, useState } from 'react';
import { Image, Input } from 'semantic-ui-react';
import '../../CustomCaisse.css';
import { imgUrl } from '@/helpers';
import CustomInput from '../../../CustomInput/CustomInput';

const PaymentInput = (props) => {
  const [value, setValue] = useState();
  const [changing, setChanging] = useState(true);

  useEffect(() => {
    if (!changing) setChanging(true);
  }, [props.getValue]);

  return (
    <div
      className={'payment-block-input ' + (props.className ? props.className : ' ') + (props.disabled ? ' payment-block-input-disabled' : ' ')}
      style={props.style}>
      <span className="payment-block-input-left">
        <Input
          min={1}
          type={'number'}
          name={props.name}
          disabled={!!props.disabled}
          value={changing ? props.value.toString() === '0' ? '' : props.value : props.value}
          onChange={(e) => {
            setChanging(false);
            props.onChange(e);
          }}
        />
      </span>
      <span
        className="payment-block-input-right"
        onClick={(e) => {
          if (!props.disabled)
            props.onChange({ target: { value: props.getValue } });
        }}
      >
        <span>{props.title}</span>
        <Image
          src={props.image ? imgUrl + '/payments/' + props.image : require('@/assets/other.svg')}
        />
      </span>
      {!!props.disabled && <span className={'alert-note'}>
            <Image
              src={require('../../../../assets/division.svg')}
            />

      </span>}
    </div>
  );
};

PaymentInput.defaultProps = {
  disabled: false,
};

export default PaymentInput;
