import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";
import {
  conclusionTranslate,
  conclusionClassName,
} from "../../helpers/reservationFormatting";
import "./style.css";

const ConclusionBtn = ({ conclusion, checked = false, onClick = () => {} }) => {
  const { t } = useTranslation();
  return (
    <Button
      className={conclusionClassName(conclusion)}
      circular
      size="mini"
      onClick={onClick}
    >
      {checked && <Icon name="check" />}
      {t(conclusionTranslate(conclusion))}
    </Button>
  );
};

export default ConclusionBtn;
