import React, { useCallback, useEffect } from "react";
import { Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Icon } from "semantic-ui-react";

import { CustomSpinner } from "./components/LazyLoading/LazyLoading";
import GlobalExport from "./components/GlobalExport/GlobalExport";

import Routes from "@/routes/index";
import history from "./helpers/history";
import withClearCache from "./ClearCache";
import { switchLanguageInHtml, wsUrl } from "./helpers";
import useEcho from "./hooks/useEcho";
import useListener from "./hooks/useListener";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

const CloseButton = ({ closeToast }) => (
  <Icon name={"close"} onClick={closeToast} />
);

const ClearCacheComponent = withClearCache(Main);

console.warn = () => {};

//override default localStorage function
let originalSetItem = localStorage.setItem;
let originalRemoveItem = localStorage.removeItem;

localStorage.setItem = function (key, value) {
  let event = new Event("itemInserted", { composed: true });

  event.value = value;
  event.key = key;

  document.dispatchEvent(event);

  originalSetItem.apply(this, arguments);
};

localStorage.removeItem = function (key) {
  let event = new Event("itemRemoved", { composed: true });

  event.key = key;

  document.dispatchEvent(event);

  originalRemoveItem.apply(this, arguments);
};

function Main() {
  const dispatch = useDispatch();
  const getMe = useCallback(() => dispatch({ type: "ME_REQUEST" }), [dispatch]);
  const meFailed = useCallback(() => dispatch({ type: "ME_FAILED" }), [
    dispatch,
  ]);
  const checkSession = useCallback(
    (payload) => dispatch({ type: "CHECK_SESSION_REQUEST", payload }),
    [dispatch]
  );
  const getTicketInformations = useCallback(
    (payload) =>
      dispatch({
        type: "INDEX_REQUEST_TICKET_INFO",
        payload,
      }),
    [dispatch]
  );

  const getCheckoutConfiguration = useCallback(
    (payload) =>
      dispatch({
        type: "INDEX_REQUEST_CONFIGURATION",
        payload,
      }),
    [dispatch]
  );

  const getPlanningConfiguration = useCallback(
    (payload) =>
      dispatch({
        type: "INDEX_REQUEST_CONFIGURATION_PLANNING",
        payload,
      }),
    [dispatch]
  );

  const getUnreadNotification = useCallback(
    (payload) =>
      dispatch({
        type: "GET_UNREAD_NOTIFICATIONS_REQUEST",
        payload,
      }),
    [dispatch]
  );

  const token = useSelector((state) => state.auth.token);
  const sessionLoading = useSelector((state) => state.session.checkLoading);
  const user = useSelector((state) => state.auth.user);
  let loading = useSelector((state) => state.auth.meLoading);
  let selected = useSelector((state) => state.auth.selected);

  const echo = useEcho({ token, link: wsUrl });

  const listener = useListener({ selected, user, echo });

  useEffect(() => {
    // check if localStorage lang is set by default
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "fr");
      document.querySelector("#root").classList.remove("container-ar");
      document.querySelector("html");
    } else if (localStorage.getItem("lang") === "ar") {
      document.querySelector("#root").classList.add("container-ar");
    }
    switchLanguageInHtml(localStorage.getItem("lang"));
  }, []);

  useEffect(() => {
    //check if the token is register inside the localStorage
    if (token === null) {
      let localToken = localStorage.getItem("token");
      if (localToken !== null && localToken !== "") {
        getMe();
      } else {
        meFailed();
      }
    }
  }, [getMe, meFailed, token]);

  useEffect(() => {
    if (user.id) {
      const getCheckoutConfigurationPromise = new Promise((resolve) => {
        resolve(
          getCheckoutConfiguration({
            page: 1,
            size: 1,
            complex_id: localStorage.getItem("complex_id"),
          })
        );
      });
      const getPlanningConfigurationPromise = new Promise((resolve) => {
        resolve(
          getPlanningConfiguration({
            page: 1,
            size: 1,
            complex_id: localStorage.getItem("complex_id"),
          })
        );
      });
      const getCheckoutSessionPromise = new Promise((resolve) => {
        resolve(
          checkSession({
            user_id: user.id,
            complex_id: localStorage.getItem("complex_id"),
          })
        );
      });
      const getTicketInformationsPromise = new Promise((resolve) => {
        resolve(
          getTicketInformations({
            page: 1,
            size: 1,
            complex_id: localStorage.getItem("complex_id"),
          })
        );
      });
      const getUnreadNotificationPromise = new Promise((resolve) => {
        resolve(getUnreadNotification(user?.id));
      });
      Promise.all([
        getCheckoutConfigurationPromise,
        getPlanningConfigurationPromise,
        getCheckoutSessionPromise,
        getTicketInformationsPromise,
        getUnreadNotificationPromise,
      ]);
    }
  }, [
    user,
    checkSession,
    selected,
    getTicketInformations,
    getCheckoutConfiguration,
    getUnreadNotification,
    getPlanningConfiguration,
  ]);

  return (
    <>
      {loading || sessionLoading ? (
        <CustomSpinner />
      ) : (
        <Router history={history}>
          <Routes />
        </Router>
      )}
      <ToastContainer
        position={"top-right"}
        draggable={false}
        autoClose={4000}
        closeButton={<CloseButton />}
      />
      <GlobalExport />
    </>
  );
}

function App() {
  useEffect(() => {
    // Out side  change form other tabs
    window.addEventListener("storage", (e) => {
      if ((e.key === "token" || e.key === "complex_id") && !document.hasFocus())
        window.location.reload();
    });

    // from local changes :empty for now
    window.addEventListener("itemInserted", (e) => {}, true);
    // from local changes :empty for now
    window.addEventListener("itemRemoved", (e) => {}, true);
  }, []);

  return <ClearCacheComponent />;
}

export default App;
