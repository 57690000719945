import React from 'react';
import './SubscriptionModal.css';
import { Button, Form, Grid, Icon, Image, Input, Loader, Modal, Pagination } from 'semantic-ui-react';
import moment from 'moment';
import { formatCodeCountry, normalDate, nullString, phoneNumber, randomstring } from '../../../helpers/formatting';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import CustomInput from '../../CustomInput/CustomInput';
import { Creators as SubscribersCreators } from '../../../store/ducks/subscriber';
import { valeur } from '../../../pages/CaissePage/CaissePage';
import { DEFAULT_COUNTRY_CODE } from '../../../utils/countryCode';
import { handleNumberInputChange } from '../../../helpers/operation';

class SubscriptionModal extends React.Component {
  constructor(props) {
    super(props);
    let somePassword = randomstring();
    this.state = {
      modalContent:
        (props.input && props.input.subscriber_id) ||
        (this.props.player && this.props.player.user_id)
          ? 'affect subscription'
          : null,
      search_player: '',
      user: {
        id: '',
        firstName: '',
        lastName: '',
        mobile: '',
        country_code: DEFAULT_COUNTRY_CODE,
        email: '',
      },
      payment_type_list: [],
      subscription_credit_id: null,
      subscriber: null,
      subscriber_id: props.input && props.input.subscriber_id,
      complexes: Number(localStorage.getItem('complex_id')),
      teams: [],
      capitaine: [],
      password: somePassword,
      password_confirmation: somePassword,
      pending_ticket: '',
      amount: '',
      amount2: props.input ? props.input.amount : 0,
      subscription: '',
      solde: '',
      inputAmount: props.input.amount,
      expire: '',
      errors: {
        firstName: null,
        lastName: null,
        email: null,
      },
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.handleChangeFloat = this.handleChangeFloat.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  componentDidMount() {
    if (this.props.player && this.props.player.user_id && !this.props.input.subscriber_id) {
      this.props.editPlayer(this.props.player.user_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      let user = {
        id: this.props.user.id,
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        email: this.props.user.email,
        mobile: this.props.user.mobile,
        country_code: this.props.user.country_code,
      };
      this.setState({
        ...this.state,
        user: user,
        modalContent: 'affect subscription',
        subscriber: null,
        subscriber_id: null,
        amount: '',
        amount2: '',
        subscription: '',
        solde: '',
        inputAmount: '',
        expire: '',
      });
    }
    if (
      !this.props.loading &&
      JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)
    ) {
      let errors = {
        firstName: null,
        lastName: null,
        email: null,
      };
      for (let index in this.props.errors) {
        errors[index] = { content: this.props.errors[index][0] };
      }
      this.setState({ errors });
    }
  }

  onChangeSubscriber = (id, data) => {
    if (!id) {
      this.setState({ amount2: '', subscriber: null, subscriber_id: null });
    } else {
      if (data.subscription.subscription_type.is_reload === 1)
        this.setState({ amount2: '', subscriber: data, subscriber_id: id });
      else
        this.setState({
          amount2: parseFloat(this.props.restAmount),
          subscriber: data,
          subscriber_id: id,
        });
    }
  };

  onChangeHandle(event, eventMobile = null) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (eventMobile)
      this.setState({
        [eventMobile.target.name]: eventMobile.target.value,
      });
  }

  affectPlayer() {
    let user = { ...this.state.user };
    let exist_player = null;
    let filter_participants = this.props.currentTicket.participants.filter((itm) => itm.user_id);
    exist_player = filter_participants.map((participant, i) => {
      if (participant.user_id) {
        return participant.user_id;
      }
    });
    //don't affect player after payment with his carte if this player is already exist
    if (!exist_player.includes(user.id)) {
      let currentTicket = { ...this.props.currentTicket };
      currentTicket.participants.map((item, i) => {
        if (this.props.player && item.user_index === this.props.player.user_index) {
          item['user_id'] = user.id;
          item['firstName'] = user.firstName;
          item['lastName'] = user.lastName;
        }
      });
      this.props.updateCurrentTicket(currentTicket);
    }
  }

  renderAffectSubscription = () => {
    let creditsOptions = [];
    let hourTypesArray = [];
    let restAmount = parseFloat(this.props.restAmount);
    if (restAmount < 0) restAmount = 0;
    let currency = localStorage.getItem('currency');
    let item = this.state.subscriber;
    if (item && item.subscription) {
      creditsOptions = item.subscription.credit_bonuses.map((it) => {
        return {
          text:
            'credit: ' +
            it.credit +
            currency +
            (it.bonus ? ' + ' + (it.bonus ? 'bonus:' + it.bonus + currency : '') : ''),
          key: it.id,
          value: it.id,
        };
      });
    }
    let isAvaible = this.state.subscriber !== null;
    let selectedArticle = this.props.currentTicket.articles.filter((it) =>
      this.props.selectedArticles.includes(it.id.toString().split(valeur)[0]),
    );
    if (selectedArticle.length > 0) {
      selectedArticle.forEach((it) => {
        if (this.state.subscriber) {
          let hasActivity = this.state.subscriber.subscription.activities.filter(
            (item) => item.id.toString() === it.child.activity_id.toString(),
          );
          if (hasActivity.length === 0) isAvaible = false;
        }
        if (it?.hourType)
          hourTypesArray.push(it.hourType.id);
      });
    }
    return (
      <Form>
        {this.props.editLoading ? (
          <Loader/>
        ) : (
          <>
            <Grid columns={2}>
              <Grid.Column className="player-input">
                <CustomInput
                  name={'lastName'}
                  label={i18n.t('last_name')}
                  type={'text'}
                  value={this.state.user.lastName}
                />
                <CustomInput
                  name={'firstName'}
                  label={i18n.t('first_name')}
                  type={'text'}
                  value={this.state.user.firstName}
                />
                <CustomInput
                  name={'mobile'}
                  label={i18n.t('mobile')}
                  type={'phone'}
                  value={this.state.user.mobile}
                  countrycode={this.state.user.country_code}
                />
                <CustomInput
                  name={'email'}
                  label={i18n.t('email')}
                  type={'text'}
                  value={this.state.user.email}
                />
                <CustomInput
                  name={'pending_ticket'}
                  label={i18n.t('pending_ticket')}
                  type={'text'}
                  value={this.props.currentTicket.number}
                />
              </Grid.Column>
              <Grid.Column className="player-input">
                <CustomInput
                  name={'abonnement'}
                  get={'/subscribers'}
                  url={'/subscribers'}
                  param={
                    '&search_activities=[' +
                    this.props.activities.join(',') + ']' +
                    '&search_user=' + this.state.user.id +
                    (hourTypesArray.length > 0 ?
                        '&search_hour_types=[' + hourTypesArray.join(',') + ']' :
                        ''
                    ) +
                    (this.props.subscriptionModalOption === 'subscription'
                      ? '&is_reload=0'
                      : '&is_reload=1') +
                    '&is_expired=0'
                  }
                  label={i18n.t('subscriptions')}
                  noData={i18n.t('empty_data')}
                  type={'custom-dropdown'}
                  render={(it) => (
                    <>
                      <span>{it.subscription.name}</span>
                    </>
                  )}
                  value={this.state.subscriber_id}
                  refresh={this.props.reloadUpdated}
                  onInitial={(data) => {
                    data && this.setState({ subscriber: data });
                    if (
                      data &&
                      !this.state.user.id &&
                      (!this.state.user.email || !this.state.user.mobile)
                    ) {
                      let user = {
                        id: data.user.id,
                        firstName: data.user.firstName,
                        lastName: data.user.lastName,
                        mobile: data.user.mobile,
                        email: data.user.email,
                      };
                      this.setState({
                        user: user,
                      });
                    }
                  }}
                  onChange={this.onChangeSubscriber}
                />
                <span className="amount-bloc">
                  {this.state.subscriber &&
                  this.state.subscriber.subscription.subscription_type.is_reload === 1 && (
                    <>
                        <span>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width={6} verticalAlign={'middle'}>
                                <label className="form-label">{i18n.t('solde')}</label>
                              </Grid.Column>
                              <Grid.Column width={10}>
                                <Input
                                  name="solde"
                                  type={'text'}
                                  value={
                                    this.state.subscriber
                                      ? parseFloat(this.state.subscriber.total_balance).toFixed(2) +
                                      ' ' +
                                      currency
                                      : ''
                                  }
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </span>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={6} verticalAlign={'middle'}>
                            <label className="form-label">{i18n.t('expire')}</label>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            <Input
                              name="expire"
                              type={'text'}
                              value={
                                this.state.subscriber ? this.state.subscriber.expiry_date : ''
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </>
                  )}
                </span>
                {this.state.subscriber &&
                moment(normalDate(this.state.subscriber.expiry_date)).diff(moment(), 'days') <
                0 && <span className={'error-msg'}>{i18n.t('subscription-expired')}</span>}
                <br/>
                {/* {this.state.subscriber &&
                  moment(normalDate(this.state.subscriber.expiry_date)).diff(moment(), 'days') >= 0 &&
                  this.state.subscriber.subscription.subscription_type.is_reload === 1 && (
                  <span className="amount-bloc amount-bloc-reload">
                      <span>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={6} verticalAlign={'middle'}>
                              <label className="form-label">{i18n.t('amount')}</label>
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <CustomDropdown
                                name="amount"
                                type={'text'}
                                value={this.state.subscription_credit_id}
                                onChange={(id, data) =>
                                  this.setState({ subscription_credit_id: id })
                                }
                                fetching={false}
                                keyword={'key'}
                                render={(it) => <span>{it.text}</span>}
                                options={creditsOptions}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </span>
                      <Button
                        loading={this.props.loadingDetail}
                        className="btn-inline-icon"
                        onClick={() => {
                          let valid = this.state.subscription_credit_id !== null;
                          if (valid) {
                            let selected = item.subscription.credit_bonuses.filter(
                              (it) => it.id === this.state.subscription_credit_id,
                            );
                            let credits = { bonus: 0, credit: 0 };
                            if (selected.length > 0)
                              credits = {
                                credit: selected[0].credit,
                                bonus: selected[0].bonus,
                              };

                            let data = {
                              date: moment().format('DD-MM-YYYY'),
                              label: 'reload_credit',
                              credit: credits.credit,
                              bonus: credits.bonus,
                              payment_type_id: item.payment_type.id,
                              subscriber_id: item.id,
                            };
                            this.setState({ subscription_credit_id: null });
                            this.props.reload(data);
                          }
                        }}
                      >
                        <Image src={require('../../../assets/recharge.svg')}/>
                        <span className={'reload-span'}>{i18n.t('reload')}</span>
                      </Button>
                    </span>
                )} */}

                {this.state.subscriber &&
                this.state.subscriber.subscription.subscription_type.is_reload === 1 && (
                  <CustomInput
                    type={'number'}
                    name={'amount2'}
                    label={
                      i18n.t('deserved_amount') + ' (' + restAmount.toFixed(2) + currency + ')'
                    }
                    value={this.state.amount2}
                    onChange={(e) => {
                      if (this.state.subscriber) {
                        //   (parseFloat(this.state.subscriber.solde) >= parseFloat(e.target.value))) {
                        this.handleChangeFloat(e);
                      }
                    }}
                  />
                )}
                {parseFloat(this.props.restAmount) < 0 ? <p className={'modal-p-error'}>{i18n.t('sold-excess')}</p> :
                  (this.state.subscriber &&
                    this.state.subscriber.subscription.subscription_type.is_reload === 1 &&
                    parseFloat(this.state.subscriber.total_balance.toFixed(2)) < parseFloat(this.state.amount2)) && (
                  <p className={'modal-p-error'}>{i18n.t('sold-insufficient')}</p>
                )}

                {item &&
                (!item.document ||
                  !item.document.cin ||
                  !item.document.folder ||
                  !item.document.certificate) && (
                  <div className={'subscriber-msg'}>
                      <span className={'form-label'}>
                        <Icon name={'warning sign'} size={'large'} color={'red'}/>
                        {this.checkDocument(item.document)
                          ? i18n.t('doc-messing')
                          : i18n.t('docs-messings')}
                      </span>
                    <div>
                        <span>
                          <Icon
                            name={'circle'}
                            color={item.document && item.document.cin ? 'green' : 'red'}
                          />{' '}
                          {i18n.t('cni')}
                        </span>
                      <span>
                          <Icon
                            name={'circle'}
                            color={item.document && item.document.folder ? 'green' : 'red'}
                          />{' '}
                        {i18n.t('folder')}
                        </span>
                      <span>
                          <Icon
                            name={'circle'}
                            color={item.document && item.document.certificate ? 'green' : 'red'}
                          />{' '}
                        {i18n.t('certificate')}
                        </span>
                    </div>
                  </div>
                )}
              </Grid.Column>
            </Grid>
            {!isAvaible && this.state.subscriber !== null && (
              <Grid columns={2}>
                <Grid.Column className="player-input">
                  <p className={'modal-p-error'}>
                    {i18n.t('subscription_selected_does_not_match_activity_selected')}
                  </p>
                </Grid.Column>
              </Grid>
            )}
            <div className="group-btn-edit-player">
              {this.props.type === 'client' && (
                <Button
                  className="btn-select"
                  content={i18n.t('cancel')}
                  onClick={() => {
                    this.handleClose();
                  }}
                />
              )}

              <Button
                disabled={
                  this.state.amount2 === '' ||
                  parseFloat(this.state.amount2) <= 0 ||
                  parseFloat(this.props.restAmount) < 0 ||
                  (this.state.subscriber &&
                    this.state.subscriber.subscription.subscription_type.is_reload === 1 &&
                    parseFloat(this.state.subscriber.total_balance.toFixed(2)) < parseFloat(this.state.amount2)) ||
                  !isAvaible
                }
                className="btn-inline-icon btn-payment"
                onClick={() => {
                  let { amount2, subscriber } = this.state;
                  let label = '';
                  let subscriber_detail = {
                    expense: parseFloat(amount2).toFixed(2),
                    subscriber_id: subscriber.id,
                    label,
                    date: moment().format('DD-MM-YYYY'),
                  };
                  if (this.props.input.subscription_credit_id)
                    subscriber_detail.id = this.props.input.subscription_credit_id;

                  this.props.onPay(subscriber, subscriber_detail);

                  if (
                    this.props.subscriptionModalOption === 'subscription' ||
                    (this.props.subscriptionModalOption === 'card' &&
                      this.props.player &&
                      !this.props.player.user_id)
                  ) {
                    this.affectPlayer();
                  }
                }}
              >
                {i18n.t('pay')} &nbsp;
                <Image src={require('../../../assets/payer.svg')}/>
              </Button>
            </div>
          </>
        )}
      </Form>
    );
  };

  // true : messing one document , false +2
  checkDocument = (document) => {
    let count = 0;
    if (document) {
      if (document.cin) count++;
      if (document.folder) count++;
      if (document.certificate) count++;
      return count >= 2;
    } else {
      return false;
    }
  };

  handleChangeFloat = (event) => {
    let val = event.target.value;
    handleNumberInputChange(val);
    if (val >= 0 || event.target.value === '0.' || event.target.value === '') {
      this.setState({
        [event.target.name]: val,
      });
      this.props.onChange(val);
    }
  };

  handleChangeSearch = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      modalContent: '',
    });

    this.props.searchPlayer(this.props.page, 8, event.target.value);
  };

  renderSearchContent() {
    let exist_player = [];
    if (this.props.subscriptionModalOption === 'subscription') {
      let filter_participants = this.props.currentTicket.participants.filter((itm) => itm.user_id);
      exist_player = filter_participants.map((participant, i) => {
        if (participant.user_id) {
          return participant.user_id;
        }
      });
    }
    return this.props.users.map((item, index) => {
      if (!exist_player.includes(item.id)) {
        return (
          <div key={index} className="player-row">
            <span>
              {nullString(item.firstName).toLowerCase()} {nullString(item.lastName).toUpperCase()}
            </span>
            <span>{formatCodeCountry(item.country_code) + ' ' + phoneNumber(item.mobile)}</span>
            <span>{item.email}</span>
            <span style={{ color: '#7EB5E5' }}>
              <Icon
                name="edit"
                onClick={() => {
                  this.setState({
                    modalContent: 'affect subscription',
                    id: item.id,
                  });
                  this.props.editPlayer(item.id);
                }}
              />
            </span>
          </div>
        );
      }
    });
  }

  handlePaginationChange = (e, data) => {
    this.props.searchPlayer(data.activePage, 8, this.state.search_player);
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={() => {
          //reset old value:
          this.props.onChange(this.state.inputAmount);
          this.props.onClose();
        }}
        size="large"
        className="modal-ticket modal-edit-player"
      >
        <Modal.Content className="modal-content">
          <h3 className="modal-content-header">{i18n.t('client-information')}</h3>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={() => {
                this.props.onChange(this.state.inputAmount);
                this.props.onClose();
              }}
            />
            <div className="search-bloc">
              {!(
                this.props.subscriptionModalOption === 'subscription' &&
                this.props.player &&
                this.props.player.user_id
              ) && (
                <>
                  <Input
                    name="search_player"
                    className="search-input-player"
                    icon="search"
                    placeholder={i18n.t('search')}
                    onChange={this.handleChangeSearch}
                    value={this.state.search_player}
                  />
                </>
              )}
            </div>
            <div className="player-informations">
              {this.state.modalContent === '' && (
                <div className="search-list">
                  {this.props.loading || this.props.editLoading ? (
                    <Loader/>
                  ) : (
                    <div>
                      {this.renderSearchContent()}

                    </div>
                  )}
                  <div className={'ticket-pagination'}>
                    <Pagination
                      activePage={this.props.page}
                      boundaryRange={1}
                      onPageChange={(e, data) => this.handlePaginationChange(e, data)}
                      size="mini"
                      siblingRange={1}
                      totalPages={this.props.last}
                      ellipsisItem={'...'}
                      firstItem={null}
                      lastItem={null}
                      prevItem={null}
                      nextItem={null}
                    />
                  </div>
                </div>
              )}
              {this.state.modalContent === 'affect subscription' && (
                <div className="edit-player-bloc">{this.renderAffectSubscription()}</div>
              )}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

SubscriptionModal.defaultProps = {
  open: true,
  activities: [],
  onClose: () => {
  },
};

const mapDispatchToProps = (dispatch) => ({
  searchPlayer: (page, size, search) =>
    dispatch({ type: 'SEARCH_REQUEST_USER', payload: { page, size, search } }),
  store: (data) =>
    dispatch({ type: 'STORE_CLIENT_REQUEST_USER', payload: { ...data, redirection: false } }),
  reload: (data) => dispatch(SubscribersCreators.indexRequestSubscriberDetail({ ...data })),
  editPlayer: (id) => dispatch({ type: 'EDIT_REQUEST_USER', payload: id }),
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
});
const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    client: state.user.client,
    page: state.user.page,
    last: state.user.last,
    size: state.user.size,
    loading: state.user.loading,
    role: state.role,
    permissions: state.user.clientPermission,
    currentTicket: state.caisse.currentTicket,
    errors: state.user.error,
    user: state.user.selected,
    editLoading: state.user.editLoading,
    credit: state.caisse.credit,
    loadingDetail: state.subscriber.loadingDetail,
    reloadUpdated: state.subscriber.updated,
  };
};

SubscriptionModal.defaultProps = {
  subscriber_id: null,
  isActivity: false,
  autoSelected: false,
  selectedArticles: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionModal);
