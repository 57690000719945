import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  currencyService: {
    index: async ({ page='', size, search }) => {
      const result = await getMethod(
        '/currencies?page=' + page +
        (size ? '&size=' + size :'' ) +
        (search && search.search_name ? '&search_name=' + search.search_name : ''),
      );
      return result.data;
    },
    store: async (data) => {
      const result = await postMethod('/currencies', data);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/currencies/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/currencies/' + data.id, data);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/currencies/' + id);
      return result.data;
    },
  },
};
