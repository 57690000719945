import moment from 'moment';
import { normalDate } from './formatting';

export const getCreditStatus = (subscription, total, expiration) => {
  if(!subscription) return '#FF1818';
  
  let is_reload = subscription.subscription_type.is_reload === 1;
  if (is_reload) {
    let months = moment(normalDate(expiration)).diff(moment(), 'months');
    let days = moment(normalDate(expiration)).diff(moment(), 'days');
    if (days < 0) return '#FF1818';
    else {
      if (months > 3) {
        if (total > 20) return '#72F286';
        else if (total <= 20 && total >= 1) return '#FF9C44';
        else return '#FF1818';
      } else if (months <= 3 && months >= 0) return '#FF9C44';
      else return '#FF1818';
    }
  } else {
    let months = moment(normalDate(expiration)).diff(moment(), 'months');
    let days = moment(normalDate(expiration)).diff(moment(), 'days');
    if (days < 0) return '#FF1818';
    else {
      if (months > 3) return '#72F286';
      else if (months <= 3 && months >= 0) return '#FF9C44';
      else return '#FF1818';
    }
  }
};

export const getCreditStatusForCredit = (total) => {
  if (total > 20) return '#72F286';
  else if (total <= 20 && total >= 1) return '#FF9C44';
  else return '#FF1818';
};
