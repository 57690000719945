import React from "react";
import "./CustomerFile.css";
import { Button, Modal } from "semantic-ui-react";
import CustomTab from "@/components/CustomTab/CustomTab";
import CustomInput from '@/components/CustomInput/CustomInput';
import WithLoader from "../../components/WithLoader/WithLoader";
import ReservationTable from "./ReservationTable";
import SubscriptionTable from "./SubscriptionTable";
import { Creators as TeamCreators } from "@/store/ducks/team";
import { Creators as UserCreators } from "@/store/ducks/user";
import { Creators as RoleCreators } from "@/store/ducks/role";
import { Creators as CreditCreators } from "@/store/ducks/credit";
import { connect } from "react-redux";
import moment from "moment";
import * as fr from "moment/locale/fr";
import * as en from "moment/locale/en-ca";
import history from "../../helpers/history";
import { imgUrl, url, splash, throttle } from '../../helpers';
import i18n from "@/i18n";
import {
  formatCodeCountry,
  normalDate,
  capitalize,
  nullStringForTable,
  phoneNumber,
  nullString,
  formattingDate
} from "../../helpers/formatting";
import Image from "../ImageComponent/Image";
import CreditTable from "./CreditTable";

class CustomerFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalCreditOpen: false,
      item: null,
      total_credit: "",
      date: moment().format("YYYY-MM-DD"),
      credit: '',
      user_id: ''
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.props.edit(this.props.id);
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.creditSuccessStore && this.props.creditSuccessStore !== prevProps.creditSuccessStore) {
      console.log('success');
      this.props.index(this.props.page, this.props.size, {
        user_id: this.props.id,
      });
      this.handleCreditClose();
    }
  }

  handleCreditOpen = () => {
    this.setState({ modalCreditOpen: true, item: this.props.selected });
  }

  handleCreditClose = () => this.setState({ modalCreditOpen: false, credit: ''});

  handleSubmit = (data) => {
    this.props.store(data);
  }

  onChangeHandle(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  renderCreditModal() {
    const { t } = this.props;
    const { item } = this.state;
    let currency = localStorage.getItem("currency");

    return (
      <Modal
        open={this.state.modalCreditOpen}
        onClose={this.handleCreditClose}
        size="small"
      >
        <Modal.Header>
          <div className={"modal-reload-header"}>
            <Button className={"modal-close-btn"} onClick={this.handleCreditClose}>
              X
            </Button>
            <div className={"modal-reload-user"}>
              <Image
                src={item && item.avatar ? url + "/uploads/users/" + item.avatar : splash}
                alt={"user"}
              />
              <span className={"modals-reload-name"}>
                {item && nullStringForTable(nullString(item?.firstName) + " " + nullString(item?.lastName))}
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Content className={"modal-reload-main"}>
          <h2>{i18n.t("credit")}</h2>
          <div>
            <CustomInput
              type={"date"}
              className={"input-bg"}
              name={"date"}
              label={i18n.t("date")}
              value={this.state.date}
              onChange={this.onChangeHandle}
            />
            <CustomInput
              type={"number"}
              className={"input-bg"}
              name={"credit"}
              label={i18n.t("credit")}
              value={this.state.credit}
              onChange={this.onChangeHandle}
            />
                        
            <Button
              disabled={this.props.creditLoading || !this.state.credit}
              loading={this.props.creditLoading}
              className={"modal-btn-save"}
              onClick={throttle(() => {
                if (item && this.state.credit && this.state.date) {
                  let data = {
                    date: formattingDate(this.state.date),
                    credit: this.state.credit,
                    user_id: item.id,
                    complex_id: localStorage.getItem("complex_id")
                  };

                  this.handleSubmit(data);
                }
              },2000)
              }
            >
              {i18n.t("save")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    const { selected } = this.props;
    let localisation = localStorage.getItem("lang") === "fr" ? fr : en;

    let selectedComplex =
      selected && selected.complexes
        ? selected.complexes.filter(
            (it) => it.id.toString() === localStorage.getItem("complex_id")
          )
        : [];
    return (
      <WithLoader loading={this.props.editLoading}>
        <div className={"profile-page"}>
          <div className={"profile-header"}>
            <div className={"profile-details"}>
              <Image
                className="avatar-cover"
                type={"user"}
                src={
                  !selected.avatar ? "" : imgUrl + "/users/" + selected.avatar
                }
                size="medium"
                circular
              />
              <div className={"profile-details-items"}>
                <span className={"profile-name"}>
                  {selected.firstName} {selected.lastName}{" "}
                </span>
                <span className={"profile-phone"}>
                  {formatCodeCountry(selected.country_code) +
                    nullStringForTable(phoneNumber(selected.mobile))}
                </span>
                <span className={"profile-mail"}>{selected.email}</span>
              </div>
            </div>

            <div className={"profile-info"}>
              <span>
                {i18n.t("born_the")}{" "}
                {selected.birthdate
                  ? moment(normalDate(selected.birthdate))
                      .locale(localStorage.getItem("lang"), localisation)
                      .format("LL")
                  : "---"}
              </span>
              <span>
                {i18n.t("address") + " "}:{"  "}
                {selected.address ? selected.address : "---"}
              </span>
              <span>
                {"CP "}:{"  "} {selected.zip ? selected.zip : "---"}
                <span style={{ marginLeft: 14 }}>
                  {i18n.t("city") + " "}:{"  "}
                  {selected.country ? selected.country : "---"}
                </span>
              </span>
              <span>
                {i18n.t("tel") + " "}:{"  "}{" "}
                {formatCodeCountry(selected.country_code) +
                  nullStringForTable(phoneNumber(selected.tel))}
              </span>
            </div>
            <div className={"profile-credit"}>
              <div className={"profile-credit-item"}>
                <Image
                  className="user-credit"
                  src={require("../../assets/credit.svg")}
                  size="mini"
                />
                {selectedComplex.length
                  ? parseFloat(selectedComplex[0].card_balance).toFixed(2)
                  : 0}{" "}
                {localStorage.getItem("currency")}
              </div>
              <div className={"profile-credit-item"}>
                <Image
                  className="user-credit"
                  src={require("../../assets/cash.png")}
                  size="mini"
                />
                {selectedComplex.length
                  ? parseFloat(selectedComplex[0].user_credits).toFixed(2)
                  : 0}{" "}
                {localStorage.getItem("currency")}
              </div>
              <div className={"profile-credit-item"}>
                <Image
                  className="user-credit"
                  src={require("../../assets/credit_to_pay.png")}
                  size="mini"
                />
                {selectedComplex.length
                  ? parseFloat(selectedComplex[0].credits_to_pay).toFixed(2)
                  : 0}{" "}
                {localStorage.getItem("currency")}
              </div>
              <Button
                icon="pencil alternate"
                onClick={() => {
                  if (this.props.editBtn !== null) this.props.editBtn();
                  else history.push("/clients/" + this.props.id + "/edit");
                }}
                circular
                className={"profile-btn"}
              />
            </div>
          </div>

          <CustomTab
            panes={[
              {
                menuItem: i18n.t("reservations"),
                render: () => (
                  <ReservationTable
                    t={(s) => i18n.t(s)}
                    client={this.props.id}
                  />
                ),
              },
              {
                menuItem: i18n.t("subscriptions"),
                render: () => (
                  <SubscriptionTable
                    showClient={false}
                    t={(s) => i18n.t(s)}
                    client={this.props.id}
                  />
                ),
              },
              {
                menuItem: capitalize(i18n.t("credits")),
                render: () => (
                  <>
                  <Button
                    className={'round-btn credit-btn'}
                    onClick={() => this.handleCreditOpen()}
                  >
                    {i18n.t('credit')}
                  </Button>

                  <CreditTable
                    showClient={false}
                    t={(s) => i18n.t(s)}
                    client={this.props.id}
                  />
                  </>
                ),
              },
              // { menuItem: i18n.t('competitions'), render: () => <CompetitionTable t={(s)=>t(s)}/> },
              //{ menuItem: i18n.t('sports_school'), render: () => <ReservationTable t={(s)=>t(s)}/>},
            ]}
          />
        </div>
        {this.state.modalCreditOpen && this.renderCreditModal()}
      </WithLoader>
    );
  }
}

CustomerFile.defaultProps = {
  editBtn: null,
};

const mapDispatchToProps = {
  getTeams: (page, size) => TeamCreators.indexRequestTeam({ page, size }),
  getRoles: (page, size) => RoleCreators.indexRequestRole({ page, size }),
  getClientPermission: () => UserCreators.clientPermissionRequest(),
  edit: (id) => UserCreators.editRequestUser(id),
  store: (data) => CreditCreators.storeRequestCredit(data),
  index: (page, size, search) => CreditCreators.indexRequestCredit({page, size, search}),
};

const mapStateToProps = (state) => {
  return {
    selected: state.user.selected,
    editLoading: state.user.editLoading,
    creditLoading: state.credit.loading,
    creditSuccessStore : state.credit.successStore,
    page: state.credit.page,
    size: state.credit.size
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFile);
