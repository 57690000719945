// Action types and creators
import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as sessionService from '../../services/sessionService';
import history from '../../helpers/history';
import { errorToast } from '../../helpers/customToast';
import i18next from 'i18next';

export const { Types, Creators } = createActions({
  // session request
  startSessionRequest: ['payload'],
  checkSessionRequest: ['payload'],
  closeSessionRequest: ['payload'],
  getSessionRequest: ['payload'],
  resetSessionRequest: [''],

  // Session response
  sessionResponse: ['payload'],
  sessionCheckResponse: ['payload'],
});

const INITIAL_STATE = {
  id: null,
  session: null,
  ComplexSession: null,
  start_button: false,
  conclusion: null,
  loading: false,
  getLoading: false,
  checkLoading: false,
  error: null,
};

function* getSession(action) {
  try {
    yield put(Creators.sessionResponse({ getLoading: true }));
    let data = yield call(sessionService.getSession, action.payload);
    let session = data.data.data;
    yield put(Creators.sessionResponse({ session }));
    yield put(Creators.sessionResponse({ getLoading: false }));
  } catch (e) {
    yield put(Creators.resetSessionRequest());
    yield put(Creators.sessionResponse({ getLoading: false }));
  }
}

function* checkSession(action) {
  try {
    yield put(Creators.sessionResponse({ checkLoading: true, ComplexSession: null, start_button: false }));
    let data = yield call(sessionService.checkSession, action.payload);
    let session = null;
    let ComplexSession = null;
    let start_button = false;
    if (data.data && data.data.hasOwnProperty('checkoutUserSession') && data.data.checkoutUserSession)
      session = { ...data.data.checkoutUserSession };
    if (data.data && data.data.hasOwnProperty('checkoutComplexSession') && data.data.checkoutComplexSession) {
      ComplexSession = { ...data.data.checkoutComplexSession };
    }
    if (data.data && data.data.hasOwnProperty('start_button') && data.data.start_button) {
      start_button = data.data.start_button;
    }

    if (session && Object.entries(session).length > 0) {
      if (session.hasOwnProperty('id'))
        localStorage.setItem('session_id', session.id);
      yield put(Creators.sessionCheckResponse({ session }));
    } else {
      localStorage.removeItem('session_id');
      yield put(Creators.sessionResponse({ session: null   }));
    }
    yield put(Creators.sessionResponse({ checkLoading: false, ComplexSession, start_button }));
  } catch (e) {
    yield put(Creators.sessionResponse({ checkLoading: false }));
  }
}

function* startSession(action) {
  try {
    yield put(Creators.sessionResponse({ loading: true }));
    let data = yield call(sessionService.storeSession, action.payload);
    let session = {
      status: data.data.data.status,
    };

    if (!Array.isArray(data.data.data.checkoutSession)) {
      session = { ...session, ...data.data.data.checkoutSession };
    }
    if (session.hasOwnProperty('id'))
      localStorage.setItem('session_id', session.id);
    if (session.hasOwnProperty('status') && session.status === 'invalid') {
      errorToast(i18next.t('you-have-close-the-register-for-today'));
    }
    yield put(Creators.sessionResponse({ session }));
    yield put(Creators.sessionResponse({ loading: false }));
  } catch (e) {
    yield put(Creators.sessionResponse({ loading: false }));
  }
}

function* closeSession(action) {
  try {
    yield put(Creators.sessionResponse({ loading: true }));
    yield call(sessionService.updateSession, action.payload);
    localStorage.removeItem('session_id');
    yield put(Creators.sessionResponse({ session: null }));
    yield put(Creators.sessionResponse({ loading: false }));
    history.push('/checkout');
  } catch (e) {
    yield put(Creators.sessionResponse({ loading: false }));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.GET_SESSION_REQUEST, getSession);
  yield takeLatest(Types.CHECK_SESSION_REQUEST, checkSession);
  yield takeLatest(Types.START_SESSION_REQUEST, startSession);
  yield takeLatest(Types.CLOSE_SESSION_REQUEST, closeSession);
}

const responseSession = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const responseCheckSession = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const resetSession = (state = INITIAL_STATE, action) => {
  localStorage.removeItem('session_id');
  return {
    ...state,
    ...INITIAL_STATE
  };
};

export const sessionReducer = createReducer(INITIAL_STATE, {
  [Types.SESSION_RESPONSE]: responseSession,
  [Types.SESSION_CHECK_RESPONSE]: responseCheckSession,
  [Types.RESET_SESSION_REQUEST]: resetSession,
});
