import { deleteMethod, getMethod, postMethod, putMethod } from '../helpers';
import { getFormData, showFormData } from '../helpers/formDataHandler';

/**
 * Get the subscriber details list
 *
 * @param page
 * @param size
 * @param search
 * @return object
 */
export const index = ({ page, size, subscriber_id }) => {
  return getMethod('/subscriberDetails?subscriber_id='+subscriber_id+'&page=' + page +
    (size ? '&size=' + size : '') );
};

/**
 * cancel reload the subscription of the subscriber
 *
 * @param data
 */
export const cancelReload = ({id, data}) => {
  return postMethod('/subscriberDetails/cancel/'+ id, data);
};
