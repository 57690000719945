import { deleteMethod, getMethod, postMethod } from '@/helpers';
import { getFormData } from '../helpers/formDataHandler';
export default {
  teamService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/teams?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.is_default ? '&is_default=' + search.is_default : '') +
        (search && search.search_competition
          ? '&search_competition=' + search.search_competition
          : '') +
        '&complex_id=' +
        localStorage.getItem('complex_id'),
      );
      return result.data;
    },
    store: async (data) => {
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('img', data.avatar);
      formData.append('complex_id', localStorage.getItem('complex_id'));
      formData.append('is_default', (data.is_default === true ? 1 : 0).toString());
      const result = await postMethod('/teams', formData);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/teams/' + id);
      return result.data;
    },
    update: async (data) => {
      let users = data.users ? data.users : [];
      for (let i = 0; i < users.length; i++) {
        users[i].complex_id = localStorage.getItem('complex_id');
        users[i].capitaine = users[i].capitaine ? 1 : 0;
        delete users[i].firstName;
        delete users[i].lastName;
        delete users[i].avatar;
        delete users[i].sync;
      }
      
      let newData = {
        id: data.id,
        name : data.name,
        img : data.avatar,
        complex_id :localStorage.getItem('complex_id'),
        is_default : (data.is_default === true ? 1 : 0).toString(),
        users : users
      };
      let formData = getFormData(newData);
      formData.append('_method', 'put');
      const result = await postMethod('/teams/' + data.id, formData);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/teams/' + id);
      return result.data;
    },
  },
};
