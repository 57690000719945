import React from "react";
import { Button, Dimmer, Form, Grid, Icon, Loader, Segment, Table, TextArea , Modal } from "semantic-ui-react";
import CustomInput from "@/components/CustomInput/CustomInput";
import MainLayout from "@/Layout/MainLayout/MainLayout";
import Header from "@/components/Header/Header";
import { Creators as payementsCreators } from "@/store/ducks/paymentMethod";
import { Creators } from "@/store/ducks/subscriber";
import { Creators as subscriptionCreators } from "@/store/ducks/subscription";
import { Creators as subscriberDetailsCreators } from "@/store/ducks/subscriberDetails";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { imgUrl, splash, url } from "../../helpers";
import WithLoader from "../../components/WithLoader/WithLoader";
import { formatCodeCountry, normalDate, nullString, nullStringForTable, phoneNumber } from "../../helpers/formatting";
import "./SubscribersPage.css";
import CustomTab from "../../components/CustomTab/CustomTab";
import moment from "moment";
import { getCreditStatus, getCreditStatusForCredit } from "../../helpers/SubscriberFormatting";
import * as fr from "moment/locale/fr";
import * as en from "moment/locale/en-ca";
import history from "../../helpers/history";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Image from "../../components/ImageComponent/Image";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import { DEFAULT_COUNTRY_CODE } from "../../utils/countryCode";
import { warningToast } from '@/helpers/customToast';

// const qualityLabel = ["father","mother","guardian"];
// const sexLabel = ["man","woman"];

class SubscriberDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      check: true,
      checkTab: true,
      modalDetails: false,
      item: null,
      complex_id: localStorage.getItem("complex_id"),
      user: {
        user_id: "",
        firstName: "",
        lastName: "",
        birthdate: "",
        birthplace: "",
        address: "",
        sex: "",
        zip: "",
        country: "",
        city: "",
        tel: "",
        mobile: "",
        country_code: DEFAULT_COUNTRY_CODE,
        email: "",
        info: "",
        company: ""
      },
      representative: {
        representative_id: null,
        firstName: "",
        lastName: "",
        birthdate: "",
        birthplace: "",
        address: "",
        sex: "",
        zip: "",
        country: "",
        city: "",
        tel: "",
        mobile: "",
        country_code: DEFAULT_COUNTRY_CODE,
        email: "",
        company: ""
      },
      subscriber: {
        quality: "",
        subscription_id: null,
        start_date: "",
        expiry_date: "",
        payment_type_id: null,
        payment_type: {},
        credit_bonus_id: "",
        number: "",
        documents: {},
        deadlines: {}
      },
      subscription: {},
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        complexes: null,
        roles: null,
        mobile: null
      },
      cancelRelodModal: false,
      modalCheckoutOpen: false,
      creditModalOpen : false
    };
  }

  checkSubscriberInformation = () => {
    let { user, representative, subscriber } = this.state;
    // checking if user information is ok
    let userCheck =
      user.firstname !== "" && user.lastname !== "" && (user.mobile !== "" || user.email !== "");
    //checking representative
    let representativeCheck =
      subscriber.quality !== "" &&
      representative.firstname !== "" &&
      representative.lastname !== "" &&
      (representative.mobile !== "" || representative.email !== "");

    return userCheck && representativeCheck;
  };

  checkSubscriber = () => {
    let { subscriber } = this.state;

    return (
      subscriber.subscription_id !== null &&
      subscriber.start_date !== "" &&
      subscriber.expiry_date !== "" &&
      subscriber.payment_type_id !== null
    );
  };

  componentDidMount() {
    if (this.props.subscriberId) {
      this.props.edit(this.props.subscriberId);
    } else if (this.props.match?.params?.subscriber) {
      this.props.edit(this.props.match.params.subscriber);
    }

    //this.props.getSubscriptionTypes(1, 100);
    //this.props.getSubscriptions(1, 100);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.loadingSave &&
      JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)
    ) {
      let errors = {
        firstName: null,
        lastName: null,
        email: null,
        complexes: null,
        roles: null
      };

      for (let index in this.props.errors) {
        errors[index] = { content: this.props.errors[index][0] };
      }
      this.setState({ errors });
    }

    if (prevProps.subscriber !== this.props.subscriber && this.props.subscriber !== null) {
      let selected = this.props.subscriber;
      let user = {
        user_id: selected.user.id,
        firstName: nullString(selected.user.firstName),
        lastName: nullString(selected.user.lastName),
        birthdate: normalDate(selected.user.birthdate),
        birthplace: nullString(selected.user.birthplace),
        address: nullString(selected.user.address),
        sex: nullString(selected.user.sex),
        zip: nullString(selected.user.zip),
        country: nullString(selected.user.country),
        city: nullString(selected.user.city),
        tel: nullString(selected.user.tel),
        mobile: nullString(selected.user.mobile),
        country_code: nullString(selected.user.country_code),
        email: nullString(selected.user.email),
        company: nullString(selected.user.company),
        info: nullString(selected.user.info)
      };

      let representative = {
        representative_id: selected.representative ? selected.representative.id : "",
        firstName: nullString(selected.representative ? selected.representative.firstName : null),
        lastName: nullString(selected.representative ? selected.representative.lastName : null),
        birthdate: normalDate(selected.representative ? selected.representative.birthdate : null),
        birthplace: nullString(selected.representative ? selected.representative.birthplace : null),
        address: nullString(selected.representative ? selected.representative.address : null),
        sex: nullString(selected.representative ? selected.representative.sex : null),
        zip: nullString(selected.representative ? selected.representative.zip : null),
        country: nullString(selected.representative ? selected.representative.country : null),
        city: nullString(selected.representative ? selected.representative.city : null),
        tel: nullString(selected.representative ? selected.representative.tel : null),
        mobile: nullString(selected.representative ? selected.representative.mobile : null),
        country_code: nullString(selected.representative ? selected.representative.country_code : null),
        email: nullString(selected.representative ? selected.representative.email : null),
        company: nullString(selected.representative ? selected.representative.company : null)
      };

      let d = selected.credit_bonus_id;
      let subscriber = {
        subscription_id: selected.subscription_id,
        start_date: normalDate(selected.start_date),
        expiry_date: normalDate(selected.expiry_date),
        payment_type_id: selected.payment_type_id,
        credit_bonus_id: d,
        number: selected.number,
        quality: nullString(selected.quality),
        deadlines: selected.deadlines.length > 0 ? selected.deadlines.map(it => ({
          date: normalDate(it.date),
          amount: it.amount, check_number: it.check_number
        })) : [{ date: "", amount: "", check_number: "" }]
      };

      let folderName = "", cinName = "", certificateName = "";
      let folderChecked = false, cinChecked = false, certificateChecked = false;

      if (selected.document) {
        folderName = selected.document?.hasOwnProperty("folder") ? selected.document.folder : "";
        folderChecked = selected.document?.hasOwnProperty("folder") && selected.document.folder;

        cinName = selected.document?.hasOwnProperty("cin") ? selected.document.cin : "";
        cinChecked = selected.document?.hasOwnProperty("cin") && selected.document.cin;

        certificateName = selected.document?.hasOwnProperty("certificate") ? selected.document.certificate : "";
        certificateChecked = selected.document?.hasOwnProperty("certificate") && selected.document.certificate;
      }

      this.setState({
        id: selected.id,
        folderName,
        cinName,
        certificateName,
        folderChecked,
        cinChecked,
        certificateChecked,
        user,
        subscriber,
        representative,
        selectedSubscriptions: selected.subscription
      });
    }

    if (prevState.subscriber.subscription_id !== this.state.subscriber.subscription_id) {
      let subscriber = { ...this.state.subscriber };
      let old = subscriber.credit_bonus_id;
      if (
        this.state.subscriber &&
        this.state.subscriber.subscriptions &&
        this.state.subscriber.subscriptions.credit_bonuses &&
        this.state.subscriber.subscriptions.credit_bonuses.length > 0 &&
        this.state.subscriber.subscriptions.credit_bonuses.filter((it) => it.id === old).length === 0
      )
        subscriber.credit_bonus_id = null;
      this.setState({ subscriber });
    }

    if(this.props.successCancelReload && 
      this.props.successCancelReload !== prevProps.successCancelReload){
      this.setState({cancelRelodModal : false, item:null});
      if(this.props.credit){
        this.setState({creditModalOpen: true});
      }
    }
  }

  setTitle = () => {
    const { t } = this.props;
    let title = t("subscriber");

    return title;
  };

  fileChangedHandler = (event) => {
    let information = this.state;
    information[event.target.name] = event.target.files[0];
    this.setState({ ...information });
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ imgPreview: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  handleChange = (e, data = null, element = "user") => {
    let value = e.target.value;
    let name = e.target.name;
    if (data) {
      value = data.value;
      name = data.name;
    }
    let selected = { ...this.state[element] };
    selected = { ...selected, [name]: value };

    this.setState({ [element]: selected });
  };

  renderBottomFragment = () => {
    const { t } = this.props;
    let check = this.state.check;
    return (
      <div className={"subscriber-container"}>
        <span className={"subscriber-title"}>{t("legal-representative")}</span>
        <div className={"subscriber-input"}>
          <Form>
            <Grid verticalAlign={"middle"}>
              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"quality"}
                  placeholder={""}
                  error={!check && this.state.subscriber.quality === ""}
                  type="text"
                  label={t("quality")}
                  mode="inline"
                  selection
                  compact
                  value={t(this.state.subscriber.quality)}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} only={"computer"}>
                <span className={"empty-input"}/>
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"lastName"}
                  type="text"
                  label={t("last_name")}
                  mode="inline"
                  value={this.state.representative.lastName}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"firstName"}
                  type="text"
                  label={t("first_name")}
                  mode="inline"
                  value={this.state.representative.firstName}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"birthdate"}
                  type="text"
                  label={t("born_the")}
                  mode="inline"
                  value={this.state.representative.birthdate}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"representative"}
                  type="text"
                  label={t("a")}
                  mode="inline"
                  value={this.state.representative.birthplace}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"address"}
                  type="text"
                  label={t("address")}
                  mode="inline"
                  value={this.state.representative.address}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"sex"}
                  type="text"
                  label={t("sex")}
                  mode="inline"
                  value={this.state.representative.sex}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"zip"}
                  type="text"
                  label={t("zip")}
                  mode="inline"
                  value={this.state.representative.zip}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"city"}
                  type="text"
                  label={t("city")}
                  mode="inline"
                  value={this.state.representative.city}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"tel"}
                  type="text"
                  label={t("tel")}
                  mode="inline"
                  value={this.state.representative.tel}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"country"}
                  type="text"
                  label={t("country")}
                  mode="inline"
                  value={this.state.representative.country}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"email"}
                  type="text"
                  label={t("email")}
                  mode="inline"
                  value={this.state.representative.email}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>

              <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                <CustomTextInput
                  className="input-bg"
                  name={"mobile"}
                  error={!check && this.state.representative.mobile === ""}
                  type="text"
                  label={t("mobile")}
                  mode="inline"
                  value={phoneNumber(this.state.representative.mobile)}
                  onChange={(e) => {
                  }}
                />
              </Grid.Column>
            </Grid>
          </Form>
        </div>
        <Grid>
          <Grid.Column width={16}>
            <div className={"bottom-controller-subscriber"}>
              <Button
                className={"next-subscriber"}
                onClick={() => {
                  this.setState({ index: this.state.index + 1 });
                }}
              >
                {t("next")}
              </Button>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  renderSubscriberTab = () => {
    const { t, subscriber } = this.props;
    let localisation = localStorage.getItem("lang") === "fr" ? fr : en;
    let selected = this.state.user;
    let subscription = subscriber?.subscription;
    let currency = localStorage.getItem("currency");

    return (
      <Grid textAlign={"center"}>
        <Grid.Column width={15}>
          <div className={"subscriber-container"}>
            <span className={"subscriber-title"} style={{ height: "40px" }}>
              {t("subscriber")}
            </span>
            <span>
              <Button
                icon="pencil alternate"
                onClick={() => {
                  if (this.props.type)
                    window.open("/subscriber/" + this.props.subscriberId + "/edit", "_blank");
                  else
                    history.push("/subscriber/" + this.props.match.params.subscriber + "/edit");
                }}
                circular
                className={"profile-btn subscriber-btn"}
              />
            </span>
            <div className={"subscriber-input subscriber-border-bottom"}>
              <div className={"profile-page"}>
                <div className={"profile-header"}>
                  <div className={"profile-details"}>
                    <Image
                      className='avatar-cover'
                      src={
                        !this.props.subscriber ||
                        !this.props.subscriber.user ||
                        !this.props.subscriber.user.avatar
                          ? splash
                          : imgUrl + "/users/" + this.props.subscriber.user.avatar
                      }
                      size="medium"
                      circular
                    />
                    <div className={"profile-details-items"}>
                      <span className={"profile-name"}>
                        {selected.firstName} {selected.lastName}
                      </span>
                      <span className={"profile-phone"}>{
                        formatCodeCountry(selected.country_code) + phoneNumber(selected.mobile)
                      }</span>
                      <span className={"profile-mail"}>{selected.email}</span>
                    </div>
                  </div>

                  <div className={"profile-info"}>
                    <span>
                      {t("born_the")}{" "}
                      {selected.birthdate
                        ? moment(selected.birthdate)
                          .locale(localStorage.getItem("lang"), localisation)
                          .format("LL")
                        : "---"}
                    </span>
                    <span>
                      {t("address")}: {selected.address ? selected.address : "---"}
                    </span>
                    <span>
                      CP: {selected.zip ? selected.zip : "---"} {t("city")}:{" "}
                      {selected.country ? selected.country : "---"}
                    </span>
                    <span>
                      {t("tel")}: {selected.tel ? selected.tel : "---"}
                    </span>
                  </div>
                  <div className={"profile-credit"}>
                    <span>{subscription ? subscription.name : ""}</span>
                    <Image src={require("../../assets/credit.svg")}/>

                    <span>
                      {this.props.subscriber &&
                      subscription &&
                      subscription.subscription_type &&
                      subscription.subscription_type.is_reload === 1 ?
                        (this.props.subscriber.total_balance).toFixed(2) + " " + currency : ""}
                    </span>
                    {/*<Button  icon="pencil alternate"*/}
                    {/*         onClick={()=>history.push("/subscribers/"+this.props.id*/}
                    {/*             +"/edit")}*/}
                    {/*         circular*/}
                    {/*         className={"profile-btn"}/>*/}
                  </div>
                </div>
              </div>
              <Grid.Column width={16} className={"info-subscriber"}>
                <div>
                  <label className={"form-label"}>Informations complémentaires</label>
                </div>

                <TextArea className={"textArea-input-bg"} value={selected.info}/>
              </Grid.Column>
            </div>
            <div className={"subscriber-input"}>{this.renderBottomFragment()}</div>
          </div>
        </Grid.Column>
      </Grid>
    );
  };

  getDropdownItem = (items, id) => {
    let data = "";
    let filterResult = items.filter((it) => it.value === id);
    if (filterResult.length > 0) {
      for (let i = 0; i < filterResult.length; i++) data += filterResult[i].text + " ";
    }
    return data;
  };

  renderAdministratorTab = () => {
    let currency = localStorage.getItem("currency");
    let { checkTab } = this.state;
    const { t, paymentMethods } = this.props;
    const { selectedSubscriptions } = this.state;

    let activitiesOptions = [],
      periodicityOptions = [],
      creditOptions = [];

    if (selectedSubscriptions) {
      activitiesOptions = selectedSubscriptions.activities.map(it => {
        return (
          {
            text: it.name,
            key: it.id,
            value: it.id,
            image: {
              avatar: it.avatar,
              src: url + "/uploads/activities/" + localStorage.getItem("complex_id") + "/" + it.avatar
            }
          }
        );
      });

      if (selectedSubscriptions.subscription_type.is_reload)
        creditOptions = selectedSubscriptions.credit_bonuses.map(it => {
          return (
            {
              text: "credit: " + it.credit + currency + (it.bonus !== null ? " + bonus:" + it.bonus + currency : ""),
              key: it.id,
              value: it.id
            }
          );
        });
      else {
        creditOptions = selectedSubscriptions.total_amount;
      }

      let it = selectedSubscriptions.periodicity;
      periodicityOptions = [{ text: it.name, key: it.id, value: it.id, is_reload: it.is_reload }];
    }

    let numMonth = selectedSubscriptions ? selectedSubscriptions.total_amount : 1;


    let isValid = selectedSubscriptions && selectedSubscriptions.subscription_type.is_reload === 1;


    return (
      <Grid textAlign={"center"}>
        <Grid.Column width={15}>
          <div className={"subscriber-container"}>
            <span className={"subscriber-title"}>{t("subscription")}</span>
            <div className={"subscriber-input subscriber-border-bottom"}>
              <Form>
                <Grid>
                  <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                    <CustomInput className="input-bg"
                                 name={"subscription_id"}
                                 key={"subscriber-subscription_id"}
                                 error={!checkTab && this.state.subscriber.subscription_id === null}
                                 type="custom-dropdown"
                                 url={"/subscriptions"}
                                 filter={"search_name"}
                                 disabled={true}
                                 render={it => {
                                   return (
                                     <>
                                       <Image
                                         src={url + "/uploads/subscriptions/" + localStorage.getItem("complex_id") + "/" + it.avatar}
                                         type={"item"}/>
                                       <span>{it.name}</span>
                                     </>
                                   );
                                 }}
                                 placeholder={t("select-subscription")}
                                 label={t("name")}
                                 value={this.state.subscriber.subscription_id}
                                 onChange={(e, d) => {
                                   this.handleSubscriptionsChange({
                                     target: {
                                       name: "subscription_id",
                                       value: e
                                     }
                                   }, d, "subscriber");
                                 }}
                    />


                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                    <CustomInput className="input-bg" name={"subscription_id"}
                                 type="dropdown"
                                 compact
                                 search
                                 selection
                                 clearable
                                 options={activitiesOptions}
                                 disabled={true}
                                 label={t("activities")}
                                 mode="inline"
                                 multiple={true}
                                 value={activitiesOptions.map(it => it.value)}
                                 onChange={(e, d) => this.handleChange(e, d, "subscriber")}/>

                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                    <CustomInput className="input-bg"
                                 name={"start_date"}
                                 error={!checkTab && this.state.subscriber.start_date === ""}
                      // errormsg={t("champ-required") }
                                 type="date"
                                 label={t("start")}
                                 disabled={true}
                                 mode="inline"
                                 value={this.state.subscriber.start_date}
                                 onChange={(e) => this.handleChange(e, null, "subscriber")}
                    />
                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                    <CustomInput className="input-bg"
                                 name={"expiry_date"}
                                 error={!checkTab && this.state.subscriber.expiry_date === ""}
                                 type="date"
                                 label={t("end")}
                                 disabled={true}
                                 mode="inline"
                                 value={this.state.subscriber.expiry_date}
                                 onChange={(e) => this.handleChange(e, null, "subscriber")}
                    />
                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>

                    {!isValid ?
                      <CustomInput className="input-bg"
                                   name={"montant"}
                                   type="text"
                                   label={t("montant")}
                                   disabled={true}
                                   mode="inline"
                                   value={creditOptions}
                      />
                      :
                      <CustomInput className="input-bg"
                                   name={"credit_bonus_id"}
                                   error={!checkTab && (this.state.subscriber.credit_bonus_id === "" || this.state.subscriber.credit_bonus_id === null)}
                        // errormsg={t("champ-required") }
                                   type="custom-dropdown"
                                   fetching={false}
                                   disabled={true}
                                   filter={"text"}
                                   keyword={"value"}
                                   options={creditOptions}
                                   render={it => (
                                     <>
                                       <span>{it.text}</span>
                                     </>
                                   )}
                                   placeholder={t("select-amount")}
                                   label={t("montant")}
                                   mode="inline"
                                   value={this.state.subscriber.credit_bonus_id}
                                   onChange={(e, d) => this.handleChange({
                                     target: {
                                       name: "credit_bonus_id",
                                       value: e
                                     }
                                   }, null, "subscriber")}
                      />
                    }
                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                    <CustomInput className="input-bg"
                                 name={"subscription_id"}
                                 type="dropdown"
                                 compact
                                 search
                                 selection
                                 clearable
                                 options={periodicityOptions}
                                 disabled={true}
                                 multiple={true}
                      //placeholder={t("select-periodicity")}
                                 label={t("periodicity")}
                                 mode="inline"
                                 value={periodicityOptions.map(it => it.value)}
                                 onChange={(e, d) => this.handleChange(e, d, "subscriber")}
                    />
                  </Grid.Column>

                  <Grid.Column className={"no-padding-subscriber"} computer={8} mobile={16} tablet={16}>
                    <CustomInput className="input-bg regulation-input"
                                 name={"payment_type_id"}
                                 error={!checkTab && this.state.subscriber.payment_type_id === null}
                                 type="custom-dropdown"
                                 url={"/paymentTypes"}
                                 disabled={true}
                                 param={"&status=[2,1]"}
                                 render={it => (
                                   <>
                                     <Image className={it.icon ? "filter-img" : "no-filter-img"}
                                            src={url + "/uploads/payments/" + it.icon} type={"item"}/>
                                     <span>{it.name}</span>
                                   </>
                                 )}
                                 placeholder={t("select-regulation")}
                                 label={t("regulation")}
                                 mode="inline"
                                 onInitial={(d) => this.setState({ selectedPaymentType: d })}
                                 value={this.state.subscriber.payment_type_id}
                                 onChange={(e, d) => this.handleChange({
                                   target: {
                                     name: "payment_type_id",
                                     value: e,
                                     data: d
                                   }
                                 }, null, "subscriber")}
                    />
                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"}
                               computer={8}
                               mobile={16}
                               tablet={16}>
                    <CustomInput className="input-bg"
                                 name={"number"}
                                 type="number"
                                 disabled={true}
                                 label={t("number")}
                                 mode="inline"
                                 value={this.state.subscriber.number}
                                 onChange={(e) => this.handleChange(e, null, "subscriber")}
                    />
                  </Grid.Column>


                  <Grid.Column className={"no-padding-subscriber"} computer={16} mobile={16} tablet={16}>
                    <Grid columns={16}>
                      <Grid.Column tablet={16}>
                        <label className={"form-label"}>{t("deadline")}</label>
                      </Grid.Column>
                      {this.state.subscriber.deadlines.map((it, i) => {
                        return (<Grid.Column className={"deadlines-bg"} key={"deadline-" + i} width={16}>
                            <Grid columns={16}>
                              {/*{i!==0  && <Grid.Column computer={2} tablet={12}/>}*/}
                              <Grid.Column className={"deadlines-padding"} computer={6} tablet={16}>
                                <CustomInput
                                  label={t("date")}
                                  disabled={true}
                                  className="input-bg full-width-input"
                                  name={"deadlines"}
                                  type="date"
                                  mode="inline"
                                  value={this.state.subscriber.deadlines[i].date}
                                  onChange={(e) => this.handleDeadline(e, i, "date")}
                                />
                              </Grid.Column>
                              <Grid.Column computer={8} className={"computer-only"}/>
                              {i !== 0 && <Grid.Column computer={2} tablet={12}/>}
                              <Grid.Column className={"deadlines-padding"} computer={6} tablet={i === 0 ? 16 : 16}>
                                <CustomInput label={t("amount")}
                                             className="input-bg full-width-input"
                                             name={"deadlines"}
                                             type="number"
                                             min={"0"}
                                             disabled={true}
                                             mode="inline"
                                             value={this.state.subscriber.deadlines[i].amount}
                                             onChange={(e) => this.handleDeadline(e, i, "amount")}
                                />
                              </Grid.Column>
                              {this.state.selectedPaymentType && this.state.selectedPaymentType.slug === "cheque" &&
                              <Grid.Column className={"deadlines-padding"} computer={6} tablet={i === 0 ? 16 : 16}>
                                <CustomInput label={t("check_number")}
                                             className="input-bg full-width-input"
                                             name={"deadlines"}
                                             disabled={true}
                                             type="text"
                                             mode="inline"
                                             value={this.state.subscriber.deadlines[i].check_number}
                                             onChange={(e) => this.handleDeadline(e, i, "check_number")}
                                />
                              </Grid.Column>}
                              {i !== 0 &&
                              <Grid.Column computer={1} className={"deadlines-padding"} tablet={16} textAlign={"left"}>
                                <Button icon={"x"} circular={true} color={"red"}
                                        onClick={() => this.removeDeadLine(i)}/>
                              </Grid.Column>
                              }

                            </Grid>
                          </Grid.Column>
                        );
                      })
                      }
                    </Grid>

                  </Grid.Column>
                  {false && this.state.selectedPaymentType && this.state.selectedPaymentType.is_cash !== 1 &&
                  <Grid.Column width={16}>
                    <Button
                      onClick={this.addDeadLine}
                      className={"no-btn-style"}>
                      <img src={require("../../assets/add_btn.svg")} alt={""}/>
                    </Button>
                  </Grid.Column>}

                </Grid>
              </Form>
            </div>
          </div>
          {this.renderDocuments(isValid)}
        </Grid.Column>

      </Grid>
    );
  };

  renderDocuments = (isValid) => {
    const { t } = this.props;
    return (
      <Grid textAlign={"center"}>
        <Grid.Column width={15}>
          <div className={"subscriber-container"}>
            <span className={"subscriber-title"}>{t("documents")}</span>
            <div className={"subscriber-input"}>
              <Form>
                <Grid>
                  <Grid.Column className={"no-padding-subscriber"} width={8}>
                    <CustomInput
                      className="input-large-bg"
                      type="text"
                      disabled={true}
                      label={t("folder")}
                      mode="inline"
                      value={this.state.folderName}
                    />

                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"} width={8}>
                    <input id={"doc"}
                           onChange={this.handleFileChange} accept={"image/*,.pdf"}
                           type={"file"} name={"folder"} className={"subscriber-file-input"}/>
                    <div className={"file-preview"}>
                      {this.state.folderChecked ? <span>
                      <a target={"_blank"}
                         href={imgUrl + "/documents/folder/" + localStorage.getItem("complex_id") + "/" + this.state.folderName}>
                      <Icon name={"search plus"} color={"green"}/>
                    </a>
                      </span> : <span>
                              <Icon name={"search plus"} color={"grey"}/>
                      </span>}

                      {false && <label htmlFor={"doc"} className={"download-btn"}>
                        <img src={require("../../assets/download.svg")} alt={"download"}/>
                        {t("import")}
                      </label>}

                    </div>


                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"} width={8}>
                    <CustomInput
                      className="input-large-bg"
                      type="text"
                      disabled={true}
                      label={t("CIN")}
                      mode="inline"
                      value={this.state.cinName}
                      onChange={this.handleChange}
                    />

                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"} width={8}>
                    <input id={"cin"}
                           onChange={this.handleFileChange} accept={"image/*,.pdf"}
                           type={"file"} name={"cin"} className={"subscriber-file-input"}/>
                    <div className={"file-preview"}>
                      {this.state.cinChecked ? <span><a target={"_blank"}
                                                        href={imgUrl + "/documents/cin/" + localStorage.getItem("complex_id") + "/" + this.state.cinName}>
                      <Icon name={"search plus"} color={"green"}/>
                    </a></span> : <span>
                              <Icon name={"search plus"} color={"grey"}/>
                      </span>}

                      {false && <label htmlFor={"cin"} className={"download-btn"}>
                        <img src={require("../../assets/download.svg")} alt={"download"}/>
                        {t("import")}
                      </label>}

                    </div>
                  </Grid.Column>
                  <Grid.Column className={"no-padding-subscriber"} width={8}>
                    <CustomInput
                      className="input-large-bg"
                      type="text"
                      label={t("certificate")}
                      disabled={true}
                      mode="inline"
                      value={this.state.certificateName}
                      onChange={this.handleChange}/>

                  </Grid.Column>

                  <Grid.Column className={"no-padding-subscriber"} width={8}>
                    <input id={"cert"} type={"file"}
                           onChange={this.handleFileChange} accept={"image/*,.pdf"}
                           name={"certificate"}
                           className={"subscriber-file-input"}/>
                    <div className={"file-preview"}>

                      {this.state.certificateChecked ? <span className={"file-preview"}> <a target={"_blank"}
                                                                                            href={imgUrl + "/documents/certificate/" + localStorage.getItem("complex_id") + "/" + this.state.certificateName}>
                      <Icon name={"search plus"} color={"green"}/>
                    </a></span> : <span>
                              <Icon name={"search plus"} color={"grey"}/>
                      </span>}

                      {false && <label htmlFor={"cert"} className={"download-btn"}>
                        <img src={require("../../assets/download.svg")} alt={"download"}/>
                        {t("import")}
                      </label>}

                    </div>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
          </div>
          <div className={"bottom-controller-subscriber"}>
            <Button className={"next-subscriber"}
                    onClick={() => this.setState({ index: this.state.index - 1 })}
            >{t("previous")}</Button>
          </div>
        </Grid.Column>
      </Grid>

    );
  };

  /*****Cancel reload ******/
  cancelReload(with_avoir){
    const { t } = this.props;
    let expense = parseFloat(this.state.item.credit) + parseFloat(this.state.item.bonus);
    if(this.props.subscriber.total_balance >= parseFloat(expense)){
      if (!this.props.session || (this.props.session && nullString(this.props.session.status) === "1")){
        this.toggleSessionModal(true);
      }  
      else{
        let subscriber_detail = {
          date: moment().format("YYYY-MM-DD"),
          label: "Recharge recovery of "+this.state.item.date,
          credit: 0,
          bonus: 0,
          payment_type_id: null,
          expense: parseFloat(expense),
          subscriber_id: this.state.item.subscriber_id,
          checkout_session_id: this.props.session.id
        };
        
        if(with_avoir){
          this.props.cancelReload(this.state.item.id, 
            {is_ticket: false,
            subscriber_detail: subscriber_detail, 
            date: moment().format("YYYY-MM-DD"),
            hour: moment().format("hh:mm"),
            amount: parseFloat(expense),
            is_credit: 1,
            payed_amount: 0,
            status: 0,
            owner_id: this.props.user.id,
            parent_id: null,
            complex_id: localStorage.getItem("complex_id"),
            articles: [],
            participants: [],
            checkout_session_id: this.props.session.id,
            payments: {articles:[], participants:[]},
            discountTypes: {articles:[], participants:[]},
            notes: []
          });
        }else{
          this.props.cancelReload(this.state.item.id, 
            {is_ticket: false, subscriber_detail: subscriber_detail}
          );
        }
      }
    }else{
      warningToast(t("warning-msg-insufficient-sold"));
    } 
  }

  toggleSessionModal = (modalCheckoutOpen) => {
    this.setState({
      modalCheckoutOpen,
      cancelRelodModal : false , item : null
    });
  };

  renderNoCheckoutModal() {
    const { t } = this.props;
    return (
      <Modal open={this.state.modalCheckoutOpen} onClose={() => {
        this.toggleSessionModal(false);
      }} size="tiny">
        <Modal.Header>{t("no-checkout-session")}</Modal.Header>
        <Modal.Content>
          <p>{t("msg-checkout-open-session")}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("cancel")} onClick={() => {
            this.toggleSessionModal(false);
          }}/>
          <Button
            content={t("open-session")}
            className="negative"
            onClick={() => {
              history.push("/checkout");
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  renderCancelReloadModal() {
    const { t } = this.props;
    return (
      <Modal open={this.state.cancelRelodModal} onClose={() => {
        this.setState({cancelRelodModal : false, item:null});
      }} size="tiny">
        <Modal.Header>{t("cancel-reload")}</Modal.Header>
        <Modal.Content>
          <Button
            icon="close"
            color="red"
            className="modal-btn-close"
            circular
            size="tiny"
            onClick={() => this.setState({cancelRelodModal : false, item:null})}
          />
          <p>{t("msg-cancel-reload")}</p>
        </Modal.Content>
        <Modal.Actions>
        <Button
            content={t("close")}
            floated={"left"}
            onClick={() => this.setState({cancelRelodModal : false, item:null})}
          />

          <Button
            content={t("cancel-reload")}
            className={"negative"}
            loading={this.props.cancelReloadLoading}
            disabled={this.props.cancelReloadLoading}
            onClick={() => this.cancelReload(false)}
          />
          
          <Button
            content={t("cancel-reload-with-avoir")}
            className={"positive"}
            loading={this.props.cancelReloadLoading}
            disabled={this.props.cancelReloadLoading}
            onClick={()=>this.cancelReload(true)}
          />

        </Modal.Actions>
      </Modal>
    );
  }

  renderCreditModal() {
    const { t } = this.props;
    return (
      <ModalConfirm
        open={this.state.creditModalOpen}
        onClose={this.handleClose}
        title={t("avoir")}
        message={
          <p>
            {t("an-avoir")} {this.props.credit && this.props.credit.credit_number} {t("has-amount")}{" "}
            {this.props.credit && parseFloat(this.props.credit.amount).toFixed(2)}
            {localStorage.getItem("currency")} {t("has-generate")}
          </p>
        }
        ok={() => this.setState({ creditModalOpen: false })}
      />
    );
  }

  /** */
  renderPayments = () => {
    const { t, subscriber: item, details, page, last, loadingDetails, size } = this.props;
    let currency = localStorage.getItem("currency");
    let lastSolde = parseFloat(item.total_balance);

    return (
      <div className={"payments-container"}>
        <div className={"modal-reload-header payment-border"}>
          <div className={"modal-reload-complex payment-credits"}>
            <div>
              <img src={require("../../assets/park.svg")} alt={"complex"}/>
              <span className={"modal-reload-complex-name"}>
                <h3>{item && item.subscription.name}</h3>
                <h6>N° {item && item.id}</h6>
              </span>
            </div>
            <span className={"modal-reload-credit "}>
              <span
                className={"round-credit"}
                style={{
                  backgroundColor:
                    item && getCreditStatus(item.subscription, item.total_balance, item.expiry_date)
                }}
              />
              <h3>
                {parseFloat(item.total_balance).toFixed(2)} {t("credits")}
              </h3>
            </span>
          </div>
          <div className={"modal-reload-user"}>
            <Image
              src={item && item.user.avatar ? url + "/uploads/users/" + item.user.avatar : splash}
              alt={"user"}
            />
            <span className={"modals-reload-name"}>
              {item && item.user.firstName + " " + item.user.lastName}
            </span>
          </div>
          <div className={"payment-date-container"}>
            <div className={"modal-reload-date"}>
              <span className={"modal-reload-slug"}>{t("start_date")}</span>
              <span className={"modal-reload-date"}>{item && item.start_date}</span>
            </div>

            <div className={"modal-reload-date"}>
              <span className={"modal-reload-slug"}>{t("expiry_date")}</span>
              <span
                className={"modal-reload-date"}
                style={{
                  color: item && getCreditStatus(item.subscription, item.total_balance, item.expiry_date)
                }}
              >
                {item && item.expiry_date}
              </span>
            </div>
          </div>
        </div>

        <div className={"table-container"}>
          <Table fixed={true} className={"no-overflow"}>
            <Table.Header>
              <Table.Row className={"payment-table-header"}>
                <Table.HeaderCell width="1"/>
                <Table.HeaderCell width="2">
                  <div className={"payment-cell"}>{t("date")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell width="2">
                  <div className={"payment-cell"}>{t("reloading")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell width="2">
                  <div className={"payment-cell"}>{t("expense")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell width="2">
                  <div className={"payment-cell"}>{t("bonus")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell width="2">
                  <div className={"payment-cell"}>{t("regulation")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <div className={"payment-cell"}>{t("check_number")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <div className={"payment-cell"}>{t("article")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell width="2">
                  <div className={"payment-cell"}>{t("balance")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell width="1"/>
                <Table.HeaderCell width="1"/>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {details.map((it, i) => {
                let credit = this.creditFormatting(it.credit);
                let expense = this.creditFormatting(it.expense);
                let bonus = this.creditFormatting(it.bonus);
                let style = it.expense ? "payment-row payment-row-red" : "payment-row";

                let dataRow = (
                  <Table.Row className={style} key={"row-payemnt-" + i}>
                    <Table.Cell className={"payement-index"} width={1}>
                      <div>{i + 1}</div>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <div>{it.date}</div>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <div>{credit + currency}</div>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <div>{expense + currency}</div>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <div>{bonus + currency}</div>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <div>
                        {nullStringForTable(
                          it ? (it.payment_type ? it.payment_type.name : null) : null
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <div>{it.check_number ? it.check_number : "---"}</div>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <div>{it.article ? it.article.name : "---"}</div>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <div>{parseFloat(lastSolde).toFixed(2) + currency}</div>
                    </Table.Cell>
                    <Table.Cell width={1}>
                      <div>
                        <span
                          className={"round-credit"}
                          style={{ background: getCreditStatusForCredit(lastSolde) }}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell width={1}>
                      <div>
                        {((!it.expense && it.ticket && it.ticket.status !== 2) || (it.expense && it.ticket)) &&
                          <Button
                            className={"no-bg"}
                            onClick={() => {
                              if(it.expense && it.ticket){
                                this.setState({ modalDetails: true, item: it });
                              } 
                              else{
                                this.setState({ cancelRelodModal: true, item: it });
                              }
                            }}
                          >
                            <img
                              src={require("../../assets/payment-details.svg")}
                              alt={"show more"}
                            />
                          </Button>
                        }
                        
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );

                lastSolde = lastSolde - (credit + bonus) + expense;

                return (dataRow);
              })}
              <Table.Row className={"payment-row payment-row-loading"}>
                <Table.Cell width={1}/>
                <Table.Cell width={2}/>
                <Table.Cell width={2}/>
                <Table.Cell width={2}/>
                <Table.Cell width={2}/>
                <Table.Cell width={2} textAlign="center">
                  {(!loadingDetails && last > page) ? 
                    <Button
                      circular  
                      icon="angle double down"
                      onClick={() => {
                        if (this.props.subscriberId) {
                          this.props.getMoreDetails({
                            page: page + 1,
                            size: size,
                            subscriber_id: this.props.subscriberId
                          });
                        } else if (this.props.match.params.subscriber) {
                          this.props.getMoreDetails({
                            page: page + 1,
                            size: size,
                            subscriber_id: this.props.match.params.subscriber
                          });
                        }
                      }}
                    />:
                    <Segment className={"no-shadow-box"}>
                      <Dimmer className={"with-loader-dimmer"} active inverted>
                        <Loader/>
                      </Dimmer>
                    </Segment>
                  }
                </Table.Cell>
                <Table.Cell width={2}/>
                <Table.Cell width={2}/>
                <Table.Cell width={2}/>
                <Table.Cell width={1}/>
                <Table.Cell width={1}/>
              </Table.Row>
            </Table.Body>

            <Table.Footer className={"payment-footer"}>
              <Table.Row className={"payment-footer-row"}>
                <Table.HeaderCell width={2} colSpan={4} className={"payment-footer-title"}>
                  {t("credit-balance")}
                </Table.HeaderCell>
                <Table.HeaderCell/>
                <Table.HeaderCell/>
                <Table.HeaderCell/>
                <Table.HeaderCell textAlign={"left"} width={2} colSpan={2}>
                  {parseFloat(item.total_balance).toFixed(2) + currency}
                </Table.HeaderCell>
                <Table.HeaderCell/>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>
    );
  };

  renderDetails = () => {
    let { item } = this.state;
    let currency = localStorage.getItem("currency");
    let localisation = localStorage.getItem("lang") === "fr" ? fr : en;
    let { t } = this.props;
    return (
      <ModalConfirm
        open={this.state.modalDetails}
        onClose={() => {
          this.setState({ modalDetails: false });
        }}
        size={"small"}
        title={"Dépense"}
        message={
          <div className={"table-container"}>
            <Table fixed={true} className={"no-overflow table-max-height"}>
              <Table.Header>
                <Table.Row className={"payment-table-header"}>
                  <Table.HeaderCell>
                    <div className={"payment-cell"}>{t("date")}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className={"payment-cell"}>{t("activity")}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className={"payment-cell"}>{t("duration")}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className={"payment-cell"}>{t("ticket")}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className={"payment-cell"}>{t("amount")}</div>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row className={"payment-row"} key={"row-payemnt-1"}>
                  <Table.Cell>
                    <div>{item ? item.date : nullStringForTable(item)}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div>
                      {nullStringForTable(
                        item
                          ? item.article
                          ? item.article.name + " - " + item.article.parent.name
                          : null
                          : null
                      )}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div>
                      {nullStringForTable(
                        item
                          ? item.reservation
                          ? item.reservation.duration.duration
                          : null
                          : null
                      )}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div>
                      {nullStringForTable(
                        item
                          ? item.ticket
                          ? item.ticket.number
                          : null
                          : null
                      )}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div>
                      {nullStringForTable(item ? (item.article ? item.expense : item.expense) : 0) +
                      currency}
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        }
        cancelText={"Fermer"}
        cancel={() => {
          this.setState({ modalDetails: false });
        }}
      />
    );
  };

  renderDeadlines = () => {
    const { t, subscriber: item } = this.props;
    let currency = localStorage.getItem("currency");
    let lastSolde = 0;
    return (
      <div className={"payments-container"}>
        <div className={"modal-reload-header payment-border"}>
          <div className={"modal-reload-complex payment-credits"}>
            <div>
              <img src={require("../../assets/park.svg")} alt={"complex"}/>
              <span className={"modal-reload-complex-name"}>
                <h3>
                  {item && nullStringForTable(item.subscription ? item.subscription.name : null)}
                </h3>
                <h6>N° {item && item.id}</h6>
              </span>
            </div>
          </div>
          <div className={"modal-reload-user"}>
            <Image
              src={item && item.user.avatar ? url + "/uploads/users/" + item.user.avatar : splash}
              alt={"user"}
            />
            <span className={"modals-reload-name"}>
              {item && item.user.firstName + " " + item.user.lastName}
            </span>
          </div>
          <div className={"payment-date-container"}>
            <div className={"modal-reload-date"}>
              <span className={"modal-reload-slug"}>{t("start_date")}</span>
              <span className={"modal-reload-date"}>{item && item.start_date}</span>
            </div>

            <div className={"modal-reload-date"}>
              <span className={"modal-reload-slug"}>{t("expiry_date")}</span>
              <span
                className={"modal-reload-date"}
                style={{
                  color: item && getCreditStatus(item.subscription, item.total_balance, item.expiry_date)
                }}
              >
                {item && item.expiry_date}
              </span>
            </div>
          </div>
        </div>

        <div className={"table-container"}>
          <Table fixed={true} className={"no-overflow table-max-height"}>
            <Table.Header>
              <Table.Row className={"payment-table-header"}>
                <Table.HeaderCell/>
                <Table.HeaderCell>
                  <div className={"payment-cell"}>{t("deadline")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className={"payment-cell"}>{t("amount")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className={"payment-cell"}>{t("regulation")}</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className={"payment-cell"}>{t("action")}</div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {item.deadlines.map((it, i) => {
                return (
                  <Table.Row key={"details-" + i} className={"payment-row"}>
                    <Table.Cell className={"payement-index"}>
                      <div>{i + 1}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>{it.date}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>{(it.amount ? it.amount : 0) + currency}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        {nullStringForTable(
                          item ? (item.payment_type ? item.payment_type.name : null) : null
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <span
                          className={"label-round-credit"}
                          style={{ background: it.payed === 0 ? "#FF1818" : "#72F286" }}
                        >
                          {it.payed === 0 ? t("not_paid") : t("paid")}
                        </span>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  };

  creditFormatting = (credit) => {
    if (credit === null) return 0;

    return parseFloat(credit);
  };

  render() {
    const { t, subscriber } = this.props;

    let reload =
      subscriber && subscriber.subscription
        ? subscriber.subscription.subscription_type.is_reload === 1
        : false;
    let panes = [
      { menuItem: t("subscriber-information"), render: () => this.renderSubscriberTab() },
      { menuItem: t("administrative-documents"), render: () => this.renderAdministratorTab() }
    ];

    if (reload) panes.push({ menuItem: t("payments"), render: () => this.renderPayments() });
    else panes.push({ menuItem: t("payments"), render: () => this.renderDeadlines() });

    return (
      <MainLayout {...this.props.sidebarProps} type={this.props.type}>
        <Header title={this.setTitle()}>
          <Link to={"/subscriber"} onClick={(e) => {
            if (this.props.type) {
              e.preventDefault();
              this.props.caisseRedirection("subscriber");
            }
          }}>
            <Button>{t("back")} </Button>
          </Link>
        </Header>
        {this.renderDetails()}
        {this.state.cancelRelodModal && this.renderCancelReloadModal()}
        {this.state.modalCheckoutOpen && this.renderNoCheckoutModal()}
        {this.state.creditModalOpen && this.renderCreditModal()}
        <div>
          <WithLoader
            loading={this.props.editLoading || this.props.loadingTypes || this.props.loading}
          >
            <CustomTab
              cssClassName={"tab-subscriber"}
              activeIndex={this.state.index}
              panes={panes}
              onTabChange={(e,data) => {
                if(data.activeIndex === 2){
                  if (this.props.subscriberId) {
                    this.props.getDetails({ subscriber_id: this.props.subscriberId, page: 1, size: 5 });
                  } else if (this.props.match?.params?.subscriber) {
                    this.props.getDetails({ subscriber_id: this.props.match.params.subscriber, page: 1, size: 5 });
                  }
                }
                this.setState({ index: data.activeIndex })}
              }
            />
          </WithLoader>
        </div>
      </MainLayout>
    );
  }
}

const mapDispatchToProps = {
  getSubscriptions: (page, size) => subscriptionCreators.indexRequestSubscription({ page, size }),
  getSubscriptionTypes: (page, size) => payementsCreators.indexRequestPaymentMethod({ page, size }),
  save: (data) => Creators.storeRequestSubscriber({ ...data }),
  edit: (data) => Creators.editRequestSubscriber(data),
  caisseRedirection: (value, status = null, type = null, date = null) =>
    ({ type: "CAISSE_REDIRECTION_REQUEST", payload: { value, status, type, date } }),

  getDetails: (data) => subscriberDetailsCreators.indexRequestSubscriberDetails(data),
  getMoreDetails: (data) => subscriberDetailsCreators.indexMoreRequestSubscriberDetails(data),
  update: (data) => Creators.updateRequestSubscriber(data),
  cancelReload: (id,data) => subscriberDetailsCreators.cancelReloadRequestSubscriber({ id, data}),


};

const mapStateToProps = (state) => {
  return {
    loading: state.subscription.loading,
    subscriptions: state.subscription.subscriptions,
    details: state.subscriberDetails.details,
    page: state.subscriberDetails.page,
    size: state.subscriberDetails.size,
    last: state.subscriberDetails.last,
    loadingDetails: state.subscriberDetails.loading,
    loadingTypes: state.paymentMethod.loading,
    subscriberLoading: state.subscriber.loading,
    subscriber: state.subscriber.subscriber,
    editLoading: state.subscriber.editLoading,
    paymentMethods: state.paymentMethod.paymentMethods,
    session: state.session.session,
    user: state.auth.user,
    cancelReloadLoading: state.subscriberDetails.cancelReloadLoading,
    successCancelReload: state.subscriberDetails.successCancelReload,
    credit: state.subscriberDetails.credit
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriberDetails);
