import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Form, Modal } from "semantic-ui-react";
import i18n from "@/i18n";
import CustomInput from "@/components/CustomInput/CustomInput";
import { randomstring } from "@/helpers/formatting";
import { SIZE } from "../../../utils/mock";
import { DEFAULT_COUNTRY_CODE } from "../../../utils/countryCode";
import { throttle } from "../../../helpers";
import { formattingExistUser, reversePhoneNumber } from "../../../helpers/formatting";
import { checkIsAvailable } from "../../../services/userService";
import { Creators as userCreators } from "@/store/ducks/user";

class AddClientModal extends React.Component {
  constructor(props) {
    super(props);
    let somePassword = randomstring();
    this.state = {
      content: null,
      search_client: "",
      id: "",
      img: "",
      firstName: "",
      lastName: "",
      birthdate: "1980-01-01",
      email: "",
      mobile: "",
      country_code: DEFAULT_COUNTRY_CODE,
      imgPreview: "",
      company: "",
      country: "",
      zip: "",
      address: "",
      society: "",
      complexes: Number(localStorage.getItem("complex_id")),
      teams: [],
      capitaine: [],
      password: somePassword,
      password_confirmation: somePassword,
      errors: {
        firstName: null,
        lastName: null,
        email: null
      },
      check: true,
      joinModal: false,
      old: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  componentDidMount() {
    this.props.getRoles(1, 100);
    this.props.getClientPermission();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.loading &&
      JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)
    ) {
      let errors = {
        firstName: null,
        lastName: null,
        email: null
      };
      for (let index in this.props.errors) {
        errors[index] = { content: this.props.errors[index][0] };
      }
      this.setState({ errors });
    }
    if ((prevProps.client !== this.props.client) && this.props.client) {
      this.initClient();
      this.props.onFinish(this.props.client);
    }

    if (this.props.joined && this.props.joined !== prevProps.joined) {
      this.props.onFinish(this.state.old);
      this.setState({ old: null, joinModal: false });
    }
  }

  renderJoinModal = () => {
    const t  = (s)=>i18n.t(s);
    let oldUser = this.state.old;
    let msg = formattingExistUser(t, oldUser);
    return (
      <Modal
        size={"small"}
        onClose={() => !this.props.loadingJoin && this.setState({ joinModal: false, old: null })}
        open={this.state.joinModal}
      >
        <Modal.Header>{t("confirm-join")}</Modal.Header>
        <Modal.Content>
          <p>{msg.first.trim()} <b>{msg.second}</b></p>
          <p> {msg.third} </p>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("cancel")}
                  onClick={() => !this.props.loadingJoin && this.setState({ joinModal: false, old: null })}/>
          <Button
            content={t("confirm")}
            className="negative"
            loading={this.props.loadingJoin}
            disabled={this.props.loadingJoin}
            onClick={() => {
              let old = this.state.old;
              let data = { user_id: old.id, complexes: [localStorage.getItem("complex_id")]};
             this.props.joinUser(data);
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  };


  fileChangedHandler = (event) => {
    this.setState({ [event.target.name]: event.target.files[0] });
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ imgPreview: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  handleChangeSearch(event) {
    this.setState({
      [event.target.name]: event.target.value,
      content: null
    });

    this.props.search(this.props.page, SIZE, event.target.value);
  }

  handleChange(event, eventMobile = null) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if (eventMobile && eventMobile.target)
      this.setState({
        [eventMobile.target.name]: eventMobile.target.value
      });
  }

  initClient = () =>{
    let somePassword = randomstring();
    this.setState({
      id: "",
      img: "",
      firstName: "",
      lastName: "",
      birthdate: "1980-01-01",
      email: "",
      mobile: "",
      country_code: DEFAULT_COUNTRY_CODE,
      imgPreview: "",
      company: "",
      country: "",
      zip: "",
      address: "",
      society: "",
      complexes: Number(localStorage.getItem("complex_id")),
      teams: [],
      capitaine: [],
      password: somePassword,
      password_confirmation: somePassword,
    })
  }

  handleSubmit = async () => {
    let { roles } = this.props.role;
    let stateWithoutError = { ...this.state };
    delete stateWithoutError.error;
    let r = roles.filter((it) => {
      return it.slug === "client";
    });
    let data = {
      permissions: this.props.permissions,
      ...stateWithoutError,
      roles: r.length > 0 ? r[0].id : null,
      checkout: true
    };
    if (!data.owner_complex_id) data.owner_complex_id = localStorage.getItem("complex_id");
    let checkResponse = await checkIsAvailable({
      email: data.email,
      country_code: data.country_code,
      mobile: reversePhoneNumber(data.mobile)
    });
    if (checkResponse.data && checkResponse.data.data && !Array.isArray(checkResponse.data.data) && checkResponse.data.data.complexes.filter(it => it.id.toString() === localStorage.getItem("complex_id")).length === 0) {
      this.setState({ joinModal: true, old: checkResponse.data.data });
    } else {
      if (data.mobile === "" && data.tel === "") {
        data.country_code = "";
      }
      if (data.id)
        this.props.update(data);
      else
        this.props.store(data);
    }


  };

  handleThrottleSubmit = throttle(this.handleSubmit, 2000);

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={() => {
          this.props.onClose();
          this.initClient();
        }}
        size="large"
        closeIcon={false}
      >
        <Modal.Content className="modal-content">
          <Button
            icon="close"
            color="red"
            className="modal-btn-close"
            circular
            size="tiny"
            onClick={()=> {
              this.props.onClose();
              this.initClient();
            }}
          />
          <div className="add-client-block">
            <h3 className="client-list-header">{"Ajouter un client"}</h3>
            <div style={{ textAlign: "right" }}>
              <Button onClick={() => {
                this.props.onClose();
                this.initClient();
              }}>{i18n.t("back")}</Button>
            </div>
            <Form className="client-input">
              <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                <div style={{ flex: 1, margin: "5px 20px" }}>
                  <CustomInput
                    name={"img"}
                    imageurl={this.state.imgPreview}
                    className="input-bg picture-input"
                    type="picture"
                    label={i18n.t("avatar")}
                    mode="inline"
                    onChange={this.fileChangedHandler}
                  />

                  <CustomInput
                    className="input-bg"
                    name={"firstName"}
                    error={this.state.errors.firstName !== null}
                    errormsg={this.state.errors.firstName}
                    type="text"
                    label={i18n.t("first_name")}
                    mode="inline"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />

                  <CustomInput
                    className="input-bg"
                    name={"lastName"}
                    error={this.state.errors.lastName !== null}
                    errormsg={this.state.errors.lastName}
                    type="text"
                    label={i18n.t("last_name")}
                    mode="inline"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />

                  <CustomInput
                    className="input-bg"
                    type="date"
                    name={"birthdate"}
                    label={i18n.t("birthday")}
                    mode="inline"
                    value={this.state.birthdate}
                    onChange={this.handleChange}
                  />

                  <CustomInput
                    className="input-bg"
                    type="email"
                    name={"email"}
                    label={i18n.t("email")}
                    mode="inline"
                    value={this.state.email}
                    error={this.state.errors.email !== null}
                    errormsg={this.state.errors.email}
                    onChange={this.handleChange}
                  />
                </div>

                <div style={{ flex: 1, margin: "5px" }}>
                  <br/>
                  <CustomInput
                    className="input-bg"
                    type="phone"
                    name="mobile"
                    label={i18n.t("mobile")}
                    mode="inline"
                    value={this.state.mobile}
                    countrycode={this.state.country_code}
                    onChange={this.handleChange}
                  />

                  <CustomInput
                    className="input-bg"
                    type="text"
                    name={"company"}
                    label={i18n.t("society")}
                    mode="inline"
                    value={this.state.company}
                    onChange={this.handleChange}
                  />
                  <CustomInput
                    className="input-bg"
                    type="text"
                    name="country"
                    label={i18n.t("country")}
                    mode="inline"
                    value={this.state.country}
                    onChange={this.handleChange}
                  />
                  <CustomInput
                    className="input-bg"
                    type="text"
                    name="zip"
                    label={i18n.t("zip")}
                    mode="inline"
                    value={this.state.zip}
                    onChange={this.handleChange}
                  />
                  <CustomInput
                    className="input-bg"
                    type="text"
                    name="address"
                    label={i18n.t("address")}
                    mode="inline"
                    value={this.state.address}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  disabled={this.props.addLoading}
                  loading={this.props.addLoading}
                  content={i18n.t("save")}
                  className={"client-green-btn"}
                  onClick={this.handleThrottleSubmit}
                />
              </div>
            </Form>
            {this.renderJoinModal()}
          </div>
        </Modal.Content>
      </Modal>
    );

  }

}

const mapDispatchToProps = (dispatch) => ({
  search: (page, size, search) =>
    dispatch({ type: "SEARCH_REQUEST_USER", payload: { page, size, search } }),
  store: (data) => dispatch({ type: "STORE_CLIENT_REQUEST_USER", payload: { ...data, redirection: false } }),
  update: (data) => dispatch({ type: "UPDATE_CLIENT_REQUEST_USER", payload: { ...data, redirection: false } }),
  getRoles: (page, size) => dispatch({ type: "INDEX_REQUEST_ROLE", payload: { page, size } }),
  getClientPermission: () => dispatch({ type: "CLIENT_PERMISSION_REQUEST" }),
  caisse_redirection: (value) => dispatch({ type: "CAISSE_REDIRECTION_REQUEST", payload: { value } }),
  edit: (id) => dispatch({ type: "EDIT_REQUEST_USER", payload: id }),
  joinUser: (data) => dispatch(userCreators.joinRequestUser(data)),

});

const mapStateToProps = (state) => {
  return {
    article_in_payment: state.caisse.article_in_payment,
    users: state.user.users,
    client: state.user.client,
    page: state.user.page,
    last: state.user.last,
    total: state.user.total,
    size: state.user.size,
    from: state.user.from,
    check: state.user.check,
    joined: state.user.joined,
    addLoading: state.user.loading,
    loadingCheck: state.user.loadingCheck,
    loadingJoin: state.user.loadingJoin,
    role: state.role,
    permissions: state.user.clientPermission,
    errors: state.user.error
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddClientModal));
