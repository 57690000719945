import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import * as closureService from '../../services/closureService';
import { errorToast, infoToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingClosure: ['payload'],

  indexRequestClosure: ['payload'],
  indexRequestStatsClosure: ['payload'],
  indexResponseClosure: ['data'],

  storeRequestClosure: ['payload'],
  storeResponseClosure: ['data'],

  editRequestClosure: ['id'],
  editResponseClosure: ['data'],

  updateRequestClosure: ['payload'],
  updateResponseClosure: ['data'],

  destroyRequestClosure: ['id'],
  destroyResponseClosure: ['data'],

  resetStatsResponseClosure: [],

  saveClosureDetailsRequest: ['payload'],
  saveClosureDetailsResponse: ['payload'],

  ClosureByDateRequest: ['payload'],
  ClosureByDateResponse: ['data'],

  resetClosureByDateResponse: [],
});

// Initial state
const INITIAL_STATE = {
  closures: [],
  closure: null,
  stats: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  statsLoading: false,
  detailsLoading: false,
  editLoading: false,
  errors: null,
  detailsFinish: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
  closuresByDate: null,
};


//index
function* index(action) {
  try {
    yield put(Creators.loadingClosure({ loading: true }));
    const data = yield call(closureService.index, action.payload);
    yield put(Creators.indexResponseClosure(data));
    yield put(Creators.loadingClosure({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingClosure({ loading: false }));
  }
}

//index
function* stats(action) {
  try {
    yield put(Creators.loadingClosure({ statsLoading: true, stats: null }));
    const data = yield call(closureService.stats, action.payload);
    yield put(Creators.loadingClosure({ stats: data, statsLoading: false }));
  } catch (e) {
    yield put(Creators.loadingClosure({ statsLoading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingClosure({ detailsFinish: false, loading: true, closure: null }));
    const data = yield call(closureService.store, action.payload);
    yield put(Creators.storeResponseClosure(data));
    yield put(Creators.loadingClosure({ loading: false, detailsFinish: true }));
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingClosure({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}


//edit
function* edit(action) {
  try {
    yield put(Creators.loadingClosure({ closure: null, editLoading: true }));
    const data = yield call(closureService.edit, action.payload);
    yield put(Creators.editResponseClosure(data));
    yield put(Creators.loadingClosure({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingClosure({ editLoading: false }));
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingClosure({ loading: true }));
    const data = yield call(closureService.update, action.payload);
    yield put(Creators.updateResponseClosure(data));
    yield put(Creators.loadingClosure({ loading: false }));
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(
      Creators.loadingClosure({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingClosure({ deleteLoading: true }));
    const data = yield call(closureService.destroy, action.payload);
    yield put(Creators.destroyResponseClosure(data));
    yield put(Creators.loadingClosure({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

// store closure details
function* storeDetails(action) {
  try {
    yield put(Creators.loadingClosure({ detailsFinish: false, detailsLoading: true }));
    const data = yield call(closureService.storeDetail, action.payload);

    yield put(Creators.saveClosureDetailsResponse(data));
    yield put(Creators.loadingClosure({ detailsFinish: true, detailsLoading: false }));
    infoToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingClosure({ detailsFinish: false, detailsLoading: false }));
    errorToast(i18n.t('failed_store'));

  }
}


//closure by date
function* closureByDate(action) {
  try {
    yield put(Creators.loadingClosure({ loading: true }));
    const data = yield call(closureService.closureByDate, action.payload);
    yield put(Creators.ClosureByDateResponse(data));
    yield put(Creators.loadingClosure({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingClosure({ loading: false }));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CLOSURE, index);
  yield takeLatest(Types.INDEX_REQUEST_STATS_CLOSURE, stats);
  yield takeLatest(Types.STORE_REQUEST_CLOSURE, store);
  yield takeLatest(Types.EDIT_REQUEST_CLOSURE, edit);
  yield takeLatest(Types.UPDATE_REQUEST_CLOSURE, update);
  yield takeLatest(Types.DESTROY_REQUEST_CLOSURE, destroy);
  yield takeLatest(Types.SAVE_CLOSURE_DETAILS_REQUEST, storeDetails);
  yield takeLatest(Types.CLOSURE_BY_DATE_REQUEST, closureByDate);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    closures: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    closure: action.data,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    closure: action.data.data,
  };
};
//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding_closure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const storeDetailsResponse = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

const resetStatsResponse = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    stats: null,
    closure: null,
    detailsFinish: false,
  };
};

const closure_by_date = (state= INITIAL_STATE, action) => {
  return {
    ...state,
    closuresByDate: action.data,
  }
}

const reset_closure_by_date = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    closuresByDate: null,
  };
};



// Reducer
export const closureReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_CLOSURE]: index_response,
  [Types.STORE_RESPONSE_CLOSURE]: store_response,
  [Types.EDIT_RESPONSE_CLOSURE]: edit_response,
  [Types.RESET_STATS_RESPONSE_CLOSURE]: resetStatsResponse,
  [Types.UPDATE_RESPONSE_CLOSURE]: update_response,
  [Types.DESTROY_REQUEST_CLOSURE]: destroy_request,
  [Types.DESTROY_RESPONSE_CLOSURE]: destroy_response,
  [Types.LOADING_CLOSURE]: laoding_closure,
  [Types.SAVE_CLOSURE_DETAILS_RESPONSE]: storeDetailsResponse,
  [Types.CLOSURE_BY_DATE_RESPONSE]: closure_by_date,
  [Types.RESET_CLOSURE_BY_DATE_RESPONSE]: reset_closure_by_date,
});
