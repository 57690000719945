import React, { useState } from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';
import Loader from '../Loader/Loader';
import PropTypes from 'prop-types';
import i18n from '@/i18n';

const CustomPagination = (props) => {
  const [size, setSize] = useState(props.size ? props.size : 1);
  const sizes = [
    { key: 2, text: 2, value: 2 },
    { key: 3, text: 3, value: 3 },
    { key: 10, text: 10, value: 10 },
    { key: 20, text: 20, value: 20 },
  ];

  const sizeUpdate = (e, d) => {
    props.loadMore(props.page, d.value, props.search);
    setSize(d.value);
  };
  const go = (page, search) => {
    if (page > 0 && page < props.last + 1) props.loadMore(page, size, search);
  };

  return (
    <div className="table-footer">
      <div className="table-footer-component">
        <span>{i18n.t('pagination_line_per_page')}</span>
        <Dropdown value={size} onChange={sizeUpdate} upward options={sizes}/>
      </div>
      <div className="table-footer-component">
        <span>
          {props.page} sur {props.last}
        </span>
      </div>
      <div className="table-footer-component">
        <span>
          <Icon
            name="angle left"
            disabled={props.page - 1 <= 0}
            onClick={() => {
              go(props.page - 1, props.search);
            }}
          />
        </span>
        <span>
          <Icon
            name="angle right"
            disabled={props.page + 1 > props.last}
            onClick={() => go(props.page + 1, props.search)}
          />
        </span>
      </div>
    </div>
  );
};

CustomPagination.prototype = {
  page: PropTypes.number,
  total: PropTypes.number,
  loadMore: PropTypes.func,
};

export default CustomPagination;
