import { call, put, takeLatest } from "redux-saga/effects";
import { createActions, createReducer } from "reduxsauce";
import configurationPlanningService from "@/services/configurationPlanningService";
import { errorToast, validToast } from "../../helpers/customToast";
import i18n from "../../i18n/index";

export const DEFAULT_SELECTED_OBJ = {
  id: null,
  slot_interval: "00:15",
  show_side_menu: 0,
  complex_id: localStorage.getItem("complex_id"),
};

// Configuration types and creators
export const { Types, Creators } = createActions({
  loadingConfigurationPlanning: ["payload"],

  indexRequestConfigurationPlanning: ["payload"],
  indexResponseConfigurationPlanning: ["payload"],

  storeRequestConfigurationPlanning: ["payload"],
  storeResponseConfigurationPlanning: ["payload"],

  updateRequestConfigurationPlanning: ["payload"],
  updateResponseConfigurationPlanning: ["payload"],
});

// Initial state
const INITIAL_STATE = {
  configuration: DEFAULT_SELECTED_OBJ,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  success: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingConfigurationPlanning({ loading: true }));
    const data = yield call(configurationPlanningService.index, action.payload);
    yield put(Creators.indexResponseConfigurationPlanning(data));
    yield put(Creators.loadingConfigurationPlanning({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingConfigurationPlanning({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(
      Creators.loadingConfigurationPlanning({
        loading: true,
        errors: null,
        success: false,
      })
    );
    const response = yield call(
      configurationPlanningService.store,
      action.payload
    );

    validToast(i18n.t("successful_store"));
    yield put(
      Creators.loadingConfigurationPlanning({
        loading: false,
        configuration: response.data,
        success: true,
      })
    );
  } catch (e) {
    yield put(Creators.loadingConfigurationPlanning({ loading: false }));
    yield put(
      Creators.loadingConfigurationPlanning({ errors: e.response.data.errors })
    );
    errorToast(i18n.t("failed_store"));
  }
}

//update
function* update(action) {
  try {
    yield put(
      Creators.loadingConfigurationPlanning({
        loading: true,
        errors: null,
        success: false,
      })
    );
    const response = yield call(
      configurationPlanningService.update,
      action.payload
    );
    validToast(i18n.t("successful_update"));
    yield put(
      Creators.loadingConfigurationPlanning({
        loading: false,
        configuration: response.data,
        success: true,
      })
    );
  } catch (e) {
    yield put(Creators.loadingConfigurationPlanning({ loading: false }));
    yield put(
      Creators.loadingConfigurationPlanning({ errors: e.response.data.errors })
    );
    errorToast(i18n.t("failed_update"));
    //history.push('/error-404');
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CONFIGURATION_PLANNING, index);
  yield takeLatest(Types.STORE_REQUEST_CONFIGURATION_PLANNING, store);
  yield takeLatest(Types.UPDATE_REQUEST_CONFIGURATION_PLANNING, update);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    configuration:
      action.payload.data && action.payload.data?.length > 0
        ? action.payload.data[0]
        : DEFAULT_SELECTED_OBJ,
    last: action.payload.last_page,
    page: action.payload.current_page,
    total: action.payload.total,
    size: action.payload.per_page,
    from: action.payload.from,
  };
};

const laoding_configuration = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const configurationPlanningReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_CONFIGURATION_PLANNING]: index_response,
  [Types.LOADING_CONFIGURATION_PLANNING]: laoding_configuration,
});
