import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import equipmentService from '../../services/equipmentService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingEquipment: ['data'],

  indexRequestEquipment: ['payload'],
  indexResponseEquipment: ['data'],

  storeRequestEquipment: ['payload'],
  storeResponseEquipment: ['data'],

  editRequestEquipment: ['id'],
  editResponseEquipment: ['data'],

  updateRequestEquipment: ['payload'],
  updateResponseEquipment: ['data'],

  destroyRequestEquipment: ['id'],
  destroyResponseEquipment: ['data'],
});

// Initial state
const INITIAL_STATE = {
  equipments: [],
  equipment: null,
  loading: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingEquipment({ loading: true }));
    const data = yield call(equipmentService.equipmentService.index, action.payload);
    yield put(Creators.indexResponseEquipment(data));
    yield put(Creators.loadingEquipment({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingEquipment({ loading: true }));
    const data = yield call(equipmentService.equipmentService.store, action.payload);
    yield put(Creators.storeResponseEquipment(data));
    yield put(Creators.loadingEquipment({ loading: false }));
    yield history.push('/configuration/equipments');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingEquipment({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingEquipment({ editLoading: true }));
    const data = yield call(equipmentService.equipmentService.edit, action.payload);
    yield put(Creators.editResponseEquipment(data));
    yield put(Creators.loadingEquipment({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingEquipment({ editLoading: false }));
    history.push('/error-404');
  }
}

// //update
function* update(action) {
  try {
    yield put(Creators.loadingEquipment({ loading: true }));
    const data = yield call(equipmentService.equipmentService.update, action.payload);
    yield put(Creators.updateResponseEquipment(data));
    yield put(Creators.loadingEquipment({ loading: false }));
    yield history.push('/configuration/equipments');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(
      Creators.loadingEquipment({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

// //destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingEquipment({ deleteLoading: true }));
    const data = yield call(equipmentService.equipmentService.destroy, action.payload);
    yield put(Creators.destroyResponseEquipment(data));
    yield put(Creators.loadingEquipment({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_EQUIPMENT, index);
  yield takeLatest(Types.STORE_REQUEST_EQUIPMENT, store);
  yield takeLatest(Types.EDIT_REQUEST_EQUIPMENT, edit);
  yield takeLatest(Types.UPDATE_REQUEST_EQUIPMENT, update);
  yield takeLatest(Types.DESTROY_REQUEST_EQUIPMENT, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    equipments: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    equipment: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const equipmentReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_EQUIPMENT]: index_response,
  [Types.STORE_RESPONSE_EQUIPMENT]: store_response,
  [Types.EDIT_RESPONSE_EQUIPMENT]: edit_response,
  [Types.UPDATE_RESPONSE_EQUIPMENT]: update_response,
  [Types.DESTROY_REQUEST_EQUIPMENT]: destroy_request,
  [Types.DESTROY_RESPONSE_EQUIPMENT]: destroy_response,
  [Types.LOADING_EQUIPMENT]: loading,
});
