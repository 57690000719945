import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

/**
 * Modal confirmation is used in Caisse for confirmation or showing  information
 *
 * */
const ModalConfirm = (props) => {
  const [t] = useTranslation();

  return (
    <Modal open={props.open} onClose={props.onClose}
           closeOnDimmerClick={props.loading}
           closeOnEscape={props.loading}
           size={props.size} className="modal-ticket">
      <Modal.Content className="modal-content">
        <h3 className="modal-content-header">{props.title}</h3>
        <div className="modal-content-body">
          {props.isClosing && <Button
            icon="close"
            color="red"
            className="modal-btn-close"
            circular
            size="tiny"
            disabled={props.loading}
            onClick={() => props.closeBtn ? props.closeBtn() : props.onClose()}
          />}
          {props.message}
          <div style={{textAlign : 'center'}}>{props.input}</div>
          <span className="btn-group">
            {props.ok && (
              <Button className="modal-delete-btn" onClick={props.ok} loading={props.loading}>
                {props.okText ? props.okText : t("ok")}
              </Button>
            )}
            {props.cancel && (
              <Button className="modal-cancel-btn" disabled={props.loading} onClick={props.cancel}>
                {props.cancelText ? props.cancelText : t("cancel")}
              </Button>
            )}
          </span>
        </div>
      </Modal.Content>
    </Modal>
  );
};

ModalConfirm.defaultProps = {
  closeBtn: null,
  loading: false,
  isClosing:true,
  size:'tiny'
};

export default ModalConfirm;
