import React from 'react';
import Sidemenu from '@/components/Sidemenu/Sidemenu';
import { Switch } from 'react-router-dom';
import { PreventUpdate } from '../../components/PreventUpdate/PreventUpdate';

let eventHandler = null;

class WithSidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      width: 250,
      isMobile: window.innerWidth < 1100,
      selectedModule: null,
    };
  }

  setSelectedModule = (mod) => {
    this.setState({ selectedModule: mod });
  };

  children = React.Children.map(this.props.children, (it, i) => {
    return React.cloneElement(it, {
      sidebarProps: {
        setModule: (s) => this.setSelectedModule(s),
        toggleDarkMode: () => this.toggleDarkMode(),
        toggleSidemenu: () => this.toggleSidemenu(),
      },
    });
  });

  handleResizing = () => {
    if ((window.innerWidth < 1100) !== this.state.isMobile)
      this.setState({
        isMobile: window.innerWidth < 1100,
      });
  };

  /**
   *here we handle switching between mobile view and desktop view
   *  desktop >= 810
   *  Mobile  < 810
   */
  componentDidMount() {
    eventHandler = window.addEventListener(
      'resize',
      this.handleResizing,
      false,
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizing, false);
  }

  /**
   * this function handle closing and opening the sidebar
   * toggle is disabled in mobile view
   * */
  toggleSidemenu() {
    const width = this.state.width === 250 ? 60 : 250;
    this.setState({ width });
  }

  toggleDarkMode() {
    if (this.container.current.classList.length === 1) {
      localStorage.setItem('dark', 'dark');
      this.container.current.classList.add('dark');
    } else {
      localStorage.setItem('dark', '');
      this.container.current.classList.remove('dark');
    }
  }

  render() {
    let classList = 'container';
    if (this.state.isMobile || this.state.width === 60) classList += ' mobile-layout ';
    if (localStorage.getItem('dark') === 'dark')
      classList += ` ${localStorage.getItem('dark')}`;
    return (
      <div ref={this.container} className={classList}>
        <Sidemenu
          isMobile={this.state.isMobile}
          width={this.state.width}
          module={this.state.selectedModule}
        />
        <PreventUpdate isMobile={this.state.isMobile} width={this.state.width}>
          <Switch>{this.children}</Switch>
        </PreventUpdate>
      </div>
    );
  }
}

export default WithSidebar;
