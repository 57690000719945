import React from "react";
import ReactDOM from "react-dom";
import store, { initSaga } from "./store";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import "@/i18n";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

initSaga();

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
