import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import * as ContactService from '../../services/contactService';
import { errorToast, validToast } from '../../helpers/customToast';
import history from '../../helpers/history';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingContact: ['payload'],

  storeRequestContact: ['payload'],
  indexRequestContact: ['payload'],

  searchRequestContact: ['payload'],
  searchResponseContact: ['payload'],

  editRequestContact: ['payload'],
  updateRequestContact: ['payload'],
  destoryRequestContact: ['payload'],


  responseContact: ['payload'],
  responseEditContact: ['payload'],
  errorContact: ['payload'],
});

// Initial state
const INITIAL_STATE = {
  contacts: [],
  firstName: '',
  lastName: '',
  selected: {},
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  deleteSuccess: false,
  reset: false,
  loading: false,
  editLoading: false,
  updateLoading: false,
  deleteLoading: false,
  error: null,
};

function* store(action) {
  try {
    yield put(Creators.loadingContact({ loading: true, error: null }));
    const data = yield call(ContactService.store, action.payload);
    yield put(Creators.loadingContact({ loading: false }));
    validToast(i18n.t('successful_store'));
    //history.push('/configuration/users');
  } catch (e) {
    yield put(Creators.loadingContact({ loading: false }));
    yield put(Creators.errorContact(e.response.data.errors));
    errorToast(i18n.t('failed_store'));
  }
}

function* update(action) {
  try {
    yield put(Creators.loadingContact({ updateLoading: true, error: null }));
    const data = yield call(ContactService.update, action.payload);
    yield put(Creators.loadingContact({ updateLoading: false }));
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(Creators.errorContact({ updateLoading: false }));
    yield put(Creators.errorContact());
    errorToast(i18n.t('failed_update'));
  }
}

function* edit(action) {
  try {
    yield put(Creators.loadingContact({ editLoading: true }));
    const response = yield call(ContactService.edit, action.payload);
    yield put(Creators.responseEditContact({ selected: response.data.data }));
    yield put(Creators.loadingContact({ editLoading: false }));
  } catch (e) {
    yield put(Creators.errorContact({ editLoading: false, editError: false }));
    history.push('/error-404');
  }
}

function* destory(action) {
  try {
    yield put(Creators.loadingContact({ deleteLoading: true, deleteSuccess: false }));
    const data = yield call(ContactService.destroy, action.payload);
    yield put(Creators.loadingContact({ deleteLoading: false, deleteSuccess: true }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
    yield put(
      Creators.errorContact({
        deleteLoading: false,
        deleteSuccess: true,
        error: true,
      }),
    );
  }
}

function* index(action) {
  try {
    yield put(Creators.loadingContact({ loading: true }));
    const data = yield call(ContactService.index, action.payload);
    yield put(Creators.responseContact(data));
    yield put(Creators.loadingContact({ loading: false }));
  } catch (e) {
    yield put(Creators.errorContact());
    yield put(Creators.loadingContact({ loading: false }));
  }
}

// function* search(action) {
//   try {
//     yield put(Creators.loadingContact({ loading: true }));
//     const data = yield call(ContactService.search, action.payload);
//     yield put(Creators.searchResponseContact(data));
//     yield put(Creators.loadingContact({ loading: false }));
//   } catch (e) {
//     yield put(Creators.errorContact());
//     yield put(Creators.loadingContact({ loading: false }));
//   }
// }


export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CONTACT, index);
  // yield takeLatest(Types.SEARCH_REQUEST_CONTACT, search);
  yield takeLatest(Types.STORE_REQUEST_CONTACT, store);
  yield takeLatest(Types.EDIT_REQUEST_CONTACT, edit);
  yield takeLatest(Types.UPDATE_REQUEST_CONTACT, update);
  yield takeLatest(Types.DESTORY_REQUEST_CONTACT, destory);

}

// Reducer

const contact_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    contacts: [...action.payload.data.data],
    last: action.payload.data.last_page,
    page: action.payload.data.current_page,
    total: action.payload.data.total,
    size: action.payload.data.per_page,
    from: action.payload.data.from,
  };
};

const search_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    contacts: [...action.payload.data.data],
    last: action.payload.data.last_page,
    page: action.payload.data.current_page,
    total: action.payload.data.total,
    size: action.payload.data.per_page,
    from: action.payload.data.from,
  };
};

const contact_response_edit = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    ...action.payload,
  };
};

const contact_failed = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: action.payload,
  };
};

const contact_loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
    error: null,
  };
};

export const contactReducer = createReducer(INITIAL_STATE, {
  [Types.RESPONSE_CONTACT]: contact_response,
  [Types.SEARCH_RESPONSE_CONTACT]: search_response,
  [Types.RESPONSE_EDIT_CONTACT]: contact_response_edit,
  [Types.LOADING_CONTACT]: contact_loading,
  [Types.ERROR_CONTACT]: contact_failed,
});
