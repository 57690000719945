import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";
import authentication, { authReducer } from "./authentication";
import network, { networkReducer } from "./network";
import role, { roleReducer } from "./role";
import permission, { permissionReducer } from "./permission";
import moduleAction, { moduleReducer } from "./module";
import compType, { compTypeReducer } from "./compType";
import complex, { complexReducer } from "./complex";
import complexTimes, { complexTimesReducer } from "./complexTimes";
import duration, { durationReducer } from "./duration";
import terrain, { terrainReducer } from "./terrain";
import terrainTimes, { terrainTimesReducer } from "./terrainTimes";
import competition, { competitionReducer } from "./competition";
import team, { teamReducer } from "./team";
import activity, { activityReducer } from "./activity";
import user, { userReducer } from "./user";
import sensor, { sensorReducer } from "./sensor";
import side, { sideReducer } from "./side";
import resolution, { resolutionReducer } from "./resolution";
import camera, { cameraReducer } from "./camera";
import cameraPlanning, { cameraPlanningReducer } from "./cameraPlanning";
import reservation, { reservationReducer } from "./reservation";
import caisse, { caisseReducer } from "./caisse";
import currency, { currencyReducer } from "./currency";
import discount, { discountReducer } from "./discount";
import parent, { parentReducer } from "./parent";
import child, { childReducer } from "./child";
import vat, { vatReducer } from "./vat";
import article, { articleReducer } from "./article";
import hourType, { hourTypeReducer } from "./hourType";
import paymentMethod, { paymentMethodReducer } from "./paymentMethod";
import ticketInfo, { ticketInfoReducer } from "./ticketInfo";
import hourConfigs, { hourConfigsReducer } from "./hourConfigs";
import subscription, { subscriptionReducer } from "./subscription";
import priceManagements, { priceManagementsReducer } from "./priceManagements";
import periodicity, { periodicityReducer } from "./periodicity";
import subscriptionType, { subscriptionTypeReducer } from "./subscriptionType";
import mixedHour, { mixedHourReducer } from "./mixedHour";
import subscriber, { subscriberReducer } from "./subscriber";
import video, { videoReducer } from "./video";
import action, { actionReducer } from "./action";
import booking, { bookingReducer } from "./booking";
import videoPlanning, { videoPlanningReducer } from "./videoPlanning";
import contact, { contactReducer } from "./contact";
import session, { sessionReducer } from "./session";
import closure, { closureReducer } from "./closures";
import credit, { creditReducer } from "./credit";
import activityTypes, { activityTypesReducer } from "./activityTypes";
import equipment, { equipmentReducer } from "./equipment";
import planning, { planningTypeReducer } from "./planningTypes";
import reporting, { reportingReducer } from "./reporting";
import configuration, { configurationReducer } from "./configuration";
import configuratioPlanning, {
  configurationPlanningReducer,
} from "./configurationPlanning";
import planningLeisure, {
  planningLeisureTypeReducer,
} from "./planningLeisureTypes";
import notification, { notificationReducer } from "./notification";
import creditBonus, { creditBonusReducer } from "./creditBonus";
import subscriberDetails, {
  subscriberDetailsReducer,
} from "./subscriberDetails";
import support, { supportReducer } from "./support";
import bookingConfiguration, { bookingConfigurationReducer } from "./bookingConfiguration";

export default function* rootSaga() {
  yield all([
    fork(authentication),
    fork(network),
    fork(role),
    fork(permission),
    fork(moduleAction),
    fork(compType),
    fork(complex),
    fork(complexTimes),
    fork(duration),
    fork(terrain),
    fork(terrainTimes),
    fork(team),
    fork(competition),
    fork(activity),
    fork(user),
    fork(sensor),
    fork(side),
    fork(resolution),
    fork(camera),
    fork(cameraPlanning),
    fork(reservation),
    fork(caisse),
    fork(currency),
    fork(discount),
    fork(parent),
    fork(child),
    fork(vat),
    fork(article),
    fork(hourType),
    fork(paymentMethod),
    fork(ticketInfo),
    fork(hourConfigs),
    fork(subscription),
    fork(priceManagements),
    fork(periodicity),
    fork(subscriptionType),
    fork(mixedHour),
    fork(subscriber),
    fork(video),
    fork(action),
    fork(booking),
    fork(videoPlanning),
    fork(contact),
    fork(session),
    fork(closure),
    fork(credit),
    fork(activityTypes),
    fork(equipment),
    fork(planning),
    fork(reporting),
    fork(configuration),
    fork(planningLeisure),
    fork(notification),
    fork(creditBonus),
    fork(subscriberDetails),
    fork(support),
    fork(configuratioPlanning),
    fork(bookingConfiguration),
  ]);
}
export const rootReducer = combineReducers({
  auth: authReducer,
  network: networkReducer,
  role: roleReducer,
  permission: permissionReducer,
  module: moduleReducer,
  compType: compTypeReducer,
  complex: complexReducer,
  complexTimes: complexTimesReducer,
  duration: durationReducer,
  terrain: terrainReducer,
  terrainTimes: terrainTimesReducer,
  team: teamReducer,
  competition: competitionReducer,
  activity: activityReducer,
  user: userReducer,
  sensor: sensorReducer,
  side: sideReducer,
  resolution: resolutionReducer,
  camera: cameraReducer,
  cameraPlanning: cameraPlanningReducer,
  reservation: reservationReducer,
  caisse: caisseReducer,
  currency: currencyReducer,
  discount: discountReducer,
  session: sessionReducer,
  parent: parentReducer,
  child: childReducer,
  vat: vatReducer,
  article: articleReducer,
  hourType: hourTypeReducer,
  paymentMethod: paymentMethodReducer,
  ticketInfo: ticketInfoReducer,
  hourConfigs: hourConfigsReducer,
  subscription: subscriptionReducer,
  subscriber: subscriberReducer,
  subscriberDetails: subscriberDetailsReducer,
  priceManagements: priceManagementsReducer,
  periodicity: periodicityReducer,
  subscriptionType: subscriptionTypeReducer,
  mixedHour: mixedHourReducer,
  video: videoReducer,
  action: actionReducer,
  booking: bookingReducer,
  videoPlanning: videoPlanningReducer,
  contact: contactReducer,
  closure: closureReducer,
  credit: creditReducer,
  activityType: activityTypesReducer,
  equipments: equipmentReducer,
  planningType: planningTypeReducer,
  reporting: reportingReducer,
  configuration: configurationReducer,
  planningLeisureType: planningLeisureTypeReducer,
  notification: notificationReducer,
  creditBonus: creditBonusReducer,
  support: supportReducer,
  configurationPlanning: configurationPlanningReducer,
  bookingConfiguration: bookingConfigurationReducer
});
