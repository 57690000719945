import { createActions, createReducer } from "reduxsauce";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as reservationService from "../../services/reservationService";
import { errorToast, validToast } from "../../helpers/customToast";
import { Creators as CaisseCreators } from "./caisse";
import i18n from "../../i18n/index";
import {
  addDiscountToTicket,
  caisseResponseToTicket,
} from "../../helpers/caisseFormatting";
import history from "../../helpers/history";
import { getPayments } from "../../helpers/reservationFormatting";
import { convertTicket, getAmountToPay } from "../../helpers/ticket";
import moment from "moment";

// Action types and creators
export const { Types, Creators } = createActions({
  loadingReservation: ["payload"],

  //update reservation from websocket
  addReservationRequest: ["payload"],
  updateReservationRequest: ["payload"],
  deleteReservationRequest: ["payload"],

  indexRequestReservation: ["payload"],
  indexResponseReservation: ["payload"],

  storeRequestReservation: ["payload"],
  storeResponseReservation: ["payload"],

  editRequestReservation: ["payload"],
  editResponseReservation: ["payload"],

  updateRequestReservation: ["payload"],
  updateResponseReservation: ["payload"],

  destroyRequestReservation: ["payload"],
  destroyResponseReservation: ["payload"],

  historyRequestReservation: ["payload"],
  historyResponseReservation: ["payload"],

  resetReservationHistory: [""],

  deletedRequestReservation: ["payload"],
  deletedResponseReservation: ["payload"],

  retrieveRequestReservation: ["payload"],
  retrieveResponseReservation: ["payload"],

  getReservationHourType: ["payload"],

  getReservationAvailableHour: ["payload"],

  getReservationArticle: ["payload"],

  InformationRequestReservation: ["payload"],
  InformationResponseReservation: ["payload"],
  clearInformationResponseReservation: [""],
  clearErrorResponse: [""],
  //getArticleReservationResponse:["payload"],
});

//
//Types.UPDATE_CURRENT_TICKET_REQUEST

// Initial state
const INITIAL_STATE = {
  information: "",
  reservations: [],
  hourTypes: [],
  addedReservation: null,
  reservation: null,
  loading: false,
  informationLoading: false,
  loadingHour: false,
  updated: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  historyLoading: false,
  addLoading: false,
  deleteLoading: false,
  cutLoading: false,
  copyLoading: false,
  EncaisseLoading: false,
  recoverLoading: false,
  availableHourLoading: false,
  pasteLoading: false,
  successStore: false,
  successCut: false,
  successCopy: false,
  successPaste: false,
  successUpdate: false,
  successDestroy: false,
  deletedReservations: [],
  availableHours: [],
  pageDeleted: 0,
  lastDeleted: 0,
  totalDeleted: 0,
  reservationHistory: [],
  pageHistory: 0,
  lastHistory: 0,
  totalHistory: 0,
  successRetrieve: false,
  silent: false,
  lastRefresh: moment().toString(),
};

export const getCaisseState = (state) => {
  return state.caisse;
};

export const getReservationState = (state) => {
  return state.reservation;
};

//index
function* index(action) {
  try {
    if (action.payload.hasOwnProperty("silent"))
      yield put(Creators.loadingReservation({ silent: true }));

    if (action.payload?.search?.is_leisure === 1)
      yield put(Creators.loadingReservation({ reservations: [] }));

    yield put(Creators.loadingReservation({ loading: true }));
    const data = yield call(reservationService.index, action.payload);
    yield put(Creators.indexResponseReservation(data));

    if (action.payload.hasOwnProperty("silent"))
      yield put(Creators.loadingReservation({ silent: false }));

    yield put(Creators.loadingReservation({ loading: false }));
  } catch (e) {
    if (action.payload.hasOwnProperty("silent"))
      yield put(Creators.loadingReservation({ silent: false }));

    yield put(Creators.loadingReservation({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    if (action.payload.copy)
      yield put(
        Creators.loadingReservation({
          addLoading: true,
          updated: false,
          addedReservation: null,
          errors: null,
        })
      );
    else
      yield put(
        Creators.loadingReservation({
          addLoading: true,
          updated: false,
          errors: null,
        })
      );

    let response = yield call(reservationService.store, action.payload);
    response = response.data;
    if (response.hasOwnProperty("data")) response = response.data;
    let data = {
      addLoading: false,
    };
    if (action.payload.copy)
      data = {
        addLoading: false,
        updated: true,
      };
    else {
      data = {
        addLoading: false,
        updated: true,
      };
    }

    if (response.hasOwnProperty("errors")) {
      data = {
        ...data,
        updated: false,
        errors: response.errors,
      };
    } else if (action.payload.copy) {
      data = {
        ...data,
        addedReservation: response,
      };
    }

    yield put(Creators.loadingReservation({ ...data }));

    if (!data.hasOwnProperty("errors"))
      yield validToast(i18n.t("reservation_successful_store"));
  } catch (e) {
    yield put(
      Creators.loadingReservation({
        addLoading: false,
        updated: false,
        errors: null,
      })
    );
    errorToast(i18n.t("reservation_failed_store"));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingReservation({ editLoading: true }));
    const data = yield call(reservationService.edit, action.payload);
    yield put(Creators.editResponseReservation(data));
    yield put(Creators.loadingReservation({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingReservation({ editLoading: false }));
  }
}

// //update
function* update(action) {
  try {
    yield put(
      Creators.loadingReservation({ addLoading: true, updated: false })
    );
    let response = yield call(reservationService.update, action.payload);
    let reservation = yield select(getReservationState);
    // yield put(Creators.updateResponseReservation(data));
    response = response.data;
    if (response.hasOwnProperty("data")) response = response.data;

    let data = {};
    if (action.payload.copy)
      data = {
        addLoading: false,
      };
    else {
      data = {
        addLoading: false,
        updated: true,
      };
    }

    if (response.hasOwnProperty("errors")) {
      data = {
        ...data,
        updated: false,
        errors: [...response.errors],
      };
    }

    if (!data.hasOwnProperty("errors")) {
      // let reservations = reservation.reservations.filter(it => it.id !== action.payload.information.reservation_id);
      yield put(Creators.loadingReservation({ ...data }));
      validToast(i18n.t("reservation_successful_updated"));
    }
    yield put(Creators.loadingReservation({ ...data }));
  } catch (e) {
    console.log("error in reservation update : ", e);
    errorToast(i18n.t("reservation_failed_updating"));
    yield put(
      Creators.loadingReservation({ addLoading: false, updated: false })
    );
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(
      Creators.loadingReservation({
        deleteLoading: true,
        successDestroy: false,
        updated: false,
      })
    );
    let reservation = yield select(getReservationState);
    yield call(reservationService.destroy, action.payload);
    //yield put(Creators.destroyResponseReservation(data));
    yield put(
      Creators.loadingReservation({
        deleteLoading: false,
        successDestroy: true,
        updated: true,
      })
    );
    let reservations = reservation.reservations.filter(
      (it) => it.id !== action.payload.id
    );
    yield put(Creators.loadingReservation({ reservations }));
    validToast(i18n.t("reservation_successful_delete"));
  } catch (e) {
    errorToast(i18n.t("reservation_failed_delete"));
    yield put(
      Creators.loadingReservation({
        deleteLoading: false,
        successDestroy: false,
        updated: false,
      })
    );
  }
}

//history
function* ReservationHistory(action) {
  try {
    yield put(Creators.loadingReservation({ historyLoading: true }));
    const data = yield call(
      reservationService.reservationHistory,
      action.payload
    );
    yield put(Creators.historyResponseReservation(data));
    yield put(Creators.loadingReservation({ historyLoading: false }));
  } catch (e) {
    yield put(Creators.loadingReservation({ historyLoading: false }));
  }
}

//Available hours used in reservation modal in caisse
function* reservationHours(action) {
  try {
    yield put(Creators.loadingReservation({ availableHourLoading: true }));
    const data = yield call(
      reservationService.reservationAvailableHours,
      action.payload
    );
    let availableHours = data.data;
    yield put(
      Creators.loadingReservation({
        availableHours,
        availableHourLoading: false,
      })
    );
  } catch (e) {
    yield put(Creators.loadingReservation({ availableHourLoading: false }));
  }
}

//deleted reservations
function* deletedReservation(action) {
  try {
    yield put(Creators.loadingReservation({ loading: true }));
    const data = yield call(
      reservationService.deletedReservation,
      action.payload
    );
    yield put(Creators.deletedResponseReservation(data));
    yield put(Creators.loadingReservation({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingReservation({ loading: false }));
  }
}

//retrieve deleted reservation
function* retrieve(action) {
  try {
    yield put(
      Creators.loadingReservation({ recoverLoading: true, updated: false })
    );
    const data = yield call(
      reservationService.retrieveReservation,
      action.payload
    );
    yield put(Creators.retrieveResponseReservation(data));
    yield put(
      Creators.loadingReservation({ recoverLoading: false, updated: true })
    );
  } catch (e) {
    yield put(
      Creators.loadingReservation({ recoverLoading: false, updated: false })
    );
  }
}

//load reservatio hour Types
function* loadReservationHourTypes(action) {
  try {
    yield put(Creators.loadingReservation({ loadingHour: true }));
    const response = yield call(
      reservationService.reservationHour,
      action.payload
    );

    yield put(
      Creators.loadingReservation({
        hourTypes: response.data.data,
        loadingHour: false,
      })
    );
  } catch (e) {
    yield put(Creators.loadingReservation({ loadingHour: false }));
  }
}

//get reservation information
function* reservationInformation(action) {
  try {
    yield put(Creators.loadingReservation({ informationLoading: true }));
    const data = yield call(
      reservationService.reservationInformation,
      action.payload
    );

    yield put(Creators.InformationResponseReservation(data));
    yield put(Creators.loadingReservation({ informationLoading: false }));
  } catch (e) {
    yield put(Creators.loadingReservation({ informationLoading: false }));
  }
}

function* getReservationArticle(action) {
  try {
    yield put(Creators.loadingReservation({ EncaisseLoading: true }));
    let response = yield call(
      reservationService.getArticleReservation,
      action.payload
    );
    response = response.data.data;

    let { isLeisure, all, user_id, type } = action.payload;

    let data = yield caisseResponseToTicket(response);
    let reservation = { ...response.reservation };

    let currentTicket = null;
    let others = {};

    if ((reservation.ticket && !isLeisure) || response.status === "error") {
      yield put(Creators.loadingReservation({ EncaisseLoading: false }));
      errorToast(i18n.t("ticket_already_linked"));
    } else if (!data) {
      yield put(Creators.loadingReservation({ EncaisseLoading: false }));
      if (!response.article) errorToast(i18n.t("article_not_found"));
      else errorToast(i18n.t("no_article_found_with_same_nb"));
    }

    if (data && !(reservation.ticket || response.status === "error")) {
      reservation.article_id = response.article ? response.article.id : null;
      let payments = yield getPayments(response.article, reservation);
      currentTicket = {
        ...data,
        user: reservation.user,
        payed_amount: payments.types.length > 0 ? payments.types[0].amount : 0,
        payments: payments,
        article_reservation: response.article ? response.article.id : null,
        is_credit: 0,
        is_advance: 0,
        is_credit_account: 0,
        owner_id: reservation.owner_id,
        reservation,
        created_reservation: reservation,
        id: "",
      };

      // partial amount > payed //=> payed
      // articles
      if (reservation.advance_ticket) {
        //article status
        currentTicket.articles[0].payed_amount =
          payments.types.length > 0 ? payments.types[0].amount : 0;
        currentTicket.articles[0].status =
          parseFloat(currentTicket.articles[0].payed_amount) <
          parseFloat(currentTicket.articles[0].total_price)
            ? "partial"
            : "payed";
        for (let i = 0; i < payments.participants.length; i++) {
          currentTicket.participants[i].payed_amount =
            payments.participants[i].amount;
          currentTicket.participants[i].status =
            parseFloat(currentTicket.participants[i].amount) >
            parseFloat(currentTicket.participants[i].payed_amount)
              ? "partial"
              : "payed";
        }
      }

      if (reservation.advance_ticket) {
        delete reservation.advance_ticket.reservation;
        let convertedTicket = convertTicket(reservation.advance_ticket);
        yield put(
          CaisseCreators.updateTicketRequest({
            ...convertedTicket,
            status: 0,
            // payed_amount: currentTicket.payments.types[0].amount,
          })
        );
      }
    } else if (data && reservation.ticket && isLeisure) {
      currentTicket = convertTicket(reservation.ticket);
    }

    if (currentTicket) {
      currentTicket = addDiscountToTicket(currentTicket, reservation);
      if (reservation.is_leisure) {
        let amountToPay = 0,
          restAmountToPay = 0;
        let articlePay = [];
        for (let i = 0; i < currentTicket.participants.length; i++) {
          let id = response.article ? response.article.id : "";
          if (
            Boolean(all) &&
            currentTicket.participants[i].status !== "payed"
          ) {
            articlePay.push(id.toString() + "-" + (i + 1));
          } else if (
            currentTicket.participants[i].status !== "payed" &&
            ((type === "exist" &&
              currentTicket.participants[i].user_id &&
              currentTicket.participants[i].user_id.toString() ===
                user_id.toString() &&
              currentTicket.participants[i].article_id.toString() ===
                id.toString()) ||
              (type === "new" && (i + 1).toString() === user_id.toString()))
          ) {
            articlePay.push(id.toString() + "-" + (i + 1));
          }
        }
        // fill articleToPay + amount
        if (articlePay.length && response.article) {
          let articleToPay = { [response.article.id]: articlePay };
          let amountToPay1 = getAmountToPay(currentTicket, articleToPay);
          amountToPay = amountToPay1[0];
          restAmountToPay = amountToPay1[1];
          others = { articleToPay, amountToPay, restAmountToPay };
          yield put(
            CaisseCreators.loadingCaisse({
              articleToPay,
              amountToPay,
              restAmountToPay,
            })
          );
        }
      }

      others = {
        ...others,
        selectSubFamily: response.article.parent_id,
        selectHourType: response.article.hour_type_id,
      };

      others = {
        ...others,
        advanceChecked: reservation.advance_ticket
          ? [reservation.advance_ticket]
          : null,
      };
      if (!currentTicket.id) {
        currentTicket.created_reservation.price =
          currentTicket.articles[0].total_price;
        yield put(
          CaisseCreators.storeTicketRequest({
            ...currentTicket,
            autoLoad: true,
            others,
          })
        );
      } else
        yield put(
          CaisseCreators.updateCurrentTicketResponse({ ...currentTicket })
        );
    }
    // yield put(
    //   Creators.loadingReservation({
    //     EncaisseLoading: false,
    //     advanceChecked: reservation.advance_ticket ? [reservation.advance_ticket] : null,
    //   }),
    // );

    if (
      data &&
      !(response.status === "error") &&
      history.location.pathname !== "/caisse"
    ) {
      if (currentTicket.id) {
        let state = yield select(getCaisseState);
        localStorage.setItem("ticket", JSON.stringify({ ...state, others }));
        // console.log('CurrentTicket: ', JSON.stringify({ ...state, others }));
        const winCheckout = window.open("/caisse?auto-load=true", "_blank");
        if (winCheckout) winCheckout.focus();
      } else if (
        data &&
        !(response.status === "error") &&
        history.location.pathname === "/caisse"
      ) {
        //yield put(CaisseCreators.caisseRedirectionRequest({ value: 'home' }));
      }
      yield put(Creators.loadingReservation({ EncaisseLoading: false }));
    }
  } catch (e) {
    console.log("error from getReservationArticle : ", e);
    yield put(Creators.loadingReservation({ EncaisseLoading: false }));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_RESERVATION, index);
  yield takeLatest(Types.STORE_REQUEST_RESERVATION, store);
  yield takeLatest(Types.EDIT_REQUEST_RESERVATION, edit);
  yield takeLatest(Types.UPDATE_REQUEST_RESERVATION, update);
  yield takeLatest(Types.DESTROY_REQUEST_RESERVATION, destroy);
  yield takeLatest(Types.HISTORY_REQUEST_RESERVATION, ReservationHistory);
  yield takeLatest(Types.DELETED_REQUEST_RESERVATION, deletedReservation);
  yield takeLatest(Types.RETRIEVE_REQUEST_RESERVATION, retrieve);
  yield takeLatest(Types.GET_RESERVATION_HOUR_TYPE, loadReservationHourTypes);

  yield takeLatest(Types.GET_RESERVATION_ARTICLE, getReservationArticle);

  yield takeLatest(
    Types.INFORMATION_REQUEST_RESERVATION,
    reservationInformation
  );
  //getReservationAvailableHour
  yield takeLatest(Types.GET_RESERVATION_AVAILABLE_HOUR, reservationHours);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    reservations: action.payload.data,
    last: action.payload.last_page,
    page: action.payload.current_page,
    total: action.payload.total,
    size: action.payload.per_page,
    from: action.payload.from,
    lastRefresh: moment().toString(),
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    reservation: action.payload.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

//history
const history_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    reservationHistory: [...action.payload.data],
    lastHistory: action.payload.last_page,
    pageHistory: action.payload.current_page,
    totalHistory: action.payload.total,
    sizeHistory: action.payload.per_page,
  };
};

//deleted reservation
const deleted_reserv_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deletedReservations: [...action.payload.data],
    lastDeleted: action.payload.last_page,
    sizeDeleted: action.payload.per_page,
    pageDeleted: action.payload.current_page,
    totalDeleted: action.payload.total,
  };
};
//retrieve deleted reservations
const retrieve_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successRetrieve: false,
  };
};
const retrieve_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successRetrieve: true,
  };
};
const reset_reservation_history = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    reservationHistory: [],
    pageHistory: 0,
    lastHistory: 0,
    totalHistory: 0,
    sizeHistory: 0,
  };
};

const reservation_information = (state = INITIAL_STATE, action) => {
  let response = action.payload.data.data.reservation;
  return {
    ...state,
    information: response,
  };
};

const clear_reservation_information = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    information: null,
  };
};

const clear_error = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errors: null,
  };
};

const add_reservation = (state = INITIAL_STATE, action) => {
  let reservations = state.reservations.filter(
    (it) => it.id !== action.payload.id
  );
  return {
    ...state,
    reservations: [...reservations, action.payload],
  };
};

const update_reservation = (state = INITIAL_STATE, action) => {
  let reservations = state.reservations.filter(
    (it) => it.id !== action.payload.id
  );
  return {
    ...state,
    reservations: [...reservations, action.payload],
  };
};
const delete_reservation = (state = INITIAL_STATE, action) => {
  let reservations = state.reservations.filter(
    (it) => it.id !== action.payload.id
  );
  return {
    ...state,
    reservations: [...reservations],
  };
};

// Reducer
export const reservationReducer = createReducer(INITIAL_STATE, {
  //addReservationRequest
  //updateReservationRequest
  //deleteReservationRequest
  [Types.ADD_RESERVATION_REQUEST]: add_reservation,
  [Types.UPDATE_RESERVATION_REQUEST]: update_reservation,
  [Types.DELETE_RESERVATION_REQUEST]: delete_reservation,
  [Types.INDEX_RESPONSE_RESERVATION]: index_response,
  [Types.STORE_RESPONSE_RESERVATION]: store_response,
  [Types.EDIT_RESPONSE_RESERVATION]: edit_response,
  [Types.UPDATE_RESPONSE_RESERVATION]: update_response,
  [Types.DESTROY_RESPONSE_RESERVATION]: destroy_response,
  [Types.LOADING_RESERVATION]: loading,
  [Types.HISTORY_RESPONSE_RESERVATION]: history_response,
  [Types.DELETED_RESPONSE_RESERVATION]: deleted_reserv_response,
  [Types.RETRIEVE_RESPONSE_RESERVATION]: retrieve_response,
  [Types.RETRIEVE_REQUEST_RESERVATION]: retrieve_request,
  [Types.RESET_RESERVATION_HISTORY]: reset_reservation_history,

  [Types.INFORMATION_RESPONSE_RESERVATION]: reservation_information,
  [Types.CLEAR_INFORMATION_RESPONSE_RESERVATION]: clear_reservation_information,
  //clearErrorResponse
  [Types.CLEAR_ERROR_RESPONSE]: clear_error,
});
