import React, { useCallback, useEffect, useState } from "react";

import { Button, Modal } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../CustomInput/CustomInput";
import {
  Creators,
  DEFAULT_SELECTED_OBJ,
} from "../../store/ducks/configurationPlanning";
import "./index.css";

const DEFAULT_DURATIONS = [
  {
    label: "00:15",
    value: "00:15",
  },
  {
    label: "00:30",
    value: "00:30",
  },
  {
    label: "00:45",
    value: "00:45",
  },
  {
    label: "01:00",
    value: "01:00",
  },
  {
    label: "01:15",
    value: "01:15",
  },
  {
    label: "01:30",
    value: "01:30",
  },
  {
    label: "01:45",
    value: "01:45",
  },
  {
    label: "02:00",
    value: "02:00",
  },
];

const PlanningConfigurationsModal = ({ open, onClose, onSuccess }) => {
  const [t] = useTranslation();
  const [selected, setSelected] = useState({ ...DEFAULT_SELECTED_OBJ });
  const { configuration, loading, success } = useSelector(
    (state) => state.configurationPlanning
  );
  const dispatch = useDispatch();

  const saveConfiguration = useCallback(
    (data) => {
      if (data.id) dispatch(Creators.updateRequestConfigurationPlanning(data));
      else dispatch(Creators.storeRequestConfigurationPlanning(data));
    },
    [dispatch]
  );

  const cleanupConfiguration = useCallback(
    (data) => {
      dispatch(Creators.loadingConfigurationPlanning(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (success) {
      if (onSuccess) onSuccess(configuration);
      onClose();
    }
    return () => cleanupConfiguration({ success: false });
  }, [success]);

  useEffect(() => {
    setSelected({ ...configuration });
  }, [configuration,open]);

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Modal.Header>
        <h3>{t("planning_configurations")}</h3>
      </Modal.Header>
      <Modal.Content>
        <div className="configuration-planning-container">
          <CustomInput
            name="slot_interval"
            label={t("slot_interval")}
            type={"custom-dropdown"}
            direction={0}
            options={DEFAULT_DURATIONS}
            keyword={"value"}
            filter={"label"}
            render={(it) => (
              <>
                <span>{it.label}</span>
              </>
            )}
            error={!selected.slot_interval}
            fetching={false}
            value={selected.slot_interval}
            onChange={(slot_interval) =>
              setSelected({ ...selected, slot_interval: slot_interval })
            }
          />
          <CustomInput
            name="show_side_menu"
            label={t("show_side_menu")}
            type={"checkbox"}
            checked={!!selected.show_side_menu}
            onChange={(slot_interval) =>
              setSelected({
                ...selected,
                show_side_menu: selected.show_side_menu ? 0 : 1,
              })
            }
          />
          <div className="configuration-planning-btns">
            <Button disabled={loading} loading={loading} onClick={onClose}>
              {t("cancel")}
            </Button>
            <Button
              color="red"
              disabled={loading || !selected.slot_interval}
              onClick={() => saveConfiguration(selected)}
              loading={loading}
            >
              {t("save")}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default PlanningConfigurationsModal;
