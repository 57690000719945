import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import moduleService from '@/services/moduleService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';
// Action types and creators
export const { Types, Creators } = createActions({
  loadingModule: ['payload'],

  indexRequestModule: null,
  indexResponseModule: ['data'],

  storeRequestModule: ['payload'],
  storeResponseModule: ['data'],

  editRequestModule: ['id'],
  editResponseModule: ['data'],

  updateRequestModule: ['payload'],
  updateResponseModule: ['data'],

  destroyRequestModule: ['id'],
  destroyResponseModule: ['data'],
});

// Initial state
const INITIAL_STATE = {
  modules: [],
  module: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingModule({ loading: true }));
    const data = yield call(moduleService.moduleService.index, action.payload);
    yield put(Creators.indexResponseModule(data));
    yield put(Creators.loadingModule({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingModule({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingModule({ loading: true }));
    const data = yield call(moduleService.moduleService.store, action.payload);
    yield put(Creators.storeResponseModule(data));
    yield put(Creators.loadingModule({ loading: false }));
    yield history.push('/configuration/modules');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingModule({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingModule({ editLoading: true }));
    const data = yield call(moduleService.moduleService.edit, action.payload);
    yield put(Creators.editResponseModule(data));
    yield put(Creators.loadingModule({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingModule({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingModule({ loading: true }));
    const data = yield call(moduleService.moduleService.update, action.payload);
    yield put(Creators.updateResponseModule(data));
    yield put(Creators.loadingModule({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/modules');
  } catch (e) {
    yield put(Creators.loadingModule({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingModule({ deleteLoading: true }));
    const data = yield call(moduleService.moduleService.destroy, action.payload);
    yield put(Creators.destroyResponseModule(data));
    yield put(Creators.loadingModule({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_MODULE, index);
  yield takeLatest(Types.STORE_REQUEST_MODULE, store);
  yield takeLatest(Types.EDIT_REQUEST_MODULE, edit);
  yield takeLatest(Types.UPDATE_REQUEST_MODULE, update);
  yield takeLatest(Types.DESTROY_REQUEST_MODULE, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    modules: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    module: action.data.data,
  };
};
//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding_module = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const moduleReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_MODULE]: index_response,
  [Types.STORE_RESPONSE_MODULE]: store_response,
  [Types.EDIT_RESPONSE_MODULE]: edit_response,
  [Types.UPDATE_RESPONSE_MODULE]: update_response,
  [Types.DESTROY_RESPONSE_MODULE]: destroy_response,
  [Types.LOADING_MODULE]: laoding_module,
});
