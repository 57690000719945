import React from 'react';
import { Checkbox, Dropdown, Flag, Icon, Popup } from 'semantic-ui-react';
import './Navbar.css';
import { connect } from 'react-redux';
import { Creators } from '@/store/ducks/authentication';
import i18n from '../../i18n';
import OverlayComponent from '../OverlayComponent/OverlayComponent';
import { imgUrl, switchLanguageInHtml, url } from '../../helpers';
import { checkModule, nullString } from '../../helpers/formatting';
import history from '../../helpers/history';
import SideTransition from '../SideTransition/SideTransition';
import { NavLink, withRouter } from 'react-router-dom';
import Image from '../../components/ImageComponent/Image';
import moment from 'moment';
import * as fr from 'moment/locale/fr';
import * as ar from 'moment/locale/ar';
import * as en from 'moment/locale/en-ca';
import NotificationPanel from '../NotificationPanel/NotificationPanel';

/**
 * @Component
 * Navbar contain the toggle icon for sidebar , name of the complex,notification icon,
 * username and picture
 *
 * */
class Navbar extends React.PureComponent {
  constructor(props) {
    super(props);
    let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'fr';
    this.state = {
      show: false,
      showRightMenu: false,
      showNotification: false,
      lang,
      checked: localStorage.getItem('dark') === 'dark',
    };


    this.myLanguage = [
      {
        text: (
          <span className={'navbar-flag'}>
            <Flag name={'us'}/>
            EN
          </span>
        ),
        value: 'en',
        key: 'en',
      },
      {
        text: (
          <span className={'navbar-flag'}>
            <Flag name={'fr'}/>
            FR
          </span>
        ),
        value: 'fr',
        key: 'fr',
      },
      {
        text: (
          <span className={'navbar-flag'}>
            <Flag name={'bh'}/>
            AR
          </span>
        ),
        value: 'ar',
        key: 'ar',
      },
    ];

    this.onSwitchMode = this.onSwitchMode.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  onLanguageChange(event, data) {
    let { value } = data;
    i18n
      .changeLanguage(value)
      .then(() => {
        localStorage.setItem('lang', value);
        switchLanguageInHtml(value);
        if (value === 'ar') {
          document.querySelector('#root').classList.add('container-ar');
        } else {
          document.querySelector('#root').classList.remove('container-ar');
        }
        this.setState({ lang: value });
      })
      .catch((e) => {});
  }

  onChangeHandler(event, data) {
    //
  }

  onSwitchMode() {
    this.setState({ checked: !this.state.checked });
    this.props.toggleDarkMode();
  }

  render() {
    let { type, firstName, lastName, avatar, user_id, allModule, roles, totalUnread } = this.props;
    let t = (s) => i18n.t(s);
    let localisation = localStorage.getItem('lang') === 'fr' ? fr : localStorage.getItem('lang') === 'ar' ? ar : en;

    const trigger = (
      <Image
        type={'user'}
        tabIndex="0"
        src={
          imgUrl + '/users/' + avatar
        }
        className={'navbar-avatar'}

      />
    );

    if (type === 'player')
      return (
        <div className="navbar-container">
          <div className="navbar">
            <div className="navbar-left"/>
            <div className="navbar-right">
              <span className="name">
                <Dropdown
                  icon={null}
                  defaultValue={localStorage.getItem('lang') ? localStorage.getItem('lang') : 'fr'}
                  onChange={this.onLanguageChange}
                  options={this.myLanguage}
                />
              </span>

              <span className="name">
                {' '}
                {firstName ? nullString(firstName[0]).toLowerCase() + '.' + nullString(lastName).toUpperCase() : 'Your Name'}
              </span>
              <Dropdown
                closeOnChange={false}
                labeled
                button
                onChange={this.onChangeHandler}
                trigger={trigger}
                icon={null}
                className="profile-dropdown"
                direction="left"
                // options={options}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text={
                      <span>
                        {i18n.t('signed_in_as')}{' '}
                        <strong> {firstName ? firstName[0] + '.' + lastName : 'Your Name'}</strong>
                      </span>
                    }
                    disabled
                  />
                  <Dropdown.Item text={i18n.t('your_profile')}/>
                  <Dropdown.Item
                    onClick={this.onSwitchMode}
                    text={
                      <span className="switch-mode">
                        {i18n.t('dark_mode')}
                        <Checkbox slider disabled checked={this.state.checked}/>
                      </span>
                    }
                  />
                  <Dropdown.Item text={i18n.t('settings')}/>

                  <Dropdown.Item text={i18n.t('sign_out')}/>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      );

    let path = this.props.location.pathname;
    return (
      <div className="navbar-container">
        <div className="navbar">
          <div className="navbar-left">
            {this.props.isMobile ? (
              <Popup
                position="bottom left"
                content={i18n.t('this_feature_is_disabled')}
                trigger={<Icon name="bars" className="navbar-icon" onClick={this.props.onPress}/>}
              />
            ) : (
              <Icon name="bars" className="navbar-icon" onClick={this.props.onPress}/>
            )}

            <span className="header">
              {i18n.t('complex_navbar') +
              ' ' +
              (this.props.selected ? this.props.selected.name : '--- ---')}{' '}
            </span>
          </div>
          {path === '/checkout' && <span className="header-date">
             {this.props.session && moment(this.props.session.date).isValid() && moment(this.props.session.date).locale(localStorage.getItem('lang'), localisation).format('dddd LL')}
            </span>}
          <div className="navbar-right">
            <Icon name="bell outline"
                  onClick={() => {
                    this.setState((prevState) => ({ showNotification: true }));
                  }}
                  className="navbar-icon">
              {!!totalUnread && <span className={'badge-nb'}/>}
            </Icon>
            <span className="name">
              <Dropdown
                icon={null}
                value={this.state.lang}
                onChange={this.onLanguageChange}
                options={this.myLanguage}
              />
            </span>

            <span className="name"> {firstName ? firstName[0] + '.' + lastName : 'Your Name'}</span>

            <Dropdown
              closeOnChange={false}
              labeled
              button
              onChange={this.onChangeHandler}
              trigger={trigger}
              icon={null}
              className="profile-dropdown"
              direction={localStorage.getItem('lang') === 'ar' ? 'right' : 'left'}
              // options={options}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text={
                    <span>
                      {i18n.t('signed_in_as')}{' '}
                      <strong> {firstName ? nullString(firstName[0]).toLowerCase() + '.' + nullString(lastName).toUpperCase() : 'Your Name'} </strong>
                    </span>
                  }
                  disabled
                />
                {roles !== null && Array.isArray(roles) && roles.length > 0 && <Dropdown.Item
                  text={
                    <span>
                      <strong> {roles[0].name} </strong>
                    </span>
                  }
                  disabled
                />}
                <Dropdown.Item
                  text={i18n.t('your_profile')}
                  onClick={() => history.push('/configuration/users/' + user_id + '/edit')}
                />
                <Dropdown.Item
                  onClick={this.onSwitchMode}
                  text={
                    <span className="switch-mode">
                      {i18n.t('dark_mode')}
                      <Checkbox slider disabled checked={this.state.checked}/>
                    </span>
                  }
                />
                <Dropdown.Item text={i18n.t('settings')}/>

                <Dropdown.Item onClick={this.props.logout} text={i18n.t('sign_out')}/>
              </Dropdown.Menu>
            </Dropdown>

            <span tabIndex={1}>
              <Icon
                name={'ellipsis vertical'}
                className={'navbar-ellipsis'}
                color={'black'}
                onClick={() => this.setState({ show: true })}
              >
                {this.state.show && (
                  <OverlayComponent onClose={() => this.setState({ show: false })}>
                    <div className={'navbar-complex-container'}>
                      <div className={'navbar-complex-header'}>
                        {/*<Icon name={'arrow left'} color={"black"}/>*/}
                        {i18n.t('complex')}
                      </div>

                      {this.props.complexes &&
                      this.props.complexes.map((it, i) => {
                        return (
                          <span
                            key={'com-' + i}
                            className={'navbar-complex-item'}
                            onClick={() =>
                              this.props.selected.toString() !== it.id.toString() &&
                              this.props.changeComplex(it.id)
                            }
                          >
                              <Image
                                type={'item'}
                                src={imgUrl + '/complexes/' + it.avatar}
                                className={'navbar-avatar'}
                              />
                            {/*<span>{i18n.t('complex') + ' ' + it.name}</span>*/}
                            <span>{it.name}</span>
                            {this.props.selected &&
                            it.id.toString() === this.props.selected.id.toString() ? (
                              <span className={'navbar-complex-selected'}>
                                  <Icon name={'circle'} color={'green'}/>
                                </span>
                            ) : (
                              <span className={'navbar-complex-selected'}>
                                  <Icon name={'circle outline'} color={'green'}/>
                                </span>
                            )}
                            </span>
                        );
                      })}
                    </div>
                  </OverlayComponent>
                )}
              </Icon>
            </span>
            {history.location.pathname !== '/' &&
            <Icon name="th" className="navbar-icon" onClick={() => this.setState({ showRightMenu: true })}/>}

          </div>
        </div>

        <SideTransition position={'right'}
                        size={220}
                        show={this.state.showRightMenu}
                        onClose={() => this.setState({ showRightMenu: false })}>


          <div className={'module-container'}>
            {allModule.map((it, i) => {
              if (it.slug !== 'dashboard' && it.slug !== 'player') {
                let isAvailable = checkModule(it.slug, this.props.modules);
                if (isAvailable)
                  return (
                    <NavLink
                      key={'side-menu-module-menu-' + i}
                      className={'side-menu-item-link'}
                      to={'/' + it.slug}
                    >
                      <div
                        className={'side-menu-item'}
                        style={{
                          background: it.background,
                          color: it.slug === 'reporting' ? '#C4C4C4' : '#FFFFFF',
                        }}
                      >
                        <Image src={url + '/uploads/modules/' + it.avatar} type={'item'} alt={it.name}/>
                        <h3>{t(it.slug)}</h3>
                      </div>
                    </NavLink>
                  );
                else
                  return null;
                // return (
                //   <span key={'side-menu-module-menu-' + i} className={'side-menu-item-link'}>
                //   <div
                //     className={'side-menu-item'}
                //     style={{
                //       background: it.slug === 'reporting' ? '#21212166' : '#21212144',
                //       color: it.slug === 'reporting' ? '#C4C4C4' : '#ececec',
                //     }}
                //   >
                //     <Image src={url + '/uploads/modules/' + it.avatar} type='item' alt={it.name}/>
                //     <h3>{t(it.slug)}</h3>
                //   </div>
                // </span>
                // );
              } else return null;

            })}
          </div>

        </SideTransition>

        {this.state.showNotification && <NotificationPanel
          toggle={() => {
            this.setState((prevState) => ({ showNotification: !prevState.showNotification }));
          }}
          show={this.state.showNotification}
        />}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user.id,
    lastName: state.auth.user.lastName,
    firstName: state.auth.user.firstName,
    avatar: state.auth.user.avatar,
    roles: state.auth.roles,
    selected: state.auth.selected,
    complexes: state.auth.complexes,
    allModule: state.auth.all_modules,
    modules: state.auth.selected.modules,
    session: state.session.session,
    totalUnread: state.notification.totalUnread,
  };
};
const mapDispatchToProps = {
  logout: Creators.logoutRequest,
  changeComplex: (data) => Creators.changeComplexRequest(data),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
