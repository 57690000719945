import { getMethod } from '@/helpers/index';


export default {
  reportingService:
    {
      //turnover reporting
      turnover: async ({ start_date, end_date }) => {
        const result = await getMethod('/reporting/turnover?complex_id=' +
          localStorage.getItem('complex_id') +
          (start_date ? '&start_date=' + start_date : '') +
          (end_date ? '&end_date=' + end_date : ''),
        );

        return result.data;
      },

      //reservation reporting
      reservations: async ({ start_date, end_date, reservation_type}) => {
        const result = await getMethod('/reporting/reservations?complex_id=' +
          localStorage.getItem('complex_id') +
          (start_date ? '&start_date=' + start_date : '') +
          (end_date ? '&end_date=' + end_date : '') +
          (reservation_type ? '&reservation_type=' + reservation_type : ''),
        );

        return result.data;
      },

      //sales reporting
      sales: async ({ start_date, end_date }) => {
        const result = await getMethod('/reporting/sales?complex_id=' +
          localStorage.getItem('complex_id') +
          (start_date ? '&start_date=' + start_date : '') +
          (end_date ? '&end_date=' + end_date : ''),
        );

        return result.data;
      },

      //subscription reporting
      subscriptions: async ({ start_date, end_date }) => {
        const result = await getMethod('/reporting/subscriptions?complex_id=' +
          localStorage.getItem('complex_id') +
          (start_date ? '&start_date=' + start_date : '') +
          (end_date ? '&end_date=' + end_date : ''),
        );

        return result.data;
      },

      //occupancy rate reporting
      occupancyRate: async ({data}) => {
        const result = await getMethod('/reporting/occupancyRate?complex_id=' +
          localStorage.getItem('complex_id') +
          (data.start_date ? '&start_date=' + data.start_date : '') +
          (data.end_date ? '&end_date=' + data.end_date : '') +
          (data.start_hour ? '&start_hour=' + data.start_hour : '') +
          (data.end_hour ? '&end_hour=' + data.end_hour : '') +
          (data.terrains && data.terrains.length > 0 ? '&terrains=[' + data.terrains.join(',') + ']' : '')
        );

        return result.data;
      },

      //reporting
      report: async ({ search_date, show_next }) => {
        //reporting
        const result = await getMethod('/reporting/planning?complex_id=' +
          localStorage.getItem('complex_id') +
          (search_date ? '&search_date=' + search_date : '') +
          (show_next ? '&show_next=' + show_next : ''),
        );

        return result.data;
      },
    },
};