import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import priceManagementsService from '../../services/priceManagementsService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingPriceManagements: ['data'],

  indexRequestPriceManagements: ['payload'],
  indexResponsePriceManagements: ['data'],


  storeRequestPriceManagements: ['payload'],
  storeResponsePriceManagements: ['data'],

  editRequestPriceManagements: ['id'],
  editResponsePriceManagements: ['data'],

  updateRequestPriceManagements: ['payload'],
  updateResponsePriceManagements: ['data'],

  clearReservationPriceManagements: [''],

  reservationRequestPriceManagements: ['payload'],
  reservationResponsePriceManagements: ['payload'],
});

// Initial state
const INITIAL_STATE = {
  PriceManagements: null,
  reservationPrice: null,
  information: {
    duration_id: '',
    activity_id: null,
    hour_type_id: '',
    day: '',
  },
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
};

//edit
function* index(action) {
  try {
    yield put(Creators.loadingPriceManagements({ loading: true }));
    const data = yield call(priceManagementsService.priceManagementsService.index, action.payload);
    yield put(Creators.indexResponsePriceManagements(data));

    yield put(Creators.loadingPriceManagements({ loading: false }));
  } catch (e) {
    yield put(Creators.indexResponsePriceManagements(null));
    yield put(Creators.loadingPriceManagements({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingPriceManagements({ editLoading: true }));
    const data = yield call(priceManagementsService.priceManagementsService.store, action.payload);
    yield put(Creators.storeResponsePriceManagements(data));
    yield put(Creators.loadingPriceManagements({ editLoading: false }));
    yield history.push('/configuration/priceConfigs');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingPriceManagements({
        editLoading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingPriceManagements({ editLoading: true }));
    const data = yield call(priceManagementsService.priceManagementsService.edit, action.payload);
    yield put(Creators.editResponsePriceManagements(data));

    yield put(Creators.loadingPriceManagements({ editLoading: false }));
  } catch (e) {
    yield put(Creators.editResponsePriceManagements(null));
    yield put(Creators.loadingPriceManagements({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingPriceManagements({ loading: true }));
    const data = yield call(priceManagementsService.priceManagementsService.update, action.payload);
    yield put(Creators.updateResponsePriceManagements(data));
    yield put(Creators.loadingPriceManagements({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/priceConfigs');
  } catch (e) {
    yield put(
      Creators.loadingPriceManagements({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}


function* reservation(action) {
  try {

    let information = { ...INITIAL_STATE.information, ...action.payload };
    yield put(Creators.loadingPriceManagements({ loading: true, information }));
    const data = yield call(priceManagementsService.priceManagementsService.reservationAmount, action.payload);
    yield put(Creators.reservationResponsePriceManagements(data));
    yield put(Creators.loadingPriceManagements({ loading: false }));
  } catch (e) {
    yield put(Creators.reservationResponsePriceManagements(null));
    yield put(Creators.loadingPriceManagements({ loading: false, information: { ...INITIAL_STATE.information } }));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_PRICE_MANAGEMENTS, index);
  yield takeLatest(Types.STORE_REQUEST_PRICE_MANAGEMENTS, store);
  yield takeLatest(Types.EDIT_REQUEST_PRICE_MANAGEMENTS, edit);
  yield takeLatest(Types.UPDATE_REQUEST_PRICE_MANAGEMENTS, update);
  yield takeLatest(Types.RESERVATION_REQUEST_PRICE_MANAGEMENTS, reservation);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    priceManagements: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};
//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    priceManagements: { ...action.data },
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

const reservation_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    reservationPrice: action.payload.data.amount,

  };
};

const clear_reservation_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    reservationPrice: INITIAL_STATE.reservationPrice,
    information: INITIAL_STATE.information,

  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const priceManagementsReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_PRICE_MANAGEMENTS]: index_response,
  [Types.STORE_RESPONSE_PRICE_MANAGEMENTS]: store_response,
  [Types.EDIT_RESPONSE_PRICE_MANAGEMENTS]: edit_response,
  [Types.UPDATE_RESPONSE_PRICE_MANAGEMENTS]: update_response,
  [Types.RESERVATION_RESPONSE_PRICE_MANAGEMENTS]: reservation_response,
  [Types.CLEAR_RESERVATION_PRICE_MANAGEMENTS]: clear_reservation_response,
  [Types.LOADING_PRICE_MANAGEMENTS]: loading,
});
