import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  cameraService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/cameras?complex_id=' + localStorage.getItem('complex_id') + 
        (page ? '&page=' + page :'') +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_resolution ? '&search_resolution=' + search.search_resolution: '')
        ,
      );
      return result.data;
    },
    store: async (data) => {
      let newData = {
        ...data,
        camera_config: { ...data.camera_config, lower_quality:data.lower_quality ? 1 : 0},
        ffmpeg_config: data.ffmpeg_config,
      };
      const result = await postMethod('/cameras', newData);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/cameras/' + id);
      return result.data;
    },
    update: async (data) => {
      let newData = {
        ...data,
        camera_config: { ...data.camera_config, lower_quality:data.camera_config.lower_quality ? 1 : 0},
        ffmpeg_config: data.ffmpeg_config,
      };
      const result = await putMethod('/cameras/' + data.id, newData);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/cameras/' + id);
      return result.data;
    },
  },
};
