import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import "./LazyLoading.css";

export const CustomSpinner = () => {
  return (
    <div
      className={
        "load-div " + (localStorage.getItem("dark") ? "load-div-dark" : "")
      }
      style={{ width: "100vw", height: "100vh" }}
    >
      <img src={require("../../assets/logo.svg")} alt={"INEO"} />
      <div className={"animated-bar-container"}>
        <div className={"animated-bar"} />
      </div>
    </div>
  );
};

const LazyLoading = ({ fn, customProps, big }) => {
  useEffect(() => {
    if (customProps.hasOwnProperty("sidebarProps"))
      customProps.sidebarProps.setModule(customProps.customProps.module);
    document.title = "Ineo - " + customProps.customProps.name;
  }, []);

  let { t } = useTranslation();
  let SomeComponent = React.lazy(fn);
  let line = CustomSpinner();
  const spinner = (
    <div className={"load-div"} style={{ width: "100vw", height: "100vh" }}>
      <Loader active={true} inline={"centered"} />
    </div>
  );
  return (
    <ErrorBoundary>
      <React.Suspense fallback={spinner}>
        <SomeComponent
          {...customProps}
          complex_id={customProps?.customProps?.options?.complex_id}
          {...customProps.sidebarProps}
          t={t}
        />
      </React.Suspense>
    </ErrorBoundary>
  );
};

export const Spinner = (
  <div className={"load-div"} style={{ width: "100vw", height: "100vh" }}>
    <Loader active={true} inline={"centered"} />
  </div>
);

export default LazyLoading;
