import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { getFormData, showFormData } from '../helpers/formDataHandler';

export default {
  supportMessageService: {
    store: async (data) => {
      let fd = new FormData();
      data.complex_id = localStorage.getItem('complex_id');
      fd = getFormData(data);
      
      const result = await postMethod('/supportMessages', fd);
      return result.data;
    },

    readAt: async (id) => {
      const result = await postMethod('/supportMessages/'+ id + '/readAt');
      return result.data;
    },

    destroy: async (id) => {
      const result = await deleteMethod('/supportMessages/' + id);
      return result.data;
    },
  },
};
