import React, { useState } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import './ReccurrenceComponent.css';

const outputData = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const inputLabelFr = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM'];
const inputLabelEn = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const ReccurrenceComponent = (props) => {
  const [values, setValues] = useState(filterInput(props.values));
  const labels = props.lang === 'fr' ? inputLabelFr : inputLabelEn;
  return (
    <div className={'reccurrence-container'}>
      {labels.map((it, i) => {
        return (
          <Form.Field
            key={'recc-check-' + i}
            error={props.error}
            className={'recc-input'}
            control={Checkbox}
            label={it}
            value={outputData[i]}
            checked={values[i] === 1}
            onChange={() => {
              let v = [...values];
              v[i] = values[i] === 1 ? 0 : 1;
              setValues(v);
              props.onChange(filterDays(v));
            }}
          />
        );
      })}
    </div>
  );
};

const filterInput = (values) => {
  let days = [];
  for (let i = 0; i < outputData.length; i++) {
    if (values.includes(outputData[i])) {
      days.push(1);
    } else {
      days.push(0);
    }
  }

  return days;
};

const filterDays = (days) => {
  let outputElement = [];
  for (let i = 0; i < days.length; i++) {
    if (days[i] === 1) outputElement.push(outputData[i]);
  }
  return outputElement;
};

ReccurrenceComponent.defaultProps = {
  error: false,
  lang: 'fr',
  values: new Array(outputData.length).fill(0),
};

export default ReccurrenceComponent;
