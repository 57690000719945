import React from "react";
import { Button, Form, Grid, Icon, Image, Input, Loader, Modal, Popup } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import i18n from "@/i18n";
import CustomInput from "@/components/CustomInput/CustomInput";
import { randomstring } from "@/helpers/formatting";
import {
  cumulArticlePayedAmountFromPaymentArticle,
  cumulDiscountArticlesFromParticipant,
  cumulDiscountsFromArticle,
  cumulParticipantPayedAmountFromPaymentParticipant,
  cumulPayedAmountFromPaymentTypes,
  cumulPaymentArticlesFromParticipant,
  cumulPaymentTypesFromArticle,
  cumulTotalDiscountFromDiscounts,
  getParentIdIsActivity,
  getParticipantDiscount,
  isSubscriptionPaymentType,
  setArticleStatus,
  setParticipantStatus
} from "@/helpers/ticket";
import { imgUrl } from "@/helpers";
import { getHT } from "@/helpers/caisse/tvaCalcul";
import { getAmountToPay } from "../../../helpers/ticket";
import moment from "moment";
import { Creators as SubscribersCreators } from "../../../store/ducks/subscriber";
import { normalDate, nullString } from "../../../helpers/formatting";
import { ticketAccordionStyle } from "../../../helpers/caisse/ticketStyle";
import ModalConfirm from "../../ModalConfirm/ModalConfirm";
import { Creators as reservationCreators } from "../../../store/ducks/reservation";
import { getCurrentNote, getCurrentNoteIndex, hasAllParticipantsByNote } from "../../../helpers/caisse/noteFormatting";
import { valeur } from "../../../pages/CaissePage/CaissePage";
import { getParticipantName } from "../../../helpers/caisseFormatting";
import { DEFAULT_COUNTRY_CODE } from "../../../utils/countryCode";
import { throttle } from "../../../helpers";


class TicketAccordionItem extends React.Component {
  constructor(props) {
    super(props);
    let somePassword = randomstring();

    this.state = {
      data: [],
      modalOpen: false,
      unpayModalOpen: false,
      modalContent: "",
      search_player: "",
      id: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      country_code: DEFAULT_COUNTRY_CODE,
      payment_type_list: [],
      subscription_credit_id: null,
      subscriber: null,
      subscriber_id: props.subscriber_id,
      complexes: Number(localStorage.getItem("complex_id")),
      teams: [],
      capitaine: [],
      password: somePassword,
      password_confirmation: somePassword,
      pending_ticket: "",
      amount: "",
      amount2: "",
      subscription: "",
      solde: "",
      expire: "",
      team_id: "",
      errors: {
        firstName: null,
        lastName: null,
        email: null
      },
      selected_participant: false,
      icon_type_payment: "",
      //credit_value: null,
      //confirmCreditModalOpen: false,
      user_index: null,
      cancelParticipantModalOpen: false
    };

    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  componentDidMount() {
    //load payed amount
    if (this.props.paymentTypes) {
      let subscriptionType = this.props.paymentTypes.filter(it => it.is_subscription === 1);
      let payedAmount = subscriptionType.length > 0 ? this.props.currentTicket.payments.participants.filter(it => it.user_index === this.props.id && it.payment_type_id === subscriptionType[0].id) : [];
      this.setState({ amount2: payedAmount.length > 0 ? payedAmount[0].amount : "" });
    }
    if (this.props.articleToPay) {
      if (this.props.article && this.props.articleToPay.hasOwnProperty(this.props.article.id) &&
        this.props.articleToPay[this.props.article.id].includes(this.props.id)) {
        this.setState({ selected_participant: true });
      } else {
        this.setState({ selected_participant: false });
      }
    }

    let payment_type_list = this.props.paymentTypes.map((item, index) => {
      return { ...item, amount: "" };
    });
    this.setState({ payment_type_list: payment_type_list });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    //select unselect participant
    if (this.props.articleToPay !== prevProps.articleToPay) {
      if (this.props.article && this.props.articleToPay.hasOwnProperty(this.props.article.id) &&
        this.props.articleToPay[this.props.article.id].includes(this.props.id)) {
        this.setState({ selected_participant: true });
      } else {
        this.setState({ selected_participant: false });
      }
    }

    // check reload is updated correctly
    if (prevProps.reloadUpdated !== this.props.reloadUpdated && this.props.reloadUpdated) {
      this.setState({
        subscription_credit_id: null
      });
    }

    if (prevProps.user !== this.props.user) {
      let team_id = "";
      if (this.props.currentTicket.reservation) {
        let filterParticipant = this.props.currentTicket.reservation.participants.filter(it => it.id === this.props.user.id);
        if (filterParticipant.length)
          team_id = filterParticipant[0].pivot.team_id;
        else if (this.props.currentTicket.reservation.participants.length)
          team_id = this.props.currentTicket.reservation.participants[0].pivot.team_id;
      }
      this.setState({
        ...this.state,
        id: this.props.user.id,
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        email: this.props.user.email,
        mobile: this.props.user.mobile,
        team_id
      });
    }
    if (
      !this.props.loading &&
      JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)
    ) {
      let errors = {
        firstName: null,
        lastName: null,
        email: null
      };
      for (let index in this.props.errors) {
        errors[index] = { content: this.props.errors[index][0] };
      }
      this.setState({ errors });
    }
    //set status of participant
    if (this.props.status !== prevProps.status && prevProps.status !== null) {
      this.setState({ status: this.props.status });
      if (this.props.status === "payed") {
        this.props.participant_to_partial_payment([]);
      }
    }

    // check if is expired
    if (this.state.subscriber && this.state.subscriber !== prevState.subscriber) {
      let { subscriber } = this.state;
      let isExpired = moment(normalDate(this.state.subscriber.expiry_date)).diff(moment(), "days") >= 0;
      if (subscriber.solde > 0 && isExpired) {
        let selectedArticle = this.props.currentTicket.participants.filter(it => it.user_index === this.props.id);
        let amountToPay = parseFloat(selectedArticle[0].amount) - parseFloat(selectedArticle[0].payed_amount);
        if (amountToPay > 0) {
          this.setState({ amount2: amountToPay > parseFloat(subscriber.solde) ? parseFloat(subscriber.solde).toFixed(2) : amountToPay });
        }
      } else
        this.setState({ amount2: "" });
    }

    //affect player
    if (this.props.client !== prevProps.client && this.state.user_index !== null) {
      this.affectPlayer(this.props.client);
      this.setState({ modalOpen: false, user_index: null });
    }

  };

  handleClose = () => {
    this.setState({ modalOpen: false, unpayModalOpen: false, cancelParticipantModalOpen: false });
  };

  /**
   * start select participant to pay
   **/
    // async restoreCreditOrAdvance(payments_type, id) {
    //   let currentTicket = { ...this.props.currentTicket };
    //   for (let payment_type of payments_type) {
    //     if (payment_type.credit_id && payment_type.user_index.toString() === id.toString()) {
    //       let creditTicket = await caisseService.caisseService.getCredit(payment_type.credit_id);
    //       creditTicket = creditTicket.data;
    //       creditTicket.payed_amount = parseFloat(creditTicket.payed_amount) - parseFloat(payment_type.amount);
    //       await caisseService.caisseService.updateTicket(creditTicket);
    //     }
    //     if (payment_type.advance_id && payment_type.user_index.toString() === id.toString()) {
    //       let advanceTicket = await caisseService.caisseService.getCredit(payment_type.advance_id);
    //       advanceTicket = convertTicket(advanceTicket.data);
    //       advanceTicket.payed_amount = parseFloat(advanceTicket.payed_amount) - parseFloat(payment_type.amount);
    //       //delete link advance/reservation
    //       if (advanceTicket.payed_amount === 0) {
    //         let reservation = currentTicket.reservation;
    //         let reservationTicket = { ...reservation };
    //         reservationTicket.is_advance = 0;
    //         reservationTicket.advance_id = null;
    //         reservationTicket.advance = null;
    //         reservationTicket.advance_ticket = null;
    //         let state = resetReservationState(); //convert reservation
    //         let reservationData = reservationResponse(state.reservationForm, reservationTicket);
    //         this.props.updateReservation(reservationData);
    //       }
    //       await caisseService.caisseService.updateTicket(advanceTicket);
    //     }
    //   }

    // }

  unpayParticipant = async () => {
    //to check
    let currentTicket = { ...this.props.currentTicket };
    let participant = currentTicket.participants.filter((itm) => itm.user_index === this.props.id);
    //restore credit/advance
    // this.restoreCreditOrAdvance(currentTicket.payments.participants, participant[0].user_index);

    let article = currentTicket.articles.filter((itm) => itm.id.toString() === this.props.article_id.toString());

    //set participant
    participant[0].status = null;
    participant[0].payed_amount = 0;

    await currentTicket.payments.participants.map((item, index) => {
      if (item.user_index === this.props.id) {
        currentTicket.payments.types.map((type, i) => {
          if (type.payment_type_id === item.payment_type_id) {
            type.amount = parseFloat(type.amount) - parseFloat(item.amount);
          }
        });
      }
    });

    let payment_participant = currentTicket.payments.participants.filter(
      (itm) => itm.user_index !== this.props.id
    );
    let selectedParticipant = currentTicket.payments.participants.filter(
      (itm) => itm.user_index === this.props.id
    )[0];
    let payment_types = currentTicket.payments.types.filter((itm) => itm.amount !== 0);
    let payment_articles = currentTicket.payments.articles.filter(
      (itm) => itm.article_id.toString() !== selectedParticipant.article_id.toString()
    );
    currentTicket.payments.types = payment_types;
    currentTicket.payments.articles = payment_articles;
    currentTicket.payments.participants = payment_participant;
    let partsCumuli = [];
    if (selectedParticipant)
      partsCumuli = cumulPaymentArticlesFromParticipant(currentTicket, selectedParticipant.article_id);

    payment_articles = [
      ...payment_articles,
      ...partsCumuli
    ];
    //vat for article payment ticket (vat exact)
    payment_articles.map((it, i) => {
      if (it.article_id.toString() === this.props.article_id.toString()) {
        it.amount_ht = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
          getHT(parseFloat(it.amount), article[0].vat.value);
        it.vat_amount = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
          (parseFloat(it.amount) - parseFloat(getHT(parseFloat(it.amount), article[0].vat.value))).toFixed(3);
        it.vat_id = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? null : article[0].vat_id;
      }

    });

    currentTicket.payments.articles = payment_articles;
    currentTicket.payments.types = cumulPaymentTypesFromArticle(currentTicket);
    currentTicket.payed_amount = cumulPayedAmountFromPaymentTypes(currentTicket);
    article[0].payed_amount = cumulArticlePayedAmountFromPaymentArticle(currentTicket, article[0].id);
    article[0].status = setArticleStatus(currentTicket, article[0]);

    await this.props.updateCurrentTicket(currentTicket);
    await this.props.updateTicket(currentTicket);

    //reset
    this.handleClose();
    this.props.article_to_pay([], 0, 0);
    this.props.article_to_partial_payment([]);
    this.props.participant_to_partial_payment([]);
    this.props.caisse_redirection("home");
  };

  updateParticipantPayment = async () => {
    const currentTicket = this.props.currentTicket;

    this.props.updateCurrentNote(this.props.note_index);
    currentTicket.noteInProgress = this.props.note_index ? true : false;
    this.props.updateCurrentTicket({ ...currentTicket });

    await this.props.article_to_edit_payment(null);
    await this.props.participant_to_edit_payment(this.props.id);
    await this.props.caisse_redirection("payment");
    this.handleClose();
  };

  deleteNote(ticket) {
    //cancel note from ticket
    let participants = ticket.payments.participants.filter(itm => itm.note_index !== this.props.currentNote);
    ticket.payments.participants = participants;

    let articles = ticket.payments.articles.filter(itm => itm.note_index !== this.props.currentNote);
    ticket.payments.articles = articles;

    ticket.payments.types = cumulPaymentTypesFromArticle(ticket);
    ticket.payed_amount = cumulPayedAmountFromPaymentTypes(ticket);

    ticket.articles.forEach((item, index) => {
      item.payed_amount = cumulArticlePayedAmountFromPaymentArticle(ticket, item.article_id);
      item.status = setArticleStatus(ticket, item);
    });

    ticket.participants.forEach((item, index) => {
      item.payed_amount = cumulParticipantPayedAmountFromPaymentParticipant(ticket, item.user_index);
      item.status = setParticipantStatus(ticket, item);
    });

    //discount
    let participantsDiscount = ticket.discountTypes.participants.filter(itm => itm.note_index !== this.props.currentNote);
    ticket.discountTypes.participants = participantsDiscount;

    let articlesDiscount = ticket.discountTypes.articles.filter(itm => itm.note_index !== this.props.currentNote);
    ticket.discountTypes.articles = articlesDiscount;

    ticket.discountTypes.discounts = cumulDiscountsFromArticle(ticket);
    ticket.discount = cumulTotalDiscountFromDiscounts(ticket);


    //delete note
    let notes = ticket.notes.filter(itm => itm.note_index !== this.props.currentNote);
    ticket.notes = notes;
    ticket.noteInProgress = false;
    this.props.updateCurrentNote(null);
    this.props.updateTicket(ticket);
    this.handleClose();
    //this.props.caisse_redirection('ticket', 1 , 'ticket' ,  moment().format('YYYY-MM-DD') );
  }

  renderUnpayParticipantModal() {
    return (
      <Modal
        open={this.state.unpayModalOpen}
        onClose={this.handleClose}
        size="tiny"
        className="modal-ticket"
      >
        <Modal.Content className="modal-content">
          <h3
            className="modal-content-header">Modifier{this.props.currentTicket.status === 1 && <>Annuler</>} paiement</h3>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={this.handleClose}
            />
            <p>Voulez vous modifier {this.props.currentTicket.status === 1 && <>ou annuler</>} le
              paiement du participant sélectionné? </p>
            <span className="btn-group">
              <Button className="modal-delete-btn" onClick={this.updateParticipantPayment}>
                Modifier paiement
              </Button>
              {this.props.currentTicket.status === 1 &&
              <Button className="modal-cancel-btn" onClick={this.unpayParticipant}>
                Annuler paiement
              </Button>
              }
            </span>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  selectParticipantToPay = async () => {
    let ticket = { ...this.props.currentTicket };
    let notes = [...this.props.currentTicket.notes];
    let currentNote = this.props.currentNote;
    let noteInProgress = ticket.noteInProgress;
    if (this.props.status === "payed") {
      this.setState({ unpayModalOpen: true });
    } else {
      if (this.props.caisseRedirection.value === "discount") {
        this.props.caisse_redirection("home");
      }
      let list_participant = [];
      let articleToPay = { ...this.props.articleToPay };
      let participantToPartialPayment = [...this.props.participantToPartialPayment];
      let articleToPartialPayment = [...this.props.articleToPartialPayment];
      if (articleToPay[this.props.article_id]) {
        list_participant = articleToPay[this.props.article_id];
      }

      //select participant
      if (!this.state.selected_participant) {
        if (currentNote !== this.props.participant.note_index && this.props.participant.note_index) {
          this.toggleNote(ticket, this.props.participant.note_index);
          currentNote = this.props.participant.note_index;
          if (currentNote) noteInProgress = true;
          articleToPay = {};
          list_participant = [];
        }

        if (this.props.status === "partial" && this.props.currentNote !== this.props.participant.note_index)
          return;
        //partial payment case
        if (this.props.status === "partial") {
          if (!participantToPartialPayment.includes(this.props.id)) {
            participantToPartialPayment.push(this.props.id);
          }
        }

        list_participant.push(this.props.id);
        articleToPay[this.props.article_id] = list_participant;
        //note
        if (noteInProgress) {
          if (notes.length > 0) {
            //unselect article and participant
            if (this.props.participant.note_index === null) {
              delete articleToPay[this.props.article_id];
            }


            let index = getCurrentNoteIndex(ticket, currentNote);


            if (this.props.id && !this.props.participant.status && this.props.participant.note_index !== currentNote) {
              let part = null;
              ticket.participants.forEach(itm => {
                if (itm.user_index === this.props.id) {
                  itm.note_index = currentNote;
                  part = itm;
                  if ((index < notes.length) && ((!notes[index].user_id && itm.user_id) || (!notes[index].user_index))) {
                    notes[index].user_id = itm.user_id;
                    notes[index].user_index = itm.user_index;
                    notes[index].user = itm.user;
                    let name = getParticipantName(itm);
                    notes[index].name = !!name ? name : "Joueur " + itm.user_index.split(valeur)[1];
                  }
                }
              });
              let selected = [];
              //switch discount to note
              ticket.discountTypes.participants.forEach(itm => {
                if (itm.user_index === this.props.id) {
                  itm.note_index = currentNote;
                  selected.push(itm);
                }
              });

              let participantWithoutNote = ticket.participants.filter((it) => it.note_index !== part.note_index);
              // check if it has the all note
              if (hasAllParticipantsByNote(ticket, part.article_id, part.note_index) || participantWithoutNote.length === 0) {
                ticket.articles.forEach(itm => {
                  if (itm.id === part.article_id && itm.note_index === null) itm.note_index = part.note_index;
                });
                ticket.discountTypes.articles.forEach(itm => {
                  if (itm.article_id === part.article_id) itm.note_index = part.note_index;
                });
              }

              let articleDiscount = ticket.discountTypes.articles.filter(itm => itm.article_id !== this.props.article_id);
              ticket.discountTypes.articles = [...articleDiscount, ...cumulDiscountArticlesFromParticipant(ticket, this.props.article_id)];

            }
            this.props.updateCurrentTicket(ticket);
          }
        }
      }
      //deselect one participant
      else {
        if (this.props.status === "partial") {
          participantToPartialPayment = participantToPartialPayment.filter(
            (itm) => itm !== this.props.id
          );
        }

        if(this.props.pendingClassicSub){
          this.props.updatePendingClassicSub(null);
        }

        //pass from selection of an entire activity to selection of participants list with partial participant
        let partialParticipants = ticket.participants.filter(item => item.status === "partial" && item.user_index !== this.props.id)
          .map(item => {
            return item.user_index;
          });
        partialParticipants.map(partialParticipant => {
          Object.entries(articleToPay).map((article_to_pay, i) => {
            if (this.props.article_id === article_to_pay[0] && article_to_pay[1].includes(partialParticipant)) {
              if (!participantToPartialPayment.includes(partialParticipant)) {
                participantToPartialPayment.push(partialParticipant);
              }
            }
          });
        });

        //pass from selection of partial activity to selection of participants list without partial participant
        if (this.props.article.status === "partial" && articleToPartialPayment.includes(this.props.article_id)) {
          articleToPartialPayment = articleToPartialPayment.filter(
            (itm) => itm !== this.props.article_id
          );
        }

        list_participant = list_participant.filter((itm) => itm !== this.props.id);
        articleToPay[this.props.article_id] = list_participant;

        //delete article from list => unselect all participants
        if (list_participant.length === 0) {
          delete articleToPay[this.props.article_id];
        }
      }

      this.props.article_to_pay(
        articleToPay,
        getAmountToPay(this.props.currentTicket, articleToPay, this.props.participant.note_index)[0],
        getAmountToPay(this.props.currentTicket, articleToPay, this.props.participant.note_index)[1]
      );
      this.props.participant_to_partial_payment(participantToPartialPayment);
      this.props.article_to_partial_payment(articleToPartialPayment);
    }
  };

  /**
   * end select participant to pay
   **/
  onChangeHandle(event, eventMobile = null) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if (eventMobile && eventMobile.target)
      this.setState({
        [eventMobile.target.name]: eventMobile.target.value
      });
  }

  onChangeTeam = (id, data) => {
    if (!id) {
      this.setState({ team_id: null });
    } else {
      this.setState({ team_id: id });
    }
  };

  toggleNote = (currentTicket, note_index) => {
    let articleToPay = {};
    let articleToPartialPayment = [];
    if (note_index !== this.props.currentNote) {
      const currentTicket = { ...this.props.currentTicket };
      articleToPay = {};
      articleToPartialPayment = [];
      this.props.article_to_pay(articleToPay, 0, 0);
      this.props.participant_to_partial_payment([]);
      this.props.article_to_partial_payment(articleToPartialPayment);
      this.props.updateCurrentNote(note_index);
      currentTicket.noteInProgress = note_index ? true : false;
      this.props.updateCurrentTicket({ ...currentTicket });
    }
  };

  renderAddPlayer() {
    let { roles } = this.props.role;
    return (
      <>
        <div className="player-input">
          <CustomInput
            name={"lastName"}
            label={i18n.t("last_name")}
            type={"text"}
            value={this.state.lastName}
            onChange={this.onChangeHandle}
            error={this.state.errors.lastName !== null}
            errormsg={this.state.errors.lastName}
          />
          <CustomInput
            name={"firstName"}
            label={i18n.t("first_name")}
            type={"text"}
            value={this.state.firstName}
            onChange={this.onChangeHandle}
            error={this.state.errors.firstName !== null}
            errormsg={this.state.errors.firstName}
          />
          <CustomInput
            name={"mobile"}
            label={i18n.t("mobile")}
            type={"phone"}
            value={this.state.mobile}
            countrycode={this.state.country_code}
            onChange={this.onChangeHandle}
          />
          <CustomInput
            name={"email"}
            label={i18n.t("email")}
            type={"text"}
            value={this.state.email}
            onChange={this.onChangeHandle}
            error={this.state.errors.email !== null}
            errormsg={this.state.errors.email}
          />
        </div>
        <br/>
        <Button
          className="player-green-btn"
          loading={this.props.loading}
          disabled={this.props.loading}
          onClick={this.handleThrottleSubmit}
        >
          {i18n.t("save")}
        </Button>
      </>
    );
  }

  handleSubmit = () => {
    let stateWithoutError = { ...this.state };
    let { roles } = this.props.role;
    delete stateWithoutError.error;
    delete stateWithoutError.payment_type_list;
    let r = roles.filter((it) => {
      return it.slug === "client";
    });
    let data = {
      permissions: this.props.permissions,
      ...stateWithoutError,
      roles: r.length > 0 ? r[0].id : null,
      checkout: true
    };
    this.props.store(data);
    this.setState({ content: "search", user_index: this.props.id });
  };

  handleThrottleSubmit = throttle(this.handleSubmit, 2000);

  affectPlayer(user) {
    let data = { ...this.props.currentTicket };
    let selectedParticipant = null;
    if (data.reservation) {
      data.created_reservation = { ...data.reservation };
    }

    data.participants.map((item, i) => {
      if (item.user_index.toString() === this.props.id.toString()) {
        item["user_id"] = user.id;
        item["firstName"] = user.firstName;
        item["lastName"] = user.lastName;
        if (data.reservation) {
          let participants = data.created_reservation.participants;
          if (i >= participants.length) {
            participants.push(
              {
                ...user,
                pivot: {
                  contact_id: null,
                  team_id: this.state.team_id,
                  sensor_id: null,
                  contact: null
                }
              }
            );
          } else {
            participants[i] = {
              ...user,
              pivot: {
                contact_id: null,
                team_id: this.state.team_id,
                sensor_id: null,
                contact: null

              }
            };
          }
        }
      }
    });
    //note division case (affect/edit user_id after select note user)

    data.notes.forEach(note => {
      if (data.noteInProgress) {
        if (note.note_index === this.props.currentNote && !note.user_id) {
          note.user_id = user.id;
          note.name = user.firstName + " " + user.lastName;
        }
      }
    });

    this.props.updateCurrentTicket(data);

    this.handleClose();
  }

  payHandle = () => {
    let currentTicket = { ...this.props.currentTicket };
    let participants = { ...currentTicket.payments.participants };
    let currentParticipant = null;
    participants.map((it, i) => {
      if (it.user_index === this.props.id) currentParticipant = it;
    });
  };

  participantPayment(type_payment_id, amount, current_participant) {
    let currentTicket = { ...this.props.currentTicket };
    let participants = { ...currentTicket.payments.participants };
    participants = Object.values(participants);

    //get participant amount
    let payment_type_amount = 0;
    //get sum amount of old payment type of participant
    let sum_old_payed_amount = 0;
    let paymentTypesParticipant = currentTicket.payments.participants.filter(
      (itm) => itm.user_index === current_participant.user_index
    );
    paymentTypesParticipant.map((item, index) => {
      sum_old_payed_amount = parseFloat(sum_old_payed_amount) + parseFloat(item.amount);
    });
    //payment not totaly payed
    let total_price =
      parseFloat(current_participant.amount) -
      parseFloat(getParticipantDiscount(currentTicket, current_participant.user_index));
    if (parseFloat(sum_old_payed_amount) < parseFloat(total_price)) {
      let rest_amout_to_pay = parseFloat(total_price) - parseFloat(sum_old_payed_amount);
      if (rest_amout_to_pay > amount) {
        payment_type_amount = parseFloat(amount);
      } else {
        payment_type_amount = parseFloat(rest_amout_to_pay);
      }
    }
    if (payment_type_amount > 0) {
      let participant = {
        user_id: current_participant.user_id,
        user_index: current_participant.user_index,
        article_id: current_participant.article_id,
        amount: parseFloat(payment_type_amount).toFixed(2),
        payment_type_id: type_payment_id
      };
      participants.push(participant);
    }

    currentTicket.payments.participants = participants;

    return parseFloat(amount) - parseFloat(payment_type_amount);
  }

  renderAffectPlayerModal() {
    let { reservation } = this.props.currentTicket;
    let teams = [];
    let teamOptions = [];
    if (reservation && reservation.match) {
      if (reservation.match.team_a) {
        teamOptions.push(reservation.match.team_a);
        teams.push(reservation.match.team_a);
      }

      if (reservation.match.team_b) {
        teamOptions.push(reservation.match.team_b);
        teams.push(reservation.match.team_b);
      }

    }

    let isDisabled = false;
    teams.forEach(it => {
      if (!it.is_default && !isDisabled) isDisabled = true;
    });

    return (
      <Form>
        {this.props.editLoading ? (
          <Loader/>
        ) : (
          <>
            <Grid columns={2}>
              <Grid.Column className="player-input">
                <CustomInput
                  name={"lastName"}
                  label={i18n.t("last_name")}
                  type={"text"}
                  value={this.state.lastName}
                  onChange={this.onChangeHandle}
                  readOnly={true}
                />
                <CustomInput
                  name={"firstName"}
                  label={i18n.t("first_name")}
                  type={"text"}
                  value={this.state.firstName}
                  onChange={this.onChangeHandle}
                  readOnly={true}
                />
                <CustomInput
                  name={"mobile"}
                  label={i18n.t("mobile")}
                  type={"phone"}
                  value={this.state.mobile}
                  countrycode={this.state.country_code}
                  onChange={this.onChangeHandle}
                  readOnly={true}
                />
                <CustomInput
                  name={"email"}
                  label={i18n.t("email")}
                  type={"text"}
                  value={this.state.email}
                  onChange={this.onChangeHandle}
                  readOnly={true}
                />

              </Grid.Column>
              <Grid.Column className="player-input">
                <CustomInput
                  name={"pending_ticket"}
                  label={i18n.t("pending_ticket")}
                  type={"text"}
                  value={this.props.currentTicket.number}
                  readOnly={true}
                />
                {teamOptions.length > 0 && <CustomInput
                  name={"team"}
                  label={i18n.t("team")}
                  fetching={false}
                  options={teamOptions}
                  // disabled={isDisabled}
                  type={"custom-dropdown"}
                  render={(it) => (
                    <>
                      <span>{it.name}</span>
                    </>
                  )}
                  value={this.state.team_id}
                  onChange={this.onChangeTeam}
                />}
              </Grid.Column>
            </Grid>
            <div className="group-btn-edit-player">
              <Button
                disabled={teamOptions ? !this.state.team_id : false}
                className="btn-select"
                content={this.props.user_id ? i18n.t("edit") : i18n.t("select")}
                onClick={() => {
                  this.affectPlayer(this.props.user);
                }}
              />


              <Button
                className="btn-select"
                content={i18n.t("cancel")}
                onClick={() => {
                  this.handleClose();
                }}
              />


            </div>
          </>
        )}
      </Form>
    );
  }

  handleChangeSearch(event) {
    this.setState({
      [event.target.name]: event.target.value,
      modalContent: ""
    });
    this.props.searchPlayer(this.props.page, this.props.size, event.target.value);
  }

  renderSearchContent() {
    let filter_participants = this.props.currentTicket.participants.filter((itm) => itm.user_id);
    let exist_player = filter_participants.map((participant, i) => {
      if (participant.user_id) {
        return participant.user_id;
      }
    });
    return this.props.users.map((item, index) => {
      if (!exist_player.includes(item.id)) {
        return (
          <div key={index} className="player-row">
            <span>
              {nullString(item.firstName).toLowerCase()} {nullString(item.lastName).toUpperCase()}
            </span>
            <span>{item.mobile}</span>
            <span>{item.email}</span>
            <span style={{ color: "#7EB5E5" }}>
              <Icon
                name="edit"
                onClick={() => {
                  this.setState({ modalContent: "affect player", id: item.id });
                  this.props.editPlayer(item.id);
                }}
              />
            </span>
          </div>
        );
      }
    });
  }

  renderEditPlayerModal() {
    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="large"
        className="modal-ticket modal-edit-player"
      >
        <Modal.Content className="modal-content">
          <h3 className="modal-content-header">{i18n.t("player_info")}</h3>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={this.handleClose}
            />
            <div className="search-bloc">
              {this.state.modalContent !== "add player" && (
                <>
                  <Input
                    name="search_player"
                    className="search-input-player"
                    icon="search"
                    placeholder="Recherche"
                    onChange={this.handleChangeSearch}
                    value={this.state.search_player}
                  />
                  {this.state.modalContent === "" && (
                    <div className="add-player">
                      <Button
                        icon="plus"
                        color="blue"
                        circular
                        size="tiny"
                        onClick={() => this.setState({
                          modalContent: "add player",
                          firstName: "",
                          lastName: "",
                          mobile: "",
                          email: ""
                        })}
                      />
                      {i18n.t("add_player")}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="player-informations">
              {this.state.modalContent === "" && this.state.search_player !== "" && (
                <div className="search-list">
                  {this.props.loading ? <Loader/> : this.renderSearchContent()}
                </div>
              )}
              {this.state.modalContent === "affect player" && (
                <div className="edit-player-bloc">{this.renderAffectPlayerModal()}</div>
              )}
              {this.state.modalContent === "add player" && (
                <>
                  <div className="back-btn">
                    <Button
                      content="Retour"
                      onClick={() => this.setState({ modalContent: "" })}
                    /><br/>
                  </div>
                  <div className="add-player-bloc">{this.renderAddPlayer()}</div>
                </>
              )}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  getPaymentIconOfParticipant() {
    if (this.props.status === "payed" || this.props.status === "partial") {
      let participant_payment = this.props.currentTicket.payments.participants.filter(
        (itm) => itm.user_index === this.props.id
      );
      let participant_payment_nb = Object.entries(participant_payment).length;
      if (participant_payment_nb < 3) {
        return (
          <div className="payment-icon-group">
            {participant_payment.map((item, index) => {
              if (index <= 2) {
                return this.props.paymentTypes.map((itm, i) => {
                  if (item.payment_type_id === itm.id) {
                    return (
                      <Popup
                        key={i}
                        wide
                        trigger={
                          <Image
                            className={
                              this.props.status === "payed" ?
                                (this.props.participantToEditPayment === this.props.id ?
                                  "ticket-accord-item-payment-edit" : "ticket-accord-item-payment")
                                : "ticket-accord-item-payment-partial"
                            }
                            src={itm.icon ? imgUrl + "/payments/" + itm.icon : require("@/assets/other.svg")}
                          />
                        }
                      >
                        <span style={{ color: "#00B876" }}>{item.amount}{localStorage.getItem("currency")}</span>
                      </Popup>

                    );
                  }
                });
              }
            })}
          </div>
        );
      } else {
        return (
          <div className="payment-icon-group">
            <Popup
              wide
              trigger={
                <Image
                  className={
                    this.props.status === "payed"
                      ? "ticket-accord-item-payment"
                      : "ticket-accord-item-payment-partial"
                  }
                  src={require("../../../assets/other_white.svg")}
                />
              }
            >
              {participant_payment.map((item, index) => {
                return (
                  <Grid columns="equal" style={{ color: "#00B876" }}>
                    {this.props.paymentTypes.map((itm, i) => {
                      if (item.payment_type_id === itm.id) {
                        return (
                          <>
                            <Grid.Column>
                              <Image
                                key={i}
                                className="ticket-accord-item-payment-popup"
                                src={imgUrl + "/payments/" + itm.icon}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              {item.amount}{localStorage.getItem("currency")}
                            </Grid.Column></>
                        );
                      }
                    })}
                  </Grid>
                );
              })}
            </Popup>
          </div>
        );
      }
    }
  }

  /**
   * cancel participant discount
   */
  cancelParticipantDiscount = async () => {
    let currentTicket = { ...this.props.currentTicket };
    if (currentTicket.discountTypes.participants && currentTicket.discountTypes.participants.length) {
      let participantsDiscount = currentTicket.discountTypes.participants.filter(item => item.user_index !== this.props.id);
      currentTicket.discountTypes.participants = participantsDiscount;
    }

    //set status
    if (currentTicket.participants && currentTicket.participants.length) {
      currentTicket.participants.map((item, index) => {
        if (item.user_index === this.props.id && item.status === "payed") {
          item["status"] = null;
        }
      });
    }

    let articleDiscount = currentTicket.discountTypes.articles.filter(itm => itm.article_id !== this.props.article_id);
    articleDiscount = [...articleDiscount, ...cumulDiscountArticlesFromParticipant(currentTicket, this.props.article_id)];
    currentTicket.discountTypes.articles = articleDiscount;
    currentTicket.discountTypes.discounts = cumulDiscountsFromArticle(currentTicket);
    currentTicket.discount = cumulTotalDiscountFromDiscounts(currentTicket);

    await this.props.updateCurrentTicket(currentTicket);

    this.props.caisse_redirection("home");
    this.props.select_family(getParentIdIsActivity(this.props.menusData));
    this.props.select_sub_family(0);
    this.props.select_hour_type(0);
  };

  renderCancelParticipantDiscountModal() {
    return (
      <ModalConfirm
        open={this.state.cancelParticipantModalOpen}
        onClose={this.handleClose}
        title={"Annuler la remise d'un participant"}
        message={
          <p>Voulez vous annuler la remise de ce participant?
          </p>
        }
        okText={"Oui"}
        ok={async () => {
          await this.cancelParticipantDiscount();
          this.handleClose();
        }}
        cancelText={"Non"}
        cancel={this.handleClose}

      />
    );
  }

  /**
   *
   */

  render() {
    const { isDetail } = this.props;
    return (
      <React.Fragment key={this.props.key}>
        {!isDetail && this.renderEditPlayerModal()}
        {!isDetail && this.renderUnpayParticipantModal()}
        {this.state.cancelParticipantModalOpen && this.renderCancelParticipantDiscountModal()}
        {/* {this.renderConfirmCreditModal()} */}
        <Button className="ticket-accord-item"
                style={ticketAccordionStyle[
                  this.props.status === "payed" ?
                    (this.props.participantToEditPayment &&
                    this.props.participantToEditPayment.toString() === this.props.id.toString() ?
                      "edited" : "payed") :
                    this.state.selected_participant ?
                      "selected" : this.props.status === null ? "default" : this.props.status
                  ]}
        >
          {!isDetail && this.props.currentTicket && this.props.status === null &&
          <Icon
            className="ticket-accord-item-edit"
            name="edit"
            // disabled={this.props.is_note === null}
            onClick={() => {
              this.setState({ modalOpen: true, modalContent: "" });
              if (this.props.type === "client") {
                this.setState({ modalContent: "affect player" });
                this.props.editPlayer(this.props.user_id);
              }
            }}
          />
          }

          {this.props.currentTicket && this.props.status === null && this.props.participant && !!this.props.participant.note_index &&
          <Icon
            className="ticket-accord-item-delete"
            name="trash"
            onClick={() => {
              // reset participant note_index / discount /without payment
              let currentTicket = { ...this.props.currentTicket };
              let id = this.props.id;

              let note_index = this.props.participant.note_index;
              let currentNote = getCurrentNote(currentTicket, note_index);

              if (currentNote && (this.props.participant.user_index === currentNote.user_index || this.props.participant.user_id === currentNote.user_id)) {
                let indexOfNote = getCurrentNoteIndex(currentTicket, note_index);
                // select a replacement participant
                let participants = currentTicket.participants
                  .filter(it => it.article_id === this.props.participant.article_id &&
                    it.note_index === note_index && it.user_index !== this.props.participant.user_index);
                if (participants.length > 0) {
                  //there's a replacement
                  let selectedParticipant = null;
                  participants.forEach(it => {
                    if (!selectedParticipant) selectedParticipant = it;
                    else if (selectedParticipant !== null && !selectedParticipant.user_id && it.user_id) {
                      selectedParticipant = it;
                    }
                  });
                  currentTicket.notes[indexOfNote].user_index = selectedParticipant.user_index;
                  currentTicket.notes[indexOfNote].user_id = selectedParticipant.user_id;
                  currentTicket.notes[indexOfNote].user = selectedParticipant.user ? { ...selectedParticipant.user } : null;
                  let name = getParticipantName(selectedParticipant);
                  currentTicket.notes[indexOfNote].name = !!name ? name : "Joueur " + selectedParticipant.user_index.split(valeur)[1];

                } else {
                  // delete user_id + user_index + user object + name
                  currentTicket.notes[indexOfNote].user_index = null;
                  currentTicket.notes[indexOfNote].user_id = null;
                  currentTicket.notes[indexOfNote].user = null;
                  currentTicket.notes[indexOfNote].name = "";
                }
              }

              currentTicket.participants.forEach(it => {
                if (it.user_index === id) it.note_index = null;
              });

              // affect new user if note.user_index has the same as participant

              currentTicket.discountTypes.participants.forEach(it => {
                if (it.user_index === id) it.note_index = null;
              });

              // let hasAll = hasAllParticipantsByNote(currentTicket,this.props.article_id,this.props.currentNote)
              // if(!hasAll)
              currentTicket.articles.forEach(it => {
                if (this.props.article_id.toString() === it.article_id.toString() && it.note_index === this.props.currentNote) it.note_index = null;
              });

              let articleDiscount = currentTicket.discountTypes.articles.filter(itm => itm.article_id !== this.props.article_id);
              currentTicket.discountTypes.articles = [...articleDiscount, ...cumulDiscountArticlesFromParticipant(currentTicket, this.props.article_id)];

              currentTicket.discountTypes.discounts = cumulDiscountsFromArticle(currentTicket);
              let list_participant = [];
              let articleToPay = this.props.articleToPay ? { ...this.props.articleToPay } : {};
              list_participant = articleToPay[this.props.article_id] ? articleToPay[this.props.article_id] : [];
              list_participant = list_participant.filter((itm) => itm !== this.props.id);
              articleToPay[this.props.article_id] = list_participant;

              //delete article from list => unselect all participants
              if (list_participant.length === 0) {
                delete articleToPay[this.props.article_id];
              }

              this.props.article_to_pay(
                articleToPay,
                getAmountToPay(this.props.currentTicket, articleToPay, this.props.currentNote)[0],
                getAmountToPay(this.props.currentTicket, articleToPay, this.props.currentNote)[1]
              );
              this.props.updateCurrentTicket(currentTicket);
            }}
          />
          }
          <span onClick={() => !isDetail && this.selectParticipantToPay()}>
            {this.props.name.length > 19 ? this.props.name.substr(0, 18) + "..." : this.props.name}
            <br/>
            {(parseFloat(this.props.price) - parseFloat(getParticipantDiscount(this.props.currentTicket, this.props.id))).toFixed(2)}
            {localStorage.getItem("currency")}
          </span>
          {this.getPaymentIconOfParticipant()}
          {getParticipantDiscount(this.props.currentTicket, this.props.id) > 0 && (
            <span className="ticket-accord-item-remise"
                  onClick={() => {
                    this.props.is_note === null && parseFloat(this.props.payed_amount) === 0 && this.setState({ cancelParticipantModalOpen: true });
                  }}
            >
              <Image src={require("../../../assets/remise.svg")}/>
              {getParticipantDiscount(this.props.currentTicket, this.props.id).toFixed(2)}
              {localStorage.getItem("currency")}
            </span>
          )}
        </Button>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchPlayer: (page, size, search) => dispatch({ type: "SEARCH_REQUEST_USER", payload: { page, size, search } }),
  store: (data) => dispatch({ type: "STORE_CLIENT_REQUEST_USER", payload: { ...data, redirection: false } }),
  editPlayer: (id) => dispatch({ type: "EDIT_REQUEST_USER", payload: id }),
  updateCurrentTicket: (data) => dispatch({ type: "UPDATE_CURRENT_TICKET_REQUEST", payload: data }),
  article_to_pay: (article, amount, rest) => dispatch({
    type: "ARTICLE_TO_PAY_REQUEST",
    payload: { article, amount, rest }
  }),
  caisse_redirection: (value, status = null, type = null, date = null) =>
    dispatch({ type: "CAISSE_REDIRECTION_REQUEST", payload: { value, status, type, date } }),
  participant_to_edit_payment: (value) => dispatch({ type: "PARTICIPANT_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  article_to_edit_payment: (value) => dispatch({ type: "ARTICLE_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  participant_to_partial_payment: (value) => dispatch({
    type: "PARTICIPANT_TO_PARTIAL_PAYMENT_REQUEST",
    payload: value
  }),
  article_to_partial_payment: (value) =>
    dispatch({ type: "ARTICLE_TO_PARTIAL_PAYMENT_REQUEST", payload: value }),
  reload: (data) => dispatch(SubscribersCreators.indexRequestSubscriberDetail({ ...data })),
  storeTicket: (data) => dispatch({ type: "STORE_TICKET_REQUEST", payload: data }),
  updateTicket: (data) => dispatch({ type: "UPDATE_TICKET_REQUEST", payload: data }),
  select_family: (value) => dispatch({ type: "SELECT_FAMILY_REQUEST", payload: value }),
  select_sub_family: (value) => dispatch({ type: "SELECT_SUB_FAMILY_REQUEST", payload: value }),
  select_hour_type: (value) => dispatch({ type: "SELECT_HOUR_TYPE_REQUEST", payload: value }),
  updateReservation: (data) => dispatch(reservationCreators.updateRequestReservation(data)),
  updateCurrentNote: (id) => dispatch({ type: 'CURRENT_NOTE_REQUEST', payload: id }),
  updatePendingClassicSub: (value) => dispatch({ type: "PENDING_CLASSIC_SUB_REQUEST", payload: value }),
});

const mapStateToProps = (state) => {
  let paymentTypes = state.paymentMethod.paymentMethods;
  paymentTypes.sort(function(a, b) {
    return a.id - b.id;
  });
  return {
    users: state.user.users,
    client: state.user.client,
    page: state.user.page,
    size: state.user.size,
    loading: state.user.loading,
    role: state.role,
    permissions: state.user.clientPermission,
    errors: state.user.error,
    user: state.user.selected,
    editLoading: state.user.editLoading,
    currentTicket: state.caisse.currentTicket,
    articleToPay: state.caisse.articleToPay,
    participantToPartialPayment: state.caisse.participantToPartialPayment,
    amountToPay: state.caisse.amountToPay,
    restAmountToPay: state.caisse.restAmountToPay,
    paymentTypes: paymentTypes,
    caisseRedirection: state.caisse.caisse_redirection,
    credit: state.caisse.credit,
    loadingDetail: state.subscriber.loadingDetail,
    reloadUpdated: state.subscriber.updated,
    articleToPartialPayment: state.caisse.articleToPartialPayment,
    menusData: state.caisse.menusData,
    participantToEditPayment: state.caisse.participantToEditPayment,
    appLoading: state.caisse.appLoading,
    currentNote: state.caisse.currentNote,
    pendingClassicSub : state.caisse.pendingClassicSub,
  };
};

TicketAccordionItem.defaultProps = {
  isDetail: false,
  key: ""
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TicketAccordionItem));
