import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import bookingConfigurationService from '../../services/bookingConfigurationService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingBookingConfiguration: ['data'],
  initialErrorsBookingConfiguration: ['data'],

  indexRequestBookingConfiguration: ['payload'],
  indexResponseBookingConfiguration: ['data'],

  storeRequestBookingConfiguration: ['payload'],
  storeResponseBookingConfiguration: ['data'],

  editRequestBookingConfiguration: ['payload'],
  editResponseBookingConfiguration: ['data'],

  updateRequestBookingConfiguration: ['payload'],
  updateResponseBookingConfiguration: ['data'],

  destroyRequestBookingConfiguration: ['payload'],
  destroyResponseBookingConfiguration: ['data'],
});

// Initial state
const INITIAL_STATE = {
  bookingConfigurations: [],
  bookingConfiguration: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successUpdate: false,
  successDestroy: false,
  successList: false
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingBookingConfiguration({ loading: true }));
    const data = yield call(bookingConfigurationService.bookingConfigurationService.index, action.payload);
    yield put(Creators.indexResponseBookingConfiguration(data));
    yield put(Creators.loadingBookingConfiguration({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingBookingConfiguration({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingBookingConfiguration({ loading: true, errors: null }));
    const data = yield call(bookingConfigurationService.bookingConfigurationService.store, action.payload);
    yield put(Creators.storeResponseBookingConfiguration(data));
    yield put(Creators.loadingBookingConfiguration({ loading: false }));
    yield history.push('/configuration/bookingConfigurations');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingBookingConfiguration({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingBookingConfiguration({ editLoading: true }));
    const data = yield call(bookingConfigurationService.bookingConfigurationService.edit, action.payload);
    yield put(Creators.editResponseBookingConfiguration(data));
    yield put(Creators.loadingBookingConfiguration({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingBookingConfiguration({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingBookingConfiguration({ loading: true, errors: null }));
    const data = yield call(bookingConfigurationService.bookingConfigurationService.update, action.payload);
    yield put(Creators.updateResponseBookingConfiguration(data));
    yield put(Creators.loadingBookingConfiguration({ loading: false }));
    yield history.push('/configuration/bookingConfigurations');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(Creators.loadingBookingConfiguration({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingBookingConfiguration({ deleteLoading: true }));
    const data = yield call(bookingConfigurationService.bookingConfigurationService.destroy, action.payload);
    yield put(Creators.destroyResponseBookingConfiguration(data));
    yield put(Creators.loadingBookingConfiguration({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    yield put(Creators.loadingBookingConfiguration({ deleteLoading: false }));
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_BOOKING_CONFIGURATION, index);
  yield takeLatest(Types.STORE_REQUEST_BOOKING_CONFIGURATION, store);
  yield takeLatest(Types.EDIT_REQUEST_BOOKING_CONFIGURATION, edit);
  yield takeLatest(Types.UPDATE_REQUEST_BOOKING_CONFIGURATION, update);
  yield takeLatest(Types.DESTROY_REQUEST_BOOKING_CONFIGURATION, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    bookingConfigurations: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    bookingConfiguration: action.data.data
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

const initial_errors = (state = INITIAL_STATE) => {
  return {
    ...state,
    errors: null,
  };
};

// Reducer
export const bookingConfigurationReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_BOOKING_CONFIGURATION]: index_response,
  [Types.STORE_RESPONSE_BOOKING_CONFIGURATION]: store_response,
  [Types.EDIT_RESPONSE_BOOKING_CONFIGURATION]: edit_response,
  [Types.UPDATE_RESPONSE_BOOKING_CONFIGURATION]: update_response,
  [Types.DESTROY_REQUEST_BOOKING_CONFIGURATION]: destroy_request,
  [Types.DESTROY_RESPONSE_BOOKING_CONFIGURATION]: destroy_response,
  [Types.LOADING_BOOKING_CONFIGURATION]: loading,
  [Types.INITIAL_ERRORS_BOOKING_CONFIGURATION]: initial_errors,
});
