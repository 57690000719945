import { getMethod, postMethod } from "@/helpers/index";

export default {
  creditService: {
    index: async ({ page = 1, size = 10, search }) => {
      const result = await getMethod(
        "/credits?user_id=" +
          search.user_id +
          "&page=" +
          page +
          (size ? "&size=" + size : "") +
          "&complex_id=" +
          localStorage.getItem("complex_id")
      );
      return result.data;
    },
    
    store: async (data) => {
      const result = await postMethod("/credits", data);
      return result.data;
    },
  },
};
