import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import paymentMethodService from '../../services/paymentMethodService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingPaymentMethod: ['data'],

  indexRequestPaymentMethod: ['payload'],
  indexResponsePaymentMethod: ['data'],

  storeRequestPaymentMethod: ['payload'],
  storeResponsePaymentMethod: ['data'],

  editRequestPaymentMethod: ['id'],
  editResponsePaymentMethod: ['data'],

  updateRequestPaymentMethod: ['payload'],
  updateResponsePaymentMethod: ['data'],

  destroyRequestPaymentMethod: ['id'],
  destroyResponsePaymentMethod: ['data'],
});

// Initial state
const INITIAL_STATE = {
  paymentMethods: [],
  paymentMethod: null,
  last: 0,
  page: 0,
  total: 0,
  size: 10,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingPaymentMethod({ loading: true }));
    const data = yield call(paymentMethodService.paymentMethodService.index, action.payload);
    yield put(Creators.indexResponsePaymentMethod(data));
    yield put(Creators.loadingPaymentMethod({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingPaymentMethod({ loading: true }));
    const data = yield call(paymentMethodService.paymentMethodService.store, action.payload);
    yield put(Creators.storeResponsePaymentMethod(data));
    yield put(Creators.loadingPaymentMethod({ loading: false }));
    yield history.push('/paymentMethods');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingPaymentMethod({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingPaymentMethod({ editLoading: true }));
    const data = yield call(paymentMethodService.paymentMethodService.edit, action.payload);
    yield put(Creators.editResponsePaymentMethod(data));
    yield put(Creators.loadingPaymentMethod({ editLoading: false }));
  } catch (e) {
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingPaymentMethod({ loading: true }));
    const data = yield call(paymentMethodService.paymentMethodService.update, action.payload);
    yield put(Creators.updateResponsePaymentMethod(data));
    yield put(Creators.loadingPaymentMethod({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/paymentMethods');
  } catch (e) {
    yield put(
      Creators.loadingPaymentMethod({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingPaymentMethod({ deleteLoading: true }));
    const data = yield call(paymentMethodService.paymentMethodService.destroy, action.payload);
    yield put(Creators.destroyResponsePaymentMethod(data));
    yield put(Creators.loadingPaymentMethod({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_PAYMENT_METHOD, index);
  yield takeLatest(Types.STORE_REQUEST_PAYMENT_METHOD, store);
  yield takeLatest(Types.EDIT_REQUEST_PAYMENT_METHOD, edit);
  yield takeLatest(Types.UPDATE_REQUEST_PAYMENT_METHOD, update);
  yield takeLatest(Types.DESTROY_REQUEST_PAYMENT_METHOD, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    paymentMethods: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    paymentMethod: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};
// Reducer
export const paymentMethodReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_PAYMENT_METHOD]: index_response,
  [Types.STORE_RESPONSE_PAYMENT_METHOD]: store_response,
  [Types.EDIT_RESPONSE_PAYMENT_METHOD]: edit_response,
  [Types.UPDATE_RESPONSE_PAYMENT_METHOD]: update_response,
  [Types.DESTROY_REQUEST_PAYMENT_METHOD]: destroy_request,
  [Types.DESTROY_RESPONSE_PAYMENT_METHOD]: destroy_response,
  [Types.LOADING_PAYMENT_METHOD]: loading,
});
