import { deleteMethod, getMethod, postMethod } from '../helpers';
import { getFormData } from '../helpers/formDataHandler';


export const index = ({ page = '', size, search }) => {
  return getMethod(
    '/complexes?page=' + page +
    (size ? '&size=' + size : '') +
    (search && search.search_name ? '&search_name=' + search.search_name : ''),
  );
};

export const store = (data) => {
  // form data
  let modules = [];
  for (let i = 0; i < data.modules.length; i++) {
    if (data.modules[i].checked) modules.push(data.modules[i].id);
  }

  let equipments = data.equipments.filter(it => it.equipment_id);

  let newData = {
    ...data.information,
    is_booking:data.information.is_booking ? 1 : 0,
    is_client:data.information.is_client ? 1 : 0,
    configuration: { ...data.configuration,  lower_quality:data.configuration.lower_quality ? 1 : 0},
    modules: modules,
    colors: data.colors,
    pages: data.booking,
  };

  let formData = getFormData(newData);

  for (let i = 0; i < equipments.length; i++) {
    formData.append('equipment[' + equipments[i].equipment_id + '][number]', equipments[i].number);
  }

  return postMethod('/complexes', formData);
};

export const edit = (id) => {
  return getMethod('/complexes/' + id);
};
export const update = (data) => {
  let modules = [];
  for (let i = 0; i < data.modules.length; i++) {
    if (data.modules[i].checked) modules.push(data.modules[i].id);
  }

  let equipments = data.equipments.filter(it => it.equipment_id);


  let newData = {
    ...data.information,
    is_booking:data.information.is_booking ? 1 : 0,
    is_client:data.information.is_client ? 1 : 0,
    configuration: { ...data.configuration,  lower_quality:data.configuration.lower_quality ? 1 : 0},
    modules: modules,
    colors: data.colors,
    pages: data.booking,
  };
  let formData = getFormData(newData);

  for (let i = 0; i < equipments.length; i++) {
    formData.append('equipment[' + equipments[i].equipment_id + '][number]', equipments[i].number);
  }


  formData.append('_method', 'put');
  return postMethod('/complexes/' + newData.id, formData);
};

export const destroy = (id) => {
  return deleteMethod('/complexes/' + id);
};
