import { deleteMethod, getMethod, postMethod } from '../helpers';
import { getFormData, showFormData } from '../helpers/formDataHandler';
import { reversePhoneNumber } from '../helpers/formatting';

export const index = ({ page, size, search }) => {
  return getMethod(
    '/contacts?user_id=' +
    search.userId +
    'page=' +
    page +
    (size ? '&size=' + size :'' ) +
    (search && search.search_first_name ? '&search_first_name=' + search.search_first_name : '') +
    (search && search.search_last_name ? '&search_last_name=' + search.search_last_name : '') +
    (search && search.search_email ? '&search_email=' + search.search_email : '') +
    (search && search.search_mobile ? '&search_mobile=' + reversePhoneNumber(search.search_mobile) : '') +
    '&complex_id=' +
    localStorage.getItem('complex_id'),
  );
};

export const store = (data) => {
  // form data
  let fd = getFormData(data);
  showFormData(fd);
  return postMethod('/contacts', fd);
};

export const edit = (id) => {
  return getMethod('/contacts/' + id + '?complex_id=' + localStorage.getItem('complex_id'));
};

export const update = (data) => {

  let fd = getFormData(data);
  fd.append('_method', 'put');
  showFormData(fd);

  return postMethod('/contacts/' + data.id, fd);
};

export const destroy = (id) => {
  return deleteMethod('/contacts/' + id);
};
