import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import cameraPlanningService from '@/services/cameraPlanningService';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingCameraPlanning: ['payload'],

  indexRequestCameraPlanning: ['payload'],
  indexResponseCameraPlanning: ['payload'],

  storeRequestCameraPlanning: ['payload'],
  storeResponseCameraPlanning: ['payload'],

  editRequestCameraPlanning: ['payload'],
  editResponseCameraPlanning: ['payload'],

  updateRequestCameraPlanning: ['payload'],
  updateResponseCameraPlanning: ['payload'],

  destroyRequestCameraPlanning: ['payload'],
  destroyResponseCameraPlanning: ['payload'],

  terrainsRequestCameraPlanning: ['payload'],
  terrainsResponseCameraPlanning: ['data'],

  hoursRequestCameraPlanning: ['payload'],
  hoursResponseCameraPlanning: ['data'],

  listRequestCameraPlanning: ['payload'],
  listResponseCameraPlanning: ['data'],

});

// Initial state
const INITIAL_STATE = {
  information: '',
  cameraPlannings: [],
  hourTypes: [],
  cameras: [],
  terrains: [],
  hours: [],
  addedCameraPlanning: null,
  cameraPlanning: null,
  loading: false,
  informationLoading: false,
  loadingHour: false,
  updated: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  addLoading: false,
  deleteLoading: false,
  cutLoading: false,
  copyLoading: false,
  EncaisseLoading: false,
  recoverLoading: false,
  pasteLoading: false,
  successStore: false,
  successCut: false,
  successCopy: false,
  successPaste: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingCameraPlanning({ loading: true }));
    const data = yield call(cameraPlanningService.cameraPlanningService.index, action.payload);
    yield put(Creators.indexResponseCameraPlanning(data));
    yield put(Creators.loadingCameraPlanning({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingCameraPlanning({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    if (action.payload.copy)
      yield put(Creators.loadingCameraPlanning({ addLoading: true, updated: false, addedCameraPlanning: null }));
    else yield put(Creators.loadingCameraPlanning({ addLoading: true, updated: false }));

    let response = yield call(cameraPlanningService.cameraPlanningService.store, action.payload);

    if (action.payload.copy)
      yield put(
        Creators.loadingCameraPlanning({
          addLoading: false,
          updated: true,
          addedCameraPlanning: response.data.data,
        }),
      );
    else yield put(Creators.loadingCameraPlanning({ addLoading: false, updated: true }));

    yield validToast(i18n.t('camera_planning_successful_store'));
  } catch (e) {
    yield put(Creators.loadingCameraPlanning({ addLoading: false, updated: false }));
    errorToast(i18n.t('camera_planning_failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingCameraPlanning({ editLoading: true, updated: false }));
    const data = yield call(cameraPlanningService.cameraPlanningService.edit, action.payload);
    yield put(Creators.editResponseCameraPlanning(data));
    yield put(Creators.loadingCameraPlanning({ editLoading: false, updated: true }));
  } catch (e) {
    yield put(Creators.loadingCameraPlanning({ editLoading: false, updated: false }));
  }
}

// //update
function* update(action) {
  try {
    yield put(Creators.loadingCameraPlanning({ addLoading: true, updated: false }));
    yield call(cameraPlanningService.cameraPlanningService.update, action.payload);
    //yield put(Creators.updateResponseReservation(data));
    yield put(Creators.loadingCameraPlanning({ addLoading: false, updated: true }));
    validToast(i18n.t('camera_planning_successful_updated'));
  } catch (e) {
    errorToast(i18n.t('camera_planning_failed_updating'));
    yield put(Creators.loadingCameraPlanning({ addLoading: false, updated: false }));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(
      Creators.loadingCameraPlanning({
        deleteLoading: true,
        successDestroy: false,
        updated: false,
      }),
    );
    yield call(cameraPlanningService.cameraPlanningService.destroy, action.payload);
    //yield put(Creators.destroyResponseReservation(data));
    yield put(
      Creators.loadingCameraPlanning({
        deleteLoading: false,
        successDestroy: true,
        updated: true,
      }),
    );
    validToast(i18n.t('camera_planning_successful_delete'));
  } catch (e) {
    errorToast(i18n.t('camera_planning_failed_delete'));
    yield put(
      Creators.loadingCameraPlanning({
        deleteLoading: false,
        successDestroy: false,
        updated: false,
      }),
    );
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CAMERA_PLANNING, index);
  yield takeLatest(Types.STORE_REQUEST_CAMERA_PLANNING, store);
  yield takeLatest(Types.EDIT_REQUEST_CAMERA_PLANNING, edit);
  yield takeLatest(Types.UPDATE_REQUEST_CAMERA_PLANNING, update);
  yield takeLatest(Types.DESTROY_REQUEST_CAMERA_PLANNING, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    cameraPlannings: action.payload.data,
    last: action.payload.last_page,
    page: action.payload.current_page,
    total: action.payload.total,
    size: action.payload.per_page,
    from: action.payload.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    cameraPlanning: action.payload.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const cameraPlanningReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_CAMERA_PLANNING]: index_response,
  [Types.STORE_RESPONSE_CAMERA_PLANNING]: store_response,
  [Types.EDIT_RESPONSE_CAMERA_PLANNING]: edit_response,
  [Types.UPDATE_RESPONSE_CAMERA_PLANNING]: update_response,
  [Types.DESTROY_RESPONSE_CAMERA_PLANNING]: destroy_response,
  [Types.LOADING_CAMERA_PLANNING]: loading,
});