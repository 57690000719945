import {
  deleteMethod,
  getMethod,
  postMethod,
  putMethod,
} from "@/helpers/index";
import {
  planningRequest,
  matchReportRequest,
} from "../helpers/competitionFormatting";

export default {
  competitionService: {
    /**
     * get competition list
     * @param {*} param0
     * @returns
     */
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        "/competitions?complex_id=" +
          search.complex_id +
          (page ? "&page=" + page : "") +
          (size ? "&size=" + size : "") +
          (search && search.search_name
            ? "&search_name=" + search.search_name
            : "") +
          (search && search.search_activity
            ? "&search_activity=" + search.search_activity
            : "") +
          (search && search.search_compType
            ? "&search_compType=" + search.search_compType
            : "") +
          (search && search.search_archive
            ? "&search_archive=" + search.search_archive
            : "")
      );
      return result.data;
    },

    /**
     * Store competition
     * @param {*} data
     * @returns
     */
    store: async (data) => {
      let newTeams = {};
      for (let i = 0; i < data.teams.length; i++) {
        newTeams = {
          ...newTeams,
          [data.teams[i]]: { complex_id: localStorage.getItem("complex_id") },
        };
      }
      let newTerrains = {};
      for (let i = 0; i < data.terrains.length; i++) {
        newTerrains = {
          ...newTerrains,
          [data.terrains[i]]: {
            complex_id: localStorage.getItem("complex_id"),
          },
        };
      }
      // for (let i = 0; i < data.journey_days.length; i++) {
      //   delete data.journey_days[i].duration;
      // }

      let journey_days = data.journey_days;
      if (data.auto && data.same_date) {
        let journeys = [];
        for (let i = 0; i < data.journey_days[0].terrain_id.length; i++) {
          journeys.push({
            ...data.journey_days[0],
            terrain_id: data.journey_days[0].terrain_id[i],
          });
        }
        journey_days = journeys;
      }

      let newData = {
        name: data.name,
        activity_id: data.activity_id,
        compType_id: data.compType_id,
        complex_id: localStorage.getItem("complex_id"),
        start_date: data.start_date,
        round_trip: data.round_trip === true ? 1 : 0,
        days: data.days,
        match_by_day: data.match_by_day,
        victory: data.victory,
        defeat: data.defeat,
        nul: data.nul,
        forfeit_v: data.forfeit_v,
        forfeit_d: data.forfeit_d,
        bonus: data.bonus === true ? 1 : 0,
        penalty: data.penalty,
        teams: newTeams,
        terrains: newTerrains,
        auto: data.auto ? 1 : 0,
        same_date: data.same_date ? 1 : 0,
        journey_days: journey_days,
        auto_referee: data.auto_referee ? 1 : 0,
        warm_up_time: data.warm_up_time,
        break_time: data.break_time,
        parts_nb: data.parts_nb,
        parts_time: data.parts_time,
      };
      const result = await postMethod("/competitions", newData);
      return result.data;
    },

    /**
     * edit competition
     * @param {*} id
     * @returns
     */
    edit: async (id) => {
      const result = await getMethod("/competitions/" + id);
      return result.data;
    },

    /**
     * update competition
     * @param {*} data
     * @returns
     */
    update: async (data) => {
      let newTeams = {};
      for (let i = 0; i < data.teams.length; i++) {
        newTeams = {
          ...newTeams,
          [data.teams[i]]: { complex_id: localStorage.getItem("complex_id") },
        };
      }
      let newTerrains = {};
      for (let i = 0; i < data.terrains.length; i++) {
        newTerrains = {
          ...newTerrains,
          [data.terrains[i]]: {
            complex_id: localStorage.getItem("complex_id"),
          },
        };
      }
      let newData = {
        id: data.id,
        name: data.name,
        activity_id: data.activity_id,
        compType_id: data.compType_id,
        complex_id: localStorage.getItem("complex_id"),
        start_date: data.start_date,
        round_trip: data.round_trip === true ? 1 : 0,
        days: data.days,
        match_by_day: data.match_by_day,
        victory: data.victory,
        defeat: data.defeat,
        nul: data.nul,
        forfeit_v: data.forfeit_v,
        forfeit_d: data.forfeit_d,
        bonus: data.bonus === true ? 1 : 0,
        penalty: data.penalty,
        teams: newTeams,
        same_date: data.same_date ? 1 : 0,
        terrains: newTerrains,
        auto_referee: data.auto_referee ? 1 : 0,
        warm_up_time: data.warm_up_time,
        break_time: data.break_time,
        parts_nb: data.parts_nb,
        parts_time: data.parts_time,
      };
      const result = await putMethod("/competitions/" + data.id, newData);
      return result.data;
    },

    /**
     * delete competition
     * @param {*} id
     * @returns
     */
    destroy: async ({ id, withMatch }) => {
      const result = await deleteMethod(
        "/competitions/" + id + (withMatch ? "?with_matches=" + withMatch : "")
      );
      return result.data;
    },

    /**
     * get match
     * @param {*}
     * @returns
     */
    getMatch: async (match_id) => {
      const result = await getMethod("/competitions/match/" + match_id);
      return result.data;
    },

    /**
     * journey planning
     * @param {*} data
     * @returns
     */
    planning: async (data) => {
      let formattedData = planningRequest(data);
      return postMethod("/competitions/planning", formattedData);
    },

    /**
     * match composition
     * @param {*} data
     * @param {*} reservation_id
     * @returns
     */
    matchComposition: async (data) => {
      return postMethod("/competitions/matchComposition", data);
    },

    /**
     * match report
     * @param {*} data
     * @returns
     */
    matchReport: async (data) => {
      let formattedData = matchReportRequest(data);
      return postMethod("/competitions/matchReport", formattedData);
    },

    /**
     * ranking
     * @param {*} id
     * @returns
     */
    ranking: async (id) => {
      const result = await getMethod("/competitions/" + id + "/ranking");
      return result.data;
    },

    /**
     * stats
     * @param {*} id
     * @returns
     */
    stats: async ({ id, journey = null }) => {
      const result = await getMethod(
        "/competitions/" +
          id +
          "/stats" +
          (journey ? "?journey=" + journey : "")
      );
      return result.data;
    },

    /**
     * stats team
     * @param {*} id
     * @param team_id
     * @returns
     */
    statsTeam: async ({ id, team_id }) => {
      const result = await getMethod(
        "/competitions/" + id + "/stats/" + team_id
      );
      return result.data;
    },

    /**
     * archive competition
     * @param {*} id
     * @returns
     */
    archive: async (id) => {
      const result = await getMethod("/competitions/" + id + "/archive");
      return result.data;
    },

    /**
     * archive competition
     * @param {*} id
     * @returns
     */
    unarchive: async (id) => {
      const result = await getMethod("/competitions/" + id + "/unarchive");
      return result.data;
    },
    forfeitMatch: async ({ reservation_id, data }) => {
      return await postMethod(
        "/reservation/forfeit/" + reservation_id,
        data,
        {}
      );
    },
    resetMatch: async ({ reservation_id }) => {
      console.log(reservation_id);
      return await getMethod("/reservation/reset/" + reservation_id, {});
    },
  },
};
