import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  hourTypeService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/hourTypes?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        '&complex_id=' +
        localStorage.getItem('complex_id'),
      );

      return result.data;
    },
    store: async (data) => {
      let newData = {
        ...data,
        is_main: data.is_main === true ? 1 : 0,
        is_mixed: data.is_mixed === true ? 1 : 0,
        complex_id: localStorage.getItem('complex_id'),
      };
      const result = await postMethod('/hourTypes', newData);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/hourTypes/' + id);
      return result.data;
    },
    update: async (data) => {
      let newData = {
        ...data,
        is_main: data.is_main === true ? 1 : 0,
        is_mixed: data.is_mixed === true ? 1 : 0,
        complex_id: localStorage.getItem('complex_id'),
      };
      const result = await putMethod('/hourTypes/' + data.id, newData);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/hourTypes/' + id);
      return result.data;
    },
  },
};
