import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import parentService from '../../services/parentService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingParent: ['data'],

  indexRequestParent: ['payload'],
  indexResponseParent: ['data'],

  storeRequestParent: ['payload'],
  storeResponseParent: ['data'],

  editRequestParent: ['id'],
  editResponseParent: ['data'],

  updateRequestParent: ['payload'],
  updateResponseParent: ['data'],

  destroyRequestParent: ['id'],
  destroyResponseParent: ['data'],
});

// Initial state
const INITIAL_STATE = {
  parents: [],
  parent: null,
  loading: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingParent({ loading: true }));
    const data = yield call(parentService.parentService.index, action.payload);
    yield put(Creators.indexResponseParent(data));
    yield put(Creators.loadingParent({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingParent({ loading: true }));
    const data = yield call(parentService.parentService.store, action.payload);
    yield put(Creators.storeResponseParent(data));
    yield put(Creators.loadingParent({ loading: false }));
    yield history.push('/parents');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingParent({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingParent({ editLoading: true }));
    const data = yield call(parentService.parentService.edit, action.payload);
    yield put(Creators.editResponseParent(data));
    yield put(Creators.loadingParent({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingParent({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingParent({ loading: true }));
    const data = yield call(parentService.parentService.update, action.payload);
    yield put(Creators.updateResponseParent(data));
    yield put(Creators.loadingParent({ loading: false }));
    yield history.push('/parents');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(Creators.loadingParent({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingParent({ deleteLoading: true }));
    const data = yield call(parentService.parentService.destroy, action.payload);
    yield put(Creators.destroyResponseParent(data));
    yield put(Creators.loadingParent({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_PARENT, index);
  yield takeLatest(Types.STORE_REQUEST_PARENT, store);
  yield takeLatest(Types.EDIT_REQUEST_PARENT, edit);
  yield takeLatest(Types.UPDATE_REQUEST_PARENT, update);
  yield takeLatest(Types.DESTROY_REQUEST_PARENT, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    parents: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    parent: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const loading_parent = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const parentReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_PARENT]: index_response,
  [Types.STORE_RESPONSE_PARENT]: store_response,
  [Types.EDIT_RESPONSE_PARENT]: edit_response,
  [Types.UPDATE_RESPONSE_PARENT]: update_response,
  [Types.DESTROY_REQUEST_PARENT]: destroy_request,
  [Types.DESTROY_RESPONSE_PARENT]: destroy_response,
  [Types.LOADING_PARENT]: loading_parent,
});
