export const user = {
  name: "Chady Mbarki",
  profile: "/profile",
};

export const newMenu = (t) => {
  return [
    {
      name: t("recover_password"),
      path: "/resetPassword",
      import: "/NewPasswordPage/NewPasswordPage",
      editable: false,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "unauthorized",
      permission: "login-access",
      sidebar: false,
      icon: null,
      sousMenu: [],
    },
    {
      name: t("login"),
      path: "/login",
      import: "/LoginPage/LoginPage",
      editable: false,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "unauthorized",
      permission: "login-access",
      sidebar: false,
      icon: null,
      sousMenu: [],
    },
    {
      name: t("dashboard"),
      path: "/login",
      import: "/DashboardPage/Redirection",
      editable: false,
      removable: false,
      addable: false,
      module: "dashboard",
      permission: "dashboard-access",
      sidebar: false,
      icon: {
        name: "home",
        color: "black",
      },
      sousMenu: [],
    },

    {
      name: t("dashboard"),
      path: "/",
      import: "/DashboardPage/DashboardPage",
      editable: false,
      removable: false,
      addable: false,
      module: "dashboard",
      permission: "dashboard-access",
      sidebar: true,
      icon: {
        name: "home",
        color: "black",
      },
      sousMenu: [],
    },
    {
      name: t("support"),
      path: "/support",
      import: "/SupportPage/SupportPage",
      editable: false,
      removable: false,
      addable: false,
      withSideMenu: true,
      module: "support",
      permission: "support-access",
      sidebar: true,
      icon: {
        name: "headphones",
        color: "black",
      },
      sousMenu: [
        {
          name: t("tickets"),
          path: "/support",
          import: "/SupportPage/SupportPage",
          editable: false,
          removable: false,
          addable: false,
          withSideMenu: true,
          module: "support",
          permission: "support-access",
          sidebar: true,
          icon: {
            name: "home",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("create_ticket"),
          path: "/support/create",
          import: "/SupportPage/FormSupport",
          editable: false,
          removable: false,
          addable: false,
          withSideMenu: true,
          module: "support",
          permission: "support-create",
          sidebar: false,
          icon: {
            name: "home",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("edit_ticket"),
          path: "/support/:support/edit",
          import: "/SupportPage/FormSupport",
          editable: false,
          removable: false,
          addable: false,
          withSideMenu: true,
          module: "support",
          permission: "support-edit",
          sidebar: false,
          icon: {
            name: "home",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("ticket_details"),
          path: "/support/:support/details",
          import: "/SupportPage/SupportDetails",
          editable: false,
          removable: false,
          addable: false,
          withSideMenu: true,
          module: "support",
          permission: "support-edit",
          sidebar: false,
          icon: {
            name: "home",
            color: "black",
          },
          sousMenu: [],
        },
      ],
    },

    {
      name: t("reporting"),
      path: "/reporting",
      import: "/ReportingPage/TurnoverPage",
      editable: true,
      removable: true,
      addable: true,
      module: "reporting",
      permission: "reporting-access",
      sidebar: true,
      icon: {
        name: "chart area",
        color: "black",
      },
      sousMenu: [
        {
          name: t("turnover"),
          path: "/reporting/turnover",
          import: "/ReportingPage/TurnoverPage",
          editable: true,
          removable: true,
          addable: true,
          module: "reporting",
          permission: "reporting-access",
          sidebar: true,
          icon: {
            name: "chart area",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("reservation"),
          path: "/reporting/reservation",
          import: "/ReportingPage/ReservationPage",
          editable: true,
          removable: true,
          addable: true,
          module: "reporting",
          permission: "reporting-access",
          sidebar: true,
          icon: {
            name: "chart area",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("sales"),
          path: "/reporting/sales",
          import: "/ReportingPage/SalesPage",
          editable: true,
          removable: true,
          addable: true,
          module: "reporting",
          permission: "reporting-access",
          sidebar: true,
          icon: {
            name: "chart area",
            color: "black",
          },
          sousMenu: [],
        },
        // {
        //   name: t('sales'),
        //   path: '/reporting/sales',
        //   import: '/ReportingPage/SalesPage',
        //   editable: true,
        //   removable: true,
        //   addable: true,
        //   module: 'reporting',
        //   permission: 'reporting-access',
        //   sidebar: true,
        //   icon: {
        //     name: 'chart area',
        //     color: 'black',
        //   },
        //   sousMenu: [],
        // },
        {
          name: t("subscriptions"),
          path: "/reporting/subscriptions",
          import: "/ReportingPage/SubscriptionsPage",
          editable: true,
          removable: true,
          addable: true,
          module: "reporting",
          permission: "reporting-access",
          sidebar: true,
          icon: {
            name: "chart area",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("occupancy-rate"),
          path: "/reporting/occupancyRate",
          import: "/ReportingPage/OccupancyRatePage",
          editable: true,
          removable: true,
          addable: true,
          module: "reporting",
          permission: "reporting-access",
          sidebar: true,
          icon: {
            name: "chart area",
            color: "black",
          },
          sousMenu: [],
        },
      ],
    },

    {
      name: t("reservation"),
      path: "/reservation",
      import: "/PlanningPage/PlanningPage",
      editable: true,
      removable: true,
      addable: true,
      module: "reservation",
      permission: "planning-access",
      sidebar: true,
      icon: {
        name: "calendar alternate",
        color: "black",
      },
      sousMenu: [
        {
          name: t("planning"),
          path: "/reservation",
          import: "/PlanningPage/PlanningPage",
          editable: true,
          removable: true,
          addable: true,
          module: "reservation",
          permission: "planning-access",
          sidebar: true,
          icon: {
            name: "calendar alternate",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("planningLeisure"),
          path: "/leisure",
          import: "/PlanningLeisurePage/PlanningLeisurePage",
          editable: true,
          removable: true,
          addable: true,
          module: "reservation",
          permission: "planning-leisure-access",
          sidebar: true,
          icon: {
            name: "calendar alternate",
            color: "black",
          },
          sousMenu: [],
        },
      ],
    },

    {
      name: t("CRM"),
      path: "/crm",
      import: "/ClientsPage/ClientsPage",
      editable: true,
      removable: true,
      addable: true,
      module: "crm",
      permission: "client-access",
      sidebar: true,
      icon: {
        name: "users",
        color: "black",
      },
      sousMenu: [
        {
          name: t("clients"),
          path: "/clients",
          import: "/ClientsPage/ClientsPage",
          editable: true,
          removable: true,
          addable: true,
          module: "crm",
          permission: "client-access",
          sidebar: true,
          icon: {
            name: "users",
            color: "black",
          },
          sousMenu: [],
        },

        {
          name: t("reservations"),
          path: "/reservations",
          import: "/ReservationsPage/ReservationsPage",
          editable: true,
          removable: true,
          addable: true,
          module: "crm",
          permission: "reservation-access",
          sidebar: true,
          icon: {
            name: "history",
            color: "black",
          },
          sousMenu: [],
        },
      ],
    },

    {
      name: t("add_client"),
      path: "/clients/create",
      import: "/ClientsPage/FormClient",
      editable: true,
      removable: true,
      addable: true,
      module: "crm",
      permission: "client-create",
      sidebar: false,
      sousMenu: [],
    },
    {
      name: t("profile_client"),
      path: "/profile/:client",
      import: "/ProfilePage/ProfilePage",
      editable: true,
      removable: true,
      addable: true,
      module: "crm",
      permission: "client-access",
      sidebar: false,
      sousMenu: [],
    },
    {
      name: t("edit_client"),
      path: "/clients/:client/edit",
      import: "/ClientsPage/FormClient",
      editable: true,
      removable: true,
      addable: true,
      module: "crm",
      permission: "client-edit",
      sidebar: false,
      sousMenu: [],
    },
    {
      name: t("subscription"),
      path: "/subscription",
      editable: true,
      removable: true,
      addable: true,
      module: "subscription",
      permission: "subscription-access",
      sidebar: true,
      icon: {
        name: "vcard",
        color: "black",
      },
      sousMenu: [
        {
          name: t("subscriptions"),
          path: "/subscription/",
          import: "/SubscriptionPage/SubscriptionPage",
          editable: true,
          removable: true,
          addable: true,
          exact: true,
          module: "subscription",
          permission: "subscription-access",
          sidebar: true,
          icon: {
            name: "vcard",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("subscribers"),
          path: "/subscriber",
          import: "/SubscribersPage/SubscribersPage",
          editable: true,
          removable: true,
          addable: true,
          module: "subscription",
          permission: "subscriber-access",
          sidebar: true,
          icon: {
            name: "user",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("subscription"),
          path: "/subscriber/create",
          import: "/SubscribersPage/FormSubscriber",
          editable: true,
          removable: true,
          addable: true,
          module: "subscription",
          permission: "subscriber-create",
          sidebar: false,
          icon: {
            name: "user",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("credit_bonuses"),
          path: "/creditBonuses",
          import: "/CreditBonusPage/CreditBonusPage",
          editable: true,
          removable: true,
          addable: true,
          module: "subscription",
          permission: "credit-bonus-access",
          sidebar: true,
          icon: {
            name: "user",
            color: "black",
          },
          sousMenu: [],
        },
      ],
    },
    {
      name: t("add_subscription"),
      path: "/subscription/create",
      import: "/SubscriptionPage/FormSubscription",
      editable: true,
      removable: true,
      addable: true,
      module: "subscription",
      permission: "subscription-create",
      sidebar: false,
      sousMenu: [],
    },
    {
      name: t("edit_subscription"),
      path: "/subscription/:subscription/edit",
      import: "/SubscriptionPage/FormSubscription",
      editable: true,
      removable: true,
      addable: true,
      module: "subscription",
      permission: "subscription-edit",
      sidebar: false,
      sousMenu: [],
    },
    {
      name: t("subscriber"),
      path: "/subscriber/:subscriber",
      import: "/SubscribersPage/SubscriberDetails",
      editable: true,
      removable: true,
      addable: true,
      module: "subscription",
      permission: "subscriber-access",
      sidebar: false,
      sousMenu: [],
    },
    {
      name: t("subscriber"),
      path: "/subscriber/:subscriber/edit",
      import: "/SubscribersPage/FormSubscriber",
      editable: true,
      removable: true,
      addable: true,
      module: "subscription",
      permission: "subscriber-edit",
      sidebar: false,
      sousMenu: [],
    },
    {
      name: t("credit_bonus"),
      path: "/creditBonuses/create",
      import: "/CreditBonusPage/FormCreditBonus",
      editable: true,
      removable: true,
      addable: true,
      module: "subscription",
      permission: "credit-bonus-create",
      sidebar: false,
      icon: {
        name: "user",
        color: "black",
      },
      sousMenu: [],
    },
    {
      name: t("credit_bonus"),
      path: "/creditBonuses/:creditBonus/edit",
      import: "/CreditBonusPage/FormCreditBonus",
      editable: true,
      removable: true,
      addable: true,
      module: "subscription",
      permission: "credit-bonus-edit",
      sidebar: false,
      sousMenu: [],
    },
    {
      name: t("competition"),
      path: "/competition",
      import: "/CompetitionPage/ChampionnatPage/ChampionnatPage",
      editable: true,
      removable: true,
      addable: true,
      module: "competition",
      permission: "competition-access",
      sidebar: true,
      icon: {
        name: "winner",
        color: "black",
      },
      sousMenu: [
        {
          name: t("championnat"),
          path: "/competition/championnat",
          import: "/CompetitionPage/ChampionnatPage/ChampionnatPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "competition-access",
          sidebar: true,
          icon: {
            name: "winner",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_championnat"),
          path: "/competition/championnat/create",
          import: "/CompetitionPage/ChampionnatPage/FormChampionnat",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "competition-create",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("edit_competition"),
          path: "/competition/championnat/:championnat/edit",
          import: "/CompetitionPage/ChampionnatPage/FormChampionnat",
          editable: true,
          removable: false,
          addable: false,
          module: "competition",
          permission: "competition-edit",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("calendar"),
          path: "/competition/championnat/:championnat/calendar",
          import: "/CompetitionPage/ChampionnatPage/CalendarPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "championship-calendar",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("calendar"),
          path:
            "/competition/championnat/:championnat/calendar/:journey/journey",
          import: "/CompetitionPage/ChampionnatPage/JourneyStatsPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "championship-calendar",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("calendar"),
          path:
            "/competition/championnat/:championnat/calendar/:journey/journey/planning",
          import: "/CompetitionPage/ChampionnatPage/JourneyPlanningPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "championship-calendar",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("video"),
          path:"/competition/championnat/:championnat/calendar/:reservation/video",
          import: "/CompetitionPage/ChampionnatPage/VideoPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "championship-calendar",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("stats_team"),
          path: "/competition/championnat/:championnat/calendar/:team/team",
          import: "/CompetitionPage/ChampionnatPage/StatsTeamPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "championship-calendar",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("ranking"),
          path: "/competition/championnat/:championnat/ranking",
          import: "/CompetitionPage/ChampionnatPage/RankingPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "championship-ranking",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("stats"),
          path: "/competition/championnat/:championnat/stats",
          import: "/CompetitionPage/ChampionnatPage/StatsPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "championship-stats",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("stats"),
          path: "/competition/championnat/:championnat/generalStats",
          import: "/CompetitionPage/ChampionnatPage/GeneralStatsPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "championship-stats",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("tournois"),
          path: "/competition/tournois",
          import: "/CompetitionPage/TournoisPage/TournoisPage",
          editable: false,
          removable: true,
          addable: true,
          module: "competition",
          permission: "tournois-access",
          sidebar: true,
          icon: {
            name: "winner",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("teams"),
          path: "/configuration/teams",
          import: "/TeamPage/TeamPage",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "team-access",
          sidebar: true,
          icon: {
            name: "shield",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_team"),
          path: "/configuration/teams/create",
          import: "/TeamPage/FormTeam",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "team-create",
          sidebar: false,
        },
        {
          name: t("edit_team"),
          path: "/configuration/teams/:team/edit",
          import: "/TeamPage/FormTeam",
          editable: true,
          removable: true,
          addable: true,
          module: "competition",
          permission: "team-edit",
          sidebar: false,
        },
      ],
    },
    {
      name: t("caisse"),
      path: "/checkout",
      import: "/CaissePage/HomePage",
      editable: true,
      removable: true,
      addable: true,
      module: "checkout",
      permission: "checkout-access",
      sidebar: true,
      icon: {
        name: "desktop",
        color: "black",
      },
      sousMenu: [
        {
          name: t("caisse"),
          path: "/checkout",
          import: "/CaissePage/HomePage",
          editable: false,
          removable: false,
          addable: false,
          module: "checkout",
          withSideMenu: true,
          permission: "checkout-home-access",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("fond_caisse"),
          path: "/fond-caisse",
          import: "/FondCaissePage/FondCaissePage",
          editable: false,
          removable: false,
          addable: false,
          module: "checkout",
          withSideMenu: true,
          permission: "checkout-fond-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("caisse"),
          path: "/caisse",
          import: "/CaissePage/CaissePage",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          withSideMenu: false,
          permission: "checkout-page-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("tickets"),
          path: "/tickets",
          import: "/TicketsPage/TicketsPage",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "ticket-info-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("ticket_infos"),
          path: "/ticketInfos",
          import: "/TicketInfoPage/TicketInfoPage",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "ticket-info-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("add_ticket_info"),
          path: "/ticketInfos/create",
          import: "/TicketInfoPage/FormTicketInfo",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "ticket-info-create",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("edit_ticket_info"),
          path: "/ticketInfos/:ticketInfo/edit",
          import: "/TicketInfoPage/FormTicketInfo",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "ticket-info-edit",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("payment-method"),
          path: "/paymentMethods",
          import: "/PaymentMethodPage/PaymentMethodPage",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "payment-method-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("add_payment_method"),
          path: "/paymentMethods/create",
          import: "/PaymentMethodPage/FormPaymentMethod",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "payment-method-create",
          sidebar: false,
        },
        {
          name: t("edit_payment_method"),
          path: "/paymentMethods/:paymentMethod/edit",
          import: "/PaymentMethodPage/FormPaymentMethod",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "payment-method-edit",
          sidebar: false,
        },
        {
          name: t("parent"),
          path: "/parents",
          import: "/ParentPage/ParentPage",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "parent-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("add_parent"),
          path: "/parents/create",
          import: "/ParentPage/FormParent",
          editable: false,
          removable: false,
          addable: true,
          module: "checkout",
          permission: "parent-create",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("edit_parent"),
          path: "/parents/:parent/edit",
          import: "/ParentPage/FormParent",
          editable: true,
          removable: false,
          addable: false,
          module: "checkout",
          permission: "parent-edit",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("child"),
          path: "/children",
          import: "/ChildPage/ChildPage",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "child-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("add_child"),
          path: "/children/create",
          import: "/ChildPage/FormChild",
          editable: false,
          removable: false,
          addable: true,
          module: "checkout",
          permission: "child-create",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("edit_child"),
          path: "/children/:child/edit",
          import: "/ChildPage/FormChild",
          editable: true,
          removable: false,
          addable: false,
          module: "checkout",
          permission: "child-edit",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("article"),
          path: "/articles",
          import: "/ArticlePage/ArticlePage",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "article-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("add_article"),
          path: "/articles/create",
          import: "/ArticlePage/FormArticle",
          editable: false,
          removable: false,
          addable: true,
          module: "checkout",
          permission: "article-create",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("edit_article"),
          path: "/articles/:article/edit",
          import: "/ArticlePage/FormArticle",
          editable: true,
          removable: false,
          addable: false,
          module: "checkout",
          permission: "article-edit",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("discounts"),
          path: "/discounts",
          import: "/DiscountPage/DiscountPage",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "discount-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("add_discount"),
          path: "/discounts/create",
          import: "/DiscountPage/FormDiscount",
          editable: false,
          removable: false,
          addable: true,
          module: "checkout",
          permission: "discount-create",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("edit_discount"),
          path: "/discounts/:discount/edit",
          import: "/DiscountPage/FormDiscount",
          editable: true,
          removable: false,
          addable: false,
          module: "checkout",
          permission: "discount-edit",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("vat"),
          path: "/vats",
          import: "/VatPage/VatPage",
          editable: true,
          removable: true,
          addable: true,
          module: "checkout",
          permission: "vat-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("add_vat"),
          path: "/vats/create",
          import: "/VatPage/FormVat",
          editable: false,
          removable: false,
          addable: true,
          module: "checkout",
          permission: "vat-create",
          sidebar: false,
        },
        {
          name: t("edit_vat"),
          path: "/vats/:vat/edit",
          import: "/VatPage/FormVat",
          editable: true,
          removable: false,
          addable: false,
          module: "checkout",
          permission: "vat-edit",
          sidebar: false,
        },
        {
          name: t("closure"),
          path: "/closure",
          import: "/ClosurePage/ClosurePage",
          editable: false,
          removable: false,
          addable: false,
          module: "checkout",
          permission: "closure-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("archive"),
          path: "/archives",
          import: "/ClosurePage/ArchivesPage",
          editable: false,
          removable: false,
          addable: false,
          module: "checkout",
          permission: "archive-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("cash-fund"),
          path: "/caisse",
          import: "/CaissePage/CaissePage",
          editable: true,
          removable: true,
          addable: true,
          withSideMenu: false,
          module: "checkout",
          permission: "caisse-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("checkout_configuration"),
          path: "/checkout/configuration",
          import: "/ConfigurationPage/ConfigurationPage",
          editable: false,
          removable: false,
          addable: false,
          module: "checkout",
          withSideMenu: true,
          permission: "checkout-configuration-access",
          sidebar: true,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("add_checkout_configuration"),
          path: "/checkout/configuration/create",
          import: "/ConfigurationPage/FormConfiguration",
          editable: false,
          removable: false,
          addable: false,
          module: "checkout",
          withSideMenu: true,
          permission: "checkout-configuration-create",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("edit_checkout_configuration"),
          path: "/checkout/configuration/:configuration/edit",
          import: "/ConfigurationPage/FormConfiguration",
          editable: false,
          removable: false,
          addable: false,
          module: "checkout",
          withSideMenu: true,
          permission: "checkout-configuration-edit",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
        {
          name: t("thermal_printer_test"),
          path: "/tprinter",
          import: "/TPrinter",
          editable: false,
          removable: false,
          addable: false,
          module: "checkout",
          permission: "archive-access",
          sidebar: false,
          icon: {
            name: "",
            color: "black",
          },
        },
      ],
    },
    {
      name: t("video"),
      path: "/video",
      import: "/VideosPage/VideosPage",
      editable: true,
      removable: true,
      addable: true,
      module: "video",
      permission: "video-access",
      sidebar: true,
      icon: {
        name: "film",
        color: "black",
      },
      sousMenu: [
        {
          name: t("videos"),
          path: "/videos",
          import: "/VideosPage/VideosPage",
          editable: true,
          removable: true,
          addable: true,
          module: "video",
          permission: "video-access",
          sidebar: true,
          icon: {
            name: "film",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("actions"),
          path: "/actions",
          import: "/ActionsPage/ActionsPage",
          editable: true,
          removable: true,
          addable: true,
          module: "video",
          permission: "action-access",
          sidebar: true,
          icon: {
            name: "pause circle",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("videos_planning"),
          path: "/videos-planning/",
          import: "/VideoPlanningPage/TerrainList",
          editable: true,
          removable: true,
          addable: true,
          module: "video",
          permission: "video-planning-access",
          sidebar: true,
          icon: {
            name: "film",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("videos_planning"),
          path: "/videos/planning/:terrain",
          import: "/VideoPlanningPage/VideoPlanning",
          editable: true,
          removable: true,
          addable: true,
          module: "video",
          permission: "video-planning-access",
          sidebar: false,
        },
      ],
    },

    {
      name: t("players"),
      path: "/player",
      import: "/",
      editable: true,
      removable: false,
      addable: false,
      withSideMenu: true,
      module: "player",
      permission: "player-access",
      sidebar: true,
      icon: {
        name: "futbol outline",
        color: "black",
      },
      sousMenu: [],
    },
    {
      name: t("stat_sensor"),
      path: "/sensorStat",
      import: "/SensorStatPage/HomePage",
      editable: true,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "sensor",
      permission: "sensor-stats-access",
      sidebar: true,
      target: "_blank",
      icon: {
        name: "chart pie",
        color: "black",
      },
      sousMenu: [],
    },

    {
      name: t("stat_sensor"),
      path: "/sensorStat/match-code",
      import: "/SensorStatPage/CodeMatchPage",
      editable: true,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "reporting",
      permission: "sensor-stats-access",
      sidebar: false,
      target: "_blank",
      icon: {
        name: "chart pie",
        color: "black",
      },
      sousMenu: [],
    },
    {
      name: t("stat_sensor"),
      path: "/sensorStat/auth",
      import: "/SensorStatPage/AuthPage",
      editable: true,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "reporting",
      permission: "sensor-stats-access",
      sidebar: false,
      target: "_blank",
      icon: {
        name: "chart pie",
        color: "black",
      },
      sousMenu: [],
    },
    {
      name: t("stat_sensor"),
      path: "/sensorStat/stat",
      import: "/SensorStatPage/StatPage",
      editable: true,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "reporting",
      permission: "sensor-stats-access",
      sidebar: false,
      target: "_blank",
      icon: {
        name: "chart pie",
        color: "black",
      },
      sousMenu: [],
    },

    //TODO Here you can add your router so
    // the configuration still show up at the end

    {
      name: t("configuration"),
      path: "/configuration",
      import: "/RedirectionPage/RedirectionPage",
      editable: true,
      removable: true,
      addable: true,
      module: "configuration",
      permission: "configuration-access",
      sidebar: true,
      icon: {
        name: "cogs",
        color: "black",
      },
      sousMenu: [
        {
          name: t("centre_management"),
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "configuration-access",
          sidebar: true,
          sousMenu: [
            {
              name: t("activities"),
              path: "/configuration/activities",
              import: "/ActivitiesPage/ActivityPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "activity-access",
              sidebar: true,
              icon: {
                name: "certificate",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_activity"),
              path: "/configuration/activities/create",
              import: "/ActivitiesPage/FormActivity",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "activity-create",
              sidebar: false,
            },
            {
              name: t("edit_activity"),
              path: "/configuration/activities/:activity/edit",
              import: "/ActivitiesPage/FormActivity",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "activity-edit",
              sidebar: false,
            },
            {
              name: t("equipments"),
              path: "/configuration/equipments",
              import: "/EquipmentsPage/EquipmentPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "equipment-access",
              sidebar: true,
              icon: {
                name: "certificate",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_equipment"),
              path: "/configuration/equipments/create",
              import: "/EquipmentsPage/FormEquipment",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "equipment-create",
              sidebar: false,
            },
            {
              name: t("edit_equipment"),
              path: "/configuration/equipments/:equipment/edit",
              import: "/EquipmentsPage/FormEquipment",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "equipment-edit",
              sidebar: false,
            },
            {
              name: t("activity_types"),
              path: "/configuration/activityTypes",
              import: "/ActivityTypesPage/ActivityTypesPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "activity-type-access",
              sidebar: true,
              icon: {
                name: "certificate",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_activity_types"),
              path: "/configuration/activityTypes/create",
              import: "/ActivityTypesPage/FormActivityTypes",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "activity-type-create",
              sidebar: false,
            },
            {
              name: t("edit_activity_types"),
              path: "/configuration/activityTypes/:activity/edit",
              import: "/ActivityTypesPage/FormActivityTypes",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "activity-type-edit",
              sidebar: false,
            },
            {
              name: t("price_config"),
              path: "/configuration/priceConfigs",
              import: "/PriceConfigPage/PriceConfigPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "price-config-access",
              sidebar: true,
              icon: {
                name: "th",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("terrains"),
              path: "/configuration/terrains",
              import: "/TerrainPage/TerrainPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "terrain-access",
              sidebar: true,
              icon: {
                name: "flag checkered",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_terrain"),
              path: "/configuration/terrains/create",
              import: "/TerrainPage/FormTerrain",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "terrain-create",
              sidebar: false,
            },
            {
              name: t("edit_terrain"),
              path: "/configuration/terrains/:terrain/edit",
              import: "/TerrainPage/FormTerrain",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "terrain-edit",
              sidebar: false,
            },
            {
              name: t("add_terrain_time"),
              path: "/configuration/times/:terrain",
              import: "/TerrainPage/TerrainTimes",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "terrain-time",
              sidebar: false,
            },
            {
              name: t("durations"),
              path: "/configuration/durations",
              import: "/DurationPage/DurationPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "duration-access",
              sidebar: true,
              icon: {
                name: "clock outline",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_duration"),
              path: "/configuration/durations/create",
              import: "/DurationPage/FormDuration",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "duration-create",
              sidebar: false,
            },
            {
              name: t("edit_duration"),
              path: "/configuration/durations/:duration/edit",
              import: "/DurationPage/FormDuration",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "duration-edit",
              sidebar: false,
            },
            {
              name: t("planningType"),
              path: "/configuration/planningTypes",
              import: "/PlanningTypePage/PlanningTypePage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "activity-access",
              sidebar: true,
              icon: {
                name: "certificate",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_planning_type"),
              path: "/configuration/planningType/create",
              import: "/PlanningTypePage/FormPlanningType",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "activity-create",
              sidebar: false,
            },
            {
              name: t("edit_planning_type"),
              path: "/configuration/planningType/:planningType/edit",
              import: "/PlanningTypePage/FormPlanningType",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "activity-edit",
              sidebar: false,
            },
            {
              name: t("planningLeisureType"),
              path: "/configuration/planningLeisureTypes",
              import: "/PlanningLeisureTypePage/PlanningLeisureTypePage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "planning-leisure-type-access",
              sidebar: true,
              icon: {
                name: "certificate",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_planning_leisure_type"),
              path: "/configuration/planningLeisureTypes/create",
              import: "/PlanningLeisureTypePage/FormPlanningLeisureType",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "planning-leisure-type-create",
              sidebar: false,
            },
            {
              name: t("edit_planning_leisure_type"),
              path: "/configuration/planningLeisureTypes/:planningLeisure/edit",
              import: "/PlanningLeisureTypePage/FormPlanningLeisureType",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "planning-leisure-type-edit",
              sidebar: false,
            },
            {
              name: t("edit_user"),
              path: "/configuration/users/:user/edit",
              import: "/UserPage/FormUser",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "complexuser-edit",
              sidebar: false,
            },
            {
              name: t("add_user"),
              path: "/configuration/users/create",
              import: "/UserPage/FormUser",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",

              permission: "complexuser-create",
              sidebar: false,
            },
            {
              name: t("users"),
              path: "/configuration/users",
              import: "/UserPage/UserPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "complexuser-access",
              sidebar: true,
              icon: {
                name: "user circle",
                color: "black",
              },
              sousMenu: [],
            },
          ],
        },
        {
          name: t("networks"),
          path: "/configuration/networks",
          import: "/NetworkPage/NetworkPage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "network-access",
          sidebar: true,
          icon: {
            name: "sitemap",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_network"),
          path: "/configuration/networks/create",
          import: "/NetworkPage/FormNetwork",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "network-create",
          sidebar: false,
        },
        {
          name: t("edit_network"),
          path: "/configuration/networks/:network/edit",
          import: "/NetworkPage/FormNetwork",
          editable: true,
          removable: false,
          addable: false,
          module: "configuration",
          permission: "network-edit",
          sidebar: false,
        },
        {
          name: t("complex"),
          path: "/configuration/complex",
          import: "/ComplexPage/ComplexPage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "complex-access",
          sidebar: true,
          icon: {
            name: "map marker",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_complex"),
          path: "/configuration/complex/create",
          import: "/ComplexPage/FormComplex",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "complex-create",
          sidebar: false,
        },
        {
          name: t("edit_complex"),
          path: "/configuration/complex/:complex/edit",
          import: "/ComplexPage/FormComplex",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "complex-edit",
          sidebar: false,
        },
        {
          name: t("add_complex_time"),
          path: "/configuration/complexTimes/:complex",
          import: "/ComplexPage/ComplexTimes",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "complex-time",
          sidebar: false,
        },
        {
          name: t("roles"),
          path: "/configuration/roles",
          import: "/RolePage/RolePage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "role-access",
          sidebar: true,
          icon: {
            name: "chess",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_role"),
          path: "/configuration/roles/create",
          import: "/RolePage/FormRole",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "role-create",
          sidebar: false,
        },
        {
          name: t("edit_role"),
          path: "/configuration/roles/:role/edit",
          import: "/RolePage/FormRole",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "role-edit",
          sidebar: false,
        },

        {
          name: t("permissions"),
          path: "/configuration/permissions",
          import: "/PermissionPage/PermissionPage",
          editable: true,
          removable: false,
          addable: false,
          module: "configuration",
          permission: "permission-access",
          sidebar: true,
          icon: {
            name: "key",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("permission"),
          path: "/configuration/permissions/create",
          import: "/PermissionPage/FormPermission",
          editable: true,
          removable: false,
          addable: false,
          module: "configuration",
          permission: "permission-create",
          sidebar: false,
          sousMenu: [],
        },
        {
          name: t("permission"),
          path: "/configuration/permissions/:permission/edit",
          import: "/PermissionPage/FormPermission",
          editable: true,
          removable: false,
          addable: false,
          module: "configuration",
          permission: "permission-edit",
          sidebar: false,
          sousMenu: [],
        },

        {
          name: t("modules"),
          path: "/configuration/modules",
          import: "/ModulePage/ModulePage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "module-access",
          sidebar: true,
          icon: {
            name: "folder",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_module"),
          path: "/configuration/modules/create",
          import: "/ModulePage/FormModule",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "module-create",
          sidebar: false,
        },
        {
          name: t("edit_module"),
          path: "/configuration/modules/:module/edit",
          import: "/ModulePage/FormModule",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "module-edit",
          sidebar: false,
        },
        {
          name: t("competitionTypes"),
          path: "/configuration/compTypes",
          import: "/CompetitionTypePage/CompetitionTypePage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "comp-type-access",
          sidebar: true,
          icon: {
            name: "star",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_comp_type"),
          path: "/configuration/compTypes/create",
          import: "/CompetitionTypePage/FormCompetitionType",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "comp-type-create",
          sidebar: false,
        },
        {
          name: t("edit_comp_type"),
          path: "/configuration/compTypes/:compType/edit",
          import: "/CompetitionTypePage/FormCompetitionType",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "comp-type-edit",
          sidebar: false,
        },
        {
          name: t("sensors"),
          path: "/configuration/sensors",
          import: "/SensorPage/SensorPage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "sensor-access",
          sidebar: true,
          icon: {
            name: "microchip",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_sensor"),
          path: "/configuration/sensors/create",
          import: "/SensorPage/FormSensor",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "sensor-create",
          sidebar: false,
        },
        {
          name: t("edit_sensor"),
          path: "/configuration/sensors/:sensor/edit",
          import: "/SensorPage/FormSensor",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "sensor-edit",
          sidebar: false,
        },
        {
          name: t("booking"),
          path: "/c/",
          import: "/BookingConfigurationPage/BookingConfigurationPage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "booking-configuration-access",
          sidebar: true,
          sousMenu: [
            {
              name: t("configuration"),
              path: "/configuration/bookingConfigurations",
              import: "/BookingConfigurationPage/BookingConfigurationPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "booking-configuration-access",
              sidebar: true,
              icon: {
                name: "bookmark",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_configuration"),
              path: "/configuration/bookingConfigurations/create",
              import: "/BookingConfigurationPage/FormBookingConfiguration",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "booking-configuration-create",
              sidebar: false,
            },
            {
              name: t("edit_configuration"),
              path: "/configuration/bookingConfigurations/:bookingConfiguration/edit",
              import: "/BookingConfigurationPage/FormBookingConfiguration",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "booking-configuration-edit",
              sidebar: false,
            },
          ],
        },
        {
          name: t("video"),
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "configuration-access",
          sidebar: true,
          // icon: {
          //   name: 'camera',
          //   color: 'black',
          // },
          sousMenu: [
            {
              name: t("cameras"),
              path: "/configuration/cameras",
              import: "/CameraPage/CameraPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "camera-access",
              sidebar: true,
              icon: {
                name: "camera",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("cameras_planning"),
              path: "/cameras/planning/:camera",
              import: "/CameraPage/CameraPlanning",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "camera-planning-access",
              sidebar: false,
            },
            {
              name: t("edit_resolution"),
              path: "/configuration/cameras/:camera/edit",
              import: "/CameraPage/FormCamera",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "camera-edit",
              sidebar: false,
            },
            {
              name: t("add_resolution"),
              path: "/configuration/cameras/create",
              import: "/CameraPage/FormCamera",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "camera-create",
              sidebar: false,
            },

            {
              name: t("sides"),
              path: "/configuration/sides",
              import: "/SidePage/SidePage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "side-access",
              sidebar: true,
              icon: {
                name: "compress",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_side"),
              path: "/configuration/sides/create",
              import: "/SidePage/FormSide",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "sensor-create",
              sidebar: false,
            },
            {
              name: t("edit_side"),
              path: "/configuration/sides/:side/edit",
              import: "/SidePage/FormSide",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "sensor-edit",
              sidebar: false,
            },
            {
              name: t("resolutions"),
              path: "/configuration/resolutions",
              import: "/ResolutionPage/ResolutionPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "resolution-access",
              sidebar: true,
              icon: {
                name: "eye",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_resolution"),
              path: "/configuration/resolutions/create",
              import: "/ResolutionPage/FormResolution",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "resolution-create",
              sidebar: false,
            },
            {
              name: t("edit_resolution"),
              path: "/configuration/resolutions/:resolution/edit",
              import: "/ResolutionPage/FormResolution",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "resolution-edit",
              sidebar: false,
            },
          ],
        },

        {
          name: t("currencies"),
          path: "/configuration/",
          import: "/SubscriptionTypePage/SubscriptionTypePage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "configuration-access",
          sidebar: true,
          sousMenu: [
            {
              name: t("currencies"),
              path: "/configuration/currencies",
              import: "/CurrenciesPage/CurrencyPage",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "currency-access",
              sidebar: true,
              icon: {
                name: "euro",
                color: "black",
              },
              sousMenu: [],
            },
            {
              name: t("add_currency"),
              path: "/configuration/currencies/create",
              import: "/CurrenciesPage/FormCurrency",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "currency-create",
              sidebar: false,
            },
            {
              name: t("edit_currency"),
              path: "/configuration/currencies/:currency/edit",
              import: "/CurrenciesPage/FormCurrency",
              editable: true,
              removable: true,
              addable: true,
              module: "configuration",
              permission: "currency-edit",
              sidebar: false,
            },
          ],
        },
        {
          name: t("subscription_types"),
          path: "/configuration/subscriptionTypes",
          import: "/SubscriptionTypePage/SubscriptionTypePage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "subscription-type-access",
          sidebar: true,
          icon: {
            name: "clock",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_subscription_type"),
          path: "/configuration/subscriptionTypes/create",
          import: "/SubscriptionTypePage/FormSubscriptionType",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "subscription-type-create",
          sidebar: false,
        },
        {
          name: t("edit_subscription_type"),
          path: "/configuration/subscriptionTypes/:subscriptionType/edit",
          import: "/SubscriptionTypePage/FormSubscriptionType",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "subscription-type-edit",
          sidebar: false,
        },
        {
          name: t("periodicities"),
          path: "/configuration/periodicities",
          import: "/PeriodicityPage/PeriodicityPage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "periodicity-access",
          sidebar: true,
          icon: {
            name: "recycle",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_periodicity"),
          path: "/configuration/periodicities/create",
          import: "/PeriodicityPage/FormPeriodicity",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "periodicity-create",
          sidebar: false,
        },
        {
          name: t("edit_periodicity"),
          path: "/configuration/periodicities/:periodicity/edit",
          import: "/PeriodicityPage/FormPeriodicity",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "periodicity-edit",
          sidebar: false,
        },
        {
          name: t("hour_types"),
          path: "/configuration/hourTypes",
          import: "/HourTypePage/HourTypePage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "hour-type-access",
          sidebar: true,
          icon: {
            name: "clock",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_hour_type"),
          path: "/configuration/hourTypes/create",
          import: "/HourTypePage/FormHourType",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "hour-type-create",
          sidebar: false,
        },
        {
          name: t("edit_hour_type"),
          path: "/configuration/hourTypes/:hourType/edit",
          import: "/HourTypePage/FormHourType",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "hour-type-edit",
          sidebar: false,
        },
        {
          name: t("mixed_hours"),
          path: "/configuration/mixedHours",
          import: "/MixedHourPage/MixedHourPage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "mixed-hour-access",
          sidebar: true,
          icon: {
            name: "clock",
            color: "black",
          },
          sousMenu: [],
        },
        {
          name: t("add_mixed_hour"),
          path: "/configuration/mixedHours/create",
          import: "/MixedHourPage/FormMixedHour",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "mixed-hour-create",
          sidebar: false,
        },
        {
          name: t("edit_mixed_hour"),
          path: "/configuration/mixedHours/:mixedHour/edit",
          import: "/MixedHourPage/FormMixedHour",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "mixed-hour-edit",
          sidebar: false,
        },
        {
          name: t("hours_config"),
          path: "/configuration/hourConfigs",
          import: "/HoursConfigPage/HoursConfigPage",
          editable: true,
          removable: true,
          addable: true,
          module: "configuration",
          permission: "hours-config-access",
          sidebar: true,
          icon: {
            name: "th",
            color: "black",
          },
          sousMenu: [],
        },
      ],
    },
    {
      name: t("error"),
      path: "/error-page",
      import: "/ErrorPage/ErrorPage",
      editable: false,
      removable: false,
      addable: false,
      module: "",
      withSideMenu: false,
      permission: "",
      sidebar: false,
      icon: null,
      sousMenu: [],
    },
    {
      name: t("error"),
      path: "/error-401",
      import: "/ErrorPage/Error401Page",
      editable: false,
      removable: false,
      addable: false,
      module: "",
      withSideMenu: false,
      permission: "",
      sidebar: false,
      icon: null,
      sousMenu: [],
    },
    {
      name: t("error"),
      path: "/error-403",
      import: "/ErrorPage/Error403Page",
      editable: false,
      removable: false,
      addable: false,
      module: "",
      withSideMenu: false,
      permission: "",
      sidebar: false,
      icon: null,
      sousMenu: [],
    },

    {
      name: t("videos"),
      path: "/videos/watch/:slug",
      import: "/VideoCodePage/VideoCodePage",
      editable: false,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "",
      permission: "",
      sidebar: false,
      icon: {
        name: "home",
        color: "black",
      },
      sousMenu: [],
    },
    {
      name: t("videos"),
      path: "/videos-planning/:slug",
      import: "/VideoPlanningPage/VideoPlanningList",
      editable: false,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "",
      permission: "",
      sidebar: false,
      icon: {
        name: "home",
        color: "black",
      },
      sousMenu: [],
    },
    {
      name: t("videos"),
      path: "/videos/:slug",
      import: "/VideoList/VideoList",
      editable: false,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "",
      permission: "",
      sidebar: false,
      icon: {
        name: "home",
        color: "black",
      },
      sousMenu: [],
    },
    {
      name: t("booking"),
      path: "/booking/:slug",
      import: "/BookingPage/BookingPage",
      editable: false,
      removable: false,
      addable: false,
      withSideMenu: false,
      module: "",
      permission: "",
      sidebar: false,
      icon: {
        name: "home",
        color: "black",
      },
      sousMenu: [],
    },
    {
      name: t("championnat"),
      path: "/public-routes/competition/:complex_id/championnat",
      import: "/Public/Competition/ChampoinnatPublicPage",
      editable: true,
      removable: true,
      addable: true,
      module: "",
      permission: "",
      sidebar: false,
      withSideMenu: false,
      sousMenu: [],
    },
    {
      name: t("calendar"),
      path: "/public-routes/championnat/:championnat/calendar",
      import: "/Public/Competition/CalendarCompetitionPage",
      editable: true,
      removable: true,
      addable: true,
      module: "",
      permission: "",
      sidebar: false,
      withSideMenu: false,
      sousMenu: [],
    },
    {
      name: t("video"),
      path:"/public-routes/championnat/:championnat/calendar/:reservation/video",
      import: "/Public/Competition/VideoCompetitionPage",
      editable: true,
      removable: true,
      addable: true,
      module: "",
      permission: "",
      sidebar: false,
      withSideMenu: false,
      sousMenu: [],
    },
    {
      name: t("ranking"),
      path: "/public-routes/championnat/:championnat/ranking",
      import: "/Public/Competition/RankingCompetitionPage",
      editable: true,
      removable: true,
      addable: true,
      module: "",
      permission: "",
      sidebar: false,
      withSideMenu: false,
      sousMenu: [],
    },
    {
      name: t("stats"),
      path: "/public-routes/championnat/:championnat/stats",
      import: "/Public/Competition/StatsCompetitionPage",
      editable: false,
      removable: false,
      addable: false,
      module: "",
      permission: "",
      sidebar: false,
      withSideMenu: false,
      sousMenu: [],
    },
    {
      name: t("stats"),
      path: "/public-routes/championnat/:championnat/generalStats",
      import: "/Public/Competition/GeneralStatsCompetitionPage",
      editable: false,
      removable: false,
      addable: false,
      module: "",
      permission: "",
      sidebar: false,
      withSideMenu: false,
      sousMenu: [],
    },
    {
      name: t("stats_team"),
      path: "/public-routes/championnat/:championnat/calendar/:team/team",
      import: "/Public/Competition/StatsTeamCompetitionPage",
      editable: false,
      removable: false,
      addable: false,
      module: "",
      permission: "",
      sidebar: false,
      withSideMenu: false,
      sousMenu: [],
    },
  ];
};
