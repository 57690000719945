import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import permissionService from '@/services/permissionService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingPermission: ['payload'],

  indexRequestPermission: null,
  indexResponsePermission: ['data'],

  storeRequestPermission: ['payload'],
  storeResponsePermission: ['data'],

  editRequestPermission: ['id'],
  editResponsePermission: ['data'],

  updateRequestPermission: ['payload'],
  updateResponsePermission: ['data'],

  destroyRequestPermission: ['id'],
  destroyResponsePermission: ['data'],
});

// Initial state
const INITIAL_STATE = {
  permissions: [],
  permission: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingPermission({ loading: true }));
    const data = yield call(permissionService.permissionService.index, action.payload);
    yield put(Creators.indexResponsePermission(data));
    yield put(Creators.loadingPermission({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingPermission({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingPermission({ loading: true }));
    const data = yield call(permissionService.permissionService.store, action.payload);
    yield put(Creators.storeResponsePermission(data));
    yield put(Creators.loadingPermission({ loading: false }));
    yield history.push('/configuration/permissions');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingPermission({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingPermission({ editLoading: true }));
    const data = yield call(permissionService.permissionService.edit, action.payload);
    yield put(Creators.editResponsePermission(data));
    yield put(Creators.loadingPermission({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingPermission({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingPermission({ loading: true }));
    const data = yield call(permissionService.permissionService.update, action.payload);
    yield put(Creators.updateResponsePermission(data));
    yield put(Creators.loadingPermission({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/permissions');
  } catch (e) {
    yield put(
      Creators.loadingPermission({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingPermission({ deleteLoading: true }));
    const data = yield call(permissionService.permissionService.destroy, action.payload);
    yield put(Creators.destroyResponsePermission(data));
    yield put(Creators.loadingPermission({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_PERMISSION, index);
  yield takeLatest(Types.STORE_REQUEST_PERMISSION, store);
  yield takeLatest(Types.EDIT_REQUEST_PERMISSION, edit);
  yield takeLatest(Types.UPDATE_REQUEST_PERMISSION, update);
  yield takeLatest(Types.DESTROY_REQUEST_PERMISSION, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    permissions: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    permission: action.data.data,
  };
};
//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding_permission = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const permissionReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_PERMISSION]: index_response,
  [Types.STORE_RESPONSE_PERMISSION]: store_response,
  [Types.EDIT_RESPONSE_PERMISSION]: edit_response,
  [Types.UPDATE_RESPONSE_PERMISSION]: update_response,
  [Types.DESTROY_RESPONSE_PERMISSION]: destroy_response,
  [Types.LOADING_PERMISSION]: laoding_permission,
});
