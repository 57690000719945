import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { putMethod } from '../helpers';
import { exportFile } from '@/helpers/exportDownloader';

export default {
  planningLeisureTypeService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/leisurePlannings?complex_id=' + localStorage.getItem('complex_id') +
        (page ? '&page=' + page : '') +
        (size ? '&size=' + size : '') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_terrain ? '&search_terrain=' + search.search_terrain : ''),
      );

      return result.data;
    },

    store: async (data) => {
      let {
        name,
        terrains,
        activities,
        places_nb,
        duration_id,
      } = data;
      let complex_id = localStorage.getItem('complex_id');

      let newData = {
        name,
        terrains,
        complex_id,
        places_nb,
        activities,
        duration_id,
      };


      const result = await postMethod('/leisurePlannings', newData);
      return result.data;
    },

    edit: async (id) => {
      const result = await getMethod('/leisurePlannings/' + id);
      return result.data;
    },
    update: async (data) => {
      let {
        id,
        name,
        terrains,
        activities,
        places_nb,
        duration_id,
      } = data;
      let complex_id = localStorage.getItem('complex_id');

      let newData = {
        id,
        name,
        terrains,
        complex_id,
        activities,
        places_nb,
        duration_id,
      };

      const result = await putMethod('/leisurePlannings/' + data.id, newData);
      return result.data;
    },
    
    destroy: async (id) => {
      const result = await deleteMethod('/leisurePlannings/' + id);
      return result.data;
    },

    //export
    exportExcel: async ({ search }) => {
      const response = await getMethod(
        '/planningLeisureTypesExport?complex_id=' + localStorage.getItem('complex_id') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_terrain ? '&search_terrain=' + search.search_terrain : ''),
        {responseType: 'blob'}
      );

      exportFile(response, 'Types_planification_loisir');
    }
  },
};
