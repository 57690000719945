import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import * as ComplexService from '../../services/complexService';
import { errorToast, validToast } from '../../helpers/customToast';
import history from '../../helpers/history';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingComplex: ['payload'],

  storeRequestComplex: ['payload'],
  indexRequestComplex: ['payload'],
  editRequestComplex: ['payload'],
  updateRequestComplex: ['payload'],
  destoryRequestComplex: ['payload'],

  responseComplex: ['payload'],
  responseEditComplex: ['payload'],
  errorComplex: ['payload'],
});

// Initial state
const INITIAL_STATE = {
  complex: [],
  selected: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  deleteSuccess: false,
  reset: false,
  loading: false,
  editLoading: false,
  updateLoading: false,
  deleteLoading: false,
  error: null,
};

function* store(action) {
  try {
    yield put(Creators.loadingComplex({ loading: true, error: null }));
    yield call(ComplexService.store, action.payload);
    yield put(Creators.loadingComplex({ loading: false }));
    validToast(i18n.t('successful_store'));
    history.push('/configuration/complex');
  } catch (e) {
    yield put(Creators.loadingComplex({ loading: false }));
    yield put(Creators.errorComplex(e.response?.data?.errors));
    errorToast(i18n.t('failed_store'));
  }
}

function* update(action) {
  try {
    yield put(Creators.loadingComplex({ loading: true, error: null }));
    yield call(ComplexService.update, action.payload);
    yield put(Creators.loadingComplex({ loading: false }));
    history.push('/configuration/complex');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(Creators.loadingComplex({ loading: false }));
    yield put(Creators.errorComplex(e.response?.data?.errors));
    errorToast(i18n.t('failed_update'));
  }
}

function* edit(action) {
  console.log('complex');
  console.log(action);
  try {
    yield put(Creators.loadingComplex({ editLoading: true }));
    const response = yield call(ComplexService.edit, action.payload);
    yield put(Creators.responseEditComplex({ selected: response.data.data }));
    yield put(Creators.loadingComplex({ editLoading: false }));
  } catch (e) {
    yield put(Creators.errorComplex({ editLoading: false, editError: false }));
    history.push('/error-404');
  }
}

function* destory(action) {
  try {
    yield put(
      Creators.loadingComplex({
        deleteLoading: true,
        deleteSuccess: false,
        error: null,
      }),
    );
    yield call(ComplexService.destroy, action.payload);
    yield put(Creators.loadingComplex({ deleteLoading: false, deleteSuccess: true }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    yield put(
      Creators.errorComplex({
        deleteLoading: false,
        deleteSuccess: false,
        error: null,
      }),
    );
    errorToast(i18n.t('failed_delete'));
  }
}

function* index(action) {
  try {
    yield put(Creators.loadingComplex({ loading: true }));
    const data = yield call(ComplexService.index, action.payload);
    yield put(Creators.responseComplex(data));
    yield put(Creators.loadingComplex({ loading: false }));
  } catch (e) {
    yield put(Creators.errorComplex());
    yield put(Creators.loadingComplex({ loading: false }));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_COMPLEX, index);
  yield takeLatest(Types.STORE_REQUEST_COMPLEX, store);
  yield takeLatest(Types.EDIT_REQUEST_COMPLEX, edit);
  yield takeLatest(Types.UPDATE_REQUEST_COMPLEX, update);
  yield takeLatest(Types.DESTORY_REQUEST_COMPLEX, destory);
}

// Reducer

const complex_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    complex: [...action.payload.data.data],
    last: action.payload.data.last_page,
    page: action.payload.data.current_page,
    total: action.payload.data.total,
    size: action.payload.data.per_page,
    from: action.payload.data.from,
  };
};

const complex_response_edit = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    ...action.payload,
  };
};

const complex_failed = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: action.payload,
  };
};

const complex_loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

export const complexReducer = createReducer(INITIAL_STATE, {
  [Types.RESPONSE_COMPLEX]: complex_response,
  [Types.RESPONSE_EDIT_COMPLEX]: complex_response_edit,
  [Types.LOADING_COMPLEX]: complex_loading,
  [Types.ERROR_COMPLEX]: complex_failed,
});
