import React from 'react';
import ContentLoader from 'react-content-loader';


export const MainLayoutAccessPlaceholder = (props) => {
  return (<span>rezrer</span>);
};


export const MainLayoutCreatePlaceholder = (props) => {
  return (<span>rezrer</span>);
};


export const CheckoutLayoutPlaceholder = (props) => {
  return (<span>rezrer</span>);
};


export const PicturePlaceholder = (props) => {
  return (

    <ContentLoader
      speed={1}
      width={80}
      height={80}
      viewBox="0 0 80 80"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="37" cy="40" r="30"/>
    </ContentLoader>

  );
};


export const IconPlaceholder = (props) => {
  return (
    <ContentLoader
      speed={1}
      width={120}
      height={80}
      viewBox="0 0 120 80"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="22" cy="35" r="16"/>
      <circle cx="66" cy="36" r="16"/>
    </ContentLoader>
  );
};


export const TextPlaceholder = (props) => {
  return (
    <ContentLoader
      speed={1}
      width={120}
      height={80}
      viewBox="0 0 120 80"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="15" rx="4" ry="4" width="100%" height="20"/>
      <rect x="0" y="44" rx="4" ry="4" width={'68%'} height="20"/>
    </ContentLoader>
  );
};

export const IdPlaceholder = (props) => {
  return (
    <ContentLoader
      speed={1}
      width={40}
      height={40}
      viewBox="0 0 120 80"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="15" rx="8" ry="8" width="100%" height="100%"/>
    </ContentLoader>
  );
};