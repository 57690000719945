import { deleteMethod, getMethod, postMethod, putMethod } from '../helpers';
import { getFormData, showFormData } from '../helpers/formDataHandler';

export default {
  creditUserService: {
    /**
     * Create a subscriber
     *
     * @param data
     */
    store: (data) => {
      return postMethod('/userCredits', data);
    },

    /**
     * Get the credit user
     *
     * @param id
     * @return object
     */
    edit : (id) => {
      return getMethod('/userCredits/' + id);
    },


    /**
     * Update the credit user
     *
     * @param data
     */
    update : (data) => {
      return postMethod('/userCredits/' + data.id, data);
    },

    /**
     * Delete the credit user
     *
     * @param id
     */
    destroy : (id) => {
      return deleteMethod('/userCredits/' + id);
    },

    /**
     * Get the credit user sold
     *
     * @param data
     * @return object
     */
    
    getCreditUserSolde: async (data) => {
      const result = await postMethod('/userCredits' , data);
      let amount =result.data.data;
      if(amount) amount= amount.toFixed(2);
      return amount;
    }
    
  }
}