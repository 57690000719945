import React from "react";
import MainLayout from "@/Layout/MainLayout/MainLayout";
import FullCalendar from "@fullcalendar/react";
import plugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  Divider,
  Icon,
  Loader,
  Message,
  Modal,
  Transition,
} from "semantic-ui-react";
import PlanningConfigurationsModal from "@/components/PlanningConfigurationsModal";
import Image from "../../components/ImageComponent/Image";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import moment from "moment";
import localization, * as fr from "moment/locale/fr";
import * as en from "moment/locale/en-ca";
import ReactTooltip from "react-tooltip";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import { imgUrl } from "../../helpers";
import {
  formatCodeCountry,
  formattingDate,
  nullString,
  nullStringForTable,
  nullStringNumber,
  phoneNumber,
  toMinutes,
} from "../../helpers/formatting";
import {
  conclusionColor,
  getAdvanceTicket,
  player,
  reservationResponse,
  resetReservationState,
  ticketReservationStatus,
  conclusionForPlayer,
} from "../../helpers/reservationFormatting";
import { convertTicket, getCreditNumber } from "@/helpers/ticket";
import caisseService from "@/services/caisseService";
import { connect } from "react-redux";
import { Creators as terrainCreators } from "../../store/ducks/terrain";
import { Creators as teamsCreators } from "../../store/ducks/team";
import { Creators as reservationCreators } from "../../store/ducks/reservation";
import { Creators as priceCreators } from "../../store/ducks/priceManagements";
import { Creators as caisseCreators } from "../../store/ducks/caisse";
import { Creators as planningTypeCreators } from "../../store/ducks/planningTypes";
import WithLoader from "@/components/WithLoader/WithLoader";
import CustomReservationHistory from "../../components/CustomReservationHistory/CustomReservationHistory";
import { warningToast } from "../../helpers/customToast";
import { edit, reservationHour } from "../../services/reservationService";
import i18n from "../../i18n";
import ReservationModal from "../../components/ReservationModal/ReservationModal";
import "./PlanningPage.css";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import PlanningHeader from "../../components/PartPlanning/PlanningHeader";
import PlanningSide from "../../components/PartPlanning/PlanningSide";
import PlanningStats from "../../components/PlanningStats/PlanningStats";
import { debounce } from "@/helpers";
import history from "../../helpers/history";

// let events = [];
let contextMenuListener = null;
let menu = null;

const DIFF_TIME = 30;

let defaultPlanningType = {
  id: 0,
  name: "Global",
  isDefault: true,
  activity: null,
  terrains: [],
  selected: true,
};

/**
 * @Component
 *
 * Planning Page has fullCalendar and four modals
 * 1- Modal for Reservation History
 * 2- Modal for Deleted Reservation
 * 3- Modal for Adding reservation
 * 4- Modal for editing reservation
 *
 * **/
class PlanningPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const params =
      props.type === "caisse"
        ? ""
        : new URLSearchParams(props.history.location.search);
    let id = props.type === "caisse" ? "" : params.get("id");
    this.state = {
      credit_value: 0,
      confirmCreditModalOpen: false,
      creditModalOpen: false,
      newDate: "",
      editing: false,
      canMove: true,
      search_id: id,
      get: !!id,
      date:
        params && params.get("date")
          ? moment(params.get("date")).isValid()
            ? moment(params.get("date")).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      terrains: [],
      planningType: [{ ...defaultPlanningType }],
      durations: [],
      teams: [],
      toggle: !this.props.configuration.show_side_menu,
      modalHistory: false,
      mainLoading: false,
      modalDeleted: false,
      modalNewUser: false,
      modalConfiguration: false,
      deleteModal: false,
      isDragging: false,
      selectedChoice: false,
      modalCheckoutOpen: false,
      modalDirection: "left",
      isLoading: false,
      search_user: null,
      results: [],
      client: null,
      clients: [],
      events: [],
      addEvent: null,
      info: {},
      teamIndex: 0,
      ...resetReservationState(this.props.user.id),
      copy: null,
      cut: null,
      undo: null,
      eventId: null,
      menuType: "",
      isOperating: false,
      menuIsOpen: false,
      loadingCopy: false,
      loadingCut: false,
      loadingUndo: false,
      loadingPaste: false,
      loadingRemove: false,
      joinModal: false,
      old: null,
    };
    this.contextMenu = React.createRef();
    this.intervallLoading = React.createRef();
    this.fullCalendarRef = React.createRef();
    this.tooltipRef = React.createRef();
    this.scrollEvent = null;
  }

  resetAddReservationModal = () => {
    let state = Object.assign(
      {},
      { ...resetReservationState(this.props.user.id) }
    );
    this.setState({ ...state });
  };

  handleReservationFormOnChange = (e) => {
    let { reservationForm } = this.state;

    reservationForm = { ...reservationForm, [e.target.name]: e.target.value };
    this.setState({ reservationForm: reservationForm });
  };

  fillTeamFromUsers = (teams, team_id = "") => {
    let players = [];
    if (teams.users) {
      for (let i = 0; i < teams.users.length; i++) {
        let playerfromuser = {
          user_id: teams.users[i].id,
          firstName: teams.users[i].firstName,
          lastName: teams.users[i].lastName,
          email: teams.users[i].email,
          mobile: teams.users[i].mobile,
          sensor_id: "",
          team_id: team_id,

          removable: false,
          search: false,
          sensor: false,
        };
        players.push(playerfromuser);
      }

      if (players.length < 5) {
        let length = 5 - players.length;
        for (let i = 0; i < length; i++) {
          players.push(player);
        }
      }
    } else {
      players = new Array(5).fill(player);
    }

    return players;
  };

  /**
   *  get reservation history
   * @param index
   * @param item
   * @returns {Promise<void>}
   */
  getReservationHistory = async (index, item) => {
    let selected = this.state.planningType.filter((it) => it.selected);
    let terrains =
      selected.length > 0 ? selected[0].terrains.map((it) => it.id) : [];
    await this.setState({ search_user: index });
    this.props.reservationHistory(
      this.props.pageReservHistory,
      this.props.sizeReservHistory,
      this.state.search_user,
      terrains
    );
  };

  checkForSession = () => {
    let { session } = this.props;
    if (!session || nullString(session.status) === "1") return false;
    else return true;
  };

  toggleSessionModal = (modalCheckoutOpen) => {
    this.setState({
      modalCheckoutOpen,
    });
  };

  renderNoCheckoutModal() {
    const { t } = this.props;
    return (
      <Modal
        open={this.state.modalCheckoutOpen}
        onClose={() => {
          this.toggleSessionModal(false);
        }}
        size="tiny"
      >
        <Modal.Header>{t("no-checkout-session")}</Modal.Header>
        <Modal.Content>
          <p>{t("msg-checkout-open-session-reservation")}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t("cancel")}
            onClick={() => {
              this.toggleSessionModal(false);
            }}
          />
          <Button
            content={t("open-session")}
            className="negative"
            onClick={() => {
              history.push("/checkout");
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  /*
   *   Render Reservation history Modals
   * */
  reservationHistoryRow() {
    let row = this.props.dataReservHistory.map((item, index) => {
      return (
        <CustomReservationHistory
          key={index}
          id={item.id}
          conclusion={item.conclusion}
          activity={item.activity.name}
          duration={item.duration.duration}
          date={item.date}
          start_hour={item.start_hour}
          end_hour={item.end_hour}
          created_at={item.created_at}
          first_name={item.user.firstName}
          last_name={item.user.lastName}
          mobile={
            item.user.country_code === "33"
              ? item.user.mobile
                ? phoneNumber("0" + item.user.mobile)
                : null
              : phoneNumber(item.user.mobile)
          }
          owner={item.owner}
        />
      );
    });

    return row;
  }

  renderHistoryReservationModal = () => {
    let selected = this.state.planningType.filter((it) => it.selected);
    let terrains =
      selected.length > 0 ? selected[0].terrains.map((it) => it.id) : [];
    const { t } = this.props;
    return (
      <Modal
        size={"large"}
        open={this.state.modalHistory}
        onClose={() => this.setState({ modalHistory: false })}
      >
        <Modal.Header className={"centered-title modal-title header"}>
          {t("reservation_history")}
          <Button
            icon="close"
            color="red"
            className={"close-modal"}
            circular
            onClick={() => this.setState({ modalHistory: false })}
          />
        </Modal.Header>
        <Modal.Content image scrolling className={"history-content"}>
          <Modal.Description>
            <div className={"modal-history-container"}>
              <div className={"modal-history-header"}>
                <span>{t("user")}</span>
                <CustomDropdown
                  name={"search_user"}
                  placeholder={t("select_user")}
                  url={"clients/search"}
                  param={"&type=client"}
                  className={"history-modal-dropdown"}
                  get={"/users"}
                  noData={t("no_customer_found")}
                  value={this.state.search_user}
                  onChange={this.getReservationHistory}
                  render={(item) => {
                    return (
                      <>
                        <img
                          className="avatar-cover"
                          src={imgUrl + "/users/" + item.avatar}
                          alt={""}
                          style={{ width: "15px", height: "15px" }}
                        />
                        <span>
                          {nullString(item.firstName).toLowerCase()}{" "}
                          {nullString(item.lastName).toUpperCase()}
                        </span>
                      </>
                    );
                  }}
                />
              </div>
              <div className={"modal-history-content"}>
                {this.state.search_user ? (
                  <>
                    <Transition animation={"fade"} visible={this.props.loading}>
                      <div className={"loading-deleted-reservation"}>
                        <Loader active={true} size={"medium"} />
                        <Divider />
                      </div>
                    </Transition>
                    {this.reservationHistoryRow()}
                  </>
                ) : (
                  <div style={{ margin: "30px", width: "70%" }}>
                    <Message icon size="large">
                      <Message.Content>
                        <span>{t("liste_reservation_empty")}</span>
                        {/* <Icon name='circle notched' loading/> */}
                      </Message.Content>
                    </Message>
                  </div>
                )}
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions className={"modal-history-footer"}>
          <CustomPagination
            loadMore={(page, size, search) =>
              this.props.reservationHistory(page, size, search, terrains)
            }
            size={2}
            page={this.props.pageHistory}
            last={this.props.lastHistory}
            total={this.props.totalHistory}
            search={this.state.search_user}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  /**
   *  retrieve selected id
   * @param id
   */
  retrieveDeletedReservation = (id) => {
    this.props.retrieveDeletedReservation(id);
  };

  renderRecoveryModal = () => {
    const { t } = this.props;
    return (
      <Modal
        size={"large"}
        open={this.state.modalDeleted}
        onClose={() => this.setState({ modalDeleted: false })}
      >
        <Modal.Header className={"centered-title modal-title header"}>
          {t("deleted_reservation")}
          <Button
            icon="close"
            color="red"
            className={"close-modal"}
            circular
            onClick={() => this.setState({ modalDeleted: false })}
          />
        </Modal.Header>
        <Modal.Content image scrolling className={"history-content"}>
          <Modal.Description>
            <div className={"modal-history-container"}>
              <div className={"modal-history-content"}>
                {
                  <Transition
                    animation={"fade"}
                    visible={this.props.loading || this.props.recoverLoading}
                  >
                    <div className={"loading-deleted-reservation"}>
                      <Loader active={true} size={"medium"} />
                      <Divider />
                    </div>
                  </Transition>
                }

                {this.deletedReservationRow()}
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions className={"modal-history-footer"}>
          <CustomPagination
            size={3}
            page={this.props.pageDeleted}
            last={this.props.lastDeleted}
            total={this.props.totalDeleted}
            loadMore={this.props.deletedReservation}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  deletedReservationRow() {
    const { t } = this.props;
    let row = this.props.dataDeletedReservation.map((item, index) => {
      return (
        <CustomReservationHistory
          key={index}
          id={item.id}
          conclusion={item.conclusion}
          activity={item.activity.name}
          duration={item.duration.duration}
          date={item.date}
          start_hour={item.start_hour}
          end_hour={item.end_hour}
          created_at={item.deleted_at}
          deleted_at={item.deleted_at}
          first_name={nullString(item.user.firstName).toLowerCase()}
          last_name={nullString(item.user.lastName).toUpperCase()}
          mobile={
            item.user.country_code === "33"
              ? item.user.mobile
                ? phoneNumber("0" + item.user.mobile)
                : null
              : phoneNumber(item.user.mobile)
          }
          owner={item.owner}
          remover={item.remover}
          setNewDate={(date) => this.setState({ newDate: date })}
          type="deleted"
          onClick={debounce(async (id) => {
            this.retrieveDeletedReservation(id);
          }, 1000)}
        />
      );
    });

    return row;
  }

  // rendering Three button on the right side of the planning header
  renderPlanningButton = () => {
    const { t } = this.props;
    return (
      <div className={"planning-btn-right"}>
        <Button
          className={"btn-right-plan"}
          onClick={() => {
            this.setState({
              modalConfiguration: true,
            });
          }}
        >
          <Icon name="setting" color="#EEF1F7" size="big" />
        </Button>
        <Button
          className={"btn-right-plan"}
          title={t("reservation_history")}
          onClick={() => this.setState({ modalHistory: true })}
        >
          <Image src={require("../../assets/addReser.png")} />
        </Button>
        <Button
          className={"btn-right-plan"}
          title={t("deleted_reservation")}
          onClick={() => this.setState({ modalDeleted: true })}
        >
          <Image src={require("../../assets/history.png")} />
        </Button>
        {<Loader size={"small"} />}
      </div>
    );
  };

  //render the tooltips for the hover  on the event
  renderToolTips = (id) => {
    //filtering by id
    let { t } = this.props;
    // if (this.state.events.length > 0 && id && id !== this.state.eventId && this.state.menuIsOpen!=="open") {
    if (
      this.state.events.length > 0 &&
      id &&
      !this.state.menuIsOpen &&
      !this.state.isDragging
    ) {
      let eventFiltered = this.props.reservations.filter(
        (it) => it.id.toString() === id.toString()
      );

      if (eventFiltered.length === 0) return <div />;

      let selected = eventFiltered[0];
      return (
        <div className={"label-container"}>
          <div className={"label-header"}>
            <span>{nullString(selected.activity?.name)}</span>
            <span>{selected.duration?.duration.split(":").join("H")}</span>
            <span>
              <Image
                type={"item"}
                alt={"Activity"}
                src={
                  selected.activity?.icon
                    ? imgUrl +
                    "/activities/" +
                    localStorage.getItem("complex_id") +
                    "/" +
                    selected.activity.icon
                    : ""
                }
              />
            </span>
          </div>
          <div className={"label-content"}>
            <span className={"label-name"}>
              {nullStringForTable(
                selected.user
                  ? nullString(selected.user.firstName).toLowerCase()
                  : null
              )}{" "}
              {nullStringForTable(
                selected.user
                  ? nullString(selected.user.lastName).toUpperCase()
                  : null
              )}
            </span>

            <span>
              <span className={"bold-label "}>{t("mobile")}: </span>{" "}
              {nullStringForTable(
                selected.user && selected.user.mobile
                  ? formatCodeCountry(selected.user.country_code) +
                  phoneNumber(selected.user.mobile)
                  : null
              )}
            </span>
            <span>
              <span className={"bold-label "}>{t("email")}: </span>{" "}
              {nullStringForTable(selected.user ? selected.user.email : null)}
            </span>
            <span>
              <span className={"bold-label "}>{t("price")}: </span>{" "}
              {nullStringForTable(selected.price) +
                localStorage.getItem("currency")}
            </span>
            <span>
              <span className={"bold-label "}>{t("society")}: </span>{" "}
              {nullStringForTable(selected.user ? selected.user.company : null)}
            </span>
            <span>
              <span className={"bold-label "}>{t("network_code")}: </span>{" "}
              {nullStringForTable(selected.code_res)}
            </span>
            <span>
              <span className={"bold-label "}>{t("info")}: </span>{" "}
              {nullStringForTable(selected.infos)}{" "}
            </span>
          </div>
          <div className={"label-footer"}>
            <div>
              <Button
                className={"round-btn venu"}
                onClick={() => {
                  let reservationForm = { ...this.state.reservationForm };
                  selected.conclusion = "Venu";
                  this.props.update({
                    ...reservationResponse({ ...reservationForm }, selected),
                    date: this.state.date,
                  });
                }}
              >
                {t("to-come")}
              </Button>
              <Button
                className={"round-btn pas-venu"}
                onClick={() => {
                  let reservationForm = { ...this.state.reservationForm };
                  selected.conclusion = "Pas Venu";
                  this.props.update({
                    ...reservationResponse({ ...reservationForm }, selected),
                    date: this.state.date,
                  });
                }}
              >
                {t("not-come")}
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  };

  //render the events for the full-calendar
  renderEvent = (e) => {
    const {
      color,
      user,
      price,
      icon,
      startHour,
      endHour,
      status,
      conclusion,
      hasRecurrence,
      hasCompetition,
      competition,
    } = e.event.extendedProps;
    let { id } = e.event;
    let { cut } = this.state;
    let isCut = false;
    let currencyIcon = localStorage.getItem("currency");
    let currencyBgColor =
      conclusion === "Pas Venu" ? "#fff" : ticketReservationStatus(status);

    let currencyColor =
      conclusion === "Pas Venu" ? conclusionColor(conclusion) : "#fff";

    if (cut !== null && id === cut.id) isCut = true;

    e.el.innerHTML = `<div data-event-id=${id} data-event-start=${startHour} data-event-end=${endHour} class=${isCut ? "event-container event-cut" : "event-container"
      } style="background-color: ${color};">
                                    <div class="event-overflow" data-event-id=${id} data-event-start=${startHour} data-event-end=${endHour} ></div>
                                    <div class="event-description ${conclusion === "Venu" ||
        conclusion === "player"
        ? "venu-text"
        : ""
      }">
                                    <span>${startHour} - ${endHour} (${price ? price + localStorage.getItem("currency") : "---"
      })</span>
                                    <span>${hasCompetition
        ? nullString(competition.team_a?.name) +
        "<b> - </b>" +
        nullString(competition.team_b?.name)
        : nullString(
          user.firstname
        ).toLowerCase() +
        " " +
        nullString(
          user.lastname
        ).toUpperCase()
      }</span>
                                    <span>${user.phone}</span>
                                    </div>
                                    <div class="img-container">
                                    <img src="${icon}" alt="activity"/>
                                    <img  class="recurrence-icon"
                                    style="${"opacity:" + (hasRecurrence ? "1" : "0")
      }"
                                    src="${require("../../assets/icon_recurrence.svg")}" alt="recurrence"/>
                                    ${hasCompetition
        ? `<img  class="competition-icon" alt="competition" src="${require("../../assets/competition.svg")}" />`
        : ""
      }
                                    <span class="currency-status" style="background-color:${currencyBgColor};color:${currencyColor};">${currencyIcon}</span>
                                    </div>
                                </div>`;
  };

  // position the hover labels
  handleEventPositioned = (e) => {
    const { id } = e.event;
    e.el.setAttribute("data-tip", id);
    e.el.setAttribute("data-for", "soclose");
    ReactTooltip.rebuild();
  };

  // Show modal when event is clicked
  onEventHandler = (e, ev) => {
    try {
      let id = e.el.dataset.tip;
      let start = e.event.start;
      //extendedProps: Object
      // conclusion: "A valider"
      // created_at: "2021-07-07 15:58:08"
      // dateEvent: "2021-07-08"
      // endHour: "01:00"

      this.setState({
        info: { ...this.state.info, dateStr: start },
        eventId: id,
      });
      this.loaderEvent(id);
    } catch (e) {
      //
    }
  };

  getClosingAndOpeningOfField = (it) => {
    let startTime =
      it &&
        it.terrain_time &&
        it.terrain_time.open &&
        it.terrain_time.open[
        moment(this.state.date).locale("en").format("ddd").toLowerCase()
        ]
        ? it.terrain_time.open[
        moment(this.state.date).locale("en").format("ddd").toLowerCase()
        ]
        : this.props.selectedComplex.complex_time
          ? this.props.selectedComplex.complex_time.open
          : "08:00";
    let endTime =
      it &&
        it.terrain_time &&
        it.terrain_time.close &&
        it.terrain_time.close[
        moment(this.state.date).locale("en").format("ddd").toLowerCase()
        ]
        ? it.terrain_time.close[
        moment(this.state.date).locale("en").format("ddd").toLowerCase()
        ]
        : this.props.selectedComplex.complex_time
          ? this.props.selectedComplex.complex_time.close
          : "24:00";
    return { startTime, endTime };
  };

  closingDateAndTime = (date, terrainTimes) => {
    let plusDate = date;
    let hours = terrainTimes.endTime.split(":")[0];
    if (parseInt(hours) >= 24) {
      hours = hours % 24;
      if (hours.toString().length === 1) {
        terrainTimes.endTime =
          "0" + hours.toString() + ":" + terrainTimes.endTime.split(":")[1];
      }
      // not sure why to start day to month
      plusDate = moment(plusDate).add(1, "days").format("YYYY-MM-DD");
    }

    return plusDate + "T" + terrainTimes.endTime + ":00";
  };

  openingDateAndTime = (date, terrainTimes) => {
    let plusDate = date;
    let hours = terrainTimes.startTime.split(":")[0];
    if (parseInt(hours) >= 24) {
      hours = hours % 24;
      if (hours.toString().length === 1) {
        terrainTimes.startTime =
          "0" + hours.toString() + ":" + terrainTimes.startTime.split(":")[1];
      }
      // not sure why to start day to month
      plusDate = moment(plusDate).add(1, "days").format("YYYY-MM-DD");
    }
    return plusDate + "T" + terrainTimes.startTime + ":00";
  };

  loaderEvent = (id) => {
    try {
      let filterResult = this.state.events.filter(
        (it) => it.id.toString() === id.toString()
      );

      if (filterResult.length === 0) return;

      if (filterResult[0].ticket_id || filterResult[0].advance_ticket) {
        this.setState({ mainLoading: true });
        this.props.getReservation(filterResult[0].id);
      } else {
        this.loadReservation(filterResult[0]);
      }
    } catch (e) {
      //
      console.log("load event", e);
    }
  };

  loadReservation = (filterResult) => {
    try {
      let reservationForm = { ...this.state.reservationForm };
      let info = this.state.info ? { ...this.state.info } : {};
      let filledReservation = reservationResponse(
        reservationForm,
        filterResult
      );
      this.props.getReservationInformation({
        user: filledReservation.information.user.user_id,
        complex_id: localStorage.getItem("complex_id"),
      });

      let terrainTimes = this.getClosingAndOpeningOfField(filterResult.terrain);

      let plusDate = this.state.date;
      let hours = terrainTimes.endTime.split(":")[0];
      if (parseInt(hours) >= 24) {
        hours = hours % 24;
        if (hours.toString().length === 1) {
          terrainTimes.endTime =
            "0" + hours.toString() + ":" + terrainTimes.endTime.split(":")[1];
        }
        // not sure why to start day to month
        plusDate = moment(plusDate).add(1, "days").format("YYYY-MM-DD");
      }

      let reservationResult = {
        ...filledReservation,
        //selectedActivity: filledReservation.selectedActivity,
        //selectedCompetition: filledReservation.selectedActivity.competitions[0],
      };

      if (!reservationResult.information.owner_id)
        reservationResult.information.owner_id = this.props.user.id;

      this.setState({
        mainLoading: false,
        reservationForm: reservationResult,
        modalAdd: true,
        get: false,
        info: {
          ...info,
          closingTime: plusDate + "T" + terrainTimes.endTime + ":00",
          openingTime: this.openingDateAndTime(this.state.date, terrainTimes),
        },
      });
    } catch (e) {
      //
      console.log("loadReservation", e);
    }
  };

  // handle date change from the calendar
  onChange = async (e) => {
    // for avoiding ISO
    let date = new Date(e.toString());
    let newDate = moment(date).format("YYYY-MM-DD");
    await this.goTo(newDate, 0);
    await this.setState({ date: newDate });
  };

  // click on the row is full-calendar
  handleDayClick = async (info) => {
    if (!this.state.menuIsOpen) {
      let selected = this.state.planningType.filter((it) => it.selected);
      let finHour = "";
      let t = info.resource.id;
      let reservationForm = { ...this.state.reservationForm };

      let start = info.dateStr.substring(
        info.dateStr.indexOf("T") + 1,
        info.dateStr.indexOf("T") + 6
      );
      let eventDate = info.dateStr.split("T")[0];
      let endTime = info.resource._resource.extendedProps.endTime;
      let startTime = info.resource._resource.extendedProps.startTime;
      let plusDate = this.state.date;
      let hours = endTime ? endTime.split(":")[0] : "0";
      if (parseInt(hours) >= 24) {
        hours = hours % 24;
        if (hours.toString().length === 1) {
          endTime = "0" + hours.toString() + ":" + endTime.split(":")[1];
        }
        // not sure why to start day to month
        plusDate = moment(plusDate).add(1, "days").format("YYYY-MM-DD");
      }

      let terrain = this.props.terrains.filter(
        (it) => it.id.toString() === t.toString()
      );
      if (terrain.length > 0) {
        // test isStart hour after start
        if (
          moment(eventDate + "T" + start + ":00").isBefore(
            this.state.date +
            "T" +
            info.resource._resource.extendedProps.startTime +
            ":00",
            "hour"
          ) ||
          moment(eventDate + "T" + start + ":00").isSameOrAfter(
            plusDate + "T" + endTime + ":00",
            "hour"
          )
        )
          return;

        if (selected.length > 0 && selected[0].activity)
          terrain[0].activities = [selected[0].activity];

        reservationForm.selectedTerrain = terrain;
        if (
          terrain[0].activities.length ||
          (selected.length > 0 && selected[0].activity)
        ) {
          let selectedActivity =
            selected.length > 0 && selected[0].activity
              ? selected[0].activity
              : terrain[0].activities[0];
          reservationForm.selectedActivity = selectedActivity;
          reservationForm.information.activity_id = selectedActivity.id;

          // fill competition { real_missing_participants_nb,missing_participants_nb, participants_nb}
          if (selectedActivity) {
            let nb =
              selectedActivity && selectedActivity.participants_nb
                ? selectedActivity.participants_nb
                : 1;
            let isMatch =
              nullString(selectedActivity.is_match).toString() === "1";
            reservationForm.competition.participants_nb =
              selectedActivity && selectedActivity.participants_nb
                ? selectedActivity.participants_nb
                : 1;
            reservationForm.competition.missing_participants_nb =
              selectedActivity && selectedActivity.participants_nb
                ? selectedActivity.participants_nb - 1
                : 0;
            reservationForm.competition.real_missing_participants_nb = selectedActivity.participants_nb
              ? selectedActivity.participants_nb - 1
              : 0;

            // fill participants
            if (isMatch) {
              let nbPerTeam1 = nb > 1 ? nb - parseInt((nb / 2).toString()) : 1;
              let nbPerTeam2 = nb > 1 ? parseInt((nb / 2).toString()) : 1;

              reservationForm.participants.equipe1.players = new Array(
                nbPerTeam1
              ).fill({ ...player });
              reservationForm.participants.equipe2.players = new Array(
                nbPerTeam2
              ).fill({ ...player });
            } else {
              reservationForm.participants.equipe1.players = new Array(
                nb
              ).fill({ ...player });
              reservationForm.participants.equipe2.players = new Array(0).fill({
                ...player,
              });
            }
          }

          if (selectedActivity.durations.length) {
            let filterByDefault = selectedActivity.durations.filter(
              (it) => it.pivot.is_default
            );
            if (filterByDefault.length) {
              reservationForm.information.duration_id = filterByDefault[0].id;
              finHour = await this.getEndHour(start, filterByDefault[0]);
            } else {
              reservationForm.information.duration_id =
                selectedActivity.durations[0].id;
              finHour = await this.getEndHour(
                start,
                selectedActivity.durations[0]
              );
            }
          }
        }
      }
      let value = moment(info.dateStr).format("YYYY-MM-DD");
      reservationForm.information.date = value;
      reservationForm.information.terrain_id = t;
      reservationForm.information.start_hour = start;
      reservationForm.information.end_hour = finHour;
      reservationForm.information.owner_id = this.props.user.id;

      let owner = null;

      this.setState({
        eventId: "",
        info: {
          ...info,
          dateStr: info.date,
          owner,
          closingTime: plusDate + "T" + endTime + ":00",
          openingTime: value + "T" + startTime + ":00",
        },
        modalAdd: true,
        reservationForm,
      });
    } else if (!this.state.isOperating && this.state.menuIsOpen) {
      this.toggleMenu("hide");
    }
  };

  // handle checkbox
  onShowHandler = (i) => {
    let terrains = [...this.state.terrains];
    terrains[i].hidden = terrains[i].hidden === 1 ? 0 : 1;
    this.setState({ terrains });
  };

  onToggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  // handle the adding or subtracting day from the state.date
  onChangeDay = async (i) => {
    let day = new Date(this.state.date);
    await day.setDate(day.getDate() + i);

    if (i > 0) this.goTo(day, 0);
    else {
      this.goTo(day, 0);
    }

    this.setState({ date: moment(day).format("YYYY-MM-DD") });
  };

  goTo = async (date, i = 0) => {
    this.fullCalendarRef.current.calendar.state.currentDate = new Date(date);
  };

  // render custom menu for the calendar
  contextMenuHandler = (e) => {
    let offset = document.getElementById("calendar").getClientRects()[0];
    const x = e.clientX - offset.left;
    e.preventDefault();
    let isAnEvent =
      e.target.classList.contains("fc-event") ||
      e.target.classList.contains("event-overflow") ||
      (e.target.classList.contains("event-container") &&
        e.target.classList.length === 1);
    let isArow =
      e.target.classList.contains("fc-widget-content") &&
      e.target.classList.length === 1;
    let origin = {
      left: 0,
      top: 0,
    };
    if (e.pageX || e.pageY) {
      origin.left = e.pageX;
      origin.top = e.pageY;
    } else if (e.clientX || e.clientY) {
      origin.left =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      origin.top =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }

    if (isAnEvent) {
      let attributes = null;
      if (e.target.classList.contains("event-container"))
        attributes = e.target.attributes;
      else attributes = e.target.attributes;

      let eventId = attributes.getNamedItem("data-event-id").value;
      this.setState({ menuType: "event", eventId: eventId });
      this.setPosition(origin);
    }
    if (isArow) {
      let columns = document.querySelectorAll(".fc-day");
      let selected = -1;
      const offsetWidth = this.fullCalendarRef.current.elRef.current
        .offsetWidth;
      const offsetHeight = this.fullCalendarRef.current.elRef.current
        .offsetHeight;
      const widthPerCol = offsetWidth / columns.length;
      for (let i = 0; i < columns.length; i++) {
        if (widthPerCol * i < x && widthPerCol * i + widthPerCol) {
          selected = i;
        }
      }
      const startDate = e.target.parentElement.dataset["time"];
      const resourceId = columns[selected].getAttribute("data-resource-id");

      let newEvent = {};
      if (this.state.copy !== null) {
        newEvent = {
          ...this.state.copy,
          newStartHour: startDate,
          resourceId,
          y: e.srcElement.parentElement.rowIndex,
        };
        this.setState({ copy: newEvent });
      } else if (this.state.cut !== null) {
        newEvent = {
          ...this.state.cut,
          newStartHour: startDate,
          resourceId,
          y: e.srcElement.parentElement.rowIndex,
        };
        this.setState({ cut: newEvent });
      }

      this.setState({ menuType: "row" });

      this.setPosition(origin);
    }

    return false;
  };

  copyEvent = (type = "copy") => {
    if (type === "copy") this.setState({ loadingCopy: true });
    else this.setState({ loadingCut: true });

    let eventId = this.state.eventId;
    let events = this.state.events;
    if (eventId !== null) {
      let eventIndex = this.getIndexOfEventById(eventId, events);
      let selectedEvent = null;
      if (eventIndex !== -1) {
        selectedEvent = this.state.events[eventIndex];
        this.setState({ [type]: selectedEvent });
      }

      if (type === "copy") this.setState({ cut: null, loadingCopy: false });
      else this.setState({ copy: null, loadingCut: false });

      this.toggleMenu("hide");
    }
  };

  isBeforeClosing = (reservationForm, info) => {
    let information = { ...reservationForm.information };
    let selectedActivity = reservationForm.selectedActivity
      ? Array.isArray(reservationForm.selectedActivity)
        ? reservationForm.selectedActivity[0]
        : reservationForm.selectedActivity
      : null;
    let selectedDurations = selectedActivity
      ? selectedActivity.durations.filter(
        (it) => it.id === information.duration_id
      )
      : [];
    let isBefore =
      selectedDurations.length > 0 &&
        information.start_hour &&
        info &&
        info.hasOwnProperty("closingTime")
        ? moment(information.date + "T" + information.start_hour + ":00")
          .add(toMinutes(selectedDurations[0].duration), "minutes")
          .isSameOrBefore(info.closingTime)
        : true;

    return isBefore;
  };

  pasteEvent = async () => {
    let { t } = this.props;
    let { cut, copy, events, eventId } = this.state;
    this.setState({ undo: this.state.events });
    let updateEvents = null;

    if (cut !== null) {
      updateEvents = events.filter((ev, i) => ev.id !== eventId);
      let selectedTerrain = this.state.terrains.filter(
        (it) => it.id.toString() === cut.resourceId
      );
      if (
        selectedTerrain.length > 0 &&
        selectedTerrain[0].activities.filter(
          (it) => it.id.toString() === cut.activity_id.toString()
        ).length > 0
      ) {
        let event = this.updateEndDate(cut);
        let reservationRes = reservationResponse(
          { ...this.state.reservationForm },
          event
        );
        let tranformedHour = reservationRes.information.start_hour;
        let terrainTimes = this.getClosingAndOpeningOfField(selectedTerrain[0]);
        let closingTime = this.closingDateAndTime(
          reservationRes.information.date,
          terrainTimes
        );
        //(start_hour,end_hour,activity_id,duration_id, id)

        let { hourTypeId, price } = await this.getHourTypeId(
          tranformedHour,
          reservationRes.information.end_hour,
          reservationRes.information.activity_id,
          reservationRes.information.duration_id,
          reservationRes.information.hour_type_id
        );
        reservationRes.information.hour_type_id = hourTypeId;
        reservationRes.information.price = price;
        let price_per_participant = reservationRes.competition.participants_nb
          ? (
            nullStringNumber(price) /
            reservationRes.competition.participants_nb
          ).toFixed(2)
          : 0;

        reservationRes.competition.price_per_participant = price_per_participant;

        if (this.isBeforeClosing(reservationRes, { closingTime })) {
          this.props.update({ ...reservationRes, date: this.state.date });
        } else {
          warningToast(t("extend-closing-time"));
          this.toggleMenu("hide");
        }
      } else {
        warningToast(t("invalid-area"));
        this.toggleMenu("hide");
        for (let i = 0; i < this.state.terrains.length; i++)
          await this.state.terrains[i].activities.forEach((it) => {
            if (it.id.toString() === cut.activity_id.toString()) {
              document
                .querySelector(
                  'td[data-resource-id="' + this.state.terrains[i].id + '"]'
                )
                .classList.add("animated-border");
            }
          });
      }
    } else if (copy !== null) {
      updateEvents = events.filter((ev, i) => ev.id !== eventId);
      let selectedTerrain = this.state.terrains.filter(
        (it) => it.id.toString() === copy.resourceId
      );

      if (
        selectedTerrain.length > 0 &&
        selectedTerrain[0].activities.filter(
          (it) => it.id.toString() === copy.activity_id.toString()
        ).length > 0
      ) {
        let event = this.updateEndDate(copy);
        event.id = null;
        let reservationRes = reservationResponse(
          { ...this.state.reservationForm },
          event
        );

        let terrainTimes = this.getClosingAndOpeningOfField(selectedTerrain[0]);
        let closingTime = this.closingDateAndTime(
          reservationRes.information.date,
          terrainTimes
        );

        //(start_hour,end_hour,activity_id,duration_id, id)
        let { hourTypeId, price } = await this.getHourTypeId(
          reservationRes.information.start_hour,
          reservationRes.information.end_hour,
          reservationRes.information.activity_id,
          reservationRes.information.duration_id,
          reservationRes.information.hour_type_id
        );
        reservationRes.information.hour_type_id = hourTypeId;
        reservationRes.information.price = price;

        reservationRes.information.is_advance = 0;
        reservationRes.information.advance_ticket = getAdvanceTicket();
        reservationRes.information.advance = "";

        reservationRes.information.price = price;
        reservationRes.information.conclusion = "A valider";

        let price_per_participant = reservationRes.competition.participants_nb
          ? (
            nullStringNumber(price) /
            reservationRes.competition.participants_nb
          ).toFixed(2)
          : 0;
        reservationRes.competition.price_per_participant = price_per_participant;

        if (this.isBeforeClosing(reservationRes, { closingTime })) {
          this.props.store({
            ...reservationRes,
            date: this.state.date,
            copy: true,
          });
        } else {
          warningToast(t("extend-closing-time"));
          this.toggleMenu("hide");
        }
      } else {
        warningToast(t("invalid-area"));
        this.toggleMenu("hide");
        for (let i = 0; i < this.state.terrains.length; i++)
          await this.state.terrains[i].activities.forEach((it) => {
            if (it.id.toString() === copy.activity_id.toString()) {
              document
                .querySelector(
                  'td[data-resource-id="' + this.state.terrains[i].id + '"]'
                )
                .classList.add("animated-border");
            }
          });
      }
    }
  };

  /**
   *  render delete modal for event  when right click an event
   * @returns {ReactComponent}
   */
  renderDeleteModal = () => {
    const { t, reservations } = this.props;
    let { eventId } = this.state;
    let filterResult = reservations.filter(
      (it) => it.id.toString() === eventId
    );

    return (
      <Modal
        open={this.state.deleteModal}
        onClose={() =>
          this.setState({ deleteModal: false, selectedChoice: false })
        }
        size="tiny"
      >
        <Modal.Header>{t("confirm-delete")}</Modal.Header>
        <Modal.Content>
          <p>{t("msg-confirm-delete-reservation")}</p>
          {filterResult.length > 0 && filterResult[0].ticket_id && (
            <Message negative>
              <p>
                {filterResult[0].ticket_status === 0 &&
                  t("you_gonna_to_cancled_a_ticket") +
                  filterResult[0].ticket_number}
              </p>
              <p>
                {filterResult[0].ticket_status === 1 &&
                  t("you_gonna_to_detele_a_ticket") +
                  filterResult[0].ticket_number}
              </p>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t("cancel")}
            floated={"left"}
            onClick={() =>
              this.setState({ deleteModal: false, selectedChoice: false })
            }
          />
          {filterResult.length > 0 &&
            (filterResult[0].parent_id || filterResult[0].recurrence !== 0) && (
              <Button
                content={t("delete_all")}
                color={"google plus"}
                loading={this.props.deleteLoading}
                onClick={() => {
                  this.setState({ deleteModal: false, deleteAllModal: true });
                }}
              />
            )}
          <Button
            content={t("delete")}
            className="negative"
            loading={this.props.deleteLoading || this.state.mainLoading}
            onClick={debounce(async () => {
              try {
                this.setState({ mainLoading: true });
                let status = 0;
                let ticket_id = filterResult.length
                  ? filterResult[0].ticket_id
                  : null;
                let ticket = ticket_id ? await edit(filterResult[0].id) : null;
                if (ticket) ticket = ticket.data.data.ticket;
                if (ticket) {
                  await this.props.updateCurrentTicket(convertTicket(ticket));
                  // cancel ticket status = 0
                  if (ticket.status.toString() === "0") {
                    await this.cancelArticles(true);
                  } else if (ticket.status.toString() === "1") {
                    await this.deleteTicket();
                  }
                }
                await this.props.destory(this.state.eventId, false);
                this.setState({
                  mainLoading: false,
                  deleteModal: false,
                  selectedChoice: false,
                });
              } catch (e) {
                this.setState({
                  mainLoading: false,
                  deleteModal: false,
                  selectedChoice: false,
                });
              }
            }, 1000)}
          />
        </Modal.Actions>
      </Modal>
    );
  };
  /**
   *  render delete all modal for event  when right click an event
   * @returns {ReactComponent}
   */
  renderDeleteAllModal = () => {
    const { t, reservations } = this.props;
    let { eventId } = this.state;
    let filterResult = reservations.filter(
      (it) => it.id.toString() === eventId
    );

    return (
      <Modal
        open={this.state.deleteAllModal}
        onClose={() =>
          this.setState({ deleteModal: false, selectedChoice: false })
        }
        size="tiny"
      >
        <Modal.Header>{t("confirm-delete")}</Modal.Header>
        <Modal.Content>
          <p>
            {filterResult.length > 0 && filterResult[0].parent_id
              ? t("msg-confirm-delete-reservation-child")
              : t("msg-confirm-delete-reservation-parent")}
          </p>
          {filterResult.length > 0 && filterResult[0].ticket && (
            <Message negative>
              <p>
                {nullString(filterResult[0].ticket_status).toString() === "0" &&
                  t("you_gonna_to_cancled_a_ticket") +
                  filterResult[0].ticket_number}
              </p>
              <p>
                {nullString(filterResult[0].ticket_status).toString() === "1" &&
                  t("you_gonna_to_detele_a_ticket") +
                  filterResult[0].ticket_number}
              </p>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t("no")}
            floated={"left"}
            onClick={() =>
              this.setState({
                deleteAllModal: false,
                deleteModal: false,
                selectedChoice: false,
              })
            }
          />
          {filterResult.length > 0 &&
            (filterResult[0].parent_id || filterResult[0].recurrence !== 0) && (
              <Button
                content={t("yes")}
                className="negative"
                loading={this.props.deleteLoading}
                onClick={() => {
                  this.props.destory(
                    this.state.eventId,
                    filterResult[0].parent_id,
                    true
                  );
                  this.setState({
                    deleteModal: false,
                    selectedChoice: false,
                    deleteAllModal: false,
                  });
                }}
              />
            )}
        </Modal.Actions>
      </Modal>
    );
  };

  handleClientReservationChange = (e) => {
    let { reservationForm } = this.state;
    let { information } = reservationForm;
    let { user } = information;
    let newReservationForm = {
      ...reservationForm,
      information: {
        ...information,
        user: { ...user, [e.target.name]: e.target.value },
      },
    };
    this.setState({ reservationForm: newReservationForm });
  };

  handleReservationChange = (e) => {
    let { reservationForm } = this.state;
    let { information } = this.state.reservationForm;

    information = { ...information, [e.target.name]: e.target.value };
    reservationForm = { ...reservationForm, information };
    this.setState({ reservationForm: reservationForm });
  };

  EnCaisseEvent = () => {
    let eventId = this.state.eventId;
    if (this.checkForSession())
      this.props.getReservationArticle({
        id: eventId,
        isLeisure: false,
        all: false,
        user_id: null,
      });
    else this.toggleSessionModal(true);
  };

  undoEvent = () => {
    let { undo, cut, copy } = this.state;

    // chacking if the last operation is copy or cut
    if (undo !== null && undo.length) {
      // operation is cut
      if (cut) {
        let updateEvents = undo.filter(
          (ev, i) => ev.id.toString() === cut.id.toString()
        );
        if (updateEvents.length) {
          let reservationRes = reservationResponse(
            { ...this.state.reservationForm },
            updateEvents[0]
          );
          this.props.update({ ...reservationRes });
        }
      } else if (copy) {
        // how to get the id of added it
        if (this.props.addedReservation)
          this.props.destory(this.props.addedReservation.id);
      }
    }
    this.setState({ undo: null });
    this.toggleMenu("hide");
  };

  // TODO ADD REMOVE
  removeEvent = () => {
    this.setState({ undo: this.state.events });
    let { events, eventId } = this.state;
    // let selectedEvent = events.filter(it=>it.id.toString() === eventId.toString());
    // if(selectedEvent.length && selectedEvent[0].price)
    this.setState({ deleteModal: true });
  };

  getEndHour = async (start, duration) => {
    let finDate = "";
    try {
      let minute = duration.duration.split(":");
      minute[0] = parseInt(minute[0]) * 60;
      minute[1] = parseInt(minute[1]) + minute[0];

      finDate = await moment(
        formattingDate(this.state.date) + "T" + start + ":00+00:00"
      )
        .utc()
        .add(minute[1], "minute")
        .format("HH:mm");
    } catch (e) {
      //
    }

    return finDate;
  };

  updateEndDate = (event) => {
    let { close, open } = JSON.parse(localStorage.getItem("complex_time"));

    let today = moment(this.state.date).format("YYYY-MM-DD");
    let newStart = today + "T" + event.newStartHour + "+00:00";

    let getDiff = moment(event.start).diff(moment(event.end));
    let newEndHour = moment(newStart).add(Math.abs(getDiff), "ms");
    newEndHour = event.newStartHour.substr(0, 5);
    let minute = event.duration.duration.split(":");
    minute[0] = parseInt(minute[0]) * 60;
    minute[1] = parseInt(minute[1]) + minute[0];

    let finDate = moment(this.state.date + "T" + newEndHour + ":00+00:00")
      .utc()
      .add(minute[1], "minute")
      .format("HH:mm");

    let newEnd = today + "T" + newEndHour + ":00+00:00";
    let arrTr = document.querySelectorAll(
      'tr[data-time="' + event.newStartHour + '"]'
    );
    let index = -1;
    arrTr.forEach((it, i) => {
      if (it.rowIndex === event.y) index = i;
    });
    if (
      parseInt(event.newStartHour.substr(0, 5).split(":")[0]) <
      parseInt(open.split(":")[0]) &&
      index >= 0
    ) {
      today = moment(today).add(1, "day").format("YYYY-MM-DD");
    }
    event = {
      ...event,
      date: today,
      start_hour: event.newStartHour.substr(0, 5),
      end_hour: finDate,
      terrain_id: event.resourceId,
    };
    return event;
  };

  getIndexOfEventById = (eventId, events) => {
    for (let i = 0; i < events.length; i++) {
      if (events[i].id.toString() === eventId.toString()) return i;
    }
    return -1;
  };

  setPosition = ({ left, top }, type = 1) => {
    this.toggleMenu("show");
    if (type === 1) {
      this.contextMenu.current.style.left = `${left}px`;
      this.contextMenu.current.style.top = `${top}px`;
    }
    if (type === 2) {
      this.contextMenu.current.style.right = `${left}px`;
      this.contextMenu.current.style.top = `${top}px`;
    }
  };

  getHourTypeId = async (
    start_hour,
    end_hour,
    activity_id,
    duration_id,
    id
  ) => {
    /**
     *
     date: this.state.reservationForm.information.date,
     complex_id: localStorage.getItem('complex_id'),
     activity_id: this.state.reservationForm.information.activity_id,
     end_hour: this.state.reservationForm.information.end_hour,
     start_hour: this.state.reservationForm.information.start_hour,
     duration_id: this.state.reservationForm.information.duration_id,
     * */
    let data = await reservationHour({
      date: this.state.date,
      start_hour,
      end_hour,
      duration_id,
      activity_id,
      complex_id: localStorage.getItem("complex_id"),
    });
    try {
      if (data.data.data.length > 0) {
        let filterData = data.data.data.filter((it) => it.id === id);
        if (filterData.length > 0)
          return {
            hourTypeId: filterData[0].id,
            price: filterData[0].article_amount,
          };
        else
          return {
            hourTypeId: data.data.data[0].id,
            price: data.data.data[0].article_amount,
          };
      } else return { hourTypeId: 0, price: 0 };
    } catch (e) {
      return { hourTypeId: 0, price: 0 };
    }
  };

  toggleMenu = (command) => {
    let isOpen = command === "show";
    this.setState({ menuIsOpen: isOpen });
  };

  cancelArticles = async (allSelected = true) => {
    let status = 2;
    let currentTicket = { ...this.props.currentTicket };
    let articles = [];
    // let subscriberDetailsToDelete = [];
    // let creditDetailsToDelete = [];
    let creditValue = 0;
    let totalArticleAmount = 0;
    let restoredCreditList = [];
    for (let article of currentTicket.articles) {
      if (allSelected) {
        totalArticleAmount += parseFloat(article.payed_amount);
        for (let article_payment of currentTicket.payments.articles) {
          if (article_payment.article_id.toString() === article.id.toString()) {
            //restore avoir
            if (article_payment.credit_id) {
              creditValue += parseFloat(article_payment.amount);
              let restoredCreditListIds = restoredCreditList.length
                ? restoredCreditList.map((itm) => {
                  return itm.id;
                })
                : [];
              if (!restoredCreditListIds.includes(article_payment.credit_id)) {
                restoredCreditList.push({
                  id: article_payment.credit_id,
                  credit_number: getCreditNumber(
                    currentTicket,
                    article_payment.credit_id
                  ),
                  credit_value: parseFloat(article_payment.amount),
                });
              } else {
                restoredCreditList.map((itm, i) => {
                  if (itm.id === article_payment.credit_id) {
                    itm.credit_value =
                      parseFloat(itm.credit_value) +
                      parseFloat(article_payment.amount);
                  }
                });
              }
            }
          }
        }
        article.status = "cancled";
      }
      //all articles cancled ->  ticket cancled
      if (article.status !== "cancled") {
        status = 0;
      }
      articles.push(article);
    }

    currentTicket.articles = articles;
    currentTicket.status = status;
    if (allSelected) {
      currentTicket.status = 2;
      let articles = currentTicket.articles.map((article, index) => {
        return { ...article, status: "cancled" };
      });
      currentTicket.articles = articles;
    }
    creditValue = parseFloat(totalArticleAmount);
    this.setState({ credit_value: creditValue });

    // this.props.restored_credit_list(restoredCreditList);
    // currentTicket.restoredCreditList = restoredCreditList;

    // await this.props.updateTicket(currentTicket);
  };

  renderCreditModal = () => {
    return (
      <ModalConfirm
        open={this.state.creditModalOpen}
        onClose={() => {
          this.setState({ creditModalOpen: false });
        }}
        title={"Avoir"}
        message={
          <>
            <p>
              L'avoir n° {this.props.credit && this.props.credit.credit_number}{" "}
              d'un montant de{" "}
              {this.props.credit &&
                parseFloat(this.props.credit.amount).toFixed(2)}
              {localStorage.getItem("currency")} a été généré
            </p>
            {this.renderRestoredCreditList()}
          </>
        }
        ok={() => {
          this.setState({ creditModalOpen: false });
          this.props.restored_credit_list(null);
        }}
      />
    );
  };

  renderRestoredCreditList = () => {
    if (this.props.restoredCreditList) {
      return this.props.restoredCreditList.map((itm, i) => {
        return (
          <p key={i}>
            L'avoir n° {itm.credit_number} d'un montant de{" "}
            {parseFloat(itm.credit_value).toFixed(2)}
            {localStorage.getItem("currency")} a été restauré
          </p>
        );
      });
    }
  };

  createCredit(credit_amount, cancelMethod = null) {
    let currentTicket = { ...this.props.currentTicket };
    let credit = {};
    credit.date = currentTicket.date;
    credit.hour = currentTicket.hour;
    credit.amount = parseFloat(credit_amount).toFixed(2);
    credit.is_credit = 1;
    credit.payed_amount = 0;
    credit.status = 0;
    credit.owner_id = this.props.user.id;
    credit.parent_id = currentTicket.id;
    credit.complex_id = currentTicket.complex_id;
    credit.articles = [];
    credit.participants = [];
    if (credit_amount > 0) {
      this.props.storeTicket(credit);
    }
  }

  renderConfirmCreditModal() {
    return (
      <ModalConfirm
        open={this.state.confirmCreditModalOpen}
        onClose={() => this.setState({ confirmCreditModalOpen: false })}
        title={"Confirmation avoir"}
        message={<p>Cette action va générer un avoir</p>}
        ok={() => {
          this.createCredit(this.state.credit_value);
          this.setState({ confirmCreditModalOpen: false });
        }}
        cancel={() => {
          this.props.updateCurrentTicket(null);
          this.setState({ confirmCreditModalOpen: false });
        }}
      />
    );
  }

  deleteTicket = async () => {
    let currentTicket = { ...this.props.currentTicket };
    let { reservation_id } = currentTicket;

    this.props.setValueReservationTicket({ reservationTicket: null });
    if (currentTicket.id) {
      //restore credit/advance
      // for (let article of currentTicket.articles) {
      //   await this.restoreCreditOrAdvance(currentTicket.payments.articles, article.id);
      // }
      // if (currentTicket && reservation_id)
      //   await this.props.destoryReservation({ id: reservation_id });

      await caisseService.caisseService.destroyTicket(currentTicket.id);
    }
  };

  /***
   * Restore credit or advance
   */
  // restoreCreditOrAdvance = async (payments_type, id) => {
  //   for (let payment_type of payments_type) {
  //     if (payment_type.article_id.toString() === id.toString()) {
  //       if (payment_type.credit_id) {
  //         let creditTicket = await caisseService.caisseService.getCredit(payment_type.credit_id);
  //         creditTicket = creditTicket.data;
  //         creditTicket.payed_amount = parseFloat(creditTicket.payed_amount) - parseFloat(payment_type.amount);
  //         await caisseService.caisseService.updateTicket(creditTicket);
  //       }
  //       if (payment_type.advance_id) {
  //         let advanceTicket = await caisseService.caisseService.getCredit(payment_type.advance_id);
  //         advanceTicket = convertTicket(advanceTicket.data);
  //         advanceTicket.payed_amount = parseFloat(advanceTicket.payed_amount) - parseFloat(payment_type.amount);
  //         await caisseService.caisseService.updateTicket(advanceTicket);
  //       }
  //     }
  //   }
  // };

  handleCompetition = (e, d) => {
    let reservationForm = { ...this.state.reservationForm };
    reservationForm.competition[e.target.name] = e.target.value;
    this.setState({ reservationForm });
  };

  componentDidMount() {
    const getTerrainsPromise = new Promise((resolve) =>
      resolve(this.props.getTerrains(1, ""))
    );
    const getPlanningTypesPromise = new Promise((resolve) =>
      resolve(this.props.getPlanningTypes(1, ""))
    );
    const getTeamsPromise = new Promise((resolve) =>
      resolve(this.props.getTeams(1, 2, { is_default: 1 }))
    );

    Promise.all([getTeamsPromise, getTerrainsPromise, getPlanningTypesPromise]);

    if (this.props.type === "caisse") {
      this.scrollEvent = document
        .querySelector(".caisse-main-content")
        .addEventListener(
          "scroll",
          (e) =>
            this.scrollEventListener(
              e,
              document.querySelector(".caisse-main-content")
            ),
          false
        );
    } else {
      this.scrollEvent = document.addEventListener(
        "scroll",
        (e) => this.scrollEventListener(e, window),
        false
      );
    }

    // auto refresh
    // this.intervallLoading.current = setInterval(() => {
    //   let diff = moment().diff(this.props.lastRefresh, 'seconds');
    //   if (diff && diff >= DIFF_TIME && !this.props.loading) {
    //     let selected = this.state.planningType.filter(it => it.selected);
    //     this.props.index({
    //       silent: true,
    //       page: 1,
    //       size: '',
    //       terrains: selected.length > 0 ? selected[0].terrains.map(it => it.id) : [],
    //       search: {
    //         search_activity: selected.length > 0 && selected[0].activity ? selected[0].activity.id : '',
    //         show_next: true,
    //         search_date: moment(this.state.date).format('DD-MM-YYYY'),
    //       },
    //     });
    //   }
    // }, DIFF_TIME);
  }

  async componentDidUpdate(prevProps, prevState) {
    // show confirmation modal for cancled article
    if (
      this.state.credit_value !== prevState.credit_value &&
      this.state.credit_value > 0
    ) {
      this.setState({ confirmCreditModalOpen: true });
    }

    //get credit number
    if (this.props.credit && this.props.credit !== prevProps.credit) {
      this.setState({ creditModalOpen: true });
    }

    //open reservation modal after getting reservation
    if (
      this.props.reservation !== prevProps.reservation &&
      this.props.reservation
    ) {
      this.loadReservation(this.props.reservation);
    }

    if (
      (this.state.modalDeleted === true &&
        this.state.modalDeleted !== prevState.modalDeleted &&
        !(this.props.recoverLoading || this.props.loading)) ||
      this.props.successRetrieve !== prevProps.successRetrieve
    ) {
      let selected = this.state.planningType.filter((it) => it.selected);
      let terrains =
        selected.length > 0 ? selected[0].terrains.map((it) => it.id) : [];
      this.props.deletedReservation(1, 3, terrains);
    }

    if (
      this.state.reservationForm.information.start_hour &&
      this.state.reservationForm.information.end_hour &&
      this.state.reservationForm.information.activity_id &&
      this.state.modalAdd &&
      (this.state.reservationForm.information.start_hour !==
        prevState.reservationForm.information.start_hour ||
        this.state.reservationForm.information.end_hour !==
        prevState.reservationForm.information.end_hour ||
        this.state.reservationForm.information.activity_id !==
        prevState.reservationForm.information.activity_id ||
        this.state.modalAdd !== prevState.modalAdd)
    ) {
      this.props.getReservationHour({
        date: this.state.date,
        complex_id: localStorage.getItem("complex_id"),
        activity_id: this.state.reservationForm.information.activity_id,
        end_hour: this.state.reservationForm.information.end_hour,
        start_hour: this.state.reservationForm.information.start_hour,
        duration_id: this.state.reservationForm.information.duration_id,
      });

      /**
       * $complex_id, $activity_id, $date, $duration_id, $start_hour, $end_hour
       * */
    }

    if (
      this.state.reservationForm.information.start_hour &&
      this.state.reservationForm.information.end_hour &&
      this.state.reservationForm.information.activity_id &&
      this.state.reservationForm.information.duration_id &&
      this.props.hourTypes !== prevProps.hourTypes &&
      !this.state.reservationForm.information.ticket
    ) {
      let price = -1;
      if (
        this.props.hourTypes.length > 0 &&
        this.props.hourTypes !== prevProps.hourTypes
      )
        if (
          (!this.state.reservationForm.information.ticket &&
            this.state.reservationForm.information.hour_type_id === 0) ||
          this.state.reservationForm.information.hour_type_id === ""
        ) {
          await this.handleReservationChange({
            target: { name: "hour_type_id", value: this.props.hourTypes[0].id },
          });

          await this.handleReservationChange({
            target: {
              name: "price",
              value: this.props.hourTypes[0].article_amount,
            },
          });
          price = this.props.hourTypes[0].article_amount;
        } else if (
          this.props.hourTypes.filter(
            (it) =>
              it.id === this.state.reservationForm.information.hour_type_id
          ).length === 0
        ) {
          await this.handleReservationChange({
            target: { name: "hour_type_id", value: this.props.hourTypes[0].id },
          });
          if (!this.state.reservationForm.information.price)
            await this.handleReservationChange({
              target: {
                name: "price",
                value: this.props.hourTypes[0].article_amount,
              },
            });
          price = this.props.hourTypes[0].article_amount;
        } else {
          let filteredData = this.props.hourTypes.filter(
            (it) =>
              it.id === this.state.reservationForm.information.hour_type_id
          );
          if (filteredData.length > 0) {
            if (!this.state.reservationForm.information.ticket)
              await this.handleReservationChange({
                target: {
                  name: "price",
                  value: filteredData[0].article_amount,
                },
              });
            price = filteredData[0].article_amount;
          }
        }
      if (this.state.reservationForm.information.ticket)
        price = this.state.reservationForm.information.price;
      if (!this.state.reservationForm.information.is_player && price > 0) {
        let price_per_participant =
          nullStringNumber(
            this.state.reservationForm.competition.participants_nb
          ) && price > 0
            ? (
              price /
              nullStringNumber(
                this.state.reservationForm.competition.participants_nb
              )
            ).toFixed(2)
            : 0;
        await this.handleCompetition({
          target: {
            name: "price_per_participant",
            value: price_per_participant,
          },
        });
      }
    }

    if (
      this.state.date !== prevState.date ||
      this.state.planningType.length !== prevState.planningType.length
    ) {
      let selected = this.state.planningType.filter((it) => it.selected);
      this.props.index({
        page: 1,
        size: "",
        terrains:
          selected.length > 0 ? selected[0].terrains.map((it) => it.id) : [],
        search: {
          search_activity:
            selected.length > 0 && selected[0].activity
              ? selected[0].activity.id
              : "",
          search_date: moment(this.state.date).format("DD-MM-YYYY"),
          show_next: true,
        },
      });
    }

    if (prevProps.updated !== this.props.updated && this.props.updated) {
      if (this.state.menuIsOpen) this.toggleMenu("hide");

      await this.resetAddReservationModal();
      // let selected = this.state.planningType.filter(it => it.selected);
      // await this.props.index({
      //   page: 1,
      //   size: '',
      //   terrains: selected.length > 0 ? selected[0].terrains.map(it => it.id) : [],
      //   search: {
      //     search_activity: selected.length > 0 && selected[0].activity ? selected[0].activity.id : '',
      //     show_next: true, search_date: moment(this.state.date).format('DD-MM-YYYY'),
      //   },
      // });
      this.setState((s) => ({ canMove: true }));
    }

    if (
      this.props.teams !== prevProps.teams ||
      (!this.props.loadingTerrain &&
        this.props.loadingTerrain !== prevProps.loadingTerrain) ||
      this.props.reservations !== prevProps.reservations ||
      this.props.terrains !== prevProps.terrains
    ) {
      if (this.props.terrains !== prevProps.terrains) {
        this.setState({ terrains: this.props.terrains });
      }

      if (this.props.teams !== prevProps.teams) {
        this.setState({ teams: this.props.teams });
      }

      if (this.props.reservations !== prevProps.reservations) {
        this.setState({ events: this.props.reservations }, () => {
          if (this.state.get) {
            this.loaderEvent(this.state.search_id);
          }
        });
      }
      if (
        !this.props.loadingTerrain &&
        this.props.loadingTerrain !== prevProps.loadingTerrain
      ) {
        menu = document.getElementById("context-menu");
        contextMenuListener = document
          .getElementById("calendar")
          .addEventListener("contextmenu", this.contextMenuHandler);

        window.addEventListener("click", (e) => {
          if (!this.state.isOperating && this.state.menuIsOpen) {
            if (
              !e.target.className.includes("fc") &&
              !e.target.className.includes("menu-option")
            )
              this.toggleMenu("hide");
          }
        });
      }
    }
    // if (this.props.successRetrieve !== prevProps.successRetrieve) {
    //   this.props.deletedReservation(1, 3);
    // }

    if (prevProps.EncaisseLoading && !this.props.EncaisseLoading)
      this.toggleMenu("hide");

    if (
      !this.state.menuIsOpen === "show" &&
      this.state.menuIsOpen !== prevState.menuIsOpen
    )
      this.setState({ eventId: null });

    if (prevProps.planningTypes !== this.props.planningTypes) {
      let planningType = [...this.state.planningType];
      this.props.planningTypes.reverse().forEach((it) => {
        planningType.push({
          name: it.name,
          activity: it.activity ? it.activity : null,
          id: it.id,
          isDefault: false,
          selected: false,
          terrains: it.terrains,
        });
      });
      if (planningType.length === 1) {
        let selected = planningType.filter((it) => it.selected);
        this.props.index({
          page: 1,
          size: "",
          terrains:
            selected.length > 0 ? selected[0].terrains.map((it) => it.id) : [],
          search: {
            search_activity:
              selected.length > 0 && selected[0].activity
                ? selected[0].activity.id
                : "",
            search_date: moment(this.state.date).format("DD-MM-YYYY"),
            show_next: true,
          },
        });
      }
      this.setState({ planningType });
    }
  }

  componentWillUnmount() {
    try {
      let eventScroll = document.querySelector(".caisse-main-content");
      if (eventScroll)
        eventScroll.removeEventListener(
          "scroll",
          this.scrollEventListener,
          false
        );
      document.removeEventListener("scroll", this.scrollEventListener, false);
      let eventCalendar = document.getElementById("calendar");
      if (eventCalendar)
        eventCalendar.removeEventListener(
          "contextmenu",
          this.contextMenuHandler
        );
      // if (this.intervallLoading.current) clearInterval(this.intervallLoading.current);
    } catch (e) {
      //
    }
  }

  scrollEventListener = (e, element) => {
    //840px window.innerWidth
    let defaultHeight = this.props.type === "caisse" ? 200 : 250;
    let extra = document.querySelector("#stats-element")
      ? document.querySelector("#stats-element").getBoundingClientRect().height
      : 0;
    let height = this.state.toggle
      ? defaultHeight
      : window.innerWidth <= 840 && document.querySelector(".planning-left")
        ? document.querySelector(".planning-left").getBoundingClientRect()
          .height + defaultHeight
        : defaultHeight;
    let scroll =
      this.props.type === "caisse" ? element.scrollTop : element.scrollY;
    // if (this.props.caisse) height = 200;
    height += extra;

    if (scroll > height) {
      let header = document.querySelector(".fc-row.fc-widget-header");
      let current = header ? header.cloneNode(true) : "";
      let stickyHeader = document.getElementById("sticky-header");
      if (stickyHeader) {
        // clear all
        stickyHeader.innerHTML = "";
        stickyHeader.append(current);
      }
    } else {
      let stickyHeader = document.getElementById("sticky-header");
      if (stickyHeader) stickyHeader.innerHTML = "";
    }
  };

  render() {
    const { t, configuration } = this.props;
    const { planningType } = this.state;
    let selected = planningType.filter((it) => it.selected);
    let terrainsSelected =
      selected.length > 0 && !selected[0].isDefault
        ? selected[0].terrains
        : this.state.terrains;
    let localisation = localStorage.getItem("lang") === "fr" ? fr : en;
    let openComplex =
      this.props.selectedComplex.complex_time &&
        this.props.selectedComplex.complex_time.open
        ? this.props.selectedComplex.complex_time.open
        : "08:00";
    let closeComplex =
      this.props.selectedComplex.complex_time &&
        this.props.selectedComplex.complex_time.close
        ? this.props.selectedComplex.complex_time.close
        : "24:00";

    let terrains = terrainsSelected.map((it, i) => {
      return {
        id: it.id,
        title: it.name,
        BackgroundColor: it.color,
        show: it.hidden === 0,
        businessHours: {
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // ALL week,
          startTime:
            it.terrain_time &&
              it.terrain_time.open &&
              it.terrain_time.open[
              moment(this.state.date).locale("en").format("ddd").toLowerCase()
              ]
              ? it.terrain_time.open[
              moment(this.state.date)
                .locale("en")
                .format("ddd")
                .toLowerCase()
              ]
              : openComplex,
          endTime:
            it.terrain_time &&
              it.terrain_time.close &&
              it.terrain_time.close[
              moment(this.state.date).locale("en").format("ddd").toLowerCase()
              ]
              ? it.terrain_time.close[
              moment(this.state.date)
                .locale("en")
                .format("ddd")
                .toLowerCase()
              ]
              : closeComplex,
        },

        startTime:
          it.terrain_time &&
            it.terrain_time.open &&
            it.terrain_time.open[
            moment(this.state.date).locale("en").format("ddd").toLowerCase()
            ]
            ? it.terrain_time.open[
            moment(this.state.date).locale("en").format("ddd").toLowerCase()
            ]
            : openComplex,
        endTime:
          it.terrain_time &&
            it.terrain_time.close &&
            it.terrain_time.close[
            moment(this.state.date).locale("en").format("ddd").toLowerCase()
            ]
            ? it.terrain_time.close[
            moment(this.state.date).locale("en").format("ddd").toLowerCase()
            ]
            : closeComplex,
      };
    });

    let events = [];
    if (this.props.reservations.length > 0)
      events = this.props.reservations.map((it) => ({
        id: it.id,
        resourceId: it.terrain_id,
        title: it.activity?.name,
        price: it.price,
        color: conclusionColor(conclusionForPlayer(it)),
        conclusion: conclusionForPlayer(it),
        start: it.date + "T" + it.start_hour + ":00+00:00",
        end: moment(it.date + "T" + it.start_hour)
          .add(toMinutes(it.duration.duration), "minutes")
          .format("YYYY-MM-DDTHH:mm:00+00:00"),
        dateEvent: it.date,
        startHour: it.start_hour,
        endHour: it.end_hour,
        icon: it.activity?.icon
          ? imgUrl +
          "/activities/" +
          localStorage.getItem("complex_id") +
          "/" +
          it.activity.icon
          : require("../../assets/placeholder/item.jpg"),
        user: {
          firstname: nullStringForTable(
            it.contact_id
              ? it.contact
                ? it.contact.first_name
                : null
              : it.user
                ? it.user.firstName
                : null
          ),
          lastname: nullStringForTable(
            it.contact_id
              ? it.contact
                ? it.contact.last_name
                : null
              : it.user
                ? it.user.lastName
                : null
          ),
          phone: nullStringForTable(
            it.contact_id
              ? it.contact
                ? formatCodeCountry(it.contact.country_code) +
                phoneNumber(it.contact.mobile)
                : null
              : it.user
                ? formatCodeCountry(it.user.country_code) +
                phoneNumber(it.user.mobile)
                : null
          ),
          company: nullStringForTable(
            it.contact_id
              ? it.contact
                ? it.contact.company
                : null
              : it.user
                ? it.user.company
                : null
          ),
        },
        owner: {
          firstname: nullString(it.owner ? it.owner.firstName : null),
          lastname: nullString(it.owner ? it.owner.lastName : null),
          // mobile: nullString(it.owner ? (it.owner.country_code === "33" ? (it.owner.mobile ? phoneNumber("0" + it.owner.mobile) : null) : phoneNumber(it.owner.mobile)) : null)
          mobile: nullString(
            it.owner
              ? formatCodeCountry(it.owner.country_code) +
              phoneNumber(it.owner.mobile)
              : null
          ),
        },
        hasRecurrence: !!it.parent_id || !!it.recurrence,
        info: it.infos,
        created_at: it.created_at,
        hasCompetition: !!it.match?.competition_id,
        competition: it.match,
        status: it.ticket_id
          ? parseFloat(it.ticket_price) === 0 && it.ticket_status !== 0
            ? 2
            : it.ticket_status
          : -1,
      }));

    let selectedEvent = null;

    if (this.state.events.length > 0 && this.state.eventId)
      selectedEvent = this.state.events.filter(
        (it) => it.id.toString() === this.state.eventId.toString()
      );

    // filter the terrains that has show === true
    let filteredTerrains = terrains.filter((it) => it.show);

    return (
      <MainLayout
        {...this.props.sidebarProps}
        withMenu={this.props.type !== "caisse"}
      >
        {this.renderHistoryReservationModal()}
        {this.renderRecoveryModal()}
        {this.renderConfirmCreditModal()}

        {this.state.modalAdd && (
          <ReservationModal
            openComplex={openComplex}
            closeComplex={closeComplex}
            title={
              <div className={"reservation-modal-title"}>
                {selectedEvent && selectedEvent.length > 0 && (
                  <span>
                    {selectedEvent && selectedEvent.length > 0
                      ? t("createdBy") +
                      " " +
                      nullStringForTable(
                        selectedEvent[0].owner
                          ? selectedEvent[0].owner.firstName
                          : null
                      ) +
                      " " +
                      nullStringForTable(
                        selectedEvent[0].owner
                          ? selectedEvent[0].owner.lastName
                          : null
                      ) +
                      " "
                      : ""}
                  </span>
                )}
                <h2>
                  {t("reservation") +
                    " " +
                    t("of") +
                    " " +
                    moment(this.state.info.dateStr)
                      .utc()
                      .locale(i18n.language, localization)
                      .format("dddd DD MMMM YYYY") +
                    " " +
                    t("a").toLowerCase() +
                    " " +
                    this.state.reservationForm.information.start_hour}
                </h2>
                {selectedEvent && selectedEvent.length > 0 && (
                  <span>
                    {selectedEvent && selectedEvent.length > 0
                      ? " " +
                      t("createdAt") +
                      " " +
                      moment(selectedEvent[0].created_at).format(
                        "YYYY/MM/DD HH:mm"
                      )
                      : ""}
                  </span>
                )}
              </div>
            }
            state={this.state}
            setState={(obj) => this.setState(obj)}
            onClose={() => this.resetAddReservationModal()}
            t={t}
            onSubmit={(isMatch) => {
              let reservationForm = { ...this.state.reservationForm };

              if (reservationForm.information.owner_id === null) {
                reservationForm.information.owner_id = this.props.user.id;
              }

              if (reservationForm.information.reservation_id)
                this.props.update({
                  ...reservationForm,
                  date: this.state.date,
                  isMatch,
                });
              else
                this.props.store({
                  ...reservationForm,
                  date: this.state.date,
                  isMatch,
                });
            }}
          />
        )}
        <PlanningStats
          updated={this.props.updated}
          isVisible={this.props.type === "caisse"}
          date={this.state.date}
        />
        {this.state.planningType.length > 1 && (
          <ul className="planning-list">
            {this.state.planningType.map((it, i) => {
              return (
                <li
                  key={"planning-type-" + i}
                  className={
                    "header-res tab-th " + (it.selected ? "active" : "")
                  }
                  onClick={() => {
                    let planningType = [...this.state.planningType];
                    for (let index = 0; index < planningType.length; index++) {
                      planningType[index].selected = index === i;
                      if (index === i) terrains = planningType[index].terrains;
                    }
                    this.setState({ planningType });
                  }}
                >
                  {t(it.name)}
                </li>
              );
            })}
          </ul>
        )}

        <div
          className="planning-container"
          style={{
            backgroundColor: this.props.caisse ? "white" : "transparent",
          }}
        >
          <WithLoader loading={this.props.loadingTerrain}>
            <PlanningSide
              leisure={false}
              toggle={!this.state.toggle}
              date={this.state.date}
              onChange={this.onChange}
              terrains={this.state.terrains}
              change={() => this.setState({ toggle: true })}
              onShowHandler={(i) => this.onShowHandler(i)}
              setDate={() => {
                this.goTo(new Date());
                this.setState({ date: new Date() });
              }}
            />
            <div
              className={
                !this.state.toggle
                  ? "planning-right planning-open"
                  : "planning-right"
              }
            >
              <Transition
                animation={"fade"}
                duration={500}
                visible={this.state.toggle}
              >
                <Button
                  className={"round-btn ajourdhui right-side-btn"}
                  onClick={() => {
                    this.goTo(new Date());
                    this.setState({
                      date: moment(new Date()).format("YYYY-MM-DD"),
                    });
                  }}
                >
                  {t("today")}
                </Button>
              </Transition>

              <div
                className={
                  !this.state.toggle ? "display-none" : "planning-right-toggle"
                }
              >
                <Icon
                  name={"angle right"}
                  color={"black"}
                  size={"small"}
                  onClick={() => this.setState({ toggle: false })}
                />
              </div>

              <PlanningHeader
                localization={localization}
                date={this.state.date}
                onChangeDay={(v) => this.onChangeDay(v)}
              />

              {this.renderPlanningButton()}

              <div id="calendar">
                {(this.props.loading ||
                  this.props.addLoading ||
                  this.props.deleteLoading ||
                  this.props.loadingPlanningType) && (
                    <div className={"calender-loading"}>
                      <Loader active />
                    </div>
                  )}
                <FullCalendar
                  ref={this.fullCalendarRef}
                  minTime={openComplex}
                  maxTime={closeComplex}
                  footer={false}
                  themeSystem="bootstrap"
                  locale={localStorage.getItem("lang")}
                  defaultView="resourceTimeGridDay"
                  slotDuration={configuration.slot_interval + ":00"}
                  allDaySlot={false}
                  height={"auto"}
                  timeZone={false}
                  defaultDate={this.state.date}
                  gotoDate={this.state.date}
                  header={{
                    right: "",
                    center: "",
                    left: "",
                  }}
                  slotLabelFormat={[
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    },
                  ]}
                  schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                  plugins={[plugin, interactionPlugin]}
                  resourceRender={function (renderInfo) {
                    let show = renderInfo.resource._resource.extendedProps.show;
                    if (show) {
                      renderInfo.el.classList.add("header-res");
                      renderInfo.el.innerText = renderInfo.resource.title;
                    }
                  }}
                  eventDragStart={() => {
                    if (this.tooltipRef.current)
                      this.tooltipRef.current.globalHide();
                  }}
                  eventDragStop={() => {
                    // if (this.tooltipRef.current)
                    //   this.tooltipRef.current.showTooltip();
                  }}
                  businessHours={[
                    {
                      daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // ALL week,
                      startTime: this.props.selectedComplex.complex_time
                        ? this.props.selectedComplex.complex_time.open
                        : "08:00",
                      endTime: this.props.selectedComplex.complex_time
                        ? this.props.selectedComplex.complex_time.close
                        : "24:00",
                    },
                  ]}
                  dayRender={function (e) {
                    let id = e.el.attributes.getNamedItem("data-resource-id")
                      .value;
                    let arr = terrains.filter(
                      (it) => it.id.toString() === id.toString()
                    );
                    if (arr.length > 0)
                      e.el.style = `background-color:${arr[0].BackgroundColor};border-color:#DADFEA;opacity:1`;
                    else {
                      e.el.style = `background-color:#15A4FA;border-color:#DADFEA;opacity:1`;
                    }
                  }}
                  dateClick={this.handleDayClick}
                  resources={filteredTerrains}
                  events={events}
                  eventOverlap={true}
                  nowIndicator={true}
                  editable={!this.props.addLoading && !this.props.loading}
                  eventDrop={async (info) => {
                    if (
                      this.props.addLoading ||
                      this.props.loading ||
                      !this.state.canMove
                    ) {
                      info.revert();
                      return;
                    }
                    try {
                      let hour = moment(info.event.start).utc().format("HH");
                      let eventDate = moment(info.event.start)
                        .utc()
                        .format("YYYY-MM-DD");
                      let minute = moment(info.event.start).utc().format("mm");
                      let endHour = moment(info.event.end)
                        .utc()
                        .format("HH:mm");
                      let startHour = moment(info.event.start)
                        .utc()
                        .format("HH:mm");
                      let openDate = openComplex.split(":");

                      let selectedEvent = this.state.events.filter(
                        (it) => it.id.toString() === info.event.id.toString()
                      );

                      let oldEvent = JSON.parse(
                        JSON.stringify(selectedEvent[0])
                      );

                      if (
                        oldEvent.ticket_id &&
                        oldEvent.ticket_status === 0 &&
                        oldEvent.start_hour !== startHour
                      ) {
                        this.setState({ canMove: true });
                        info.revert();
                        return;
                      }

                      oldEvent.start_hour =
                        info.oldEvent.extendedProps.startHour;
                      oldEvent.end_hour = info.oldEvent.extendedProps.endHour;
                      this.setState({ undo: [oldEvent], cut: oldEvent });

                      let hasActivity = true;
                      let selectedActivity = [];
                      let newTerrainId =
                        info.newResource !== null
                          ? info.newResource.id
                          : selectedEvent[0].terrain_id;
                      let selectedNewTerrain = [];
                      if (newTerrainId !== null) {
                        selectedNewTerrain = this.state.terrains.filter(
                          (it) => it.id.toString() === newTerrainId.toString()
                        );
                        selectedActivity = selectedNewTerrain[0].activities.filter(
                          (it) =>
                            it.id.toString() ===
                            selectedEvent[0].activity_id.toString()
                        );
                        hasActivity = selectedActivity.length > 0;
                      }

                      let plusDate = this.state.date;
                      let endTime =
                        selectedNewTerrain.length &&
                          selectedNewTerrain[0].terrain_time &&
                          selectedNewTerrain[0].terrain_time.close[
                          moment(this.state.date)
                            .locale("en")
                            .format("ddd")
                            .toLowerCase()
                          ]
                          ? selectedNewTerrain[0].terrain_time.close[
                          moment(this.state.date)
                            .locale("en")
                            .format("ddd")
                            .toLowerCase()
                          ]
                          : closeComplex;
                      let startTime =
                        selectedNewTerrain.length &&
                          selectedNewTerrain[0].terrain_time &&
                          selectedNewTerrain[0].terrain_time.open[
                          moment(this.state.date)
                            .locale("en")
                            .format("ddd")
                            .toLowerCase()
                          ]
                          ? selectedNewTerrain[0].terrain_time.open[
                          moment(this.state.date)
                            .locale("en")
                            .format("ddd")
                            .toLowerCase()
                          ]
                          : openComplex;
                      let time = endTime.split(":");

                      if (parseInt(time[0]) >= 24) {
                        let hour = parseInt(time[0]) % 24;
                        if (hour.toString().length === 1) {
                          hour = "0" + hour.toString();
                        }
                        plusDate = moment(plusDate)
                          .add(1, "days")
                          .format("YYYY-MM-DD");
                        endTime = hour + ":" + time[1];
                      }

                      if (
                        moment(
                          eventDate + "T" + hour + ":" + minute + ":00"
                        ).isBefore(
                          this.state.date + "T" + startTime + ":00",
                          "hour"
                        ) ||
                        moment(
                          eventDate + "T" + hour + ":" + minute + ":00"
                        ).isSameOrAfter(
                          plusDate + "T" + endTime + ":00",
                          "hour"
                        )
                      ) {
                        this.setState({ canMove: true });
                        info.revert();
                        return;
                      }

                      this.setState({ canMove: false });

                      if (hasActivity) {
                        let selected = selectedEvent[0];
                        let duration = selected.duration.duration;
                        selected.terrain_id = newTerrainId;
                        selected.date = moment(info.event.start)
                          .utc()
                          .format("YYYY-MM-DD");
                        selected.start_hour = hour + ":" + minute;

                        let minute_duration = duration.split(":");
                        let hours_end = parseInt(minute_duration[0]) * 60;
                        let minutes_end =
                          parseInt(minute_duration[1]) + hours_end;
                        selected.end_hour = await moment(
                          formattingDate(this.state.date) +
                          "T" +
                          hour +
                          ":" +
                          minute +
                          ":00+00:00"
                        )
                          .utc()
                          .add(minutes_end, "minute")
                          .format("HH:mm");

                        let reservationRes = await reservationResponse(
                          this.state.reservationForm,
                          selected
                        );

                        //(start_hour,end_hour,activity_id,duration_id, id)
                        let { hourTypeId, price } = await this.getHourTypeId(
                          reservationRes.information.start_hour,
                          reservationRes.information.end_hour,
                          reservationRes.information.activity_id,
                          reservationRes.information.duration_id,
                          reservationRes.information.hour_type_id
                        );
                        reservationRes.information.hour_type_id = hourTypeId;
                        reservationRes.information.price = price;

                        let price_per_participant = reservationRes.competition
                          .participants_nb
                          ? (
                            nullStringNumber(price) /
                            reservationRes.competition.participants_nb
                          ).toFixed(2)
                          : 0;
                        reservationRes.competition.price_per_participant = price_per_participant;
                        let isBefore = this.isBeforeClosing(reservationRes, {
                          closingTime: plusDate + "T" + endTime + ":00",
                        });
                        if (isBefore) {
                          info.oldEvent.remove();
                          this.props.update({
                            ...reservationRes,
                            date: this.state.date,
                          });
                        } else {
                          warningToast(t("extend-closing-time"));
                          info.revert();
                          let oldEvents = this.state.events.filter(
                            (it) =>
                              it.id.toString() !== info.event.id.toString()
                          );
                          this.setState({ events: [...oldEvents, oldEvent] });

                          this.setState({ canMove: true });
                        }
                      } else {
                        warningToast(t("invalid-area"));
                        this.setState({ canMove: true });
                        info.revert();
                      }
                    } catch (e) {
                      this.setState({ canMove: true });
                    }
                  }}
                  eventRender={this.renderEvent}
                  eventClick={this.onEventHandler}
                  eventPositioned={this.handleEventPositioned}
                />
              </div>
              {localStorage.getItem("lang") !== "ar" && (
                <div id={"sticky-header"} />
              )}
            </div>
            {this.renderDeleteModal()}
            {this.renderDeleteAllModal()}
            {this.renderCreditModal()}
            {this.renderNoCheckoutModal()}
          </WithLoader>
        </div>
        <ReactTooltip
          id="soclose"
          ref={this.tooltipRef}
          place={"left"}
          disabled={this.state.menuIsOpen || this.state.isDragging}
          offset={{ top: this.state.toggle ? -20 : -40 }}
          getContent={this.renderToolTips}
          type={"light"}
          clickable={false}
          effect="solid"
          delayHide={100}
          delayUpdate={100}
          delayShow={1000}
        />
        <Transition visible={this.state.menuIsOpen} duration={0}>
          <div className="menu-context" ref={this.contextMenu}>
            <ul className="menu-options">
              <li
                className="menu-option"
                onClick={() => this.toggleMenu("hide")}
              >
                <Icon name={"close"} />
                {t("close")}
              </li>
              {this.state.menuType === "event" ? (
                <>
                  <li
                    className="menu-option"
                    onClick={() => this.copyEvent("copy")}
                  >
                    <Icon name={"copy"} />
                    {t("copy")}
                    <span className={"menu-spinner"}>
                      {this.state.loadingCopy && (
                        <Loader active inline size={"mini"} />
                      )}
                    </span>
                  </li>
                  <li
                    className="menu-option"
                    onClick={() => this.copyEvent("cut")}
                  >
                    <Icon name={"cut"} />
                    {t("cut")}
                    <span className={"menu-spinner"}>
                      {this.state.loadingCut && (
                        <Loader active inline size={"mini"} />
                      )}
                    </span>
                  </li>

                  {
                    <li
                      onClick={debounce(async () => {
                        !this.props.EncaisseLoading &&
                          selectedEvent &&
                          selectedEvent.length > 0 &&
                          selectedEvent[0].ticket_id === null &&
                          // moment(this.state.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
                          this.EnCaisseEvent();
                      }, 1000)}
                      className={
                        selectedEvent &&
                          selectedEvent.length > 0 &&
                          selectedEvent[0].ticket_id === null
                          ? // && moment(this.state.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
                          "menu-option"
                          : "menu-option menu-disabled"
                      }
                    >
                      <Icon name={"money"} />
                      {t("Encaisser")}
                      <span className={"menu-spinner"}>
                        {this.props.EncaisseLoading && (
                          <Loader active inline size={"mini"} />
                        )}
                      </span>
                    </li>
                  }
                  <li
                    onClick={debounce(async () => {
                      // selectedEvent &&
                      // selectedEvent.length > 0 &&
                      // (!selectedEvent[0].price ||
                      //   !(selectedEvent[0].ticket && parseFloat(selectedEvent[0].ticket.payed_amount) > 0)) &&
                      this.removeEvent();
                    }, 1000)}
                    className={
                      // selectedEvent && selectedEvent.length > 0 && (!selectedEvent[0].price ||
                      //   !(selectedEvent[0].ticket && parseFloat(selectedEvent[0].ticket.payed_amount) > 0))
                      "menu-option"
                      // : 'menu-option menu-disabled'
                    }
                  >
                    <Icon name={"trash"} />
                    {t("delete")}
                    <span className={"menu-spinner"}>
                      {this.props.deleteLoading && (
                        <Loader active inline size={"mini"} />
                      )}
                    </span>
                  </li>
                </>
              ) : (
                <>
                  <li
                    onClick={this.undoEvent}
                    className={
                      this.state.undo
                        ? "menu-option"
                        : "menu-option menu-disabled"
                    }
                  >
                    <Icon name={"undo"} />
                    {t("undo")}

                    <span className={"menu-spinner"}>
                      {this.props.addLoading && (
                        <Loader active inline size={"mini"} />
                      )}
                    </span>
                  </li>
                  <li
                    onClick={debounce(async () => {
                      (this.state.cut !== null || this.state.copy !== null) &&
                        !this.props.addLoading &&
                        this.pasteEvent();
                    }, 1000)}
                    className={
                      this.state.cut || this.state.copy
                        ? "menu-option"
                        : "menu-option menu-disabled"
                    }
                  >
                    <Icon name={"paste"} />
                    {t("paste")}
                    <span className={"menu-spinner"}>
                      {this.props.addLoading && (
                        <Loader active inline size={"mini"} />
                      )}
                    </span>
                  </li>
                </>
              )}
            </ul>
          </div>
        </Transition>
        <PlanningConfigurationsModal
          open={this.state.modalConfiguration}
          onSuccess={(config) => {
            this.setState({
              toggle: !config.show_side_menu,
            });
          }}
          onClose={() =>
            this.setState({
              modalConfiguration: !this.state.modalConfiguration,
            })
          }
        />
      </MainLayout>
    );
  }
}

const mapDispatchToProps = {
  getPlanningTypes: (page, size) =>
    planningTypeCreators.indexRequestPlanningType({ page, size }),
  getTerrains: (page, size) =>
    terrainCreators.indexRequestTerrain({ page, size }),
  getReservation: (data) => reservationCreators.editRequestReservation(data),
  store: (data) => reservationCreators.storeRequestReservation(data),
  update: (data) => reservationCreators.updateRequestReservation(data),
  destory: (data, parent_id, all = false) =>
    reservationCreators.destroyRequestReservation({
      id: data,
      parent_id,
      all,
    }),
  index: (data) => reservationCreators.indexRequestReservation(data),
  getTeams: (page, size, search) =>
    teamsCreators.indexRequestTeam({ page, size, search }),
  reservationHistory: (page, size = 2, user_id, terrains, is_leisure = 0) =>
    reservationCreators.historyRequestReservation({
      page,
      size,
      user_id,
      terrains,
      is_leisure,
    }),
  deletedReservation: (page, size = 3, terrains = [], is_leisure = 0) =>
    reservationCreators.deletedRequestReservation({
      page,
      size,
      terrains,
      is_leisure,
    }),
  retrieveDeletedReservation: (id) =>
    reservationCreators.retrieveRequestReservation(id),
  resetHistory: () => reservationCreators.resetReservationHistory(),
  getReservationHour: (data) =>
    reservationCreators.getReservationHourType(data),
  getReservationInformation: (data) =>
    reservationCreators.InformationRequestReservation(data),
  clearReservationInformation: () =>
    reservationCreators.clearInformationResponseReservation(),
  getReservationArticle: ({ id, isLeisure, all, user_id }) =>
    reservationCreators.getReservationArticle({
      id,
      isLeisure,
      all,
      user_id,
    }),
  getReservationAmount: (data) =>
    priceCreators.reservationRequestPriceManagements(data),
  clearReservationAmount: () =>
    priceCreators.clearReservationPriceManagements(),
  restored_credit_list: (data) =>
    caisseCreators.restoredCreditListRequest(data),
  getTicket: (data) => caisseCreators.getTicketRequest(data),
  destoryReservation: (payload) =>
    caisseCreators.destroyRequestReservation(payload),
  updateTicket: (data) => caisseCreators.updateTicketRequest(data),
  storeTicket: (data) => caisseCreators.storeTicketRequest(data),
  destroyTicket: (id) => caisseCreators.destroyTicketRequest(id),
  setValueReservationTicket: (data) => caisseCreators.setValues(data),
  updateCurrentTicket: (data) =>
    caisseCreators.updateCurrentTicketRequest(data),
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    terrains: state.terrain.terrains,
    teams: state.team.teams,
    loadingTerrain: state.terrain.loading,
    loading: state.reservation.loading,
    addedReservation: state.reservation.addedReservation,
    editLoading: state.reservation.editLoading,
    updated: state.reservation.updated,
    addLoading: state.reservation.addLoading,
    EncaisseLoading: state.reservation.EncaisseLoading,
    deleteLoading: state.reservation.deleteLoading,
    recoverLoading: state.reservation.recoverLoading,
    reservations: state.reservation.reservations,
    reservation: state.reservation.reservation,
    lastRefresh: state.reservation.lastRefresh,
    hourTypes: state.reservation.hourTypes,
    hourLoading: state.reservation.loadingHour,
    /*********reservation history ********/
    dataReservHistory: state.reservation.reservationHistory,
    pageHistory: state.reservation.pageHistory,
    totalHistory: state.reservation.totalHistory,
    sizeHistory: state.reservation.sizeHistory,
    lastHistory: state.reservation.lastHistory,
    /*********deleted reservation ********/
    dataDeletedReservation: state.reservation.deletedReservations,
    pageDeleted: state.reservation.pageDeleted,
    totalDeleted: state.reservation.totalDeleted,
    lastDeleted: state.reservation.lastDeleted,
    successRetrieve: state.reservation.successRetrieve,
    /********* currentTicket********/
    currentTicket: state.caisse.currentTicket,
    restoredCreditList: state.caisse.restoredCreditList,
    credit: state.caisse.credit,

    selectedComplex: state.auth.selected,

    planningTypes: state.planningType.planningTypes,
    loadingPlanningType: state.planningType.loading,
    session: state.session.session,
    /********* planning configurations********/
    configuration: state.configurationPlanning.configuration,
  };
};
//Planning default props :
PlanningPage.defaultProps = {
  type: "",
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanningPage);
