import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { getFormData } from '../helpers/formDataHandler';

export default {
  parentService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/checkoutParents?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : ''),
      );

      return result.data;
    },

    store: async (data) => {
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('img', data.avatar);
      formData.append('is_activity', data.is_activity === true ? 1 : 0);
      formData.append('is_advance', data.is_advance === true ? 1 : 0);
      formData.append('is_credit', data.is_credit === true ? 1 : 0);
      formData.append('is_reload', data.is_reload === true ? 1 : 0);
      const result = await postMethod('/checkoutParents', formData);
      return result.data;
    },

    edit: async (id) => {
      const result = await getMethod('/checkoutParents/' + id);
      return result.data;
    },
    update: async (data) => {
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('img', data.avatar);
      formData.append('is_activity', data.is_activity === true ? 1 : 0);
      formData.append('is_advance', data.is_advance === true ? 1 : 0);
      formData.append('is_credit', data.is_credit === true ? 1 : 0);
      formData.append('is_reload', data.is_reload === true ? 1 : 0);
      formData.append('_method', 'put');

      const result = await postMethod('/checkoutParents/' + data.id, formData);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/checkoutParents/' + id);
      return result.data;
    },
  },
};
