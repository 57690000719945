import { getMethod, postMethod, putMethod } from '../helpers';


export const getSession = (id) => {
  return getMethod('/checkoutSessions/' + id);
};

export const checkSession = (data) => {
  return postMethod('/checkUserSession', data);
};

export const storeSession = (data) => {
  return postMethod('/checkoutSessions', data);
};

export const updateSession = (data) => {
  return putMethod('/checkoutSessions/' + data.id, data);
};



