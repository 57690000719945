import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  subscriptionTypeService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/subscriptionTypes?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        '&complex_id=' +
        localStorage.getItem('complex_id'),
      );
      return result.data;
    },
    store: async (data) => {
      let newData = {
        ...data,
        is_reload: data.is_reload === true ? 1 : 0,
        complex_id: localStorage.getItem('complex_id'),
      };
      const result = await postMethod('/subscriptionTypes', newData);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/subscriptionTypes/' + id);
      return result.data;
    },
    update: async (data) => {
      let newData = {
        ...data,
        is_reload: data.is_reload === true ? 1 : 0,
        complex_id: localStorage.getItem('complex_id'),
      };
      const result = await putMethod('/subscriptionTypes/' + data.id, newData);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/subscriptionTypes/' + id);
      return result.data;
    },
  },
};
