import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { getFormData, showFormData } from '../helpers/formDataHandler';

export default {
  supportTicketService: {
    index: async ({ page, size, search}) => {
      const result = await getMethod(
        '/supportTickets?page=' +page +
        (size ? '&size=' + size :'')+
        '&complex_id=' + (search && search.complex_id ? search.complex_id : localStorage.getItem('complex_id'))+
        (search && search.search_user ? '&search_user=' + search.search_user :''),
      );
      return result.data;
    },

    store: async (data) => {
      let fd = new FormData();
      fd = getFormData(data);
      const result = await postMethod('/supportTickets', fd);
      return result.data;
    },

    edit: async ({id, read_at = null }) => {
      const result = await getMethod('/supportTickets/' + id +(read_at ? '?read_at=' + read_at :''));
      return result.data;
    },

    update: async (data) => {
      let fd;
      fd = getFormData(data);
      fd.append('_method', 'put');
      showFormData(fd);
      const result = await postMethod('/supportTickets/' + data.id, fd);
      return result.data;
    },

    destroy: async (id) => {
      const result = await deleteMethod('/supportTickets/' + id);
      return result.data;
    },
  },
};
