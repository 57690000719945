import { deleteMethod, getMethod } from '@/helpers/index';
import { postMethod, putMethod } from '../helpers';

export default {
  configurationService: {
    index: async ({ page, size }) => {
      const result = await getMethod(
        '/checkoutConfigurations?page=' + page +
        (size ? '&size=' + size : '') +
        '&complex_id=' + localStorage.getItem('complex_id'),
      );
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/checkoutConfigurations/' + id);
      return result.data;
    },
    store: async (data) => {
      const result = await postMethod('/checkoutConfigurations/', data);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/checkoutConfigurations/' + data.id, data);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/checkoutConfigurations/' + id);
      return result.data;
    },
  },
};