import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';
import { exportFile } from '@/helpers/exportDownloader';


export default {
  durationService: {
    //index
    index: async ({ page, size }) => {
      const result = await getMethod(
        '/durations?complex_id=' + localStorage.getItem('complex_id') +
        (page ? '&page=' + page : '') +
        (size ? '&size=' + size : '')
      );
      return result.data;
    },

    //store
    store: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await postMethod('/durations', newData);
      return result.data;
    },

    //edit
    edit: async (id) => {
      const result = await getMethod('/durations/' + id);
      return result.data;
    },

    //update
    update: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await putMethod('/durations/' + data.id, newData);
      return result.data;
    },

    //destroy
    destroy: async (id) => {
      const result = await deleteMethod('/durations/' + id);
      return result.data;
    },

    //export
    exportExcel: async () => {
      const response = await getMethod(
        '/durationsExport?complex_id=' + localStorage.getItem('complex_id'),
        {responseType: 'blob'}
      );

      exportFile(response, 'Durées');
    }
  }
};
