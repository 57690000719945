import { Button, Loader } from 'semantic-ui-react';
import React from 'react';
import { capitalize, getLocalisation } from '../../helpers/formatting';
import moment from 'moment';

const PlanningHeader = (props) => {

  return (
    <div className={'planning-right-header'}>
      <Button circular icon={'angle left'} onClick={() => props.onChangeDay(-1)}/>
      <h2>
        {capitalize(
          moment(props.date)
            .locale(...getLocalisation())
            .format('dddd DD MMMM YYYY'),
        )}
      </h2>
      <Button circular icon={'angle right'} onClick={() => props.onChangeDay(1)}/>
    </div>
  );

};
export default PlanningHeader;