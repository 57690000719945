import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { getFormData, showFormData } from '../helpers/formDataHandler';

export default {
  moduleService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/modules?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : ''),
      );
      return result.data;
    },
    store: async (data) => {
      let fd = new FormData();
      fd = getFormData(data);
      const result = await postMethod('/modules', fd);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/modules/' + id);
      return result.data;
    },
    update: async (data) => {
      let fd;
      fd = getFormData(data);
      fd.append('_method', 'put');
      showFormData(fd);
      const result = await postMethod('/modules/' + data.id, fd);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/modules/' + id);
      return result.data;
    },
  },
};
