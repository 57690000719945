import moment from 'moment';
import caisseService from '../services/caisseService';
import { toFloat } from './formatting';
import { valeur } from '../pages/CaissePage/CaissePage';
import { add } from './operation';

/**
 * convert ticket
 * @return data
 * @param ticket
 * @param note_index
 */
export const convertTicket = (oldTicket, note_index = null) => {
  let ticket = { ...oldTicket };
  let articles = [];
  //articles
  if (ticket.articles) {
    ticket.articles.forEach((article, i) => {
      let defaultArticle = [];
      if (article.details) {
        if (article.pivot && article.pivot.article_detail_id)
          defaultArticle = article.details.filter(it => it.id === article.pivot.article_detail_id);
        else
          defaultArticle = article.details.filter(it => it.is_default === 1);
      }

      if (defaultArticle.length === 0 || (defaultArticle.length > 0 && defaultArticle[0].is_default)) {
        articles.push({
          ...article.pivot,
          parent: article.parent,
          child_id: article.child_id,
          child: article.child,
          id:
            (article.parent.is_activity === 1 && article.child.is_res === 1)
              ? article.id.toString()
              : article.id.toString() + valeur + (nbOccurrenceByIdInArticle(articles, article.id.toString())).toString(),
          name: article.name,
          info: article.info,
          hourType: article.hour_type,
          duration: article.duration,
          details: article.details,
          vat: article.vat,
          default_participants_nb: article.default_participants_nb,
          total_price: article.total_price,
          price_per_participant: article.price_per_participant,
          price_per_participant_ht: article.price_per_participant_ht,
          // amount: article.total_price,
          // amount_ht: article.amount_ht,
          // vat_amount: article.vat_amount,
          // vat_id: article.vat_id,
          article_detail_id: defaultArticle.length === 0 ? article.pivot ? article.pivot.article_detail_id : '' : defaultArticle[0].id,
        });
      } else
        articles.push({
          ...article.pivot,
          parent: article.parent,
          child_id: article.child_id,
          child: article.child,
          id:
            (article.parent.is_activity === 1 && article.child.is_res === 1)
              ? article.id.toString()
              : article.id.toString() + valeur + (nbOccurrenceByIdInArticle(articles, article.id.toString())).toString(),
          name: article.name,
          info: article.info,
          duration: article.duration,
          details: article.details,
          vat: article.vat,
          default_participants_nb: defaultArticle[0].participant_nb,
          total_price: defaultArticle[0].total_price,
          price_per_participant: defaultArticle[0].price_per_participant,
          price_per_participant_ht: defaultArticle[0].price_per_participant_ht,
          // amount: defaultArticle[0].total_price,
          // amount_ht: defaultArticle[0].amount_ht,
          // vat_amount: defaultArticle[0].vat_amount,
          // vat_id: article.vat_id,
          article_detail_id: defaultArticle[0].id,
        });
    });
  }

  //participants
  if (ticket.participants) {
    let participants = ticket.participants && Object.entries(ticket.participants).length
      ? ticket.participants.map((participant, i) => {
        return {
          user_id: participant.user_id,
          user_index: participant.user_index,
          article_id: participant.article_id.toString(),
          amount: participant.amount,
          amount_ht: participant.amount_ht,
          vat_amount: participant.vat_amount,
          vat_id: participant.vat_id,
          payed_amount: participant.payed_amount,
          subscriber_id: participant.subscriber_id,
          subscriber_detail_id: participant.subscriber_detail_id,
          status: participant.status ? participant.status : null,
          firstName: participant.user ? participant.user.firstName : 'Joueur',
          lastName: participant.user ? participant.user.lastName : '-',
          user: participant.user,
          note_index: participant.note_index,
        };
      })
      : [];
    ticket.participants = ticket.participants[0] && ticket.participants[0].user ? participants : ticket.participants;
  }

  //payment
  if (ticket.payments) {
    let paymentArticle = ticket.payments.articles && Object.entries(ticket.payments.articles).length
      ? ticket.payments.articles.map((art, i) => {
        let articleById = ticket.articles.filter(
          (it, j) =>
            it.id.toString().split(valeur)[0] === art.article_id.toString().split(valeur)[0],
        );
        return {
          ...art,
          article_id: articleById.length
            ? (articleById[0].parent.is_activity === 1 && articleById[0].child.is_res === 1)
              ? art.article_id.toString().split(valeur)[0]
              : getArticleIdByNote(articles, art.article_id.toString(), art.note_index)
            : getArticleIdByNote(articles, art.article_id.toString(), art.note_index),
        };
      })
      : [];
    ticket.payments.articles = paymentArticle;
    let types = ticket.payments.types && Object.entries(ticket.payments.types).length
      ? ticket.payments.types.map((type, i) => {
        return {
          payment_type_id: type.pivot ? type.pivot.payment_type_id : null,
          amount: type.pivot ? type.pivot.amount : null,
        };
      })
      : [];
    ticket.payments.types = ticket.payments.types[0] && ticket.payments.types[0].pivot ? types : ticket.payments.types;
  }

  if (ticket.payments && !ticket.payments.hasOwnProperty('participants')) {
    ticket.payments.participants = [];
  }

  //discount
  if (ticket.discountTypes) {
    let discountArticles = ticket.discountTypes.articles && Object.entries(ticket.discountTypes.articles).length
      ? ticket.discountTypes.articles.map((art, i) => {
        let articleById = ticket.articles.filter(
          (it, j) =>
            it.id.toString().split(valeur)[0] === art.article_id.toString().split(valeur)[0],
        );
        return {
          ...art,
          article_id: articleById.length
            ? (articleById[0].parent.is_activity === 1 && articleById[0].child.is_res === 1)
              ? art.article_id.toString().split(valeur)[0]
              : getArticleIdByNote(articles, art.article_id.toString(), art.note_index)
            : getArticleIdByNote(articles, art.article_id.toString(), art.note_index),
        };
      })
      : [];
    ticket.discountTypes.articles = discountArticles;
    let discountTypes = ticket.discountTypes.discounts && Object.entries(ticket.discountTypes.discounts).length
      ? ticket.discountTypes.discounts.map((discount, i) => {
        return {
          discount_id: discount.pivot ? discount.pivot.discount_id : null,
          amount: discount.pivot ? discount.pivot.amount : null,
          percentage: discount.pivot ? discount.pivot.percentage : null,
        };
      }) : [];
    ticket.discountTypes.discounts = ticket.discountTypes.discounts && ticket.discountTypes.discounts[0] && ticket.discountTypes.discounts[0].pivot
      ? discountTypes
      : ticket.discountTypes.discounts;
  } else {
    ticket.discountTypes = {
      discounts: [],
      articles: [],
      participants: [],
    };
  }
  ticket.discount = ticket.discount ? ticket.discount : 0;

  ticket.articles = ticket.articles && ticket.articles[0] && ticket.articles[0].pivot ? articles : ticket.articles;

  if (ticket.reservation)
    ticket.article_reservation = ticket.reservation.article_id;
  else
    ticket.article_reservation = null;

  //article to pay with note_index
  let stillNote = ticket.notes.filter(it => it.status === 0);
  if (stillNote.length === 0) {
    ticket.noteInProgress = false;
  } else {
    ticket.noteInProgress = true;
  }

  //in case of edit participant number & the activity is payed by advance => we have to save this advance
  ticket.pendingAdvance = ticket.pendingAdvance ? ticket.pendingAdvance : null;

  return ticket;
};

/**
 *
 * @param {*} articles
 * @param {*} id
 * @param {*} note_index
 * @returns
 */
const getArticleIdByNote = (articles, id, note_index) => {
  let filter = articles.filter(it => {
    return (it.id.toString().split(valeur)[0] === id.toString().split(valeur)[0] && it.note_index === note_index);
  });

  if (filter.length > 0) return filter[0].id;
  return '';
};

/**
 *
 * @param {*} menuData
 * @returns
 */
export const getParentIdIsActivity = (menuData) => {
  let parentIsActivity = menuData.filter(itm => itm.is_activity === 1);
  return parentIsActivity[0].id;
};

export const getParentIdIsAdvance = (menuData) => {
  let parentIsAdvance = menuData.filter(itm => itm.is_advance === 1);
  return parentIsAdvance[0].id;
};


/**
 * Discount functions
 */
/**
 *
 * @param {*} ticket
 * @param {*} id
 * @param {*} note_index
 * @returns
 */
export const getArticleDiscount = (ticket, id, note_index = null) => {
  let discount = 0;

  //get article_nb
  if (id && id !== 'undefined') {
    ticket.discountTypes.articles.forEach((item, index) => {
      if (item.article_id.toString() === id.toString() && item.note_index === note_index) {
        discount = parseFloat(discount) + parseFloat(item.amount);
      }
    });
  }

  return discount;
};

/**
 * Discount functions
 */
/**
 *
 * @param {*} ticket
 * @param {*} id
 * @param {*} note_index
 * @returns
 */
export const getArticleTotalDiscount = (ticket, id) => {
  let discount = 0;

  //get article_nb
  if (id && id !== 'undefined') {
    ticket.discountTypes.articles.forEach((item, index) => {
      if (item.article_id.toString() === id.toString()) {
        discount = parseFloat(discount) + parseFloat(item.amount);
      }
    });
  }

  return discount;
};


/**
 *
 * @param {*} ticket
 * @param {*} id
 */
export const getParticipantDiscount = (ticket, id) => {
  let discount = 0;
  ticket.discountTypes.participants.forEach((item, index) => {
    if (item.user_index.toString() === id.toString()) {
      discount = parseFloat(discount) + parseFloat(item.amount);
    }
  });
  return discount;
};


/**
 *
 * @param {*} currentTicket
 * @param {*} currentArticle
 * @param {*} articleToPay
 */
export const getAmountToPay = (currentTicket, articleToPay, note_index = null, articleToPartialPayment = []) => {
  let amountToPay = 0;
  let restAmountToPay = 0;
  let amountWithoutDiscount = 0;
  let totalsAmount = 0;
  if (currentTicket) {
    let seletedArticlesList = Object.entries(articleToPay);
    if (seletedArticlesList.length !== 0) {
      seletedArticlesList.map((article, i) => {
        currentTicket.articles.map((item, index) => {
          if (item.id.toString() === article[0].toString()) {
            //get participant amount
            if (item.parent.is_activity === 1 && item.child.is_res === 1 && article[1].length) {
              currentTicket.participants.map((participant, index) => {
                if (participant.article_id.toString() === article[0].toString() &&
                  participant.note_index === note_index) {
                  if (articleToPartialPayment.includes(participant.article_id.toString()) || (article[1].includes(participant.user_index) && participant.note_index === note_index)) {
                    totalsAmount += parseFloat(participant.amount);
                    amountToPay = (
                      parseFloat(amountToPay) +
                      parseFloat(participant.amount) -
                      parseFloat(getParticipantDiscount(currentTicket, participant.user_index))
                    ).toFixed(2);
                    restAmountToPay = (
                      parseFloat(restAmountToPay) +
                      parseFloat(participant.amount) -
                      parseFloat(participant.payed_amount) -
                      parseFloat(getParticipantDiscount(currentTicket, participant.user_index))
                    ).toFixed(2);
                    amountWithoutDiscount = (
                      parseFloat(amountWithoutDiscount) + parseFloat(participant.amount)
                    ).toFixed(2);
                  }
                }
              });
            } else if (item.note_index === note_index) {
              let payed_amount = parseFloat(item.payed_amount);
              let article_nb = parseInt(item.article_nb);
              let articleDiscount = getArticleDiscount(currentTicket, item.id, item.note_index);

              totalsAmount += parseFloat(item.total_price) * parseInt(article_nb);
              amountToPay = (
                parseFloat(amountToPay) +
                parseFloat(item.total_price) * parseInt(article_nb) -
                parseFloat(articleDiscount)
              ).toFixed(2);
              restAmountToPay = (
                parseFloat(restAmountToPay) +
                parseFloat(item.total_price) * parseInt(article_nb) -
                (parseFloat(payed_amount) ? parseFloat(payed_amount) : 0) -
                parseFloat(articleDiscount)
              ).toFixed(2);
              amountWithoutDiscount = (
                parseFloat(amountWithoutDiscount) +
                parseFloat(item.total_price) * parseInt(article_nb)
              ).toFixed(2);
            }
          }
        });
      });
    }
  }
  return [amountToPay, restAmountToPay, amountWithoutDiscount, totalsAmount];
};

export const getPaymentName = (paymentTypes, id) => {
  let name = '';
  paymentTypes.map((itm, i) => {
    if (itm.id === id) {
      name = itm.name;
    }
  });
  return name;
};

export const getPaymentSlug = (paymentTypes, id) => {
  let slug = '';
  paymentTypes.map((itm, i) => {
    if (itm.id === id) {
      slug = itm.slug;
    }
  });
  return slug;
};

export const calcArticleAmount = (article, payments, withPayed = false) => {
  let values = {
    amount: 0,
  };

  let isActivity = article.length === 2 && article[1].length > 0;

  if (!isActivity) {
    payments.articles.forEach((it, i) => {
      if (it.article_id.toString() === article[0].toString()) {
        values.amount += toFloat(it.amount);
      }
    });
  } else {
    payments.participants.forEach((it, i) => {
      let participants = article[1];
      let articleId = article[0];
      if (withPayed) {
        if (it.article_id.toString() === articleId.toString()) {
          values.amount += toFloat(it.amount);
        }
      } else if (participants.includes(it.user_index)) {
        values.amount += toFloat(it.amount);
      }
    });
  }

  return {
    amount: toFloat(values.amount).toFixed(2),
  };
};

let fillWithZero = (val, number = 4) => {
  try {
    let arr = val.toString().split('');
    let result = Array(number - arr.length).fill(0);
    return [...result, ...arr].join('');
  } catch (e) {
    return val;
  }
};

export const getNextTicketId = async () => {
  let year = moment().format('YYYY');
  let month = moment().format('MM');
  let day = moment().format('DD');
  let nb = await caisseService.caisseService.getLastTicketId().then((data) => {
    try {
      let number = data.data.data.number;
      var index = number.substr(number.length - 4); //last 4 caracters
      //let index = parseInt(number.slice(8, 13));
      if (number === '' || number === null) number = year + month + day + '0001';
      if (number.slice(0, 8) === year + month + day || number.slice(0, 10) === 'AT' + year + month + day) {
        ++index;
      } else {
        index = 1;
      }
      index = fillWithZero(index);
      let newNumber = 'AT' + year + month + day + index.toString();
      return newNumber;
    } catch (e) {
      return 'AT' + year + month + day + '0001';
    }
  });
  return nb;
};

/**
 *
 * @param {*} ticket
 * @param {*} id
 */
export const getStatusOfArticle = (ticket, id) => {
  ticket.articles.map((article, index) => {
    if (article.id === id) {
      return article.status;
    }
  });
};

/**
 *
 * @param {*} ticket
 * @param {*} id
 */
export const getStatusOfParticipant = (ticket, id) => {
  ticket.participants.map((participant, index) => {
    if (participant.user_index === id) {
      return participant.status;
    }
  });
};

/**
 *
 * @param {*} ticket
 * @param {*} article_id
 * @returns
 */
export const articleIsActivity = (ticket, article_id) => {
  let value = false;
  if (ticket && ticket.articles)
    ticket.articles.map((itm, index) => {
      if (itm.id.toString() === article_id.toString()) {
        if (itm.parent.is_activity === 1 && itm.child.is_res === 1) value = true;
      }
    });
  return value;
};

/**
 *
 * @param {*} ticket
 * @param {*} article_id
 */
export const articleIsAdvance = (ticket, article_id) => {
  let value = false;
  ticket.articles.map((itm, index) => {
    if (itm.id.toString() === article_id.toString()) {
      if (itm.parent.is_advance === 1) value = true;
    }
  });
  return value;
};

/**
 *
 * @param {*} ticket
 * @param {*} id
 */
export const haveAdvance = (ticket, id = null) => {
  let advance = null;
  if (ticket.payments.articles) {
    ticket.payments.articles.map((item, index) => {
      if (id) {
        if (item.advance_id && item.article_id === id) advance = { id: item.advance_id, amount: item.amount };
      } else {
        if (item.advance_id) advance = { id: item.advance_id, amount: item.amount };
      }

    });
  }
  if (ticket.payments.participants) {
    ticket.payments.participants.map((item, index) => {
      if (id) {
        if (item.advance_id && item.user_index === id) advance = { id: item.advance_id, amount: item.amount };
      } else {
        if (item.advance_id) advance = { id: item.advance_id, amount: item.amount };
      }
    });
  }

  return advance;
};


/**
 *
 * @param {*} ticket
 * @param {*} id
 */
export const ticketHasActivity = (ticket) => {
  let value = false;
  if (ticket.articles.length) {
    ticket.articles.map((item, index) => {
      if (item.parent.is_activity === 1) {
        value = true;
      }
    });
  }
  return value;
};

/**
 *
 * @param {*} paymentTypeListe
 * @param {*} id
 * @returns
 */
export const isSubscriptionPaymentType = (paymentTypeListe, id) => {
  let value = false;
  if (paymentTypeListe)
    paymentTypeListe.map((itm, index) => {
      if (itm.id == id && itm.is_subscription == 1) {
        value = true;
      }
    });
  return value;
};

export const onlyOneParticipantSelected = (ticket, articleToPay, participantToEditPayment) => {
  let value = false;
  let user = null;
  let seletedArticles = Object.entries(articleToPay);
  if (seletedArticles.length === 1) {
    let selectedParticipants = seletedArticles[0][1];
    if (selectedParticipants.length === 1) {
      value = true;
      let participant = ticket.participants.filter(itm => itm.user_index === selectedParticipants[0]);
      user = participant[0];
    }
  } else if (participantToEditPayment && seletedArticles.length === 0) {
    value = true;
    let participant = ticket.participants.filter(itm => itm.user_index === participantToEditPayment);
    user = participant[0];
  } else {
    value = false;
  }

  return { value, user };
};

/**
 * this function auto select an user when one participant is selected and many other article also selected
 * */
export const onlyOneParticipantWhenMultipleArticleSelected = (ticket, articleToPay, participantToEditPayment) => {
  let value = false;
  let user = null;
  let seletedArticles = Object.entries(articleToPay);
  if (seletedArticles.length > 0) {
    let nbActivity = seletedArticles.filter(it => it[1].length === 1);
    if (nbActivity.length !== 1) return { value, user };
    let selectedParticipants = nbActivity[0][1];
    if (selectedParticipants.length === 1) {
      value = true;
      let participant = ticket.participants.filter(itm => itm.user_index === selectedParticipants[0]);
      user = participant[0];
    }
  } else if (participantToEditPayment && seletedArticles.length === 0) {
    value = true;
    let participant = ticket.participants.filter(itm => itm.user_index === participantToEditPayment);
    user = participant[0];
  } else {
    value = false;
  }

  return { value, user };
};


export const getParticipantFromArticleToPay = (ticket, articleToPay, participantToEditPayment) => {
  let value = false;
  let user = null;
  let selectedArticles = articleToPay && Object.values(articleToPay) ? Object.values(articleToPay).filter(it => it.length > 0) : [];
  if (selectedArticles.length === 1) {
    let selectedParticipants = selectedArticles[0];
    if (selectedParticipants.length === 1) {
      value = true;
      let participant = ticket.participants.filter(itm => itm.user_index === selectedParticipants[0]);
      user = participant[0];
    }
  } else if (participantToEditPayment && selectedArticles.length === 0) {
    value = true;
    let participant = ticket.participants.filter(itm => itm.user_index === participantToEditPayment);
    user = participant[0];
  } else {
    value = false;
  }

  return { value, user };
};


export const haveNotActivitySelected = (ticket, articleToPay, articleToEditPayment, articleToPartialPayment) => {
  let value = false;
  if (ticket) {
    ticket.articles.map((item, index) => {
      let seletedArticlesList = Object.entries(articleToPay);
      if (seletedArticlesList.length !== 0) {
        seletedArticlesList.map((article, i) => {
          if (item.id.toString() === article[0].toString() ||
            (articleToPartialPayment && item.id.toString() === articleToPartialPayment.toString())) {
            if (item.parent.is_activity === 0 || (item.parent.is_activity === 1 && item.child.is_res === 0)) {
              value = true;
            }
          }
        });
      }
      if (articleToEditPayment && item.id.toString() === articleToEditPayment.toString()) {
        if (item.parent.is_activity === 0 || (item.parent.is_activity === 1 && item.child.is_res === 0)) {
          value = true;
        }
      }
    });
  }
  return value;
};

export const filterParticipantsByArticle = (articleId, participantToPartialPayment) => {
  return participantToPartialPayment.filter((it) => !it.toString().includes(articleId + '-'));
};


export const getIndexOf = (items, callback) => {
  let index = -1;
  if (items.length > 0)
    for (let i = 0; i < items.length; i++) {
      if (callback(items[i])) {
        index = i;
        break;
      }
    }
  return index;
};

export const cumulPayedAmountFromPaymentTypes = (ticket) => {
  let sum = 0;
  ticket.payments.types.map((item, index) => {
    sum = (parseFloat(sum) + parseFloat(item.amount)).toFixed(2);
  });
  return sum;
};

export const cumulPaymentTypesFromArticle = (ticket) => {
  let result = [];
  if (ticket.payments.articles.length) {
    ticket.payments.articles.map((article_payment, i) => {
      let index = getIndexOf(
        result,
        (it) => it.payment_type_id.toString() === article_payment.payment_type_id.toString(),
      );
      if (index === -1) {
        result.push({
          payment_type_id: article_payment.payment_type_id,
          amount: parseFloat(article_payment.amount),
        });
      } else {
        result[index].amount = (
          parseFloat(result[index].amount) + parseFloat(article_payment.amount)
        ).toFixed(2);
      }
    });
  }
  return result;
};

export const cumulPaymentArticlesFromParticipant = (ticket, id) => {
  let result = [];
  if (!id) return result;
  if (ticket.payments.participants.length) {
    ticket.payments.participants.map((participant_payment, i) => {
      if (participant_payment.article_id.toString() === id.toString()) {
        let index = getIndexOf(
          result,
          (it) => (it.payment_type_id.toString() === participant_payment.payment_type_id.toString()
            && it.credit_id === participant_payment.credit_id
            && it.subscriber_id === participant_payment.subscriber_id
            && it.note_index === participant_payment.note_index
            // && it.subscriber_detail_id === participant_payment.subscriber_detail_id
            // && it.credit_account_id === participant_payment.credit_account_id
            && (it.note_index === participant_payment.note_index)
            && (it.credit_user_id === participant_payment.credit_user_id)),
          // && (it.credit_user_id === participant_payment.credit_user_id ||
          // it.advance_id === participant_payment.advance_id)),

        );
        if (index === -1) {
          result.push({
            article_id: id,
            payment_type_id: participant_payment.payment_type_id,
            amount: parseFloat(participant_payment.amount),
            vat_id: participant_payment.vat_id,
            credit_id: participant_payment.credit_id,
            subscriber_id: participant_payment.subscriber_id,
            subscriber_detail_id: null,
            credit_account_id: null,
            credit_user_id: participant_payment.credit_user_id,
            advance_id: participant_payment.advance_id,
            note_index: participant_payment.note_index,

          });
        } else {
          result[index].amount = (
            parseFloat(result[index].amount) + parseFloat(participant_payment.amount)
          ).toFixed(2);
        }
      }
    });
  }
  return result;
};

export const cumulSamePaymentParticipant = (data) => {
  let result = [];
  if (data.length) {
    data.map((participant_payment, i) => {
      let index = getIndexOf(
        result,
        (it) => (
          it.user_index === participant_payment.user_index && 
          it.payment_type_id.toString() === participant_payment.payment_type_id.toString() && 
          it.credit_id === participant_payment.credit_id && 
          it.subscriber_id === participant_payment.subscriber_id && 
          it.credit_user_id === participant_payment.credit_user_id &&
          it.note_index === participant_payment.note_index && 
          it.advance_id === participant_payment.advance_id
      ));
      if (index === -1) {
        result.push({...participant_payment});
      } else {
        result[index].amount = (
          parseFloat(result[index].amount) + parseFloat(participant_payment.amount)
        ).toFixed(2);
      }
    });
  }
  return result;
};

export const cumulPaymentTypeListPartial = (paymentTypeList) => {
  let result = [];
  paymentTypeList.map((payment_type, i) => {
    let index = getIndexOf(
      result,
      (it) => (it.id.toString() === payment_type.id.toString()
        && it.credit_id === payment_type.credit_id
        && it.subscriber_id === payment_type.subscriber_id
        && it.credit_user_id === payment_type.credit_user_id
        && it.note_index === payment_type.note_index
      ),
    );
    if (index === -1) {
      result.push({ ...payment_type });
    } else {
      result[index].amount = (
        (result[index].amount !== '' ? parseFloat(result[index].amount) : 0) +
        (payment_type.amount !== '' ? parseFloat(payment_type.amount) : 0)
      ).toFixed(2);

      //select 2 partial object (partial participant + partial participant / partial participant + partial article) 
      if(result[index].payed_user_index.length === 0 && payment_type.payed_user_index.length > 0){
        result[index].payed_user_index = payment_type.payed_user_index;
      }
      
      if (!parseFloat(result[index].amount)) result[index].amount = '';
    }

  });
  return result;
};

export const cumulArticlePayedAmountFromPaymentArticle = (ticket, article_id) => {
  let result = 0;
  if (ticket.payments.articles && ticket.payments.articles.length) {
    ticket.payments.articles.map((article_payment, i) => {
      if (article_payment && article_id && article_payment.article_id.toString() === article_id.toString()) {
        result += parseFloat(article_payment.amount);
      }
    });
  }

  return result;
};


export const cumulParticipantPayedAmountFromPaymentParticipant = (ticket, participant_id) => {
  let result = 0;
  if (ticket.payments.participants && ticket.payments.participants.length) {
    ticket.payments.participants.map((participant_payment, i) => {
      if (participant_payment.user_index.toString() === participant_id.toString()) {
        result += parseFloat(participant_payment.amount);
      }
    });
  }


  return result;
};


export const cumulDiscountArticles = (ticket) => {
  let result = [];
  ticket.discountTypes.articles.map((art, i) => {
    let index = getIndexOf(
      result,
      (it) => (it.article_id.toString() === art.article_id.toString()
        && it.discount_id === art.discount_id
      ),
    );
    if (index === -1) {
      result.push({ ...art });
    } else {
      result[index].amount = (
        (result[index].amount !== '' ? parseFloat(result[index].amount) : 0) +
        (art.amount !== '' ? parseFloat(art.amount) : 0)
      ).toFixed(2);
      if (!parseFloat(result[index].amount)) result[index].amount = '';
    }

  });
  return result;
};

/**
 * get total amount from articles
 * @param {*} ticket
 * @returns
 */
export const cumulAmountFromArticlePrice = (ticket) => {
  let amount = 0;
  if (ticket) {
    ticket.articles.forEach(item => {
      amount = amount + (parseFloat(item.total_price) * parseFloat(item.article_nb));
    });
  }
  return parseFloat(amount).toFixed(2);
};

/**
 * get total amountHT from articles
 * @param {*} ticket
 * @returns
 */
export const cumulAmountHTFromArticles = (ticket) => {
  let amount_ht = 0;
  if (ticket) {
    ticket.articles.forEach((item, index) => {
      amount_ht = parseFloat(amount_ht) + parseFloat(item.amount_ht);
    });
  }
  return amount_ht.toFixed(3);
};

/**
 * get total vatAmount from articles
 * @param {*} ticket
 * @returns
 */
export const cumulVatAmountFromArticles = (ticket) => {
  let vat_amount = 0;
  if (ticket) {
    ticket.articles.forEach((item, index) => {
      vat_amount = parseFloat(vat_amount) + parseFloat(item.vat_amount);
    });
  }
  return vat_amount.toFixed(3);
};

export const getCreditNumber = (ticket, credit_id) => {
  let creditNumber = null;
  if (ticket && ticket.usedCredits) {
    creditNumber = ticket.usedCredits.filter(itm => itm.id.toString() === credit_id.toString())
                            .map(itm => {return itm.credit_number});
    creditNumber = creditNumber[0];
  }
  return creditNumber;

};

export const cumulTotalDiscountFromDiscounts = (ticket) => {
  let sum = 0;
  ticket.discountTypes.discounts.map((item, index) => {
    sum = (parseFloat(sum) + parseFloat(item.amount)).toFixed(2);
  });
  return sum;
};

export const cumulDiscountsFromArticle = (ticket) => {
  let result = [];
  ticket.discountTypes.articles.map((article_discount, i) => {
    let index = getIndexOf(result,
      (it) => it.discount_id.toString() === article_discount.discount_id.toString(),
    );
    if (index === -1) {
      result.push({
        discount_id: article_discount.discount_id,
        amount: parseFloat(article_discount.amount).toFixed(2),
        percentage: article_discount.percentage,
      });
    } else {
      result[index].amount = (parseFloat(result[index].amount) + parseFloat(article_discount.amount)).toFixed(2);
    }
  });
  return result;
};

export const cumulDiscountArticlesFromParticipant = (ticket, id) => {
  let result = [];
  ticket.discountTypes.participants.map((participant_discount, i) => {
    if (participant_discount.article_id.toString() === id.toString()) {
      let index = getIndexOf(result, (it) => (
        it.discount_id.toString() === participant_discount.discount_id.toString()
        && it.note_index === participant_discount.note_index
      ));
      if (index === -1) {
        result.push({
          article_id: id,
          amount: parseFloat(participant_discount.amount).toFixed(2),
          percentage: participant_discount.percentage,
          discount_id: participant_discount.discount_id,
          note_index: participant_discount.note_index,
        });
      } else {
        result[index].amount = (parseFloat(result[index].amount) + parseFloat(participant_discount.amount)).toFixed(2);
        result[index].percentage = participant_discount.percentage;
      }
    }
  });
  return result;
};

export const setArticleStatus = (ticket, article) => {
  let status = null;
  let discount = parseFloat(getArticleDiscount(ticket, article.id ? article.id : article.article_id, article.note_index));
  if (parseFloat(article.payed_amount) === 0 && discount < (parseFloat(article.total_price) * parseInt(article.article_nb))) {
    status = null;
  } else if (add(parseFloat(article.payed_amount),
    discount) <
    parseFloat(article.total_price) * parseInt(article.article_nb)
  ) {
    status = 'partial';
  } else {
    status = 'payed';
  }
  return status;
};

export const setArticleWithoutNoteStatus = (ticket, article) => {
  let status = null;
  let discount = parseFloat(getArticleTotalDiscount(ticket, article.id ? article.id : article.article_id));
  if (parseFloat(article.payed_amount) === 0 && discount < (parseFloat(article.total_price) * parseInt(article.article_nb))) {
    status = null;
  } else if (add(parseFloat(article.payed_amount),
    discount) <
    parseFloat(article.total_price) * parseInt(article.article_nb)
  ) {
    status = 'partial';
  } else {
    status = 'payed';
  }
  return status;
};

export const setParticipantStatus = (ticket, participant) => {
  let status = null;
  if (parseFloat(participant.payed_amount) === 0) {
    status = null;
  } else if (
    add(parseFloat(participant.payed_amount),
      parseFloat(getParticipantDiscount(ticket, participant.user_index))) <
    participant.amount
  ) {
    status = 'partial';
  } else {
    status = 'payed';
  }
  return status;
};

export const getTotalArticleNb = (ticket) => {
  let nb = 0;
  if (ticket.articles && ticket.articles.length) {
    ticket.articles.map(itm => {
      nb = parseInt(nb) + parseInt(itm.article_nb);
    });
  }
  return nb;
};

export const nbOccurrenceByIdInArticle = (articles, id) => {
  let nb = articles.reduce((acc, current) => {
    if (current.id.toString().split(valeur)[0] === id.toString().split(valeur)[0])
      return (acc + 1);
    else
      return acc;
  }, 0);

  return nb;
};

export const getLastOccurrenceByIdInArticle = (articles, id) => {
  let max = articles.reduce((acc = [], current) => {
    if (current.id.toString().split(valeur)[0] === id.toString().split(valeur)[0]) {
      let value = parseInt(current.id.toString().split(valeur)[1]);
      if (value >= acc) return value;
      else return acc;
    } else
      return acc;
  }, 0);

  return max;
};

export const getQte = (ticket, article) => {
  let discounts = ticket.discountTypes.articles.filter(it => it.article_id === article.id);
  let discountValue = discounts.reduce((acc, current) => acc + parseFloat(current.amount), 0);
  let articleRestAmount = (parseFloat(article.total_price) * parseFloat(article.article_nb)) - parseFloat(article.payed_amount) - discountValue;
  let availableArticleNb = parseFloat(article.total_price) === 0 ?
    parseFloat(article.article_nb)
    :
    parseInt((parseFloat(articleRestAmount) / (parseFloat(article.total_price) - (discountValue / parseFloat(article.article_nb)))));

  return { articleRestAmount, availableArticleNb };
};

export const isAdvance = (ticket) => {
  let value = false;
  if (ticket.articles[0] && ticket.articles[0].parent.is_advance === 1) {
    value = true;
  }
  return value;
};


export const cumulPayedUserIndex = (data) => {
  let result = [];
  if (data.length) {
    data.map((item, i) => {
      let index = getIndexOf(
        result,
        (it) => Object.keys(it)[0] === Object.keys(item)[0],
      );
      if (index === -1) {
        result.push(item);
      } else {
        result[index][Object.keys(result[index])] = parseFloat(result[index][Object.keys(result[index])]) +
          parseFloat(Object.values(item)[0]);
      }
    });
  }
  return result;
};

export const cumulSameUsedCreditIndex = (data) => {
  let result = [];
  if (data.length) {
    data.map((item, i) => {
      let index = getIndexOf(
        result,
        (it) => it.id === item.id,
      );
      if (index === -1) {
        result.push({...item});
      } else {
        result[index].amount = (
          parseFloat(result[index].amount) + parseFloat(item.amount)
        ).toFixed(2);
      }
    });
  }
  return result;
};

export const totallyPayedByAvoirOrAdvance = (ticket,checkedArticleIdList = null) => {
  let value = false;
  let total = 0;
  let articlesAmount = parseFloat(ticket.payed_amount);
  let articlePayments = ticket.payments.articles;

  if(checkedArticleIdList && checkedArticleIdList.length > 0){
    articlePayments = articlePayments.filter(itm=> checkedArticleIdList.includes(itm.article_id));

    articlesAmount = ticket.articles.filter(itm=> checkedArticleIdList.includes(itm.id))
                            .reduce((acc, value) => {return (acc + parseFloat(value.payed_amount))}, 0);
  }
  if(articlePayments){
    articlePayments.forEach(itm=>{
      if(itm.credit_id || (itm.subscriber && itm.subscriber.subscription && 
        itm.subscriber.subscription.subscription_type && 
        itm.subscriber.subscription.subscription_type.is_reload === 0)
      ){
          total += parseFloat(itm.amount);
      }
    })
  }
  
  if(parseFloat(total) === parseFloat(articlesAmount)){
    return value = true;
  }
  
  
  return value;
};

/***
 * sum of old payed amount of payment type => from payed_user_index
 */
// export const totalAmountOfPayedUserIndex = (paymentTypeList) => {
//   let result = 0;
//   if(paymentTypeList && paymentTypeList.payed_user_index){
//     result =  paymentTypeList.payed_user_index.reduce((acc, it) => {return (acc + parseFloat(Object.values(it)[0]))}, 0);
//   }
//   return parseFloat(result);
// }
