import React from "react";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Label,
  Loader,
  Modal,
  Popup,
  Table,
  Transition,
} from "semantic-ui-react";
import moment from "moment";
import "./ReservationModal.css";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import Image from "../ImageComponent/Image";
import i18n from "../../i18n";
import { debounce, imgUrl, url } from "../../helpers";
import CustomInput from "../CustomInput/CustomInput";
import {
  capitalize,
  formattingDate,
  formattingExistUser,
  isEmpty,
  nullString,
  nullStringForTable,
  nullStringNumber,
  phoneNumber,
  reversePhoneNumber,
  toDate,
  toMinutes,
} from "../../helpers/formatting";
import {
  checkForDuplicatedParticipant,
  conclusionTranslate,
  checkParticipants,
  game,
  getAdvanceTicket,
  player,
} from "../../helpers/reservationFormatting";
import TimeInput from "../TimeInput/TimeInput";
import ReccurrenceComponent from "../ReccurrenceComponent/ReccurrenceComponent";
import CustomTab from "../CustomTab/CustomTab";
import ConclusionBtn from "../ConclusionBtn";
import localization, * as fr from "moment/locale/fr";
import * as en from "moment/locale/en-ca";
import CustomTable from "../CustomTable/CustomTable";
import { Creators as reservationCreators } from "../../store/ducks/reservation";
import { Creators as priceCreators } from "../../store/ducks/priceManagements";
import { connect } from "react-redux";
import {
  checkGamesRepetition,
  generateNewGames,
  getIndexResource,
  resize,
} from "../../helpers/leisureFormatting";
import { checkIsAvailable } from "../../services/userService";
import { humans, levels, privates } from "../../utils/player";
import { getLang } from "../../helpers/constant";

class ReservationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCheck: false,
    };
    this.refBtn = React.createRef();
  }

  getParticipants = () => {
    let eq1 = this.props.state.reservationForm.participants.equipe1.players
      .filter((it) => !it.contact_id)
      .map((it) => it.user_id);
    let eq2 = this.props.state.reservationForm.participants.equipe2.players
      .filter((it) => !it.contact_id)
      .map((it) => it.user_id);

    return [...eq1, ...eq2];
  };

  componentDidMount = async () => {
    if (this.props.type === "default" || this.props.type === "leisure") {
      if (
        this.props.teams.length >= 2 &&
        !this.props.state.reservationForm.information.reservation_id
      ) {
        if (this.props.type === "default") {
          await this.onChangeTeam({}, { value: this.props.teams[1].id }, 2);
        }
        await this.onChangeTeam({}, { value: this.props.teams[0].id }, 1);
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    //change the size of the participants according to the participants_nb
    if (
      this.props.type === "leisure" &&
      this.props.state.reservationForm.competition.participants_nb !==
        prevProps.state.reservationForm.competition.participants_nb
    ) {
      this.onChangeParticipants(
        this.props.state.reservationForm.competition.participants_nb
      );
    }

    //change the size of the games
    if (
      this.props.type === "leisure" &&
      this.props.state.reservationForm.information.games_nb !==
        prevProps.state.reservationForm.information.games_nb
    ) {
      this.onChangeGames(this.props.state.reservationForm.information.games_nb);
    }

    //load available hour for reservationModal in the caisse page
    if (
      this.props.type === "caisse" &&
      this.props.state.reservationForm.information.date &&
      this.props.state.reservationForm.information.terrain_id &&
      this.props.state.reservationForm.information.terrain_id !==
        prevProps.state.reservationForm.information.terrain_id &&
      this.props.state.reservationForm.information.hour_type_id
    ) {
      let data = {
        complex_id: localStorage.getItem("complex_id"),
        hour_type_id: this.props.state.reservationForm.information.hour_type_id,
        terrain_id: this.props.state.reservationForm.information.terrain_id,
        date: this.props.state.reservationForm.information.date,
      };
      this.props.getReservationAvailableHours(data);
    }
    // Load default teams;
    if (
      !this.props.type === "caisse" &&
      this.props.state.reservationForm.information.activity_id !==
        prevProps.state.reservationForm.information.activity_id
    ) {
      if (this.props.teams.length >= 2) {
        await this.onChangeTeam({}, { value: this.props.teams[0].id }, 1);
        await this.onChangeTeam({}, { value: this.props.teams[1].id }, 2);
      }
      //await this.autoFill();
    }

    // load reservation information after setting the user for the update and create for any selected user

    if (
      this.props.state.reservationForm.information.user.user_id !== null &&
      this.props.state.reservationForm.information.user.user_id !==
        prevProps.state.reservationForm.information.user.user_id
    ) {
      this.props.getReservationInformation({
        user: this.props.state.reservationForm.information.user.user_id,
        complex_id: localStorage.getItem("complex_id"),
      });
    }

    // if (
    //   (this.props.state.modalAdd !== prevProps.state.modalAdd && !this.props.state.modalAdd) ||
    //   (this.props.state.reservationForm.information.user.user_id === null &&
    //     this.props.state.reservationForm.information.user.user_id !==
    //     prevState.state.reservationForm.information.user.user_id)
    // ) {
    //   this.props.clearReservationInformation();
    // }

    if (this.props.errors && this.props.errors !== prevProps.errors) {
      let index = 0;
      if (this.props.errors[0].hasOwnProperty("main-user")) {
        index = 0;
      } else index = 1;
      this.refBtn.current.disabled = false;
      this.setState({ loadingCheck: false });
      this.props.setState({ loadingCheck: false, selectedIndex: index });
    }

    //detect change players array

    if (
      this.props.state.reservationForm.participants !==
      prevProps.state.reservationForm.participants
    ) {
      let nbOfEmpty =
        this.props.state.reservationForm.participants.equipe1.players.length -
        this.getParticipantEmptyLength(
          this.props.state.reservationForm.participants.equipe1.players
        );
      let nbOfEmpty2 =
        this.props.state.reservationForm.participants.equipe2.players.length -
        this.getParticipantEmptyLength(
          this.props.state.reservationForm.participants.equipe2.players
        );

      let nbTotal = nbOfEmpty + nbOfEmpty2;
      let competition = { ...this.props.state.reservationForm.competition };
      if (
        competition.participants_nb - competition.missing_participants_nb <
        nbTotal
      ) {
        let reservationForm = this.props.state.reservationForm;
        competition.missing_participants_nb =
          competition.participants_nb - nbTotal;
        reservationForm.competition = competition;
        this.props.setState({ reservationForm });
      }
    }
  };

  componentWillUnmount() {
    this.props.clearError();
  }

  hasTicket = () => {
    const state = this.props.state;
    return state.reservationForm.information.ticket !== null;
  };

  getParticipantEmptyLength = (players) => {
    let nbOfEmpty = 0;
    for (let i = 0; i < players.length; i++) {
      let eq = players[i];
      if (!this.isPlayerEmpty(eq)) {
        if (!this.checkPlayer(eq)) return false;
      } else nbOfEmpty++;
    }

    return nbOfEmpty;
  };

  /**
   * check the participant tab for validation
   * @param isMatch
   * @returns {boolean}
   */
  checkParticipant = (isMatch) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let { participants } = reservationForm;
    let isValid = true;
    let nbOfEmpty = 0;
    let nbOfEmpty2 = 0;
    let index = 0;

    // check for duplicated participants
    let allParticipants = participants.equipe1.players;
    if (isMatch)
      allParticipants = participants.equipe1.players.concat(
        participants.equipe2.players
      );

    let checkedForDuplication = checkForDuplicatedParticipant(allParticipants);
    if (checkedForDuplication) return { isValid: false, index };

    if (isMatch) {
      if (!participants.equipe1.id || !participants.equipe2.id) {
        index = participants.equipe1.id ? 1 : 0;
        return { isValid: false, index };
      }
      // check if at least one player for each team
      nbOfEmpty = this.getParticipantEmptyLength(participants.equipe1.players);
      nbOfEmpty2 = this.getParticipantEmptyLength(participants.equipe2.players);
      if (
        nbOfEmpty === participants.equipe1.players.length &&
        nbOfEmpty2 === participants.equipe2.players.length
      ) {
        return { isValid: false, index };
      }
      // for(let i=0;i<participants.equipe2.players.length;i++){
      //     let eq =participants.equipe2.players[i];
      //     if(!this.isPlayerEmpty(eq)) {
      //         if(!this.checkPlayer(eq))
      //             return false;
      //     }else
      //     nbOfEmpty++;
      // }
      //
      // if(nbOfEmpty===participants.equipe2.players.length) return false;
      //
    } else {
      nbOfEmpty = 0;
      for (let i = 0; i < participants.equipe1.players.length; i++) {
        let eq = participants.equipe1.players[i];

        if (!this.isPlayerEmpty(eq)) {
          if (!this.checkPlayer(eq)) return { isValid: false, index };
        } else nbOfEmpty++;
      }
      if (nbOfEmpty === participants.equipe1.players.length)
        return { isValid: false, index };
    }
    return { isValid, index };
  };

  /**
   * check the required participant if filled correctly
   * @param player
   * @returns {boolean|boolean}
   */
  isPlayerEmpty = (player) => {
    if (!player) return true;
    return (
      player.firstName === "" &&
      player.lastName === "" &&
      player.mobile === "" &&
      player.email === ""
    );
  };

  checkPlayer = (player) => {
    return (
      (player.firstName || player.lastName) && (player.email || player.mobile)
    );
  };

  getClosingAndOpeningOfField = (it, date) => {
    let startTime =
      it &&
      it.terrain_time &&
      it.terrain_time.open &&
      it.terrain_time.open[
        moment(date).locale("en").format("ddd").toLowerCase()
      ]
        ? it.terrain_time.open[
            moment(date).locale("en").format("ddd").toLowerCase()
          ]
        : this.props.selectedComplex.complex_time
        ? this.props.selectedComplex.complex_time.open
        : "08:00";
    let endTime =
      it &&
      it.terrain_time &&
      it.terrain_time.close &&
      it.terrain_time.close[
        moment(date).locale("en").format("ddd").toLowerCase()
      ]
        ? it.terrain_time.close[
            moment(date).locale("en").format("ddd").toLowerCase()
          ]
        : this.props.selectedComplex.complex_time
        ? this.props.selectedComplex.complex_time.close
        : "24:00";
    return { startTime, endTime };
  };

  closingDateAndTime = (date, terrainTimes) => {
    let plusDate = date;
    let hours = terrainTimes.endTime.split(":")[0];
    if (parseInt(hours) >= 24) {
      hours = hours % 24;
      if (hours.toString().length === 1) {
        terrainTimes.endTime =
          "0" + hours.toString() + ":" + terrainTimes.endTime.split(":")[1];
      }
      // not sure why to start day to month
      plusDate = moment(plusDate).add(1, "days").format("YYYY-MM-DD");
    }

    return plusDate + "T" + terrainTimes.endTime + ":00";
  };

  openingDateAndTime = (date, terrainTimes) => {
    let plusDate = date;
    let hours = terrainTimes.startTime.split(":")[0];
    if (parseInt(hours) >= 24) {
      hours = hours % 24;
      if (hours.toString().length === 1) {
        terrainTimes.startTime =
          "0" + hours.toString() + ":" + terrainTimes.startTime.split(":")[1];
      }
      // not sure why to start day to month
      plusDate = moment(plusDate).add(1, "days").format("YYYY-MM-DD");
    }
    return plusDate + "T" + terrainTimes.startTime + ":00";
  };

  isBeforeClosing = () => {
    let { state } = this.props;
    let { selectedTerrain } = state.reservationForm;
    let selected =
      Array.isArray(selectedTerrain) && selectedTerrain.length > 0
        ? selectedTerrain[0]
        : selectedTerrain;
    let reservationForm = { ...state.reservationForm };
    let information = { ...reservationForm.information };
    let selectedActivity = reservationForm.selectedActivity
      ? Array.isArray(reservationForm.selectedActivity)
        ? reservationForm.selectedActivity[0]
        : reservationForm.selectedActivity
      : null;
    let selectedDurations = selectedActivity
      ? selectedActivity.durations.filter(
          (it) => it.id === information.duration_id
        )
      : [];
    let openComplex =
      this.props.selectedComplex.complex_time &&
      this.props.selectedComplex.complex_time.open
        ? this.props.selectedComplex.complex_time.open
        : "08:00";

    let isBefore = true;
    let isAfter = true;
    /***
     *
     */
    if (selectedDurations.length > 0 && information.start_hour) {
      if (state.date !== reservationForm.information.date && selected) {
        let terrainTimes = this.getClosingAndOpeningOfField(
          selected,
          reservationForm.information.date
        );
        let closingTime = this.closingDateAndTime(
          reservationForm.information.date,
          terrainTimes
        );
        let openingTime = this.openingDateAndTime(
          reservationForm.information.date,
          terrainTimes
        );

        isBefore = moment(
          reservationForm.information.date +
            "T" +
            information.start_hour +
            ":00"
        )
          .add(toMinutes(selectedDurations[0].duration), "minutes")
          .isSameOrBefore(closingTime);

        isAfter = !(
          moment(
            reservationForm.information.date +
              "T" +
              information.start_hour +
              ":00"
          ).isSameOrAfter(
            reservationForm.information.date + "T" + openComplex + ":00"
          ) &&
          moment(
            reservationForm.information.date +
              "T" +
              information.start_hour +
              ":00"
          ).isBefore(openingTime)
        );
      } else if (state.info) {
        if (state.info.hasOwnProperty("closingTime"))
          isBefore = moment(
            information.date + "T" + information.start_hour + ":00"
          )
            .add(toMinutes(selectedDurations[0].duration), "minutes")
            .isSameOrBefore(state.info.closingTime);

        if (state.info.hasOwnProperty("openingTime"))
          isAfter = !moment(
            reservationForm.information.date +
              "T" +
              information.start_hour +
              ":00"
          ).isBetween(
            reservationForm.information.date + "T" + openComplex + ":00",
            state.info.openingTime
          );
      }
    }
    return { isBefore, isAfter };
  };

  /**
   * check information Panel in reservation modal
   *
   * */
  checkInformation = () => {
    let { state, setState, type } = this.props;
    let reservationForm = { ...state.reservationForm };
    let information = { ...reservationForm.information };
    let competition = { ...reservationForm.competition };
    let advance_ticket = { ...information.advance_ticket };
    let isValid = false;

    if (!information.checkReservation)
      setState({
        reservationForm: {
          ...reservationForm,
          checkReservation: true,
          information: { ...information, show: true },
        },
      });
    let checkingPlacement = this.isBeforeClosing();

    if (
      checkingPlacement.isAfter &&
      checkingPlacement.isBefore &&
      (information.user.mobile || information.user.email) &&
      (information.user.lastName || information.user.firstName) &&
      information.duration_id &&
      information.hour_type_id &&
      information.start_hour &&
      information.end_hour &&
      information.terrain_id &&
      ((competition.competition_id &&
        (competition.competition_day || competition.round)) ||
        !competition.competition_id) &&
      (information.recurrence === 0 ||
        (information.recurrence &&
          information.recurrence_days.length > 0 &&
          information.end_date_rec !== "" &&
          information.start_date_rec !== "")) &&
      (type === "leisure" ? competition.group_name : true) &&
      (!information.is_advance ||
        advance_ticket.payments.types[0].payment_type_id)
    ) {
      isValid = true;
    }
    return isValid;
  };

  checkGames = () => {
    let { state, setState } = this.props;
    let reservationForm = { ...state.reservationForm };
    let information = { ...reservationForm.information };
    let games = information.games;
    if (!information.checkGame)
      setState({
        reservationForm: {
          ...reservationForm,
          checkGame: true,
          information: { ...information, show: true },
        },
      });

    for (let i = 1; i < games.length; i++) {
      if (
        !(games[i].date && games[i].start_hour && games[i].end_hour) ||
        checkGamesRepetition(games, i)
      )
        return false;
    }

    return true;
  };

  /*
   * debounce for prevent multi click when saving reservation
   * */

  handleCompetitionDropdown = (e, d) => {
    let { state, setState } = this.props;
    let competition = { ...state.reservationForm.competition };
    competition[d.name] = d.value;

    let { reservationForm } = state;

    reservationForm = { ...reservationForm, competition };
    setState({ reservationForm: reservationForm });
  };

  handleReservationDropdown = (e, d) => {
    this.handleReservationChange({ target: { name: d.name, value: d.value } });
  };

  handleReservationChange = (e) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let { information } = state.reservationForm;

    information = {
      ...information,
      [e.target.name]:
        typeof e.target.value === "string"
          ? e.target.value.trimStart()
          : e.target.value,
    };
    reservationForm = { ...reservationForm, information };
    setState({ reservationForm: reservationForm });
  };

  handleAdvanceTicketChange = (amount = "", article_id = "", data = null) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let { information } = state.reservationForm;
    let { advance_ticket } = information;

    // is editing
    if (information.reservation_id) {
      if (advance_ticket.article_id.toString() !== article_id.toString()) {
        reservationForm.old_advance_id = advance_ticket.id;
        advance_ticket.id = null;
      }
    }
    // case empty
    if (!article_id) {
      state.reservationForm.is_advance = 0;
      state.reservationForm.advance = "";
    }
    if (data) {
      advance_ticket.articles = [data];
      advance_ticket.articles[0].article_nb = 1;
      advance_ticket.articles[0].total_price = data.total_price;
    } else {
      advance_ticket.articles = [];
    }
    advance_ticket.payments.types[0].amount = amount;
    advance_ticket = { ...advance_ticket, article_id, amount };
    information = {
      ...information,
      advance_ticket,
      // article_id,
      advance: amount,
      is_advance: article_id ? 1 : 0,
    };
    reservationForm = { ...reservationForm, information };
    setState({ reservationForm: reservationForm });
  };

  handlePaymentTypeChange = (e, d) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let { information } = state.reservationForm;
    let { advance_ticket } = information;
    let { payments } = advance_ticket;
    // is editing
    if (information.reservation_id) {
      reservationForm.old_advance_id = advance_ticket.id;
      advance_ticket.id = null;
    }

    // check if paymentType is credit
    if (d && d.is_credit_account) {
      //add to payment.articles[0]
      let credit_detail = {
        complex_id: localStorage.getItem("complex_id"),
        expense: advance_ticket.amount,
        is_advance: 0,
        user_id: information.user_id,
      };
      payments.articles[0].credit_user_id = information.user.user_id;
      payments.articles[0].credit_detail = credit_detail;
    } else {
      delete payments.articles[0].credit_user_id;
      delete payments.articles[0].credit_detail;
    }

    payments.articles[0].payment_type_id = e;
    payments.types[0].payment_type_id = e;
    payments.types[0].amount = advance_ticket.amount;
    advance_ticket = { ...advance_ticket, payments };
    information = { ...information, advance_ticket };
    reservationForm = { ...reservationForm, information };
    setState({ reservationForm: reservationForm });
  };

  removePlayer = (index, team) => {
    let { state, setState, type } = this.props;
    let { reservationForm } = state;
    let { participants } = reservationForm;
    if (type === "leisure") {
      this.handleCompetitionDropdown(
        {},
        {
          name: "participants_nb",
          value: parseInt(reservationForm.competition.participants_nb) - 1,
        }
      );
    } else {
      if (team === 1) {
        let { equipe1 } = participants;
        equipe1.players.splice(index, 1);
        participants = { ...participants, ...equipe1 };
        reservationForm = { ...reservationForm, ...participants };
      } else {
        let { equipe2 } = participants;
        equipe2.players.splice(index, 1);
        participants = { ...participants, ...equipe2 };
        reservationForm = { ...reservationForm, ...participants };
      }
      setState({ reservationForm: reservationForm });
    }
  };

  removeGame = (index) => {
    let { state, setState, type } = this.props;
    let reservationForm = { ...state.reservationForm };
    let information = { ...reservationForm.information };

    let games = [...information.games];
    if (games[index].reservation_id)
      reservationForm.leisure_ids.push(games[index].reservation_id);

    games.splice(index, 1);
    information = { ...information, games: [...games] };
    information.games_nb -= 1;
    reservationForm = { ...reservationForm, information };
    setState({ reservationForm: reservationForm });
  };

  addNewPlayer = (team) => {
    let { state, setState, type } = this.props;

    let { reservationForm } = state;
    let { participants } = reservationForm;
    if (type === "leisure") {
      this.handleCompetitionDropdown(
        {},
        {
          name: "participants_nb",
          value: parseInt(reservationForm.competition.participants_nb) + 1,
        }
      );
    } else {
      if (team === 1) {
        let { equipe1 } = participants;
        equipe1.players.push({ id: null, ...player, removable: true });
        participants = { ...participants, ...equipe1 };
        reservationForm = { ...reservationForm, ...participants };
      } else {
        let { equipe2 } = participants;
        equipe2.players.push({ id: null, ...player, removable: true });
        participants = { ...participants, ...equipe2 };
        reservationForm = { ...reservationForm, ...participants };
      }
      setState({ reservationForm: reservationForm });
    }
  };

  renderPlayerInformation = (index, team, width = 6) => {
    let { state, t, type } = this.props;
    let { reservationForm } = state;
    let isLeisure = type === "leisure";
    let { participants } = reservationForm;

    let selectedTeams = this.props.teams.filter(
      (it) =>
        it.id === participants.equipe1.id || it.id === participants.equipe2.id
    );
    let isDisabled = selectedTeams.filter((it) => it.is_default);
    let id = selectedTeams.length > 0 ? selectedTeams[0].id.toString() : "0";
    let equipe = team === 1 ? participants.equipe1 : participants.equipe2;
    let pl = equipe.players[index];
    let firstPl = this.isPlayerEmpty(participants.equipe1.players[0]);
    let secondPl =
      participants.equipe2.players.length > 0
        ? this.isPlayerEmpty(participants.equipe2.players[0])
        : true;
    let isEmpty = this.isPlayerEmpty(pl);

    let allParticipants = participants.equipe2.players.concat(
      participants.equipe1.players
    );
    let duplicateParticipants = checkParticipants(pl.user_id, allParticipants);

    return (
      <>
        {pl.search && !pl.disabled && (
          <Transition animation={"slide left"} visible={pl.search}>
            <Grid.Column width={14} className={"no-padding"}>
              <div className={"search-part"}>
                <div
                  className={"-close planning-search-btn-focused"}
                  onClick={() =>
                    this.handleParticipantChange(
                      {
                        target: {
                          name: "search",
                          value: false,
                        },
                      },
                      index,
                      team
                    )
                  }
                >
                  <Icon name={"x"} color={"red"} />
                </div>
                <div style={{ display: "flex", flex: 1 }}>
                  <div
                    style={{ flex: 1, marginRight: "4px", marginLeft: "18px" }}
                  >
                    <CustomDropdown
                      placeholder={t("select_user")}
                      url={"/clients/search"}
                      get={"/users"}
                      noData={t("no_customer_found")}
                      param={"&type=client"}
                      value={pl.user_id}
                      prevent={this.getParticipants()}
                      onChange={(v, e) =>
                        this.handleSearchParticipantChange(
                          e,
                          index,
                          team,
                          "client"
                        )
                      }
                      error={false}
                      render={(item) => {
                        return (
                          <div className={"row-user"}>
                            <Image
                              className="avatar-cover"
                              src={imgUrl + "/users/" + item.avatar}
                              alt={""}
                            />
                            <span>
                              {nullString(item.firstName).toLowerCase()}{" "}
                              {nullString(item.lastName).toUpperCase()}
                            </span>
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div
                    style={{ flex: 1, marginRight: "4px", marginLeft: "4px" }}
                  >
                    <CustomDropdown
                      placeholder={t("select-a-contact")}
                      url={"/contacts"}
                      get={"/contacts"}
                      disabled={true}
                      noData={t("no_contact_found")}
                      value={pl.contact_id}
                      onChange={(v, e) =>
                        this.handleSearchParticipantChange(
                          e,
                          index,
                          team,
                          "contact"
                        )
                      }
                      error={false}
                      render={(item) => {
                        return (
                          <div className={"row-user"}>
                            <Image
                              src={imgUrl + "/contacts/" + item.avatar}
                              alt={""}
                            />
                            <span>
                              {item.first_name} {item.last_name}
                            </span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Transition>
        )}
        {!pl.search && (
          <>
            {
              <Grid.Column width={1} className={"no-padding"}>
                <div
                  className={"planning-search-btn"}
                  onClick={() =>
                    !pl.disabled &&
                    this.handleParticipantChange(
                      {
                        target: {
                          name: "search",
                          value: true,
                        },
                      },
                      index,
                      team
                    )
                  }
                >
                  <Icon name={"search"} />
                </div>
              </Grid.Column>
            }
            <Grid.Column width={pl.sensor ? 2 : 3} className={"no-padding"}>
              <Input
                type={"text"}
                placeholder={t("first_name")}
                name={"firstName"}
                error={
                  (team === 1 && index === 0 && firstPl && secondPl) ||
                  (!isEmpty && !(pl.firstName || pl.lastName))
                }
                disabled={pl.disabled}
                value={pl.firstName}
                onChange={(e) => this.handleParticipantChange(e, index, team)}
              />
            </Grid.Column>
            <Grid.Column width={pl.sensor ? 2 : 3} className={"no-padding"}>
              <Input
                type={"text"}
                placeholder={t("last_name")}
                name={"lastName"}
                error={
                  (team === 1 && index === 0 && firstPl && secondPl) ||
                  (!isEmpty && !(pl.firstName || pl.lastName))
                }
                disabled={pl.disabled}
                value={pl.lastName}
                onChange={(e) => this.handleParticipantChange(e, index, team)}
              />
            </Grid.Column>
            <Grid.Column width={pl.sensor ? 2 : 4} className={"no-padding"}>
              <CustomInput
                type={"phone"}
                showLabel={false}
                name={"mobile"}
                error={
                  (team === 1 && index === 0 && firstPl && secondPl) ||
                  (!isEmpty && !(pl.mobile || pl.email)) ||
                  this.getParticipantError(id + "-" + index, "mobile")
                }
                disabled={pl.disabled}
                placeholder={t("mobile")}
                value={phoneNumber(pl.mobile)}
                countrycode={pl.country_code}
                onChange={(e, mobileEvent) =>
                  this.handleParticipantChange(e, index, team, mobileEvent)
                }
              />
            </Grid.Column>
            <Grid.Column width={4} className={"no-padding"}>
              <Input
                type={"text"}
                error={
                  (team === 1 && index === 0 && firstPl && secondPl) ||
                  (!isEmpty && !(pl.mobile || pl.email)) ||
                  this.getParticipantError(id + "-" + index, "email")
                }
                name={"email"}
                placeholder={t("email")}
                value={pl.email}
                disabled={pl.disabled}
                onChange={(e) => this.handleParticipantChange(e, index, team)}
              />
            </Grid.Column>
            {isLeisure && (
              <Grid.Column width={4} className={"no-padding"}>
                <CustomDropdown
                  disabled={pl.payed}
                  className={"capteur-drp"}
                  placeholder={t("discount")}
                  url={"/discounts"}
                  value={pl.discount_id}
                  onChange={(v, e) =>
                    this.handleParticipantChange(
                      { target: { name: "discount_id", value: v ? v : "" } },
                      index,
                      team
                    )
                  }
                  error={false}
                  render={(item) => {
                    return (
                      <>
                        <span>
                          {item.name}-
                          {item.amount
                            ? item.amount + localStorage.getItem("currency")
                            : item.value + "%"}
                        </span>
                      </>
                    );
                  }}
                />
              </Grid.Column>
            )}
            {pl.sensor && !isLeisure && (
              <Grid.Column width={4} className={"no-padding"}>
                <CustomDropdown
                  className={"capteur-drp"}
                  placeholder={t("sensor")}
                  url={"/sensors"}
                  value={pl.sensor_id}
                  onChange={(v, e) =>
                    this.handleParticipantChange(
                      { target: { name: "sensor_id", value: v ? v : "" } },
                      index,
                      team
                    )
                  }
                  error={false}
                  render={(item) => {
                    return (
                      <>
                        <span>
                          {item.code}-{item.serial_number}
                        </span>
                      </>
                    );
                  }}
                />
              </Grid.Column>
            )}
            {this.props.type !== "leisure" && (
              <Grid.Column width={1} className={"no-padding"}>
                <Dropdown
                  direction={getLang() === "ar" ? "right" : "left"}
                  className="icon padding-dots"
                  icon={null}
                  floating
                  trigger={<Icon name={"ellipsis vertical"} />}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon="random"
                      disabled={
                        isDisabled.length !== 2 || this.isPlayerEmpty(pl)
                      }
                      text={t("switch_team")}
                      onClick={() => this.switchPlayer(index, team)}
                    />
                    <Dropdown.Item
                      icon="microchip"
                      text={pl.sensor ? t("delete_sensor") : t("affect_sensor")}
                      onClick={() =>
                        this.handleParticipantChange(
                          { target: { name: "sensor", value: !pl.sensor } },
                          index,
                          team
                        )
                      }
                    />
                    {/*<Dropdown.Item icon='trash' disabled={true} text={"Supprimer Participant"} onClick={() => {}}/>*/}
                  </Dropdown.Menu>
                </Dropdown>
                {duplicateParticipants.length > 0 ? (
                  <Popup
                    content={t("duplicated_participant")}
                    position={"top right"}
                    trigger={
                      <Icon
                        color={"red"}
                        name={"warning sign"}
                        className={"participant-warning"}
                      />
                    }
                  />
                ) : null}
              </Grid.Column>
            )}
          </>
        )}
      </>
    );
  };

  renderGame = (index, pl) => {
    let { state, t } = this.props;
    let { selectedActivity } = state.reservationForm;
    let games = state.reservationForm.information.games;
    let hasTicket = this.hasTicket();
    return (
      <>
        {
          <>
            <Grid.Column width={4} className={"no-padding"}>
              <Input
                type={"date"}
                className={"input-bg input-date-small"}
                placeholder={t("date")}
                name={"date"}
                min={state.reservationForm.information.date}
                disabled={index === 0}
                value={pl.date}
                error={
                  !state.reservationForm.checkGame
                    ? false
                    : !pl.date || checkGamesRepetition(games, index)
                }
                onChange={(e) => {
                  let games = [...state.reservationForm.information.games];
                  games[index].date = e.target.value;
                  this.handleReservationChange({
                    target: { name: "games", value: games },
                  });
                }}
              />
            </Grid.Column>

            <Grid.Column width={4} className={"no-padding"}>
              <TimeInput
                disabled={index === 0}
                name={"start_hour"}
                error={
                  !state.reservationForm.checkGame
                    ? false
                    : !pl.start_hour || checkGamesRepetition(games, index)
                }
                onChange={async (v) => {
                  let value = state.reservationForm.information.duration_id;
                  let durations = selectedActivity
                    ? selectedActivity.durations
                    : [];
                  let games = [...state.reservationForm.information.games];

                  if (value) {
                    let duration = durations.filter((it) => it.id === value);
                    let finDate = "";

                    try {
                      let minute = duration[0].duration.split(":");
                      minute[0] = parseInt(minute[0]) * 60;
                      minute[1] = parseInt(minute[1]) + minute[0];

                      finDate = await moment(state.date + "T" + v + ":00+00:00")
                        .utc()
                        .add(minute[1], "minute")
                        .format("HH:mm");
                    } catch (e) {
                      //
                    }

                    games[index].end_hour = finDate;
                  }
                  games[index].start_hour = v;

                  this.handleReservationChange({
                    target: { name: "games", value: games },
                  });
                }}
                value={pl.start_hour}
              />
            </Grid.Column>

            <Grid.Column width={4} className={"no-padding"}>
              <TimeInput
                disabled={true}
                error={
                  !state.reservationForm.checkGame
                    ? false
                    : !pl.end_hour || checkGamesRepetition(games, index)
                }
                name={"end_hour"}
                onChange={async (v) => {}}
                value={pl.end_hour}
              />
            </Grid.Column>
          </>
        }
      </>
    );
  };

  /**
   *
   * @param teams
   * @param team_id
   * @param index
   * @returns {[]}
   *
   * Fill participant when team is change
   */
  fillTeamFromUsers = async (teams, team_id = "", index = 1) => {
    let players = [];
    let { state } = this.props;
    let selectedTeam = team_id
      ? this.props.teams.filter((it) => it.id.toString() === team_id.toString())
      : [];
    let nbs = this.getNbPerTeam();
    let isDefault = false;
    if (selectedTeam.length > 0) isDefault = selectedTeam[0].is_default === 1;

    if (teams && teams.users.length > 0) {
      for (let i = 0; i < teams.users.length; i++) {
        let playerFromUser = {
          ...player,
          user_id: teams.users[i].id,
          firstName: teams.users[i].firstName,
          lastName: teams.users[i].lastName,
          email: teams.users[i].email,
          mobile: teams.users[i].mobile,
          country_code: teams.users[i].country_code,
          sensor_id: "",
          team_id: team_id,

          removable: false,
          search: false,
          sensor: false,
        };
        players.push(playerFromUser);
      }

      let nbs = this.getNbPerTeam();

      if (players.length < nbs[index - 1]) {
        let length = nbs[index - 1] - players.length;
        for (let i = 0; i < length; i++) {
          players.push({ ...player });
        }
      }
    } else {
      if (
        isDefault &&
        (state.reservationForm.participants[
          "equipe" + (index === 1 ? index + 1 : index - 1)
        ].toString() === team_id.toString() ||
          state.reservationForm.participants[
            "equipe" + (index === 1 ? index + 1 : index - 1)
          ].toString() === "0")
      ) {
        let user = state.reservationForm.information.user;
        let playerFromUser = {
          ...player,
          user_id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          mobile: user.mobile,
          country_code: user.country_code,
          sensor_id: "",
          team_id: team_id,

          removable: false,
          search: false,
          sensor: false,
        };
        players.push(playerFromUser);

        if (players.length < nbs[index - 1]) {
          let length = nbs[index - 1] - players.length;
          for (let i = 0; i < length; i++) {
            players.push({ ...player });
          }
        }
      } else
        players = new Array(nbs[index - 1])
          .fill(null)
          .map(() => ({ ...player }));
    }

    return players;
  };

  /**
   * change number of participants
   * */
  onChangeParticipants = (size) => {
    let { state, setState } = this.props;
    let participants = { ...state.reservationForm.participants };
    if (participants.equipe1.players.length !== size) {
      resize(participants.equipe1.players, size);
      //resize(participants.equipe2.players, size);
      let reservationForm = { ...state.reservationForm };
      reservationForm.participants = participants;
      setState({ reservationForm });
    }
  };

  /**
   * change number of games
   * */
  onChangeGames = (size) => {
    let { state, setState, selected } = this.props;
    let information = { ...state.reservationForm.information };
    if (information.games.length !== size) {
      let defaultGame = {
        ...information.games[0],
        start_hour: information.start_hour,
        end_hour: information.end_hour,
        date: information.date,
        reservation_id: information.reservation_id,
      };
      information.games[0] = defaultGame;
      resize(information.games, size, { ...game });
      information.games = generateNewGames(
        information.games,
        selected[0].duration?.duration
      );
      let reservationForm = { ...state.reservationForm };
      reservationForm.information = information;
      setState({ reservationForm });
    }
  };

  /**
   *
   * @param e
   * @param d
   * @param team
   * @param teams
   *
   * Handle participant input
   */
  onChangeTeam = async (e, d, team, teams = null) => {
    let { state, setState } = this.props;
    let reservationForm = { ...state.reservationForm };
    let participants = { ...reservationForm.participants };
    let equipe1 = { ...participants.equipe1 };
    let equipe2 = { ...participants.equipe2 };
    let nbs = this.getNbPerTeam();
    //handle null values
    d.value = d.value ? d.value : 0;
    if (team === 1) {
      equipe1.id = d.value;

      equipe1.players = await this.fillTeamFromUsers(teams, d.value);
      if (equipe2.id === d.value && d.value !== 0) {
        equipe2.id = 0;
        equipe2.players = new Array(nbs[1])
          .fill(null)
          .map(() => ({ ...player }));
      }
    } else {
      equipe2.id = d.value;
      equipe2.players = await this.fillTeamFromUsers(teams, d.value, team);
      if (equipe1.id === d.value && d.value !== 0) {
        equipe1.id = 0;
        equipe1.players = new Array(nbs[0])
          .fill(null)
          .map(() => ({ ...player }));
      }
    }
    participants = { ...participants, equipe2, equipe1 };
    reservationForm = { ...reservationForm, participants };
    let filterResult = state.teams;

    if (teams)
      filterResult = state.teams.filter(
        (it) =>
          teams.hasOwnProperty("id") && it.id.toString() !== teams.id.toString()
      );

    if (teams) setState({ reservationForm, teams: [...filterResult, teams] });
    else setState({ reservationForm, teams: [...state.teams] });
  };

  /*
   *  Render Information tab
   *  in the reservation modal
   *  @returns {reactComponent}
   *
   * */
  renderInformationPanel = () => {
    const { state, setState, t } = this.props;
    return (
      <Form autoComplete={"off"}>
        <div className={"modal-container-column"}>
          <Grid>
            <Grid.Row className={"no-padding-bottom"}>
              <Grid.Column
                computer={8}
                tablet={16}
                className={
                  localStorage.getItem("lang") === "ar"
                    ? "modal-border-right"
                    : "modal-border-left"
                }
              >
                <div className={"header-search "}>
                  <Grid columns={16} verticalAlign={"middle"}>
                    <Grid.Column width={4}>
                      <span className={"label-modal"}>
                        {t("choose-a-client")}
                      </span>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <CustomDropdown
                        placeholder={t("select-a-user")}
                        url={"/clients/search"}
                        get={"/users"}
                        noData={t("no_customer_found")}
                        param={"&type=client"}
                        value={state.reservationForm.information.user.user_id}
                        onChange={this.handleClientChange}
                        // onInitial={(data) => {
                        //   state.reservationForm.information.user.contact_id &&
                        //     this.handleClientChange(data ? data.id : null, data, 'client');
                        // }}
                        error={false}
                        render={(item) => {
                          return (
                            <>
                              <Image
                                className="avatar-cover"
                                src={imgUrl + "/users/" + item.avatar}
                                alt={""}
                              />
                              <span>
                                {nullString(item.firstName).toLowerCase()}{" "}
                                {nullString(item.lastName).toUpperCase()}
                              </span>
                            </>
                          );
                        }}
                      />
                    </Grid.Column>

                    <Grid.Column width={4} className={"vertical-padding"} />

                    <Grid.Column width={12} className={"vertical-padding"}>
                      <CustomDropdown
                        placeholder={t("select-a-contact")}
                        url={"/contacts"}
                        disabled={true}
                        noData={t("no_contact_found")}
                        value={
                          state.reservationForm.information.user.contact_id
                        }
                        onChange={(i, data) =>
                          this.handleClientChange(i, data, "contact")
                        }
                        error={false}
                        render={(item) => {
                          return (
                            <>
                              <Image
                                src={imgUrl + "/contacts/" + item.avatar}
                                alt={""}
                              />
                              <span>
                                {item.first_name} {item.last_name}
                              </span>
                            </>
                          );
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </div>
                <Grid>
                  <Grid.Column width={4} />
                  <Grid.Column width={8} className={"link-btn"}>
                    <a href={"#"} onClick={(e) => this.showClientForm(e, true)}>
                      <Icon name={"add"} />

                      {t("add_client")}
                    </a>
                  </Grid.Column>
                </Grid>
                {state.reservationForm.information.show && (
                  <div className={"input-modal"}>
                    <Grid
                      verticalAlign={"middle"}
                      columns={16}
                      className={"form-input-inline-modal"}
                    >
                      <Grid.Column width={4}>
                        <span className={"label-modal"}>{t("name")}</span>
                      </Grid.Column>
                      <Grid.Column width={12} className={"full-width-input"}>
                        <div className={"input-planning-name"}>
                          <Input
                            name={"lastName"}
                            placeholder={t("last_name")}
                            type={"text"}
                            className={"input-name"}
                            error={
                              !state.reservationForm.checkReservation
                                ? false
                                : !(
                                    state.reservationForm.information.user
                                      .firstName ||
                                    state.reservationForm.information.user
                                      .lastName
                                  )
                            }
                            value={
                              state.reservationForm.information.user.lastName
                            }
                            onChange={this.handleClientReservationChange}
                          />
                          <Input
                            name={"firstName"}
                            placeholder={t("first_name")}
                            type={"text"}
                            className={"input-name"}
                            error={
                              !state.reservationForm.checkReservation
                                ? false
                                : !(
                                    state.reservationForm.information.user
                                      .firstName ||
                                    state.reservationForm.information.user
                                      .lastName
                                  )
                            }
                            value={
                              state.reservationForm.information.user.firstName
                            }
                            onChange={this.handleClientReservationChange}
                          />
                        </div>
                      </Grid.Column>
                    </Grid>
                    <CustomInput
                      label={t("born_the")}
                      onChange={this.handleClientReservationChange}
                      value={formattingDate(
                        state.reservationForm.information.user.birthdate
                      )}
                      name={"birthdate"}
                      type={"date"}
                    />

                    <CustomInput
                      mode={"inline"}
                      label={t("email")}
                      onBlur={() => {
                        let {
                          email,
                          lastEmail,
                          user_id,
                        } = state.reservationForm.information.user;
                        if (email !== lastEmail && user_id)
                          setState({ modalNewUser: true });
                      }}
                      value={nullString(
                        state.reservationForm.information.user.email
                      )}
                      error={
                        this.getMainUserError("email")
                          ? true
                          : !state.reservationForm.checkReservation
                          ? false
                          : !(
                              state.reservationForm.information.user.email ||
                              state.reservationForm.information.user.mobile
                            )
                      }
                      errormsg={
                        this.getMainUserError("email")
                          ? { content: this.getMainUserError("email") }
                          : { content: "" }
                      }
                      type={"email"}
                      onChange={this.handleClientReservationChange}
                      name={"email"}
                    />

                    <CustomInput
                      mode={"inline"}
                      label={t("mobile")}
                      error={
                        this.getMainUserError("mobile")
                          ? true
                          : !state.reservationForm.checkReservation
                          ? false
                          : !(
                              state.reservationForm.information.user.email ||
                              state.reservationForm.information.user.mobile
                            )
                      }
                      errormsg={
                        this.getMainUserError("mobile")
                          ? { content: this.getMainUserError("mobile") }
                          : { content: "" }
                      }
                      value={phoneNumber(
                        state.reservationForm.information.user.mobile
                      )}
                      countrycode={
                        state.reservationForm.information.user.country_code
                      }
                      type={"phone"}
                      onChange={this.handleClientReservationChange}
                      name={"mobile"}
                    />

                    <CustomInput
                      mode={"inline"}
                      label={t("company")}
                      value={state.reservationForm.information.user.company}
                      type={"text"}
                      onChange={this.handleClientReservationChange}
                      name={"company"}
                    />
                    <CustomInput
                      mode={"inline"}
                      label={t("info")}
                      value={state.reservationForm.information.info}
                      type={"textArea"}
                      className={"no-style text-area-height"}
                      cols={22}
                      rows={6}
                      onChange={this.handleReservationChange}
                      name={"info"}
                    />
                  </div>
                )}
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                className={"no-padding-left"}
              >
                {this.props.type === "caisse"
                  ? this.renderRightSideForCaisse()
                  : this.props.type === "default"
                  ? this.renderRightSide()
                  : this.renderLeisureRightSide()}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={"no-padding-bottom"}>
              <Grid columns={16} className={"modal-input bottom-modal"}>
                <Grid.Column width={8}>
                  <div className={"box-info"}>
                    <span className={"info-title"}>{t("information")}</span>
                    <span className={"info-description"}>
                      {this.props.informationLoading ? (
                        <div className={"planning-information-loader"}>
                          <Loader size={"tiny"} />
                        </div>
                      ) : this.props.reservationInformation &&
                        this.props.reservationInformation.activity ? (
                        <div className={"planning_information_container"}>
                          {this.props.reservationInformation.conclusion ===
                            "Pas Venu" && (
                            <Popup
                              content={t("last_reservation_user_didnt_come")}
                              position={"top center"}
                              trigger={
                                <Icon
                                  name={"warning sign"}
                                  className={"reservation-warning"}
                                />
                              }
                            />
                          )}
                          <span>
                            {t("last_reservation_was") +
                              " (" +
                              t(
                                conclusionTranslate(
                                  this.props.reservationInformation.conclusion
                                )
                              ) +
                              ")"}
                            <br />
                            {this.props.reservationInformation.activity.name +
                              " : " +
                              capitalize(
                                moment(
                                  this.props.reservationInformation.date +
                                    " " +
                                    this.props.reservationInformation.start_hour
                                )
                                  .locale(i18n.language)
                                  .format("LLLL")
                              )}
                          </span>
                          <span>
                            {capitalize(
                              moment(
                                this.props.reservationInformation.date +
                                  " " +
                                  this.props.reservationInformation.start_hour
                              )
                                .locale(i18n.language)
                                .fromNow()
                            )}
                          </span>
                        </div>
                      ) : (
                        nullStringForTable(this.props.reservationInformation)
                      )}
                    </span>
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <div className={"box-info"}>
                    <span className={"info-title"}>{t("conclusion")}</span>
                    <span className={"info-description btn-description"}>
                      <Button
                        className={"valider"}
                        onClick={() => this.handleConclusion("A valider")}
                      >
                        {state.reservationForm.information.conclusion ===
                          "A valider" && <Icon name="check" />}
                        {t("to-validate")}
                      </Button>
                      <Button
                        className={"venir"}
                        onClick={() => this.handleConclusion("A Venir")}
                      >
                        {state.reservationForm.information.conclusion ===
                          "A Venir" && <Icon name="check" />}
                        {t("to-come-up")}
                      </Button>
                      <Button
                        className={"venu"}
                        onClick={() => this.handleConclusion("Venu")}
                      >
                        {state.reservationForm.information.conclusion ===
                          "Venu" && <Icon name="check" />}
                        {t("to-come")}
                      </Button>
                      <Button
                        className={"pas-venu"}
                        onClick={() => this.handleConclusion("Pas Venu")}
                      >
                        {state.reservationForm.information.conclusion ===
                          "Pas Venu" && <Icon name="check" />}
                        {t("not-come")}
                      </Button>
                    </span>
                  </div>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>
        </div>
      </Form>
    );
  };

  handleReservationFormChange = (e, d = null) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    reservationForm = { ...reservationForm, [e.target.name]: e.target.value };
    if (e.target.name === "activity_id") {
      let competition = {
        ...reservationForm.competition,
        competition_id: "",
        competition_day: "",
        competition_day_date: "",
        round: "",
        team_a_id: "",
        team_b_id: "",
        participants_nb: d && d.participants_nb ? d.participants_nb : 1,
        real_missing_participants_nb:
          d && d.participants_nb ? parseInt(d.participants_nb) - 1 : 0,
        missing_participants_nb:
          d && d.participants_nb ? parseInt(d.participants_nb) - 1 : 0,
      };
      reservationForm = { ...reservationForm, competition };
    }
    setState({ reservationForm: reservationForm });
  };

  handleActivityChange = async (e, d) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let { information } = reservationForm;
    let { competition } = reservationForm;

    let participants = { ...reservationForm.participants };

    let finDate = "";
    let duration_id = "";
    if (d && d.durations) {
      try {
        let minute = d.durations[0].duration.split(":");
        duration_id = d.durations[0].id;
        minute[0] = parseInt(minute[0]) * 60;
        minute[1] = parseInt(minute[1]) + minute[0];
        finDate = await moment(
          formattingDate(state.date) +
            "T" +
            state.reservationForm.information.start_hour +
            ":00+00:00"
        )
          .utc()
          .add(minute[1], "minute")
          .format("HH:mm");
      } catch (e) {
        //
      }
    }

    reservationForm.old_advance_id = information.advance_ticket.id;

    let nb = d && d.participants_nb ? d.participants_nb : 1;
    let isMatch = d ? nullString(d.is_match) === "1" : false;

    information = {
      ...information,
      ...{
        duration_id,
        end_hour: finDate,
        activity_id: e,
      },
      advance: "",
      is_advance: 0,
      advance_ticket: getAdvanceTicket(),
    };

    let nbPerTeam1 = nb > 1 ? nb - parseInt(nb / 2) : 1;
    let nbPerTeam2 = nb > 1 ? parseInt(nb / 2) : 1;

    if (isMatch) {
      resize(participants.equipe1.players, nbPerTeam1);
      resize(participants.equipe2.players, nbPerTeam2);
    } else {
      resize(participants.equipe1.players, nb);
      resize(participants.equipe2.players, nb);
    }

    reservationForm = {
      ...reservationForm,
      information,
      competition: {
        ...competition,
        competition_id: "",
        competition_day: "",
        competition_day_date: "",
        round: "",
        team_a_id: "",
        team_b_id: "",
        participants_nb: d && d.participants_nb ? d.participants_nb : 1,
        real_missing_participants_nb:
          d && d.participants_nb ? parseInt(d.participants_nb) - 1 : 0,
        missing_participants_nb:
          d && d.participants_nb ? parseInt(d.participants_nb) - 1 : 0,
      },
      participants,
      selectedActivity: d,
      selectedCompetition: null,
    };

    await setState({ reservationForm });
  };

  /*
   *   Render the activity and terrain
   *   the Right side in reservation modal
   *
   * */
  renderRightSide = () => {
    let { state, setState, t, openComplex, closeComplex } = this.props;
    const { terrains } = state;
    let {
      selectedTerrain,
      selectedCompetition,
      selectedActivity,
    } = state.reservationForm;
    let teams = [],
      optionsDurations = [],
      optionsRound = [
        { key: "round-opt-1/8", text: "1/8", value: "1/8" },
        { key: "round-opt-1/4", text: "1/4", value: "1/4" },
        { key: "round-opt-1/2", text: "1/2", value: "1/2" },
        { key: "round-opt-finale", text: "finale", value: "finale" },
      ],
      optionsDays = [];
    let days = 0;
    const hasTicket = this.hasTicket();
    let hourTypesOptions = this.props.hourTypes.map((it) => ({
      key: it.name + "-" + it.id,
      text: it.name,
      value: it.id,
      data: it,
    }));
    if (selectedCompetition) {
      try {
        days = parseInt(selectedCompetition.days);
      } catch (e) {
        optionsDays.push({
          key: "jour-opt-" + 0,
          text: t("journee") + " " + 0,
          value: "0",
        });
      }

      for (let i = 1; i <= days; i++) {
        optionsDays.push({
          key: "jour-opt-" + i,
          text: t("journee") + " " + i,
          value: i,
        });
      }
    }

    return (
      <div className={"input-modal"}>
        <CustomInput
          options={terrains}
          disabled={true}
          fetching={false}
          label={t("terrain")}
          onInitial={(d) =>
            this.handleReservationFormChange({
              target: { name: "selectedTerrain", value: d },
            })
          }
          render={(it) => (
            <>
              <span>{it.name}</span>
            </>
          )}
          placeholder={t("select_field")}
          error={false}
          onchange={(e, d) => {}}
          value={state.reservationForm.information.terrain_id}
          name={"terrain_id"}
          type={"custom-dropdown"}
        />

        <CustomInput
          label={t("activity")}
          error={
            !state.reservationForm.checkReservation
              ? false
              : !state.reservationForm.information.activity_id
          }
          disabled={hasTicket}
          fetching={false}
          render={(it) => (
            <>
              <span>{it.name}</span>
            </>
          )}
          placeholder={t("select_activity")}
          onChange={this.handleActivityChange}
          options={
            selectedTerrain
              ? Array.isArray(selectedTerrain)
                ? selectedTerrain[0].activities
                : selectedTerrain.activities
              : []
          }
          value={state.reservationForm.information.activity_id}
          name={"activity_id"}
          type={"custom-dropdown"}
        />

        {state.reservationForm.competition.competition_id && selectedActivity && (
          <CustomInput
            mode={"inline"}
            name={"competition_id"}
            label={t("competition")}
            disabled={true}
            placeholder={t("select_competition")}
            onChange={async (e, d) => {
              selectedCompetition = d;

              if (selectedCompetition) {
                teams = selectedCompetition.teams;
              }
              if (teams.length < 2) {
                for (let i = 0; i < 2; i++) {
                  await teams.push(this.props.teams[i]);
                }
              }

              await this.handleCompetitionDropdown(e, {
                name: "round",
                value: "",
              });
              await this.handleCompetitionDropdown(e, {
                name: "competition_day",
                value: "",
              });
              await this.handleCompetitionDropdown(e, {
                name: "competition_id",
                value: e,
              });
              await this.handleReservationFormChange({
                target: { name: "selectedCompetition", value: d },
              });
              await setState({ teams });
              await this.onChangeTeam(e, { value: teams[0].id }, 1, teams[0]);
              await this.onChangeTeam(e, { value: teams[1].id }, 2, teams[1]);
            }}
            error={false}
            type={"custom-dropdown"}
            fetching={false}
            options={selectedCompetition ? [selectedCompetition] : []}
            render={(it) => (
              <>
                <span>{it.name}</span>
              </>
            )}
            value={state.reservationForm.competition.competition_id}
          />
        )}

        {state.reservationForm.competition.competition_id && days ? (
          <CustomInput
            mode={"inline"}
            label={t("journee")}
            disabled={true}
            name={"competition_day"}
            placeholder={t("journee")}
            error={
              !state.reservationForm.checkReservation
                ? false
                : !state.reservationForm.competition.competition_day
            }
            onChange={(e, d) =>
              this.handleCompetitionDropdown(e, {
                name: "competition_day",
                value: e,
              })
            }
            options={optionsDays}
            type={"custom-dropdown"}
            fetching={false}
            keyword={"value"}
            fitler={"text"}
            render={(it) => (
              <>
                <span>{it.text}</span>
              </>
            )}
            value={state.reservationForm.competition.competition_day}
          />
        ) : (
          false &&
          state.reservationForm.competition.competition_id !== null &&
          state.reservationForm.competition.competition_id !== "" && (
            <CustomInput
              mode={"inline"}
              label={t("round")}
              name={"round"}
              placeholder={t("round")}
              disabled={true}
              onChange={(e, d) =>
                this.handleCompetitionDropdown(e, { name: "round", value: e })
              }
              options={optionsRound}
              fetching={false}
              keyword={"value"}
              fitler={"text"}
              render={(it) => (
                <>
                  <span>{it.text}</span>
                </>
              )}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.competition.round
              }
              type={"dropdown"}
              value={state.reservationForm.competition.round}
            />
          )
        )}
        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("duration")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <CustomDropdown
              keyword={"id"}
              className={"date-dropdown"}
              filter={"duration"}
              name={"duration_id"}
              fetching={false}
              disabled={hasTicket}
              placeholder={t("duration")}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.information.duration_id
              }
              render={(it) => (
                <>
                  <span>{it.duration}</span>
                </>
              )}
              onChange={async (e, d) => {
                let duration = d;
                let finDate = "";

                try {
                  let minute = duration.duration.split(":");
                  minute[0] = parseInt(minute[0]) * 60;
                  minute[1] = parseInt(minute[1]) + minute[0];

                  finDate = await moment(
                    formattingDate(state.date) +
                      "T" +
                      state.reservationForm.information.start_hour +
                      ":00+00:00"
                  )
                    .utc()
                    .add(minute[1], "minute")
                    .format("HH:mm");
                } catch (e) {
                  //
                }

                await this.handleReservationChange({
                  target: { name: "duration_id", value: e },
                });

                await this.handleReservationChange({
                  target: { name: "end_hour", value: finDate },
                });
              }}
              options={selectedActivity ? selectedActivity.durations : []}
              value={state.reservationForm.information.duration_id}
            />
          </Grid.Column>
          <Grid.Column width={2} textAlign={"center"}>
            <label className={"label-modal"}>{t("date")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <Input
              type={"date"}
              name={"date"}
              disabled={
                hasTicket || state.reservationForm.information.is_advance === 1
              }
              onChange={this.handleReservationChange}
              value={state.reservationForm.information.date}
            />
          </Grid.Column>
        </Grid>

        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("start_hour")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <TimeInput
              // disabled={true}
              direction={0}
              name={"start_hour"}
              disabled={hasTicket}
              onChange={async (v) => {
                let value = state.reservationForm.information.duration_id;
                let durations = selectedActivity
                  ? selectedActivity.durations
                  : [];
                if (value) {
                  let duration = durations.filter((it) => it.id === value);
                  let finDate = "";

                  try {
                    let minute = duration[0].duration.split(":");
                    minute[0] = parseInt(minute[0]) * 60;
                    minute[1] = parseInt(minute[1]) + minute[0];

                    finDate = await moment(state.date + "T" + v + ":00+00:00")
                      .utc()
                      .add(minute[1], "minute")
                      .format("HH:mm");
                  } catch (e) {
                    //
                  }
                  await this.handleReservationChange({
                    target: { name: "end_hour", value: finDate },
                  });
                  await this.handleReservationChange({
                    target: { name: "hour_type_id", value: "" },
                  });

                  await this.handleReservationChange({
                    target: { name: "price", value: "" },
                  });
                }

                this.handleReservationChange({
                  target: { name: "start_hour", value: v },
                });
              }}
              value={state.reservationForm.information.start_hour}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !this.isBeforeClosing().isAfter
              }
            />
          </Grid.Column>
          <Grid.Column width={2} textAlign={"center"}>
            <label className={"label-modal"}>{t("end_hour")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <TimeInput
              disabled={true}
              name={"end_hour"}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !this.isBeforeClosing().isBefore
              }
              onChange={this.handleReservationChange}
              value={state.reservationForm.information.end_hour}
            />
          </Grid.Column>
        </Grid>
        {state.reservationForm.checkReservation &&
          !this.isBeforeClosing().isAfter && (
            <div className={"error-msg-div-opening"}>
              {t("extend-opening-time")}
            </div>
          )}

        {state.reservationForm.checkReservation &&
          !this.isBeforeClosing().isBefore && (
            <div className={"error-msg-div"}>{t("extend-closing-time")}</div>
          )}

        <CustomInput
          mode={"inline"}
          label={t("hour_type")}
          name={"hour_type_id"}
          disabled={hasTicket}
          placeholder={t("hour_type")}
          error={
            !state.reservationForm.checkReservation
              ? false
              : !state.reservationForm.information.hour_type_id
          }
          onChange={async (e, d) => {
            await this.handleReservationChange({
              target: { name: "hour_type_id", value: e },
            });
            await this.handleReservationChange({
              target: {
                name: "price",
                value: d.data ? d.data.article_amount : "",
              },
            });
            let price_per_participant =
              d &&
              d.data &&
              nullStringNumber(d.data.article_amount) &&
              state.reservationForm.competition.participants_nb
                ? (
                    nullStringNumber(d.data.article_amount) /
                    state.reservationForm.competition.participants_nb
                  ).toFixed(2)
                : 0;
            if (!state.reservationForm.information.is_player)
              this.handleCompetition({
                target: {
                  name: "price_per_participant",
                  value: price_per_participant,
                },
              });
          }}
          options={hourTypesOptions}
          keyword={"value"}
          filter={"text"}
          fetching={false}
          loading={this.props.hourLoading}
          type={"custom-dropdown"}
          render={(it) => (
            <>
              <span>{it.text}</span>
            </>
          )}
          value={state.reservationForm.information.hour_type_id}
        />

        {
          <Grid verticalAlign={"middle"}>
            <Grid.Column width={4}>
              <label className={"label-modal"}>{t("amount")}</label>
            </Grid.Column>
            <Grid.Column width={12} className={"price-input"}>
              <Input
                loading={this.props.priceLoading}
                type={"text"}
                name={"price"}
                disabled={true}
                onChange={this.handleReservationChange}
                value={
                  !state.reservationForm.information.price
                    ? ""
                    : state.reservationForm.information.price +
                      localStorage.getItem("currency")
                }
              />
            </Grid.Column>
          </Grid>
        }
        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("advance")}</label>
          </Grid.Column>
          <Grid.Column
            width={!state.reservationForm.information.is_advance ? 12 : 5}
          >
            <CustomDropdown
              url={"/articles"}
              className={"date-dropdown"}
              disabled={
                parseFloat(
                  state.reservationForm.information.advance_ticket.payed_amount
                ) > 0 || !this.checkForSession()
              }
              condition={(it) => {
                if (!state.reservationForm.information.price) return true;
                return (
                  parseFloat(it.total_price) <=
                  parseFloat(state.reservationForm.information.price)
                );
              }}
              placeholder={t("select_advance")}
              param={
                "&parent_slug=acompte&activity_id=" +
                state.reservationForm.information.activity_id
              }
              onChange={(e, d) => {
                if (e) {
                  this.handleAdvanceTicketChange(d.total_price, e, d);
                } else {
                  this.handleAdvanceTicketChange();
                }
              }}
              value={
                state.reservationForm.information.advance_ticket.article_id
              }
              render={(it) => (
                <>
                  <span>
                    {it.name +
                      " (" +
                      it.total_price +
                      localStorage.getItem("currency") +
                      ")"}
                  </span>
                </>
              )}
            />
            {!this.checkForSession() ? this.renderAlertForAdvance() : null}
          </Grid.Column>
          {state.reservationForm.information.is_advance === 1 && (
            <Grid.Column width={2} textAlign={"center"}>
              <label className={"label-modal"}>{t("type")}</label>
            </Grid.Column>
          )}
          {state.reservationForm.information.is_advance === 1 && (
            <Grid.Column width={5}>
              <CustomDropdown
                className={"date-dropdown regulation-input"}
                url={"/paymentTypes"}
                param={"&status=[0,2]"}
                condition={(it) =>
                  !(it.is_advance || it.is_subscription || it.is_credit)
                }
                error={
                  !state.reservationForm.checkReservation
                    ? false
                    : !state.reservationForm.information.advance_ticket.payments
                        .types[0].payment_type_id
                }
                disabled={
                  parseFloat(
                    state.reservationForm.information.advance_ticket
                      .payed_amount
                  ) > 0
                }
                placeholder={t("regulation")}
                onChange={(e, d) => {
                  this.handlePaymentTypeChange(e, d);
                }}
                value={
                  state.reservationForm.information.advance_ticket.payments
                    .types[0].payment_type_id
                }
                render={(it) => (
                  <>
                    <Image
                      className={it.icon ? "filter-img" : "no-filter-img"}
                      src={url + "/uploads/payments/" + it.icon}
                      type={"item"}
                    />
                    <span>{it.name}</span>
                  </>
                )}
              />
            </Grid.Column>
          )}
        </Grid>

        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("recurrence")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <CustomDropdown
              fetching={false}
              keyword={"value"}
              filter={"text"}
              placeholder={t("recurrence")}
              className={"date-dropdown"}
              selection={true}
              value={state.reservationForm.information.recurrence}
              onChange={(e, d) =>
                this.handleReservationDropdown(e, {
                  name: "recurrence",
                  value: e,
                })
              }
              name={"recurrence"}
              render={(it) => (
                <>
                  <span>{it.text}</span>
                </>
              )}
              options={[
                { key: 0, text: t("yes"), value: 1 },
                { key: 1, text: t("no"), value: 0 },
              ]}
            />
          </Grid.Column>

          <Grid.Column width={2} textAlign={"center"}>
            <label className={"label-modal"}>{t("video")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <CustomDropdown
              placeholder={t("video")}
              fetching={false}
              keyword={"value"}
              filter={"text"}
              value={state.reservationForm.information.cdn_video}
              onChange={(e, d) =>
                this.handleReservationDropdown(e, {
                  name: "cdn_video",
                  value: e,
                })
              }
              name={"cdn_video"}
              className={"date-dropdown"}
              render={(it) => (
                <>
                  <span>{it.text}</span>
                </>
              )}
              options={[
                { key: 0, text: t("yes"), value: 1 },
                { key: 1, text: t("no"), value: 0 },
              ]}
            />
          </Grid.Column>
        </Grid>
        {state.reservationForm.information.recurrence === 1 ? (
          <div className={"reccurence-planning"}>
            <Grid verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("recurrence")}</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <ReccurrenceComponent
                  error={
                    !state.reservationForm.checkReservation
                      ? false
                      : !state.reservationForm.information.recurrence_days
                          .length > 0
                  }
                  values={state.reservationForm.information.recurrence_days}
                  onChange={(val) => {
                    this.handleReservationChange({
                      target: { name: "recurrence_days", value: val },
                    });
                  }}
                />
                <Form.Group inline className={"choice_recurrence"}>
                  <Form.Radio
                    name={"choice_recurrence"}
                    label={"7 " + t("days")}
                    value={7}
                    checked={
                      nullString(
                        state.reservationForm.information.choice_recurrence
                      ) === "7"
                    }
                    onChange={() =>
                      this.handleReservationChange({
                        target: { name: "choice_recurrence", value: 7 },
                      })
                    }
                  />
                  <Form.Radio
                    name={"choice_recurrence"}
                    label={"15 " + t("days")}
                    value={15}
                    checked={
                      nullString(
                        state.reservationForm.information.choice_recurrence
                      ) === "15"
                    }
                    onChange={() =>
                      this.handleReservationChange({
                        target: { name: "choice_recurrence", value: 15 },
                      })
                    }
                  />
                </Form.Group>
              </Grid.Column>
            </Grid>

            <Grid verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("start")}</label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Input
                  error={
                    !state.reservationForm.checkReservation
                      ? false
                      : !state.reservationForm.information.start_date_rec
                  }
                  type={"date"}
                  name={"start_date_rec"}
                  onChange={this.handleReservationChange}
                  value={state.reservationForm.information.start_date_rec}
                />
              </Grid.Column>
              <Grid.Column width={2} textAlign={"center"}>
                <label className={"label-modal"}>{t("end")}</label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Input
                  error={
                    !state.reservationForm.checkReservation
                      ? false
                      : !state.reservationForm.information.end_date_rec
                  }
                  type={"date"}
                  name={"end_date_rec"}
                  onChange={this.handleReservationChange}
                  value={state.reservationForm.information.end_date_rec}
                />
              </Grid.Column>
            </Grid>
          </div>
        ) : null}

        {state.reservationForm.information.reservation_id && (
          <Grid verticalAlign={"middle"}>
            <Grid.Column width={4}>
              <label className={"label-modal"}>{t("network_code")}</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className={"code-input"}>
                {state.reservationForm.information.codeRe}
              </div>
            </Grid.Column>
            <Grid.Column width={2} textAlign={"center"}>
              <label className={"label-modal"}>Code video</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className={"code-input"}>
                {state.reservationForm.information.codeVideo}
              </div>
            </Grid.Column>
          </Grid>
        )}
      </div>
    );
  };

  /**
   * render leisure right side for the moodal tab information
   * */
  renderLeisureRightSide = () => {
    let { state, setState, t, openComplex, selected } = this.props;
    let {
      selectedTerrain,
      selectedCompetition,
      selectedActivity,
    } = state.reservationForm;
    let indexResource = 0;
    let max = 5;
    if (state.reservationForm.information.start_hour && selected.length) {
      let nb = state.reservationForm.competition.participants_nb;
      indexResource = getIndexResource(
        openComplex,
        state.reservationForm.information.start_hour,
        selected[0].duration.duration
      );
      if (state.reservationForm.information.reservation_id)
        max =
          this.props.selected[0].places_nb +
          nb -
          state.leisureStats[indexResource] +
          1;
      else
        max =
          this.props.selected[0].places_nb -
          state.leisureStats[indexResource] +
          1;
    }
    const hasTicket = this.hasTicket();
    let teams = [],
      optionsDurations = [],
      optionsRound = [
        { key: "round-opt-1/8", text: "1/8", value: "1/8" },
        { key: "round-opt-1/4", text: "1/4", value: "1/4" },
        { key: "round-opt-1/2", text: "1/2", value: "1/2" },
        { key: "round-opt-finale", text: "finale", value: "finale" },
      ],
      optionsGroup = [
        { key: "0", text: t("group_1"), value: "0" },
        { key: "1", text: t("group_2"), value: "1" },
      ],
      optionsDays = [];
    let days = 0;

    let hourTypesOptions = this.props.hourTypes.map((it) => ({
      key: it.name + "-" + it.id,
      text: it.name,
      value: it.id,
      data: it,
    }));
    if (selectedCompetition) {
      try {
        days = parseInt(selectedCompetition.days);
      } catch (e) {
        optionsDays.push({
          key: "jour-opt-" + 0,
          text: t("journee") + " " + 0,
          value: "0",
        });
      }

      for (let i = 1; i <= days; i++) {
        optionsDays.push({
          key: "jour-opt-" + i,
          text: t("journee") + " " + i,
          value: i,
        });
      }
    }

    return (
      <div className={"input-modal"}>
        <CustomInput
          options={
            selectedTerrain && Array.isArray(selectedTerrain)
              ? selectedTerrain
              : [selectedTerrain]
          }
          disabled={true}
          fetching={false}
          label={t("terrain")}
          onInitial={(d) =>
            this.handleReservationFormChange({
              target: { name: "selectedTerrain", value: d },
            })
          }
          render={(it) => (
            <>
              <span>{it.name}</span>
            </>
          )}
          placeholder={t("select_field")}
          error={false}
          value={state.reservationForm.information.terrain_id}
          name={"terrain_id"}
          type={"custom-dropdown"}
        />
        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("nb_players")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <Input
              type={"number"}
              max={max}
              min={1}
              name={"participants_nb"}
              onChange={(e) => {
                if (e.target.value > max) e.target.value = max;

                if (e.target.value < 1) e.target.value = 1;

                this.handleCompetitionDropdown(e, e.target);
              }}
              disabled={!!state.reservationForm.information.ticket_id}
              value={state.reservationForm.competition.participants_nb}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <label className={"label-modal"}>{t("group_name")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <Input
              name={"group_name"}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.competition.group_name
              }
              onChange={(e, d) => this.handleCompetitionDropdown(e, e.target)}
              type={"text"}
              value={state.reservationForm.competition.group_name}
            />
          </Grid.Column>
        </Grid>

        <CustomInput
          label={t("activity")}
          disabled={true}
          error={
            !state.reservationForm.checkReservation
              ? false
              : !state.reservationForm.information.activity_id
          }
          fetching={false}
          render={(it) => (
            <>
              <span>{it.name}</span>
            </>
          )}
          placeholder={t("select_activity")}
          onChange={this.handleActivityChange}
          options={
            selectedTerrain
              ? Array.isArray(selectedTerrain)
                ? selectedTerrain[0].activities
                : selectedTerrain.activities
              : []
          }
          value={state.reservationForm.information.activity_id}
          name={"activity_id"}
          type={"custom-dropdown"}
        />

        {state.reservationForm.competition.competition_id && selectedActivity && (
          <CustomInput
            mode={"inline"}
            name={"competition_id"}
            label={t("competition")}
            disabled={true}
            fetching={false}
            options={selectedCompetition ? [selectedCompetition] : []}
            placeholder={t("select_competition")}
            onChange={async (e, d) => {
              selectedCompetition = d;

              if (selectedCompetition) {
                teams = selectedCompetition.teams;
              }
              if (teams.length < 2) {
                for (let i = 0; i < 2; i++) {
                  await teams.push(this.props.teams[i]);
                }
              }

              await this.handleCompetitionDropdown(e, {
                name: "round",
                value: "",
              });
              await this.handleCompetitionDropdown(e, {
                name: "competition_day",
                value: "",
              });
              await this.handleCompetitionDropdown(e, {
                name: "competition_id",
                value: e,
              });
              await this.handleReservationFormChange({
                target: { name: "selectedCompetition", value: d },
              });
              await setState({ teams });
              await this.onChangeTeam(e, { value: teams[0].id }, 1, teams[0]);
              await this.onChangeTeam(e, { value: teams[1].id }, 2, teams[1]);
            }}
            error={false}
            type={"custom-dropdown"}
            filter={"name"}
            render={(it) => (
              <>
                <span>{it.name}</span>
              </>
            )}
            value={state.reservationForm.competition.competition_id}
          />
        )}

        {state.reservationForm.competition.competition_id && days ? (
          <CustomInput
            mode={"inline"}
            label={t("journee")}
            name={"competition_day"}
            placeholder={t("journee")}
            disabled={true}
            error={
              !state.reservationForm.checkReservation
                ? false
                : !state.reservationForm.competition.competition_day
            }
            onChange={(e, d) =>
              this.handleCompetitionDropdown(e, {
                name: "competition_day",
                value: e,
              })
            }
            options={optionsDays}
            type={"custom-dropdown"}
            fetching={false}
            keyword={"value"}
            fitler={"text"}
            render={(it) => (
              <>
                <span>{it.text}</span>
              </>
            )}
            value={state.reservationForm.competition.competition_day}
          />
        ) : (
          false &&
          state.reservationForm.competition.competition_id !== null &&
          state.reservationForm.competition.competition_id !== "" && (
            <CustomInput
              mode={"inline"}
              label={t("round")}
              name={"round"}
              placeholder={t("round")}
              onChange={(e, d) =>
                this.handleCompetitionDropdown(e, { name: "round", value: e })
              }
              options={optionsRound}
              fetching={false}
              keyword={"value"}
              fitler={"text"}
              render={(it) => (
                <>
                  <span>{it.text}</span>
                </>
              )}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.competition.round
              }
              type={"dropdown"}
              value={state.reservationForm.competition.round}
            />
          )
        )}
        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("duration")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <CustomDropdown
              keyword={"id"}
              className={"date-dropdown"}
              disabled={true}
              filter={"duration"}
              name={"duration_id"}
              fetching={false}
              placeholder={t("duration")}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.information.duration_id
              }
              render={(it) => (
                <>
                  <span>{it.duration}</span>
                </>
              )}
              onChange={async (e, d) => {
                let duration = d;
                let finDate = "";

                try {
                  let minute = duration.duration.split(":");
                  minute[0] = parseInt(minute[0]) * 60;
                  minute[1] = parseInt(minute[1]) + minute[0];

                  finDate = await moment(
                    formattingDate(state.date) +
                      "T" +
                      state.reservationForm.information.start_hour +
                      ":00+00:00"
                  )
                    .utc()
                    .add(minute[1], "minute")
                    .format("HH:mm");
                } catch (e) {
                  //
                }

                this.handleReservationChange({
                  target: { name: "duration_id", value: e },
                });

                this.handleReservationChange({
                  target: { name: "end_hour", value: finDate },
                });
              }}
              options={selectedActivity ? selectedActivity.durations : []}
              value={state.reservationForm.information.duration_id}
            />
          </Grid.Column>
          <Grid.Column width={2} textAlign={"center"}>
            <label className={"label-modal"}>{t("date")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <Input
              type={"date"}
              name={"date"}
              disabled={
                state.reservationForm.information.ticket ||
                state.reservationForm.information.is_advance === 1
              }
              onChange={this.handleReservationChange}
              value={state.reservationForm.information.date}
            />
          </Grid.Column>
        </Grid>

        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("start_hour")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <TimeInput
              // disabled={true}
              direction={0}
              name={"start_hour"}
              disabled={hasTicket}
              onChange={async (v) => {
                let value = state.reservationForm.information.duration_id;
                let durations = selectedActivity
                  ? selectedActivity.durations
                  : [];
                if (value) {
                  let duration = durations.filter((it) => it.id === value);
                  let finDate = "";

                  try {
                    let minute = duration[0].duration.split(":");
                    minute[0] = parseInt(minute[0]) * 60;
                    minute[1] = parseInt(minute[1]) + minute[0];

                    finDate = await moment(state.date + "T" + v + ":00+00:00")
                      .utc()
                      .add(minute[1], "minute")
                      .format("HH:mm");
                  } catch (e) {
                    //
                  }
                  await this.handleReservationChange({
                    target: { name: "end_hour", value: finDate },
                  });
                  await this.handleReservationChange({
                    target: { name: "hour_type_id", value: "" },
                  });
                  await this.handleReservationChange({
                    target: { name: "price", value: "" },
                  });
                }

                this.handleReservationChange({
                  target: { name: "start_hour", value: v },
                });
              }}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !this.isBeforeClosing().isAfter
              }
              value={state.reservationForm.information.start_hour}
            />
          </Grid.Column>
          <Grid.Column width={2} textAlign={"center"}>
            <label className={"label-modal"}>{t("end_hour")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <TimeInput
              disabled={true}
              name={"end_hour"}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !this.isBeforeClosing().isBefore
              }
              onChange={this.handleReservationChange}
              value={state.reservationForm.information.end_hour}
            />
          </Grid.Column>
        </Grid>
        {state.reservationForm.checkReservation &&
          !this.isBeforeClosing().isAfter && (
            <div className={"error-msg-div-opening"}>
              {t("extend-opening-time")}
            </div>
          )}
        {state.reservationForm.checkReservation &&
          !this.isBeforeClosing().isBefore && (
            <div className={"error-msg-div"}>{t("extend-closing-time")}</div>
          )}
        <CustomInput
          mode={"inline"}
          label={t("hour_type")}
          disabled={hasTicket}
          name={"hour_type_id"}
          placeholder={t("hour_type")}
          error={
            !state.reservationForm.checkReservation
              ? false
              : !state.reservationForm.information.hour_type_id
          }
          onChange={async (e, d) => {
            await this.handleReservationChange({
              target: { name: "hour_type_id", value: e },
            });
            await this.handleReservationChange({
              target: {
                name: "price",
                value: d && d.data ? d.data.article_amount : "",
              },
            });
            let price_per_participant =
              d &&
              d.data &&
              nullStringNumber(d.data.article_amount) &&
              state.reservationForm.competition.participants_nb
                ? (
                    nullStringNumber(d.data.article_amount) /
                    state.reservationForm.competition.participants_nb
                  ).toFixed(2)
                : 0;
            if (!state.reservationForm.information.is_player)
              this.handleCompetition({
                target: {
                  name: "price_per_participant",
                  value: price_per_participant,
                },
              });
          }}
          options={hourTypesOptions}
          keyword={"value"}
          filter={"text"}
          fetching={false}
          loading={this.props.hourLoading}
          type={"custom-dropdown"}
          render={(it) => (
            <>
              <span>{it.text}</span>
            </>
          )}
          value={state.reservationForm.information.hour_type_id}
        />

        {
          <Grid verticalAlign={"middle"}>
            <Grid.Column width={4}>
              <label className={"label-modal"}>{t("amount")}</label>
            </Grid.Column>
            <Grid.Column width={5} className={"price-input"}>
              <Input
                type={"text"}
                name={"price"}
                disabled={true}
                onChange={this.handleReservationChange}
                value={
                  !state.reservationForm.information.price
                    ? ""
                    : state.reservationForm.information.price +
                      localStorage.getItem("currency")
                }
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <label className={"label-modal"}>{t("nb_game")}</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <Input
                min={1}
                type={"number"}
                name={"games_nb"}
                onChange={this.handleReservationChange}
                value={state.reservationForm.information.games_nb}
              />
            </Grid.Column>
          </Grid>
        }
        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("advance")}</label>
          </Grid.Column>
          <Grid.Column
            width={!state.reservationForm.information.is_advance ? 12 : 5}
          >
            <CustomDropdown
              url={"/articles"}
              className={"date-dropdown"}
              disabled={
                parseFloat(
                  state.reservationForm.information.advance_ticket.payed_amount
                ) > 0 || !this.checkForSession()
              }
              condition={(it) => {
                if (!state.reservationForm.information.price) return true;
                return (
                  parseFloat(it.total_price) <=
                  parseFloat(state.reservationForm.information.price)
                );
              }}
              placeholder={t("select_advance")}
              param={
                "&parent_slug=acompte&activity_id=" +
                state.reservationForm.information.activity_id
              }
              onChange={(e, d) => {
                if (e) {
                  this.handleAdvanceTicketChange(d.total_price, e, d);
                } else {
                  this.handleAdvanceTicketChange();
                }
              }}
              value={
                state.reservationForm.information.advance_ticket.article_id
              }
              render={(it) => (
                <>
                  <span>
                    {it.name +
                      " (" +
                      it.total_price +
                      localStorage.getItem("currency") +
                      ")"}
                  </span>
                </>
              )}
            />
            {!this.checkForSession() ? this.renderAlertForAdvance() : null}
          </Grid.Column>
          {state.reservationForm.information.is_advance === 1 && (
            <Grid.Column width={2} textAlign={"center"}>
              <label className={"label-modal"}>{t("type")}</label>
            </Grid.Column>
          )}
          {state.reservationForm.information.is_advance === 1 && (
            <Grid.Column width={5}>
              <CustomDropdown
                className={"date-dropdown regulation-input"}
                url={"/paymentTypes"}
                param={"&status=[0,2]"}
                condition={(it) =>
                  !(it.is_advance || it.is_subscription || it.is_credit)
                }
                error={
                  !state.reservationForm.checkReservation
                    ? false
                    : !state.reservationForm.information.advance_ticket.payments
                        .types[0].payment_type_id
                }
                disabled={
                  parseFloat(
                    state.reservationForm.information.advance_ticket
                      .payed_amount
                  ) > 0
                }
                placeholder={t("regulation")}
                onChange={(e, d) => {
                  this.handlePaymentTypeChange(e, d);
                }}
                value={
                  state.reservationForm.information.advance_ticket.payments
                    .types[0].payment_type_id
                }
                render={(it) => (
                  <>
                    <Image
                      className={it.icon ? "filter-img" : "no-filter-img"}
                      src={url + "/uploads/payments/" + it.icon}
                      type={"item"}
                    />
                    <span>{it.name}</span>
                  </>
                )}
              />
            </Grid.Column>
          )}
        </Grid>

        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("video")}</label>
          </Grid.Column>
          <Grid.Column width={12}>
            <CustomDropdown
              placeholder={t("video")}
              fetching={false}
              keyword={"value"}
              filter={"text"}
              value={state.reservationForm.information.cdn_video}
              onChange={(e, d) =>
                this.handleReservationDropdown(e, {
                  name: "cdn_video",
                  value: e,
                })
              }
              name={"cdn_video"}
              className={"date-dropdown"}
              render={(it) => (
                <>
                  <span>{it.text}</span>
                </>
              )}
              options={[
                { key: 0, text: t("yes"), value: 1 },
                { key: 1, text: t("no"), value: 0 },
              ]}
            />
          </Grid.Column>
        </Grid>
        {state.reservationForm.information.recurrence === 1 ? (
          <div className={"reccurence-planning"}>
            <Grid verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("recurrence")}</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <ReccurrenceComponent
                  error={
                    !state.reservationForm.checkReservation
                      ? false
                      : !state.reservationForm.information.recurrence_days
                          .length > 0
                  }
                  values={state.reservationForm.information.recurrence_days}
                  onChange={(val) => {
                    this.handleReservationChange({
                      target: { name: "recurrence_days", value: val },
                    });
                  }}
                />
                <Form.Group inline className={"choice_recurrence"}>
                  <Form.Radio
                    name={"choice_recurrence"}
                    label={"7 " + t("days")}
                    value={7}
                    checked={
                      nullString(
                        state.reservationForm.information.choice_recurrence
                      ) === "7"
                    }
                    onChange={() =>
                      this.handleReservationChange({
                        target: { name: "choice_recurrence", value: 7 },
                      })
                    }
                  />
                  <Form.Radio
                    name={"choice_recurrence"}
                    label={"15 " + t("days")}
                    value={15}
                    checked={
                      nullString(
                        state.reservationForm.information.choice_recurrence
                      ) === "15"
                    }
                    onChange={() =>
                      this.handleReservationChange({
                        target: { name: "choice_recurrence", value: 15 },
                      })
                    }
                  />
                </Form.Group>
              </Grid.Column>
            </Grid>

            <Grid verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("start")}</label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Input
                  error={
                    !state.reservationForm.checkReservation
                      ? false
                      : !state.reservationForm.information.start_date_rec
                  }
                  type={"date"}
                  name={"start_date_rec"}
                  onChange={this.handleReservationChange}
                  value={state.reservationForm.information.start_date_rec}
                />
              </Grid.Column>
              <Grid.Column width={2} textAlign={"center"}>
                <label className={"label-modal"}>{t("end")}</label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Input
                  error={
                    !state.reservationForm.checkReservation
                      ? false
                      : !state.reservationForm.information.end_date_rec
                  }
                  type={"date"}
                  name={"end_date_rec"}
                  onChange={this.handleReservationChange}
                  value={state.reservationForm.information.end_date_rec}
                />
              </Grid.Column>
            </Grid>
          </div>
        ) : null}

        {state.reservationForm.information.reservation_id && (
          <Grid verticalAlign={"middle"}>
            <Grid.Column width={4}>
              <label className={"label-modal"}>{t("network_code")}</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className={"code-input"}>
                {state.reservationForm.information.codeRe}
              </div>
            </Grid.Column>
            <Grid.Column width={2} textAlign={"center"}>
              <label className={"label-modal"}>Code video</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className={"code-input"}>
                {state.reservationForm.information.codeVideo}
              </div>
            </Grid.Column>
          </Grid>
        )}
      </div>
    );
  };

  /*
   *   Render the activity and terrain
   *   the Right side in reservation modal
   *
   * */
  renderRightSideForCaisse = () => {
    let { state, setState, t } = this.props;
    let {
      selectedCompetition,
      selectedActivity,
      selectedDuration,
    } = state.reservationForm;

    let teams = [],
      optionsRound = [
        { key: "round-opt-1/8", text: "1/8", value: "1/8" },
        { key: "round-opt-1/4", text: "1/4", value: "1/4" },
        { key: "round-opt-1/2", text: "1/2", value: "1/2" },
        { key: "round-opt-finale", text: "finale", value: "finale" },
      ],
      optionsDays = [];
    let days = 0;
    const hasTicket = this.hasTicket();
    if (selectedCompetition) {
      try {
        days = parseInt(selectedCompetition.days);
      } catch (e) {
        optionsDays.push({
          key: "jour-opt-" + 0,
          text: t("journee") + " " + 0,
          value: "0",
        });
      }

      for (let i = 1; i <= days; i++) {
        optionsDays.push({
          key: "jour-opt-" + i,
          text: t("journee") + " " + i,
          value: i,
        });
      }
    }

    return (
      <div className={"input-modal"}>
        <CustomInput
          fetching={true}
          label={t("terrain")}
          url={"/terrains"}
          param={
            "&search_activity=" + state.reservationForm.information.activity_id
          }
          render={(it) => (
            <>
              <span>{it.name}</span>
            </>
          )}
          placeholder={t("select_field")}
          error={
            !state.reservationForm.checkReservation
              ? false
              : !state.reservationForm.information.terrain_id
          }
          onChange={async (e, d) => {
            await this.handleReservationFormChange({
              target: { name: "selectedTerrain", value: d },
            });
            await this.handleReservationChange({
              target: { name: "end_hour", value: "" },
            });
            await this.handleReservationChange({
              target: { name: "start_hour", value: "" },
            });
            await this.handleReservationChange({
              target: { name: "terrain_id", value: e },
            });
            setState({ teams: this.props.teams });
            await this.onChangeTeam(
              e,
              { value: this.props.teams[0].id },
              1,
              this.props.teams[0]
            );
            await this.onChangeTeam(
              e,
              { value: this.props.teams[1].id },
              2,
              this.props.teams[1]
            );
          }}
          value={state.reservationForm.information.terrain_id}
          name={"terrain_id"}
          type={"custom-dropdown"}
        />

        <CustomInput
          disabled={true}
          label={t("activity")}
          error={
            !state.reservationForm.checkReservation
              ? false
              : !state.reservationForm.information.activity_id
          }
          url={"/activities"}
          param={
            "&search_terrain=" + state.reservationForm.information.activity_id
          }
          render={(it) => (
            <>
              <span>{it.name}</span>
            </>
          )}
          onInitial={(d) => {
            this.handleReservationFormChange({
              target: { name: "selectedActivity", value: d },
            });
            // this.handleReservationFormChange({ target: { name: 'selectedCompetition', value: null } });
          }}
          placeholder={t("select_activity")}
          onChange={(e, d) => {
            this.handleReservationFormChange({ name: "activity_id", value: e });
          }}
          value={state.reservationForm.information.activity_id}
          name={"activity_id"}
          type={"custom-dropdown"}
        />

        {state.reservationForm.competition.competition_id && selectedActivity && (
          <CustomInput
            mode={"inline"}
            name={"competition_id"}
            disabled={true}
            label={t("competition")}
            placeholder={t("select_competition")}
            onChange={async (e, d) => {
              selectedCompetition = d;

              if (selectedCompetition) {
                teams = selectedCompetition.teams;
              } else {
                teams = [];
              }

              if (teams.length < 2 && this.props.teams.length >= 2) {
                for (let i = 0; i < 2; i++) {
                  await teams.push(this.props.teams[i]);
                }
              }

              await this.handleCompetitionDropdown(e, {
                name: "round",
                value: "",
              });
              await this.handleCompetitionDropdown(e, {
                name: "competition_day",
                value: "",
              });
              await this.handleCompetitionDropdown(e, {
                name: "competition_id",
                value: e,
              });
              await this.handleReservationFormChange({
                target: { name: "selectedCompetition", value: d },
              });
              await setState({ teams });
              if (teams.length >= 2) {
                await this.onChangeTeam(e, { value: teams[0].id }, 1, teams[0]);
                await this.onChangeTeam(e, { value: teams[1].id }, 2, teams[1]);
              }
            }}
            fetching={false}
            options={selectedCompetition ? [selectedCompetition] : []}
            error={false}
            type={"custom-dropdown"}
            filter={"name"}
            render={(it) => (
              <>
                <span>{it.name}</span>
              </>
            )}
            value={state.reservationForm.competition.competition_id}
          />
        )}

        {state.reservationForm.competition.competition_id && days ? (
          <CustomInput
            disabled={true}
            mode={"inline"}
            label={t("journee")}
            name={"competition_day"}
            placeholder={t("journee")}
            error={
              !state.reservationForm.checkReservation
                ? false
                : !state.reservationForm.competition.competition_day
            }
            onChange={(e, d) =>
              this.handleCompetitionDropdown(e, {
                name: "competition_day",
                value: e,
              })
            }
            options={optionsDays}
            type={"custom-dropdown"}
            fetching={false}
            keyword={"value"}
            fitler={"text"}
            render={(it) => (
              <>
                <span>{it.text}</span>
              </>
            )}
            value={state.reservationForm.competition.competition_day}
          />
        ) : (
          false &&
          state.reservationForm.competition.competition_id !== null &&
          state.reservationForm.competition.competition_id !== "" && (
            <CustomInput
              mode={"inline"}
              label={t("round")}
              name={"round"}
              placeholder={t("round")}
              onChange={(e, d) =>
                this.handleCompetitionDropdown(e, { name: "round", value: e })
              }
              options={optionsRound}
              fetching={false}
              keyword={"value"}
              fitler={"text"}
              render={(it) => (
                <>
                  <span>{it.text}</span>
                </>
              )}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.competition.round
              }
              type={"dropdown"}
              value={state.reservationForm.competition.round}
            />
          )
        )}
        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("duration")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <CustomDropdown
              keyword={"id"}
              direction={0}
              disabled={true}
              className={"date-dropdown"}
              filter={"duration"}
              name={"duration_id"}
              fetching={true}
              url={"/durations"}
              onInitial={(d) =>
                this.handleReservationFormChange({
                  target: { name: "selectedDuration", value: d },
                })
              }
              placeholder={t("duration")}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.information.duration_id
              }
              render={(it) => (
                <>
                  <span>{it.duration}</span>
                </>
              )}
              onChange={async (e, d) => {
                let duration = d;
                let finDate = "";

                try {
                  let minute = duration.duration.split(":");
                  minute[0] = parseInt(minute[0]) * 60;
                  minute[1] = parseInt(minute[1]) + minute[0];

                  finDate = await moment(
                    formattingDate(state.date) +
                      "T" +
                      state.reservationForm.information.start_hour +
                      ":00+00:00"
                  )
                    .utc()
                    .add(minute[1], "minute")
                    .format("HH:mm");
                } catch (e) {
                  //
                }
                this.handleReservationChange({
                  target: { name: "duration_id", value: e },
                });

                this.handleReservationChange({
                  target: { name: "end_hour", value: finDate },
                });
              }}
              value={state.reservationForm.information.duration_id}
            />
          </Grid.Column>
          <Grid.Column width={2} textAlign={"center"}>
            <label className={"label-modal"}>{t("date")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <Input
              type={"date"}
              name={"date"}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.information.date
              }
              onChange={this.handleReservationChange}
              value={state.reservationForm.information.date}
            />
          </Grid.Column>
        </Grid>

        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("start_hour")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <TimeInput
              // disabled={true}
              direction={0}
              loading={this.props.availableHourLoading}
              allowedHours={this.props.availableHours}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.information.start_hour
              }
              name={"start_hour"}
              onChange={async (v) => {
                let value = state.reservationForm.information.duration_id;
                if (value && selectedDuration) {
                  let duration = [selectedDuration];
                  let finDate = "";
                  try {
                    let minute = duration[0].duration.split(":");
                    minute[0] = parseInt(minute[0]) * 60;
                    minute[1] = parseInt(minute[1]) + minute[0];

                    finDate = await moment(
                      formattingDate(state.date) + "T" + v + ":00+00:00"
                    )
                      .utc()
                      .add(minute[1], "minute")
                      .format("HH:mm");
                  } catch (e) {
                    //
                  }
                  await this.handleReservationChange({
                    target: { name: "end_hour", value: finDate },
                  });
                }

                this.handleReservationChange({
                  target: { name: "start_hour", value: v },
                });
              }}
              value={state.reservationForm.information.start_hour}
            />
          </Grid.Column>
          <Grid.Column width={2} textAlign={"center"}>
            <label className={"label-modal"}>{t("end_hour")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <TimeInput
              disabled={true}
              error={
                !state.reservationForm.checkReservation
                  ? false
                  : !state.reservationForm.information.end_hour
              }
              name={"end_hour"}
              onChange={this.handleReservationChange}
              value={state.reservationForm.information.end_hour}
            />
          </Grid.Column>
        </Grid>
        <CustomInput
          mode={"inline"}
          label={t("hour_type")}
          disabled={true}
          url={"/hourTypes"}
          name={"hour_type_id"}
          placeholder={t("hour_type")}
          error={
            !state.reservationForm.checkReservation
              ? false
              : !state.reservationForm.information.hour_type_id
          }
          onChange={(e, d) => {
            this.handleReservationDropdown(e, {
              name: "hour_type_id",
              value: e,
            });
            this.handleReservationChange({
              target: { name: "price", value: d ? d.article_amount : "" },
            });
          }}
          fetching={true}
          type={"custom-dropdown"}
          render={(it) => (
            <>
              <span>{it.name}</span>
            </>
          )}
          value={state.reservationForm.information.hour_type_id}
        />

        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("advance")}</label>
          </Grid.Column>
          <Grid.Column
            width={!state.reservationForm.information.is_advance ? 12 : 5}
          >
            <CustomDropdown
              url={"/articles"}
              className={"date-dropdown"}
              disabled={
                parseFloat(
                  state.reservationForm.information.advance_ticket.payed_amount
                ) > 0 || !this.checkForSession()
              }
              placeholder={t("select_advance")}
              param={
                "&parent_slug=acompte&activity_id=" +
                state.reservationForm.information.activity_id
              }
              onChange={(e, d) => {
                if (e) {
                  this.handleAdvanceTicketChange(d.total_price, e, d);
                } else {
                  this.handleAdvanceTicketChange();
                }
              }}
              value={
                state.reservationForm.information.advance_ticket.article_id
              }
              render={(it) => (
                <>
                  <span>
                    {it.name +
                      " (" +
                      it.total_price +
                      localStorage.getItem("currency") +
                      ")"}
                  </span>
                </>
              )}
            />
            {!this.checkForSession() ? this.renderAlertForAdvance() : null}
          </Grid.Column>
          {state.reservationForm.information.is_advance === 1 && (
            <Grid.Column width={2} textAlign={"center"}>
              <label className={"label-modal"}>{t("type")}</label>
            </Grid.Column>
          )}
          {state.reservationForm.information.is_advance === 1 && (
            <Grid.Column width={5}>
              <CustomDropdown
                className={"date-dropdown regulation-input"}
                url={"/paymentTypes"}
                param={"&status=[0,2]"}
                condition={(it) =>
                  !(it.is_advance || it.is_subscription || it.is_credit)
                }
                error={
                  !state.reservationForm.checkReservation
                    ? false
                    : !state.reservationForm.information.advance_ticket.payments
                        .types[0].payment_type_id
                }
                disabled={
                  parseFloat(
                    state.reservationForm.information.advance_ticket
                      .payed_amount
                  ) > 0
                }
                placeholder={t("select_payment_type")}
                onChange={(e, d) => {
                  this.handlePaymentTypeChange(e, d);
                }}
                value={
                  state.reservationForm.information.advance_ticket.payments
                    .types[0].payment_type_id
                }
                render={(it) => (
                  <>
                    <Image
                      className={it.icon ? "filter-img" : "no-filter-img"}
                      src={url + "/uploads/payments/" + it.icon}
                      type={"item"}
                    />
                    <span>{it.name}</span>
                  </>
                )}
              />
            </Grid.Column>
          )}
        </Grid>

        <Grid verticalAlign={"middle"}>
          <Grid.Column width={4}>
            <label className={"label-modal"}>{t("recurrence")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <CustomDropdown
              fetching={false}
              keyword={"value"}
              filter={"text"}
              placeholder={t("recurrence")}
              className={"date-dropdown"}
              selection={true}
              value={state.reservationForm.information.recurrence}
              onChange={(e, d) =>
                this.handleReservationDropdown(e, {
                  name: "recurrence",
                  value: e,
                })
              }
              name={"recurrence"}
              render={(it) => (
                <>
                  <span>{it.text}</span>
                </>
              )}
              options={[
                { key: 0, text: t("yes"), value: 1 },
                { key: 1, text: t("no"), value: 0 },
              ]}
            />
          </Grid.Column>

          <Grid.Column width={2} textAlign={"center"}>
            <label className={"label-modal"}>{t("video")}</label>
          </Grid.Column>
          <Grid.Column width={5}>
            <CustomDropdown
              placeholder={t("video")}
              fetching={false}
              keyword={"value"}
              filter={"text"}
              value={state.reservationForm.information.cdn_video}
              onChange={(e, d) =>
                this.handleReservationDropdown(e, {
                  name: "cdn_video",
                  value: e,
                })
              }
              name={"cdn_video"}
              className={"date-dropdown"}
              render={(it) => (
                <>
                  <span>{it.text}</span>
                </>
              )}
              options={[
                { key: 0, text: t("yes"), value: 1 },
                { key: 1, text: t("no"), value: 0 },
              ]}
            />
          </Grid.Column>
        </Grid>
        {state.reservationForm.information.recurrence === 1 ? (
          <div className={"reccurence-planning"}>
            <Grid verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("recurrence")}</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <ReccurrenceComponent
                  error={
                    !state.reservationForm.checkReservation
                      ? false
                      : !state.reservationForm.information.recurrence_days
                          .length
                  }
                  values={state.reservationForm.information.recurrence_days}
                  onChange={(val) => {
                    this.handleReservationChange({
                      target: { name: "recurrence_days", value: val },
                    });
                  }}
                />
                <Form.Group inline className={"choice_recurrence"}>
                  <Form.Radio
                    name={"choice_recurrence"}
                    label={"7 " + t("days")}
                    value={7}
                    checked={
                      nullString(
                        state.reservationForm.information.choice_recurrence
                      ) === "7"
                    }
                    onChange={() =>
                      this.handleReservationChange({
                        target: { name: "choice_recurrence", value: 7 },
                      })
                    }
                  />
                  <Form.Radio
                    name={"choice_recurrence"}
                    label={"15 " + t("days")}
                    value={15}
                    checked={
                      nullString(
                        state.reservationForm.information.choice_recurrence
                      ) === "15"
                    }
                    onChange={() =>
                      this.handleReservationChange({
                        target: { name: "choice_recurrence", value: 15 },
                      })
                    }
                  />
                </Form.Group>
              </Grid.Column>
            </Grid>

            <Grid verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("start")}</label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Input
                  error={
                    !state.reservationForm.checkReservation
                      ? false
                      : !state.reservationForm.information.start_date_rec
                  }
                  type={"date"}
                  name={"start_date_rec"}
                  onChange={this.handleReservationChange}
                  value={state.reservationForm.information.start_date_rec}
                />
              </Grid.Column>
              <Grid.Column width={2} textAlign={"center"}>
                <label className={"label-modal"}>{t("end")}</label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Input
                  error={
                    !state.reservationForm.checkReservation
                      ? false
                      : !state.reservationForm.information.end_date_rec
                  }
                  type={"date"}
                  name={"end_date_rec"}
                  onChange={this.handleReservationChange}
                  value={state.reservationForm.information.end_date_rec}
                />
              </Grid.Column>
            </Grid>
          </div>
        ) : null}

        {state.reservationForm.information.reservation_id && (
          <Grid verticalAlign={"middle"}>
            <Grid.Column width={4}>
              <label className={"label-modal"}>{t("network_code")}</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className={"code-input"}>
                {state.reservationForm.information.codeRe}
              </div>
            </Grid.Column>
            <Grid.Column width={2} textAlign={"center"}>
              <label className={"label-modal"}>Code video</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className={"code-input"}>
                {state.reservationForm.information.codeVideo}
              </div>
            </Grid.Column>
          </Grid>
        )}
      </div>
    );
  };

  checkForSession = () => {
    let { session } = this.props;
    if (!session || nullString(session.status) === "1") return false;
    else return true;
  };

  renderAlertForAdvance = () => {
    let { t } = this.props;
    return (
      <Label prompt pointing>
        {t("msg-checkout-open-session-advance")}
      </Label>
    );
  };
  /**
   * handle conclusion
   * */
  handleConclusion = (conclusion) => {
    let { state, setState } = this.props;
    let reservationForm = state.reservationForm;
    let information = reservationForm.information;

    information.conclusion = conclusion;
    reservationForm = { ...reservationForm, information };
    setState({
      reservationForm: reservationForm,
    });
  };

  /**
   * handle change in user information
   * */
  handleClientReservationChange = (e, eventMobile = null) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let { information, competition } = state.reservationForm;
    let { user } = information;
    let newReservationForm = {
      ...reservationForm,
      information: {
        ...information,
        user: { ...user, [e.target.name]: e.target.value },
      },
      competition: { ...competition },
    };

    if (e.target.name === "lastName") {
      newReservationForm.competition.group_name =
        "Groupe " + nullString(e.target.value);
    }

    if (eventMobile && eventMobile.target) {
      newReservationForm.information.user[eventMobile.target.name] =
        eventMobile.target.value;
    }

    setState({ reservationForm: newReservationForm });
  };

  /**
   * handle client change in reservation modal
   * */
  handleClientChange = (index, it, type = "client") => {
    let { state, setState } = this.props;
    if (it !== null) {
      let {
        firstName,
        lastName,
        info,
        birthdate,
        email,
        mobile,
        company,
        avatar,
      } = it;
      let { reservationForm } = state;
      let information = { ...reservationForm.information };

      //remove old participant
      let participants = { ...reservationForm.participants };

      let players1 = participants.equipe1.players.filter(
        (itm) =>
          this.isPlayerEmpty(itm) ||
          (itm.user_id !== information.user.user_id &&
            (nullString(itm.mobile) !== nullString(information.user.mobile) ||
              nullString(itm.email) !== nullString(information.user.email)))
      );
      let players2 = participants.equipe2.players.filter(
        (itm) =>
          this.isPlayerEmpty(itm) ||
          (itm.user_id !== information.user.user_id &&
            (nullString(itm.mobile) !== nullString(information.user.mobile) ||
              nullString(itm.email) !== nullString(information.user.email)))
      );

      let deleteFrom =
        participants.equipe1.players.length > players1.length
          ? 1
          : participants.equipe2.players.length > players2.length
          ? 2
          : 0;

      let user = {
        ...information.user,
        user_id: type === "client" ? index : it.user_id,
        contact_id: type === "client" ? null : index,
        firstName: nullString(type === "client" ? firstName : it.first_name),
        lastName: nullString(type === "client" ? lastName : it.last_name),
        info: nullString(info),
        birthdate: birthdate ? formattingDate(birthdate) : "",
        email: nullString(email),
        lastEmail: nullString(email),
        mobile: nullString(mobile),
        company: nullString(company),
        avatar: avatar,
        join_user_id: nullString(it.join_user_id),
        join_complex_id: nullString(it.join_complex_id),
        ask_to_join: !!nullString(it.join_user_id),
      };

      if (deleteFrom === 1) {
        players1 = [{ ...player }, ...players1];
      }
      if (deleteFrom === 2) {
        players2 = [{ ...player }, ...players2];
      }

      participants = {
        equipe1: {
          ...participants.equipe1,
          players: players1,
        },
        equipe2: {
          ...participants.equipe2,
          players: players2,
        },
      };

      information = {
        ...information,
        show: true,
        user,
      };

      reservationForm.competition.group_name = "Groupe " + nullString(lastName);
      reservationForm = { ...reservationForm, information, participants };
      setState({ reservationForm }, () => this.autoFill(0));
    } else {
      let firstName = "",
        lastName = "",
        info = "",
        birthdate = "",
        email = "",
        mobile = "",
        company = "",
        avatar = "",
        user_id = null;
      let { reservationForm } = state;
      let information = { ...reservationForm.information };

      //remove old participant
      let participants = { ...reservationForm.participants };

      let players1 = participants.equipe1.players.filter(
        (itm) =>
          this.isPlayerEmpty(itm) ||
          (itm.user_id !== information.user.user_id &&
            (nullString(itm.mobile) !== nullString(information.user.mobile) ||
              nullString(itm.email) !== nullString(information.user.email)))
      );

      let players2 = participants.equipe2.players.filter(
        (itm) =>
          this.isPlayerEmpty(itm) ||
          (itm.user_id !== information.user.user_id &&
            (nullString(itm.mobile) !== nullString(information.user.mobile) ||
              nullString(itm.email) !== nullString(information.user.email)))
      );

      let deleteFrom =
        participants.equipe1.players.length > players1.length
          ? 1
          : participants.equipe2.players.length > players2.length
          ? 2
          : 0;

      information = {
        ...information,
        show: true,
        user: {
          user_id:
            type === "contact" ? information.user.user_id.toString() : null,
          contact_id: null,
          firstName: nullString(firstName),
          lastName: nullString(lastName),
          info: nullString(info),
          birthdate: birthdate ? formattingDate(birthdate) : "",
          email,
          lastEmail: email,
          mobile: nullString(mobile),
          company: nullString(company),
          avatar: avatar,
          join_user_id: null,
          join_complex_id: null,
          ask_to_join: false,
        },
      };

      if (deleteFrom === 1) {
        players1 = [{ ...player }, ...players1];
      }
      if (deleteFrom === 2) {
        players2 = [{ ...player }, ...players2];
      }

      participants = {
        equipe1: {
          ...participants.equipe1,
          players: players1,
        },
        equipe2: {
          ...participants.equipe2,
          players: players2,
        },
      };

      reservationForm.competition.group_name = "Groupe " + nullString(lastName);
      reservationForm = { ...reservationForm, information, participants };
      setState({ reservationForm }, () => this.autoFill(0));
    }
  };

  /**
   *
   *  auto fill the participant with reservation creator
   * @returns {void}
   */
  autoFill = (index = null) => {
    let { state, setState, type } = this.props;
    // let hasTeam = state.reservationForm.information.competition.competition_id !== '';
    let { selectedTerrain, selectedActivity } = state.reservationForm;

    // selectedTerrains = state.terrains.filter(
    //   (it) => it.id.toString() === state.reservationForm.information.terrain_id.toString(),
    // );

    if (!selectedActivity) return;

    let isMatch = selectedActivity.is_match === 1;
    let totalNb = state.reservationForm
      ? state.reservationForm.competition.participants_nb
      : 1;

    if (
      state.reservationForm.information.activity_id !== null &&
      selectedTerrain
    )
      if (
        selectedActivity &&
        (selectedActivity.is_match === 0 ||
          !state.reservationForm.competition_id ||
          type === "leisure")
      ) {
        let user = state.reservationForm.information.user;
        // check empty
        let nb = -1;
        state.reservationForm.participants.equipe1.players.forEach((it, j) => {
          if (
            it.user_id === player.user_id &&
            it.contact_id === player.contact_id &&
            it.mobile === player.mobile &&
            it.email === player.email &&
            it.firstName === player.firstName &&
            it.lastName === player.lastName &&
            nb === -1
          )
            nb = j;
        });
        // check if user exist in the tab
        let userIndex1 = -1;
        let userIndex2 = -1;
        state.reservationForm.participants.equipe1.players.forEach((it, i) => {
          if (
            it.user_id === user.user_id &&
            (it.contact_id && user.contact_id
              ? it.contact_id === user.contact_id
              : true)
          )
            userIndex1 = i;
        });

        state.reservationForm.participants.equipe2.players.forEach((it, i) => {
          if (
            it.user_id === user.user_id &&
            (it.contact_id && user.contact_id
              ? it.contact_id === user.contact_id
              : true)
          )
            userIndex2 = i;
        });
        // if user exist we dont update the user
        // and we didnt update any of the user information

        let oldPlayer = { ...player };

        if (userIndex1 !== -1) {
          oldPlayer = {
            ...state.reservationForm.participants.equipe1.players[userIndex1],
          };
          state.reservationForm.participants.equipe1.players.splice(
            userIndex1,
            1
          );
        }

        if (userIndex2 !== -1) {
          oldPlayer = {
            ...state.reservationForm.participants.equipe2.players[userIndex2],
          };
          state.reservationForm.participants.equipe2.players.splice(
            userIndex2,
            1
          );
        }

        if (nb === -1) {
          nb = state.reservationForm.participants.equipe1.players.length;
        }

        let { reservationForm } = state;
        let participants = { ...reservationForm.participants };

        let p = { ...oldPlayer };
        p.contact_id = user.contact_id;
        p.user_id = user.user_id;
        p.firstName = user.firstName;
        p.lastName = user.lastName;
        p.email = user.email;
        p.mobile = user.mobile;
        p.country_code = user.country_code;
        p.disabled = true;

        participants.equipe1.players = [p, ...participants.equipe1.players];

        // if (nb <= 1) participants.equipe1.players[0] = p;
        let nbPerTeam1 = isMatch
          ? totalNb > 1
            ? totalNb - parseInt(totalNb / 2)
            : 1
          : totalNb;
        let nbPerTeam2 = isMatch
          ? nb > 1
            ? parseInt(totalNb / 2)
            : 1
          : totalNb;

        if (type !== "leisure") {
          if (participants.equipe1.players.length > nbPerTeam1) {
            if (
              this.isPlayerEmpty(
                participants.equipe1.players[
                  participants.equipe1.players.length - 1
                ]
              )
            )
              participants.equipe1.players.pop();
          }
        } else {
          if (
            participants.equipe1.players.length !==
            parseInt(state.reservationForm.competition.participants_nb)
          ) {
            resize(
              participants.equipe1.players,
              parseInt(state.reservationForm.competition.participants_nb)
            );
          }
        }

        let information = { ...reservationForm.information };
        if (type === "leisure") {
          let games = [...information.games];
          games[0].start_hour = information.start_hour;
          games[0].end_hour = information.end_hour;
          games[0].date = information.date;
          games[0].reservation_id = information.reservation_id;
          information.games = [...games];
        }

        setState({
          reservationForm: { ...reservationForm, participants, information },
        });
      }
  };

  handleSubmit = debounce(async () => {
    this.refBtn.current.disabled = true;

    let { setState, state } = this.props;

    let selectedTerrains = [];
    let selectedActivity = [];
    let isMatch = 0;

    if (this.props.type === "caisse") {
      if (state.reservationForm.selectedTerrain)
        selectedTerrains = [state.reservationForm.selectedTerrain];
    } else
      selectedTerrains = state.terrains.filter(
        (it) =>
          it.id.toString() ===
          state.reservationForm.information.terrain_id.toString()
      );
    if (
      state.reservationForm.information.activity_id &&
      selectedTerrains.length > 0
    )
      selectedActivity = selectedTerrains[0].activities.filter(
        (it, i) => it.id === state.reservationForm.information.activity_id
      );

    if (selectedActivity.length > 0) isMatch = selectedActivity[0].is_match;

    await this.autoFill();
    let checkInformation = this.checkInformation();
    let checkParticipant = this.checkParticipant(isMatch);
    let checkGames = true;
    if (this.props.type === "leisure") checkGames = this.checkGames();

    if (checkInformation && checkParticipant.isValid && checkGames) {
      // check if user does exist
      if (
        !state.reservationForm.information.user.user_id &&
        !state.reservationForm.information.user.ask_to_join
      ) {
        let checkResponse = await checkIsAvailable({
          email: state.reservationForm.information.user.email,
          country_code: state.reservationForm.information.user.country_code,
          mobile: reversePhoneNumber(
            state.reservationForm.information.user.mobile
          ),
        });
        if (
          checkResponse.data &&
          checkResponse.data.data &&
          !Array.isArray(checkResponse.data.data) &&
          checkResponse.data.data.complexes.filter(
            (it) => it.id.toString() === localStorage.getItem("complex_id")
          ).length === 0
        ) {
          setState({ joinModal: true, old: checkResponse.data.data });
        } else {
          this.props.onSubmit(isMatch);
        }
      } else {
        this.props.onSubmit(isMatch);
      }
    } else {
      if (!checkInformation) setState({ selectedIndex: 0 });
      else if (!checkParticipant.isValid)
        setState({ selectedIndex: 1, teamIndex: checkParticipant.index });
      else if (!checkGames) setState({ selectedIndex: 2 });
      this.setState({ loadingCheck: false });
      this.refBtn.current.disabled = false;
    }
  }, 500);

  switchPlayer = (index, teams = 1) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let participants = { ...reservationForm.participants };

    let team1 = [...participants.equipe1.players];
    let team2 = [...participants.equipe2.players];
    if (teams === 1) {
      this.switchHandler(team1, team2, index);
    } else {
      this.switchHandler(team2, team1, index);
    }

    participants.equipe1.players = [...team1];
    participants.equipe2.players = [...team2];

    setState({ reservationForm: { ...reservationForm, participants } });
  };

  switchHandler = (team1, team2, index) => {
    let nbs = this.getNbPerTeam();
    let isChanged = false;
    let selectedPlayer = { ...team1[index] };
    for (let i = 0; i < team2.length; i++) {
      if (!isChanged && this.isPlayerEmpty(team2[i])) {
        team2[i].user_id = selectedPlayer.user_id;
        team2[i].contact_id = selectedPlayer.contact_id;
        team2[i].firstName = selectedPlayer.firstName;
        team2[i].lastName = selectedPlayer.lastName;
        team2[i].mobile = selectedPlayer.mobile;
        team2[i].email = selectedPlayer.email;
        team2[i].disabled = selectedPlayer.disabled;
        team2[i].removable = selectedPlayer.removable;
        isChanged = true;
      }
    }
    if (!isChanged) team2.push(selectedPlayer);

    // delete from team1
    team1.splice(index, 1);
    if (team1.length < nbs[index - 1]) team1.push({ ...player });
  };

  /**
   * Handle Search User Participant from participant Tab
   * @param e
   * @param index
   * @param team
   * @param type
   */
  handleSearchParticipantChange = (e, index, team, type = "client") => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let firstName = "",
      lastName = "",
      email = "",
      mobile = "",
      user_id = null;
    if (e) {
      firstName = e.firstName;
      lastName = e.lastName;
      email = e.email;
      mobile = e.mobile;
      user_id = e.id;
    }
    let { participants } = state.reservationForm;
    let equipe = team === 1 ? participants.equipe1 : participants.equipe2;

    equipe.players[index] = {
      ...equipe.players[index],
      firstName: type === "client" ? firstName : e.first_name,
      lastName: type === "client" ? lastName : e.last_name,
      email,
      mobile,
      user_id: type === "client" ? user_id : equipe.players[index].user_id,
      contact_id: type === "client" ? null : user_id,
    };
    participants =
      team === 1
        ? { ...participants, equipe1: equipe }
        : { ...participants, equipe2: equipe };
    reservationForm = { ...reservationForm, participants };
    setState({ reservationForm: reservationForm });
  };

  handleParticipantChange = (e, index, team, mobileEvent = null) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let { participants } = state.reservationForm;
    let equipe = team === 1 ? participants.equipe1 : participants.equipe2;
    if (equipe.players < index) equipe.players.push({ ...player });

    equipe.players[index] = {
      ...equipe.players[index],
      [e.target.name]:
        typeof e.target.value === "string"
          ? e.target.value.trimStart()
          : e.target.value,
    };
    if (mobileEvent && mobileEvent.target)
      equipe.players[index][mobileEvent.target.name] =
        typeof mobileEvent.target.value === "string"
          ? mobileEvent.target.value.trimStart()
          : mobileEvent.target.value;
    participants =
      team === 1
        ? { ...participants, equipe1: equipe }
        : { ...participants, equipe2: equipe };
    reservationForm = { ...reservationForm, participants };
    setState({ reservationForm: reservationForm });
  };

  /*
   *   Render Participants Modals
   *
   * */
  renderParticipantsModal = () => {
    let { state, setState, t } = this.props;
    let { terrains } = state;
    let teamOptions = [];
    let competition = state.reservationForm.competition;
    let hasTeam = !!state.reservationForm.competition.competition_id,
      selectedTerrains = [],
      selectedActivity = [],
      selectedCompetition = [];
    let img = "";
    let image1 = "",
      image2 = "",
      team1 = " --- ",
      team2 = "---",
      indexTeam1 = 0,
      indexTeam2 = 0;
    selectedTerrains =
      this.props.type === "caisse"
        ? [state.reservationForm.selectedTerrain]
        : terrains.filter(
            (it) =>
              it.id.toString() ===
              state.reservationForm.information.terrain_id.toString()
          );
    if (
      state.reservationForm.information.activity_id &&
      selectedTerrains &&
      selectedTerrains.length > 0 &&
      selectedTerrains[0] !== null
    ) {
      selectedActivity = selectedTerrains[0].activities.filter(
        (it, i) => it.id === state.reservationForm.information.activity_id
      );
    }
    //
    // if (selectedActivity.length > 0 && selectedActivity[0].hasOwnProperty('competitions')) {
    //   selectedCompetition = selectedActivity[0].competitions.filter(
    //     (it) => it.id === state.reservationForm.competition.competition_id,
    //   );
    // }

    if (hasTeam && competition.teams.length > 0) {
      teamOptions = competition.teams.map((it, i) => ({
        key: "tea-opt-" + i,
        text: it.name,
        value: it.id,
      }));
      if (competition.teams.length > 0) {
        for (let i = 0; i < competition.teams.length; i++) {
          if (
            state.reservationForm.participants.equipe1.id.toString() ===
            competition.teams[i].id.toString()
          ) {
            image1 = competition.teams[i].avatar
              ? imgUrl +
                "/teams/" +
                localStorage.getItem("complex_id") +
                "/" +
                competition.teams[i].avatar
              : img;
            team1 = competition.teams[i].name;
            indexTeam1 = i;
          }

          if (
            state.reservationForm.participants.equipe2.id.toString() ===
            competition.teams[i].id.toString()
          ) {
            image2 = competition.teams[i].avatar
              ? imgUrl +
                "/teams/" +
                localStorage.getItem("complex_id") +
                "/" +
                competition.teams[i].avatar
              : img;
            team2 = competition.teams[i].name;
            indexTeam2 = i;
          }
        }
      }
    } else if (
      selectedActivity.length > 0 &&
      selectedActivity[0].is_match === 1 &&
      state.teams.length > 0
    ) {
      for (let i = 0; i < state.teams.length; i++) {
        if (!isEmpty(state.teams[i])) {
          if (
            nullString(
              state.reservationForm.participants.equipe1.id
            ).toString() === state.teams[i].id.toString()
          ) {
            image1 =
              imgUrl +
              "/teams/" +
              localStorage.getItem("complex_id") +
              "/" +
              state.teams[i].avatar;
            team1 = state.teams[i].name;
            indexTeam1 = i;
          }

          if (
            nullString(
              state.reservationForm.participants.equipe2.id
            ).toString() === state.teams[i].id.toString()
          ) {
            image2 =
              imgUrl +
              "/teams/" +
              localStorage.getItem("complex_id") +
              "/" +
              state.teams[i].avatar;
            team2 = state.teams[i].name;
            indexTeam2 = i;
          }
        }
      }
    }

    let nbs = this.getNbPerTeam();
    // let teamSize = (this.props.isCaisse && this.props.article) ? 5 : this.props.article.default_participants_nb > 5 ? 5 : this.props.article.default_participants_nb;

    return selectedActivity.length > 0 && selectedActivity[0].is_match === 1 ? (
      <Grid columns={16} className={"padding-modal"} padded={false}>
        <CustomTab
          renderActiveOnly={false}
          onTabChange={(ev, data) => {
            setState({ teamIndex: data.activeIndex });
          }}
          activeIndex={state.teamIndex}
          cssClassName={"modal-tab modal-tab-player"}
          panes={[
            {
              menuItem: team1,
              pane: {
                key: 1,
                content: (
                  <Grid.Row className={"padding-modal"}>
                    <Grid.Column
                      computer={16}
                      className={
                        "no-padding-left border-bottom no-padding-right"
                      }
                    >
                      <Grid columns={16} className={"padding-modal"}>
                        {hasTeam ? (
                          <Grid.Row
                            verticalAlign={"middle"}
                            className={"center-row"}
                          >
                            <Grid.Column width={2}>
                              <Image
                                type={"item"}
                                src={image1}
                                className={"modal-team-logo"}
                              />
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <CustomDropdown
                                keyword={"value"}
                                fetching={false}
                                disabled={true}
                                error={
                                  state.reservationForm.participants.equipe1
                                    .id === 0
                                }
                                loading={state.isLoading}
                                value={
                                  state.reservationForm.participants.equipe1.id
                                }
                                options={teamOptions}
                                render={(it) => {
                                  return <span>{it.text}</span>;
                                }}
                                onChange={(e, d) =>
                                  this.onChangeTeam(
                                    e,
                                    d,
                                    1,
                                    state.teams[indexTeam1]
                                  )
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        ) : (
                          <Grid.Row
                            verticalAlign={"middle"}
                            className={"center-row"}
                          >
                            <Grid.Column width={2} textAlign={"center"}>
                              <Image
                                type={"item"}
                                alt={"team2"}
                                src={image1}
                                className={"modal-team-logo"}
                              />
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <CustomDropdown
                                url={"/teams"}
                                disabled={
                                  state.reservationForm.information.ticket &&
                                  (state.reservationForm.information.ticket
                                    .status === 2 ||
                                    state.reservationForm.information.ticket
                                      .status === 1)
                                }
                                placeholder={t("select-a-team")}
                                error={
                                  state.reservationForm.participants.equipe1
                                    .id === 0
                                }
                                value={
                                  state.reservationForm.participants.equipe1
                                    .id === 0
                                    ? null
                                    : state.reservationForm.participants.equipe1
                                        .id
                                }
                                onInitial={(t) => {
                                  if (
                                    !isEmpty(t) &&
                                    !state.teams.some(
                                      (itm) =>
                                        itm.hasOwnProperty("id") &&
                                        itm.id === t.id
                                    )
                                  )
                                    setState({
                                      teams: [...state.teams, t],
                                    });
                                }}
                                condition={(it) => {
                                  return (
                                    state.reservationForm.participants.equipe2
                                      .id !== it.id
                                  );
                                }}
                                onChange={(e, d) =>
                                  this.onChangeTeam(e, { value: e }, 1, d)
                                }
                                render={(item) => {
                                  return (
                                    <>
                                      <span>{item.name}</span>
                                    </>
                                  );
                                }}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        )}
                        {state.reservationForm.participants.equipe1.players.map(
                          (pl, i) => {
                            return (
                              <Grid.Row
                                verticalAlign={"top"}
                                key={`equipe-1-row-player-${i}`}
                                className={"border-bottom no-padding-right"}
                              >
                                <React.Fragment key={`equipe-1-player-${i}`}>
                                  <Grid.Column
                                    width={4}
                                    className={"no-padding-left "}
                                  >
                                    {pl.removable && (
                                      <Icon
                                        color={"red"}
                                        name={"trash"}
                                        className={"remove-player"}
                                        onClick={() => this.removePlayer(i, 1)}
                                      />
                                    )}
                                    <label
                                      className={
                                        i >= nbs[0]
                                          ? "label-modal no-padding "
                                          : "label-modal no-padding participant-margin"
                                      }
                                    >
                                      {t("participant")} {+(i + 1)}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={12}
                                    className={
                                      "no-padding-left no-padding-right"
                                    }
                                  >
                                    <Grid columns={16} padded={false}>
                                      {this.renderPlayerInformation(i, 1)}
                                    </Grid>
                                  </Grid.Column>
                                </React.Fragment>
                              </Grid.Row>
                            );
                          }
                        )}
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <Button
                              icon={"add user"}
                              className={"round-btn a-venir"}
                              content={t("add_player")}
                              onClick={() => this.addNewPlayer(1)}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                ),
              },
            },
            {
              menuItem: team2,
              pane: {
                key: 2,
                content: (
                  <Grid.Row className={"padding-modal"}>
                    <Grid.Column
                      computer={16}
                      className={
                        "no-padding-left border-bottom no-padding-right"
                      }
                    >
                      <Grid columns={16} className={"padding-modal"}>
                        {hasTeam ? (
                          <Grid.Row
                            verticalAlign={"middle"}
                            className={"center-row"}
                          >
                            <Grid.Column width={2}>
                              <Image
                                type={"item"}
                                src={image2}
                                className={"modal-team-logo"}
                              />
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <CustomDropdown
                                disabled={true}
                                fetching={false}
                                keyword={"value"}
                                error={
                                  state.reservationForm.participants.equipe2
                                    .id === 0
                                }
                                loading={state.isLoading}
                                value={
                                  state.reservationForm.participants.equipe2.id
                                }
                                options={teamOptions}
                                render={(it) => {
                                  return <span>{it.text}</span>;
                                }}
                                onChange={(e, d) =>
                                  this.onChangeTeam(
                                    e,
                                    d,
                                    2,
                                    state.teams[indexTeam2]
                                  )
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        ) : (
                          <Grid.Row
                            verticalAlign={"middle"}
                            className={"center-row"}
                          >
                            <Grid.Column width={2}>
                              <Image
                                type={"item"}
                                src={image2}
                                className={"modal-team-logo"}
                              />
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <CustomDropdown
                                url={"/teams"}
                                disabled={
                                  state.reservationForm.information.ticket !==
                                    null &&
                                  (state.reservationForm.information.ticket
                                    .status === 2 ||
                                    state.reservationForm.information.ticket
                                      .status === 1)
                                }
                                placeholder={t("select-a-team")}
                                error={
                                  state.reservationForm.participants.equipe2
                                    .id === 0
                                }
                                value={
                                  state.reservationForm.participants.equipe2
                                    .id === 0
                                    ? null
                                    : state.reservationForm.participants.equipe2
                                        .id
                                }
                                onInitial={(t) => {
                                  if (
                                    !isEmpty(t) &&
                                    !state.teams.some(
                                      (itm) =>
                                        itm.hasOwnProperty("id") &&
                                        itm.id === t.id
                                    )
                                  )
                                    setState({
                                      teams: [...state.teams, t],
                                    });
                                }}
                                onChange={(e, d) =>
                                  this.onChangeTeam(e, { value: e }, 2, d)
                                }
                                render={(item) => {
                                  return (
                                    <>
                                      <span>{item.name}</span>
                                    </>
                                  );
                                }}
                                condition={(it) => {
                                  return (
                                    state.reservationForm.participants.equipe1
                                      .id !== it.id
                                  );
                                }}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        )}
                        {state.reservationForm.participants.equipe2.players.map(
                          (pl, i) => {
                            return (
                              <Grid.Row
                                key={`equipe-2-player-${i}`}
                                verticalAlign={"top"}
                                className={"no-padding-left no-padding-right "}
                              >
                                <React.Fragment>
                                  <Grid.Column
                                    width={4}
                                    className={"no-padding-left"}
                                  >
                                    <Grid columns={16}>
                                      <Grid.Column width={16}>
                                        {pl.removable && (
                                          <Icon
                                            color={"red"}
                                            name={"trash"}
                                            className={"remove-player"}
                                            onClick={() =>
                                              this.removePlayer(i, 2)
                                            }
                                          />
                                        )}

                                        <label
                                          className={
                                            i >= nbs[1]
                                              ? "label-modal no-padding"
                                              : "label-modal no-padding participant-margin"
                                          }
                                        >
                                          {t("participant")} {+(i + 1)}
                                        </label>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={12}
                                    className={
                                      "no-padding-left no-padding-right"
                                    }
                                  >
                                    <Grid
                                      columns={16}
                                      className={"no-padding-right"}
                                    >
                                      {this.renderPlayerInformation(i, 2)}
                                    </Grid>
                                  </Grid.Column>
                                </React.Fragment>
                              </Grid.Row>
                            );
                          }
                        )}
                        <Grid.Row>
                          <Grid.Column width={16}>
                            {
                              <Button
                                icon={"add user"}
                                className={"round-btn a-venir"}
                                content={t("add_player")}
                                onClick={() => this.addNewPlayer(2)}
                              />
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                ),
              },
            },
          ]}
        />
      </Grid>
    ) : (
      <Grid className={" padding-modal padded-top"} padded={false}>
        {state.reservationForm.participants.equipe1.players.map((pl, i) => {
          return (
            <Grid.Row
              verticalAlign={"top"}
              key={`equipe-1-row-player-${i}`}
              className={"row-player border-bottom no-padding-right"}
            >
              <React.Fragment key={`equipe-1-player-${i}`}>
                <Grid.Column
                  computer={3}
                  mobile={8}
                  className={"row-player no-padding-left   padding-bottom"}
                >
                  {pl.removable && (
                    <Icon
                      color={"red"}
                      name={"trash"}
                      className={"remove-player"}
                      onClick={() => this.removePlayer(i, 1)}
                    />
                  )}
                  <label
                    className={
                      i >= nbs[0]
                        ? "label-modal no-padding"
                        : "label-modal no-padding participant-margin"
                    }
                  >
                    Participant {+(i + 1)}
                  </label>
                </Grid.Column>
                <Grid.Column
                  tablet={16}
                  textAlign={"center"}
                  computer={13}
                  className={"no-padding-left no-padding-right padding-bottom"}
                >
                  <Grid columns={16} className={"padded-left"}>
                    {this.renderPlayerInformation(i, 1)}
                  </Grid>
                </Grid.Column>
              </React.Fragment>
            </Grid.Row>
          );
        })}
        <Grid.Row>
          <Grid.Column width={16}>
            <Button
              icon={"add user"}
              className={"round-btn a-venir"}
              content={t("add_player")}
              onClick={() => this.addNewPlayer(1)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
  /*
   *   Render Participants Modals
   *
   * */

  /*
   *   Render Participants Modals
   *
   * */
  renderLeisureParticipantsModal = () => {
    let { state, setState, t } = this.props;
    let { terrains } = state;
    let teamOptions = [];
    let hasTeam = !!state.reservationForm.competition.competition_id,
      selectedTerrains = [],
      selectedActivity = [],
      selectedCompetition = [];
    let img = "";
    let image1 = "",
      image2 = "",
      team1 = "---",
      team2 = "---",
      indexTeam1 = 0,
      indexTeam2 = 0;
    selectedTerrains =
      this.props.type === "caisse"
        ? [state.reservationForm.selectedTerrain]
        : terrains.filter(
            (it) =>
              it.id.toString() ===
              state.reservationForm.information.terrain_id.toString()
          );
    if (
      state.reservationForm.information.activity_id &&
      selectedTerrains &&
      selectedTerrains.length > 0 &&
      selectedTerrains[0] !== null
    ) {
      selectedActivity = selectedTerrains[0].activities.filter(
        (it, i) => it.id === state.reservationForm.information.activity_id
      );
    }
    if (
      selectedActivity.length > 0 &&
      selectedActivity[0].hasOwnProperty("competitions")
    ) {
      selectedCompetition = selectedActivity[0].competitions.filter(
        (it) => it.id === state.reservationForm.competition.competition_id
      );
    }

    if (hasTeam && selectedCompetition && selectedCompetition.length > 0) {
      teamOptions = selectedCompetition[0].teams.map((it, i) => ({
        key: "tea-opt-" + i,
        text: it.name,
        value: it.id,
      }));
      if (selectedCompetition.length > 0) {
        for (let i = 0; i < selectedCompetition[0].teams.length; i++) {
          if (
            state.reservationForm.participants.equipe1.id.toString() ===
            selectedCompetition[0].teams[i].id.toString()
          ) {
            image1 = selectedCompetition[0].teams[i].avatar
              ? imgUrl +
                "/teams/" +
                localStorage.getItem("complex_id") +
                "/" +
                selectedCompetition[0].teams[i].avatar
              : img;
            team1 = selectedCompetition[0].teams[i].name;
            indexTeam1 = i;
          }

          if (
            state.reservationForm.participants.equipe2.id.toString() ===
            selectedCompetition[0].teams[i].id.toString()
          ) {
            image2 = selectedCompetition[0].teams[i].avatar
              ? imgUrl +
                "/teams/" +
                localStorage.getItem("complex_id") +
                "/" +
                selectedCompetition[0].teams[i].avatar
              : img;
            team2 = selectedCompetition[0].teams[i].name;
            indexTeam2 = i;
          }
        }
      }
    } else if (
      selectedActivity.length > 0 &&
      selectedActivity[0].is_match === 1 &&
      state.teams.length > 0
    ) {
      for (let i = 0; i < state.teams.length; i++) {
        if (!isEmpty(state.teams[i])) {
          if (
            nullString(
              state.reservationForm.participants.equipe1.id
            ).toString() === state.teams[i].id.toString()
          ) {
            image1 =
              imgUrl +
              "/teams/" +
              localStorage.getItem("complex_id") +
              "/" +
              state.teams[i].avatar;
            team1 = state.teams[i].name;
            indexTeam1 = i;
          }

          if (
            nullString(
              state.reservationForm.participants.equipe2.id
            ).toString() === state.teams[i].id.toString()
          ) {
            image2 =
              imgUrl +
              "/teams/" +
              localStorage.getItem("complex_id") +
              "/" +
              state.teams[i].avatar;
            team2 = state.teams[i].name;
            indexTeam2 = i;
          }
        }
      }
    }

    // let teamSize = (this.props.isCaisse && this.props.article) ? 5 : this.props.article.default_participants_nb > 5 ? 5 : this.props.article.default_participants_nb;

    return (
      <Grid className={" padding-modal padded-top"} padded={false}>
        {state.reservationForm.participants.equipe1.players.map((pl, i) => {
          return (
            <Grid.Row
              verticalAlign={"top"}
              key={`equipe-1-row-player-${i}`}
              className={"row-player border-bottom no-padding-right"}
            >
              <React.Fragment key={`equipe-1-player-${i}`}>
                <Grid.Column
                  computer={3}
                  mobile={8}
                  className={"row-player no-padding-left   padding-bottom"}
                >
                  {(pl.removable || i >= 1) && (
                    <Icon
                      disabled={pl.disabled}
                      color={"red"}
                      name={"trash"}
                      className={"remove-player"}
                      onClick={() => this.removePlayer(i, 1)}
                    />
                  )}
                  <label
                    className={
                      i >= 1
                        ? "label-modal no-padding"
                        : "label-modal no-padding participant-margin"
                    }
                  >
                    {t("participant")} {+(i + 1)}
                  </label>
                </Grid.Column>
                <Grid.Column
                  tablet={16}
                  textAlign={"center"}
                  computer={13}
                  className={"no-padding-left no-padding-right padding-bottom"}
                >
                  <Grid columns={16} className={"padded-left"}>
                    {this.renderPlayerInformation(i, 1)}
                  </Grid>
                </Grid.Column>
              </React.Fragment>
            </Grid.Row>
          );
        })}
        <Grid.Row>
          <Grid.Column width={16}>
            <Button
              icon={"add user"}
              className={"round-btn a-venir"}
              content={t("add_player")}
              onClick={() => this.addNewPlayer(1)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  handleCompetition = (e, d) => {
    let { state, setState } = this.props;
    let reservationForm = { ...state.reservationForm };
    reservationForm.competition[e.target.name] = e.target.value;
    setState({ reservationForm });
  };

  renderPropertyPlayerModal = () => {
    let { state, setState, t } = this.props;
    let nbOfEmpty =
      state.reservationForm.participants.equipe1.players.length -
      this.getParticipantEmptyLength(
        this.props.state.reservationForm.participants.equipe1.players
      );
    let nbOfEmpty2 =
      state.reservationForm.participants.equipe2.players.length -
      this.getParticipantEmptyLength(
        this.props.state.reservationForm.participants.equipe2.players
      );

    let nbTotal = !state.reservationForm.information.reservation_id
      ? state.reservationForm.competition.participants_nb -
        state.reservationForm.competition.real_missing_participants_nb
      : nbOfEmpty + nbOfEmpty2;
    return (
      <Grid className={"padding-modal"} padded={false} divided={"vertically"}>
        <Grid.Column width={"8"} className={"modal-border-left"}>
          <Grid.Row columns={"8"}>
            <Grid columns={"16"} verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("privates")}</label>
              </Grid.Column>
              <Grid.Column width={12} className={"price-input"}>
                <CustomDropdown
                  fetching={false}
                  value={state.reservationForm.competition.type}
                  render={(it) => {
                    return (
                      <>
                        <span>{it.show}</span>
                      </>
                    );
                  }}
                  onChange={(e, d) =>
                    this.handleCompetition({
                      target: {
                        name: "type",
                        value: e,
                      },
                    })
                  }
                  options={privates}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>

          <Grid.Row columns={"8"}>
            <Grid columns={"16"} verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("level")}</label>
              </Grid.Column>
              <Grid.Column width={12} className={"price-input"}>
                <CustomDropdown
                  fetching={false}
                  value={state.reservationForm.competition.level}
                  render={(it) => {
                    return (
                      <>
                        <span>{it.name}</span>
                      </>
                    );
                  }}
                  onChange={(e, d) =>
                    this.handleCompetition({
                      target: {
                        name: "level",
                        value: e,
                      },
                    })
                  }
                  options={levels}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>

          <Grid.Row columns={"8"}>
            <Grid columns={"16"} verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("humans")}</label>
              </Grid.Column>
              <Grid.Column width={12} className={"price-input"}>
                <CustomDropdown
                  fetching={false}
                  value={state.reservationForm.competition.sex}
                  render={(it) => {
                    return (
                      <>
                        <span>{it.name}</span>
                      </>
                    );
                  }}
                  onChange={(e, d) =>
                    this.handleCompetition({
                      target: {
                        name: "sex",
                        value: e,
                      },
                    })
                  }
                  options={humans}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Grid.Column>

        <Grid.Column width={"8"}>
          <Grid.Row columns={"8"}>
            <Grid columns={"16"} verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>
                  {t("price_per_participant")}
                </label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Input
                  loading={this.props.priceLoading}
                  type={"text"}
                  disabled={true}
                  name={"price_per_participant"}
                  onChange={this.handleCompetition}
                  value={
                    nullStringNumber(
                      state.reservationForm.competition.price_per_participant
                    ) + localStorage.getItem("currency")
                  }
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>

          <Grid.Row columns={"8"}>
            <Grid columns={"16"} verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>{t("participants_nb")}</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Input
                  type={"number"}
                  min={nbTotal}
                  name={"participants_nb"}
                  onChange={(e) => {
                    //if (e.target.value <= state.reservationForm.competition.missing_participants_nb)
                    let nb =
                      parseInt(e.target.value) -
                      parseInt(
                        state.reservationForm.competition.participants_nb
                      );
                    let nn =
                      parseInt(
                        state.reservationForm.competition.participants_nb
                      ) -
                      parseInt(
                        state.reservationForm.competition
                          .missing_participants_nb
                      );
                    let tt =
                      parseInt(
                        state.reservationForm.competition
                          .missing_participants_nb
                      ) + nb;
                    if (nn === 0) nn = 1;
                    if (nb !== 0)
                      this.handleCompetition(
                        {
                          target: {
                            name: "missing_participants_nb",
                            value:
                              tt > 0
                                ? tt >= e.target.value
                                  ? parseInt(e.target.value) - nn
                                  : tt
                                : 0,
                          },
                        },
                        null
                      );
                    this.handleCompetition(e, null);
                    this.handleCompetition(
                      {
                        target: {
                          name: "price_per_participant",
                          value: (
                            parseFloat(
                              state.reservationForm.information.price
                            ) / parseInt(e.target.value)
                          ).toFixed(2),
                        },
                      },
                      null
                    );
                  }}
                  value={state.reservationForm.competition.participants_nb}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>

          <Grid.Row columns={"8"}>
            <Grid columns={"16"} verticalAlign={"middle"}>
              <Grid.Column width={4}>
                <label className={"label-modal"}>
                  {t("missing_participants_nb")}
                </label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Input
                  type={"number"}
                  max={
                    nullStringNumber(
                      state.reservationForm.competition.participants_nb
                    ) - nbTotal
                  }
                  min={0}
                  name={"missing_participants_nb"}
                  onChange={this.handleCompetition}
                  value={
                    state.reservationForm.competition.missing_participants_nb
                  }
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    );
  };

  renderLeisureGamesModal = () => {
    let { state, setState, t } = this.props;

    return (
      <Grid className={" padding-modal padded-top"} padded={false}>
        {state.reservationForm.information.games.map((pl, i) => {
          return (
            <Grid.Row
              verticalAlign={"top"}
              key={`games-1-row-player-${i}`}
              className={"row-player border-bottom no-padding-right"}
            >
              <React.Fragment key={`game-1-player-${i}`}>
                <Grid.Column
                  computer={3}
                  mobile={8}
                  className={"row-player no-padding-left padding-bottom"}
                >
                  {i > 0 && (
                    <Icon
                      disabled={pl.disabled || pl.hasTicket}
                      color={"red"}
                      name={"trash"}
                      className={"remove-player"}
                      onClick={() => this.removeGame(i)}
                    />
                  )}
                  <label
                    className={
                      i >= 1
                        ? "label-modal no-padding"
                        : "label-modal no-padding participant-margin"
                    }
                  >
                    {t("game") + " " + (i + 1)}
                  </label>
                </Grid.Column>
                <Grid.Column
                  tablet={16}
                  textAlign={"center"}
                  computer={13}
                  className={"no-padding-left no-padding-right padding-bottom"}
                >
                  <Grid columns={16} className={"padded-left"}>
                    {this.renderGame(i, pl)}
                  </Grid>
                </Grid.Column>
              </React.Fragment>
            </Grid.Row>
          );
        })}
        <Grid.Row>
          <Grid.Column width={16}>
            <Button
              icon={"add"}
              className={"round-btn a-venir"}
              content={t("add_game")}
              onClick={() => {
                let reservationForm = { ...state.reservationForm };
                let information = { ...reservationForm.information };
                information.games_nb = parseInt(information.games_nb) + 1;
                reservationForm.information = { ...information };
                setState({ reservationForm });
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  // return Historical panel
  renderHistoricalPanel = () => {
    let { state, t } = this.props;
    return (
      <div className={"historique-container"}>
        <div className={"table-container"}>
          <CustomTable
            footer
            loading={this.props.historyLoading}
            loadMore={(page, size, search) => {
              let userId = state.reservationForm.information.user.user_id;
              if (userId !== null) {
                this.props.reservationHistory(
                  page,
                  size,
                  userId,
                  this.props.type === "leisure" ? 1 : 0
                );
              }
            }}
            size={4}
            page={this.props.pageHistory}
            last={this.props.lastHistory}
            total={this.props.totalHistory}
            header={[
              "N°",
              t("date"),
              t("activity"),
              t("terrain"),
              t("duration"),
              t("time"),
              t("amount"),
              t("code"),
              t("society"),
              t("conclusion"),
              "",
            ]}
          >
            <Table.Body>{this.renderRow()}</Table.Body>
          </CustomTable>
        </div>
      </div>
    );
  };

  renderRow = () => {
    let { t } = this.props;
    let currency = localStorage.getItem("currency");
    let row = this.props.dataReservHistory.map((item, index) => {
      return (
        <Table.Row key={item.id}>
          <Table.Cell className="td-text">
            #{index + 1 + this.props.sizeHistory * (this.props.pageHistory - 1)}
          </Table.Cell>
          <Table.Cell className="td-text">
            {toDate(
              moment(item.date).locale("fr", localization).format("LLLL")
            )}
          </Table.Cell>

          <Table.Cell className="td-text">{item.activity.name}</Table.Cell>
          <Table.Cell className="td-text">{item.terrain.name}</Table.Cell>
          <Table.Cell className="td-text">{item.duration.duration}</Table.Cell>
          <Table.Cell className="td-text">
            {item.start_hour}-{item.end_hour}
          </Table.Cell>
          <Table.Cell className="td-text">
            {(item.price ? item.price : 0) + currency}
          </Table.Cell>
          <Table.Cell className="td-text">{item.code_res}</Table.Cell>
          <Table.Cell className="td-text">
            {item.user.company ? item.user.company : "---"}
          </Table.Cell>
          <Table.Cell className="td-text">
            <ConclusionBtn conclusion={item.conclusion} />
          </Table.Cell>
          <Table.Cell className="td-text tools">
            <Button disabled className={"round-btn"}>
              {t("detail")}
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });

    return row;
  };

  renderReservationModal = () => {
    let {
      setState,
      state,
      title,
      onClose,
      deleteArticleInReservation,
      t,
      type,
    } = this.props;
    let panes = [
      {
        menuItem: t("informations"),
        render: () => {
          return this.renderInformationPanel();
        },
      },
      {
        menuItem: t("participants"),
        render: () => {
          return this.props.type === "leisure"
            ? this.renderLeisureParticipantsModal()
            : this.renderParticipantsModal();
        },
      },
      {
        menuItem: t("property"),
        render: () => this.renderPropertyPlayerModal(),
      },
      {
        menuItem: t("history"),
        render: () => {
          return this.renderHistoricalPanel();
        },
      },
    ];
    if (type === "leisure")
      panes = [
        {
          menuItem: t("informations"),
          render: () => {
            return this.renderInformationPanel();
          },
        },
        {
          menuItem: t("participants"),
          render: () => {
            return this.props.type === "leisure"
              ? this.renderLeisureParticipantsModal()
              : this.renderParticipantsModal();
          },
        },
        {
          menuItem: t("games"),
          render: () => {
            return this.renderLeisureGamesModal();
          },
        },
        {
          menuItem: t("history"),
          render: () => {
            return this.renderHistoricalPanel();
          },
        },
      ];
    return (
      <Modal
        open={state.modalAdd}
        size={"large"}
        className={"reservation-modal"}
        onClose={() => {
          if (type === "caisse") deleteArticleInReservation();
          onClose();
        }}
      >
        <Modal.Header className={"centered-title modal-title"}>
          {type === "caisse" ? (
            <div style={type === "caisse" ? { width: "100%" } : {}}>
              {title}
            </div>
          ) : (
            title
          )}
          <Button
            icon="close"
            color="red"
            className={"close-modal"}
            circular
            onClick={() => {
              if (type === "caisse") {
                deleteArticleInReservation();
              }
              onClose();
            }}
          />
        </Modal.Header>
        <Modal.Content image scrolling={false} className={"history-content"}>
          <CustomTab
            //renderActiveOnly={false}
            onTabChange={(ev, data) => {
              let userId = state.reservationForm.information.user.user_id;
              let selectedIndex = data.activeIndex;
              if (
                (this.props.type === "leisure" && selectedIndex === 3) ||
                (this.props.type !== "leisure" &&
                  selectedIndex === 3 &&
                  userId !== null)
              ) {
                this.props.reservationHistory(
                  0,
                  4,
                  userId,
                  this.props.type === "leisure" ? 1 : 0
                );
              } else {
                this.props.resetHistory();
              }
              if (
                data.activeIndex === 1 ||
                (this.props.type === "leisure" && selectedIndex === 2)
              ) {
                this.autoFill();
              }
              if (this.checkInformation())
                setState({ selectedIndex: data.activeIndex });
            }}
            activeIndex={state.selectedIndex}
            cssClassName={"modal-tab"}
            panes={panes}
          />
        </Modal.Content>
        <Modal.Actions
          className={
            localStorage.getItem("lang") === "ar"
              ? "reverse-modal-history-footer"
              : "modal-history-footer"
          }
        >
          <Button
            icon
            className={"prev-btn"}
            disabled={state.selectedIndex === 0}
            onClick={() => {
              let { selectedIndex } = state;
              setState({ selectedIndex: --selectedIndex });
            }}
          >
            <Icon name={"arrow left"} />
            {t("previous")}
          </Button>

          <Button
            icon
            className={"next-btn"}
            disabled={
              this.props.type === "leisure"
                ? state.selectedIndex === 3
                : state.selectedIndex === 3
            }
            onClick={async () => {
              let { selectedIndex } = state;

              let userId = state.reservationForm.information.user.user_id;
              if (
                ((this.props.type === "leisure" && selectedIndex === 2) ||
                  (this.props.type !== "leisure" && selectedIndex === 1)) &&
                userId !== null
              ) {
                this.props.reservationHistory(
                  0,
                  4,
                  userId,
                  this.props.type === "leisure" ? 1 : 0
                );
              } else {
                this.props.resetHistory();
              }

              if (
                selectedIndex === 0 ||
                (this.props.type === "leisure" && selectedIndex === 1)
              ) {
                await this.autoFill();
              }

              if (this.checkInformation())
                setState({ selectedIndex: ++selectedIndex });
            }}
          >
            {t("next")}
            <Icon name={"arrow right"} />
          </Button>

          <Button
            ref={this.refBtn}
            disabled={
              (state.reservationForm.information.ticket &&
                state.reservationForm.information.ticket.status === 0) ||
              this.state.loadingCheck
            }
            className={"btn-enregister"}
            loading={
              this.state.loadingCheck === true || this.props.addLoading === true
            }
            onClick={() => {
              if (this.state.loadingCheck) return;
              this.setState({ loadingCheck: true });
              this.handleSubmit();
            }}
            secondary
          >
            {t("save")}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  /*
   * toggle the user information
   *
   * */
  showClientForm = (e, show) => {
    e.preventDefault();
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let { information } = state.reservationForm;

    //remove old participant
    let participants = { ...reservationForm.participants };

    let players1 = participants.equipe1.players.filter(
      (itm) =>
        this.isPlayerEmpty(itm) ||
        (itm.user_id !== information.user.user_id &&
          (nullString(itm.mobile) !== nullString(information.user.mobile) ||
            nullString(itm.email) !== nullString(information.user.email)))
    );
    let players2 = participants.equipe2.players.filter(
      (itm) =>
        this.isPlayerEmpty(itm) ||
        (itm.user_id !== information.user.user_id &&
          (nullString(itm.mobile) !== nullString(information.user.mobile) ||
            nullString(itm.email) !== nullString(information.user.email)))
    );

    let deleteFrom =
      participants.equipe1.players.length > players1.length
        ? 1
        : participants.equipe2.players.length > players2.length
        ? 2
        : 0;

    let newData = {
      ...information,
      show: true,
      user: {
        user_id: null,
        contact_id: null,
        firstName: "",
        lastName: "",
        info: "",
        birthdate: "",
        email: "",
        lastEmail: "",
        mobile: "",
        company: "",
        join_user_id: null,
        join_complex_id: null,
      },
    };

    if (deleteFrom === 1) {
      players1 = [{ ...player }, ...players1];
    }
    if (deleteFrom === 2) {
      players2 = [{ ...player }, ...players2];
    }

    participants = {
      equipe1: {
        ...participants.equipe1,
        players: players1,
      },
      equipe2: {
        ...participants.equipe2,
        players: players2,
      },
    };

    reservationForm = {
      ...reservationForm,
      information: newData,
      participants,
    };
    setState({ reservationForm: reservationForm });
  };

  /**
   *  render is new user  modal for event  when right click an event
   * @returns {ReactComponent}
   */
  renderIsNewUserModal = () => {
    const { t, setState, state } = this.props;
    //    let { events, eventId } = this.state;

    return (
      <Modal
        open={state.modalNewUser}
        onClose={() => setState({ modalNewUser: false })}
        size="tiny"
      >
        <Modal.Header>{t("confirm-delete")}</Modal.Header>
        <Modal.Content>
          <p>{t("want_new_user")}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t("no")}
            onClick={() => setState({ modalNewUser: false })}
          />
          <Button
            content={t("yes")}
            color={"google plus"}
            onClick={() => {
              // delete participants
              this.deleteParticipantAfterNewUser(
                state.reservationForm.information.user.user_id
              );
              this.handleClientReservationChange({
                target: { name: "user_id", value: null },
              });
              setState({ modalNewUser: false });
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  renderJoinModal = () => {
    const { t, state, setState } = this.props;
    let oldUser = state.old;
    let msg = formattingExistUser(t, oldUser);
    return (
      <Modal
        size={"small"}
        onClose={() => {
          this.handleClientReservationChange({
            target: { name: "ask_to_join", value: true },
          });
          this.handleSubmit();
          setState({ joinModal: false, old: null });
        }}
        open={state.joinModal}
      >
        <Modal.Header>{t("confirm-join")}</Modal.Header>
        <Modal.Content>
          <p>
            {msg.first.trim()} <b>{msg.second}</b>
          </p>
          <p> {msg.third} </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t("cancel")}
            onClick={() => {
              this.handleClientReservationChange({
                target: { name: "ask_to_join", value: true },
              });
              this.handleSubmit();
              setState({ joinModal: false, old: null });
            }}
          />
          <Button
            content={t("confirm")}
            className="negative"
            onClick={async () => {
              let user = state.old;

              user.join_complex_id = localStorage.getItem("complex_id");
              user.join_user_id = user.id;

              await this.handleClientChange(user.id, user);
              await this.removePlayer(0, 1);
              this.handleSubmit();
              setState({ joinModal: false, old: null });
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  getNbPerTeam = () => {
    let { state } = this.props;
    let { selectedActivity } = state.reservationForm;
    let nb =
      selectedActivity && selectedActivity.participants_nb
        ? selectedActivity.participants_nb
        : 1;
    let isMatch = selectedActivity
      ? nullString(selectedActivity.is_match) === "1"
      : false;

    if (isMatch) {
      let nbPerTeam1 = nb > 1 ? nb - parseInt((nb / 2).toString()) : 1;
      let nbPerTeam2 = nb > 1 ? parseInt((nb / 2).toString()) : 1;
      return [nbPerTeam1, nbPerTeam2];
    } else {
      return [nb, 0];
    }
  };

  deleteParticipantAfterNewUser = (user_id) => {
    let { state, setState } = this.props;
    let { reservationForm } = state;
    let participants = { ...reservationForm.participants };
    let team1 = [...participants.equipe1.players];
    let team2 = [...participants.equipe2.players];
    team1 = team1.filter((it, i) => it.user_id !== user_id);
    team2 = team2.filter((it, i) => it.user_id !== user_id);
    let nbs = this.getNbPerTeam();
    for (let i = 0; i < Math.abs(team1.length - nbs[0]); i++)
      team1.push({ ...player });
    for (let i = 0; i < Math.abs(team2.length - nbs[1]); i++)
      team2.push({ ...player });
    participants.equipe1.players = team1;
    participants.equipe2.players = team2;
    setState({ reservationForm: { ...reservationForm, participants } });
  };

  getMainUserError = (name) => {
    let { errors } = this.props;
    if (errors === null || errors.length === 0) return null;
    else {
      if (errors[0].hasOwnProperty("main-user")) {
        if (errors[0]["main-user"].hasOwnProperty(name)) {
          return errors[0]["main-user"][name][0];
        }
      }
    }
    return null;
  };

  getParticipantError = (item, name) => {
    let { errors } = this.props;
    if (errors === null || errors.length === 0) return null;
    else {
      for (let it of errors) {
        for (let n in it) {
          if (n === item && it[n][name]) return it[n][name][0];
        }
      }
      return null;
    }
  };

  render() {
    return (
      <>
        {this.renderJoinModal()}
        {this.renderReservationModal()}
        {this.renderIsNewUserModal()}
      </>
    );
  }
}

ReservationModal.defaultProps = {
  state: null,
  enableAdvance: true,
  type: "default",
  onClose: () => {},
  onSubmit: () => {},
  openComplex: "08:00",
  closeComplex: "00:00",
};

const mapDispatchToProps = {
  reservationHistory: (page, size = 2, user_id, is_leisure = 0) =>
    reservationCreators.historyRequestReservation({
      page,
      size,
      user_id,
      is_leisure,
    }),
  resetHistory: () => reservationCreators.resetReservationHistory(),
  getReservationInformation: (data) =>
    reservationCreators.InformationRequestReservation(data),
  clearReservationInformation: () =>
    reservationCreators.clearInformationResponseReservation(),
  getReservationArticle: (data) =>
    reservationCreators.getReservationArticle(data),
  getReservationAmount: (data) =>
    priceCreators.reservationRequestPriceManagements(data),
  clearReservationAmount: () =>
    priceCreators.clearReservationPriceManagements(),
  getReservationAvailableHours: (data) =>
    reservationCreators.getReservationAvailableHour(data),
  clearError: (data) => reservationCreators.clearErrorResponse(data),
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    teams: state.team.teams,
    addLoading: state.reservation.addLoading,
    historyLoading: state.reservation.historyLoading,
    dataReservHistory: state.reservation.reservationHistory,
    pageHistory: state.reservation.pageHistory,
    totalHistory: state.reservation.totalHistory,
    sizeHistory: state.reservation.sizeHistory,
    lastHistory: state.reservation.lastHistory,
    hourTypes: state.reservation.hourTypes,
    availableHourLoading: state.reservation.availableHourLoading,
    availableHours: state.reservation.availableHours,
    //****          price management-loading      ***//
    priceLoading: state.priceManagements.loading,
    information: state.priceManagements.information,
    amount: state.priceManagements.reservationPrice,
    //****          reservation information-loading      ***//
    informationLoading: state.reservation.informationLoading,
    reservationInformation: state.reservation.information,
    errors: state.reservation.errors,
    selectedComplex: state.auth.selected,
    /***** session ******/
    session: state.session.session,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationModal);
