import React from 'react';
import { Button } from 'semantic-ui-react';
import '../CustomCaisse.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { imgUrl } from '@/helpers';
import Image from '@/components/ImageComponent/Image';
import CreditAccountModal from '../PaymentsModal/CreditAccountModal';


class MainMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreditAccountModal: false,
    };
  }

  render() {
    //active button
    let img_style = null;
    let style = { backgroundColor: 'transparent', color: '#FFFFFF' };
    if (this.props.id === this.props.selectFamily) {
      style = { backgroundColor: '#AFD7FA', color: '#000000' };
      img_style = { WebkitFilter: 'invert(0)', filter: 'invert(100%)' };
    }
    return (
      <>
        {this.state.showCreditAccountModal &&
        <CreditAccountModal open={this.state.showCreditAccountModal}
                            type={'credit'}
                            onClose={() => this.setState({
                              showCreditAccountModal: false,
                            })}
        />
        }

        <Button
          className="main-menu-item"
          style={style}
          disabled={
            this.props.caisseRedirection.value !== 'ticket details' && this.props.caisseRedirection.value !== 'ticket' &&
            ((this.props.is_advance && this.props.currentTicket && this.props.currentTicket.articles[0] &&
                this.props.currentTicket.articles[0].parent.is_advance === 0) ||
              (this.props.is_credit_account && this.props.currentTicket && this.props.currentTicket.articles[0] &&
                this.props.currentTicket.articles[0].parent.is_credit === 0) ||
              (this.props.is_reload && this.props.currentTicket && this.props.currentTicket.articles[0] &&
                this.props.currentTicket.articles[0].parent.is_reload === 0) ||
              (!(this.props.is_advance || this.props.is_reload) && this.props.currentTicket && this.props.currentTicket.articles[0] &&
                (this.props.currentTicket.articles[0].parent.is_advance === 1 || this.props.currentTicket.articles[0].parent.is_reload === 1))
            )
          }
          onClick={() => {
            if (this.props.is_reload) {
              this.props.caisse_redirection('subscriber');
              this.props.select_family(this.props.id);
              return;
            }

            if (!this.props.is_credit_account) {
              this.props.caisse_redirection('home');
              this.props.select_family(this.props.id);
              if (this.props.caisseRedirection.value === 'ticket details') {
                this.props.updateCurrentTicket(null);
              }
            } else {
              this.props.updateCurrentTicket(null);
              this.setState({ showCreditAccountModal: true });
            }

          }}
        >
          <Image style={{ ...img_style, width: '3em', height: '3em' }}
                 src={this.props.image ? imgUrl + '/checkout/parents/' +
                   this.props.image : ''} type={'item'}/>

          <span>{this.props.name}</span>
        </Button>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  select_family: (value) => dispatch({ type: 'SELECT_FAMILY_REQUEST', payload: value }),
  caisse_redirection: (value) => dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: { value } }),
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
});
const mapStateToProps = (state) => {
  return {
    caisseRedirection: state.caisse.caisse_redirection,
    selectFamily: state.caisse.selectFamily,
    currentTicket: state.caisse.currentTicket,
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainMenuItem));
