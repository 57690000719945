import { deleteMethod, getMethod, postMethod } from '@/helpers/index';

export default {
  videoService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/videos?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_terrain ? '&search_terrain=' + search.search_terrain : '') +
        (search && search.search_date ? '&search_date=' + search.search_date : '') +
        '&complex_id=' +
        localStorage.getItem('complex_id'),
      );
      return result.data;
    },

    edit: async (id) => {
      const result = await getMethod('/videos/' + id);
      return result.data;
    },

    destroy: async (id) => {
      const result = await deleteMethod('/videos/' + id);
      return result.data;
    },

    terrains: async (data) => {
      const result = await postMethod('/videos/terrains/', data);
      return result.data;
    },

    hours: async (data) => {
      const result = await postMethod('/videos/hours/', data);
      return result.data;
    },

    list: async (data) => {
      let newData = {...data, reservation_id: data.hour_id};
      delete newData.hour_id;
      const result = await postMethod('/videos/list/', newData);
      return result.data;
    },

    code: async (data) => {
      const result = await postMethod('/videos/code/', data);
      return result.data;
    },

    videoViews: async (id) => {
      const result = await getMethod('videos/views/' + id);
      return result.data;
    },

    actionVideoViews: async (id) => {
      const result = await getMethod('videos/action/views/' + id);
      return result.data;
    },

    downloadVideo: async (id) => {
      const result = await getMethod('videos/' + id + '/download/');
      return result.data;
    },

    downloadVideoAction: async (id) => {
      const result = await getMethod('videos/action/' + id + '/download/');
      return result.data;
    }
  },
};
