import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as subscriberDetailsService from '../../services/subscriberDetailsService';
import { Creators as susbcriberCreators} from './subscriber';
import { errorToast, validToast } from "../../helpers/customToast";
import i18n from "../../i18n/index";

// Action types and creators
export const { Types, Creators } = createActions({
  loadingSubscriberDetails: ['payload'],

  indexRequestSubscriberDetails: ['payload'],
  indexResponseSubscriberDetails: ['payload'],

  indexMoreRequestSubscriberDetails: ['payload'],
  indexMoreResponseSubscriberDetails: ['payload'],

  cancelReloadRequestSubscriber: ['payload'],
  cancelReloadResponseSubscriber: ['payload'],

  subscriberDetailsUpdate: ['data'],

});

// Initial state
const INITIAL_STATE = {
  details: [],
  loading: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  cancelReloadLoading: false,
  successCancelReload: false,
  credit: null,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingSubscriberDetails({ loading: true, details: [] }));
    const data = yield call(subscriberDetailsService.index, action.payload);
    yield put(Creators.indexResponseSubscriberDetails(data.data));
    yield put(Creators.loadingSubscriberDetails({ loading: false }));
  } catch (e) {
    //
  }
}


//more
function* more(action) {
  try {
    yield put(Creators.loadingSubscriberDetails({ loading: true }));
    const data = yield call(subscriberDetailsService.index, action.payload);
    yield put(Creators.indexMoreResponseSubscriberDetails(data.data));
    yield put(Creators.loadingSubscriberDetails({ loading: false }));
  } catch (e) {
    //
  }
}

//cancel reload from subscriber detail
function* cancelReload(action) {
  try {
    yield put(Creators.loadingSubscriberDetails({ cancelReloadLoading: true, successCancelReload: false}));
    const data = yield call(subscriberDetailsService.cancelReload, action.payload);

    //refresh list subscriber details
    if(!action.payload.data.is_ticket){
      yield put(Creators.subscriberDetailsUpdate(data.data));

      //update total balance
      yield put(
        susbcriberCreators.updateTotalBalance(data.data.data.total_balance)
      );
    }
    
    yield put(Creators.cancelReloadResponseSubscriber(data.data));
    yield put(Creators.loadingSubscriberDetails({ cancelReloadLoading: false}));

    validToast(i18n.t("successful_store"));
  } catch (e) {
    yield put(Creators.loadingSubscriberDetails({ cancelReloadLoading: false}));
    errorToast(i18n.t("failed_store"));
  }
}

//indexMoreRequestSubscriberDetails
export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_SUBSCRIBER_DETAILS, index);
  yield takeLatest(Types.INDEX_MORE_REQUEST_SUBSCRIBER_DETAILS, more);
  yield takeLatest(Types.CANCEL_RELOAD_REQUEST_SUBSCRIBER, cancelReload);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    details: [...action.payload.data],
    last: action.payload.last_page,
    page: action.payload.current_page,
    total: action.payload.total,
    size: action.payload.per_page,
    from: action.payload.from,
  };
};

const more_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    details: [...state.details, ...action.payload.data],
    last: action.payload.last_page,
    page: action.payload.current_page,
    total: action.payload.total,
    size: action.payload.per_page,
    from: action.payload.from,
  };
};

//store detail
const cancel_reload = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    credit: action.payload.data.credit_ticket,
    successCancelReload: true
  };
};

const subscriber_details_update = (state = INITIAL_STATE, action) => {
  return {
    details: [...action.data.data.subscriber_details.data],
    last: action.data.data.subscriber_details.last_page,
    page: action.data.data.subscriber_details.current_page,
    total: action.data.data.subscriber_details.total,
    size: action.data.data.subscriber_details.per_page,
    from: action.data.data.subscriber_details.from,
  };
};


const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

//indexMoreResponseSubscriberDetails
// Reducer
export const subscriberDetailsReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_SUBSCRIBER_DETAILS]: index_response,
  [Types.INDEX_MORE_RESPONSE_SUBSCRIBER_DETAILS]: more_response,
  [Types.CANCEL_RELOAD_RESPONSE_SUBSCRIBER]: cancel_reload,
  [Types.SUBSCRIBER_DETAILS_UPDATE]: subscriber_details_update,
  [Types.LOADING_SUBSCRIBER_DETAILS]: loading,
});
