import { deleteMethod, getMethod, postMethod, putMethod } from '../helpers';
import { getFormData, showFormData } from '../helpers/formDataHandler';

/**
 * Get the subscribers list
 *
 * @param page
 * @param size
 * @param search
 * @return object
 */
export const index = ({ page, size, search }) => {
  return getMethod('/subscribers?page=' + page +
    (size ? '&size=' + size : '') +
    (search && search.search_start_date ? '&search_start_date=' + search.search_start_date : '') +
    (search && search.search_user ? '&search_user=' + search.search_user : '') +
    (search && search.search_subscription ? '&search_subscription=' + search.search_subscription : '') +
    '&complex_id=' + localStorage.getItem('complex_id'));
};

/**
 * Create a subscriber
 *
 * @param data
 */
export const store = (data) => {
  let fd = getFormData(data);
  showFormData(fd);

  return postMethod('/subscribers', fd);
};

/**
 * Get the subscriber
 *
 * @param id
 * @return object
 */
export const edit = (id) => {
  return getMethod('/subscribers/' + id);
};

/**
 * Reload the subscription of the subscriber
 *
 * @param data
 */
export const reload = (data) => {
  return postMethod('/subscriberDetails', data);
};

/**
 * Update the subscriber
 *
 * @param data
 */
export const update = (data) => {
  let fd = getFormData(data);
  fd.append('_method', 'put');
  return postMethod('/subscribers/' + data.id, fd);
};

/**
 * Update the subscriber deadline
 *
 * @param data
 */
export const updateDeadline = (data) => {
  return putMethod('/subscriberDeadlines/' + data.id, data);
};

/**
 * Delete the subscriber
 *
 * @param id
 */
export const destroy = (id) => {
  return deleteMethod('/subscribers/' + id);
};

/**
 * Delete the subscriber
 *
 * @param id
 */
export const destroySubscriberDetail = (id) => {
  return deleteMethod('/subscriberDetails/' + id);
};
