import React from 'react';
import { Tab } from 'semantic-ui-react';
import './CustomTab.css';

/**
 * Create a custom tab
 *       <CustomTab panes={[
              { menuItem: 'Tab 1', render: () => <Tab.Pane>Tab 1 Content</Tab.Pane> },
              { menuItem: 'Tab 2', render: () => <Tab.Pane>Tab 2 Content</Tab.Pane> },
              { menuItem: 'Tab 3', render: () => <Tab.Pane>Tab 3 Content</Tab.Pane> },
          ]}/>
 *      you can replace <Tab.Pane> with your component
 *
 *
 * */

const CustomTab = (props) => {
  let cssClassName = props.cssClassName ? props.cssClassName : '';
  let newProps = { ...props };
  delete newProps.cssClassName;
  return (
    <div className={'tab-custom ' + cssClassName}>
      <Tab menu={{ text: true }} {...newProps} />
    </div>
  );
};

export default CustomTab;
