import { call, put, takeLatest } from "redux-saga/effects";
import { createActions, createReducer } from "reduxsauce";
import * as subscriberService from "@/services/subscriberService";
import history from "@/helpers/history";
import { errorToast, validToast } from "../../helpers/customToast";
import i18n from "../../i18n/index";

// Action types and creators
export const { Types, Creators } = createActions({
  loadingSubscriber: ["payload"],
  loadingSubscriberDetail: ["payload"],

  deleteLoading: ["payload"],
  indexRequestSubscriber: ["payload"],
  indexResponseSubscriber: ["payload"],

  reloadRequestSubscriber: ["payload"],
  reloadResponseSubscriber: ["payload"],

  indexRequestSubscriberDetail: ["payload"],
  indexResponseSubscriberDetail: ["payload"],

  storeRequestSubscriber: ["payload"],
  storeResponseSubscriber: ["payload"],

  storeRequestSubscriberDetail: ["payload"],
  storeResponseSubscriberDetail: ["payload"],

  editRequestSubscriber: ["payload"],
  editResponseSubscriber: ["payload"],

  updateRequestSubscriber: ["payload"],
  updateResponseSubscriber: ["payload"],

  updateRequestSubscriberDeadline: ["payload"],
  updateResponseSubscriberDeadline: ["payload"],

  destroyRequestSubscriber: ["payload"],
  destroyResponseSubscriber: ["payload"],

  updateTotalBalance:["payload"]
});

// Initial state
const INITIAL_STATE = {
  subscribers: [],
  subscriber: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  lastDetail: 0,
  pageDetail: 0,
  sizeDetail: 4,
  totalDetail: 0,
  fromDetail: 1,
  updated: false,
  updatedDeadline: false,
  loading: false,
  loadingDetail: false,
  editLoading: false,
  deleteLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingSubscriber({ loading: true }));
    const data = yield call(subscriberService.index, action.payload);
    yield put(Creators.indexResponseSubscriber(data));
    yield put(Creators.loadingSubscriber({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingSubscriber({ loading: false }));
  }
}

//index detail
function* indexDetail(action) {
  try {
    yield put(Creators.loadingSubscriberDetail({ loadingDetail: true, updated: false }));
    const data = yield call(subscriberService.reload, action.payload);
    // yield put(Creators.indexResponseSubscriber(data));
    yield put(Creators.loadingSubscriberDetail({ loadingDetail: false, updated: true }));

    validToast(i18n.t("successful_store"));
  } catch (e) {
    yield put(Creators.loadingSubscriberDetail({ loadingDetail: false, updated: false }));
    errorToast(i18n.t("failed_store"));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingSubscriber({ loading: true, errors: null }));
    const data = yield call(subscriberService.store, action.payload);
    //yield put(Creators.storeResponseSubscription(data));
    yield put(Creators.loadingSubscriber({ loading: false }));
    if (data.data && data.data.data?.hasOwnProperty("errors")) {
      yield put(
        Creators.loadingSubscriber({
          errors: data.data.data.errors
        })
      );
      errorToast(i18n.t("failed_store"));
    } else {
      yield history.push("/subscriber");
      validToast(i18n.t("successful_store"));
    }
  } catch (e) {
    yield put(
      Creators.loadingSubscriber({
        loading: false,
        errors: e.response.data.errors
      })
    );
    errorToast(i18n.t("failed_store"));
  }
}

//store subscription detail

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingSubscriber({ editLoading: true }));
    const data = yield call(subscriberService.edit, action.payload);
    yield put(Creators.editResponseSubscriber(data));
    yield put(Creators.loadingSubscriber({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingSubscriber({ editLoading: false }));
    history.push("/error-404");
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingSubscriber({ loading: true, errors: null }));
    const data = yield call(subscriberService.update, action.payload);
    yield put(Creators.updateResponseSubscriber(data));
    yield put(Creators.loadingSubscriber({ loading: false }));
    if (data.data && data.data.data?.hasOwnProperty("errors")) {
      yield put(
        Creators.loadingSubscriber({
          errors: data.data.data.errors
        })
      );
      errorToast(i18n.t("failed_store"));
    } else {
      validToast(i18n.t("successful_update"));
      yield history.push("/subscriber");
    }
  } catch (e) {
    yield put(
      Creators.loadingSubscriber({
        loading: false,
        errors: e.response.data.errors
      })
    );
    errorToast(i18n.t("failed_update"));
  }
}

//update subscriber deadline
function* updateDeadline(action) {
  try {
    yield put(Creators.loadingSubscriber({ loading: true, updatedDeadline: false }));
    const data = yield call(subscriberService.updateDeadline, action.payload);
    yield put(Creators.updateResponseSubscriberDeadline(data));
    yield put(Creators.loadingSubscriber({ loading: false, updatedDeadline: true }));
    validToast(i18n.t("successful_update"));
    //yield history.push('/subscriber');
  } catch (e) {
    yield put(
      Creators.loadingSubscriber({
        loading: false,
        errors: e.response.data.errors,
        updatedDeadline: false
      })
    );
    errorToast(i18n.t("failed_update"));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingSubscriber({ deleteLoading: true }));
    const data = yield call(subscriberService.destroy, action.payload);
    yield put(Creators.destroyResponseSubscriber(data));
    yield put(Creators.loadingSubscriber({ deleteLoading: false }));
    validToast(i18n.t("successful_delete"));
  } catch (e) {
    errorToast(i18n.t("failed_delete"));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_SUBSCRIBER, index);
  yield takeLatest(Types.INDEX_REQUEST_SUBSCRIBER_DETAIL, indexDetail);
  yield takeLatest(Types.STORE_REQUEST_SUBSCRIBER, store);
  yield takeLatest(Types.EDIT_REQUEST_SUBSCRIBER, edit);
  yield takeLatest(Types.UPDATE_REQUEST_SUBSCRIBER, update);
  yield takeLatest(Types.UPDATE_REQUEST_SUBSCRIBER_DEADLINE, updateDeadline);
  yield takeLatest(Types.DESTROY_REQUEST_SUBSCRIBER, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    subscribers: [...action.payload.data.data],
    last: action.payload.data.last_page,
    page: action.payload.data.current_page,
    total: action.payload.data.total,
    size: action.payload.data.per_page,
    from: action.payload.data.from
  };
};

//index detail
const index_detail_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    details: [...action.data.data],
    lastDetail: action.data.last_page,
    pageDetail: action.data.current_page,
    totalDetail: action.data.total,
    sizeDetail: action.data.per_page,
    fromDetail: action.data.from
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true
  };
};

//store detail
const store_detail_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    subscriber: action.payload.data.data
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true
  };
};

//update subscriber dedline
const update_deadline_response = (state = INITIAL_STATE, action) => {
  let deadline = action.payload.data.data;
  let i = 0;
  let subscriber = state.subscribers.filter((item, index) => {
    return item.id === deadline.subscriber_id;
  });

  let deadlines = subscriber[0].deadlines.filter((item, index) => {
    if (item.id !== deadline.id)
      return true;
    else {
      i = index;
      return false;
    }
  });

  deadlines.splice(i, 0, deadline);
  subscriber[0].deadlines = deadlines;
  let subscribers = state.subscribers.filter((item, index) => {
    return item.id !== deadline.subscriber_id;
  });

  subscribers = subscribers.concat(subscriber);

  return {
    ...state,
    subscribers: subscribers,
    successUpdate: true
  };
};

//destroy
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload
  };
};

const loading_detail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload
  };
};

const update_total_balance = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    subscriber: {...state.subscriber, total_balance: action.payload}
  };
};

// Reducer
export const subscriberReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_SUBSCRIBER]: index_response,
  [Types.INDEX_RESPONSE_SUBSCRIBER_DETAIL]: index_detail_response,
  [Types.STORE_RESPONSE_SUBSCRIBER]: store_response,
  [Types.STORE_RESPONSE_SUBSCRIBER_DETAIL]: store_detail_response,
  [Types.EDIT_RESPONSE_SUBSCRIBER]: edit_response,
  [Types.UPDATE_RESPONSE_SUBSCRIBER]: update_response,
  [Types.UPDATE_RESPONSE_SUBSCRIBER_DEADLINE]: update_deadline_response,
  [Types.DESTROY_RESPONSE_SUBSCRIBER]: destroy_response,
  [Types.LOADING_SUBSCRIBER]: loading,
  [Types.LOADING_SUBSCRIBER_DETAIL]: loading_detail,
  [Types.UPDATE_TOTAL_BALANCE]: update_total_balance,
});
