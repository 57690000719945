import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import supportTicketService from '@/services/supportTicketService';
import supportMessageService from '@/services/supportMessageService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingSupport: ['payload'],

  indexRequestSupport: null,
  indexResponseSupport: ['data'],

  storeRequestSupport: ['payload'],
  storeResponseSupport: ['data'],

  editRequestSupport: ['id'],
  editResponseSupport: ['data'],

  updateRequestSupport: ['payload'],
  updateResponseSupport: ['data'],

  destroyRequestSupport: ['id'],
  destroyResponseSupport: ['data'],

  storeRequestSupportMessage: ['payload'],
  storeResponseSupportMessage: ['data'],

  destroyRequestSupportMessage: ['id'],
  destroyResponseSupportMessage: ['data'],

  readAtRequestSupport: ['id'],
  readAtResponseSupport: ['data'],

  refreshResponseSupportMessages: ['data'],

});

// Initial state
const INITIAL_STATE = {
  support_tickets: [],
  support_ticket: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  storeLoading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
  support_message: null,
  messageLoading: false,
  successMessageStore: false,
  successMessageDestroy: false,
  successReadAt: false
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingSupport({ loading: true }));
    const data = yield call(supportTicketService.supportTicketService.index, action.payload);
    yield put(Creators.indexResponseSupport(data));
    yield put(Creators.loadingSupport({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingSupport({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingSupport({ storeLoading: true }));
    const data = yield call(supportTicketService.supportTicketService.store, action.payload);
    yield put(Creators.storeResponseSupport(data));
    yield put(Creators.loadingSupport({ storeLoading: false }));
    yield history.push('/support');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingSupport({ storeLoading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingSupport({ editLoading: true }));
    const data = yield call(supportTicketService.supportTicketService.edit, action.payload);
    yield put(Creators.editResponseSupport(data));
    yield put(Creators.loadingSupport({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingSupport({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingSupport({ loading: true }));
    const data = yield call(supportTicketService.supportTicketService.update, action.payload);
    yield put(Creators.updateResponseSupport(data));
    yield put(Creators.loadingSupport({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/support');
  } catch (e) {
    yield put(Creators.loadingSupport({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingSupport({ deleteLoading: true }));
    const data = yield call(supportTicketService.supportTicketService.destroy, action.payload);
    yield put(Creators.destroyResponseSupport(data));
    yield put(Creators.loadingSupport({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

//store message
function* storeMessage(action) {
  try {
    yield put(Creators.loadingSupport({ messageLoading: true, successStoreMessage:false }));
    const data = yield call(supportMessageService.supportMessageService.store, action.payload);
    yield put(Creators.storeResponseSupportMessage(data));
    yield put(Creators.loadingSupport({ messageLoading: false }));
    // validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingSupport({ messageLoading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//destroy message
function* destroyMessage(action) {
  try {
    yield put(Creators.loadingSupport({ messageLoading: true }));
    const data = yield call(supportMessageService.supportMessageService.destroy, action.payload);
    yield put(Creators.destroyResponseSupportMessage(data));
    yield put(Creators.loadingSupport({ messageLoading: false }));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

//readAt
function* readAt(action) {
  try {
    yield put(Creators.loadingSupport({ messageLoading: true }));
    const data = yield call(supportMessageService.supportMessageService.readtAt, action.payload);
    yield put(Creators.readAtResponseSupport(data));
    yield put(Creators.loadingSupport({ messageLoading: false }));
  } catch (e) {
    yield put(Creators.loadingSupport({ messageLoading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_SUPPORT, index);
  yield takeLatest(Types.STORE_REQUEST_SUPPORT, store);
  yield takeLatest(Types.EDIT_REQUEST_SUPPORT, edit);
  yield takeLatest(Types.UPDATE_REQUEST_SUPPORT, update);
  yield takeLatest(Types.DESTROY_REQUEST_SUPPORT, destroy);
  yield takeLatest(Types.STORE_REQUEST_SUPPORT_MESSAGE, storeMessage);
  yield takeLatest(Types.DESTROY_REQUEST_SUPPORT_MESSAGE, destroyMessage);
  yield takeLatest(Types.READ_AT_REQUEST_SUPPORT, readAt);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    support_tickets: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    support_ticket: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

//loading
const laoding_support = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

//store message
const store_message_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    support_ticket : action.data.data,
    successStoreMessage: true,
  };
};

//destroy message
const destroy_message_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    support_ticket : action.data.data,
    successDestroyMessage: true,
  };
};

//refresh messages
const refresh_messages_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    support_ticket: action.data.supportTicket,
  };
};


// Reducer
export const supportReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_SUPPORT]: index_response,
  [Types.STORE_RESPONSE_SUPPORT]: store_response,
  [Types.EDIT_RESPONSE_SUPPORT]: edit_response,
  [Types.UPDATE_RESPONSE_SUPPORT]: update_response,
  [Types.DESTROY_RESPONSE_SUPPORT]: destroy_response,
  [Types.LOADING_SUPPORT]: laoding_support,
  [Types.STORE_RESPONSE_SUPPORT_MESSAGE]: store_message_response,
  [Types.DESTROY_RESPONSE_SUPPORT_MESSAGE]: destroy_message_response,
  [Types.REFRESH_RESPONSE_SUPPORT_MESSAGES]: refresh_messages_response,

});
