import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import complexTimesService from '../../services/complexTimesService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingComplexTimes: ['data'],

  storeRequestComplexTimes: ['payload'],
  storeResponseComplexTimes: ['data'],

  editRequestComplexTimes: ['id'],
  editResponseComplexTimes: ['data'],

  updateRequestComplexTimes: ['payload'],
  updateResponseComplexTimes: ['data'],
});

// Initial state
const INITIAL_STATE = {
  complexTimes: null,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
};

//store
function* store(action) {
  try {
    yield put(Creators.loadingComplexTimes({ loading: true }));
    const data = yield call(complexTimesService.complexTimesService.store, action.payload);
    yield put(Creators.storeResponseComplexTimes(data));
    yield put(Creators.loadingComplexTimes({ loading: false }));
    yield history.push('/configuration/complex');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingComplexTimes({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingComplexTimes({ editLoading: true }));
    const data = yield call(complexTimesService.complexTimesService.edit, action.payload);
    yield put(Creators.editResponseComplexTimes(data));
    yield put(Creators.loadingComplexTimes({ editLoading: false }));
  } catch (e) {
    yield put(Creators.editResponseComplexTimes(null));
    yield put(Creators.loadingComplexTimes({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingComplexTimes({ loading: true }));
    const data = yield call(complexTimesService.complexTimesService.update, action.payload);
    yield put(Creators.updateResponseComplexTimes(data));
    yield put(Creators.loadingComplexTimes({ loading: false }));
    yield history.push('/configuration/complex');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(
      Creators.loadingComplexTimes({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.STORE_REQUEST_COMPLEX_TIMES, store);
  yield takeLatest(Types.EDIT_REQUEST_COMPLEX_TIMES, edit);
  yield takeLatest(Types.UPDATE_REQUEST_COMPLEX_TIMES, update);
}

// Reducer handlers
//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    complexTimes: { ...action.data.data },
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const complexTimesReducer = createReducer(INITIAL_STATE, {
  [Types.STORE_RESPONSE_COMPLEX_TIMES]: store_response,
  [Types.EDIT_RESPONSE_COMPLEX_TIMES]: edit_response,
  [Types.UPDATE_RESPONSE_COMPLEX_TIMES]: update_response,
  [Types.LOADING_COMPLEX_TIMES]: loading,
});
