import { createActions, createReducer } from "reduxsauce";
import { call, put, select, takeLatest } from "redux-saga/effects";
import caisseService from "../../services/caisseService";
import {
  convertTicket,
  filterParticipantsByArticle,
} from "../../helpers/ticket";
import { errorToast, warningToast } from "../../helpers/customToast";
import { getPayments } from "../../helpers/reservationFormatting";
import { useAdvanceInReservation } from "../../helpers/caisseFormatting";
import { Creators as CreatorsReservation } from "./reservation";
import history from "../../helpers/history";
import i18n from "../../i18n";
import { getCurrentNoteIndex } from "../../helpers/caisse/noteFormatting";
// Action types and creators
export const { Types, Creators } = createActions({
  //loading
  loadingCaisse: ["data"],

  updateAll: ["payload"],

  // set values
  setValues: ["data"],

  //get data
  getDataRequest: null,
  getDataResponse: ["data"],

  //select family
  selectFamilyRequest: ["payload"],
  selectFamilyResponse: ["data"],

  //select subfamily
  selectSubFamilyRequest: ["payload"],
  selectSubFamilyResponse: ["data"],

  //select HourType
  selectHourTypeRequest: ["payload"],
  selectHourTypeResponse: ["data"],

  //update current ticket
  updateCurrentTicketRequest: ["payload"],
  updateCurrentTicketResponse: ["data"],

  //current note
  currentNoteRequest: ["payload"],
  currentNoteResponse: ["data"],

  //add article to pay
  articleToPayRequest: ["payload"],
  articleToPayResponse: ["data"],

  //article to edit payment
  articleToEditPaymentRequest: ["payload"],
  articleToEditPaymentResponse: ["data"],

  //participant to edit payment
  participantToEditPaymentRequest: ["payload"],
  participantToEditPaymentResponse: ["data"],

  //article to partial payment
  articleToPartialPaymentRequest: ["payload"],
  articleToPartialPaymentResponse: ["data"],

  //participant to partial payment
  participantToPartialPaymentRequest: ["payload"],
  participantToPartialPaymentResponse: ["data"],

  //redirection
  caisseRedirectionRequest: ["payload"],
  caisseRedirectionResponse: ["data"],
  caisseRedirectionReset: ["data"],

  //get tickets
  getTicketsRequest: ["payload"],
  getTicketsResponse: ["data"],

  //store ticket
  storeTicketRequest: ["payload"],
  storeTicketResponse: ["data"],

  //update ticket
  updateTicketRequest: ["payload"],
  updateTicketResponse: ["data"],

  //get ticket
  getTicketRequest: ["payload"],
  getTicketResponse: ["data"],

  //delete ticket
  destroyTicketRequest: ["payload"],
  destroyTicketResponse: ["data"],

  //get cancled ticket (edit ticket)
  getCancledTicketRequest: ["payload"],
  getCancledTicketResponse: ["data"],

  //test of duplicate ticket (edit ticket)
  duplicateTicketRequest: ["payload"],
  duplicateTicketResponse: ["data"],

  //check credit
  checkCreditNumberRequest: ["payload"],
  checkCreditNumberResponse: ["data"],

  //check advance
  checkAdvanceNumberRequest: ["payload"],
  checkAdvanceNumberResponse: ["data"],

  //update restored credit list (cancel article)
  restoredCreditListRequest: ["payload"],
  restoredCreditListResponse: ["data"],

  //decrement occurence number of payed article
  decrementOccNbRequest: ["payload"],
  decrementOccNbResponse: ["data"],

  // PrintTicket + PrintInvoice
  printTicketRequest: ["payload"],
  printInvoiceRequest: ["payload"],

  // invoice
  storeInvoiceRequest: ["payload"],
  storeInvoiceResponse: ["payload"],

  indexInvoiceRequest: ["payload"],
  indexInvoiceResponse: ["payload"],

  destroyInvoiceRequest: ["payload"],
  destroyInvoiceResponse: ["payload"],

  updateReservationResponse: ["payload"],

  //note in progress
  noteInProgressResponse: ["payload"],

  //pending advance (edit participant nb case)
  pendingAdvanceRequest: ["payload"],
  pendingAdvanceResponse: ["data"],

  //participant payed by classic sub (pending payment)
  pendingClassicSubRequest: ["payload"],
  pendingClassicSubResponse: ["data"],

  // essential for checking for the middle checkoutSession
  checkCheckoutSession: ["data"],
});

//TODO

/***
 * selectFamily stay the same ,
 * selectSubfamily stay the same ,
 *
 * when switching between selectFamily we reset
 *
 *
 *
 *
 * */
// Initial state
const INITIAL_STATE = {
  menusData: null,
  currentTicket: null,
  currentNote: null,
  reservationTicket: null,
  selectFamily: 1,
  selectSubFamily: 0,
  selectHourType: 0,
  articleToPay: [],
  amountToPay: 0,
  restAmountToPay: 0,
  articleToEditPayment: null,
  participantToEditPayment: null,
  articleToPartialPayment: [],
  participantToPartialPayment: [],
  caisse_redirection: { value: "home" },
  loading: false,
  loadingTicket: false,
  loadingTicketById: false,
  cancledTicket: null,
  cancledTicketId: null,
  duplicateTicket: 0,
  successTicketStore: false,
  successTicketUpdate: false,
  appLoading: false,
  loadingCredit: false,
  credit: null,
  creditChecked: null,
  advanceChecked: null,
  restoredCreditList: null,
  decrementOccNb: null,
  isRedirection: false,
  invoice: null,
  invoiceError: null,
  invoices: null,
  tickets: [],
  invoiceLoading: false,
  size: 10,
  page: 1,
  last: 0,
  total: 0,
  from: 1,
  printTicket: null,
  printInvoice: null,
  totals: 0,
  total_payed: 0,
  total_price: 0,
  total_discount: 0,
  total_advance: 0,
  pendingAdvance: null,
  pendingClassicSub: null,
};

export const getCurrentTicket = (state) => {
  return state.caisse.currentTicket;
};

export const getCurrentNote = (state) => {
  return state.caisse.currentNote;
};

export const getCaisseState = (state) => {
  return state.caisse;
};

//get data (parent+child+hourType+article)
function* getData(action) {
  try {
    yield put(Creators.loadingCaisse({ loading: true }));
    const data = yield call(caisseService.caisseService.getData);
    yield put(Creators.getDataResponse(data));
    yield put(Creators.loadingCaisse({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingCaisse({ loading: false }));
  }
}

function* indexInvoice(action) {
  try {
    yield put(Creators.loadingCaisse({ invoiceLoading: true }));

    const data = yield call(
      caisseService.caisseService.getInvoices,
      action.payload
    );
    yield put(Creators.indexInvoiceResponse(data.data));

    yield put(Creators.loadingCaisse({ invoiceLoading: false }));
  } catch (e) {
    yield put(Creators.loadingCaisse({ invoiceLoading: false }));
  }
}

function* storeInvoice(action) {
  try {
    yield put(
      Creators.loadingCaisse({
        invoiceLoading: true,
        invoice: null,
        invoiceError: null,
      })
    );
    const data = yield call(
      caisseService.caisseService.storeInvoice,
      action.payload
    );
    if (data.data?.data?.hasOwnProperty("errors")) {
      yield put(
        Creators.storeInvoiceResponse({ invoiceError: data.data.data.errors })
      );
    } else {
      yield put(Creators.storeInvoiceResponse({ invoice: data.data }));
    }
    yield put(Creators.loadingCaisse({ invoiceLoading: false }));
  } catch (e) {
    yield put(Creators.loadingCaisse({ invoiceLoading: false, invoice: null }));
  }
}

//select parent
function* selectFamily(action) {
  try {
    yield put(Creators.selectFamilyResponse(action.payload));
  } catch (e) {
    //
  }
}

//select child
function* selectSubFamily(action) {
  try {
    yield put(Creators.selectSubFamilyResponse(action.payload));
  } catch (e) {
    //
  }
}

//select hour type
function* selectHourType(action) {
  try {
    yield put(Creators.selectHourTypeResponse(action.payload));
  } catch (e) {
    //
  }
}

//update current ticket
function* updateCurrentTicket(action) {
  try {
    yield put(Creators.updateCurrentTicketResponse(action.payload));
  } catch (e) {
    //
  }
}

//article to pay
function* articleToPay(action) {
  try {
    yield put(Creators.articleToPayResponse(action.payload));
  } catch (e) {
    //
  }
}

//article to edit payment
function* articleToEditPayment(action) {
  try {
    yield put(Creators.articleToEditPaymentResponse(action.payload));
  } catch (e) {
    //
  }
}

//participant to edit payment
function* participantToEditPayment(action) {
  try {
    yield put(Creators.participantToEditPaymentResponse(action.payload));
  } catch (e) {
    //
  }
}

//article to partial payment
function* articleToPartialPayment(action) {
  try {
    yield put(Creators.articleToPartialPaymentResponse(action.payload));
  } catch (e) {
    //
  }
}

//participant to partial payment
function* participantToPartialPayment(action) {
  try {
    yield put(Creators.participantToPartialPaymentResponse(action.payload));
  } catch (e) {
    //
  }
}

//redirections
function* caisseRedirection(action) {
  try {
    yield put(Creators.caisseRedirectionResponse(action.payload));
  } catch (e) {
    //
  }
}

//get tickets
function* getTickets(action) {
  try {
    yield put(
      Creators.loadingCaisse({
        loadingTicket: true,
      })
    );
    const data = yield call(
      caisseService.caisseService.getTickets,
      action.payload
    );
    yield put(Creators.checkCheckoutSession(data));
    if (data.status === "error") throw new Error("exprired checkout session ");
    yield put(Creators.getTicketsResponse(data));
    yield put(Creators.loadingCaisse({ loadingTicket: false }));
  } catch (e) {
    yield put(Creators.loadingCaisse({ loadingTicket: false }));
  }
}

//store ticket
function* storeTicket(action) {
  try {
    //handle loader
    yield put(
      Creators.loadingCaisse({ successTicketStore: false, appLoading: true })
    );
    yield put(
      CreatorsReservation.loadingReservation({
        addLoading: true,
        updated: false,
        errors: null,
      })
    );

    const d = { ...action.payload };
    let currentNoteIndex = yield select(getCurrentNote);

    //store ticket
    const data = yield call(caisseService.caisseService.storeTicket, d);
    yield put(Creators.checkCheckoutSession(data));
    if (data.status === "error") throw new Error("exprired checkout session ");
    yield put(Creators.storeTicketResponse(data));
    yield put(
      Creators.loadingCaisse({ successTicketStore: true, appLoading: false })
    );

    //response from store ticket
    let ticket = { ...data.data };
    if (ticket.hasOwnProperty("error_ticket")) {
      errorToast(i18n.t("ticket_already_linked"));
      history.push("/reservation");
    } else if (ticket.hasOwnProperty("errors")) {
      let data = {
        addLoading: false,
        updated: false,
        errors: ticket.errors,
      };
      yield put(CreatorsReservation.loadingReservation({ ...data }));
    } else {
      //credit ticket case (avoir)
      if (ticket.credit_number) {
        let currentTicket = yield select(getCurrentTicket);
        if (currentTicket) {
          currentTicket.credits.push(ticket);
          yield put(Creators.updateCurrentTicketRequest({ ...currentTicket }));
        }
      }

      //convert ticket response
      let convertedTicket = convertTicket({ ...ticket });
      /**
       * Store case
       * ticket has number => store ticket
       * ticket has advance property => store advance
       * ticket has reload property => store reload
       */
      if (
        (!ticket.advance_number &&
          !ticket.credit_number &&
          ticket.is_credit_account === 0) ||
        d.hasOwnProperty("advance") ||
        d.hasOwnProperty("reload")
      ) {
        //note case
        if (
          convertedTicket.noteInProgress &&
          convertedTicket.notes &&
          convertedTicket.notes.length
        ) {
          let index = getCurrentNoteIndex(convertedTicket, currentNoteIndex);

          if (convertedTicket.notes[index].status === 1)
            index = convertedTicket.notes.findIndex((it) => it.status === 0);

          if (convertedTicket.notes.length > index && index >= 0)
            yield put(
              Creators.loadingCaisse({
                currentNote: convertedTicket.notes[index].note_index,
              })
            );
          else {
            yield put(Creators.loadingCaisse({ currentNote: null }));
            convertedTicket.noteInProgress = false;
          }
        } else {
          yield put(Creators.loadingCaisse({ currentNote: null }));
        }

        yield put(Creators.updateCurrentTicketRequest(convertedTicket));
        yield put(
          Creators.setValues({ reservationTicket: ticket.reservation })
        );
      }

      /**
       * ticket has pending property => reset currentTicket + redirect home
       * ticket has conclude property => reset currentTicket + redirect home
       */
      if (d.pending || d.conclude) {
        yield put(Creators.updateCurrentTicketRequest(null));
      }

      /**
       * create advance from reservation case + auto payment with advance
       * after create ticket and create advance (from back)
       * we have to update payment of ticket with advance ticket response (need advance_id)
       */
      if (
        convertedTicket.reservation &&
        convertedTicket.reservation.advance_ticket &&
        d.hasOwnProperty("useAdvance")
      ) {
        let payments = yield getPayments(
          convertedTicket.articles[convertedTicket.articles.length - 1],
          convertedTicket.reservation
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let current = useAdvanceInReservation(convertedTicket, payments);
        yield put(Creators.updateTicketRequest(current));
      }

      //autoload
      if (d && d.hasOwnProperty("autoLoad") && d.autoLoad) {
        let state = yield select(getCaisseState);
        if (history.location.pathname === "/caisse") {
          yield put(Creators.caisseRedirectionRequest({ value: "home" }));
        } else {
          localStorage.setItem(
            "ticket",
            JSON.stringify({ ...state, others: d.others })
          );
          const winCheckout = window.open("/caisse?auto-load=true", "_blank");
          if (winCheckout) winCheckout.focus();
        }
        yield put(
          CreatorsReservation.loadingReservation({ EncaisseLoading: false })
        );
      }

      //handel loader
      yield put(
        CreatorsReservation.loadingReservation({
          addLoading: false,
          updated: true,
          errors: null,
        })
      );
    }
  } catch (e) {
    console.log("error from storeTicket: ", e);
    yield put(Creators.loadingCaisse({ appLoading: false }));
  }
}

//update ticket
function* updateTicket(action) {
  try {
    //handle loader
    yield put(Creators.loadingCaisse({ appLoading: true }));
    yield put(
      CreatorsReservation.loadingReservation({
        addLoading: true,
        updated: false,
        errors: null,
      })
    );

    let currentNoteIndex = yield select(getCurrentNote);
    const d = { ...action.payload };
    if (d.is_credit === 0) {
      yield put(Creators.updateTicketResponse({ successTicketUpdate: false }));
    }
    const data = yield call(caisseService.caisseService.updateTicket, d);
    yield put(Creators.checkCheckoutSession(data));
    if (data.status === "error") throw new Error("exprired checkout session ");
    if (data.hasOwnProperty("errors")) {
      let data = {
        addLoading: false,
        updated: false,
        errors: data.errors,
      };
      yield put(CreatorsReservation.loadingReservation({ ...data }));
    } else {
      if (d.is_credit === 0) {
        yield put(Creators.updateTicketResponse({ successTicketUpdate: true }));
      }
      yield put(Creators.updateTicketResponse({ credits: data.data.credits }));
      yield put(Creators.loadingCaisse({ appLoading: false }));

      //response from update ticket
      let ticket = { ...data.data };

      //update ticket
      if (
        (!ticket.credit_number &&
          !ticket.advance_number &&
          ticket.is_credit_account === 0 &&
          ticket.is_reload === 0) ||
        d.hasOwnProperty("advance") ||
        d.hasOwnProperty("reload")
      ) {
        let cov = convertTicket({ ...ticket });
        //note case
        if (cov.noteInProgress && cov.notes && cov.notes.length > 0) {
          let index = getCurrentNoteIndex(cov, currentNoteIndex);

          if (cov.notes[index].status === 1)
            index = cov.notes.findIndex((it) => it.status === 0);

          if (cov.notes.length > index && index >= 0)
            yield put(
              Creators.loadingCaisse({
                currentNote: cov.notes[index].note_index,
              })
            );
          else {
            yield put(Creators.loadingCaisse({ currentNote: null }));
            cov.noteInProgress = false;
          }
        } else {
          yield put(Creators.loadingCaisse({ currentNote: null }));
        }
        yield put(Creators.updateCurrentTicketRequest(cov));
        yield put(
          Creators.setValues({ reservationTicket: ticket.reservation })
        );
      }

      /**
       * ticket has pending property => reset currentTicket + redirect home
       * ticket has conclude property => reset currentTicket + redirect home
       */
      if (d.conclude || d.pending) {
        yield put(Creators.updateCurrentTicketRequest(null));
      }

      /**
       * create advance from reservation case + auto payment with advance (choose other articles before creating reservation)
       * after update ticket and create advance (from back)
       * we have to update payment of ticket with advance ticket response (need advance_id)
       */
      let currentTicket = convertTicket({ ...ticket }, currentNoteIndex);
      if (
        currentTicket.reservation &&
        currentTicket.reservation.advance_ticket &&
        d.hasOwnProperty("useAdvance")
      ) {
        let payments = yield getPayments(
          currentTicket.articles[currentTicket.articles.length - 1],
          currentTicket.reservation
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let current = useAdvanceInReservation(currentTicket, payments);
        yield put(Creators.updateTicketRequest(current));
      }

      //handel loader
      yield put(
        CreatorsReservation.loadingReservation({
          addLoading: false,
          updated: true,
          errors: null,
        })
      );
    }
  } catch (e) {
    yield put(Creators.loadingCaisse({ appLoading: false }));
  }
}

//get ticket
function* getTicket(action) {
  try {
    yield put(
      Creators.loadingCaisse({
        loadingTicketById: true,
      })
    );
    const data = yield call(
      caisseService.caisseService.getTicket,
      action.payload.id
    );
    yield put(Creators.getTicketResponse(data));
    if (action.payload.note_index) {
      yield put(Creators.currentNoteResponse(action.payload.note_index));
      yield put(Creators.noteInProgressResponse(true));
    }
    yield put(Creators.loadingCaisse({ loadingTicketById: false }));
  } catch (e) {
    yield put(Creators.loadingCaisse({ loadingTicketById: false }));
  }
}

//destroy ticket
function* destroyTicket(action) {
  try {
    yield put(Creators.loadingCaisse({ appLoading: true }));
    const data = yield call(
      caisseService.caisseService.destroyTicket,
      action.payload
    );
    yield put(Creators.checkCheckoutSession(data));
    if (data.status === "error") throw new Error("exprired checkout session ");
    yield put(Creators.destroyTicketResponse(data));
    yield put(Creators.loadingCaisse({ appLoading: false }));
  } catch (e) {
    yield put(Creators.loadingCaisse({ appLoading: false }));
  }
}

//save cancled ticket id (edit ticket case)
function* getCancledTicket(action) {
  try {
    yield put(Creators.getCancledTicketResponse(action.payload));
  } catch (e) {
    //
  }
}

//test of duplicate ticket (edit ticket)
function* duplicateTicket(action) {
  try {
    yield put(Creators.duplicateTicketResponse(action.payload));
  } catch (e) {
    //
  }
}

//check credit
function* checkCreditNumber(action) {
  try {
    const data = yield call(
      caisseService.caisseService.checkCreditNumber,
      action.payload
    );
    yield put(Creators.checkCreditNumberResponse(data));
  } catch (e) {
    //
  }
}

//check credit
function* checkAdvanceNumber(action) {
  try {
    const data = yield call(
      caisseService.caisseService.checkAdvanceNumber,
      action.payload
    );
    yield put(Creators.checkAdvanceNumberResponse(data));
  } catch (e) {
    //
  }
}

//update restored credit list (cancel article)
function* restoredCreditList(action) {
  try {
    yield put(Creators.restoredCreditListResponse(action.payload));
  } catch (e) {
    //
  }
}

//decrement occurence number of payed article
function* decrementOccNb(action) {
  try {
    yield put(Creators.decrementOccNbResponse(action.payload));
  } catch (e) {
    //
  }
}

//current note
function* currentNote(action) {
  try {
    yield put(Creators.currentNoteResponse(action.payload));
  } catch (e) {
    //
  }
}

//pending advance
function* pendingAdvance(action) {
  try {
    yield put(Creators.pendingAdvanceResponse(action.payload));
  } catch (e) {
    //
  }
}

//pending classic sub
function* pendingClassicSub(action) {
  try {
    yield put(Creators.pendingClassicSubResponse(action.payload));
  } catch (e) {
    //
  }
}

export default function* mySaga() {
  yield takeLatest(Types.GET_DATA_REQUEST, getData);
  //yield takeLatest(Types.GET_PAYMENT_TYPES_REQUEST, getPaymentTypes);
  yield takeLatest(Types.SELECT_FAMILY_REQUEST, selectFamily);
  yield takeLatest(Types.SELECT_SUB_FAMILY_REQUEST, selectSubFamily);
  yield takeLatest(Types.SELECT_HOUR_TYPE_REQUEST, selectHourType);
  yield takeLatest(Types.UPDATE_CURRENT_TICKET_REQUEST, updateCurrentTicket);
  yield takeLatest(Types.ARTICLE_TO_PAY_REQUEST, articleToPay);
  yield takeLatest(Types.ARTICLE_TO_EDIT_PAYMENT_REQUEST, articleToEditPayment);
  yield takeLatest(
    Types.PARTICIPANT_TO_EDIT_PAYMENT_REQUEST,
    participantToEditPayment
  );
  yield takeLatest(
    Types.ARTICLE_TO_PARTIAL_PAYMENT_REQUEST,
    articleToPartialPayment
  );
  yield takeLatest(
    Types.PARTICIPANT_TO_PARTIAL_PAYMENT_REQUEST,
    participantToPartialPayment
  );
  yield takeLatest(Types.CAISSE_REDIRECTION_REQUEST, caisseRedirection);
  yield takeLatest(Types.GET_TICKETS_REQUEST, getTickets);
  yield takeLatest(Types.STORE_TICKET_REQUEST, storeTicket);
  yield takeLatest(Types.UPDATE_TICKET_REQUEST, updateTicket);
  yield takeLatest(Types.GET_TICKET_REQUEST, getTicket);
  yield takeLatest(Types.DESTROY_TICKET_REQUEST, destroyTicket);
  yield takeLatest(Types.GET_CANCLED_TICKET_REQUEST, getCancledTicket);
  yield takeLatest(Types.DUPLICATE_TICKET_REQUEST, duplicateTicket);
  yield takeLatest(Types.CHECK_CREDIT_NUMBER_REQUEST, checkCreditNumber);
  yield takeLatest(Types.CHECK_ADVANCE_NUMBER_REQUEST, checkAdvanceNumber);
  yield takeLatest(Types.RESTORED_CREDIT_LIST_REQUEST, restoredCreditList);
  yield takeLatest(Types.DECREMENT_OCC_NB_REQUEST, decrementOccNb);
  yield takeLatest(Types.STORE_INVOICE_REQUEST, storeInvoice);
  yield takeLatest(Types.INDEX_INVOICE_REQUEST, indexInvoice);
  yield takeLatest(Types.CURRENT_NOTE_REQUEST, currentNote);
  yield takeLatest(Types.PENDING_ADVANCE_REQUEST, pendingAdvance);
  yield takeLatest(Types.PENDING_CLASSIC_SUB_REQUEST, pendingClassicSub);
}

// Reducer handlers

const get_data = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    menusData: action.data.data,
  };
};

// const get_payment_types = (state = INITIAL_STATE, action) => {
//   let paymentTypes = action.data.data;
//   paymentTypes.sort(function(a, b){return a.id-b.id});
//   return {
//     ...state,
//     paymentTypes: paymentTypes,
//   };
// };

const select_family = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    selectFamily: action.data,
  };
};
const select_sub_family = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    selectSubFamily: action.data,
  };
};
const select_hour_type = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    selectHourType: action.data,
  };
};

const update_current_ticket = (state = INITIAL_STATE, action) => {
  if (action.data)
    return {
      ...state,
      currentTicket: { ...action.data },
    };
  else
    return {
      ...state,
      currentTicket: action.data,
      reservationTicket: null,
    };
};

const article_to_pay = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    articleToPay: action.data.article,
    amountToPay: action.data.amount,
    restAmountToPay: action.data.rest,
    articleToEditPayment: null,
    participantToEditPayment: null,
    // articleToPartialPayment: [],
    // participantToPartialPayment: [],
  };
};

const article_to_edit_payment = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    articleToEditPayment: action.data,
    articleToPay: INITIAL_STATE.articleToPay,
    articleToPartialPayment: INITIAL_STATE.articleToPartialPayment,
    participantToPartialPayment: INITIAL_STATE.participantToPartialPayment,
    amountToPay: INITIAL_STATE.amountToPay,
    restAmountToPay: INITIAL_STATE.restAmountToPay,
  };
};

const participant_to_edit_payment = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    participantToEditPayment: action.data,
  };
};

const article_to_partial_payment = (state = INITIAL_STATE, action) => {
  // filter participant if article exist
  let last = action.data[action.data.length - 1];
  let participants = filterParticipantsByArticle(
    last,
    state.participantToPartialPayment
  );
  return {
    ...state,
    articleToPartialPayment: action.data,
    participantToPartialPayment: participants,
  };
};

const participant_to_partial_payment = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    participantToPartialPayment: action.data,
  };
};

const caisse_redirection = (state = INITIAL_STATE, action) => {
  if (
    !state.decrementOccNb ||
    (state.decrementOccNb &&
      (state.caisse_redirection.value === "home" ||
        state.caisse_redirection.value === "edit ticket"))
  ) {
    return {
      ...state,
      caisse_redirection: action.data,
      isRedirection: false,
    };
  } else return { ...state, isRedirection: true };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

const get_tickets = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    tickets: action.data.data.data,
    last: action.data.data.last_page,
    page: action.data.data.current_page,
    total: action.data.data.total,
    size: action.data.data.per_page,
    from: action.data.data.from,
    total_price: action.data.total_price,
    total_payed: action.data.total_payed,
    total_discount: action.data.total_discount ? action.data.total_discount : 0,
    total_advance: action.data.total_advance ? action.data.total_advance : 0,
  };
};

const store_ticket = (state = INITIAL_STATE, action) => {
  // let currentTicket = state.currentTicket;
  // if (currentTicket && !currentTicket.id) {
  //   currentTicket.id = action.data.data.id;
  // }
  // if (currentTicket && !currentTicket.usedCredits) {
  //   currentTicket.usedCredits = action.data.data.usedCredits;
  // }
  return {
    ...state,
    //currentTicket : action.data.data,
    credit: action.data.data.is_credit === 1 ? action.data.data : null,
  };
};

const update_ticket = (state = INITIAL_STATE, action) => {
  // let currentTicket = state.currentTicket;
  // if (currentTicket && action.data.credits) {
  //   currentTicket.credits = action.data.credits;
  //   delete action.data.credits;
  // }
  // if (currentTicket && !currentTicket.usedCredits) {
  //   currentTicket.usedCredits = action.data.usedCredits;
  // }
  return {
    ...state,
    //currentTicket,
    ...action.data,
  };
};

const get_ticket = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currentTicket: {
      ...convertTicket({ ...action.data.data }),
      noteInProgress: false,
    },
  };
};

const destroy_ticket = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const get_cancled_ticket = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    cancledTicket: action.data,
    cancledTicketId: action.data.id,
  };
};
const duplicate_ticket = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    duplicateTicket: action.data,
  };
};
const set_values = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

const check_credit_number = (state = INITIAL_STATE, action) => {
  // if (action.data && (action.data.data).length === 0) {
  //   warningToast('Cet avoir est invalide');
  // }
  return {
    ...state,
    creditChecked: action && action.data ? action.data.data : null,
  };
};

const check_advance_number = (state = INITIAL_STATE, action) => {
  // if (action.data && (action.data.data).length === 0) {
  //   warningToast('Cet acompte est invalide');
  // }
  return {
    ...state,
    advanceChecked: action && action.data ? action.data.data : null,
  };
};

const restored_credit_list = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    restoredCreditList: action.data,
  };
};

const decrement_occ_nb = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    decrementOccNb: action.data,
    isRedirection: false,
  };
};

const resetRedicrecation = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isRedirection: false,
  };
};

const printTicketRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    printTicket: action.payload,
  };
};

const updateAllRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const updateReservationResponse = (state = INITIAL_STATE, action) => {
  // here we update the user in the current ticket and participants
  let { currentTicket } = state;
  let { article_reservation, data } = action.payload;
  for (let i = 0; i < data.reservation.participants.length; i++) {
    for (let j = 0; j < currentTicket.participants.length; j++) {
      if (
        article_reservation.toString() ===
          currentTicket.participants[j].article_id.toString() &&
        !currentTicket.participants[j].user_id
      ) {
        currentTicket.participants[j].firstName =
          data.reservation.participants[i].firstName;
        currentTicket.participants[j].lastName =
          data.reservation.participants[i].lastName;
        currentTicket.participants[j].user_id =
          data.reservation.participants[i].user_id;
        break;
      }
    }
  }

  delete currentTicket.useAdvance;

  return {
    ...state,
    currentTicket: {
      ...currentTicket,
      article_reservation: article_reservation,
      reservation: { ...data, is_ticket: 1 },
      user_id: action.payload.data.user.user_id
        ? action.payload.data.user.user_id
        : null,
    },
    reservationTicket: {
      ...data.reservation,
      user: { ...action.payload.data.user },
    },
  };
};

const printInvoiceRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    printInvoice: action.payload,
  };
};

const invoiceResponse = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};
const indexInvoiceResponse = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    invoices: action.payload.data,
    last: action.payload.last_page,
    page: action.payload.current_page,
    total: action.payload.total,
    size: action.payload.per_page,
    from: action.payload.from,
  };
};

const current_note = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currentNote: action.data,
  };
};

const note_in_progress = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currentTicket: { ...state.currentTicket, noteInProgress: action.payload },
  };
};

const pending_advance = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    pendingAdvance: action.data,
    currentTicket: { ...state.currentTicket },
  };
};

const pending_classic_sub = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    pendingClassicSub: action.data,
  };
};

const check_checkout_session = (state = INITIAL_STATE, action) => {
  return state;
};

// Reducer
export const caisseReducer = createReducer(INITIAL_STATE, {
  [Types.GET_DATA_RESPONSE]: get_data,
  //[Types.GET_PAYMENT_TYPES_RESPONSE]: get_payment_types,
  [Types.SELECT_FAMILY_RESPONSE]: select_family,
  [Types.SELECT_SUB_FAMILY_RESPONSE]: select_sub_family,
  [Types.SELECT_HOUR_TYPE_RESPONSE]: select_hour_type,
  [Types.UPDATE_CURRENT_TICKET_RESPONSE]: update_current_ticket,
  [Types.CURRENT_NOTE_RESPONSE]: current_note,
  [Types.ARTICLE_TO_PAY_RESPONSE]: article_to_pay,
  [Types.ARTICLE_TO_EDIT_PAYMENT_RESPONSE]: article_to_edit_payment,
  [Types.PARTICIPANT_TO_EDIT_PAYMENT_RESPONSE]: participant_to_edit_payment,
  [Types.ARTICLE_TO_PARTIAL_PAYMENT_RESPONSE]: article_to_partial_payment,
  [Types.PARTICIPANT_TO_PARTIAL_PAYMENT_RESPONSE]: participant_to_partial_payment,
  [Types.CAISSE_REDIRECTION_RESPONSE]: caisse_redirection,
  [Types.CAISSE_REDIRECTION_RESET]: resetRedicrecation,
  [Types.GET_TICKETS_RESPONSE]: get_tickets,
  [Types.STORE_TICKET_RESPONSE]: store_ticket,
  [Types.UPDATE_TICKET_RESPONSE]: update_ticket,
  [Types.GET_TICKET_RESPONSE]: get_ticket,
  [Types.DESTROY_TICKET_RESPONSE]: destroy_ticket,
  [Types.GET_CANCLED_TICKET_RESPONSE]: get_cancled_ticket,
  [Types.DUPLICATE_TICKET_RESPONSE]: duplicate_ticket,
  [Types.SET_VALUES]: set_values,
  [Types.LOADING_CAISSE]: loading,
  [Types.CHECK_CREDIT_NUMBER_RESPONSE]: check_credit_number,
  [Types.CHECK_ADVANCE_NUMBER_RESPONSE]: check_advance_number,
  [Types.RESTORED_CREDIT_LIST_RESPONSE]: restored_credit_list,
  [Types.DECREMENT_OCC_NB_RESPONSE]: decrement_occ_nb,
  [Types.PRINT_TICKET_REQUEST]: printTicketRequest,
  [Types.PRINT_INVOICE_REQUEST]: printInvoiceRequest,
  [Types.STORE_INVOICE_RESPONSE]: invoiceResponse,
  [Types.INDEX_INVOICE_RESPONSE]: indexInvoiceResponse,
  [Types.UPDATE_RESERVATION_RESPONSE]: updateReservationResponse,
  [Types.UPDATE_ALL]: updateAllRequest,
  [Types.NOTE_IN_PROGRESS_RESPONSE]: note_in_progress,
  [Types.PENDING_ADVANCE_RESPONSE]: pending_advance,
  [Types.PENDING_CLASSIC_SUB_RESPONSE]: pending_classic_sub,
  [Types.CHECK_CHECKOUT_SESSION]: check_checkout_session,
});
