import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import creditBonusService from '@/services/creditBonusService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingCreditBonus: ['payload'],

  indexRequestCreditBonus: ['payload'],
  indexResponseCreditBonus: ['data'],

  storeRequestCreditBonus: ['payload'],
  storeResponseCreditBonus: ['data'],

  editRequestCreditBonus: ['id'],
  editResponseCreditBonus: ['data'],

  updateRequestCreditBonus: ['payload'],
  updateResponseCreditBonus: ['data'],

  destroyRequestCreditBonus: ['id'],
  destroyResponseCreditBonus: ['data'],
});

// Initial state
const INITIAL_STATE = {
  creditBonuses: [],
  creditBonus: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingCreditBonus({ loading: true }));
    const data = yield call(creditBonusService.creditBonusService.index, action.payload);
    yield put(Creators.indexResponseCreditBonus(data));
    yield put(Creators.loadingCreditBonus({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingCreditBonus({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingCreditBonus({ loading: true }));
    const data = yield call(creditBonusService.creditBonusService.store, action.payload);
    yield put(Creators.storeResponseCreditBonus(data));
    yield put(Creators.loadingCreditBonus({ loading: false }));
    yield history.push('/creditBonuses');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingCreditBonus({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingCreditBonus({ editLoading: true }));
    const data = yield call(creditBonusService.creditBonusService.edit, action.payload);
    yield put(Creators.editResponseCreditBonus(data));
    yield put(Creators.loadingCreditBonus({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingCreditBonus({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingCreditBonus({ loading: true }));
    const data = yield call(creditBonusService.creditBonusService.update, action.payload);
    yield put(Creators.updateResponseCreditBonus(data));
    yield put(Creators.loadingCreditBonus({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/creditBonuses');
  } catch (e) {
    yield put(
      Creators.loadingCreditBonus({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingCreditBonus({ deleteLoading: true }));
    const data = yield call(creditBonusService.creditBonusService.destroy, action.payload);
    yield put(Creators.destroyResponseCreditBonus(data));
    yield put(Creators.loadingCreditBonus({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CREDIT_BONUS, index);
  yield takeLatest(Types.STORE_REQUEST_CREDIT_BONUS, store);
  yield takeLatest(Types.EDIT_REQUEST_CREDIT_BONUS, edit);
  yield takeLatest(Types.UPDATE_REQUEST_CREDIT_BONUS, update);
  yield takeLatest(Types.DESTROY_REQUEST_CREDIT_BONUS, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    creditBonuses: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    creditBonus: action.data.data,
  };
};
//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding_credit_bonus = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const creditBonusReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_CREDIT_BONUS]: index_response,
  [Types.STORE_RESPONSE_CREDIT_BONUS]: store_response,
  [Types.EDIT_RESPONSE_CREDIT_BONUS]: edit_response,
  [Types.UPDATE_RESPONSE_CREDIT_BONUS]: update_response,
  [Types.DESTROY_REQUEST_CREDIT_BONUS]: destroy_request,
  [Types.DESTROY_RESPONSE_CREDIT_BONUS]: destroy_response,
  [Types.LOADING_CREDIT_BONUS]: laoding_credit_bonus,
});
