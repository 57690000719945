import React from 'react';
import './Sidemenu.css';
import MenuItem from '../MenuItem/MenuItem';
import { newMenu } from '@/utils/menu';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { url } from '../../helpers';
import Image from '../ImageComponent/Image';

const hasModule = (modules, slug) => {
  for (let i = 0; i < modules.length; i++) if (modules[i].slug === slug) return modules[i];

  return null;
};

const checkModule = (slug, modules) => {
  for (let i = 0; i < modules.length; i++) if (slug === modules[i].slug) return true;

  return false;
};

const dashboardMenu = ['configuration', 'support'];

const Sidemenu = React.memo((props) => {
  const { t } = useTranslation();

  let modules = useSelector(
    (state) =>
      state.auth.selected && state.auth.selected.modules ? state.auth.selected.modules : [],
    [],
  );

  let menu = newMenu(t);
  return (
    <div
      className={[
        props.width === 60 || props.isMobile ? 'fixed-side side-mobile' : 'fixed-side',
        props.module === 'dashboard' ? 'dashboard-menu' : '',
      ].join(' ')}
      style={{ minWidth: props.isMobile ? 60 : props.width, maxWidth: 250 }}
    >
      <div className="overflow-visible">
        <div className="logo">
          <Link to={'/'}>
            {props.isMobile || props.width === 60 ?
              <img src={require('../../assets/logo-small.svg')} alt={'INEO'}/>
              :
              <img src={require('../../assets/logo.svg')} alt={'INEO'}/>
            }
          </Link>
        </div>

        {props.module === 'dashboard' && (
          <div className={'dashboard-welcome'}>
            <span className={'welcome'}>{t("welcome_home")}</span>
          </div>
        )}

        <div
          className={['menu', props.module === 'dashboard' ? 'dashboard-menu-container' : ''].join(
            ' ',
          )}
        >
          {props.module !== 'dashboard'
            ? menu.map((item, i) => {
              if (item.sidebar) {
                let module = hasModule(modules, item.module);

                if (
                  (module !== null && item.module === props.module) ||
                  item.module === 'dashboard'
                )
                  return (
                    <MenuItem
                      module={module}
                      width={props.width}
                      item={item}
                      key={`menu-item-${i}`}
                      end={i === menu.length - 1}
                    />
                  );
              } else {
                return null;
              }
            })
            : props.allModules
              .map((it, i) => {
                let isAvailable = checkModule(it.slug, modules);
                if (dashboardMenu.includes(it.slug))
                  if (isAvailable)
                    return (
                      <NavLink
                        className={'dashboard-menu-item'}
                        key={'menu-' + i}
                        to={'/' + it.slug}
                      >
                        <div className={'menu-dashboard'} style={{ background: it.background }}>
                          <Image
                            draggable={false}
                            type={'item'} src={url + '/uploads/modules/' + it.avatar} alt={it.slug}/>
                          <h3>{t(it.slug)}</h3>
                        </div>
                      </NavLink>
                    );
                  else
                    return (
                      <span key={'menu-' + i} className={'dashboard-item-link'}>
                          <div
                            className={'menu-dashboard'}
                            style={{
                              background: it.slug === 'reporting' ? '#21212166' : '#21212144',
                              color: it.slug === 'reporting' ? '#C4C4C4' : '#FFFFFF',
                            }}
                          >
                            <img
                              draggable={false}
                              src={url + '/uploads/modules/' + it.avatar} alt={it.slug}/>
                            <h3>{t(it.slug)}</h3>
                          </div>
                        </span>
                    );
              })
              .reverse()}
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => {
  return {
    allModules: state.auth.all_modules,
    modules: state.auth.selected && state.auth.selected.modules ? state.auth.selected.modules : [],
  };
};

export default connect(mapStateToProps)(Sidemenu);
