import React from "react";
import { Button, Icon, Image } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { imgUrl } from "@/helpers";
import { convertTicket } from "@/helpers/ticket";
import { PrintPopup, ticketPrint } from "../../../helpers/print";
import moment from "moment";
import localization from "moment/locale/fr";
import {
  formatCodeCountry,
  nullString,
  phoneNumber,
} from "../../../helpers/formatting";
import {
  convertTicketToNote,
  getPaymentNoteFromTicket,
  hasParticipantsNote,
} from "@/helpers/caisse/noteFormatting";
import i18n from "@/i18n";
import ModalConfirm from "../../ModalConfirm/ModalConfirm";
import CustomInput from "../../CustomInput/CustomInput";
import caisseService from "../../../services/caisseService";
import InvoiceModal from "../Modal/InvoiceModal";

class TicketListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      other: 0,
      modalOpen: false,
      showNotes: false,
      selected: null,
      ticket: null,
      printTicketModalOpen: false,
      printInvoiceModalOpen: false,
      is_note: false,
      noteToPrint: null,
      note_i: null,
      without_details: false,
      info: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.modalOpen &&
      !this.props.loading &&
      this.props.loading !== prevProps.loading
    ) {
      this.handleClose();
    }
  }

  renderDefaultPaymentType(payments, payed_amount) {
    let other = parseFloat(payed_amount);
    if (this.props.paymentTypes) {
      let default_types = this.props.paymentTypes.map((item, index) => {
        if (item.is_show === 1) {
          let payment_type_list = Object.entries(payments).length
            ? payments.filter((itm) =>
                itm.pivot
                  ? itm.pivot.payment_type_id.toString() === item.id.toString()
                  : itm.payment_type_id.toString() === item.id.toString()
              )
            : [];
          let amount = Object.entries(payment_type_list).length
            ? payment_type_list[0].pivot
              ? payment_type_list[0].pivot.amount
              : payment_type_list[0].amount
            : 0;
          other =
            parseFloat(other) - parseFloat(amount) > 0
              ? parseFloat(other) - parseFloat(amount)
              : 0;

          return (
            <div
              key={index}
              className="ticket-price"
              style={{ borderRight: "1px dotted rgba(11, 108, 255, 0.65)" }}
            >
              <Image
                style={{ filter: "invert(80%)" }}
                src={
                  item.icon
                    ? imgUrl + "/payments/" + item.icon
                    : require("@/assets/other.svg")
                }
              />
              <span>
                {amount}
                {localStorage.getItem("currency")}
              </span>
            </div>
          );
        }
      });
      let other_types = (
        <div
          key={"other"}
          className="ticket-price"
          style={{ borderRight: "1px dotted rgba(11, 108, 255, 0.65)" }}
        >
          <Image
            src={require("../../../assets/other_white.svg")}
            style={{ filter: "invert(80%)" }}
          />
          <span>
            {parseFloat(other).toFixed(2)}
            {localStorage.getItem("currency")}
          </span>
        </div>
      );
      return [default_types, other_types];
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false, selected: null });
  };

  renderFoodList(small = false, articles) {
    let parent_array = [];
    let foodList = [];
    articles.forEach((item, index) => {
      if (
        item.parent.is_activity === 0 ||
        (item.parent.is_activity === 1 && item.child.is_res === 0)
      ) {
        if (!parent_array.includes(item.parent.id)) {
          foodList.push(
            <span key={"food" + index}>
              <span className={"center-img"}>
                <Image
                  src={imgUrl + "/checkout/parents/" + item.parent.avatar}
                  style={{
                    height: small ? "18px" : "30px",
                    filter: "invert(80%)",
                  }}
                />
              </span>
              {!small && item.parent.name}
            </span>
          );
        }
        parent_array.push(item.parent.id);
      }
    });
    if (small) return foodList;
    //to delete after adjust all ticket with reservation
    if (!foodList.length) {
      foodList.push(
        <div className="ticket-amount" key={"x"}>
          <b style={{ fontSize: "14px" }}>{i18n.t("activity")}</b>
          <span style={{ fontSize: "13px" }}>
            {articles[0] && articles[0].name}
            <br />
          </span>
        </div>
      );
    }
    //
    return foodList;
  }

  renderNotes = (notes = []) => {
    let color = "rgba(11, 108, 255, 0.65)";
    let ticket = convertTicket(this.props.ticket);
    return (
      <div className={"ticket-list-notes"}>
        {notes.map((note, i) => {
          // let note = convertTicketToNote(convertTicket(this.props.ticket), it.note_index);
          let articlesNote = ticket.articles.filter(
            (itm) =>
              itm.note_index === note.note_index ||
              (itm.parent &&
                itm.parent.is_activity === 1 &&
                hasParticipantsNote(ticket, itm.article_id, note.note_index))
          );
          let activitiesNote = articlesNote.filter(
            (itm) => itm.parent.is_activity === 1
          );
          let paymentsNote = getPaymentNoteFromTicket(
            ticket.payments.articles,
            note.note_index
          );
          return (
            <div
              className={
                this.props.caisse_redirection.value === "ticket"
                  ? "ticket-list-item-note"
                  : "ticket-list-item-note-details"
              }
              style={{ color: color }}
              key={"note-" + i + ticket.id}
            >
              <div
                className="ticket-family"
                style={{ backgroundColor: "rgb(78,78,78)" }}
              />
              {note.user ? (
                <div
                  className="ticket-sub-family"
                  style={{ borderRight: "1px dotted " + color }}
                >
                  <Image
                    className="ticket-avatar avatar-cover"
                    src={
                      note.user.avatar
                        ? imgUrl + "/users/" + note.user.avatar
                        : require("../../../assets/avatar.svg")
                    }
                    avatar
                  />
                  <span>
                    {note.user_id && note.user && (
                      <>
                        {note.user.firstName}
                        <br />
                        {nullString(note.user.lastName).toUpperCase()}
                        <br />
                        {formatCodeCountry(note.user.country_code) +
                          phoneNumber(note.user.mobile)}
                      </>
                    )}
                  </span>
                </div>
              ) : (
                <div
                  className="ticket-sub-family"
                  style={{ borderRight: "1px dotted " + color }}
                >
                  <Image
                    className="ticket-avatar avatar-cover"
                    src={require("../../../assets/avatar.svg")}
                    avatar
                  />
                  <span>{i18n.t("player")}</span>
                </div>
              )}
              {activitiesNote.length > 0 &&
              this.props.caisse_redirection.value === "ticket" ? (
                <div className={"ticket-activity-container"}>
                  <div
                    className="ticket-activity"
                    style={{ borderRight: "1px dotted " + color }}
                  >
                    <b>{i18n.t("activity")}</b>
                    <span style={{ fontSize: "13px" }}>
                      {activitiesNote[0] && activitiesNote[0].name}
                    </span>
                  </div>
                  {this.renderFoodList(true, articlesNote).length > 0 && (
                    <div
                      className="ticket-food"
                      style={{
                        fontSize: "12px",
                        borderRight: "1px dotted " + color,
                      }}
                    >
                      {this.renderFoodList(true, articlesNote)}
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {this.props.caisse_redirection.value === "ticket" && (
                    <div
                      className="ticket-food"
                      style={{
                        fontSize: "12px",
                        borderRight: "1px dotted " + color,
                      }}
                    >
                      {this.renderFoodList(false, articlesNote)}
                    </div>
                  )}
                </>
              )}
              <div
                className="ticket-amount"
                style={{ borderRight: "1px dotted " + color }}
              >
                <b>
                  {note.articles_nb > 1
                    ? i18n.t("articles")
                    : i18n.t("article")}
                </b>
                <span>{note.articles_nb}</span>
              </div>
              <div
                className="ticket-amount"
                style={{ borderRight: "1px dotted " + color }}
              >
                <b>{i18n.t("amount")}</b>
                <span>
                  {parseFloat(note.amount).toFixed(2)}
                  {localStorage.getItem("currency")}
                </span>
              </div>
              {this.props.caisse_redirection.value === "ticket" && (
                <div
                  className="ticket-amount"
                  style={{ borderRight: "1px dotted " + color }}
                >
                  {this.props.type === 1 ? (
                    <>
                      <b>{i18n.t("rest_to_pay")}</b>
                      <span>
                        {parseFloat(note.amount) -
                          parseFloat(note.payed_amount) -
                          parseFloat(note.discount) >
                        0
                          ? (
                              parseFloat(note.amount) -
                              parseFloat(note.payed_amount) -
                              parseFloat(note.discount)
                            ).toFixed(2)
                          : 0}
                        {localStorage.getItem("currency")}
                      </span>
                    </>
                  ) : (
                    <>
                      <b>{i18n.t("discount")}</b>
                      <span>
                        {parseFloat(note.discount) > 0
                          ? parseFloat(note.discount).toFixed(2) +
                            localStorage.getItem("currency")
                          : "-"}
                      </span>
                    </>
                  )}
                </div>
              )}
              {this.props.caisse_redirection.value === "ticket" &&
                this.renderDefaultPaymentType(paymentsNote, note.payed_amount)}
              <div className="ticket-buttons">
                {this.props.ticket.status === 1 && (
                  <Button
                    className="ticket-btn-detail"
                    onClick={() =>
                      this.getTicketDetail(ticket.id, note.note_index)
                    }
                  >
                    {i18n.t("detail")}
                  </Button>
                )}
                {ticket.status === 0 && (
                  <Button
                    content={i18n.t("printing")}
                    className="ticket-btn-edit"
                    onClick={async () => {
                      this.setState({
                        printTicketModalOpen: true,
                        is_note: true,
                        noteToPrint: note,
                        note_i: i,
                      });
                    }}
                  />
                )}
                {ticket.status === 0 && (
                  <Button
                    content={i18n.t("invoice")}
                    className="ticket-btn-payment"
                    onClick={async () => {
                      this.setState({
                        is_note: true,
                        note_i: i,
                      });
                      await this.printInvoice();
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  /**
   * Ticket detail
   * @param {*} id
   * @param {*} note_index
   */
  getTicketDetail = async (id, note_index = null) => {
    await this.props.getTicket(id, note_index);
    this.props.article_to_pay([], 0, 0);
  };

  /**
   * Ticket payment
   */
  getTicketPayment = async () => {
    let data = await caisseService.caisseService.getTicket(
      this.props.ticket.id
    );
    let ticket = convertTicket(data.data);

    if (ticket.status === 1) {
      this.props.caisseRedirection("payment");
    }
    this.props.updateCurrentTicket(ticket);
  };

  /**
   * Print ticket
   * @param {*} with_details
   */
  printTicket = async (with_details) => {
    let data = await caisseService.caisseService.getTicket(
      this.props.ticket.id
    );
    let ticket = convertTicket(data.data);
    let number = null;
    let note = null;
    if (this.state.is_note) {
      note = convertTicketToNote(
        ticket,
        ticket.notes[this.state.note_i].note_index
      );
      number = ticket.number + "/N" + (this.state.note_i + 1);
    }
    let complex = this.props.complex;
    let receipt = ticketPrint(
      note ? note : ticket,
      complex,
      this.props.ticketInfos,
      number,
      with_details,
      this.state.info
    );
    await PrintPopup(receipt);
    this.setState({
      printTicketModalOpen: false,
      noteToPrint: null,
      is_note: false,
      note_i: null,
      without_details: false,
      info: "",
    });
  };

  /**
   * Print Invoice
   * @param {*} with_details
   */
  printInvoice = async (with_details) => {
    let data = await caisseService.caisseService.getTicket(
      this.props.ticket.id
    );
    let ticket = convertTicket(data.data);
    this.setState({
      ticket,
      printInvoiceModalOpen: true,
      with_details,
    });
  };

  renderPrintTicketModal() {
    return (
      <ModalConfirm
        open={this.state.printTicketModalOpen}
        onClose={() => this.setState({ printTicketModalOpen: false })}
        title={i18n.t("print")}
        message={<p>Voulez vous imprimer le ticket avec détails?</p>}
        input={
          this.state.without_details ? (
            <CustomInput
              name={"info"}
              label={i18n.t("info")}
              type={"textArea"}
              value={this.state.info}
              onChange={(e) => this.setState({ info: e.target.value })}
              textAreaStyle={{
                backgroundColor: "white",
                border: "1px solid rgba(34,36,38,.15)",
                color: "rgba(0,0,0,.8)",
                height: "50px",
                width: "60%",
              }}
            />
          ) : (
            ""
          )
        }
        okText={i18n.t("yes")}
        cancelText={i18n.t("no")}
        cancel={() => this.printTicket(false)}
        ok={async () => {
          if (this.state.without_details) {
            await this.printTicket(true);
            //this.setState({without_details : false , info : ''});
          } else {
            this.setState({ without_details: true });
          }
        }}
      />
    );
  }

  /** */

  render() {
    let color = "rgba(11, 108, 255, 0.65)";
    let ticket = this.props.ticket;

    let activities = ticket.articles.filter(itm => itm.parent.is_activity === 1);
    let colorType = {backgroundColor: 'rgba(11, 108, 255, 0.65)'};
    if(this.props.type === 0) {
      colorType = {backgroundColor: 'rgb(0, 184, 118)'};
    }
    else if(this.props.type === 2){
      colorType = {backgroundColor: '#FF7070'};
    }
    
    return (
      <div className={"ticket-list-main"}>
        {this.state.printTicketModalOpen && this.renderPrintTicketModal()}
        <div
          className={
            this.props.caisse_redirection.value === "ticket"
              ? ticket.user
                ? "ticket-list-item-reservation"
                : "ticket-list-item"
              : ticket.user
              ? "ticket-list-item-details-reservation"
              : "ticket-list-item-details"
          }
          style={{ color: color }}
        >
          <div
            className="ticket-family"
            style={colorType}
          />
          <div
            className="ticket-information"
            style={{ borderRight: "1px dotted " + color }}
          >
            <b>N° {this.props.ticket.number}</b>
            <span>
              {i18n.t("createdAt")}{" "}
              {moment(this.props.ticket.created_at)
                .locale(i18n.language, localization)
                .format("DD-MM-YYYY à HH:mm")}
            </span>
            {this.props.ticket.owner && (
              <span>
                {i18n.t("createdBy")}{" "}
                {this.props.ticket.owner.firstName +
                  " " +
                  nullString(this.props.ticket.owner.lastName).toUpperCase()}
              </span>
            )}
          </div>
          {ticket.user && (
            <div
              className="ticket-sub-family"
              style={{ borderRight: "1px dotted " + color }}
            >
              <Image
                className="ticket-avatar avatar-cover"
                src={
                  ticket.user.avatar
                    ? imgUrl + "/users/" + ticket.user.avatar
                    : require("../../../assets/avatar.svg")
                }
                avatar
              />
              <span>
                {ticket.user_id && ticket.user && (
                  <>
                    {ticket.user.firstName}
                    <br />
                    {nullString(ticket.user.lastName).toUpperCase()}
                    <br />
                    {formatCodeCountry(ticket.user.country_code) +
                      phoneNumber(ticket.user.mobile)}
                  </>
                )}
              </span>
            </div>
          )}

          {ticket.user && this.props.caisse_redirection.value === "ticket" ? (
            <div className={"ticket-activity-container"}>
              {ticket.reservation && (
                <div className={"ticket-activity-container"}>
                  <div
                    className="ticket-activity"
                    style={{
                      borderRight: "1px dotted " + color,
                      paddingRight: 4,
                      paddingLeft: 4,
                    }}
                  >
                    <b>{i18n.t("field")}</b>
                    <span style={{ fontSize: "13px" }}>
                      {ticket.reservation.terrain &&
                        ticket.reservation.terrain.name}
                    </span>
                  </div>
                </div>
              )}
              {activities.length > 0 && (
                <div
                  className="ticket-activity"
                  style={{ borderRight: "1px dotted " + color }}
                >
                  <b>{i18n.t("activity")}</b>
                  <span style={{ fontSize: "13px" }}>
                    {activities[0] && activities[0].name}
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    {ticket.reservation &&
                      moment(ticket.reservation.date).format("DD-MM-YYYY")}
                    <br />
                    {ticket.reservation &&
                      ticket.reservation.start_hour +
                        "-" +
                        ticket.reservation.end_hour}
                  </span>
                </div>
              )}
              {this.props.caisse_redirection.value === "ticket" &&
                this.renderFoodList(true, this.props.ticket.articles).length >
                  0 && (
                  <div
                    className="ticket-food"
                    style={{
                      fontSize: "12px",
                      borderRight: "1px dotted " + color,
                    }}
                  >
                    {/* <div className={"ticket-food-container"}> */}
                    {this.renderFoodList(true, this.props.ticket.articles)}
                    {/* </div> */}
                  </div>
                )}
            </div>
          ) : (
            <>
              {this.props.caisse_redirection.value === "ticket" && (
                <div
                  className="ticket-food"
                  style={{
                    fontSize: "12px",
                    borderRight: "1px dotted " + color,
                  }}
                >
                  {this.renderFoodList(false, this.props.ticket.articles)}
                </div>
              )}
            </>
          )}
          <div
            className="ticket-amount"
            style={{ borderRight: "1px dotted " + color }}
          >
            <b>
              {this.props.ticket.articles_nb > 1
                ? i18n.t("articles")
                : i18n.t("article")}
            </b>
            <span>{this.props.ticket.articles_nb}</span>
          </div>
          <div
            className="ticket-amount"
            style={{ borderRight: "1px dotted " + color }}
          >
            <b>{i18n.t("amount")}</b>
            <span>
              {parseFloat(this.props.ticket.amount).toFixed(2)}
              {localStorage.getItem("currency")}
            </span>
          </div>
          {this.props.caisse_redirection.value === "ticket" && (
            <div
              className="ticket-amount"
              style={{ borderRight: "1px dotted " + color }}
            >
              {this.props.type === 1 ? (
                <>
                  <b>{i18n.t("still-un-payed")}</b>
                  <span>
                    {parseFloat(this.props.ticket.amount) -
                      parseFloat(this.props.ticket.payed_amount) -
                      parseFloat(this.props.ticket.discount) >
                    0
                      ? (
                          parseFloat(this.props.ticket.amount) -
                          parseFloat(this.props.ticket.payed_amount) -
                          parseFloat(this.props.ticket.discount)
                        ).toFixed(2)
                      : 0}
                    {localStorage.getItem("currency")}
                  </span>
                </>
              ) : (
                <>
                  <b>{i18n.t("discount")}</b>
                  <span>
                    {parseFloat(this.props.ticket.discount) > 0
                      ? parseFloat(this.props.ticket.discount).toFixed(2) +
                        localStorage.getItem("currency")
                      : "-"}
                  </span>
                </>
              )}
            </div>
          )}

          {this.props.caisse_redirection.value === "ticket" &&
            this.renderDefaultPaymentType(
              ticket.payments.types,
              ticket.payed_amount
            )}
          <div className="ticket-buttons">
            <Button
              className="ticket-btn-detail"
              onClick={async () => {
                await this.getTicketDetail(this.props.ticket.id);
                this.props.updateCurrentNote(null);
              }}
            >
              {i18n.t("detail")}
            </Button>
            {this.props.type === 0 && (
              <Button
                content={i18n.t("printing")}
                className="ticket-btn-edit"
                onClick={async () => {
                  this.setState({ printTicketModalOpen: true });
                }}
              />
            )}
            {this.props.ticket.status === 1 && (
              <Button
                className="ticket-btn-edit"
                onClick={() => this.getTicketPayment()}
                disabled={
                  parseFloat(this.props.ticket.amount) <=
                  parseFloat(this.props.ticket.payed_amount) +
                    parseFloat(this.props.ticket.discount)
                }
              >
                {i18n.t("payment")}
              </Button>
            )}

            {ticket.notes && ticket.notes.length > 0 && (
              <span className={"ticket-icon-note"}>
                <Icon
                  circular={true}
                  className={`angle ${!this.state.showNotes ? " down" : " up"}`}
                  onClick={() =>
                    this.setState({ showNotes: !this.state.showNotes })
                  }
                />
              </span>
            )}
          </div>
        </div>

        {this.state.showNotes && this.renderNotes(ticket.notes)}
        <InvoiceModal
          ticket={this.state.ticket}
          open={this.state.printInvoiceModalOpen}
          noteIndex={
            this.state.note_i !== null
              ? ticket.notes[this.state.note_i].note_index
              : ""
          }
          onClose={() => {
            this.setState({
              printInvoiceModalOpen: false,
              noteToPrint: null,
              is_note: false,
              note_i: null,
              without_details: false,
              info: "",
            });
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTicket: (data) =>
    dispatch({ type: "UPDATE_CURRENT_TICKET_REQUEST", payload: data }),
  updateCurrentNote: (id) =>
    dispatch({ type: "CURRENT_NOTE_REQUEST", payload: id }),
  caisseRedirection: (value, status = null, type = null, date = null) =>
    dispatch({
      type: "CAISSE_REDIRECTION_REQUEST",
      payload: { value, status, type, date },
    }),
  storeTicket: (data) =>
    dispatch({ type: "STORE_TICKET_REQUEST", payload: data }),
  updateTicket: (data) =>
    dispatch({ type: "UPDATE_TICKET_REQUEST", payload: data }),
  getCancledTicket: (id) =>
    dispatch({ type: "GET_CANCLED_TICKET_REQUEST", payload: id }),
  article_to_pay: (article, amount, rest) =>
    dispatch({
      type: "ARTICLE_TO_PAY_REQUEST",
      payload: { article, amount, rest },
    }),
  getTicket: (id, note_index = null) =>
    dispatch({ type: "GET_TICKET_REQUEST", payload: { id, note_index } }),
  updateNoteInProgress: (payload) =>
    dispatch({ type: "NOTE_IN_PROGRESS_RESPONSE", payload }),
});

const mapStateToProps = (state) => {
  let paymentTypes = state.paymentMethod.paymentMethods;
  paymentTypes.sort(function (a, b) {
    return a.id - b.id;
  });
  return {
    loading: state.caisse.appLoading,
    currentTicket: state.caisse.currentTicket,
    selectArticle: state.caisse.selectArticle,
    article_in_payment: state.caisse.article_in_payment,
    paymentTypes: paymentTypes,
    caisse_redirection: state.caisse.caisse_redirection,
    complex: state.auth.selected,
    ticketInfos: state.ticketInfo.ticketInfos,
    currentNote: state.caisse.currentNote,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TicketListItem)
);
