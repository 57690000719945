import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  mixedHourService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/mixedHours?complex_id=' + localStorage.getItem('complex_id') +
        (page ? '&page=' + page : '') +
        (size ? '&size=' + size : '') +
        (search && search.search_name ? '&search_name=' + search.search_name : ''),
      );
      return result.data;
    },
    store: async (data) => {
      const result = await postMethod('/mixedHours', data);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/mixedHours/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/mixedHours/' + data.id, data);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/mixedHours/' + id);
      return result.data;
    },
  },
};
