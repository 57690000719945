export const ticketAccordionStyle = {
    'payed': {
      background: 'rgba(0, 184, 118, 0.1)',
      border: '1px solid #00B876',
      color: '#00B876',
    },
    'partial': {
      background: 'rgba(0, 102, 255, 0.1)',
      border: '1px solid rgb(0, 102, 255)',
      color: 'rgb(0, 102, 255)',
    },
    'default': {
      background: '#FFFFFF',
      border: '1px solid rgba(11, 108, 255, 0.65)',
      color: 'rgba(11, 108, 255, 0.65)',
    },
    'selected': {
      background: 'rgba(173,0,255,0.1)',
      border: '1px solid #AD00FF',
      color: '#AD00FF',
    },
    'edited': {
      background: '#00B876',
      // border: '1px solid #FFFFFF',
      color: '#FFFFFF',
    },
  };
  
  export const ticketMainAccordionStyle = {
    'payed': {
      background: 'rgba(0, 184, 118, 0.1)', color: '#00B876',
    },
    'default': {
      background: '#FFFFFF', color: 'rgba(11, 108, 255, 0.65)',
    },
    'cancled': {
      background: '#FFFFFF', color: 'rgba(11, 108, 255, 0.65)', opacity: '0.4',
    },
    'selected': {
      background: 'rgba(173,0,255,0.1)',
      border: '1px solid #AD00FF',
      color: '#AD00FF',
    },
    'partial': {
      background: 'rgba(0, 102, 255, 0.1)', color: 'rgb(0, 102, 255)',
    },
    'edited': {
      background: '#00B876',
      border: '1px solid #FFFFFF',
      color: '#FFFFFF',
    },
  };