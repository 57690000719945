import moment from "moment";
import * as fr from "moment/locale/fr";
import * as ar from "moment/locale/ar";
import * as en from "moment/locale/en-ca";

export const getLocalisation = () => {
  let lang = localStorage.getItem("lang");

  if (lang === "ar") return [lang, ar];
  else if (lang === "en") return [lang, en];

  return [lang, fr];
};

export const nullString = (s) => {
  if (s === null || s === undefined || s === "null") return "";
  return s.toString();
};

export const nullStringNumber = (s) => {
  if (s === null || s === undefined || s === "null") return 0;
  try {
    return parseFloat(s);
  } catch (e) {
    return 0;
  }
};

export const toFloat = (s) => {
  if (s === null || s === "") return 0;
  return parseFloat(s);
};

export const nullStringForTable = (s) => {
  if (s === null || s === undefined || s === "null") return "---";
  return s;
};

export const formattingToDropdown = (data, text) => {
  return data.map((it, i) => {
    return { key: it.id, text: it[text], value: it.id };
  });
};

export const isAdmin = (roles) => roles && roles.filter(it => it.slug === "admin").length;

export const randomstring = () => Math.random().toString(36).slice(-8);

export const formattingDate = (date) => {
  let data = moment(date);
  return data.format("YYYY-MM-DD");
};

export const normalDate = (date) => {
  return date && moment(nullString(date.split("-").reverse().join("-"))).format("YYYY-MM-DD");
};

export const abbreviation = (data) => {
  let arr = data.split(" ");
  let abb = "";
  for (let index = 0; index < arr.length; index++) {
    abb = abb + arr[index].charAt(0);
  }
  return abb.toUpperCase();
};

export const toMb = (oct) => {
  try {
    return oct / 1024 / 1024;
  } catch (e) {
    return 0;
  }
};
export const toDate = (date) => {
  let arr = date.split(" ");
  arr[0] = capitalize(arr[0]);
  arr[2] = capitalize(arr[2]);
  arr.pop();
  return arr.join(" ");
};
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isEmpty = (obj) => {
  return obj === undefined || Object.keys(obj).length === 0;
};

export const phoneFormat = (phone) => {
  let cleaned = ("" + phone).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);
  if (match) {
    return (
      match[1] + "." + match[2] + "." + match[3] + "." + match[4] + (match[5] ? "." + match[5] : "")
    );
  }
  return phone;
};
export const checkModule = (slug, modules) => {
  if (modules)
    for (let i = 0; i < modules.length; i++) if (slug === modules[i].slug) return true;

  return false;
};

export const isNumeric = (str) => {
  if (typeof str != "string") return false;
  if (str === "") return true;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

export const isDecimal = (val) => {
  return val % 1 === 0 ? false : true;
}

// export const isPositive = (str) => {
//   if (isNumeric(str) && parseInt(str) >= 0)
//     return true;
//   return false;
// };

export const floorNumber = (val) => {
  return Math.floor((val + Number.EPSILON) * 10) / 10;
};

// convert duration to minute ( 02:00 to 120 )
export const toMinutes = (duration) => {
    try {
      let durations = duration.split(":");
      return parseInt(durations[0]) * 60 + parseInt(durations[1]);
    } catch (e) {
      return 0;
    }
  }
;// convert reverse toMinutes
export const toDuration = (minutes) => {
  try {
    let hour = Math.floor(minutes / 60);
    let minute = minutes % 60;
    if (hour.toString().length === 1) hour = "0" + hour.toString();
    if (minute.toString().length === 1) minute = "0" + minute.toString();
    return hour + ":" + minute;

  } catch (e) {
    return null;
  }
};

// convert  12345678 to 12 34 56 78
export const phoneNumber = (phone) => {
  if (phone === null || phone === undefined) return "";
  let spliter = phone.split("");
  let spliterWithout = [];
  let result = "";
  for (let i = 0; i < spliter.length; i++) {
    if (spliter[i] !== " ") {
      spliterWithout.push(spliter[i]);
    }
  }

  result += spliterWithout.length > 0 ? spliterWithout[0] + " " : " ";
  for (let i = 1; i < spliterWithout.length; i++) {
    if (i % 2 !== 0 && i > 0) result += " ";
    result += spliterWithout[i];
  }
  return result;
};

// convert  12 34 56 78 to 12345678
export const reversePhoneNumber = (phone) => {
  if (phone === null || phone === undefined) return "";
  let spliter = phone.split("");
  let spliterWithout = [];
  let result = "";
  for (let i = 0; i < spliter.length; i++) {
    if (spliter[i] !== " ") {
      spliterWithout.push(spliter[i]);
    }
  }
  return spliterWithout.join("");
};

//custom round for discount
export const customRound = (val) => {
  if (val.toString().indexOf(".") !== -1 || val.toString().indexOf(",") !== -1) {
    val = Math.floor((parseFloat(val) + Number.EPSILON) * 100) / 100;
    let last = val.toString().substr(val.toString().length - 1);
    if (parseInt(last) !== 5) {
      val = Math.floor((val + Number.EPSILON) * 10) / 10;
    }
  }
  // if val less than 0.05 return 0.00
  return parseFloat(val).toFixed(2); //ex 1.99 ->1.90 // 1.91 ->1.90 //1.95 ->1.95
};

// this function keep 15.50 , 15.00 -> 15
export const roundOnlyIfAvailable = (value) => {
  try {
    if (value) {
      value = Number.parseFloat(value).toFixed(2);
      let splits = value.split(".");
      if (splits.length === 2 && splits[1] === "00") return splits[0];
    }
    return value;
  } catch (e) {
    return 0;
  }
};

// get payment not the is_subscription is_card
export const getTotalPayment = (payments) => {
  let total = 0;
  for (let d in payments) {
    if (payments[d].is_subscription === 0 && payments[d].is_card === 0 && payments[d].is_credit_account === 0 && payments[d].is_coupon === 0) total += parseFloat(payments[d].amount);
  }
  return total;
};

export const checkModuleHasPermission = (modules, slugModule, slugPermission) => {
  for (let i = 0; i < modules.length; i++) {
    if (modules[i] && modules[i].slug === slugModule) {

      if (modules[i].permissions) {
        let permissions = modules[i].permissions;
        for (let j = 0; j < permissions.length; j++) {
          if (permissions[j].slug === slugPermission) return true;
        }
      } else {
        return false;
      }
    }
  }

  return false;
};

export const acceptPositive = (value) => {
  let arr = value.toString().split("")
    .filter(it => isNumeric(it));
  return arr.length > 0 ? arr.join("") : "";
};

export const durationFormatting = (d) => {
  try {
    let arr = nullString(d).split(":");
    if (parseInt(arr[0]) >= 24)
      arr[0] = (parseInt(arr[0]) % 24).toString();

    if (arr[0].length <= 1) arr[0] = "0" + arr[0];

    return arr.join(":");
  } catch (e) {
    return nullString(d);
  }
};


export const getChildren = (stats, parent_id, t) => {
  let selected = [];
  if (stats.children) {
    for (let d in stats.children) {
      if (stats.children[d].parent_id === parent_id) {
        let totals = getTotalVats(stats.children[d].vats);
        let vats = stats.children[d].vats;
        let result = {
          [t("parent")]: "---",
          [t("children")]: d,
          [t("amount_ht")]: (stats.children[d].amount - parseFloat(totals)).toFixed(2)
          //  [t('vat_amount')]: totals,
        };
        // selected.push(result);
        let i =0;
        for (let item in vats) {
          let response = {
            ...result,
            [t("vat")]: item,
            [t("vat_amount")]: (vats[item]).toFixed(2)
          };
          if(i>0){
            response[t("amount_ht")] = "---"
          }
          selected.push({ ...response });
          i++;
        }
      }
    }
  }

  console.log(selected);

  selected.sort((a,b)=> a[t("children")] > b[t("children")])
  return selected;
};

export const getParents = (stats, t) => {
  let selected = [];
  if (stats.parents) {
    for (let d in stats.parents) {
      if (stats.parents[d]) {
        let children = getChildren(stats, stats.parents[d].id, t);
        let result = {
          [t("parent")]: d,
          [t("children")]: "---",
          [t("amount_ht")]: stats.parents[d].amount
          //  [t('vat_amount')]: getTotalVats(stats.parents[d].vats),
        };
        selected.push(result);
        if (children.length > 0) {
          result[t("children")] = children[0][t("children")];
          result[t("amount_ht")] = children[0][t("amount_ht")];
          result[t("vat")] = children[0][t("vat")];
          result[t("vat_amount")] = children[0][t("vat_amount")];
          selected.push(...children.slice(1));
        }
      }
    }
  }
  return selected;
};

export const getTotalVats = (vats) => {
  let total = 0;
  if (!vats) return total;
  for (let d in vats) {
    total += parseFloat(vats[d]);
  }
  return total.toFixed(2);
};

export const convertToHeader = (arr) => {
  return arr.map(it => {
    return ({
      name: capitalize(it),
      key: it,
      width: 28
    });
  });
};


export const getPayments = (stats, t) => {
  let selected = [];
  let payments = stats?.payments;
  if (!payments)
    return selected;
  for (let d in payments) {
    let it = payments[d];
    // if(it.is_card= 0 &&
    // it.is_coupon= 0 &&
    // it.is_credit_account= 0&&
    // it.is_subscription=  0  )
    let result = {
      [t("payment_type")]: d,
      [t("sales_nb")]: it.sales_nb,
      [t("amount")]: it.amount
    };
    selected.push(result);
  }

  return selected;
};

export const formatCodeCountry = (country_code) => {
  let s = nullString(country_code);
  if (s)
    s = "+" + (s === "33" ? s + " (0)" : s + " ");
  return s;
};


export const convertDurationToAfter24 = (duration) => {
  try {
    let d = duration.split(":");
    let hour = d[0];
    let min = d[1];
    hour = parseInt(hour) + 24;
    return hour + ":" + min;
  } catch (e) {
    return duration;
  }
};

export const formattingExistUser =(t,old)=>{
  let msg = t("msg-confirm-join-client");
  msg = msg.split(".");
  let first = msg[0];
  let second = "";
  let third = msg[1];
  if (old) {
    second = ` (\n\n ${nullString(old.firstName) + " " + nullString(old.lastName)})`;
  }
  return {first,second,third}
}