import { deleteMethod, getMethod, postMethod } from '@/helpers/index';

export default {
  paymentMethodService: {
    index: async ({ page, size, search = null, status = null , slug = null}) => {
      const result = await getMethod(
        '/paymentTypes?complex_id=' + localStorage.getItem('complex_id') +
        '&page=' + page +
        (size ? '&size=' + size :'') +
        (status ? '&status=[' + status.join(',') + ']' : '') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (slug ? '&slug=[' + status.join(',') + ']' : '') ,
      );

      return result.data;
    },
    store: async (data) => {
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('img', data.icon);
      formData.append('is_show', data.is_show ? '1' : '0');
      formData.append('is_cash', data.is_cash ? '1' : '0');
      formData.append('is_subscription', data.is_subscription ? '1' : '0');
      formData.append('is_credit', data.is_credit ? '1' : '0');
      formData.append('is_credit_account', data.is_credit_account ? '1' : '0');
      formData.append('is_advance', data.is_advance ? '1' : '0');
      formData.append('is_default', data.is_default ? '1' : '0');
      formData.append('status', data.status);
      formData.append('complex_id', localStorage.getItem('complex_id'));

      const result = await postMethod('/paymentTypes', formData);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/paymentTypes/' + id);
      return result.data;
    },
    update: async (data) => {
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('img', data.icon);
      formData.append('is_show', data.is_show ? '1' : '0');
      formData.append('is_cash', data.is_cash ? '1' : '0');
      formData.append('is_subscription', data.is_subscription ? '1' : '0');
      formData.append('is_credit', data.is_credit ? '1' : '0');
      formData.append('is_credit_account', data.is_credit_account ? '1' : '0');
      formData.append('is_advance', data.is_advance ? '1' : '0');
      formData.append('is_default', data.is_default ? '1' : '0');
      formData.append('complex_id', localStorage.getItem('complex_id'));
      formData.append('status', data.status);
      formData.append('_method', 'put');

      const result = await postMethod('/paymentTypes/' + data.id, formData);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/paymentTypes/' + id);
      return result.data;
    },
  },
};
