import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import videoService from '@/services/videoService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingVideo: ['payload'],

  indexRequestVideo: ['payload'],
  indexResponseVideo: ['data'],

  editRequestVideo: ['id'],
  editResponseVideo: ['data'],

  destroyRequestVideo: ['id'],
  destroyResponseVideo: ['data'],

  terrainsRequestVideo: ['payload'],
  terrainsResponseVideo: ['data'],

  hoursRequestVideo: ['payload'],
  hoursResponseVideo: ['data'],

  listRequestVideo: ['payload'],
  listResponseVideo: ['data'],

  codeRequestVideo: ['payload'],
  codeResponseVideo: ['data'],

  videoViewsRequestVideo: ['id'],
  videoViewsResponseVideo: ['data'],

  actionVideoViewsRequestVideo: ['id'],
  actionVideoViewsResponseVideo: ['data'],

  downloadVideoRequestVideo: ['id'],
  downloadVideoResponseVideo: ['data'],

  downloadVideoActionRequestVideo: ['id'],
  downloadVideoActionResponseVideo: ['data'],
});

// Initial state
const INITIAL_STATE = {
  videos: [],
  terrains: [],
  hours: [],
  videosList: [],
  actionsList: [],
  video: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  loadingComplex: false,
  loadingTerrains: false,
  loadingHours: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
  successCode: false,
  successList: false
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingVideo({ loading: true }));
    const data = yield call(videoService.videoService.index, action.payload);
    yield put(Creators.indexResponseVideo(data));
    yield put(Creators.loadingVideo({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingVideo({ loading: false }));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingVideo({ editLoading: true }));
    const data = yield call(videoService.videoService.edit, action.payload);
    yield put(Creators.editResponseVideo(data));
    yield put(Creators.loadingVideo({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingVideo({ editLoading: false }));
    history.push('/error-404');
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingVideo({ deleteLoading: true }));
    const data = yield call(videoService.videoService.destroy, action.payload);
    yield put(Creators.destroyResponseVideo(data));
    yield put(Creators.loadingVideo({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

//get terrains
function* terrains(action) {
  try {
    yield put(Creators.loadingVideo({ loadingTerrains: true }));
    const data = yield call(videoService.videoService.terrains, action.payload);
    yield put(Creators.terrainsResponseVideo(data));
    yield put(Creators.loadingVideo({ loadingTerrains: false }));
  } catch (e) {
    yield put(Creators.loadingVideo({ loadingTerrains: false }));
  }
}

//get hours
function* hours(action) {
  try {
    yield put(Creators.loadingVideo({ loadingHours: true }));
    const data = yield call(videoService.videoService.hours, action.payload);
    yield put(Creators.hoursResponseVideo(data));
    yield put(Creators.loadingVideo({ loadingHours: false }));
  } catch (e) {
    yield put(Creators.loadingVideo({ loadingHours: false }));
  }
}

//get videos and actions list
function* list(action) {
  try {
    yield put(Creators.loadingVideo({ loading: true }));
    const data = yield call(videoService.videoService.list, action.payload);
    yield put(Creators.listResponseVideo(data));
    yield put(Creators.loadingVideo({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingVideo({ loading: false }));
  }
}

//get videos and actions list by code
function* code(action) {
  try {
    yield put(Creators.loadingVideo({ loading: true }));
    const data = yield call(videoService.videoService.code, action.payload);
    yield put(Creators.codeResponseVideo(data));
    yield put(Creators.loadingVideo({ loading: false }));
    if(!data.data.success) errorToast('Le code est invalide');
  } catch (e) {
    yield put(Creators.loadingVideo({ loading: false }));
  }
}

//increment the video views
function* videoViews(action) {
  try {
    //yield put(Creators.loadingVideo({ loading: true }));
    const data = yield call(videoService.videoService.videoViews, action.id);
    yield put(Creators.videoViewsResponseVideo(data));
    yield put(Creators.loadingVideo({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingVideo({ loading: false }));
  }
}

//increment the action video views
function* actionVideoViews(action) {
  try {
    //yield put(Creators.loadingVideo({ loading: true }));
    const data = yield call(videoService.videoService.actionVideoViews, action.id);
    yield put(Creators.actionVideoViewsResponseVideo(data));
    yield put(Creators.loadingVideo({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingVideo({ loading: false }));
  }
}

//download video
function* downloadVideo(action) {
  try {
    yield put(Creators.loadingVideo({ loading: true }));
    const data = yield call(videoService.videoService.downloadVideo, action.id);
    yield put(Creators.downloadVideoResponseVideo(data));
    yield put(Creators.loadingVideo({ loading: false }));
    //if(!data.data.success) errorToast('Le code est invalide');
  } catch (e) {
    yield put(Creators.loadingVideo({ loading: false }));
  }
}

//download video action
function* downloadVideoAction(action) {
  try {
    yield put(Creators.loadingVideo({ loading: true }));
    const data = yield call(videoService.videoService.downloadVideoAction, action.payload);
    yield put(Creators.downloadVideoActionResponseVideo(data));
    yield put(Creators.loadingVideo({ loading: false }));
    //if(!data.data.success) errorToast('Le code est invalide');
  } catch (e) {
    yield put(Creators.loadingVideo({ loading: false }));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_VIDEO, index);
  yield takeLatest(Types.EDIT_REQUEST_VIDEO, edit);
  yield takeLatest(Types.DESTROY_REQUEST_VIDEO, destroy);
  yield takeLatest(Types.TERRAINS_REQUEST_VIDEO, terrains);
  yield takeLatest(Types.HOURS_REQUEST_VIDEO, hours);
  yield takeLatest(Types.LIST_REQUEST_VIDEO, list);
  yield takeLatest(Types.CODE_REQUEST_VIDEO, code);
  yield takeLatest(Types.VIDEO_VIEWS_REQUEST_VIDEO, videoViews);
  yield takeLatest(Types.ACTION_VIDEO_VIEWS_REQUEST_VIDEO, actionVideoViews);
  yield takeLatest(Types.DOWNLOAD_VIDEO_REQUEST_VIDEO, downloadVideo);
  yield takeLatest(Types.DOWNLOAD_VIDEO_ACTION_REQUEST_VIDEO, downloadVideoAction);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    videos: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    video: action.data.data,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding_video = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

//terrains
const terrains_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    terrains: action.data.data,
  };
};

//hours
const hours_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    hours: action.data.data,
  };
};

//list
const list_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    videosList: action.data.data.videos,
    actionsList: action.data.data.actions,
    successList: true
  };
};

//list
const code_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    videosList: action.data.data.videos,
    actionsList: action.data.data.actions,
    successCode: action.data.data.success
  };
};

//video views
const videoViews_response = (state = INITIAL_STATE, action) => {
  return {
    ...state
  };
};

//action video views
const actionVideoViews_response = (state = INITIAL_STATE, action) => {
  return {
    ...state
  };
};

//download video
const downloadVideo_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    //successDestroy: true,
  };
};

//download video action
const downloadVideoAction_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    //successDestroy: true,
  };
};

// Reducer
export const videoReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_VIDEO]: index_response,
  [Types.EDIT_RESPONSE_VIDEO]: edit_response,
  [Types.DESTROY_REQUEST_VIDEO]: destroy_request,
  [Types.DESTROY_RESPONSE_VIDEO]: destroy_response,
  [Types.LOADING_VIDEO]: laoding_video,
  [Types.TERRAINS_RESPONSE_VIDEO]: terrains_response,
  [Types.HOURS_RESPONSE_VIDEO]: hours_response,
  [Types.LIST_RESPONSE_VIDEO]: list_response,
  [Types.CODE_RESPONSE_VIDEO]: code_response,
  [Types.VIDEO_VIEWS_RESPONSE_VIDEO]: videoViews_response,
  [Types.ACTION_VIDEO_VIEWS_RESPONSE_VIDEO]: actionVideoViews_response,
  [Types.DOWNLOAD_VIDEO_RESPONSE_VIDEO]: downloadVideo_response,
  [Types.DOWNLOAD_VIDEO_ACTION_RESPONSE_VIDEO]: downloadVideoAction_response,
});
