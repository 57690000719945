import { deleteMethod, getMethod, postMethod } from '@/helpers/index';

export default {
  discountService:
    {
      index: async ({ page, size, search }) => {

        const result = await getMethod(
          '/discounts?complex_id=' + localStorage.getItem('complex_id')+
            (page ? '&page=' + page :'')+
            (size ? '&size=' + size : '') +
            (search && search.search_name ? '&search_name=' + search.search_name : '')
          )
        return result.data;
      },

      store: async (data) => {
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('amount', data.amount ? data.amount : '');
        formData.append('value', data.discount ? data.discount : '');
        formData.append('img', data.avatar);
        formData.append('complex_id', localStorage.getItem('complex_id'));

        const result = await postMethod('/discounts', formData);
        return result.data;
      },

      edit: async (id) => {
        const result = await getMethod('/discounts/' + id);
        return result.data;
      },
      update: async (data) => {
        let formData = new FormData();
        formData.append('id', data.id);
        formData.append('name', data.name);
        formData.append('amount', data.amount ? data.amount : '');
        formData.append('value', data.discount ? data.discount : '');
        formData.append('img', data.avatar);
        formData.append('complex_id', localStorage.getItem('complex_id'));
        formData.append('_method', 'put');

        const result = await postMethod('/discounts/' + data.id, formData);
        return result.data;
      },
      destroy: async (id) => {
        const result = await deleteMethod('/discounts/' + id);
        return result.data;
      },
    },
};