import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import planningTypeService from '../../services/planningTypeService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingPlanningType: ['data'],

  indexRequestPlanningType: ['payload'],
  indexResponsePlanningType: ['data'],

  storeRequestPlanningType: ['payload'],
  storeResponsePlanningType: ['data'],

  editRequestPlanningType: ['id'],
  editResponsePlanningType: ['data'],

  updateRequestPlanningType: ['payload'],
  updateResponsePlanningType: ['data'],

  destroyRequestPlanningType: ['id'],
  destroyResponsePlanningType: ['data'],
});

// Initial state
const INITIAL_STATE = {
  planningTypes: [],
  planningType: null,
  loading: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingPlanningType({ loading: true,planningTypes:[] }));
    const data = yield call(planningTypeService.planningTypeService.index, action.payload);
    yield put(Creators.indexResponsePlanningType(data));
    yield put(Creators.loadingPlanningType({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingPlanningType({ loading: true }));
    const data = yield call(planningTypeService.planningTypeService.store, action.payload);
    yield put(Creators.storeResponsePlanningType(data));
    yield put(Creators.loadingPlanningType({ loading: false }));
    yield history.push('/configuration/planningTypes');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingPlanningType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingPlanningType({ editLoading: true }));
    const data = yield call(planningTypeService.planningTypeService.edit, action.payload);
    yield put(Creators.editResponsePlanningType(data));
    yield put(Creators.loadingPlanningType({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingPlanningType({ editLoading: false }));
    history.push('/error-404');
  }
}

// //update
function* update(action) {
  try {
    yield put(Creators.loadingPlanningType({ loading: true }));
    const data = yield call(planningTypeService.planningTypeService.update, action.payload);
    yield put(Creators.updateResponsePlanningType(data));
    yield put(Creators.loadingPlanningType({ loading: false }));
    yield history.push('/configuration/planningTypes');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(
      Creators.loadingPlanningType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

// //destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingPlanningType({ deleteLoading: true }));
    const data = yield call(planningTypeService.planningTypeService.destroy, action.payload);
    yield put(Creators.destroyResponsePlanningType(data));
    yield put(Creators.loadingPlanningType({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_PLANNING_TYPE, index);
  yield takeLatest(Types.STORE_REQUEST_PLANNING_TYPE, store);
  yield takeLatest(Types.EDIT_REQUEST_PLANNING_TYPE, edit);
  yield takeLatest(Types.UPDATE_REQUEST_PLANNING_TYPE, update);
  yield takeLatest(Types.DESTROY_REQUEST_PLANNING_TYPE, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    planningTypes: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    planningType: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const planningTypeReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_PLANNING_TYPE]: index_response,
  [Types.STORE_RESPONSE_PLANNING_TYPE]: store_response,
  [Types.EDIT_RESPONSE_PLANNING_TYPE]: edit_response,
  [Types.UPDATE_RESPONSE_PLANNING_TYPE]: update_response,
  [Types.DESTROY_REQUEST_PLANNING_TYPE]: destroy_request,
  [Types.DESTROY_RESPONSE_PLANNING_TYPE]: destroy_response,
  [Types.LOADING_PLANNING_TYPE]: loading,
});
