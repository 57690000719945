import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { exportFile } from '@/helpers/exportDownloader';
import { putMethod } from '../helpers';
import moment from 'moment';

export const index = async ({ page, size="",status="",search_start_date="",search_end_date="" }) => {
  const result = await getMethod(
    '/closures?page=' +
    page +
    ('&size=' + size ) +
    (search_start_date ? '&search_start_date=' + search_start_date : '') +
    (search_end_date ? '&search_end_date=' + search_end_date : '') +
    (status ? '&status=' + status : '') +
    '&complex_id=' +
    localStorage.getItem('complex_id'),
  );
  return result.data;
};
export const store = async (data) => {
  const result = await postMethod('/closures',data);
  return result.data;
};

export const update = async (data) => {
  const result = await putMethod('/closures/' + data.id,data);
  return result.data;
};

export const edit = async (id) => {
  const result = await getMethod('/closures/' + id);
  return result.data;
};
export const destroy = async (id) => {
  const result = await deleteMethod('/closures/' + id);
  return result.data;
};

export const stats = async (data)=>{
  const result = await postMethod("/closureStats",data);
  return result.data;
};

export const storeDetail = async (data)=>{
  const result = await postMethod("/closureDetails",data);
  return result.data;
};

export const closureByDate = async (data)=>{
  const result = await getMethod("/closureByDate?complex_id=" +
                        localStorage.getItem('complex_id')+
                        ('&start_date=' + data.start_date) +
                        ('&end_date=' + data.end_date)
                      );
  return result.data;
};

export const closureStatsExport = async (data)=>{
  let response = await getMethod("/closureExport?complex_id=" + 
        localStorage.getItem('complex_id')+
        (data && data.start_date ? '&start_date=' + data.start_date : '') +
        (data && data.end_date ? '&end_date=' + data.end_date : ''),
        {responseType: 'blob'}
  ); //important

  let start = data && data.start_date ? data.start_date : moment().format('YYYY-MM-DD');
  let end = data && data.end_date ? data.end_date : start;
  exportFile(response, 'Clôture_' + start + '_' + end)};
