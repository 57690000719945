export const user = {
  success: true,
  token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xOTIuMTY4LjEwMC41Njo4ODg4XC9hcGlcL2xvZ2luIiwiaWF0IjoxNTgyMTE2OTc0LCJleHAiOjE1ODIxMjA1NzQsIm5iZiI6MTU4MjExNjk3NCwianRpIjoiVHJiMlhVMWFTU2JKT1hrbyIsInN1YiI6MSwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.m_O2LGjE9hGWStx-xlpc6S_OQh49NwlxgQVSEI83zRM',
  user: {
    '0': {
      id: 1,
      firstName: 'Hamza',
      lastName: 'Alatrach',
      username: null,
      birthdate: null,
      avatar: null,
      mobile: null,
      tel: null,
      company: null,
      country: null,
      zip: null,
      info: null,
      email: 'al.atrach.hamza@gmail.com',
      active: 0,
      email_verified_at: null,
      created_at: '2020-02-13 10:47:18',
      updated_at: '2020-02-13 15:32:22',
      deleted_at: null,
    },
    modules: [
      {
        slug: 'unauthorized',
        permissions: [
          {
            slug: 'login-access',
          },
        ],
      },
      {
        slug: 'dashboard',
        permissions: [
          {
            slug: 'dashboard-access',
          },
        ],
      },
      {
        slug: 'planning',
        permissions: [
          {
            slug: 'planning-access',
          },
          {
            slug: 'planning-create',
          },
          {
            slug: 'planning-delete',
          },
          {
            slug: 'planning-edit',
          },
        ],
      },
      {
        slug: 'client',
        permissions: [
          {
            slug: 'client-access',
          },
          {
            slug: 'client-delete',
          },
          {
            slug: 'client-edit',
          },
          {
            slug: 'client-create',
          },
          {
            slug: 'client-profile',
          },
        ],
      },
      {
        slug: 'subscription',
        permissions: [
          {
            slug: 'subscription-access',
          },
          {
            slug: 'subscription-delete',
          },
          {
            slug: 'subscription-edit',
          },
          {
            slug: 'subscription-create',
          },
          {
            slug: 'subscription-user-access',
          },
        ],
      },
      {
        slug: 'reservation',
        permissions: [
          {
            slug: 'reservation-access',
          },
          {
            slug: 'reservation-delete',
          },
          {
            slug: 'reservation-create',
          },
        ],
      },

      {
        slug: 'crm',
        permissions: [
          {
            slug: 'client-access',
          },
          {
            slug: 'client-delete',
          },
          {
            slug: 'client-edit',
          },
          {
            slug: 'client-create',
          },
          {
            slug: 'client-profile',
          },

          {
            slug: 'reservation-access',
          },
          {
            slug: 'reservation-delete',
          },
          {
            slug: 'reservation-create',
          },
        ],
      },

      {
        slug: 'permission',
        permissions: [
          {
            slug: 'permission-access',
          },
          {
            slug: 'permission-delete',
          },
          {
            slug: 'permission-create',
          },
        ],
      },
      {
        slug: 'caisse',
        permissions: [
          {
            slug: 'checkout-access',
          },
          {
            slug: 'checkout-delete',
          },
          {
            slug: 'checkout-create',
          },
          {
            slug: 'discount-access',
          },
          {
            slug: 'discount-delete',
          },
          {
            slug: 'discount-create',
          },
          {
            slug: 'discount-edit',
          },
          {
            slug: 'parent-access',
          },
          {
            slug: 'parent-delete',
          },
          {
            slug: 'parent-create',
          },
          {
            slug: 'parent-edit',
          },
          {
            slug: 'child-access',
          },
          {
            slug: 'child-delete',
          },
          {
            slug: 'child-create',
          },
          {
            slug: 'child-edit',
          },
          {
            slug: 'article-access',
          },
          {
            slug: 'article-create',
          },
          {
            slug: 'article-edit',
          },
          {
            slug: 'vat-access',
          },
          {
            slug: 'vat-delete',
          },
          {
            slug: 'vat-create',
          },
          {
            slug: 'vat-edit',
          },
          {
            slug: 'closure-access',
          },
          {
            slug: 'payment-method-access',
          },
          {
            slug: 'payment-method-create',
          },
          {
            slug: 'payment-method-edit',
          },
          {
            slug: 'ticket-info-access',
          },
          {
            slug: 'ticket-info-create',
          },
          {
            slug: 'ticket-info-edit',
          },
        ],
      },

      {
        slug: 'reservation',
        permissions: [
          {
            slug: 'reservation-access',
          },
          {
            slug: 'reservation-delete',
          },
          {
            slug: 'reservation-create',
          },
        ],
      },

      {
        slug: 'competition',
        permissions: [
          {
            slug: 'competition-access',
          },
          {
            slug: 'competition-create',
          },
          {
            slug: 'competition-edit',
          },
        ],
      },
      {
        slug: 'video',
        permissions: [
          {
            slug: 'video-access',
          },
          {
            slug: 'action-access',
          },
          {
            slug: 'video-planning-access',
          },
        ],
      },

      {
        slug: 'player',
        permissions: [
          {
            slug: 'player-access',
          },
          {
            slug: 'player-delete',
          },
          {
            slug: 'player-create',
          },
        ],
      },
      {
        slug: 'stat-sensor',
        permissions: [
          {
            slug: 'sensor-stat',
          },
        ],
      },
      {
        slug: 'configuration',
        permissions: [
          {
            slug: 'network-access',
          },
          {
            slug: 'network-create',
          },
          {
            slug: 'network-edit',
          },
          {
            slug: 'complex-create',
          },
          {
            slug: 'complex-access',
          },
          {
            slug: 'complex-delete',
          },
          {
            slug: 'complex-user-access',
          },
          {
            slug: 'complex-edit',
          },
          {
            slug: 'complex-time',
          },
          {
            slug: 'terrain-access',
          },
          {
            slug: 'terrain-create',
          },
          {
            slug: 'terrain-edit',
          },
          {
            slug: 'terrain-time',
          },
          {
            slug: 'role-access',
          },
          {
            slug: 'role-create',
          },
          {
            slug: 'role-edit',
          },
          {
            slug: 'permission-access',
          },
          {
            slug: 'permission-create',
          },
          {
            slug: 'permission-delete',
          },
          {
            slug: 'permission-edit',
          },
          {
            slug: 'comp-type-access',
          },
          {
            slug: 'comp-type-create',
          },
          {
            slug: 'comp-type-edit',
          },
          {
            slug: 'module-access',
          },
          {
            slug: 'module-create',
          },
          {
            slug: 'module-edit',
          },
          {
            slug: 'duration-access',
          },
          {
            slug: 'duration-create',
          },
          {
            slug: 'duration-edit',
          },
          {
            slug: 'team-access',
          },
          {
            slug: 'team-create',
          },
          {
            slug: 'team-edit',
          },
          {
            slug: 'activity-access',
          },
          {
            slug: 'activity-create',
          },
          {
            slug: 'activity-edit',
          },
          {
            slug: 'complex-user-access',
          },
          {
            slug: 'complex-user-create',
          },
          {
            slug: 'sensor-access',
          },
          {
            slug: 'sensor-create',
          },
          {
            slug: 'sensor-edit',
          },
          {
            slug: 'side-access',
          },
          {
            slug: 'side-create',
          },
          {
            slug: 'side-edit',
          },
          {
            slug: 'resolution-access',
          },
          {
            slug: 'resolution-create',
          },
          {
            slug: 'resolution-edit',
          },
          {
            slug: 'camera-access',
          },
          {
            slug: 'camera-create',
          },
          {
            slug: 'camera-edit',
          },
          {
            slug: 'camera-planning-access',
          },
          {
            slug: 'currency-access',
          },
          {
            slug: 'currency-create',
          },
          {
            slug: 'currency-edit',
          },
          {
            slug: 'subscription-type-access',
          },
          {
            slug: 'subscription-type-create',
          },
          {
            slug: 'subscription-type-edit',
          },
          {
            slug: 'periodicity-access',
          },
          {
            slug: 'periodicity-create',
          },
          {
            slug: 'periodicity-edit',
          },
          {
            slug: 'hours-config-access',
          },
          {
            slug: 'price-config-access',
          },
          {
            slug: 'hour-type-access',
          },
          {
            slug: 'hour-type-create',
          },
          {
            slug: 'hour-type-edit',
          },
          {
            slug: 'mixed-hour-access',
          },
          {
            slug: 'mixed-hour-create',
          },
          {
            slug: 'mixed-hour-edit',
          },
        ],
      },
    ],
  },
};

// export  const user ={
//     "success": true,
//     "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xOTIuMTY4LjEwMC41Mzo4ODg4XC9hcGlcL2xvZ2luIiwiaWF0IjoxNTgyNTYwMzAwLCJleHAiOjE1ODI1NjM5MDAsIm5iZiI6MTU4MjU2MDMwMCwianRpIjoiNXlEMTBtdGZsN2w5SzVLeCIsInN1YiI6MSwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.QJTgFqMDMVmLKzgrg3-hE1d0EWZyofbCBnFNlTaHBeY",
//     "user": {
//         "0": {
//             "id": 1,
//             "firstName": "Hamza",
//             "lastName": "Alatrach",
//             "username": null,
//             "birthdate": null,
//             "avatar": null,
//             "mobile": null,
//             "tel": null,
//             "company": null,
//             "country": null,
//             "zip": null,
//             "info": null,
//             "email": "al.atrach.hamza@gmail.com",
//             "active": 0,
//             "email_verified_at": null,
//             "created_at": "2020-02-13 10:47:18",
//             "updated_at": "2020-02-13 15:32:22",
//             "deleted_at": null
//         },
//         "modules": [
//             {
//                 "id": 3,
//                 "name": "competitions",
//                 "slug": "competitions",
//                 "permission": [
//                     {
//                         "id": 1,
//                         "slug": "team-create",
//                         "title": "Team Create"
//                     },
//                     {
//                         "id": 2,
//                         "slug": "team-edit",
//                         "title": "Team Edit"
//                     },
//                     {
//                         "id": 3,
//                         "slug": "team-delete",
//                         "title": "Team Delete"
//                     },
//                     {
//                         "id": 4,
//                         "slug": "team-show",
//                         "title": "Team Show"
//                     },
//                     {
//                         "id": 5,
//                         "slug": "team-access",
//                         "title": "Team Access"
//                     }
//                 ]
//             },
//             {
//                 "id": 1,
//                 "name": "videos",
//                 "slug": "videos",
//                 "permission": [
//                     {
//                         "id": 11,
//                         "slug": "video-access",
//                         "title": "Video Access"
//                     }
//                 ]
//             },
//             {
//                 "id": 2,
//                 "name": "planning",
//                 "slug": "planning",
//                 "permission": [
//                     {
//                         "id": 12,
//                         "slug": "planning-access",
//                         "title": "Planning Access"
//                     }
//                 ]
//             }
//         ]
//     }
// };

export const player = {
  import: '/PlayerPage/Profile',
};

export const events = [
  {
    id: 123,
    title: 'hi there 7-8 ',
    resourceId:'7',
    group: 1,
    height: 2 * 50,
    start: '2021-08-09T08:30:00+00:00',
    end: '2021-08-09T09:30:00+00:00',
    type: 0,
  },
  {
    id: 123,
    title: 'hi there 7-8 ',
    resourceId:'3',
    group: 1,
    height:  52,
    start: '2021-08-09T08:30:00+00:00',
    end: '2021-08-09T09:30:00+00:00',
    type: 1,
  },

];


export const testEvents = [
  { 'title': 'event 1', 'start': '2021-08-08', 'end': '2021-08-10', 'resourceId': 'a' },
  {
    'title': 'event 3',
    'start': '2021-08-09T12:00:00+00:00',
    'end': '2021-08-10T06:00:00+00:00', 'resourceId': 'b',
  }, {
    'title': 'event 4',
    'start': '2021-08-09T07:30:00+00:00',
    'end': '2021-08-09T09:30:00+00:00', 'resourceId': 'c',
  }, {
    'title': 'event 5',
    'start': '2021-08-09T10:00:00+00:00',
    'end': '2021-08-09T15:00:00+00:00', 'resourceId': 'd',
  }, {
    'title': 'event 2',
    'start': '2021-08-09T09:00:00+00:00',
    'resourceId': 'e',
    'end': '2021-08-09T14:00:00+00:00',
  }];



export const SIZE = 10;