import React from 'react';
import { Checkbox, Image, Table } from 'semantic-ui-react';

import TicketAccordionItem from './TicketAccordionItem';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getArticleDiscount } from '@/helpers/ticket';
import { getCurrentNote } from '@/helpers/caisse/noteFormatting';
import { nullString } from '../../../helpers/formatting';
import { getActivityAmount } from '../../../helpers/caisseFormatting';

class FinalTicketAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  renderDetailsParticipants = (item) => {
    let currentTicket = this.props.currentTicket;
    let currentNote = getCurrentNote(currentTicket, this.props.currentNote);

    let result = null;
    if (currentTicket) {
      let participants = currentTicket.participants.filter(
        (itm) => itm.article_id.toString() === item.id.toString(),
      );
      if (item.parent.is_activity && item.child.is_res && Object.entries(participants).length)
        result = participants.map((it, i) => {
          if (this.props.note_index === it.note_index) {
            return (
              <TicketAccordionItem
                key={"final_ticket_item-" + it.note_index + "-" + it.user_index}
                id={it.user_index}
                article_id={it.article_id}
                user_id={it.user_id ? it.user_id : ''}
                subscriber_id={it.subscriber_id}
                status={it.status}
                payment_type={it.type_payment_id}
                name={it.user_id ? nullString(it.firstName).toLowerCase() + ' ' + nullString(it.lastName).toUpperCase() : 'Joueur ' + (i + 1)}
                price={it.amount}
                all_participant_selected={this.state.selected_article}
                type={it.user_id ? 'client' : ''}
                payed_amount={it.payed_amount}
                isDetail={true}
                is_note={this.props.is_note}
                participant={it}
              />
            );
          }
        });
    }
    return result;
  }

  render() {
    let cancledStatus = this.props.article.status === 'cancled' ? { textDecoration: 'line-through' } : {};
    let currentTicket = this.props.currentTicket;
    let isActivity = (this.props.article.parent?.is_activity === 1 && this.props.article.child?.is_res === 1);

    return(
      <div>
        <Table className="ticket-finished-table" basic="very">
          <Table.Body>
            <Table.Row style={this.state.article_style}>
              <Table.Cell collapsing>
                <Checkbox
                  name={'checkedArticleList'}
                  checked={this.props.is_checked}
                  style={this.props.article.status === 'cancled' || this.props.note_index ? { visibility: 'hidden' } : {}}
                  onChange={this.props.onChange}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span className="occ-nb-style">{this.props.article.article_nb}</span>
              </Table.Cell>
              <Table.Cell style={cancledStatus}>
                {this.props.article.name +
                ' ' +
                (this.props.article.info ? this.props.article.info + ' ' : '') +
                (this.props.article.duration ? this.props.article.duration.duration.replace(':', 'H') + ' ' : '') +
                (this.props.article.default_participants_nb !== null
                  ? this.props.article.default_participants_nb + ' pers'
                  : '')}
              </Table.Cell>
              {getArticleDiscount(this.props.currentTicket, this.props.article.id, isActivity ? this.props.note_index : this.props.article.note_index) > 0 &&
              <Table.Cell className="accordion-discount" width={2} collapsing textAlign="right"
                          onClick={() => {
                            this.props.is_note === null && parseFloat(this.props.article.payed_amount) === 0 && this.setState({ cancelArticleDiscountModalOpen: true });
                          }}
              >
                <Image
                  src={require('../../../assets/remise.svg')}
                  style={{ width: '30px' }}
                />
                {parseFloat(
                  getArticleDiscount(this.props.currentTicket, this.props.article.id, isActivity ? this.props.note_index : this.props.article.note_index),
                ).toFixed(2)}
                {localStorage.getItem('currency')}
              </Table.Cell>
              }
              <Table.Cell className="accordion-label" collapsing width={3}>
                {((isActivity ?
                  getActivityAmount(this.props.currentTicket, this.props.article.id, this.props.note_index) :
                  parseFloat((this.props.article.total_price * this.props.article.article_nb))) -
                  parseFloat(getArticleDiscount(this.props.currentTicket, this.props.article.id, this.props.article.note_index))).toFixed(2)
                }
                {localStorage.getItem('currency')}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {
          this.props.article.parent.is_activity.toString() === '1' && this.props.article.child.is_res.toString() === '1' &&
          <div className={'accordion-container'} key={'player'}>
            {this.renderDetailsParticipants(this.props.article)}
          </div>
        }
      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => ({
  caisse_redirection: (value) => dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: { value } }),
});

const mapStateToProps = (state) => {
  return {
    currentTicket: state.caisse.currentTicket,
    currentNote: state.caisse.currentNote,
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinalTicketAccordion));