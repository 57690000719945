import React, { useEffect, useState } from 'react';
import expr from 'expression-eval';
import './Calculator.css';

const labels = [
  [
    { value: 'C', type: 'operator', className: 'cal-btn-white' },
    { value: '( )', type: 'operator', className: 'cal-btn-white ', disabled: true },
    { value: '%', type: 'operator', className: 'cal-btn-white', disabled: true },
    { value: '/', type: 'operator', className: 'cal-btn-white' },
  ],
  [
    { value: '7', type: 'number' },
    { value: '8', type: 'number' },
    { value: '9', type: 'number' },
    { value: 'x', type: 'operator', className: 'cal-btn-white' },
  ],
  [
    { value: '4', type: 'number' },
    { value: '5', type: 'number' },
    { value: '6', type: 'number' },
    { value: '-', type: 'operator', className: 'cal-btn-white' },
  ],
  [
    { value: '1', type: 'number' },
    { value: '2', type: 'number' },
    { value: '3', type: 'number' },
    { value: '+', type: 'operator', className: 'cal-btn-white' },
  ],
  [
    { value: '00', type: 'number' },
    { value: '0', type: 'number' },
    { value: '.', type: 'number' },
    { value: '=', type: 'operator', className: 'cal-btn-white' },
  ],
];

function parse(str) {
  return Function(`'use strict'; return (${str})`)();
}

const Calculator = (props) => {
  const [value, setValue] = useState(props.default ? props.default : props.amount);
  const [operation, setOperation] = useState('');
  const [history, setHistory] = useState('');
  const [initial, setInitial] = useState(true);
  const [focused, setFocused] = useState(false);
  let eventListener = null;
  // useEffect(()=>{
  //     if(focused)
  //       eventListener = document.addEventListener("keypress",handleKeyChange,false);
  //     else if(eventListener)
  //       document.removeEventListener("keypress",eventListener)
  //
  // },[focused]);

  useEffect(() => {

    props.onChange(props.amount);
    setValue(parseFloat(props.amount));
    // setInitial(true);
  }, [props.amount]);

  useEffect(() => {
    props.onChange(props.default);
    setValue(props.default);
    setInitial(false);
  }, [props.default]);

  useEffect(() => {
    if (!isNaN(value) && props.amount !== '') props.onChange(parseFloat(value));
  }, [value]);

  // integrate keyboard handler
  //
  // useEffect(()=>{
  //  return ()=>{
  //      document.removeEventListener("keypress",eventListener)
  //  }
  // },[]);


  const handleChange = (val, type) => {
    if (type === 'number') {
      if (initial) {
        let oldValue = value.toString().split(' ');
        let newVal = val;
        if (newVal === '.') newVal = '0.';
        if (oldValue[oldValue.length - 1] === '0' && (val !== '0' || val !== '00'))
          setValue(newVal);
        setInitial(false);
      } else {
        let oldValue = value.toString().split(' ');
        if (['x', '+', '-', '/'].includes(oldValue[oldValue.length - 1])) {
          if (val === '.') {
            setValue(value + ' 0.');
          } else setValue(value + ' ' + val);
        } else if (val === '.') {
          let lastElement = oldValue[oldValue.length - 1].split('');
          let hasDot = lastElement.filter(it => it === '.').length > 0;
          if (!hasDot) setValue(value + val);

        } else if (oldValue[oldValue.length - 1] !== '0' && (val !== '0' || val !== '00')) {
          setValue(value + val);
        } else if (value.toString() === '0') {
          setValue(val);
        }
      }
    } else {
      switch (val) {
        case 'C':
          setValue(0);
          setInitial(true);
          break;
        case '=':
          try {
            let items = value.toString().split(' ');
            if (items.length > 1) {
              let h = value;
              let newValue = expr.parse(value.replace('x', '*'));
              let result = expr.eval(newValue);
              setValue(result);
              //setHistory(h);
            }
            break;
          } catch (e) {
            //setHistory("error acquired");
            setValue(0);
            break;
          }
        default: {
          let oldValue = value.toString().split(' ');
          if (['x', '+', '-', '/'].includes(oldValue[oldValue.length - 1]))
            oldValue[oldValue.length - 1] = val;
          else
            oldValue.push(val);

          let newVal = oldValue.join(' ');
          setValue(newVal);
        }
      }
    }
  };


  const deleteLastItem = () => {
    let item = value.toString().split(' ');
    let lastValue = item[item.length - 1].split('');
    let last = lastValue.slice(0, lastValue.length);
    let result = '';
    if (last.length === 1) {
      result = item.slice(0, item.length - 1).join(' ');
    } else {
      item[item.length - 1] = last.slice(0, last.length - 1).join('');
      result = item.join(' ');
    }


    if (result === '') {
      setInitial(true);
      result = '0';
    }


    return result;


  };

  const handleKeyChange = (e) => {
    let code = parseInt(e.keyCode);
    let charCode = String.fromCharCode(e.keyCode);

    e.preventDefault();
    // eslint-disable-next-line default-case
    switch (code) {
      case 8 : {
        setValue(deleteLastItem());
        break;
      }
      case 46 :
        handleChange('C', 'operator');
        break;
      case 106 :
        handleChange('x', 'operator');
        break;
      case 109 :
        handleChange('-', 'operator');
        break;
      case 107 :
        handleChange('+', 'operator');
        break;
      case 190 :
        handleChange('.', 'number');
        break;
        case 188 :
        handleChange('.', 'number');
        break;
      case 110 :
        handleChange('.', 'number');
        break;
      case 111 :
        handleChange('/', 'operator');
        break;
      case 191 :
        handleChange('/', 'operator');
        break;
      case 13 :
        handleChange('=', 'operator');
        break;
      case 48:
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
        handleChange(charCode, 'number');
        break;
      case 96:
        handleChange('0', 'number');
        break;
      case 97:
        handleChange('1', 'number');
        break;
      case 98:
        handleChange('2', 'number');
        break;
      case 99:
        handleChange('3', 'number');
        break;
      case 100:
        handleChange('4', 'number');
        break;
      case 101:
        handleChange('5', 'number');
        break;
      case 102:
        handleChange('6', 'number');
        break;
      case 103:
        handleChange('7', 'number');
        break;
      case 104:
        handleChange('8', 'number');
        break;
      case 105:
        handleChange('9', 'number');
        break;


    }

  };


  return (
    <>
      <div className={'calculator-container'}
           tabIndex={1}
           onKeyDown={handleKeyChange}
           onFocus={() => setFocused(true)}
           onBlur={() => setFocused(false)}
      >
        <div className={'calculator-display'}>
          <div className={'calculator-history'}>{history}</div>
          <input type={'text'}
                 className={'calculator-input'}
                 value={value ? value : '0'}
                 disabled={true}
                 onChange={() => {
                 }}/>
        </div>
        <div className={'calculator-btns'}>
          {labels.map((it, i) => {
            const items = it.map((k, j) => {
              if(k.type==="operator" && !props.withOperator )
                return null;

             let del =  { value: 'C', type: 'operator', className: 'cal-btn-white' };
              return (
              <CalculatorBtn
                withOperator={props.withOperator}
                key={`CalculatorBtn-${j}`}
                value={!props.withOperator && k.value === "." ? del.value : k.value}
                disabled={k.hasOwnProperty('disabled') ? k.disabled : false}
                type={!props.withOperator && k.value === "." ? del.type : k.type}
                cssClass={!props.withOperator && k.value === "." ? del.className : (k.className ? k.className : '')}
                handleChange={handleChange}
              />
            )});
            return (
              <div key={'cal-btn-' + i} className={'calculator-row'}>
                {items}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const CalculatorBtn = (props) => {
  return (
    <div
      style={props.disabled ? { opacity: .4 } : { opacity: 1 }}
      className={'calculator-btn '+ (props.withOperator ? ' ' : 'calculator-btn-operator ') + (props.cssClass ? props.cssClass : '')}
      onClick={() => !props.disabled && props.handleChange(props.value, props.type)}
    >
      {props.value}
    </div>
  );
};


export default Calculator;

Calculator.defaultProps = {
  onChange: (value) => {},
  default: 0,
  withOperator:true
};
