import React from 'react';
import { Button, Form, Image, Input, Loader, Pagination } from 'semantic-ui-react';
import './ClientList.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from '@/i18n';
import CustomInput from '@/components/CustomInput/CustomInput';
import CustomerFile from '@/components/CustomerFile/CustomerFile';
import { imgUrl } from '@/helpers';
import { randomstring } from '@/helpers/formatting';
import { SIZE } from '../../../utils/mock';
import { formatCodeCountry, nullString, phoneNumber } from '../../../helpers/formatting';
import { DEFAULT_COUNTRY_CODE } from '../../../utils/countryCode';

class ClientList extends React.Component {
  constructor(props) {
    super(props);
    let somePassword = randomstring();
    this.state = {
      content: null,
      search_client: '',
      id: '',
      img: '',
      firstName: '',
      lastName: '',
      birthdate: '1980-01-01',
      email: '',
      mobile: '',
      country_code: DEFAULT_COUNTRY_CODE,
      imgPreview: '',
      company: '',
      country: '',
      zip: '',
      address: '',
      society: '',
      complexes: Number(localStorage.getItem('complex_id')),
      teams: [],
      capitaine: [],
      password: somePassword,
      password_confirmation: somePassword,
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        mobile: null,
        complexes: null,
        roles: null
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  componentDidMount() {
    this.props.getRoles(1, 100);
    this.props.getClientPermission();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.loading &&
      JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)
    ) {
      let errors = {
        firstName: null,
        lastName: null,
        email: null,
        mobile: null,
        complexes: null,
        roles: null
      };
      for (let index in this.props.errors) {
        errors[index] = { content: this.props.errors[index][0] };
      }
      this.setState({ errors });
    }

    if(this.props.updateClientSuccess !== prevProps.updateClientSuccess){
      this.setState({ content: null, search_client: '' });
    }
  }

  fileChangedHandler = (event) => {
    this.setState({ [event.target.name]: event.target.files[0] });
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ imgPreview: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  handleChangeSearch(event) {
    this.setState({
      [event.target.name]: event.target.value,
      content: null,
    });


    this.props.search(this.props.page, SIZE, event.target.value);
  }

  handlePaginationChange = (e, data) => {
    this.props.search(data.activePage, SIZE, this.state.search_client);
  };


  handleChange(event, eventMobile=null) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (eventMobile && eventMobile.target ) {
      this.setState({
        [eventMobile.target.name]: eventMobile.target.value,
      });
    }
  }

  renderAddClient() {
    let { roles } = this.props.role;
    return (
      <div className="add-client-block">
        <h3 className="client-list-header">{this.state.id ? 'Modifier un client' : 'Ajouter un client'}</h3>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={() => this.setState({ content: 'client details' })}>{i18n.t('back')}</Button>
        </div>
        <Form className="client-input">
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <div style={{ flex: 1, margin: '5px 20px' }}>
              <CustomInput
                name={'img'}
                imageurl={this.state.imgPreview}
                className="input-bg picture-input"
                type="picture"
                label={i18n.t('avatar')}
                mode="inline"
                onChange={this.fileChangedHandler}
              />

              <CustomInput
                className="input-bg"
                name={'firstName'}
                error={this.state.errors.firstName !== null}
                errormsg={this.state.errors.firstName}
                type="text"
                label={i18n.t('first_name')}
                mode="inline"
                value={this.state.firstName}
                onChange={this.handleChange}
              />

              <CustomInput
                className="input-bg"
                name={'lastName'}
                error={this.state.errors.lastName !== null}
                errormsg={this.state.errors.lastName}
                type="text"
                label={i18n.t('last_name')}
                mode="inline"
                value={this.state.lastName}
                onChange={this.handleChange}
              />

              <CustomInput
                className="input-bg"
                type="date"
                name={'birthdate'}
                label={i18n.t('birthday')}
                mode="inline"
                value={this.state.birthdate}
                onChange={this.handleChange}
              />

              <CustomInput
                className="input-bg"
                type="email"
                name={'email'}
                label={i18n.t('email')}
                mode="inline"
                value={this.state.email}
                error={this.state.errors.email !== null}
                errormsg={this.state.errors.email}
                onChange={this.handleChange}
              />
            </div>

            <div style={{ flex: 1, margin: '5px' }}>
              <br/>
              <CustomInput
                className="input-bg"
                type="phone"
                name="mobile"
                label={i18n.t('mobile')}
                mode="inline"
                value={this.state.mobile}
                countrycode={this.state.country_code}
                onChange={this.handleChange}
                error={this.state.errors.mobile !== null}
                errormsg={this.state.errors.mobile}
              />

              <CustomInput
                className="input-bg"
                type="text"
                name={'company'}
                label={i18n.t('society')}
                mode="inline"
                value={this.state.company}
                onChange={this.handleChange}
              />
              <CustomInput
                className="input-bg"
                type="text"
                name="country"
                label={i18n.t('country')}
                mode="inline"
                value={this.state.country}
                onChange={this.handleChange}
              />
              <CustomInput
                className="input-bg"
                type="text"
                name="zip"
                label={i18n.t('zip')}
                mode="inline"
                value={this.state.zip}
                onChange={this.handleChange}
              />
              <CustomInput
                className="input-bg"
                type="text"
                name="address"
                label={i18n.t('address')}
                mode="inline"
                value={this.state.address}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              disabled={this.props.loading}
              loading={this.props.loading}
              content={i18n.t('save')}
              className={'client-green-btn'}
              onClick={() => {
                let stateWithoutError = { ...this.state };
                delete stateWithoutError.errors;
                let r = roles.filter((it) => {
                  return it.slug === 'client';
                });
                let data = {
                  permissions: this.props.permissions,
                  ...stateWithoutError,
                  roles: r.length > 0 ? r[0].id : null,
                  checkout: true,
                };
                if (data.id)
                  this.props.update(data);
                else
                  this.props.store(data);
                
              }}
            />
          </div>
        </Form>
      </div>
    );
  }

  renderSearchContent() {
    return this.props.users.map((item, index) => {
      return (
        <div key={index} className="client-row">
          <span>
            <Image className="ticket-avatar avatar-cover" type={'item'} avatar size="mini"
                   src={item.avatar ?
                     imgUrl + '/users/' + item.avatar :
                     require('../../../assets/avatar.svg')}
                   style={{ margin: '10px', width: 40, height: 40, borderRadius: 48 }}
            />
          </span>
          <span className="client-name">
            {nullString(item.firstName).toLowerCase()} {nullString(item.lastName).toUpperCase()}
          </span>
          <span>{formatCodeCountry(item.country_code)  + phoneNumber(item.mobile)}</span>
          <span>{item.email}</span>
          <span style={{ color: '#7EB5E5' }}>
            <Button
              className="client-btn-detail"
              onClick={() => this.setState({
                content: 'client details',
                ...item, imgPreview: imgUrl + '/users/' + item.avatar,
              })}
            >
              Detail
            </Button>
          </span>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="client-list">
        {this.state.content === null && (
          <>
            <h3 className="client-list-header">Liste des clients</h3>
            <div className="client-list-body">
              <div className="search-bloc">
                <Input
                  name="search_client"
                  className="search-input-client"
                  icon="search"
                  placeholder="Recherche"
                  onChange={this.handleChangeSearch}
                  value={this.state.search_client}
                />
                <div className="add-client">
                  <Button
                    icon="plus"
                    color="blue"
                    circular
                    size="tiny"
                    onClick={() => this.setState({
                      content: 'add client',
                      id: '',
                      img: '',
                      firstName: '',
                      lastName: '',
                      birthdate: '1980-01-01',
                      email: '',
                      mobile: '',
                      country_code: DEFAULT_COUNTRY_CODE,
                      imgPreview: '',
                      company: '',
                      country: '',
                      zip: '',
                      address: '',
                      society: '',
                    })}
                  />
                  {i18n.t('add_client')}
                </div>
              </div>
              <div>
                {this.state.search_client !== '' && (
                  <div className="search-content">
                    {this.props.loading ? <Loader/> : this.renderSearchContent()}
                    <div className={'ticket-pagination'}>
                      <Pagination
                        activePage={this.props.page}
                        boundaryRange={1}
                        onPageChange={(e, data) => this.handlePaginationChange(e, data)}
                        size="mini"
                        siblingRange={1}
                        totalPages={this.props.last}
                        ellipsisItem={'...'}
                        firstItem={null}
                        lastItem={null}
                        prevItem={null}
                        nextItem={null}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {this.state.content === 'add client' && <div>{this.renderAddClient()}</div>}
        {this.state.content === 'client details' && (
          <>
            <h3 className="client-list-header">{i18n.t('profile_client')}</h3>
            <div className="back-btn">
              <Button onClick={() => this.setState({ content: null, search_client: '' })}>{i18n.t('back')}</Button>
            </div>
            <div className="client-details">
              <CustomerFile id={this.state.id} editBtn={() => {
                this.setState({ content: 'add client' });
              }}/>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  search: (page, size, search) =>
    dispatch({ type: 'SEARCH_REQUEST_USER', payload: { page, size, search } }),
  store: (data) => dispatch({ type: 'STORE_CLIENT_REQUEST_USER', payload: { ...data, redirection: false } }),
  update: (data) => dispatch({ type: 'UPDATE_CLIENT_REQUEST_USER', payload: { ...data, redirection: false } }),
  getRoles: (page, size) => dispatch({ type: 'INDEX_REQUEST_ROLE', payload: { page, size } }),
  getClientPermission: () => dispatch({ type: 'CLIENT_PERMISSION_REQUEST' }),
  caisse_redirection: (value) => dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: { value } }),
  edit: (id) => dispatch({ type: 'EDIT_REQUEST_USER', payload: id }),

});

const mapStateToProps = (state) => {
  return {
    article_in_payment: state.caisse.article_in_payment,
    users: state.user.users,
    page: state.user.page,
    last: state.user.last,
    total: state.user.total,
    size: state.user.size,
    from: state.user.from,
    loading: state.user.loading,
    role: state.role,
    permissions: state.user.clientPermission,
    errors: state.user.error,
    updateClientSuccess : state.user.updateClientSuccess
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientList));
