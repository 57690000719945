import i18n from '../i18n';

export const archiveOptions = [
    { key: '0', text: i18n.t('competitions'), value: '0' },
    { key: '1', text: i18n.t('archive'), value: '1' },
];

export const daysOptions = [
    { key: 'monday', text: i18n.t('monday'), value: 'monday' },
    { key: 'tuesday', text: i18n.t('tuesday'), value: 'tuesday' },
    { key: 'wednesday', text: i18n.t('wednesday'), value: 'wednesday' },
    { key: 'thursday', text: i18n.t('thursday'), value: 'thursday' },
    { key: 'friday', text: i18n.t('friday'), value: 'friday' },
    { key: 'saturday', text: i18n.t('saturday'), value: 'saturday' },
    { key: 'sunday', text: i18n.t('sunday'), value: 'sunday' },
];

export const breakTimeOptions = [
    { key: "00:00", text: "00:00", value: "00:00" },
    { key: "00:05", text: "00:05", value: "00:05" },
    { key: "00:10", text: "00:10", value: "00:10" },
    { key: "00:15", text: "00:15", value: "00:15" },
    { key: "00:20", text: "00:20", value: "00:20" },
    { key: "00:25", text: "00:25", value: "00:25" },
    { key: "00:30", text: "00:30", value: "00:30" },
    { key: "00:35", text: "00:35", value: "00:35" },
    { key: "00:40", text: "00:40", value: "00:40" },
    { key: "00:45", text: "00:45", value: "00:45" },
    { key: "00:50", text: "00:50", value: "00:50" },
    { key: "00:55", text: "00:55", value: "00:55" },
    { key: "01:00", text: "01:00", value: "01:00" }
];

export const breakTimeMinutesOptions = [
    { key: "05:00", text: "05:00", value: "05:00" },
    { key: "10:00", text: "10:00", value: "10:00" },
    { key: "15:00", text: "15:00", value: "15:00" },
    { key: "20:00", text: "20:00", value: "20:00" },
    { key: "25:00", text: "25:00", value: "25:00" },
    { key: "30:00", text: "30:00", value: "30:00" },
    { key: "35:00", text: "35:00", value: "35:00" },
    { key: "40:00", text: "40:00", value: "40:00" },
    { key: "45:00", text: "45:00", value: "45:00" },
    { key: "50:00", text: "50:00", value: "50:00" },
    { key: "55:00", text: "55:00", value: "55:00" },
    { key: "60:00", text: "60:00", value: "60:00" }
];



export const getLang =()=>{
    return localStorage.getItem("lang")
}

