import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { getFormData, showFormData } from '../helpers/formDataHandler';

export default {
  articleService:
    {
      index: async ({ page ='', size, search }) => {
        const result = await getMethod('/articles?page=' + page +
          (size ? '&size=' + size :'') +
          (search && search.search_name ? '&search_name=' + search.search_name : '') +
          (search && search.search_parent ? '&search_parent=' + search.search_parent : '') +
          (search && search.search_child ? '&search_child=' + search.search_child : '') +
          '&complex_id=' + localStorage.getItem('complex_id'));
        return result.data;
      },
      store: async (data) => {
        let formData = new FormData();

        let newData = {
          name: data.name,
          info: data.info ? data.info : '',
          img: data.avatar,
          color:data.color,
          parent_id: data.parent_id,
          child_id: data.child_id,
          vat_id: data.vat_id,
          total_price: data.total_price,
          amount_ht: parseFloat(data.amount_ht).toFixed(3),
          vat_amount: parseFloat(data.vat_amount).toFixed(3),
          default_participants_nb: data.default_participants_nb,
          price_per_participant: data.price_per_participant,
          price_per_participant_ht: parseFloat(data.price_per_participant_ht).toFixed(3),
          duration_id: data.duration_id,
          hour_type_id: data.hour_type_id,
          mixed_hour_id: data.mixed_hour_id,
          complex_id: data.complex_id,
          details: data.details,
        };

        const result = await postMethod('/articles', getFormData(newData));
        return result.data;
      },
      edit: async (id) => {
        const result = await getMethod('/articles/' + id);
        return result.data;
      },
      update: async (data) => {
        let newData = {
          id: data.id,
          name: data.name,
          info: data.info ? data.info : '',
          img: data.avatar,
          color:data.color,
          parent_id: data.parent_id,
          child_id: data.child_id,
          vat_id: data.vat_id,
          total_price: data.total_price,
          amount_ht: parseFloat(data.amount_ht).toFixed(3),
          vat_amount: parseFloat(data.vat_amount).toFixed(3),
          default_participants_nb: data.default_participants_nb,
          price_per_participant: data.price_per_participant,
          price_per_participant_ht: parseFloat(data.price_per_participant_ht).toFixed(3),
          duration_id: data.duration_id,
          hour_type_id: data.hour_type_id,
          mixed_hour_id: data.mixed_hour_id,
          complex_id: data.complex_id,
          details: data.details,
        };
        
        let formData = getFormData(newData);

        showFormData(formData);
        formData.append('_method', 'put');

        const result = await postMethod('/articles/' + data.id, formData);
        return result.data;
      },
      destroy: async (id) => {
        const result = await deleteMethod('/articles/' + id);
        return result.data;
      },
    },
};