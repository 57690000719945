import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import activityTypesService from '../../services/activityTypesService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingActivityTypes: ['data'],

  indexRequestActivityTypes: ['payload'],
  indexResponseActivityTypes: ['data'],

  storeRequestActivityTypes: ['payload'],
  storeResponseActivityTypes: ['data'],

  editRequestActivityTypes: ['id'],
  editResponseActivityTypes: ['data'],

  updateRequestActivityTypes: ['payload'],
  updateResponseActivityTypes: ['data'],

  destroyRequestActivityTypes: ['id'],
  destroyResponseActivityTypes: ['data'],
});

// Initial state
const INITIAL_STATE = {
  activityTypes: [],
  activityType: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingActivityTypes({ loading: true }));
    const data = yield call(activityTypesService.activityTypesService.index, action.payload);
    yield put(Creators.indexResponseActivityTypes(data));
    yield put(Creators.loadingActivityTypes({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingActivityTypes({ loading: false }));
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingActivityTypes({ loading: true }));
    const data = yield call(activityTypesService.activityTypesService.store, action.payload);
    yield put(Creators.storeResponseActivityTypes(data));
    yield put(Creators.loadingActivityTypes({ loading: false }));
    yield history.push('/configuration/activityTypes');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingActivityTypes({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
    //
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingActivityTypes({ editLoading: true }));
    const data = yield call(activityTypesService.activityTypesService.edit, action.payload);
    yield put(Creators.editResponseActivityTypes(data));
    yield put(Creators.loadingActivityTypes({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingActivityTypes({ editLoading: false }));
    history.push('/error-404');
    //
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingActivityTypes({ loading: true }));
    const data = yield call(activityTypesService.activityTypesService.update, action.payload);
    yield put(Creators.updateResponseActivityTypes(data));
    yield put(Creators.loadingActivityTypes({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/activityTypes');
  } catch (e) {
    yield put(
      Creators.loadingActivityTypes({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
    //
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingActivityTypes({ deleteLoading: true }));
    const data = yield call(activityTypesService.activityTypesService.destroy, action.payload);
    yield put(Creators.destroyResponseActivityTypes(data));
    yield put(Creators.loadingActivityTypes({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    yield put(Creators.loadingActivityTypes({ deleteLoading: false }));
    errorToast(i18n.t('failed_delete'));
    //
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_ACTIVITY_TYPES, index);
  yield takeLatest(Types.STORE_REQUEST_ACTIVITY_TYPES, store);
  yield takeLatest(Types.EDIT_REQUEST_ACTIVITY_TYPES, edit);
  yield takeLatest(Types.UPDATE_REQUEST_ACTIVITY_TYPES, update);
  yield takeLatest(Types.DESTROY_REQUEST_ACTIVITY_TYPES, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activityTypes: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activityType: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};
// Reducer
export const activityTypesReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_ACTIVITY_TYPES]: index_response,
  [Types.STORE_RESPONSE_ACTIVITY_TYPES]: store_response,
  [Types.EDIT_RESPONSE_ACTIVITY_TYPES]: edit_response,
  [Types.UPDATE_RESPONSE_ACTIVITY_TYPES]: update_response,
  [Types.DESTROY_REQUEST_ACTIVITY_TYPES]: destroy_request,
  [Types.DESTROY_RESPONSE_ACTIVITY_TYPES]: destroy_response,
  [Types.LOADING_ACTIVITY_TYPES]: loading,
});
