/**
 * generate participant event for planning
 * */
import {
  convertDurationToAfter24,
  nullString,
  nullStringForTable,
  phoneNumber,
  toDuration,
  toMinutes,
} from "./formatting";
import moment from "moment";
import {
  conclusionColor,
  getResourceIds,
  player,
} from "./reservationFormatting";
import { imgUrl } from "./index";
import i18next from "i18next";

export const generateParticipantEvents = (
  begin,
  end,
  duration,
  date,
  nb,
  leisureStats
) => {
  let result = [];
  let dateCell = date;
  let totalM = (toMinutes(end) - toMinutes(begin)) / toMinutes(duration);
  let start_hour = begin;
  for (let i = 0; i < totalM; i++) {
    let index = leisureStats[i];
    // let index = 1;
    for (let j = index - 1; j <= nb; j++) {
      let part = {
        id: "participant-" + i + "-" + j,
        resourceId: j + 1,
        resource: j + 1,
        index: j + 1,
        type: "participant",
        eventOverlap: true,
        editable: false,
        startEditable: false,
        start: dateCell + "T" + start_hour + ":00+00:00",
        end: moment(dateCell + "T" + start_hour)
          .add(toMinutes(duration), "minutes")
          .format("YYYY-MM-DDTHH:mm:00+00:00"),
        dateEvent: dateCell,
        date: dateCell,
        startHour: start_hour,
        display: "none",
        endHour: moment(dateCell + "T" + start_hour)
          .add(toMinutes(duration), "minutes")
          .format("HH:mm"),
      };
      result.push(part);
    }
    dateCell = moment(dateCell + "T" + start_hour)
      .add(toMinutes(duration), "minutes")
      .format("YYYY-MM-DD");
    start_hour = moment(dateCell + "T" + start_hour)
      .add(toMinutes(duration), "minutes")
      .format("HH:mm");
  }
  return result;
};

export const resize = (arr, newSize, defaultValue = player) => {
  while (arr.length < newSize) arr.push({ ...defaultValue });
  arr.length = newSize;
};

export const isOverlap = (participantEvent, events) => {
  for (let i = 0; i < events.length; i++) {
    if (
      moment(participantEvent.start).isSameOrAfter(events[i].start) &&
      moment(participantEvent.start).isBefore(events[i].end) &&
      participantEvent.resource >= events[i].resource &&
      participantEvent.resource <=
        events[i].resource + parseInt(events[i].participants_nb) - 1
    )
      return true;
  }
  return false;
};

// generate main event for leisure planning
// order for now by desc
export const generateEvent = (events, begin, end, duration) => {
  let result = [];
  let nbResource = Math.floor(
    (toMinutes(end) - toMinutes(begin)) / toMinutes(duration)
  );
  let countPerResource = Array(nbResource).fill(1);
  // let eventsPair = events.filter(ev => multiCol(ev.start_hour, ev.end_hour, duration) === 1);
  // let eventsImpair = events.filter(ev => multiCol(ev.start_hour, ev.end_hour, duration) > 1);
  // eventsImpair.sort((ev1, ev2) => multiCol(ev1.start_hour, ev1.end_hour, duration) - multiCol(ev2.start_hour, ev2.end_hour, duration) > 0);
  formattedEvent(
    events.reverse(),
    begin,
    end,
    result,
    countPerResource,
    duration
  );

  return { oldEvents: result, stats: countPerResource };
};

const formattedEvent = (
  events,
  begin,
  end,
  result,
  countPerResource,
  duration,
  date
) => {
  for (let i = 0; i < events.length; i++) {
    let indexResource = getIndexResource(begin, events[i].start_hour, duration);
    if (indexResource >= countPerResource.length) indexResource = 0;
    let event = getFormattedMainEvent(
      events[i],
      countPerResource[indexResource]
    );
    result.push(event);
    //countPerResource = countResource(countPerResource, events[i], begin, end, duration);
    countPerResource = countResource(
      countPerResource,
      events[i],
      begin,
      end,
      duration,
      date
    );
  }
};

export const countResource = (counts, event, begin, end, duration, date) => {
  let start_hour = event.start_hour;
  let end_hour = event.end_hour;
  if (
    parseInt(moment(date).format("DD")) <
    parseInt(moment(event.date).format("DD"))
  ) {
    start_hour = convertDurationToAfter24(event.start_hour);
    end_hour = convertDurationToAfter24(event.end_hour);
  }
  let st = Math.abs(getIndexResource(begin, start_hour, duration, true));
  let en = Math.abs(getIndexResource(begin, end_hour, duration, false));
  if (st === en)
    counts[st] += event.match ? parseInt(event.match.participants_nb) : 0;
  else {
    for (let i = st; i < en; i++)
      counts[i] += event.match ? parseInt(event.match.participants_nb) : 0;
  }

  return counts;
};

export const multiCol = (start, end, duration) => {
  return Math.ceil((toMinutes(end) - toMinutes(start)) / toMinutes(duration));
};

// return index of event inside resource
export const getIndexResource = (start, end, duration, floor = true) => {
  if (floor)
    return Math.floor(
      (toMinutes(end) - toMinutes(start)) / toMinutes(duration)
    );
  else
    return Math.ceil((toMinutes(end) - toMinutes(start)) / toMinutes(duration));
};

export const getFormattedMainEvent = (it, pos = 1) => {
  let nb = it.match.participants_nb === null ? 1 : it.match.participants_nb;
  const HEIGHT_PER_ELEMENT = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--PLANNING-LEISURE-HEIGHT-JS");

  return {
    id: it.id,
    reservation_id: it.id,
    type: "event",
    resourceId: pos,
    resource: pos,
    participants_nb: it.match.participants_nb,
    height:
      getResourceIds(
        it.match.participants_nb === null ? 1 : it.match.participants_nb
      ).length * parseInt(HEIGHT_PER_ELEMENT),
    group_name: nullStringForTable(it.match.group_name),
    title: it.activity.name,
    conclusion: conclusionColor(it.conclusion),
    start: it.date + "T" + it.start_hour + ":00+00:00",
    end: moment(it.date + "T" + it.start_hour)
      .add(toMinutes(it.duration.duration), "minutes")
      .format("YYYY-MM-DDTHH:mm:00+00:00"),
    dateEvent: it.date,
    startHour: it.start_hour,
    endHour: it.end_hour,
    icon: it.activity.icon
      ? imgUrl +
        "/activities/" +
        localStorage.getItem("complex_id") +
        "/" +
        it.activity.icon
      : require("../assets/placeholder/item.jpg"),
    user: {
      firstname: nullStringForTable(
        it.contact_id
          ? it.contact
            ? it.contact.first_name
            : null
          : it.user
          ? it.user.firstName
          : null
      ),
      lastname: nullStringForTable(
        it.contact_id
          ? it.contact
            ? it.contact.last_name
            : null
          : it.user
          ? it.user.lastName
          : null
      ),
      phone: nullStringForTable(
        it.contact_id
          ? it.contact
            ? phoneNumber(it.contact.mobile)
            : null
          : it.user
          ? phoneNumber(it.user.mobile)
          : null
      ),
      sociaty: nullStringForTable(
        it.contact_id
          ? it.contact
            ? it.contact.company
            : null
          : it.user
          ? it.user.company
          : null
      ),
    },
    participants: fillParticipants(
      getEventParticipants(
        it.participants,
        it.article_id,
        it.ticket_participants ? it.ticket_participants : []
      ),
      nb,
      it.article_id,
      it.ticket_participants ? it.ticket_participants : []
    ),
    owner: {
      firstname: nullString(it.owner ? it.owner.firstName : null),
      lastname: nullString(it.owner ? it.owner.lastName : null),
      mobile: nullString(it.owner ? phoneNumber(it.owner.mobile) : null),
    },
    info: it.infos,
    created_at: it.created_at,
    status: it.ticket ? it.ticket.status : -1,
  };
};

const getEventParticipants = (
  participants,
  article_id,
  ticket_participants
) => {
  return participants.map((it) => {
    return {
      ...it,
      disabled: isParticipantLeisure(it.id, article_id, ticket_participants),
      type: "exist",
    };
  });
};

export const generateNewGames = (games, duration = "00:30") => {
  let newGames = [...games];

  if (newGames.length > 1) {
    for (let i = 1; i < newGames.length; i++) {
      newGames[i].date = newGames[i - 1].date;
      newGames[i].start_hour = newGames[i - 1].end_hour;
      newGames[i].end_hour = getEndHour(newGames[i - 1].end_hour, duration);
    }
  }
  return newGames;
};

let getEndHour = (start, duration) => {
  let convertedStart = toMinutes(start);
  let convertedDuration = toMinutes(duration);
  let newEnd = convertedStart + convertedDuration;
  return toDuration(newEnd);
};

export const checkGamesRepetition = (games, index = 0) => {
  let filterResult = false;

  for (let i = 0; i < games.length; i++) {
    if (
      i !== index &&
      games[index].start_hour === games[i].start_hour &&
      games[index].end_hour === games[i].end_hour &&
      games[index].date === games[i].date
    )
      return true;
  }

  return filterResult;
};

export const isParticipantLeisure = (user_id, article_id, participants) => {
  if (participants && participants.length > 0) {
    let filterData = participants.filter(
      (it, i) => it.article_id === article_id && it.user_id === user_id
    );
    if (filterData.length > 0) {
      return true;
    }
  }

  return false;
};

export const fillParticipants = (
  parts,
  nb,
  article_id,
  ticket_participants
) => {
  let diff = nb - parts.length;
  if (diff > 0) {
    let newParts = [...parts];
    for (let i = 0; i < diff; i++) {
      let newUser = {
        index: parts.length + 1 + i,
        firstName: i18next.t("participant"),
        lastName: (parts.length + 1 + i).toString(),
        disabled: false,
        type: "new",
      };

      let filterData = ticket_participants.filter(
        (it, i) =>
          it.article_id === article_id &&
          it.user_index === it.article_id + "-" + newUser.index
      );

      if (filterData.length > 0)
        newUser.disabled = filterData[0].status !== null;
      newParts.push(newUser);
    }
    return newParts;
  }
  return parts;
};
