import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import subscriptionTypeService from '@/services/subscriptionTypeService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingSubscriptionType: ['payload'],

  indexRequestSubscriptionType: ['payload'],
  indexResponseSubscriptionType: ['data'],

  storeRequestSubscriptionType: ['payload'],
  storeResponseSubscriptionType: ['data'],

  editRequestSubscriptionType: ['id'],
  editResponseSubscriptionType: ['data'],

  updateRequestSubscriptionType: ['payload'],
  updateResponseSubscriptionType: ['data'],

  destroyRequestSubscriptionType: ['id'],
  destroyResponseSubscriptionType: ['data'],
});

// Initial state
const INITIAL_STATE = {
  subscriptionTypes: [],
  subscriptionType: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingSubscriptionType({ loading: true }));
    const data = yield call(subscriptionTypeService.subscriptionTypeService.index, action.payload);
    yield put(Creators.indexResponseSubscriptionType(data));
    yield put(Creators.loadingSubscriptionType({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingSubscriptionType({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingSubscriptionType({ loading: true }));
    const data = yield call(subscriptionTypeService.subscriptionTypeService.store, action.payload);
    yield put(Creators.storeResponseSubscriptionType(data));
    yield put(Creators.loadingSubscriptionType({ loading: false }));
    yield history.push('/configuration/subscriptionTypes');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingSubscriptionType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingSubscriptionType({ editLoading: true }));
    const data = yield call(subscriptionTypeService.subscriptionTypeService.edit, action.payload);
    yield put(Creators.editResponseSubscriptionType(data));
    yield put(Creators.loadingSubscriptionType({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingSubscriptionType({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingSubscriptionType({ loading: true }));
    const data = yield call(subscriptionTypeService.subscriptionTypeService.update, action.payload);
    yield put(Creators.updateResponseSubscriptionType(data));
    yield put(Creators.loadingSubscriptionType({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/subscriptionTypes');
  } catch (e) {
    yield put(
      Creators.loadingSubscriptionType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingSubscriptionType({ deleteLoading: true }));
    const data = yield call(
      subscriptionTypeService.subscriptionTypeService.destroy,
      action.payload,
    );
    yield put(Creators.destroyResponseSubscriptionType(data));
    yield put(Creators.loadingSubscriptionType({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_SUBSCRIPTION_TYPE, index);
  yield takeLatest(Types.STORE_REQUEST_SUBSCRIPTION_TYPE, store);
  yield takeLatest(Types.EDIT_REQUEST_SUBSCRIPTION_TYPE, edit);
  yield takeLatest(Types.UPDATE_REQUEST_SUBSCRIPTION_TYPE, update);
  yield takeLatest(Types.DESTROY_REQUEST_SUBSCRIPTION_TYPE, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    subscriptionTypes: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    subscriptionType: action.data.data,
  };
};
//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const subscriptionTypeReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_SUBSCRIPTION_TYPE]: index_response,
  [Types.STORE_RESPONSE_SUBSCRIPTION_TYPE]: store_response,
  [Types.EDIT_RESPONSE_SUBSCRIPTION_TYPE]: edit_response,
  [Types.UPDATE_RESPONSE_SUBSCRIPTION_TYPE]: update_response,
  [Types.DESTROY_REQUEST_SUBSCRIPTION_TYPE]: destroy_request,
  [Types.DESTROY_RESPONSE_SUBSCRIPTION_TYPE]: destroy_response,
  [Types.LOADING_SUBSCRIPTION_TYPE]: loading,
});
