/***
 *
 * @param a
 * @param b
 * @returns {number}
 */
import { nullStringNumber } from './formatting';

export const sub = (a, b) => {
  return toFloatNumber(toFloatNumber(a) - toFloatNumber(b))
};

/***
 *
 * @param a
 * @param b
 * @returns {string|number}
 */
export const add = (a,b)=>{
  return toFloatNumber(toFloatNumber(a) + toFloatNumber(b))
}

/***
 * return float number fix fixed fraction
 * @param a
 * @returns {string|number}
 */
export const toFloatNumber = (a) => {
  try {
    return parseFloat(nullStringNumber(a).toFixed(2));
  } catch (e) {
    return 0;
  }
}

/***
 * handle convert text to number
 */
export const handleNumberInputChange = (value) => {
  if (value === '.') value = '0.';
  if (isNaN(value) && value !== '0.') return;
  let hasDot = value.split('').filter((it) => it === '.');
  if (hasDot.length > 1) {
    let delted = value.split('');
    delted.pop();
    value = delted.join('');
  }
}

/***
 * return excess of division if division  not exact
 */
export const exactDivision = (totalPrice , itemPrice , nb)=>{
  return parseFloat(totalPrice) - (parseFloat(itemPrice) * parseInt(nb));
}