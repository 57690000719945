import React from 'react';
import { Button } from 'semantic-ui-react';
import './CustomReservationHistory.css';
import moment from 'moment';
import 'moment/locale/fr';
import { nullString } from '../../helpers/formatting';

const toDate = (data) => {
  let arr = data.split(' ');
  arr.pop();
  return arr.join(' ');
};
const CustomReservationHistory = (props) => {
  let conclusionBackground = '';
  switch (props.conclusion) {
    case 'A Venir':
      conclusionBackground = '#7E72F2';
      break;
    case 'A valider':
      conclusionBackground = '#babbbc';
      break;
    case 'Pas Venu':
      conclusionBackground = '#4F4F4F';
      break;
    default:
      conclusionBackground = '#72F286';
      break;
  }
  return (
    <div className={'modal-history-item'}>
      <div className={'history-item-status'} style={{ background: conclusionBackground }}/>
      <div className={'history-item-right'}>
        <div>
          <span>
            {props.activity} - {props.duration}
          </span>
          <span>
            Le{' '}
            {props.date ? toDate(moment(props.date).locale('fr').format('LLLL')) : ''}{' '}
            - {props.start_hour}/{props.end_hour}
          </span>
          <span>
            {props.last_name} {props.first_name} - {props.mobile}
          </span>
          <span style={{ fontStyle: 'italic' }}>
            {props.type === 'deleted' ? 'Supprimée le' : 'Crée le'}{' '}
            {props.type === 'deleted' ? props.deleted_at
              ? moment(props.deleted_at).locale('fr').format('LLL')
              : '' : props.created_at
              ? moment(props.created_at).locale('fr').format('LLL')
              : ''}
            {props.type === 'deleted' ? props.remover ?
              ' par ' + props.remover.firstName + ' ' + nullString(props.remover.lastName).toUpperCase() : '' : props.owner ?
              ' par ' + props.owner.firstName + ' ' + nullString(props.owner.lastName).toUpperCase() : ''
            }
          </span>
        </div>
        {props.type === 'deleted' ? (
          <Button primary onClick={async () => {
            let day = new Date(props.date);
            await props.setNewDate(moment(day).format('YYYY-MM-DD'));
            await props.onClick(props.id);
          }
          }>
            Récupérer
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default CustomReservationHistory;
