import React from "react";
import "./SubscriptionModal.css";
import { Button, Form, Grid, Icon, Input, Loader, Modal } from "semantic-ui-react";
import { formatCodeCountry, nullString, phoneNumber, randomstring } from "../../../helpers/formatting";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import CustomInput from "../../CustomInput/CustomInput";
import { DEFAULT_COUNTRY_CODE } from "../../../utils/countryCode";
import { throttle } from "../../../helpers";

class ChooseUserAdvanceModal extends React.Component {

  constructor(props) {
    super(props);
    let somePassword = randomstring();
    this.state = {
      modalContent: props.currentTicket && props.currentTicket.user_id ? "affect player" : null,
      search_player: "",
      user: {
        id: "",
        firstName: "",
        lastName: "",
        mobile: "",
        country_code: DEFAULT_COUNTRY_CODE,
        email: "",
        avatar: ""
      },
      payment_type_list: [],
      teams: [],
      capitaine: [],
      password: somePassword,
      password_confirmation: somePassword,
      errors: {
        firstName: null,
        lastName: null,
        email: null
      }
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  componentDidMount() {
    if (this.props.currentTicket && this.props.currentTicket.user_id) {
      this.props.editPlayer(this.props.currentTicket.user_id);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user !== this.props.user) {
      let user = this.state.user;
      user.id = this.props.user.id;
      user.firstName = this.props.user.firstName;
      user.lastName = this.props.user.lastName;
      user.email = this.props.user.email;
      user.mobile = this.props.user.mobile;
      user.country_code = this.props.user.country_code;
      user.avatar = this.props.user.avatar;
      this.setState({ user: user, modalContent: "affect player" });

    }
    if (!this.props.loading && JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)) {
      let errors = {
        firstName: null,
        lastName: null,
        email: null
      };
      for (let index in this.props.errors) {
        errors[index] = { content: this.props.errors[index][0] };
      }
      this.setState({ errors });
    }
  }

  onChangeHandle(event, mobileEvent) {
    let user = { ...this.state.user };
    user[event.target.name] = event.target.value;
    if (mobileEvent)
      user[mobileEvent.target.name] = mobileEvent.target.value;
    this.setState({
      user: user
    });
  }

  renderAffectPlayerModal = () => {
    return (
      <Form>
        {this.props.editLoading ? (
          <Loader/>
        ) : (
          <>
            <Grid>
              <Grid.Column className="player-input">
                <CustomInput
                  name={"lastName"}
                  label={i18n.t("last_name")}
                  type={"text"}
                  value={this.state.user.lastName}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  name={"firstName"}
                  label={i18n.t("first_name")}
                  type={"text"}
                  value={this.state.user.firstName}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  name={"mobile"}
                  label={i18n.t("mobile")}
                  type={"phone"}
                  value={this.state.user.mobile}
                  countrycode={this.state.user.country_code}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  name={"email"}
                  label={i18n.t("email")}
                  type={"text"}
                  value={this.state.user.email}
                  onChange={this.onChangeHandle}
                />
              </Grid.Column>
            </Grid>
            <div className="group-btn-edit-player">
              <Button
                className="btn-select"
                content={i18n.t("select")}
                onClick={() => {
                  let currentTicket = this.props.currentTicket;
                  currentTicket.user_id = this.state.id;
                  currentTicket.user = this.state.user;
                  this.props.updateCurrentTicket(currentTicket);
                  //this.props.onClose();
                  this.props.onChange(this.state.user);
                  //this.setState({modalContent : '',search_player:''});
                }}
              />
              <Button
                className="btn-select"
                content={i18n.t("cancel")}
                onClick={() => {
                  this.props.onClose();
                }}
              />
            </div>
          </>
        )}
      </Form>
    );
  };

  handleChangeSearch = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      modalContent: ""
    });

    this.props.searchPlayer(this.props.page, 8, event.target.value);
  };

  renderSearchContent() {
    return this.props.users.map((item, index) => {
      return (
        <div key={index} className="player-row">
            <span>
              {nullString(item.firstName).toLowerCase()} {nullString(item.lastName).toUpperCase()}
            </span>
          <span>{formatCodeCountry(item.country_code)  + phoneNumber(item.mobile)}</span>
          <span>{item.email}</span>
          <span style={{ color: "#7EB5E5" }}>
              <Icon
                name="edit"
                onClick={() => {
                  this.setState({ modalContent: "affect player", id: item.id });
                  this.props.editPlayer(item.id);
                }}
              />
            </span>
        </div>
      );
    });
  }

  handleSubmit = () => {
    let { roles } = this.props.role;
    let r = roles.filter((it) => {
      return it.slug === "client";
    });
    let data = {
      firstName: this.state.user.firstName,
      lastName: this.state.user.lastName,
      email: this.state.user.email,
      mobile: this.state.user.mobile,
      country_code: this.state.user.country_code,
      teams: this.state.teams,
      capitaine: this.state.capitaine,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      roles: r.length > 0 ? r[0].id : null,
      checkout: true,
      complexes: Number(localStorage.getItem("complex_id"))
    };
    this.props.store(data);
  };

  handleThrottleSubmit = throttle(this.handleSubmit, 2000);

  renderAddPlayer = () => {
    return (
      <>
        <div className="player-input">
          <CustomInput
            name={"lastName"}
            label={i18n.t("last_name")}
            type={"text"}
            value={this.state.user.lastName}
            onChange={this.onChangeHandle}
            error={this.state.errors.lastName !== null}
            errormsg={this.state.errors.lastName}
          />
          <CustomInput
            name={"firstName"}
            label={i18n.t("first_name")}
            type={"text"}
            value={this.state.user.firstName}
            onChange={this.onChangeHandle}
            error={this.state.errors.firstName !== null}
            errormsg={this.state.errors.firstName}
          />
          <CustomInput
            name={"mobile"}
            label={i18n.t("mobile")}
            type={"phone"}
            value={this.state.user.mobile}
            countrycode={this.state.user.country_code}
            onChange={this.onChangeHandle}
          />
          <CustomInput
            name={"email"}
            label={i18n.t("email")}
            type={"text"}
            value={this.state.user.email}
            onChange={this.onChangeHandle}
            error={this.state.errors.email !== null}
            errormsg={this.state.errors.email}
          />
        </div>
        <br/>
        <Button
          loading={this.props.loading}
          disabled={this.props.loading}
          className="player-green-btn"
          onClick={this.handleThrottleSubmit}
        >
          {i18n.t("save")}
        </Button>
      </>
    );
  };


  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        size="large"
        className="modal-ticket modal-edit-player"
      >
        <Modal.Content className="modal-content">
          <h3 className="modal-content-header">Information client</h3>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={this.props.onClose}
            />
            <div className="search-bloc">
              {this.state.modalContent !== "add player" && (
                <>
                  <Input
                    name="search_player"
                    className="search-input-player"
                    icon="search"
                    placeholder="Recherche"
                    onChange={this.handleChangeSearch}
                    value={this.state.search_player}
                  />
                  {this.state.modalContent === null && (
                    <div className="add-player">
                      <Button
                        icon="plus"
                        color="blue"
                        circular
                        size="tiny"
                        onClick={() => {
                          let user = {
                            id: "",
                            firstName: "",
                            lastName: "",
                            mobile: "",
                            country_code: DEFAULT_COUNTRY_CODE,
                            email: "",
                            avatar: ""
                          };
                          this.setState({
                            modalContent: "add player",
                            user: user
                          });
                        }}
                      />
                      Ajouter client
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="player-informations">
              {this.state.modalContent === "" && this.state.search_player !== "" && (
                <div className="search-list">
                  {this.props.loading ? <Loader/> : this.renderSearchContent()}
                </div>
              )}
              {this.state.modalContent === "affect player" && (
                <div style={{ margin: " 50px 90px" }}>{this.renderAffectPlayerModal()}</div>
              )}
              {this.state.modalContent === "add player" && (
                <>
                  <div className="back-btn">
                    <Button
                      content="Retour"
                      onClick={() => this.setState({ modalContent: "" })}
                    /><br/>
                  </div>
                  <div className="add-player-bloc">{this.renderAddPlayer()}</div>
                </>
              )}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchPlayer: (page, size, search) => dispatch({ type: "SEARCH_REQUEST_USER", payload: { page, size, search } }),
  store: (data) => dispatch({ type: "STORE_CLIENT_REQUEST_USER", payload: { ...data, redirection: false } }),
  editPlayer: (id) => dispatch({ type: "EDIT_REQUEST_USER", payload: id }),
  updateCurrentTicket: (data) => dispatch({ type: "UPDATE_CURRENT_TICKET_REQUEST", payload: data })
});
const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    client: state.user.client,
    page: state.user.page,
    size: state.user.size,
    loading: state.user.loading,
    role: state.role,
    currentTicket: state.caisse.currentTicket,
    errors: state.user.error,
    user: state.user.selected,
    editLoading: state.user.editLoading,
    credit: state.caisse.credit,
    loadingDetail: state.subscriber.loadingDetail
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ChooseUserAdvanceModal);



