import React from 'react';
import { Button } from 'semantic-ui-react';
import '../CustomCaisse.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const HourMenuItem = (props) => {
  //active button
  let style = { border: '3px solid #AFD7FA' };
  if (props.id == props.selectTypeHour) {
    style = { border: '3px solid #7EB5E5' };
  }
  //
  return (
    <Button className="child-menu-item" style={style}
            onClick={() => props.select_hour_type(props.id)}
    >
      <span>{props.name}</span>
    </Button>
  );
};
const mapDispatchToProps = (dispatch) => ({
  select_hour_type: (value) => dispatch({ type: 'SELECT_HOUR_TYPE_REQUEST', payload: value }),
});

const mapStateToProps = state => {
  return {
    selectFamily: state.caisse.selectFamily,
    selectSubFamily: state.caisse.selectSubFamily,
    selectTypeHour: state.caisse.selectTypeHour,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HourMenuItem));