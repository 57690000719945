import { deleteMethod, getMethod, postMethod } from "../helpers";
import { getFormData, showFormData } from "../helpers/formDataHandler";
import { reversePhoneNumber } from "../helpers/formatting";
import { exportFile } from '@/helpers/exportDownloader';


export const search = ({ page, size = "", search, search_subscribers = null, is_reload = null }) => {
  return getMethod(
    "/clients/search?complex_id=" +
    localStorage.getItem("complex_id") +
    "&page=" + page +
    "&size=" + size +
    "&search=" + search +
    (search_subscribers ? "&search_subscribers=" + search_subscribers : "") +
    (is_reload ? "&is_reload=" + is_reload : "") +
    "&type=client"
  );
};

export const index = ({ page, size, search, type = "" }) => {
  if (type === "") {
    return getMethod(
      "/users?&type=" +
      (page ? "&page=" + page : "") +
      (size ? "&size=" + size : "") +
      (search && search.search_first_name
        ? "&search_first_name=" + search.search_first_name
        : "") +
      (search && search.search_last_name ? "&search_last_name=" + search.search_last_name : "") +
      (search && search.search_email ? "&search_email=" + search.search_email : "") +
      (search && search.search_mobile ? "&search_mobile=" + reversePhoneNumber(search.search_mobile) : "") +
      "&complex_id=" + localStorage.getItem("complex_id")
    );
  } else
    return getMethod(
      "/users?type=client" +
      (page ? "&page=" + page : "") +
      (size ? "&size=" + size : "") +
      (search && search.search_first_name
        ? "&search_first_name=" + search.search_first_name
        : "") +
      (search && search.search_last_name ? "&search_last_name=" + search.search_last_name : "") +
      (search && search.search_email ? "&search_email=" + search.search_email : "") +
      (search && search.search_mobile ? "&search_mobile=" + reversePhoneNumber(search.search_mobile) : "") +
      "&complex_id=" + localStorage.getItem("complex_id")
    );
};

/***********user******************/
export const store = (data) => {
  // form data
  let permissions = {};
  let newPermissions = {};
  let complexes = {};

  for (let i = 0; i < data.module.length; i++) {
    let moduleElement = data.module[i];
    for (let j = 0; j < moduleElement.length; j++) {
      for (let k = 0; k < moduleElement[j].permission.length; k++) {
        let permission = {};
        if (moduleElement[j].permission[k].checked) {
          permission = {
            [moduleElement[j].permission[k].id]: {
              module_id: moduleElement[j].id,
              complex_id: data.information.complex_id[i]
            }
          };
          permissions = { ...permissions, ...permission };
        }
      }
    }

    newPermissions = { ...newPermissions, [i]: permissions };
  }

  let information = { ...data.information };
  let roles = [information.role_id];

  for (let i = 0; i < information.complex_id.length; i++) {
    let obj = {
      [information.complex_id[i]]: {
        is_default: information.is_default[i] ? 1 : 0
      }
    };
    complexes = { ...complexes, ...obj };
  }
  delete information.is_default;
  delete information.is_default_labels;
  delete information.complex_id;
  delete information.role_id;
  let newData = {
    complex_id: localStorage.getItem("complex_id"),
    ...information,
    user_permissions: newPermissions,
    complexes: { ...complexes },
    roles: roles
  };
  let fd = getFormData(newData);
  showFormData(fd);

  return postMethod("/users", fd);
};

export const edit = (id) => {
  return getMethod("/users/" + id + "?complex_id=" + localStorage.getItem("complex_id"));
};

export const update = (data) => {
  let permissions = {};
  let newPermissions = {};
  let complexes = {};

  for (let i = 0; i < data.module.length; i++) {
    let moduleElement = data.module[i];
    for (let j = 0; j < moduleElement.length; j++) {
      for (let k = 0; k < moduleElement[j].permission.length; k++) {
        let permission = {};
        if (moduleElement[j].permission[k].checked) {
          permission = {
            [moduleElement[j].permission[k].id]: {
              module_id: moduleElement[j].id,
              complex_id: data.information.complex_id[i]
            }
          };
          permissions = { ...permissions, ...permission };
        }
      }
    }

    newPermissions = { ...newPermissions, [i]: permissions };
  }

  let information = { ...data.information };
  let roles = [information.role_id];

  for (let i = 0; i < information.complex_id.length; i++) {
    let obj = {
      [information.complex_id[i]]: {
        is_default: information.is_default[i] ? 1 : 0
      }
    };
    complexes = { ...complexes, ...obj };
  }
  delete information.is_default;
  delete information.is_default_labels;
  delete information.complex_id;
  delete information.role_id;
  let newData = {
    complex_id: localStorage.getItem("complex_id"),
    ...information,
    user_permissions: newPermissions,
    complexes: { ...complexes },
    roles: roles
  };
  let fd = getFormData(newData);
  fd.append("_method", "put");
  showFormData(fd);

  return postMethod("/users/" + data.information.id, fd);
};

export const destroy = (id) => {
  return deleteMethod("/users/" + id);
};

//export
export const exportExcel = async ({ search }) => {
  const response = await getMethod(
    '/usersExport?complex_id=' + localStorage.getItem('complex_id') +
    (search && search.search_first_name ? "&search_first_name=" + search.search_first_name : "") +
    (search && search.search_last_name ? "&search_last_name=" + search.search_last_name : "") +
    (search && search.search_email ? "&search_email=" + search.search_email : "") +
    (search && search.search_mobile ? "&search_mobile=" + reversePhoneNumber(search.search_mobile) : ""),
    {responseType: 'blob'}
  );

  exportFile(response, 'Utilisateurs');
}

/***************client************/
export const clientPermission = () => {
  return getMethod("/clientPermissions");
};

export const storeClient = (data) => {
  let { teams, capitaine } = data;
  let formattedTeams = {};
  if (teams.length > 0) {
    for (let i = 0; i < teams.length; i++) {
      let obj = {
        [teams[i]]: {
          complex_id: localStorage.getItem("complex_id"),
          capitaine: capitaine[i] ? 1 : 0
        }
      };
      formattedTeams = { ...formattedTeams, ...obj };
    }
    data.teams = formattedTeams;
  }

  delete data.capitaine;
  delete data.imgPreview;
  delete data.errors;
  delete data.teamsData;
  let fd = getFormData({ ...data, complex_id: localStorage.getItem("complex_id") });
  showFormData(fd);

  return postMethod("/users", fd);
};

export const updateClient = (data) => {
  let { teams, capitaine } = data;
  let formattedTeams = {};

  if (teams.length > 0) {
    for (let i = 0; i < teams.length; i++) {
      let obj = {
        [teams[i]]: {
          complex_id: localStorage.getItem("complex_id"),
          capitaine: capitaine[i] ? 1 : 0
        }
      };
      formattedTeams = { ...formattedTeams, ...obj };
    }
    data.teams = formattedTeams;
  }
  delete data.capitaine;
  delete data.imgPreview;
  delete data.errors;
  delete data.password;
  delete data.password_confirmation;
  delete data.reservations;
  delete data.subscriptions;
  delete data.avatar;
  delete data.teamsData;
  let fd = getFormData({ ...data, complex_id: localStorage.getItem("complex_id") });
  fd.append("_method", "put");
  showFormData(fd);

  return postMethod("/users/" + data.id, fd);
};

export const checkIsAvailable = (data) => {
  return postMethod("/checkUserComplex", data);
};

export const joinToComplex = (data) => {
  return postMethod("/addUserComplex", data);
};
