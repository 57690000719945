import React, { useCallback } from 'react';
import './RightContainer.css';
import Navbar from '../Navbar/Navbar';
const  RightContainer =React.memo((props)=>{

    let children = props.children;

    let toggleSidemenu = useCallback(()=>{
       props.toggleSidemenu()
    },[]);

    let toggleDarkMode = useCallback(()=>{
       props.toggleDarkMode()
    },[]);


    return (
      <div className="right-side">
        {props.withMenu && (
          <Navbar onPress={toggleSidemenu} toggleDarkMode={toggleDarkMode} />
        )}

        <div className="page-padding">{children}</div>
      </div>
    );
});


export default RightContainer;
