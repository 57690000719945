import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import mixedHourService from '../../services/mixedHourService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingMixedHour: ['data'],

  indexRequestMixedHour: ['payload'],
  indexResponseMixedHour: ['data'],

  storeRequestMixedHour: ['payload'],
  storeResponseMixedHour: ['data'],

  editRequestMixedHour: ['id'],
  editResponseMixedHour: ['data'],

  updateRequestMixedHour: ['payload'],
  updateResponseMixedHour: ['data'],

  destroyRequestMixedHour: ['id'],
  destroyResponseMixedHour: ['data'],
});

// Initial state
const INITIAL_STATE = {
  mixedHours: [],
  mixedHour: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingMixedHour({ loading: true }));
    const data = yield call(mixedHourService.mixedHourService.index, action.payload);
    yield put(Creators.indexResponseMixedHour(data));
    yield put(Creators.loadingMixedHour({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingMixedHour({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingMixedHour({ loading: true }));
    const data = yield call(mixedHourService.mixedHourService.store, action.payload);
    yield put(Creators.storeResponseMixedHour(data));
    yield put(Creators.loadingMixedHour({ loading: false }));
    yield history.push('/configuration/mixedHours');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingMixedHour({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingMixedHour({ editLoading: true }));
    const data = yield call(mixedHourService.mixedHourService.edit, action.payload);
    yield put(Creators.editResponseMixedHour(data));

    yield put(Creators.loadingMixedHour({ editLoading: false }));
  } catch (e) {
    yield put(Creators.editResponseMixedHour(null));
    yield put(Creators.loadingMixedHour({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingMixedHour({ loading: true }));
    const data = yield call(mixedHourService.mixedHourService.update, action.payload);
    yield put(Creators.updateResponseMixedHour(data));
    yield put(Creators.loadingMixedHour({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/mixedHours');
  } catch (e) {
    yield put(
      Creators.loadingMixedHour({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingMixedHour({ deleteLoading: true }));
    const data = yield call(mixedHourService.mixedHourService.destroy, action.payload);
    yield put(Creators.destroyResponseMixedHour(data));
    yield put(Creators.loadingMixedHour({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_MIXED_HOUR, index);
  yield takeLatest(Types.STORE_REQUEST_MIXED_HOUR, store);
  yield takeLatest(Types.EDIT_REQUEST_MIXED_HOUR, edit);
  yield takeLatest(Types.UPDATE_REQUEST_MIXED_HOUR, update);
  yield takeLatest(Types.DESTROY_REQUEST_MIXED_HOUR, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    mixedHours: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    mixedHour: { ...action.data.data },
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const mixedHourReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_MIXED_HOUR]: index_response,
  [Types.STORE_RESPONSE_MIXED_HOUR]: store_response,
  [Types.EDIT_RESPONSE_MIXED_HOUR]: edit_response,
  [Types.UPDATE_RESPONSE_MIXED_HOUR]: update_response,
  [Types.DESTROY_REQUEST_MIXED_HOUR]: destroy_request,
  [Types.DESTROY_RESPONSE_MIXED_HOUR]: destroy_response,
  [Types.LOADING_MIXED_HOUR]: loading,
});
