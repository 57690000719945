import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import currencyService from '@/services/currencyService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingCurrency: ['payload'],

  indexRequestCurrency: ['payload'],
  indexResponseCurrency: ['data'],

  storeRequestCurrency: ['payload'],
  storeResponseCurrency: ['data'],

  editRequestCurrency: ['id'],
  editResponseCurrency: ['data'],

  updateRequestCurrency: ['payload'],
  updateResponseCurrency: ['data'],

  destroyRequestCurrency: ['id'],
  destroyResponseCurrency: ['data'],
});

// Initial state
const INITIAL_STATE = {
  currencies: [],
  currency: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingCurrency({ loading: true }));
    const data = yield call(currencyService.currencyService.index, action.payload);
    yield put(Creators.indexResponseCurrency(data));
    yield put(Creators.loadingCurrency({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingCurrency({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingCurrency({ loading: true }));
    const data = yield call(currencyService.currencyService.store, action.payload);
    yield put(Creators.storeResponseCurrency(data));
    yield put(Creators.loadingCurrency({ loading: false }));
    yield history.push('/configuration/currencies');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingCurrency({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingCurrency({ editLoading: true }));
    const data = yield call(currencyService.currencyService.edit, action.payload);
    yield put(Creators.editResponseCurrency(data));
    yield put(Creators.loadingCurrency({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingCurrency({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingCurrency({ loading: true }));
    const data = yield call(currencyService.currencyService.update, action.payload);
    yield put(Creators.updateResponseCurrency(data));
    yield put(Creators.loadingCurrency({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/currencies');
  } catch (e) {
    yield put(
      Creators.loadingCurrency({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingCurrency({ deleteLoading: true }));
    const data = yield call(currencyService.currencyService.destroy, action.payload);
    yield put(Creators.destroyResponseCurrency(data));
    yield put(Creators.loadingCurrency({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CURRENCY, index);
  yield takeLatest(Types.STORE_REQUEST_CURRENCY, store);
  yield takeLatest(Types.EDIT_REQUEST_CURRENCY, edit);
  yield takeLatest(Types.UPDATE_REQUEST_CURRENCY, update);
  yield takeLatest(Types.DESTROY_REQUEST_CURRENCY, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currencies: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currency: action.data.data,
  };
};
//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding_currency = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const currencyReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_CURRENCY]: index_response,
  [Types.STORE_RESPONSE_CURRENCY]: store_response,
  [Types.EDIT_RESPONSE_CURRENCY]: edit_response,
  [Types.UPDATE_RESPONSE_CURRENCY]: update_response,
  [Types.DESTROY_REQUEST_CURRENCY]: destroy_request,
  [Types.DESTROY_RESPONSE_CURRENCY]: destroy_response,
  [Types.LOADING_CURRENCY]: laoding_currency,
});
