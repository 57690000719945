import React from 'react';
import '../../CustomCaisse.css';
import { Button, Grid, Input } from 'semantic-ui-react';
import i18n from '@/i18n';
import ModalConfirm from '@/components/ModalConfirm/ModalConfirm';
import { infoToast } from '@/helpers/customToast';
import {
  getCurrentNote,
  getCurrentNoteIndex,
  getNoteDiscount,
  getPayedAmountNote,
  hasParticipantsNote,
  returnArticleNoteToTicket,
} from '@/helpers/caisse/noteFormatting';
import { getArticleDiscount } from '@/helpers/ticket';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
import Image from '../../../ImageComponent/Image';
import { nullString } from '../../../../helpers/formatting';
import AddClientModal from '../../Modal/AddClientModal';
import { getActivityAmount, getTotalAmountForNote } from '../../../../helpers/caisseFormatting';

class NoteDivision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteNoteModalOpen: false,
      userModalOpen: false,
      editNoteUser: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {

  }

  renderHeader() {
    return (
      <Grid.Row key={'article-header-1'}>
        <Grid.Column width={1} className="note-item-label note-item-sub-label">
          Qté
        </Grid.Column>
        <Grid.Column width={10} className="note-item-label note-item-sub-label">
          Libellé
        </Grid.Column>
        <Grid.Column className="note-item-label note-item-sub-label" width={2} textAlign="right">
          Prix
        </Grid.Column>
      </Grid.Row>
    );
  }

  affectPlayer(user, user_index) {
    let data = { ...this.props.currentTicket };
    let selectedParticipant = null;
    if (data.reservation) {
      data.created_reservation = { ...data.reservation };
    }

    data.participants.map((item, i) => {
      if (item.user_index.toString() === user_index) {
        item['user_id'] = user.id;
        item['firstName'] = user.firstName;
        item['lastName'] = user.lastName;
        if (data.reservation) {
          let participants = data.created_reservation.participants;
          if (i >= participants.length) {
            participants.push(
              {
                ...user,
                pivot: {
                  contact_id: null,
                  team_id: this.state.team_id,
                  sensor_id: null,
                  contact: null,
                },
              },
            );
          } else {
            participants[i] = {
              ...user,
              pivot: {
                contact_id: null,
                team_id: this.state.team_id,
                sensor_id: null,
                contact: null,

              },
            };
          }
        }
      }
    });

    this.props.updateCurrentTicket(data);
  }

  renderArticlesNoteRows() {
    let ticket = { ...this.props.currentTicket };
    let currentNote = this.props.currentNote;

    if (currentNote && ticket.articles.length) {
      return ticket.articles.map((item, index) => {
        let canBeAdd = item.note_index === currentNote;
        let isActivity = item.parent && item.parent.is_activity === 1 && item.child?.is_res === 1;
        // check if is article is activity + has note index
        if (((item.parent && item.parent.is_activity === 1))) {
          canBeAdd = hasParticipantsNote(ticket, item.id, currentNote);
        }


        if (currentNote === item.note_index || canBeAdd)
          return (
            <Grid.Row key={'article' + index}>
              <Grid.Column width={1}>
                <Input
                  name="nb_occ_article"
                  className="badge-qte"
                  value={item.article_nb}
                  disabled={true}
                />
              </Grid.Column>
              <Grid.Column width={10} className="note-item-label">
                {item.name +
                ' ' +
                (item.info ? item.info + ' ' : '') +
                (item.duration
                  ? item.duration.duration.replace(':', 'H') + ' '
                  : '') +
                (item.default_participants_nb !== null
                  ? item.default_participants_nb + ' pers'
                  : '')
                }
              </Grid.Column>
              <Grid.Column className="note-item-label" width={2} textAlign="right">
                {isActivity ? (parseFloat(getActivityAmount(ticket, item.id, this.props.currentNote)) - parseFloat(getArticleDiscount(ticket, item.id, this.props.currentNote))).toFixed(2) :
                  (parseFloat(item.total_price * item.article_nb) - parseFloat(getArticleDiscount(ticket, item.id, this.props.currentNote))).toFixed(2)}
                {localStorage.getItem('currency')}
              </Grid.Column>
            </Grid.Row>
          );
        else {
          return null;
        }
      });
    }
  }

  cancelNote() {
    let ticket = { ...this.props.currentTicket };
    ticket.notes.pop();
    ticket.noteInProgress = false;
    ticket = returnArticleNoteToTicket(ticket, this.props.currentNote);
    this.props.updateCurrentTicket({ ...ticket });
    this.props.updateCurrentNote(null);
    infoToast('La division de la note est annulée');
    this.props.caisse_redirection('home', null, null);
  }


  // returnNoteToTicket = (ticket, note_index) => {
  //   if (!note_index) return;
  //   let articlesWithNote = ticket.articles.filter(it => it.note_index === note_index);
  //   let articlesWithoutNote = ticket.articles.filter(it => it.note_index !== note_index);

  //   let ids = articlesWithoutNote.filter(it => it.id);

  //   let addedArticles = [];
  //   //we add the articleWithNote to articles
  //   articlesWithNote.forEach(it => {
  //     let finished = false;
  //     articlesWithoutNote.forEach(itm => {
  //       if (!finished && itm.article_id.toString() === it.article_id.toString() && !itm.note_index && itm.status !== 'payed') {
  //         itm.article_nb += it.article_nb;
  //         itm.amount = itm.total_price * it.article_nb;
  //         finished = true;
  //       }
  //     });
  //     if (!finished) {
  //       addedArticles.push({
  //         ...it,
  //         note_index:null,
  //         status: '',
  //         payed_amount: 0,
  //       });
  //     }
  //   });

  //   ticket.articles = [...articlesWithoutNote, ...addedArticles];

  //   // clear payments without note_index
  //   ticket.payments.articles = ticket.payments.articles.filter(it => it.note_index !== note_index);
  //   ticket.payments.participants = ticket.payments.participants.filter(it => it.note_index !== note_index);

  //   ticket.payments.types = cumulPaymentTypesFromArticle(ticket);
  //   ticket.payed_amount = cumulPayedAmountFromPaymentTypes(ticket);
  //   // clear discounts without note_index
  //   let articleDiscount = ticket.discountTypes.articles.filter(itm => ids.includes(itm.article_id.toString()));
  //   articleDiscount = [...articleDiscount, ...cumulDiscountArticlesFromParticipant(ticket, this.props.article)];
  //   ticket.discountTypes.articles = articleDiscount;
  //   ticket.discountTypes.discounts = cumulDiscountsFromArticle(ticket);
  //   ticket.discount = cumulTotalDiscountFromDiscounts(ticket);
  // };

  renderDeleteNoteModal() {
    return (
      <ModalConfirm
        open={this.state.deleteNoteModalOpen}
        onClose={() => this.setState({ deleteNoteModalOpen: false })}
        title={'Suppression d\'une note'}
        message={<p>Vous êtes sur le point de supprimer une note</p>}
        ok={() => this.cancelNote()}
        cancel={() => {
          this.setState({ deleteNoteModalOpen: false });
        }}
        okText={'Supprimer'}
      />
    );
  }

  renderEditDropdown() {
    let ticket = { ...this.props.currentTicket };
    let currentNote = getCurrentNote(ticket, this.props.currentNote);
    let currentNoteIndex = getCurrentNoteIndex(ticket, this.props.currentNote);
    let participants = currentNote ? ticket.participants.filter(it => it.user_id) : [];
    // let participantsExits = ticket.participants.filter(it => it.user_id).map(it => it.user_id);
    if (participants.length)
      return (
        <div className={'division-edit-user'}>
          <Button icon={'close'}
                  circular={true}
                  negative
                  onClick={() => {
                    this.setState({ editNoteUser: false });
                  }}
          />
          <CustomDropdown
            placeholder={i18n.t('select_user')}
            noData={i18n.t('no_customer_found')}
            fetching={false}
            options={participants}
            keyword={'user_id'}
            filter={'firstName'}
            value={currentNote.user_id ? currentNote.user_id : ''}
            render={(item) => {
              return (
                <div className={'row-user'} key={'user-' + item.user_id}>
                <span>
                    {nullString(item.firstName).toLowerCase()} {nullString(item.lastName).toUpperCase()}
                </span>
                </div>
              );
            }}
            onChange={(v, e) => {
              let note = { ...currentNote };
              note.user_id = e ? e.user_id : null;
              note.name = e ? nullString(e.firstName).toLowerCase() + ' ' + nullString(e.lastName).toUpperCase() : '';
              note.user = e;
              let notes = [...ticket.notes];
              notes[currentNoteIndex] = note;
              ticket.notes = notes;
              this.props.updateCurrentTicket({
                ...ticket,
              });
              if (e)
                this.setState({ editNoteUser: false });
            }}
          />
        </div>
      );
    else {
      return (
        <div className={'division-edit-user'}>
          <Button icon={'close'}
                  circular={true}
                  negative
                  onClick={() => {
                    this.setState({ editNoteUser: false });
                  }}
          />
          <CustomDropdown
            placeholder={i18n.t('select_user')}
            url={'/clients/search'}
            get={'/users'}
            noData={i18n.t('no_customer_found')}
            param={'&type=client'}
            fetching={true}
            // condition={(it)=>!participantsExits.includes(it.id)}
            value={currentNote.user_id ? currentNote.user_id : ''}
            onChange={(v, e) => {
              let note = { ...currentNote };
              note.user_id = e ? e.id : null;
              note.name = e ? nullString(e.firstName).toLowerCase() + ' ' + nullString(e.lastName).toUpperCase() : '';
              note.user = e;
              let notes = [...ticket.notes];
              notes[currentNoteIndex] = note;
              ticket.notes = [...notes];
              if (note.user_index && e) {
                this.affectPlayer(e, note.user_index);
              }
              this.props.updateCurrentTicket({ ...ticket });
              if (e)
                this.setState({ editNoteUser: false });
            }}
            error={false}
            render={(item) => {
              return (
                <div className={'row-user'} key={'user-' + item.id}>
                  <span>
                    {nullString(item.firstName).toLowerCase()} {nullString(item.lastName).toUpperCase()}
                  </span>
                </div>
              );
            }}
          />
        </div>
      );
    }
  }

  render() {
    let ticket = { ...this.props.currentTicket };
    let currentNote = this.props.currentNote;
    let currentNoteTicket = getCurrentNote(ticket, this.props.currentNote);
    let currency = localStorage.getItem('currency');
    let participantsExits = ticket.participants.filter(it => it.note_index === currentNote);
    return (
      <div className="division">
        {this.state.deleteNoteModalOpen && this.renderDeleteNoteModal()}
        <h3 className="payment-title">
          {i18n.t('note_division')}
        </h3>
        {currentNoteTicket && currentNoteTicket.status === 0 &&
        <span className="cancel_note">
            <Button icon="close"
                    color="red"
                    circular
                    size="tiny"
                    onClick={() => {
                      this.setState({ deleteNoteModalOpen: true });
                    }}
            />
          </span>
        }

        <div className="division-content">
          {currentNote && (ticket && ticket.articles.filter(it => it.note_index === currentNote).length > 0 || participantsExits.length) ?
            <div className="division-list">
              <div className={'division-header'}>

                {this.state.editNoteUser ?
                  this.renderEditDropdown()
                  :
                  <div className={'division-edit-user'}>
                    <Button icon={'pencil'}
                            circular={true}
                            secondary
                            size="tiny"
                            onClick={() => {
                              this.setState({ editNoteUser: true });
                            }
                            }
                    />
                    <h3 className="division-list-user">
                      {nullString(currentNoteTicket.user?.firstName).toLowerCase()} {nullString(currentNoteTicket.user?.lastName).toUpperCase()}
                    </h3>
                  </div>
                }

                <Button
                  className={'division-add'}
                  icon="plus"
                  color="blue"
                  circular
                  size="tiny"
                  onClick={() => this.setState({
                    userModalOpen: true,
                  })}/>

              </div>

              <Grid>
                {this.renderHeader()}
                {this.renderArticlesNoteRows()}
                <Grid.Row>
                  <Grid.Column width={5}></Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    <span className="total-note">Total {(
                      parseFloat(getTotalAmountForNote(this.props.currentTicket, this.props.currentNote)) -
                      parseFloat(getNoteDiscount(this.props.currentTicket, this.props.currentNote))
                    ).toFixed(2)
                    }{currency}
                    </span>
                    {currentNote.status === 1 &&
                    <span className="payed-note">Reste à payer {(
                      (parseFloat(getTotalAmountForNote(ticket, this.props.currentNote)) - parseFloat(getPayedAmountNote(ticket, this.props.currentNote))) -
                      parseFloat(getNoteDiscount(ticket, this.props.currentNote))
                    ).toFixed(2)
                    }{currency}
                      </span>
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {currentNoteTicket && currentNoteTicket.articles_nb > 0 &&
              <div className="center-btn">
                <Button
                  loading={this.props.appLoading}
                  className={'ticket-menu-cash-off'}
                  onClick={() => {
                    this.props.article_to_pay([], 0, 0);
                    this.props.updateCurrentNote(null);
                    this.props.updateNoteInProgress(false);
                    this.props.caisse_redirection('home');
                  }}
                  content={i18n.t('close')}
                />
              </div>
              }
            </div>
            :
            <div className="division-home tools">
              {i18n.t('select_articles')}
              <Image className="orange-arrow"
                     src={require('@/assets/orange_arrow.svg')}
              />
            </div>
          }
        </div>

        <AddClientModal
          open={this.state.userModalOpen}
          onClose={() => {
            this.setState({ userModalOpen: false });
          }}
          onFinish={() => {
            let client = this.props.client;
            let currentNoteIndex = getCurrentNoteIndex(ticket, this.props.currentNote);
            let note = { ...currentNoteTicket };
            note.user_id = client ? client.id : null;
            note.name = client ? nullString(client.firstName).toLowerCase() + ' ' + nullString(client.lastName).toUpperCase() : '';
            note.user = client;
            let notes = [...ticket.notes];
            notes[currentNoteIndex] = note;
            ticket.notes = [...notes];
            if (note.user_index && client) {
              this.affectPlayer(client, note.user_index);
            }
            this.props.updateCurrentTicket({
              ...ticket,
            });
            this.setState({ userModalOpen: false });
          }}
        />
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => ({
  caisse_redirection: (value, status = null, type = null, date = null) =>
    dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: { value, status, type, date } }),
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
  updateCurrentNote: (id) => dispatch({ type: 'CURRENT_NOTE_REQUEST', payload: id }),
  storeTicket: (data) => dispatch({ type: 'STORE_TICKET_REQUEST', payload: data }),
  updateTicket: (data) => dispatch({ type: 'UPDATE_TICKET_REQUEST', payload: data }),
  article_to_pay: (article, amount, rest) =>
    dispatch({ type: 'ARTICLE_TO_PAY_REQUEST', payload: { article, amount, rest } }),
  updateNoteInProgress: (payload) => dispatch({ type: 'NOTE_IN_PROGRESS_RESPONSE', payload }),
});

const mapStateToProps = (state) => {
  return {
    currentTicket: state.caisse.currentTicket,
    currentNote: state.caisse.currentNote,
    client: state.user.client,
    user: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoteDivision));
