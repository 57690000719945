import React, { useEffect, useState } from 'react';
import { Dropdown, Icon, Pagination, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './CustomTable.css';
import i18n from '@/i18n';
import { IconPlaceholder, IdPlaceholder, PicturePlaceholder, TextPlaceholder } from '../Placeholder/Placeholder';
import Image from '../ImageComponent/Image';
import { nullStringNumber } from '../../helpers/formatting';


const CustomTable = (props) => {
  const [size, setSize] = useState(props.size ? props.size : 1);
  const [empty, setEmpty] = useState(false);
  const sizes = [
    { key: 4, text: 4, value: 4 },
    { key: 8, text: 8, value: 8 },
    { key: 10, text: 10, value: 10 },
    { key: 20, text: 20, value: 20 },
    { key: 30, text: 50, value: 50 },
  ];

  // resolve the change in the props children
  useEffect(() => {
    let isEmpty = props.children.props.children ? props.children.props.children.length === 0 : false;
    if (isEmpty !== empty)
      setEmpty(isEmpty);

  }, [props.children]);

  const sizeUpdate = (e, d) => {
    props.loadMore(props.page, d.value, props.search);
    setSize(d.value);
  };

  const go = (page, search) => {
    if (page > 0 && page < props.last + 1) props.loadMore(page, size, search);
  };

  const handlePaginationChange = (e, data, search) => {
    go(data.activePage, search);
  };


  return (
    <Table size="small" textAlign="left" compact padded unstackable>
      <Table.Header>
        <Table.Row>
          {props.header.map((it, i) => {
            return (
              <Table.HeaderCell key={`header-${i}`} className="tab-header bg-tab">
                {it}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>

      {props.loading ? (
        <Table.Body className={'loader-table-body'}>
          {Array(1).fill(0).map((it, index) => (
            <Table.Row key={'table-load-row-' + index} className={'loader-table-row'}>
              {props.header.map((it, i) => {
                if (i === 0)
                  return (<Table.Cell key={'table-load-' + i}> <IdPlaceholder/> </Table.Cell>);
                else if (i === 1)
                  return (<Table.Cell key={'table-load-' + i}> <PicturePlaceholder/> </Table.Cell>);
                else if (i < props.header.length - 1)
                  return (<Table.Cell key={'table-load-' + i}> <TextPlaceholder/> </Table.Cell>);
                else
                  return (<Table.Cell key={'table-load-' + i}> <IconPlaceholder/> </Table.Cell>);

              })}
            </Table.Row>))
          }

        </Table.Body>


      ) : (
        !empty ?
          props.children
          :
          (
            <Table.Body className={'empty-table-body'}>
              <Table.Row className={'empty-row'}>
                {props.header.map((it, i) => {
                  let middle = Math.floor(props.header.length / 2);
                  if (i === (middle - 1))
                    return <Table.Cell key={'table-empty-' + i} textAlign={'right'}> <Image
                      src={require('../../assets/empty.png')}/></Table.Cell>;
                  else if (i === middle)
                    return (<Table.Cell key={'table-empty-' + i} textAlign={'left'}><p>{i18n.t('empty_table')}</p>
                    </Table.Cell>);
                  else
                    return (<Table.Cell key={'table-empty-' + i}/>);

                })}
              </Table.Row>
            </Table.Body>)
      )}

      {props.footer && (
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={props.header.length + 1}>
              <div className="table-footer">
                {props.total > 0 &&
                <div className="table-footer-component table-footer-total">
                  <span>{i18n.t('total') + ': '} </span>
                  <span>{' ' + props.total}</span>
                </div>}
                <div className="table-footer-component">
                  <span>{i18n.t('pagination_line_per_page')}</span>
                  <Dropdown value={size} onChange={sizeUpdate} upward options={sizes}/>
                </div>
                <div className="table-footer-component">
                  <span>{`${props.page} sur ${props.last}`}</span>
                </div>

                {props.withPagination && props.total !== undefined && props.page !== 0 &&
                <div className="table-footer-component table-pagination">
                  <Pagination
                    activePage={nullStringNumber(props.page)}
                    boundaryRange={1}
                    onPageChange={(e, data) => handlePaginationChange(e, data, props.search)}
                    size='mini'
                    siblingRange={1}
                    totalPages={nullStringNumber(props.last)}
                    ellipsisItem={'...'}
                    firstItem={null}
                    lastItem={null}
                    prevItem={null}
                    nextItem={null}/>
                </div>
                }

                <div className="table-footer-component">
                  <span>
                    <Icon
                      name="angle left"
                      disabled={props.page - 1 <= 0}
                      onClick={() => {
                        go(props.page - 1, props.search);
                      }}
                    />
                  </span>
                  <span>
                    <Icon
                      name="angle right"
                      disabled={props.page + 1 > props.last}
                      onClick={() => go(props.page + 1, props.search)}
                    />
                  </span>
                </div>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
};

CustomTable.prototype = {
  header: PropTypes.array,
  page: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  footer: PropTypes.bool,
  loadMore: PropTypes.func,
  onChangeSize: PropTypes.func,
};

CustomTable.defaultProps = {
  withPagination: true,
  total: 0,
  search: {},
};

export default CustomTable;
