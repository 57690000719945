import React, { useCallback, useEffect, useRef, useState } from "react";
import { Feed, Icon, Loader } from "semantic-ui-react";
import "./NotificationPanel.css";
import OverlayComponent from "../OverlayComponent/OverlayComponent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "../../store/ducks/notification";
import { getLocalisation, nullString } from "../../helpers/formatting";
import moment from "moment";
import { imgUrl } from "../../helpers";
import history from "../../helpers/history";
import { Link } from "react-router-dom";

const convertDate = (date) => {
  let years = date.split(" ")[0];
  let times = date.split(" ")[1];

  // years = years.split('-').reverse().join('-');
  return years + "T" + times;
};

const FeedMessageItem = ({ notification }) => {
  try {
    let message = JSON.parse(notification.data);
    let index = message.data.body.indexOf("vous");
    let ms = message.data.body.slice(index !== -1 ? index : 0);
    let user = message.data.hasOwnProperty("asker")
      ? message.data.asker
      : message.data.hasOwnProperty("user")
      ? message.data.user
      : message.data.hasOwnProperty("invitee")
      ? message.data.invitee
      : message.data.hasOwnProperty("inviter")
      ? message.data.inviter
      : message.data.hasOwnProperty("owner")
      ? message.data.owner
      : message.data.notifiable;
    let reservation = message.data.reservation;

    let imageUrl = reservation
      ? reservation.user.avatar
      : user
      ? user.avatar
      : null;
    return (
      <Feed.Event
        className={"feed-item"}
        onClick={() => {
          if (reservation)
            history.replace(
              "/reservation?date=" + reservation.date + "&id=" + reservation.id
            );
        }}
      >
        <Feed.Label
          image={
            imageUrl
              ? imgUrl + "/users/" + imageUrl
              : require("../../assets/placeholder/user.jpg")
          }
        />
        <Feed.Content>
          <Feed.Summary>
            <a>
              {reservation
                ? nullString(reservation.user.firstName) +
                  " " +
                  nullString(reservation.user.lastName)
                : user
                ? nullString(user.firstName) + " " + nullString(user.lastName)
                : "--- ---"}
            </a>{" "}
            {nullString(message.data?.title)}
          </Feed.Summary>
          <Feed.Extra text className={"feed-text"}>
            {ms}
          </Feed.Extra>

          <Feed.Date>
            {notification.created_at &&
            moment(convertDate(notification.created_at))
              .locale(...getLocalisation())
              .fromNow()
              .includes("il y a")
              ? " " +
                moment(convertDate(notification.created_at))
                  .locale(...getLocalisation())
                  .fromNow()
              : " il y a " +
                moment(convertDate(notification.created_at))
                  .locale(...getLocalisation())
                  .fromNow()}
          </Feed.Date>
        </Feed.Content>

        {!notification.read_at && <span className={"ball"} />}
      </Feed.Event>
    );
  } catch (e) {
    return null;
  }
};

const NotificationPanel = (props) => {
  let [t] = useTranslation();
  const [loadData, setLoadData] = useState(false);
  const element = useRef(null);

  const dispatch = useDispatch();

  //state notification
  const notification = useSelector((state) => state.notification);
  const me = useSelector((state) => state.auth.user);

  // get data
  const getNotification = useCallback(
    (payload) => dispatch(Creators.getNotificationsRequest(payload)),
    [dispatch]
  );
  const getMoreNotification = useCallback(
    (payload) => dispatch(Creators.getMoreNotificationsRequest(payload)),
    [dispatch]
  );
  const mark = useCallback(
    (payload) => dispatch(Creators.markAsReadRequest(payload)),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      if (notification.totalUnread > 0) {
        mark({});
      }
    };
  }, []);

  //get notifications
  useEffect(() => {
    if (loadData && !notification.loading) {
      // check page
      if (notification.page === 0) {
        getNotification({
          notifiable_id: me.id,
          complex_id: localStorage.getItem("complex_id"),
          size: notification.size,
          page: 1,
        });
      } else {
        getMoreNotification({
          notifiable_id: me.id,
          complex_id: localStorage.getItem("complex_id"),
          size: notification.size,
          page: notification.page + 1,
        });
      }
    }
  }, [loadData]);

  useEffect(() => {
    const currentElement = element;
    const currentObserver = new IntersectionObserver(
      (entries) => {
        setLoadData(entries[0].isIntersecting || props.show);
      },
      {
        threshold: 0.9,
      }
    );

    if (currentElement.current) {
      currentObserver.observe(currentElement.current);
    }
    return () => {
      if (currentElement.current)
        currentObserver.unobserve(currentElement.current);
    };
  }, [element, props.show]);

  if (!props.show) return null;
  else
    return (
      <OverlayComponent
        onClose={() => {
          props.toggle();
        }}
      >
        <div className={"notification-container"}>
          <div className={"notification-title"}>
            <span>{t("notification")}</span>
            <span>
              <Icon
                name={"refresh"}
                size={"small"}
                color={"grey"}
                loading={notification.loading}
                onClick={() => {
                  getNotification({
                    notifiable_id: me.id,
                    complex_id: localStorage.getItem("complex_id"),
                    size: notification.size,
                    page: 1,
                  });
                }}
              />
            </span>
          </div>
          <div className={"notification-content"}>
            <Feed>
              {notification.notifications.length > 0 ? (
                notification.notifications.map((it, index) => {
                  return (
                    <FeedMessageItem
                      notification={it}
                      key={`notification-item-${index}`}
                    />
                  );
                })
              ) : notification.loading ? null : (
                <div className={"notification-empty"}>
                  <span>{t("no-notification-was-found")}</span>
                </div>
              )}
            </Feed>
            {notification.last > notification.page && (
              <div ref={element} className={"notification-loading"}>
                {notification.loading && (
                  <Loader active={true} size={"small"} />
                )}
              </div>
            )}
          </div>
        </div>
      </OverlayComponent>
    );
};

NotificationPanel.defaultProps = {
  toggle: () => {},
};

export default NotificationPanel;
