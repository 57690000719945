import { deleteMethod, getMethod, postMethod } from '@/helpers/index';

export default {
  ticketInfoService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/ticketInfos?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        '&complex_id=' +
        localStorage.getItem('complex_id'),
      );

      return result.data;
    },

    store: async (data) => {
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('address', data.address);
      formData.append('country', data.country);
      formData.append('city', data.city);
      formData.append('zip', data.zip);
      formData.append('tel', data.tel);
      formData.append('url', data.url);
      formData.append('siren', data.siren);
      formData.append('naf_code', data.naf_code);
      formData.append('description', data.description);
      formData.append('vat', data.vat);
      formData.append('img', data.img);
      formData.append('complex_id', localStorage.getItem('complex_id'));

      const result = await postMethod('/ticketInfos', formData);
      return result.data;
    },

    edit: async (id) => {
      const result = await getMethod('/ticketInfos/' + id);
      return result.data;
    },
    update: async (data) => {
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('address', data.address);
      formData.append('country', data.country);
      formData.append('city', data.city);
      formData.append('zip', data.zip);
      formData.append('tel', data.tel);
      formData.append('url', data.url);
      formData.append('siren', data.siren);
      formData.append('naf_code', data.naf_code);
      formData.append('description', data.description);
      formData.append('vat', data.vat);
      formData.append('img', data.img);
      formData.append('complex_id', localStorage.getItem('complex_id'));
      formData.append('_method', 'put');

      const result = await postMethod('/ticketInfos/' + data.id, formData);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/ticketInfos/' + id);
      return result.data;
    },
  },
};
