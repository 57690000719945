export function generateDataURI(content, type, byBlob) {
  switch (type) {
    case 'txt': {
      const blobType = 'text/plain;charset=ansi';

      if (byBlob) return URL.createObjectURL(new Blob([content], { type: blobType }));

      return `data:,${blobType}` + encodeURIComponent(content);
    }
    case 'css': {
      const blobType = 'text/css;charset=ansi';

      if (byBlob) return URL.createObjectURL(new Blob([content], { type: blobType }));

      return `data:,${blobType}` + encodeURIComponent(content);
    }
    case 'html': {
      const blobType = 'text/html;charset=ansi';

      if (byBlob) return URL.createObjectURL(new Blob([content], { type: blobType }));

      return `data:,${blobType}` + encodeURIComponent(content);
    }
    case 'json': {
      const blobType = 'text/json;charset=ansi';

      if (byBlob) return URL.createObjectURL(new Blob([content], { type: blobType }));

      return `data:,${blobType}` + encodeURIComponent(content);
    }
    case 'csv': {
      const blobType = 'text/csv;charset=ansi';

      if (byBlob) return URL.createObjectURL(new Blob([content], { type: blobType }));

      return `data:,${blobType}` + encodeURIComponent(content);
    }
    case 'xls': {
      const blobType = 'text/application/vnd.ms-excel;charset=ansi';

      if (byBlob) return URL.createObjectURL(new Blob([content], { type: blobType }));

      return `data:,${blobType}` + encodeURIComponent(content);
    }
    case 'xml': {
      const blobType = 'text/application/xml;charset=ansi';

      if (byBlob) return URL.createObjectURL(new Blob([content], { type: blobType }));

      return `data:,${blobType}` + encodeURIComponent(content);
    }
    default : {
      return '';
    }
  }
}

export function downloadFile(content, type, fileName = 'download', byBlob = true) {
  const dataURI = generateDataURI(content, type, byBlob);

  const anchor = document.createElement('a');
  anchor.href = dataURI;

  anchor.download = fileName;
  anchor.setAttribute('style', 'visibility:hidden');

  document.body.appendChild(anchor);
  anchor.dispatchEvent(
    new MouseEvent('click', {
      bubbles: false,
      cancelable: false,
      view: window,
    }),
  );
  document.body.removeChild(anchor);
}

export function exportFile(response, fileName ='download'){
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);
  
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}