import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateEN from '@/locales/en/translation.json';
import translateFR from '@/locales/fr/translation.json';
import translateAR from '@/locales/ar/translation.json';


const resources = {
  en: {
    translation: translateEN,
  },
  fr: {
    translation: translateFR,
    // menuTranslation:menuTranslateFR,
    //translation:crudTranslateFR,
  },
  ar: {
    translation: translateAR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: ['fr', 'en', 'ar'],
  lng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'fr',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
