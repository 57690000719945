import React from "react";
import CustomTable from "@/components/CustomTable/CustomTable";
import CustomInput from "@/components/CustomInput/CustomInput";
import "../ClientsPage/ClientsPage.css";
import "./SubscribersPage.css";
import MainLayout from "@/Layout/MainLayout/MainLayout";
import Header from "@/components/Header/Header";
import { Button, Dropdown, Icon, Input, Modal, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Creators } from "@/store/ducks/subscriber";
import { imgUrl, splash, url, throttle } from "../../helpers";
import {
  formatCodeCountry,
  formattingDate,
  nullString,
  nullStringForTable,
  phoneNumber
} from "../../helpers/formatting";
import { getCreditStatus } from "../../helpers/SubscriberFormatting";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import Image from "../../components/ImageComponent/Image";
import { index } from "@/services/subscriberService";
import { SIZE } from "../../utils/mock";
import history from "../../helpers/history";

class UserSubscriptionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkReload: false,
      modalOpen: false,
      modalCheckoutOpen: false,
      modalDeadlineOpen: false,
      item: null,
      date: "",
      label: "",
      payment_type_id: "",
      paymentTypeData: [],
      subscriber_id: "",
      credit: "",
      bonus: "",
      credit_bonus_id: "",
      deletedId: null,
      subscription_credit_id: "",
      payment_reload_type_id: null,
      search_start_date: "",
      search_user: "",
      search_subscription: "",
      errors: {
        payment_type_id: null,
        credit: null,
        date: null
      }
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onSearchHandle = this.onSearchHandle.bind(this);
  }

  componentDidMount() {
    this.props.index(1, SIZE, this.state);
  }

  handleOpen = (item) => {
    if (!this.props.session || this.props.session.status.toString() === "1") {
      this.toggleSessionModal(true);
      return;
    }
    let data = { item: item };
    if (item)
      data = {
        ...data,
        payment_type_id: new Array(item.deadlines.length).fill(null),
        checkNumber: new Array(item.deadlines.length).fill("")
      };
    item.subscription.subscription_type.is_reload.toString() === "1"
      ? this.setState({
        ...data,
        modalOpen: true
      })
      : this.setState({ ...data, modalDeadlineOpen: true });
  };

  handleDropdownChange = (e, d) => {
    if (e.target.name === "payment_type_id") {
      let payment_type_id = [...this.state.payment_type_id];
      let paymentTypeData = [...this.state.paymentTypeData];
      payment_type_id[e.target.index] = e.target.value;
      paymentTypeData[e.target.index] = d;
      this.setState({ payment_type_id, paymentTypeData });
    } else if (e.target.name === "payment_reload_type_id") {
      let paymentTypeData = [...this.state.paymentTypeData];
      paymentTypeData[e.target.index] = d;
      this.setState({ payment_reload_type_id: e.target.value, paymentTypeData });
    } else this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () =>
    this.setState({
      modalOpen: false,
      modalCheckoutOpen: false,
      modalDeadlineOpen: false,
      item: null,
      date: "",
      label: "",
      payment_type_id: [],
      paymentTypeData: [],
      checkNumber: [],
      payment_reload_type_id: null,
      subscriber_id: "",
      credit: "",
      subscription_credit_id: "",
      bonus: "",
      errors: {
        payment_type_id: null,
        credit: null,
        date: null
      }
    });

  handleDelete = (id) => {
    this.props.destroy(id);
  };

  checkReloadModal = () => {
    let { date, subscription_credit_id, payment_reload_type_id } = this.state;
    return date === "" || subscription_credit_id === "" || !payment_reload_type_id;
  };

  componentDidUpdate(prevProps) {
    if (this.props.updated !== prevProps.updated) {
      this.handleClose();
      this.props.index(this.props.page, this.props.size, this.state);
    }
  }

  onChangeHandle(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onSearchHandle(event) {
    event.preventDefault();
    this.props.index(1, this.props.size, this.state);
  }

  toggleSessionModal = (modalCheckoutOpen) => {
    this.setState({
      modalCheckoutOpen
    });
  };

  renderDeleteModal() {
    const { t } = this.props;
    return (
      <Modal open={this.state.modalOpen} onClose={this.handleClose} size="tiny">
        <Modal.Header>{t("confirm-delete")}</Modal.Header>
        <Modal.Content>
          <p>{t("msg-confirm-delete-client")}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("cancel")} onClick={this.handleClose}/>
          <Button
            content={t("confirm")}
            className="negative"
            loading={this.props.deleteLoading}
            onClick={() => this.handleDelete(this.state.deletedId)}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  renderNoCheckoutModal() {
    const { t } = this.props;
    return (
      <Modal open={this.state.modalCheckoutOpen} onClose={this.handleClose} size="tiny">
        <Modal.Header>{t("no-checkout-session")}</Modal.Header>
        <Modal.Content>
          <p>{t("msg-checkout-open-session")}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("cancel")} onClick={this.handleClose}/>
          <Button
            content={t("open-session")}
            className="negative"
            onClick={() => {
              history.push("/checkout");
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  renderModal() {
    const { t } = this.props;
    let { item, checkReload } = this.state;
    let currency = localStorage.getItem("currency");
    let creditOptions = [];
    if (item && item.subscription)
      creditOptions = item.subscription.credit_bonuses.map((it) => {
        return {
          text:
            "credit: " +
            it.credit +
            currency +
            (it.bonus ? " + " + (it.bonus ? "bonus:" + it.bonus + currency : "") : ""),
          key: it.id,
          value: it.id
        };
      });

    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="large"
        className={"modal-reload-container"}
      >
        <Modal.Header>
          <div className={"modal-reload-header"}>
            <Button className={"modal-close-btn"} onClick={this.handleClose}>
              X
            </Button>
            <div className={"modal-reload-complex"}>
              <img src={require("../../assets/park.svg")} alt={"complex"}/>

              <span className={"modal-reload-complex-name"}>
                <h3>{item && item.subscription.name}</h3>
                <h6>N° {item && item.id}</h6>
                <span className={"modal-reload-credit"}>
                  <span
                    className={"round-credit"}
                    style={{
                      backgroundColor:
                        item && getCreditStatus(item.subscription, item.total_balance, item.expiry_date)
                    }}
                  />
                  <h3>
                    {item && parseFloat(item.total_balance).toFixed(2)} {t("credits")}
                  </h3>
                </span>
              </span>
            </div>
            <div className={"modal-reload-user"}>
              <Image
                src={item && item.user && item.user.avatar ? url + "/uploads/users/" + item.user.avatar : splash}
                alt={"user"}
              />
              <span className={"modals-reload-name"}>
                {item && nullStringForTable(nullString(item.user?.firstName) + " " + nullString(item.user?.lastName))}
              </span>
            </div>
            <div className={"payment-date-container"}>
              <div className={"modal-reload-date"}>
                <span className={"modal-reload-slug"}>{t("start_date")}</span>
                <span className={"modal-reload-date"}>{item && item.start_date}</span>
              </div>

              <div className={"modal-reload-date"}>
                <span className={"modal-reload-slug"}>{t("expiry_date")}</span>
                <span
                  className={"modal-reload-date"}
                  style={{
                    color: item && getCreditStatus(item.subscription, item.total_balance, item.expiry_date)
                  }}
                >
                  {item && item.expiry_date}
                </span>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Content className={"modal-reload-main"}>
          <h3>{t("reload")}</h3>
          <div style={{ width: "80%" }}>
            <CustomInput
              type={"date"}
              className={"input-bg"}
              name={"date"}
              label={t("date")}
              value={this.state.date}
              error={checkReload && this.state.date === ""}
              onChange={this.onChangeHandle}
            />
            <CustomInput
              className="input-bg"
              name={"label"}
              label={t("informations")}
              mode="inline"
              value={this.state.label}
              onChange={this.onChangeHandle}
            />
            <CustomInput
              className="input-bg regulation-input"
              name={"payment_type_id"}
              error={
                checkReload &&
                (this.state.payment_reload_type_id === "" ||
                  this.state.payment_reload_type_id === null)
              }
              type="custom-dropdown"
              url={"/paymentTypes"}
              param={"&status=[2,1]"}
              render={(it) => (
                <>
                  <Image
                    className={it.icon ? "filter-img" : "no-filter-img"}
                    src={url + "/uploads/payments/" + it.icon}
                    type={"item"}
                  />
                  <span>{it.name}</span>
                </>
              )}
              placeholder={t("select-regulation")}
              label={t("regulation")}
              mode="inline"
              value={this.state.payment_reload_type_id}
              onChange={(e, d) =>
                this.handleDropdownChange(
                  {
                    target: {
                      name: "payment_reload_type_id",
                      value: e,
                      data: d,
                      index: 0
                    }
                  },
                  d
                )
              }
            />

            {this.state.paymentTypeData &&
            this.state.paymentTypeData.length > 0 &&
            this.state.paymentTypeData[0].slug === "cheque" && (
              <CustomInput
                className="input-bg"
                name={"check_number"}
                label={t("check_number")}
                mode="inline"
                value={this.state.checkNumber[0]}
                onChange={(e) => {
                  let checkNumber = [...this.state.checkNumber];
                  checkNumber[0] = e.target.value;
                  this.setState({
                    checkNumber
                  });
                }}
              />
            )}

            <CustomInput
              className="input-bg"
              name={"subscription_credit_id"}
              error={
                checkReload &&
                (this.state.subscription_credit_id === "" ||
                  this.state.subscription_credit_id === null)
              }
              type="custom-dropdown"
              fetching={false}
              options={creditOptions}
              placeholder={t("select-amount")}
              label={t("montant")}
              keyword={"value"}
              mode="inline"
              filter={"text"}
              render={(it) => (
                <>
                  <span>{it.text}</span>
                </>
              )}
              value={this.state.subscription_credit_id}
              onChange={(e, d) =>
                this.handleDropdownChange(
                  {
                    target: {
                      name: "subscription_credit_id",
                      value: e
                    }
                  },
                  d
                )
              }
            />

            <Button
              disabled={this.props.loadingDetail}
              loading={this.props.loadingDetail}
              className={"modal-btn-save"}
              onClick={throttle(() => {
                let valid = this.checkReloadModal();
                this.setState({ checkReload: valid });
                if (!valid) {
                  let selected = item.subscription.credit_bonuses.filter(
                    (it) => it.id.toString() === this.state.subscription_credit_id.toString()
                  );
                  let credit_bonus = { bonus: 0, credit: 0 };
                  if (selected.length > 0)
                    credit_bonus = {
                      credit: selected[0].credit,
                      bonus: selected[0].bonus,
                      id: selected[0].id
                    };

                  let data = {
                    date: formattingDate(this.state.date),
                    label: this.state.label,
                    credit: credit_bonus.credit,
                    bonus: credit_bonus.bonus,
                    credit_bonus_id: credit_bonus.id,
                    payment_type_id: this.state.payment_reload_type_id,
                    check_number:
                      this.state.checkNumber.length > 0 ? this.state.checkNumber[0] : "",
                    subscriber_id: item.id,
                    checkout_session_id: this.props.session.id
                  };

                  this.props.reload(data);
                }
              },2000)
              }
            >
              {t("save")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  renderDeadlinesModal() {
    const { t } = this.props;
    let { item } = this.state;
    let currency = localStorage.getItem("currency");
    let creditOptions = [];

    return (
      <Modal
        open={this.state.modalDeadlineOpen}
        onClose={this.handleClose}
        size="large"
        className={"modal-reload-container"}
      >
        <Modal.Header>
          <div className={"modal-reload-header"}>
            <Button className={"modal-close-btn"} onClick={this.handleClose}>
              X
            </Button>
            <div className={"modal-reload-complex"}>
              <img src={require("../../assets/park.svg")} alt={"complex"}/>

              <span className={"modal-reload-complex-name"}>
                <h3>{item && item.subscription.name}</h3>
                <h6>N° {item && item.id}</h6>
              </span>
            </div>
            <div className={"modal-reload-user"}>
              <Image
                src={item && item.user.avatar ? url + "/uploads/users/" + item.user.avatar : splash}
                alt={"user"}
              />
              <span className={"modals-reload-name"}>
                {item && nullStringForTable(item.user.firstName) + " " + nullString(item.user.lastName)}
              </span>
            </div>
            <div className={"payment-date-container"}>
              <div className={"modal-reload-date"}>
                <span className={"modal-reload-slug"}>{t("start_date")}</span>
                <span className={"modal-reload-date"}>{item && item.start_date}</span>
              </div>

              <div className={"modal-reload-date"}>
                <span className={"modal-reload-slug"}>{t("expiry_date")}</span>
                <span
                  className={"modal-reload-date"}
                  style={{
                    color: item && getCreditStatus(item.subscription, item.solde, item.expiry_date)
                  }}
                >
                  {item && item.expiry_date}
                </span>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Content>
          <h2 style={{ textAlign: "center" }}>{t("deadlines")}</h2>
          <div className={"table-container"}>
            <Table className={"no-overflow-x"}>
              <Table.Header>
                <Table.Row className={"payment-table-header"}>
                  <Table.HeaderCell/>
                  <Table.HeaderCell>
                    <div className={"payment-cell"}>{t("deadline")}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell ColSpan={2}>
                    <div className={"payment-cell"}>{t("regulation")}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className={"payment-cell"}>{t("amount")}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className={"payment-cell"}>{t("action")}</div>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {item &&
                item.deadlines &&
                item.deadlines.map((it, i) => {
                  return (
                    <Table.Row className={"payment-row"}>
                      <Table.Cell className={"payement-index"}>
                        <div>{i + 1}</div>
                      </Table.Cell>
                      <Table.Cell>
                        <div>{it.date}</div>
                      </Table.Cell>
                      <Table.Cell ColSpan={2} className={"regulation-modal-cell"}>
                        <div className={"regulation-modal-container"}>
                          <CustomDropdown
                            direction={
                              item.deadlines.length === 1
                                ? null
                                : item.deadlines.length - 1 === i
                                ? 1
                                : 0
                            }
                            name={"regulation-" + item.id + "-" + i}
                            className="regulation-modal-input"
                            //name={'payment_type_id'}
                            type="custom-dropdown"
                            url={"/paymentTypes"}
                            showLabel={false}
                            param={"&status=[2,1]"}
                            render={(it) => (
                              <>
                                <span>{it.name}</span>
                              </>
                            )}
                            placeholder={t("regulation")}
                            mode="inline"
                            onInitial={(data) => {
                              let paymentTypeData = [...this.state.paymentTypeData];
                              paymentTypeData[i] = data;
                              this.setState({ paymentTypeData });
                            }}
                            disabled={it.payed}
                            value={it.payed ? it.payment_type_id : this.state.payment_type_id[i]}
                            onChange={(e, d) =>
                              this.handleDropdownChange(
                                {
                                  target: {
                                    name: "payment_type_id",
                                    value: e,
                                    index: i
                                  }
                                },
                                d
                              )
                            }
                          />

                          {this.state.paymentTypeData &&
                          this.state.paymentTypeData.length > 0 &&
                          this.state.paymentTypeData[i] &&
                          this.state.paymentTypeData[i].slug === "cheque" && (
                            <CustomInput
                              placeholder={t("check_number")}
                              className="regulation-modal-input regulation-modal-check "
                              showLabel={false}
                              disabled={it.payed}
                              value={it.payed ? it.check_number : this.state.checkNumber[i]}
                              onChange={(e) => {
                                let checkNumber = [...this.state.checkNumber];
                                checkNumber[i] = e.target.value;
                                this.setState({ checkNumber });
                              }}
                              type={"text"}
                            />
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div>{(it.amount ? parseFloat(it.amount).toFixed(2) : 0) + currency}</div>
                      </Table.Cell>

                      <Table.Cell>
                        <Button
                          style={{
                            borderRadius: "20px",
                            backgroundColor: it.payed === 0 ? "#FF1818" : "#72F286",
                            color: "white",
                            fontSize: 12
                          }}
                          disabled={it.payed === 0 ? "" : "true"}
                          //loading='true'
                          onClick={() => {
                            let data = {
                              id: it.id,
                              payment_type_id: this.state.payment_type_id[i],
                              payed: 1,
                              check_number:
                                this.state.checkNumber && this.state.checkNumber[i]
                                  ? this.state.checkNumber[i]
                                  : "",
                              checkout_session_id: this.props.session.id
                            };
                            if (this.state.payment_type_id[i]) this.props.updateDeadline(data);
                          }}
                        >
                          {it.payed === 0 ? t("not_paid") : t("paid")}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    const { t, subscribers } = this.props;
    let currency = localStorage.getItem("currency");

    return (
      <MainLayout {...this.props.sidebarProps} type={this.props.type}>
        <Header title={t("list_abonnes")}/>
        <Link to={"/subscriber/create"} target={this.props.type ? "_blank " : "_self"}>
          <Button className={"round-btn"}>{t("add_abonne")}</Button>
        </Link>
        <div className="responsive-filter">
          <div className="filter-input">
            <CustomDropdown
              className={"search-custom-dropdown search-input"}
              name={"search_user"}
              placeholder={t("user")}
              url={"clients/search"}
              get={"/users"}
              param={"&type=client&search_subscribers=true"}
              value={this.state.search_user}
              onChange={(index, item) => this.setState({ search_user: index })}
              error={false}
              render={(it) => {
                return (
                  <>
                    <Image
                      className='avatar-cover'
                      src={imgUrl + "/users/" + it.avatar}
                      alt={""}
                      style={{ width: "15px", height: "15px" }}
                    />
                    <span>
                      {nullString(it.firstName).toLowerCase()} {nullString(it.lastName).toUpperCase()}
                    </span>
                  </>
                );
              }}
            />
            <CustomDropdown
              className={"search-custom-dropdown search-input"}
              name={"search_subscription_id"}
              placeholder={t("subscription")}
              url={"/subscriptions"}
              get={"/subscriptions"}
              param={""}
              value={this.state.search_subscription}
              onChange={(index, item) => this.setState({ search_subscription: index })}
              error={false}
              render={(it) => {
                return (
                  <>
                    <Image
                      className='avatar-cover'
                      type={"item"}
                      src={imgUrl + "/subscription/" + it.avatar}
                      alt={""}
                      style={{ width: "15px", height: "15px" }}
                    />
                    <span>
                      {it.name}
                    </span>
                  </>
                );
              }}
            />
            <Input
              type={"date"}
              value={this.state.search_start_date}
              name={"search_start_date"}
              className="search-it search-date search-input"
              onChange={this.onChangeHandle}
            />
          </div>
          <Button
            className="round-btn venu"
            secondary
            onClick={this.onSearchHandle}
            loading={this.props.loading}
          >
            {t("search")}
          </Button>
          <Button
            className="round-btn pas-venu"
            onClick={() => {
              let detail = {
                id: t("subscribers"),
                api: () => index({
                  page: 1,
                  search: {
                    search_start_date: this.state.search_start_date,
                    search_user: this.state.search_user
                  }
                }),
                formatting: (data) => data.map(it => {
                  return ({
                    [t("last_name")]: it.user.lastName,
                    [t("first_name")]: it.user.firstName,
                    [t("email")]: it.user.email,
                    [t("mobile")]: it.user.mobile ? formatCodeCountry(it.user.country_code) + phoneNumber(it.user.mobile) : "---",
                    [t("abonnement")]: it.subscription && it.subscription.subscription_type && it.subscription.subscription_type.name,
                    [t("start_date")]: it.start_date,
                    [t("expiry_date")]: it.expiry_date,
                    [t("solde")]: it.subscription && it.subscription.subscription_type && it.subscription.subscription_type.is_reload.toString() === "1" ? nullStringForTable(parseFloat(it.solde).toFixed(2)) + currency : ""
                  });
                })
              };
              let event = new CustomEvent("export", { detail });
              document.dispatchEvent(event);
            }}
          >
            {t("export")}
          </Button>

        </div>
        <div className={"table-container"}>
          <CustomTable
            loading={this.props.loading}
            loadMore={this.props.index}
            size={10}
            page={this.props.page}
            last={this.props.last}
            total={this.props.total}
            search={this.state}
            header={[
              "N°",
              "",
              t("last_name"),
              t("first_name"),
              t("email"),
              t("mobile"),
              t("abonnement"),
              t("start_date"),
              t("expiry_date"),
              t("solde"),
              "",
              ""
            ]}
            footer
          >
            <Table.Body>
              {subscribers.filter(it => it.user).map((it, i) => {
                return (
                  <Table.Row key={`client-${i}`}>
                    <Table.Cell className="td-text">#{i + this.props.from}</Table.Cell>
                    <Table.Cell className="td-text">
                      <span className={"td-img"}>
                        <Image
                          className='avatar-cover'
                          type={"user"}
                          src={it.user && it.user && it.user.avatar ? imgUrl + "/users/" + it.user.avatar : ""}
                          circular
                        />{" "}
                      </span>
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.user ? it.user.lastName : "").toUpperCase()}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.user ? it.user.firstName : "").toLowerCase()}
                    </Table.Cell>
                    <Table.Cell className="td-text">{nullStringForTable(it.user ? it.user.email : "")}</Table.Cell>
                    <Table.Cell className="td-text">
                      {it.user && it.user.mobile ? formatCodeCountry(it.user.country_code) + phoneNumber(it.user.mobile) : "---"}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(
                        it.subscription ? it.subscription.name : null
                      )}
                      {(it.subscription ? <br/> : "")}
                      {(it.subscription && it.subscription.subscription_type ? "(" + nullStringForTable(
                        it.subscription ? it.subscription.subscription_type.name : null
                      ) + ")" : "")}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.start_date)}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.expiry_date)}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {it.subscription && it.subscription.subscription_type && it.subscription.subscription_type.is_reload.toString() === "1" ? nullStringForTable(parseFloat(it.total_balance).toFixed(2)) + currency : "---"}
                    </Table.Cell>

                    <Table.Cell width={1}>
                      <span
                        className={"round-credit"}
                        style={{
                          backgroundColor: getCreditStatus(
                            it.subscription,
                            it.solde,
                            it.expiry_date
                          )
                        }}
                      />
                    </Table.Cell>

                    <Table.Cell className="td-text tools">
                      <Dropdown
                        direction={"left"}
                        className="icon"
                        icon={null}
                        floating
                        trigger={<Icon name={"ellipsis vertical"}/>}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon="folder"
                            text={t("folder")}
                            onClick={() => {
                              if (this.props.type === "caisse") {
                                this.props.caisseRedirection("subscriberDetails", it.id);
                              } else
                                this.props.history.push("/subscriber/" + it.id);
                            }}
                          />
                          {it.subscription && it.subscription?.subscription_type &&
                          ((it.deadlines && Object.keys(it.deadlines).length !== 0) ||
                            (it.subscription && it.subscription?.subscription_type && nullString(it.subscription.subscription_type.is_reload).toString() === "1")) ? (
                            <Dropdown.Item
                              icon={
                                it.subscription?.subscription_type?.is_reload.toString() === "1"
                                  ? "refresh"
                                  : "payment"
                              }
                              text={
                                it.subscription?.subscription_type?.is_reload.toString() === "1"
                                  ? t("reload")
                                  : t("pay")
                              }
                              onClick={() => this.handleOpen(it)}
                            />
                          ) : (
                            ""
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </CustomTable>
        </div>
        {this.state.modalOpen && this.renderModal()}
        {this.state.modalCheckoutOpen && this.renderNoCheckoutModal()}
        {this.state.modalDeadlineOpen && this.renderDeadlinesModal()}
      </MainLayout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  index: (page, size, search) => dispatch(Creators.indexRequestSubscriber({ page, size, search })),
  reload: (data) => dispatch(Creators.indexRequestSubscriberDetail({ ...data })),
  updateDeadline: (data) => dispatch(Creators.updateRequestSubscriberDeadline(data)),
  destroy: (id) => dispatch(Creators.destoryRequestUser(id)),
  caisseRedirection: (value, status = null, type = null, date = null) =>
    dispatch({ type: "CAISSE_REDIRECTION_REQUEST", payload: { value, status, type, date } })
});

const mapStateToProps = (state) => {
  return {
    subscribers: state.subscriber.subscribers,
    session: state.session.session,
    deadline: state.subscriber.deadline,
    page: state.subscriber.page,
    last: state.subscriber.last,
    total: state.subscriber.total,
    size: state.subscriber.size,
    from: state.subscriber.from,
    loading: state.subscriber.loading,
    loadingDetail: state.subscriber.loadingDetail,
    updated: state.subscriber.updated,
    updatedDeadline: state.subscriber.updatedDeadline,
    deleteLoading: state.subscriber.deleteLoading,
    successDestroy: state.subscriber.deleteSuccess
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSubscriptionPage);
