import React from 'react';
import { Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import i18n from '../i18n';

export const message = (message, icon, type) => {
  const customMessage = (
    <p>
      <Icon name={icon}/>
      {i18n.t(message)}
    </p>
  );
  toast(customMessage, type);
};

export const loadingToast = (msg, options = {}) => {
  message(msg, 'download', {
    ...options, type: 'info', hideProgressBar: true, progress: undefined,
  });
};


export const errorToast = (msg, options = {}) => {
  message(msg, 'times circle', { ...options, type: 'error' });
};

export const infoToast = (msg, options = {}) => {
  message(msg, 'info circle', { ...options, type: 'info' });
};

export const validToast = (msg, options = {}) => {
  message(msg, 'check circle', { ...options, type: 'success' });
};

export const warningToast = (msg, options = {}) => {
  message(msg, 'info circle', { ...options, type: 'warning' });
};
