import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import childService from '../../services/childService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingChild: ['data'],

  indexRequestChild: ['payload'],
  indexResponseChild: ['data'],

  storeRequestChild: ['payload'],
  storeResponseChild: ['data'],

  editRequestChild: ['id'],
  editResponseChild: ['data'],

  updateRequestChild: ['payload'],
  updateResponseChild: ['data'],

  destroyRequestChild: ['id'],
  destroyResponseChild: ['data'],
});

// Initial state
const INITIAL_STATE = {
  children: [],
  child: null,
  loading: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingChild({ loading: true }));
    const data = yield call(childService.childService.index, action.payload);
    yield put(Creators.indexResponseChild(data));
    yield put(Creators.loadingChild({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingChild({ loading: true }));
    const data = yield call(childService.childService.store, action.payload);
    yield put(Creators.storeResponseChild(data));
    yield put(Creators.loadingChild({ loading: false }));
    yield history.push('/children');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingChild({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingChild({ editLoading: true }));
    const data = yield call(childService.childService.edit, action.payload);
    yield put(Creators.editResponseChild(data));
    yield put(Creators.loadingChild({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingChild({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingChild({ loading: true }));
    const data = yield call(childService.childService.update, action.payload);
    yield put(Creators.updateResponseChild(data));
    yield put(Creators.loadingChild({ loading: false }));
    yield history.push('/children');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(Creators.loadingChild({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingChild({ deleteLoading: true }));
    const data = yield call(childService.childService.destroy, action.payload);
    yield put(Creators.destroyResponseChild(data));
    yield put(Creators.loadingChild({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CHILD, index);
  yield takeLatest(Types.STORE_REQUEST_CHILD, store);
  yield takeLatest(Types.EDIT_REQUEST_CHILD, edit);
  yield takeLatest(Types.UPDATE_REQUEST_CHILD, update);
  yield takeLatest(Types.DESTROY_REQUEST_CHILD, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    children: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    child: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const loading_child = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const childReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_CHILD]: index_response,
  [Types.STORE_RESPONSE_CHILD]: store_response,
  [Types.EDIT_RESPONSE_CHILD]: edit_response,
  [Types.UPDATE_RESPONSE_CHILD]: update_response,
  [Types.DESTROY_REQUEST_CHILD]: destroy_request,
  [Types.DESTROY_RESPONSE_CHILD]: destroy_response,
  [Types.LOADING_CHILD]: loading_child,
});
