import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  roleService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/roles?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : ''),
      );
      return result.data;
    },
    store: async (data) => {
      const result = await postMethod('/roles', data);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/roles/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/roles/' + data.id, data);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/roles/' + id);
      return result.data;
    },
  },
};
