import React from 'react';

class OverlayComponent extends React.Component {
  ref = React.createRef();

  handleClick = (e) => {
    if (this.ref.current.contains(e.target)) {
      // this.props.handleClick(e);
      return;
    } else {
      this.props.onClose();
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  render() {
    return <div  className={this.props.className} ref={this.ref}>{this.props.children}</div>;
  }
}


OverlayComponent.defaultProps={
  className:"",
};

export default OverlayComponent;
