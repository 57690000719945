import { getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  complexTimesService: {
    store: async (data) => {
      const result = await postMethod('/complexTimes', data);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/complexTimes/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/complexTimes/' + data.id, data);
      return result.data;
    },
  },
};
