import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import configurationService from '@/services/configurationService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Configuration types and creators
export const { Types, Creators } = createActions({
  loadingConfiguration: ['payload'],

  indexRequestConfiguration: ['payload'],
  indexResponseConfiguration: ['payload'],

  storeRequestConfiguration: ['payload'],
  storeResponseConfiguration: ['payload'],

  updateRequestConfiguration: ['payload'],
  updateResponseConfiguration: ['payload'],

  editRequestConfiguration: ['payload'],
  editResponseConfiguration: ['payload'],

  destroyRequestConfiguration: ['payload'],
  destroyResponseConfiguration: ['payload'],
});

// Initial state
const INITIAL_STATE = {
  configurations: [],
  configuration: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingConfiguration({ loading: true }));
    const data = yield call(configurationService.configurationService.index, action.payload);
    yield put(Creators.indexResponseConfiguration(data));
    yield put(Creators.loadingConfiguration({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingConfiguration({ loading: false }));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingConfiguration({ editLoading: true }));
    const data = yield call(configurationService.configurationService.edit, action.payload);
    yield put(Creators.editResponseConfiguration(data));
    yield put(Creators.loadingConfiguration({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingConfiguration({ editLoading: false }));
    history.push('/error-404');
  }
}


//store
function* store(action) {
  try {
    yield put(Creators.loadingConfiguration({ loading: true, errors: null }));
    const data = yield call(configurationService.configurationService.store, action.payload);
    validToast(i18n.t('successful_store'));
    history.push('/checkout/configuration/');
    yield put(Creators.loadingConfiguration({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingConfiguration({ loading: false }));
    yield put(Creators.loadingConfiguration({ errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
    //history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingConfiguration({ loading: true, errors: null }));
    const data = yield call(configurationService.configurationService.update, action.payload);
    validToast(i18n.t('successful_update'));
    history.push('/checkout/configuration/');
    yield put(Creators.loadingConfiguration({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingConfiguration({ loading: false }));
    yield put(Creators.loadingConfiguration({ errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
    //history.push('/error-404');
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingConfiguration({ deleteLoading: true }));
    const data = yield call(configurationService.configurationService.destroy, action.payload);
    yield put(Creators.destroyResponseConfiguration(data));
    yield put(Creators.loadingConfiguration({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CONFIGURATION, index);
  yield takeLatest(Types.EDIT_REQUEST_CONFIGURATION, edit);
  yield takeLatest(Types.STORE_REQUEST_CONFIGURATION, store);
  yield takeLatest(Types.UPDATE_REQUEST_CONFIGURATION, update);
  yield takeLatest(Types.DESTROY_REQUEST_CONFIGURATION, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    configurations: [...action.payload.data],
    last: action.payload.last_page,
    page: action.payload.current_page,
    total: action.payload.total,
    size: action.payload.per_page,
    from: action.payload.from,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  console.log(action.payload.data);
  return {
    ...state,
    configuration: action.payload.data,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding_configuration = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const configurationReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_CONFIGURATION]: index_response,
  [Types.EDIT_RESPONSE_CONFIGURATION]: edit_response,
  [Types.DESTROY_REQUEST_CONFIGURATION]: destroy_request,
  [Types.DESTROY_RESPONSE_CONFIGURATION]: destroy_response,
  [Types.LOADING_CONFIGURATION]: laoding_configuration,
});
