import { getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  priceManagementsService: {
    index: async (search) => {
      const result = await getMethod(
        '/prices?complex_id=' +
        localStorage.getItem('complex_id') +
        (search ? '&search_day=' + search : ''),
      );
      return result.data;
    },
    store: async (data) => {
      const result = await postMethod('/prices', data);
      return result.data;
    },
    edit: async (id, search) => {
      const result = await getMethod('/prices/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/prices/' + data.id, data);
      return result.data;
    },
    reservationAmount: async (data) => {
      const result = await postMethod('/reservation/amount', data);
      return result.data;
    },
  },
};
