import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    e.preventDefault();
    const show = this.state.show;
    this.setState({ show: !show });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.width !== this.props.width) {
      const show = this.props.width === 100;
      this.setState({ show: show });
    }
  }

  hasPermission = (module, slug) => {
    for (let i = 0; i < module.permissions.length; i++)
      if (module.permissions[i].slug === slug) return true;
    return null;
  };

  render() {
    const { item,t } = this.props;
    const dropStyle = this.state.show ? 'sous-menu' : 'sous-menu display-none';
    const iconName = this.state.show ? 'angle up' : 'angle down';
    const spanClass = this.props.end ? 'item item-end' : 'item';
    return (
      <>
        {item.sousMenu && item.sousMenu.length === 0 ? (
          <span className={spanClass}>
            <NavLink to={item.path} target={item.target} activeClassName={'active'} exact={true}>
              <Icon name={item.icon.name} color={item.icon.color}/>
              <span>{item.name}</span>
            </NavLink>
          </span>
        ) : (
          <div className="has-menu">
            <span className={spanClass}>
              <NavLink
                to={item.path === undefined ? '/' : item.path}
                activeClassName={''}
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle(e);
                }}
              >
                {!item.icon ?
                  <Icon className={'nav-default-icon'} name={'circle'} color={'black'} size={'mini'}/>
                  :
                  <Icon name={item.icon.name} color={item.icon.color}/>
                }
                <span>
                  {item.path === '/caisse' ? t('begin') : item.name}
                  <Icon name={iconName} color="black" className="last"/>
                </span>
              </NavLink>
            </span>
            <div className={dropStyle}>
              {item.sousMenu.map((it, i) => {
                if (it.sidebar && this.hasPermission(this.props.module, it.permission)) {
                  if (it.sousMenu && it.sousMenu.length)
                    return (
                      <MenuItem
                        module={this.props.module}
                        width={this.props.width}
                        item={it}
                        show={false}
                        key={`menu-item-sous-${i}`}
                        end={i === it.sousMenu.length - 1}
                      />
                    );
                  else
                    return (
                      <span className="item" key={`sous-menu-${i}`}>
                      <NavLink
                        to={it.path}
                        target={item.target ? item.target : ''}
                        activeClassName={'active'}
                      >
                        <Icon className={'nav-default-icon'} name={'circle'} color={it.icon.color} size={'mini'}/>
                        <span>
                          {it.path === '/caisse' ? t('begin') : it.name}
                        </span>
                      </NavLink>
                    </span>
                    );
                }

                return null;
              })}
            </div>
          </div>
        )}
      </>
    );
  }
}

MenuItem.defaultProps = {
  target: '',
  show: true,
};

MenuItem.propTypes = {
  // item: PropTypes.array
};

export default withTranslation()(MenuItem);
