import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import actionService from '@/services/actionService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingAction: ['payload'],

  indexRequestAction: ['payload'],
  indexResponseAction: ['data'],

  editRequestAction: ['id'],
  editResponseAction: ['data'],

  destroyRequestAction: ['id'],
  destroyResponseAction: ['data'],
});

// Initial state
const INITIAL_STATE = {
  actions: [],
  action: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingAction({ loading: true }));
    const data = yield call(actionService.actionService.index, action.payload);
    yield put(Creators.indexResponseAction(data));
    yield put(Creators.loadingAction({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingAction({ loading: false }));
    //
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingAction({ editLoading: true }));
    const data = yield call(actionService.actionService.edit, action.payload);
    yield put(Creators.editResponseAction(data));
    yield put(Creators.loadingAction({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingAction({ editLoading: false }));
    history.push('/error-404');
    //
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingAction({ deleteLoading: true }));
    const data = yield call(actionService.actionService.destroy, action.payload);
    yield put(Creators.destroyResponseAction(data));
    yield put(Creators.loadingAction({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
    //
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_ACTION, index);
  yield takeLatest(Types.EDIT_REQUEST_ACTION, edit);
  yield takeLatest(Types.DESTROY_REQUEST_ACTION, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    actions: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    action: action.data.data,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding_action = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const actionReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_ACTION]: index_response,
  [Types.EDIT_RESPONSE_ACTION]: edit_response,
  [Types.DESTROY_REQUEST_ACTION]: destroy_request,
  [Types.DESTROY_RESPONSE_ACTION]: destroy_response,
  [Types.LOADING_ACTION]: laoding_action,
});
