import { deleteMethod, getMethod, postMethod, putMethod } from "../helpers";
import { reservationRequest } from "../helpers/reservationFormatting";

//index
export const index = async ({ page = "", size, terrains = [], search }) => {
  let result = await getMethod(
    "/reservations?&page=" +
      page +
      (size ? "&size=" + size : "") +
      (terrains && terrains.length > 0
        ? "&terrains=[" + terrains.join(",") + "]"
        : "") +
      (search && search.search_date
        ? "&search_date=" + search.search_date
        : "") +
      (search && search.search_hour
        ? "&search_hour=" + search.search_hour
        : "") +
      (search && search.search_user
        ? "&search_user=" + search.search_user
        : "") +
      (search && search.search_activity
        ? "&search_activity=" + search.search_activity
        : "") +
      (search && search.is_leisure
        ? "&is_leisure=" + search.is_leisure
        : "&is_leisure=0") +
      (search && search.search_terrain
        ? "&search_terrain=" + search.search_terrain
        : "") +
      (search && search.show_next ? "&show_next=" + search.show_next : "") +
      "&complex_id=" +
      localStorage.getItem("complex_id")
  );
  return result.data;
};

//store
export const store = (data) => {
  // clearing data
  let formattedData = reservationRequest(data);
  if (!formattedData.reservation.is_leisure)
    return postMethod("/reservations", formattedData);
  else return postMethod("/leisureReservation/storeUpdate", formattedData);
};

//update
export const update = (data) => {
  let formattedData = reservationRequest(data);
  if (!formattedData.reservation.is_leisure)
    return putMethod(
      "/reservations/" + data.information.reservation_id,
      formattedData
    );
  else return postMethod("/leisureReservation/storeUpdate", formattedData);
};

//edit
export const edit = (data) => {
  return getMethod("/reservations/" + data);
};

//deleted
export const destroy = ({ id, parent_id, all }) => {
  return deleteMethod(
    "/reservations/" +
      id +
      "?" +
      (all ? "all=" + all : "") +
      (parent_id ? "&parent_id=" + parent_id : "")
  );
};

//get reservation history per user
export const reservationHistory = async ({
  page,
  size = "",
  user_id,
  terrains = [],
  is_leisure = 0,
}) => {
  const result = await getMethod(
    "/reservations/history/" +
      user_id +
      "?page=" +
      page +
      "&size=" +
      size +
      "&is_leisure=" +
      is_leisure +
      (terrains && terrains.length > 0
        ? "&terrains=[" + terrains.join(",") + "]"
        : "") +
      "&complex_id=" +
      localStorage.getItem("complex_id")
  );
  return result.data;
};

//get list deleted reservation
export const deletedReservation = async ({
  page,
  size,
  terrains = [],
  is_leisure = 0,
}) => {
  const result = await getMethod(
    "/reservations/deleted/" +
      localStorage.getItem("complex_id") +
      "?page=" +
      page +
      "&size=" +
      size +
      "&is_leisure=" +
      is_leisure +
      (terrains && terrains.length > 0
        ? "&terrains=[" + terrains.join(",") + "]"
        : "")
  );
  return result.data;
};

//retrieve deleted reservation
export const retrieveReservation = async (reservation_id) => {
  const result = await putMethod("/reservations/retrieve/" + reservation_id);
  return result.data;
};

export const reservationHour = async (data) => {
  return await postMethod("/hourTypes/reservation", data);
};

export const getArticleReservation = async (data) => {
  return await getMethod("/reservations/article/" + data.id);
};

export const reservationInformation = async (data) => {
  return await getMethod(
    "/reservation/" + data.user + "/complex/" + data.complex_id
  );
};

//get reservation available hours
export const reservationAvailableHours = async (data) => {
  const result = await postMethod("/availableTerrainHours", data);
  return result.data;
};
