import React from 'react';
import HourMenuItem from './HourMenuItem';
import '../CustomCaisse.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class HourMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //this.props.getData();
  }

  renderHourType() {
    if (this.props.menusData) {
      let selectedFamily = this.props.menusData.filter(itm=> itm.id === this.props.selectFamily);
      if(selectedFamily){
        let hourMenu = selectedFamily[0].children.map(
          (item, index) => {
            if (item.id == this.props.selectSubFamily) {
              let hourType = item.hourTypes.map((it, i) => {
                return <HourMenuItem switchReservatio={this.props.switchReservation} key={i} id={it.id} name={it.acronym}/>;
              });
              return hourType;
            }
          },
        );
        return hourMenu;
      }
    }
  }

  render() {
    return <div className={'caisse-main-menu-third'}>{this.renderHourType()}</div>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  //getData: () => dispatch({type: "GET_DATA_REQUEST"}),
});

const mapStateToProps = (state) => {
  return {
    menusData: state.caisse.menusData,
    selectFamily: state.caisse.selectFamily,
    selectSubFamily: state.caisse.selectSubFamily,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HourMenu));
