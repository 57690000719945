import { getMethod, postMethod } from '../helpers';

export const getComplexBySlug = (data) => {
  return getMethod('/checkComplex/' + data.complex);
};

export const getSlots = (data) => {
  return postMethod('/bookings/slots/' + data.complex, {
    activity_id: data.activity,
    date: data.date,
    duration_id: data.duration,
    complex: data.complex
  });
};

export const bookingHourType = async (data) => {
  return await postMethod('/hourTypes/reservation', data, {});
};

export const getActivities = (data) => {
  return getMethod('/activities?is_booking=1' + (data.complex ? `&complex_id=${data.complex}&` : ''));
};

export const loginWithReservation = (data) => {
  return postMethod('/bookings/login', data);
};

export const registerUser = (data) => {
  return postMethod('/bookings/register', data);
};

export const confirmReservation = (data) => {
  return postMethod('/bookings/confirm', data);
};
