import { reversePhoneNumber } from './formatting';

export const getFormData = (obj, form, namespace) => {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursively.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        getFormData(obj[property], fd, formKey);
      } else if (typeof obj[property] === 'string') {
        if (formKey === 'mobile' || formKey === 'phone' || formKey === 'tel')
          fd.append(formKey, (!obj[property] && obj[property] !== 0) ? '' : reversePhoneNumber(obj[property]));
        else
          fd.append(formKey, (!obj[property] && obj[property] !== 0) ? '' : obj[property].trim());
      } else {
        // if it's a string or a File object
        if (formKey === 'mobile' || formKey === 'phone' || formKey === 'tel')
          fd.append(formKey, (!obj[property] && obj[property] !== 0) ? '' : reversePhoneNumber(obj[property]));
        else
          fd.append(formKey, (!obj[property] && obj[property] !== 0) ? '' : obj[property]);
      }
    }
  }

  return fd;
};

export const showFormData = (fd) => {
  for (let pair of fd.entries()) {
    //
  }
};
