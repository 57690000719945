import React from 'react';
import { withRouter } from 'react-router-dom';
import {Icon ,Button ,Image ,Popup} from 'semantic-ui-react';
import { PrintPopup, creditPrint } from '../../../helpers/print';
import { nullString } from '../../../helpers/formatting';
import moment from 'moment';
import i18n from '@/i18n';
import localization from 'moment/locale/fr';

class CreditListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    let color = 'rgba(11, 108, 255, 0.65)';
    return (
      <div className={'credits-list-item'} style={{ color: color }}>
        <div className="ticket-family" style={{ backgroundColor: color }}>
        </div>
        <div className="ticket-information" style={{  position : 'relative',borderRight: '1px dotted ' + color }}>
            <div><b>N° {this.props.credit.credit_number}</b>
            <input id={this.props.credit.credit_number} defaultValue={this.props.credit.credit_number ? this.props.credit.credit_number : ''}
              style={{height : '0px' , zIndex : -1 , position : 'absolute'}} />
            <Popup
              content='Copié!'
              on='click'
              pinned
              trigger={
                <Icon name="copy" onClick={()=>{
                  let textToBeCopied = document.getElementById(this.props.credit.credit_number);
                  textToBeCopied.select();
                  document.execCommand('copy');
                }}/>
              }
            />
            </div>
            <span>{i18n.t('createdAt')} {moment(this.props.credit.created_at).locale(i18n.language, localization).format('DD-MM-YYYY à HH:mm')}</span>
            {this.props.credit.owner &&
              <span>
                {i18n.t('createdBy')} {this.props.credit.owner.firstName + ' ' + nullString(this.props.credit.owner.lastName).toUpperCase()}
              </span>
            }
        </div>
        <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
          <b>Montant</b>
          <span>
            {parseFloat(this.props.credit.amount).toFixed(2)}{localStorage.getItem('currency')}
          </span>
        </div>
        <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
          <b>Montant utilisé</b>
          <span>
            {parseFloat(this.props.credit.payed_amount).toFixed(2)}{localStorage.getItem('currency')}
          </span>
        </div>
        <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
          <b>Montant restant</b>
          <span>
            {(parseFloat(this.props.credit.amount) > parseFloat(this.props.credit.payed_amount) ?
              (parseFloat(this.props.credit.amount) - parseFloat(this.props.credit.payed_amount)).toFixed(2) : 0 )}
              {localStorage.getItem('currency')}
          </span>
        </div>
        <div className="ticket-buttons">
          <Button
            content={'Imprimer'}
            className="ticket-btn-edit"
            onClick={async () => {
              let receipt = await creditPrint(this.props.credit);
              PrintPopup(receipt);
            }}
          />
        </div>
      </div>
    );
  }
}


export default withRouter(CreditListItem);
