import React from "react";
import { Grid, Icon } from 'semantic-ui-react';
import "./CustomTextInput.css";


const CustomTextInput = (props)=>{

  return (
    <div className="form">
        <Grid>
          <Grid.Row>
            {props.showLabel  &&
            <Grid.Column width={4} verticalAlign={"middle"}>
              <label className="form-label">{props.label}</label>
            </Grid.Column>}
            <Grid.Column width={12} className={"custom-text-input-container"}>
              <div className={props.className}>
                <p className={"custom-p"}>{props.value !== "" ? props.value : props.placeholder  }</p>

              </div>
              {props.type==="dropdown" && <Icon name={"caret down"} className={"custom-text-input-dropdown"}/>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
    </div>
  );


};


CustomTextInput.defaultProps={
  label:"",
  className:"",
  showLabel:true,
  value:"",
  type:"",
  placeholder:""
};

export default CustomTextInput;