import { reversePhoneNumber } from './formatting';
import moment from 'moment';

export const planningRequest = (request) => {
  let data = [];
  request.matches.map((match, index) => {
    //get user_id
    let user = null;

    let participants_team_a = match.participants.filter(itm => itm.team_id === match.team_a_id);
    let participants_team_b = match.participants.filter(itm => itm.team_id === match.team_b_id);
    if (participants_team_a.length > 0) {
      participants_team_a.map((it, i) => {
        if (it.capitaine === 1) {
          user = it;
        }
      });
      if (!user) {
        user = participants_team_a[0];
      }
    } else if (participants_team_b.length > 0) {
      participants_team_b.map((it, i) => {
        if (it.capitaine === 1) {
          user = it;
        }
      });
      if (!user) {
        user = participants_team_b[0];
      }
    }
    //get competition (match)
    let competition = {
      id: match.id,
      competition_id: request.competition_id,
      competition_day: match.day,
      competition_day_date:  match.date,
      team_a_id: match.team_a_id,
      team_b_id: match.team_b_id,
      participants_nb: match.participants.length,
      missing_participants_nb : 0,
      price_per_participant : 0,
      auto_referee: match.auto_referee ? 1 : 0,
    };
    let formattedData = {
      complex_id: localStorage.getItem('complex_id'),
      user: {
        user_id: user.user_id,
        contact_id: user.contact_id ? user.contact_id : '',
        [user.contact_id ? 'first_name' : 'firstName']: user.firstName,
        [user.contact_id ? 'last_name' : 'lastName']: user.lastName,
        birthdate: user.birthdate,
        email: user.email,
        mobile: reversePhoneNumber(user.mobile),
        company: user.company,
        avatar: user.avatar,
      },
      reservation: {
        id: match.reservation_id,
        owner_id: request.owner_id,
        activity_id: request.activity_id,
        terrain_id: match.terrain_id ? match.terrain_id : match.reservation.terrain_id,
        competition: competition,
        duration_id: match.duration_id ? match.duration_id : match.reservation.duration_id,
        is_ticket: 0,
        is_advance: 0,
        is_leisure: 0,
        cdn_video: 1,
        recurrence: 0,
        article_id: null,
        date: match.date,
        start_hour: match.start_hour ? match.start_hour : match.reservation.start_hour,
        end_hour: match.end_hour ? match.end_hour : match.reservation.end_hour,
        infos: null,
        hour_type_id: null,
        price: null,
        conclusion: 'A valider',
        participants: match.participants,
      },
    };

    data.push(formattedData);
  });
  return data;
};

export const matchReportRequest = (request) => {
  let participants = request.participants.team_a.concat(request.participants.team_b);
  participants = participants.map((item, index) => {
    return {
      ...item,
      match_men: item.match_men ? 1 : 0,
      cards: item.cards.length ? item.cards.filter(itm => itm.red_card || itm.yellow_card) : [],
    };
  });
  let scorers = request.scorers.team_a.concat(request.scorers.team_b);

  let data = {
    complex_id: localStorage.getItem('complex_id'),
    reservation: {
      id: request.reservation_id,
      match: {
        id: request.id,
        but_a: request.but_a ? request.but_a : 0,
        but_b: request.but_b ? request.but_b : 0,
      },
      participants: participants,
    },
    scorers: scorers,

  };

  return data;
};


export const convertParticipants = (participants) => {
  return participants.map(it => {
    let part = {
      user_id: it.id,
      firstName: it.firstName,
      lastName: it.lastName,
      email: it.email,
      mobile: it.mobile,
      sensor_id: null,
      capitaine: 0,
      team_id: it.pivot.team_id,
      titular: it.pivot.titular,
      substitute: it.pivot.substitute,
      avatar:it.avatar
    };

    return part;
  });
};

export const getCsc=(pos)=>{
  if(pos===1) return "CSC équipe 2";
  else  return "CSC équipe 1"
};


export const getBut=(pos)=>{
  if(pos===1) return "Casper 1";
  else  return "Casper 2"
};
