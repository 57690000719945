import { getIndexOf } from '../ticket';
import { nullString } from '../formatting';

/**
 * TTC = HT + (HT x TVA / 100)
 *
 * @param {*} total
 * @param {*} percentage
 * @returns
 */
export const getTTC = (total, percentage) => {
  if(!total || parseFloat(total)===0) return 0;
  console.log((parseFloat(total) +  ((parseFloat(total) * parseFloat(percentage)) / 100)).toFixed(2));
  return (parseFloat(total) +  ((parseFloat(total) * parseFloat(percentage)) / 100)).toFixed(2);
};

/**
 * HT = (100 x TTC) / (100 + TVA)
 *
 * @param {*} total
 * @param {*} percentage
 * @returns
 */
export const getHT = (total, percentage) => {
  if(!total || parseFloat(total)===0) return 0;
  return ((100 * parseFloat(total)) / (100 + parseFloat(percentage))).toFixed(3);
};

export const getVatOfArticle = (ticket, article_id) => {
  let vat = null;
  ticket.articles.forEach(item => {
    if(item.id.toString() === article_id.toString()){
      vat = item.vat;
    }
  });
  return vat;
}
/**
 *
 * @param {*} ticket
 * @returns
 */
export const getTicketVat = (ticket) => {
  let result = [];
  let cloneTicket = JSON.parse(JSON.stringify(ticket));
  cloneTicket.payments.articles.map((article_payment, i) => {
    let vat = getVatOfArticle(cloneTicket , article_payment.article_id);
    let index = getIndexOf(result, it => (nullString(it.vat_id).toString() === nullString(article_payment.vat_id).toString()));
    if (index === -1) {
      result.push({
        ...article_payment,
        vat_acronym : vat.acronym,
        vat_percentage : vat.value
      });
    } else {
      result[index].amount = (parseFloat(result[index].amount) + parseFloat(article_payment.amount)).toFixed(2);
      result[index].amount_ht = (parseFloat(result[index].amount_ht) + parseFloat(article_payment.amount_ht)).toFixed(3);
      result[index].vat_amount = (parseFloat(result[index].vat_amount) + parseFloat(article_payment.vat_amount)).toFixed(3);
    }
  });
  return result;
};
