import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import cameraService from '../../services/cameraService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingCamera: ['data'],

  indexRequestCamera: ['payload'],
  indexResponseCamera: ['data'],

  storeRequestCamera: ['payload'],
  storeResponseCamera: ['data'],

  editRequestCamera: ['payload'],
  editResponseCamera: ['data'],

  updateRequestCamera: ['payload'],
  updateResponseCamera: ['data'],

  destroyRequestCamera: ['id'],
  destroyResponseCamera: ['data'],
});

// Initial state
const INITIAL_STATE = {
  cameras: [],
  camera: null,
  cameraPlannings: [],
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingCamera({ loading: true }));
    const data = yield call(cameraService.cameraService.index, action.payload);
    yield put(Creators.indexResponseCamera(data));
    yield put(Creators.loadingCamera({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingCamera({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingCamera({ loading: true }));
    const data = yield call(cameraService.cameraService.store, action.payload);
    yield put(Creators.storeResponseCamera(data));
    yield put(Creators.loadingCamera({ loading: false }));
    yield history.push('/configuration/cameras');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingCamera({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingCamera({ editLoading: true }));
    const data = yield call(cameraService.cameraService.edit, action.payload);
    yield put(Creators.editResponseCamera(data));
    yield put(Creators.loadingCamera({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingCamera({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingCamera({ loading: true }));
    const data = yield call(cameraService.cameraService.update, action.payload);
    yield put(Creators.updateResponseCamera(data));
    yield put(Creators.loadingCamera({ loading: false }));
    yield history.push('/configuration/cameras');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(Creators.loadingCamera({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingCamera({ deleteLoading: true }));
    const data = yield call(cameraService.cameraService.destroy, action.payload);
    yield put(Creators.destroyResponseCamera(data));
    yield put(Creators.loadingCamera({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_CAMERA, index);
  yield takeLatest(Types.STORE_REQUEST_CAMERA, store);
  yield takeLatest(Types.EDIT_REQUEST_CAMERA, edit);
  yield takeLatest(Types.UPDATE_REQUEST_CAMERA, update);
  yield takeLatest(Types.DESTROY_REQUEST_CAMERA, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    cameras: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    camera: action.data.data,
    cameraPlannings: action.data.data.camera_plannings
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};
// Reducer
export const cameraReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_CAMERA]: index_response,
  [Types.STORE_RESPONSE_CAMERA]: store_response,
  [Types.EDIT_RESPONSE_CAMERA]: edit_response,
  [Types.UPDATE_RESPONSE_CAMERA]: update_response,
  [Types.DESTROY_REQUEST_CAMERA]: destroy_request,
  [Types.DESTROY_RESPONSE_CAMERA]: destroy_response,
  [Types.LOADING_CAMERA]: loading,
});
