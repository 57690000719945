import React from "react";
import CustomTable from "@/components/CustomTable/CustomTable";
import { Button, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { Creators } from "@/store/ducks/credit";
import { nullStringForTable } from "../../helpers/formatting";
import { Link } from "react-router-dom";
import history from "../../helpers/history";
import "../../pages/ClientsPage/ClientsPage.css";
import "../../pages/SubscribersPage/SubscribersPage.css";

class CreditTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkReload: false,
      modalOpen: false,
      user_id: "",
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onSearchHandle = this.onSearchHandle.bind(this);
  }

  componentDidMount() {
    this.setState({ user_id: this.props.client });
    this.props.index(this.props.page, this.props.size, {
      user_id: this.props.client,
    });
  }

  handleOpen = (item) => {
    if (!this.props.session || this.props.session.status.toString() === "1") {
      this.toggleSessionModal(true);
      return;
    }
    this.setState({ item: item });
    item.subscription.subscription_type.is_reload === 1
      ? this.setState({ modalOpen: true })
      : this.setState({ modalDeadlineOpen: true });
  };

  handleDropdownChange = (e, d) => {
    this.setState({ [d.name]: d.value });
  };

  handleClose = () =>
    this.setState({
      modalOpen: false,
    });

  onChangeHandle(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onSearchHandle(event) {
    event.preventDefault();
    this.props.index(1, this.props.size, this.state);
  }

  toggleSessionModal = (modalCheckoutOpen) => {
    this.setState({
      modalCheckoutOpen,
    });
  };

  render() {
    const { t, credits } = this.props;
    return (
      <div>
        <div className={"table-container"}>
          <CustomTable
            loading={this.props.loading}
            loadMore={this.props.index}
            size={10}
            page={this.props.page}
            last={this.props.last}
            total={this.props.total}
            search={this.state}
            header={[
              "N°",
              t("credit"),
              t("refund"),
              t("expense"),
              t("ticket_number"),
              t("ticket_date"),
              t(""),
            ]}
            footer
          >
            <Table.Body>
              {credits.map((it, i) => {
                return (
                  <Table.Row key={`client-${i}`}>
                    <Table.Cell className="td-text">
                      #{i + this.props.from}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.credit)}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.payed_credit)}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.expense)}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.ticket?.number)}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.ticket?.date)}
                    </Table.Cell>
                    <Table.Cell className="td-text tools">
                      {it.ticket && !it.ticket?.is_credit_account && (
                        <Link
                          disabled={!it.ticket}
                          onClick={() => {
                            localStorage.setItem(
                              "goToTicket",
                              JSON.stringify(it.ticket)
                            );
                          }}
                          to={{
                            pathname: "/caisse",
                            state: {
                              ticket: it.ticket,
                            },
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            className={"round-btn"}
                            disabled={!it.ticket}
                            onClick={() => {}}
                          >
                            {t("details")}
                          </Button>
                        </Link>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </CustomTable>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  index: (page, size, search) =>
    dispatch(Creators.indexRequestCredit({ page, size, search })),
});

CreditTable.defaultProps = {
  showClient: true,
};

const mapStateToProps = (state) => {
  return {
    credits: state.credit.credits,
    page: state.credit.page,
    last: state.credit.last,
    total: state.credit.total,
    size: state.subscriber.size,
    from: state.credit.from,
    loading: state.credit.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditTable);
