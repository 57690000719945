import React, { useCallback, useEffect, useMemo } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import i18t from "../i18n";
import { useSelector } from "react-redux";
import CustomRoute from "../components/CustomRoute/CustomRoute";
import WithSidebar from "../Layout/WithSidebar/WithSidebar";
import { newMenu } from "../utils/menu";
import history from "../helpers/history";
import useTitleNotification from "../hooks/useTitleNotification";

/***
 *
 *  load specific router to connected user make sure if user not connected he has the module :
 *  module: [
 *      {
 *          slug:"unauthorized"
 *          permission:"login-access"
 *      }
 *  ]
 *
 ***/

/***
 * for the client Role we need to not show the dashboard page with item
 * so check if user has the role of the client dont show the dashboard
 *
 *  the dashboard i a module we can not associate the complex  manager
 *  for the admin we need to filter it out
 * */

const loadRouters = (
  modules,
  data,
  responseWithSideBar,
  responseWithoutSideBar,
  memo = [],
  options
) => {
  let module = null;
  for (let i = 0; i < data.length; i++) {
    if (
      data[i].import !== undefined &&
      data[i].module === "" &&
      data[i].path !== undefined
    ) {
      let item = (
        <CustomRoute
          key={
            "routes-" +
            (responseWithoutSideBar.length + responseWithSideBar.length) +
            i +
            1
          }
          path={data[i].path}
          exact={true}
          import={data[i].import}
          customProps={{ name: data[i].name, options }}
        />
      );
      if (data[i].withSideMenu === false) responseWithoutSideBar.push(item);
      else responseWithSideBar.push(item);
    } else if (
      data[i].import !== undefined &&
      data[i].import !== "" &&
      data[i].path !== undefined
    ) {
      let hasModule = has(memo, data[i].module);
      let hasPermission = null;
      if (hasModule === null) {
        module = has(modules, data[i].module);
        module && memo.push(module);
        hasPermission = module
          ? has(module.permissions, data[i].permission)
          : null;
      } else {
        hasPermission = has(hasModule.permissions, data[i].permission);
      }
      if (hasPermission !== null) {
        // skip adding permission to link if user doesn't has permission
        let selected = data[i];
        let extraProps = {
          editable: false,
          removable: false,
          addable: false,
        };

        let name = selected.permission.substring(
          0,
          selected.permission.lastIndexOf("-")
        );
        if (selected.addable)
          extraProps.addable =
            memo.includes(name + "-create") ||
            (module && has(module.permissions, name + "-create") !== null);

        if (selected.editable)
          extraProps.editable =
            memo.includes(name + "-edit") ||
            (module && has(module.permissions, name + "-edit") !== null);

        if (selected.addable)
          extraProps.removable =
            memo.includes(name + "-delete") ||
            (module && has(module.permissions, name + "-delete") !== null);

        let item = (
          <CustomRoute
            key={
              "routes-" +
              (responseWithoutSideBar.length + responseWithSideBar.length) +
              i +
              1
            }
            path={data[i].path}
            exact={true}
            import={data[i].import}
            customProps={{
              name: data[i].name,
              module: data[i].module,
              options,
              permissions: { ...extraProps },
            }}
          />
        );

        //  let i=   <Route path={data[i].path} exact render={(props) => <LazyLoading
        //     fn={React.lazy(() => import(`@/pages${data[i].import}`))}
        //     customProps={{...props,permissions:{ ...extraProps}}}/>}
        // />;
        if (data[i].withSideMenu === false) responseWithoutSideBar.push(item);
        else responseWithSideBar.push(item);
      }
    }

    if (data[i].sousMenu !== undefined && data[i].sousMenu.length > 0) {
      loadRouters(
        modules,
        data[i].sousMenu,
        responseWithSideBar,
        responseWithoutSideBar,
        memo,
        options
      );
    }
  }
  return responseWithSideBar;
};

let has = (modules, slug) => {
  if (modules === null || modules === undefined) {
    return null;
  } else {
    for (let i = 0; i < modules.length; i++) {
      if (modules[i].slug === slug) return modules[i];
    }
    return null;
  }
};

const Routes = React.memo((props) => {
  // TODO refactoring this for earning more performance
  //    const authentificationInformation = useSelector(state=>state.auth);
  let selected = useSelector((state) => state.auth.selected);
  let modules = selected && selected.modules ? selected.modules : [];
  let responseWithout = [];

  // TODO when every think its tested enable this
  //
  // if( authentificationInformation.roles && authentificationInformation.roles[0].slug=== "admin")
  //     modules=authentificationInformation.modules;
  // else if(authentificationInformation.selected !== null)
  //     modules=authentificationInformation.selected[0].modules;
  // else
  //     modules=authentificationInformation.modules;

  const t = useCallback((s) => {
    return i18t.t(s);
  }, []);

  // extra props to add bydefault to routes
  const options = {
    complex_id: selected.id,
  };

  const data = useMemo(() => newMenu(t), [t]);

  let routes = loadRouters(modules, data, [], responseWithout, [], options);

  return (
    <Switch>
      {responseWithout}
      <WithSidebar>
        {routes}
        <Route>
          {modules &&
          modules.filter((it) => it.slug === "unauthorized").length > 0 ? (
            <Redirect to={"/login"} />
          ) : (
            <Redirect
              to={{
                pathname: "/error-page",
                state: { referrer: history.location },
              }}
            />
          )}
        </Route>
      </WithSidebar>
    </Switch>
  );
});

export default Routes;
