import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import sideService from '../../services/sideService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingSide: ['data'],

  indexRequestSide: ['payload'],
  indexResponseSide: ['data'],

  storeRequestSide: ['payload'],
  storeResponseSide: ['data'],

  editRequestSide: ['id'],
  editResponseSide: ['data'],

  updateRequestSide: ['payload'],
  updateResponseSide: ['data'],

  destroyRequestSide: ['id'],
  destroyResponseSide: ['data'],
});

// Initial state
const INITIAL_STATE = {
  sides: [],
  side: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingSide({ loading: true }));
    const data = yield call(sideService.sideService.index, action.payload);
    yield put(Creators.indexResponseSide(data));
    yield put(Creators.loadingSide({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingSide({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingSide({ loading: true }));
    const data = yield call(sideService.sideService.store, action.payload);
    yield put(Creators.storeResponseSide(data));
    yield put(Creators.loadingSide({ loading: false }));
    yield history.push('/configuration/sides');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingSide({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingSide({ editLoading: true }));
    const data = yield call(sideService.sideService.edit, action.payload);
    yield put(Creators.editResponseSide(data));
    yield put(Creators.loadingSide({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingSide({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingSide({ loading: true }));
    const data = yield call(sideService.sideService.update, action.payload);
    yield put(Creators.updateResponseSide(data));
    yield put(Creators.loadingSide({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/sides');
  } catch (e) {
    yield put(Creators.loadingSide({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingSide({ deleteLoading: true }));
    const data = yield call(sideService.sideService.destroy, action.payload);
    yield put(Creators.destroyResponseSide(data));
    yield put(Creators.loadingSide({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_SIDE, index);
  yield takeLatest(Types.STORE_REQUEST_SIDE, store);
  yield takeLatest(Types.EDIT_REQUEST_SIDE, edit);
  yield takeLatest(Types.UPDATE_REQUEST_SIDE, update);
  yield takeLatest(Types.DESTROY_REQUEST_SIDE, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sides: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    side: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};
// Reducer
export const sideReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_SIDE]: index_response,
  [Types.STORE_RESPONSE_SIDE]: store_response,
  [Types.EDIT_RESPONSE_SIDE]: edit_response,
  [Types.UPDATE_RESPONSE_SIDE]: update_response,
  [Types.DESTROY_REQUEST_SIDE]: destroy_request,
  [Types.DESTROY_RESPONSE_SIDE]: destroy_response,
  [Types.LOADING_SIDE]: loading,
});
