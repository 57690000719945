import { deleteMethod, getMethod } from '@/helpers/index';

export default {
  actionService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/actionVideos?page=' + page +
        (size ? '&size=' + size : '')+
        (search && search.search_terrain ? '&search_terrain=' + search.search_terrain : '') +
        (search && search.search_date ? '&search_date=' + search.search_date : '') +
        '&complex_id=' + localStorage.getItem('complex_id'),
      );
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/actions/' + id);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/actions/' + id);
      return result.data;
    },
  },
};