import React from "react";

import { Checkbox, Dropdown, Form, Grid, Input, Label, Radio, Icon, Popup, Segment, Image} from 'semantic-ui-react';
import ImageWithFallBack from '../ImageComponent/Image';
import './CustomInput.css';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import InputColor from 'react-input-color';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from '@/i18n';
import TimeInput from '../TimeInput/TimeInput';
import { nullString } from "../../helpers/formatting";
import PhoneInput from "../PhoneInput/PhoneInput";

const options = [
  { key: ".com", text: ".com", value: ".com" },
  { key: ".net", text: ".net", value: ".net" },
  { key: ".org", text: ".org", value: ".org" },
];

const CustomInput = (prop) => {
  let showLabel = prop.showLabel;
  let props = { ...prop };
  delete props.showLabel;
  let $image = (
    <ImageWithFallBack
      type={"item"}
      className="img-upload avatar-cover"
      src=""
      verticalAlign="middle"
    />
  );
  if (props.imageurl) {
    $image = (
      <ImageWithFallBack
        type={"user"}
        className="img-upload avatar-cover"
        src={props.imageurl}
        circular
        verticalAlign="middle"
      />
    );
  }

  switch (props.type) {
    case "search":
      return (
        <Input
          className="search-input"
          {...{
            ...props,
            icon: "search",
            type: props.hasOwnProperty("searchType")
              ? props.searchType
              : "text",
          }}
        />
      );
    case "search-dropdown":
      return <Dropdown className="search-dropdown" {...{ ...props }} />;
    case "timeInput":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid width={16}>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <TimeInput {...{ ...props, type: "", label: null }} />
                {props.errormsg && props.error ? (
                  props.errormsg.content !== "" ? (
                    <Label prompt pointing>
                      {props.errormsg.content}
                    </Label>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "picture":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid width={16}>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <label className={"label-image"}>
                  {$image}
                  <span
                    className="form-label"
                    style={{ marginLeft: "10px", fontWeight: 400 }}
                  >
                    {i18n.t("msg-upload-img")}
                  </span>
                  <Input
                    id="picture"
                    {...{
                      accept: "image/*",
                      ...props,
                      type: "file",
                      label: false,
                    }}
                    style={{ display: "none" }}
                  />
                </label>
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "dropdown":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {props.label ? (
                <>
                  <Grid.Column width={4} verticalAlign="middle">
                    <label className="form-label">
                      {props.label + (props.required ? "*" : "")}
                    </label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Dropdown {...{ ...props, type: "", label: null }} />
                    {props.errormsg && props.error ? (
                      props.errormsg.content !== "" ? (
                        <Label prompt pointing>
                          {props.errormsg.content}
                        </Label>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Grid.Column>
                </>
              ) : (
                <>
                  <Dropdown {...{ ...props, type: "", label: null }} />
                  {props.errormsg && props.error ? (
                    props.errormsg.content !== "" ? (
                      <Label prompt pointing>
                        {props.errormsg.content}
                      </Label>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </>
              )}
            </Grid>
          </Form.Field>
        </div>
      );
    case "custom-dropdown":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <CustomDropdown {...{ ...props, type: "", label: null }} />
                {props.errormsg && props.error ? (
                  props.errormsg.content !== "" ? (
                    <Label prompt pointing>
                      {props.errormsg.content}
                    </Label>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "checkbox":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}

              <Grid.Column width={12}>
                <Checkbox
                  toggle
                  {...{ ...props, label: null, error: nullString(props.error) }}
                />
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "radioGroup":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <Form.Group inline>
                  {props.labels.map((it, i) => {
                    return (
                      <Form.Field
                        key={"radio-" + i}
                        control={Radio}
                        label={props.labels[i]}
                        checked={props.checked[i]}
                        onChange={() => props.onChange(i)}
                      />
                    );
                  })}
                </Form.Group>
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "checkboxGroup":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <Form.Group className={"group-checkbox"}>
                  {props.labels.map((it, i) => {
                    return (
                      <Form.Field
                        key={"checkbox-" + i}
                        control={Checkbox}
                        label={props.labels[i]}
                        checked={props.checked[i]}
                        onChange={() => props.onChange(i)}
                      />
                    );
                  })}
                </Form.Group>
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "color-input":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <InputColor
                  {...{ ...props }}
                  onChange={props.onChange}
                  placement="right"
                />
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "number":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {props.label ? (
                <>
                  {showLabel && (
                    <Grid.Column width={4} verticalAlign="middle">
                      <label className="form-label">
                        {props.label + (props.required ? "*" : "")}
                      </label>
                    </Grid.Column>
                  )}
                  <Grid.Column width={12}>
                    <Input
                      min={0}
                      {...{ ...props, label: false, type: "number" }}
                      onChange={(e) => {
                        //    e.target.value = e.target.value.trimStart();
                        props.onChange(e);
                      }}
                    />
                    {props.errormsg && props.error ? (
                      props.errormsg.content !== "" ? (
                        <Label prompt pointing>
                          {props.errormsg.content}
                        </Label>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Grid.Column>
                </>
              ) : (
                <>
                  <Input
                    min={0}
                    {...{ ...props, label: false, type: "number" }}
                    onChange={(e) => {
                      //   e.target.value = e.target.value.trimStart();
                      props.onChange(e);
                    }}
                  />
                  {props.errormsg && props.error ? (
                    props.errormsg.content !== "" ? (
                      <Label prompt pointing>
                        {props.errormsg.content}
                      </Label>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </>
              )}
            </Grid>
          </Form.Field>
        </div>
      );
    case "number-point":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <Grid columns={3}>
                  <Grid.Column>
                    <input
                      name="victory"
                      min={0}
                      placeholder={i18n.t("victory")}
                      value={props.valueVictory}
                      type="text"
                      onChange={(e) => props.onChange(e, "victory")}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <input
                      name="defeat"
                      min={0}
                      placeholder={i18n.t("defeat")}
                      value={props.valueDefeat}
                      type="text"
                      onChange={(e) => props.onChange(e, "defeat")}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <input
                      name="nul"
                      min={0}
                      placeholder={i18n.t("nul")}
                      value={props.valueNul}
                      type="text"
                      onChange={(e) => props.onChange(e, "nul")}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "number-forfeit":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <Grid columns={3}>
                  <Grid.Column>
                    <input
                      name="forfeit_v"
                      min={0}
                      placeholder={i18n.t("victory")}
                      value={props.value_forfeit_v}
                      type="text"
                      // pattern="^[0-9]"
                      onChange={(e) => props.onChange(e, "forfeit_v")}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <input
                      name="forfeit_d"
                      min={0}
                      placeholder={i18n.t("defeat")}
                      value={props.value_forfeit_d}
                      type="text"
                      onChange={(e) => props.onChange(e, "forfeit_d")}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "break-time":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid width={16}>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <CustomDropdown
                  label={props.label}
                  className={"input-bg"}
                  fetching={false}
                  name={props.name}
                  placeholder={props.placeholder}
                  render={(it) => (
                    <>
                      <span>{it.text}</span>{" "}
                    </>
                  )}
                  options={props.options}
                  keyword={"key"}
                  filter={"text"}
                  value={props.value}
                  onChange={props.onChange}
                />
                {props.errormsg && props.error ? (
                  props.errormsg.content !== "" ? (
                    <Label prompt pointing>
                      {props.errormsg.content}
                    </Label>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "open-close-input":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <Grid columns={2}>
                  <Grid.Column>
                    <TimeInput
                      name={props.name}
                      label="Ouverture"
                      labelPosition="right"
                      value={props.valueOpen}
                      onChange={(value) =>
                        props.onChange(value, "open", props.name)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <TimeInput
                      name={props.name}
                      label="Ouverture"
                      labelPosition="right"
                      value={props.valueClose}
                      onChange={(value) =>
                        props.onChange(value, "close", props.name)
                      }
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case "textArea":
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">
                    {props.label + (props.required ? "*" : "")}
                  </label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <textarea
                  {...{
                    ...props,
                    label: null,
                    value: props.value === null ? "" : props.value,
                  }}
                  style={{ backgroundColor: "#EEF1F7", ...props.textAreaStyle }}
                />
                {props.errormsg && props.error ? (
                  props.errormsg.content !== "" ? (
                    <Label prompt pointing>
                      {props.errormsg.content}
                    </Label>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    case 'textAreaWithAttachment':
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              {showLabel && (
                <Grid.Column width={4} verticalAlign="middle">
                  <label className="form-label">{props.label + (props.required ? '*' : '')}</label>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <textarea
                  {...{
                    name: props.name,
                    className: props.className,
                    label: props.label,
                    type: props.type,
                    mode: props.mode,
                    rows: props.rows,
                    defaultValue: props.defaultValue,
                    onChange: props.onChange,
                    label: null, 
                    disabled: props.disabled,
                    value: props.value === null ? '' : props.value }
                  }
                  style={{ backgroundColor: '#EEF1F7', ...props.textAreaStyle }}
                />
                <Label attached='bottom' style={{height : "32px"}}>
                  {props.attachmentFileName && 
                    <><Icon name='download'/>{props.attachmentFileName}</>
                  }
                  
                  <label className='upload-icon'>
                    <Popup content={i18n.t('msg-upload-file')} 
                      trigger={
                        <Icon name='attach' size='large' link/>
                      } 
                    />
                    <Input
                      id="attachment"
                      {...{
                        accept: '*',
                        name: props.attachName,
                        imageurl: props.imageurl,
                        onChange : props.attachOnChange,
                        disabled: props.disabled,
                        type: 'file',
                        label: false,
                      }}
                      style={{ display: 'none' }}
                    />
                  </label>
                </Label>
                {props.errormsg && props.error ? (
                  props.errormsg.content !== '' ? (
                    <Label prompt pointing>
                      {props.errormsg.content}
                    </Label>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid>
          </Form.Field>
        </div>
      );
    
    case 'phone':
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              <Grid.Row>
                {showLabel && (
                  <Grid.Column width={4} verticalAlign="middle">
                    <label className="form-label">
                      {props.label + (props.required ? "*" : "")}
                    </label>
                  </Grid.Column>
                )}
                <Grid.Column width={12}>
                  <PhoneInput {...props} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Field>
        </div>
      );

    default:
      return (
        <div className="form">
          <Form.Field error={props.error}>
            <Grid>
              <Grid.Row>
                {showLabel && (
                  <Grid.Column width={4} verticalAlign="middle">
                    <label className="form-label">
                      {props.label + (props.required ? "*" : "")}
                    </label>
                  </Grid.Column>
                )}
                <Grid.Column width={12}>
                  <Input
                    {...{ ...props, label: null }}
                    onChange={
                      typeof props.onChange !== "undefined"
                        ? (e) => {
                            e.target.value = e.target.value.trimStart();
                            props.onChange(e);
                          }
                        : () => {}
                    }
                  />
                  {props.errormsg && props.error ? (
                    props.errormsg.content !== "" ? (
                      <Label prompt pointing>
                        {props.errormsg.content}
                      </Label>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Field>
        </div>
      );
  }
};

CustomInput.defaultProps = {
  required: false,
  showLabel: true,
};

export default CustomInput;
