import moment from "moment";
import localization from "moment/locale/fr";
import {
  formatCodeCountry,
  getTotalPayment,
  nullString,
  phoneNumber,
  roundOnlyIfAvailable,
} from "./formatting";
import { getArticleDiscount, getPaymentName } from "./ticket";
import { getHT, getTicketVat } from "./caisse/tvaCalcul";
import { keys } from "lodash";

const getPropertyType = (data, attr, defaultAttr = "") => {
  if (data && data[attr]) return data[attr];
  else return defaultAttr;
};
//Thermal printer
const ThermalPrinter = require("node-thermal-printer").printer;
const PrinterTypes = require("node-thermal-printer").types;
export const TPrinter = async (data) => {
  let printer = new ThermalPrinter({
    type: PrinterTypes.EPSON,
    //interface: 'tcp://xxx.xxx.xxx.xxx',
  });
  printer.println("hello");
  // printer.cut();  //cut the paper
  printer.partialCut(); //partial cut
  printer.execute(function (err) {
    if (err) {
      console.error("Print failed:", err);
    } else {
      console.log("Print done!");
    }
  });
};

// Popup for print
export const PrintPopup = async (data) => {
  let Window = window.open("", "Receipt");

  Window.document.write(data);
  Window.document.close(); // necessary for IE >= 10

  Window.onload = function () {
    // necessary if the div contain images

    Window.focus(); // necessary for IE >= 10
    setTimeout(() => {
      Window.print();
      Window.close();
    }, 100);
  };
};

/**
 *
 * @param {*} data
 */
export const concludeXPrint = (data) => {
  let currency = localStorage.getItem("currency");
  let total = 0,
    quantity = 0;
  let payments = data.checkoutStats.payments;
  let parents = data.checkoutStats.parents;
  let children = data.checkoutStats.children;
  let vats = data.checkoutStats.vats;
  let users = data.users;
  let user = data.checkout_session ? data.checkout_session.user : null;
  let paymentRows = [],
    parentsRow = [],
    childrenRow = [],
    vatsRow = [],
    othersRow = [];

  for (let v in vats) {
    vatsRow.push(
      `
      <div class="col">
            <span class="col-8">${v}%</span>
            <span class="col-4 right">${
              parseFloat(vats[v]).toFixed(2) + currency
            }</span>
      </div>
      `
    );
  }

  for (let d in payments) {
    if (payments.hasOwnProperty(d)) {
      if (
        payments[d].is_card === 0 &&
        payments[d].is_subscription === 0 &&
        payments[d].is_credit_account === 0 &&
        payments[d].is_coupon === 0
      )
        total += payments[d].theoretical_amount;
      quantity += payments[d].sales_nb;
      let payUsers = [];
      for (let us in payments[d].users) {
        let selected = users.filter((it) => it.id.toString() === us);
        if (selected.length)
          payUsers.push(`
          <span class="col-10 by-users">
                ${
                  nullString(selected[0].firstName).toLowerCase() +
                  " " +
                  nullString(selected[0].lastName).toUpperCase()
                } -
                ${payments[d].users[us].amount.toFixed(2) + currency}
          </span>
        `);
      }
      if (
        payments[d].is_subscription === 0 &&
        payments[d].is_card === 0 &&
        payments[d].is_credit_account === 0 &&
        payments[d].is_coupon === 0
      ) {
        paymentRows.push(
          `<div class="col">
            <span class="col-8">${d}</span>
            <span class="col-4 right">${
              payments[d].amount.toFixed(2) + currency
            }</span>
          </div>`
        );
      } else {
        othersRow.push(
          `<div class="col">
            <span class="col-8">${d}</span>
            <span class="col-4 right">${
              payments[d].amount.toFixed(2) + currency
            }</span>
          </div>`
        );
      }
    }
  }

  for (let d in parents) {
    if (parents.hasOwnProperty(d)) {
      let payUsers = [];
      for (let us in parents[d].users) {
        let selected = users.filter((it) => it.id.toString() === us);
        if (selected.length)
          payUsers.push(`
          <span class="col-10 by-users">
                ${
                  nullString(selected[0].firstName).toLowerCase() +
                  " " +
                  nullString(selected[0].lastName).toUpperCase()
                } -
                ${parents[d].users[us].amount.toFixed(2) + currency}
          </span>
        `);
      }
      if (d !== "Acompte" && d !== "Recharge" && d !== "Remboursement crédit") {
        parentsRow.push(
          `<div class="col">
            <span class="col-8">${d}</span>
            <span class="col-4 right">${
              parents[d].amount.toFixed(2) + currency
            }</span>
          </div>`
        );
      } else {
        othersRow.push(
          `<div class="col">
            <span class="col-8">${d}</span>
            <span class="col-4 right">${
              parents[d].amount.toFixed(2) + currency
            }</span>
          </div>`
        );
      }
    }
  }
  let payUsers = [];
  for (let d in children) {
    if (children.hasOwnProperty(d)) {
      for (let us in children[d].users) {
        let selected = users.filter((it) => it.id.toString() === us);
        if (selected.length) {
          let index = -1;
          payUsers.forEach((it, i) => {
            if (it.id.toString() === us) index = i;
          });
          if (index === -1)
            payUsers.push({
              ...selected[0],
              amount: parseFloat(children[d].users[us].amount),
            });
          else {
            payUsers[index].amount += parseFloat(children[d].users[us].amount);
          }
        }
      }

      childrenRow.push(
        `<div class="col">
          <span class="col-8">${d}</span>
          <span class="col-4 right">${
            parseFloat(
              children[d].hasOwnProperty("payments")
                ? getTotalPayment(children[d].payments)
                : children[d].amount
            ).toFixed(2) + currency
          }</span>
        </div>`
      );
    }
  }

  let usersRows = [];
  if (payUsers.length) {
    for (let it of payUsers) {
      usersRows.push(
        `<div class="col">
          <span class="col-8">${
            nullString(it.firstName).toLowerCase() +
            " " +
            nullString(it.lastName).toUpperCase()
          }</span>
          <span class="col-4 right">${it.amount.toFixed(2) + currency}</span>
        </div>`
      );
    }
  }

  let html = `
  <html lang="en" >
      <head>
      <meta charset="UTF-8">
      <title>Receipt</title>
      <link rel='stylesheet prefetch' href='https://fonts.googleapis.com/css?family=VT323'>
      <style> ${concludeStyle()}</style>
      </head>
      <body>
      <div class='receipt'>
        <div class='paper'>
          <h1>Clôture ${data.status.toString() === "0" ? "X" : "Z"}</h1>
          <div>
            du ${
              moment(data.closing_date + "T" + data.hour)
                .locale("fr", localization)
                .format("LLLL") +
              (user !== null
                ? " par " +
                  nullString(user.firstName).toLowerCase() +
                  " " +
                  nullString(user.lastName).toUpperCase()
                : "")
            }
            </div>
            <hr/>
          <h4>Récapitulatif CA</h4>
          <div class="col">
            <span class="col-8">Total journée TTC</span>
            <span class="col-4 right">${total.toFixed(2) + currency}</span>
          </div>
         ${vatsRow.join("")}
          <hr>
          ${
            parentsRow.length > 0
              ? `<h4>Récapitulatif Famille</h4> ${parentsRow.join("")} <hr>`
              : ``
          }
         ${
           childrenRow.length > 0
             ? `<h4>Récapitulatif Sous / Famille</h4> ${childrenRow.join(
                 ""
               )} <hr>`
             : ``
         }
         ${
           paymentRows.length > 0
             ? `<h4>Encaissements</h4> ${paymentRows.join("")} <hr>`
             : ``
         }
          ${
            othersRow.length > 0
              ? `<h4>Autres</h4> ${othersRow.join("")} <hr>`
              : ``
          }
          <h4>${usersRows.length === 1 ? "vendeur" : "vendeurs"}</h4>
            ${usersRows.join("")}
      </div>
    </body>
    </html>
  `;
  return html;
};
/**
 *
 * @param {*} data
 */
export const concludeSessionPrint = (data) => {
  let currency = localStorage.getItem("currency");
  let payments = [];
  if (data && data.checkoutStats && data.checkoutStats.payments) {
    for (let att in data.checkoutStats.payments) {
      payments.push(`
                 <div class="col">
                    <span class="col-6">${att}</span>
                    <span class="col-2 right"/>
                    <span class="col-4 right">${
                      data.checkoutStats.payments[att] + currency
                    }</span>
                </div>
        `);
    }
  }

  let articles = [];
  if (data && data.checkoutStats && data.checkoutStats.articles) {
    for (let att in data.checkoutStats.articles) {
      articles.push(`
                 <div class="col">
                    <span class="col-6">${att}</span>
                    <span class="col-2 right">x${
                      data.checkoutStats.articles[att].total
                    }</span>
                    <span class="col-4 right">${
                      data.checkoutStats.articles[att].amount + currency
                    }</span>
                </div>
        `);
    }
  }
  let tickets = [];
  if (data && data.checkoutStats && data.checkoutStats.tickets) {
    for (let att in data.checkoutStats.tickets) {
      let sous = [];
      for (let a in data.checkoutStats.tickets[att]) {
        sous.push(`
                    <span class="col-2 right">${a}</span>
                    <span class="col-4 right">${
                      data.checkoutStats.tickets[att][a] + currency
                    }</span>
        `);
      }

      tickets.push(`
                 <div class="col">
                    <span>${att}</span>
                    ${sous.join("")}
                </div>
        `);
    }
  }

  let html = `
            <html lang="en" >
            <head>
            <meta charset="UTF-8">
            <title>Receipt</title>
            <link rel='stylesheet prefetch' href='https://fonts.googleapis.com/css?family=VT323'>
            <style> ${concludeStyle()}</style>
            </head>
            <body>
            <div class='receipt'>
                <div class='paper'>
                <h1>Clôture du caisse</h1>
                <div class="col">
                    <span class="col-6">Date: ${getPropertyType(
                      data,
                      "start_date"
                    )}</span>
                    <span class="col-6 right">Heure: 08:00</span>
                </div>
                <div class="col">
                    <span class="col-6">Session: ${getPropertyType(
                      data,
                      "id"
                    )}</span>
                </div>
                <div>
                    Ouverte le ${getPropertyType(
                      data,
                      "start_date"
                    )} à 08:00 par utilisateur
                </div>
                <div>Clôturer par utilisateur</div>
                <h4>Encaissements</h4>
                ${payments.join("")}
                <h4>Controle du fond de caisse</h4>
                <div class="col">
                    <span class="col-10">Fond de caisse initial</span>
                    <span class="col-2 right">${
                      getPropertyType(data, "initial_amount", 0) + currency
                    }</span>
                </div>
                <div class="col">
                    <span class="col-10">Réglement espèces</span>
                    <span class="col-2 right">0${currency}</span>
                </div>
                <div class="col">
                    <span class="col-10">Dépense</span>
                    <span class="col-2 right">0${currency}</span>
                </div>
                <div class="col">
                    <span class="col-10">Apport du coffre</span>
                    <span class="col-2 right">0${currency}</span>
                </div>
                <div class="col">
                    <span class="col-10">Remise du coffre</span>
                    <span class="col-2 right">0${currency}</span>
                </div>
                <div class="col">
                    <span class="col-10">Fond du caisse théorique</span>
                    <span class="col-2 right">0${currency}</span>
                </div>
                <div class="col">
                    <span class="col-10">Fond du caisse saisi</span>
                    <span class="col-2 right">0${currency}</span>
                </div>
                <div class="col">
                    <span class="col-10">Ecart</span>
                    <span class="col-2 right">0${currency}</span>
                </div>
                </div>
                <hr>
                <div class='paper'>
                <h1>Clôture du caisse</h1>
                <div class="col">
                    <span class="col-6">Date: ${getPropertyType(
                      data,
                      "closing_date",
                      ""
                    )}</span>
                    <span class="col-6 right">Heure: 11:00</span>
                </div>
                <div class="col">
                    <span class="col-6">Session: ${getPropertyType(
                      data,
                      "id"
                    )}</span>
                </div>
                <div>
                    Ouverte le 23-09-2020 à 08:00 par utilisateur
                </div>
                <div>Clôturer par utilisateur</div>
                <div class="border-top">
                    <div class="col">
                    <span class="col-10">CA TOTAL</span>
                    <span class="col-2 right">${
                      getPropertyType(data.checkoutStats, "ca", "0") + currency
                    }</span>
                    </div>
                    <div class="col">
                    <span class="col-10">Nb de vente</span>
                    <span class="col-2 right">${
                      getPropertyType(
                        data.checkoutStats,
                        "articles_total",
                        "0"
                      ) + currency
                    }</span>
                    </div>
                    <div class="col">
                    <span class="col-10">Panier moyen</span>
                    <span class="col-2 right">${
                      parseFloat(
                        getPropertyType(
                          data.checkoutStats,
                          "amount_article_average",
                          "0"
                        )
                      ).toFixed(2) + currency
                    }</span>
                    </div>
                    <div class="col">
                    <span class="col-10">Nb d'articles moyen</span>
                    <span class="col-2 right">${
                      parseFloat(
                        getPropertyType(
                          data.checkoutStats,
                          "articles_average",
                          "0"
                        )
                      ).toFixed(2) + currency
                    }</span>
                    </div>
                </div>
                <div class="border-top">
                    <div class="col">
                    <span class="col-6">Ticket</span>
                    <span class="col-2 right">${getPropertyType(
                      data.checkoutStats,
                      "tickets_total",
                      "0"
                    )}</span>
                    <span class="col-4 right">${
                      getPropertyType(data.checkoutStats, "ca", "0") + currency
                    }</span>
                    </div>
                    <div class="col">
                    <span class="col-6">Bon de livraison</span>
                    <span class="col-2 right">x0</span>
                    <span class="col-4 right">0${currency}</span>
                    </div>
                    <div class="col">
                    <span class="col-6">Facture</span>
                    <span class="col-2 right">x0</span>
                    <span class="col-4 right">0${currency}</span>
                    </div>
                </div>
                <div class="border-top">
                    <h4>Par profil TVA</h4>
                    <div class="col">
                    <span class="col-6">Profil</span>
                    <span class="col-2 right">Base</span>
                    <span class="col-4 right">TVA</span>
                    </div>
                    <div class="col">
                    <span class="col-6">Taux normal</span>
                    <span class="col-2 right">0${currency}</span>
                    <span class="col-4 right">0${currency}</span>
                    </div>
                    <div class="col">
                    <span class="col-6">Taux réduit</span>
                    <span class="col-2 right">0${currency}</span>
                    <span class="col-4 right">0${currency}</span>
                    </div>
                </div>
                <div class="border-top">
                    <h4>Par famille</h4>
                       ${articles.join("")}
                </div>
                <div class="border-top">
                    <h4>Accomptes</h4>
                    <div class="col">
                    <span class="col-10">Accomptes versé</span>
                    <span class="col-2 right">0${currency}</span>
                    </div>
                    <div class="col">
                    <span class="col-10">Accomptes consommés</span>
                    <span class="col-2 right">0${currency}</span>
                    </div>
                </div>
                <div class="border-top">
                    <h4>Avoirs</h4>
                    <div class="col">
                    <span class="col-10">Avoirs émis</span>
                    <span class="col-2 right">0${currency}</span>
                    </div>
                    <div class="col">
                    <span class="col-10">Avoirs consommés</span>
                    <span class="col-2 right">0${currency}</span>
                    </div>
                </div>
                </div>
                <hr>
                <div class='paper'>
                <h1>Clôture du caisse</h1>
                <div class="col">
                    <span class="col-6">Date: ${getPropertyType(
                      data,
                      "closing_date",
                      ""
                    )}</span>
                    <span class="col-6 right">Heure: 11:00</span>
                </div>
                <div class="col">
                    <span class="col-6">Session: 11</span>
                </div>
                <div>
                    Ouverte le ${getPropertyType(
                      data,
                      "start_date",
                      ""
                    )} à 08:00 par utilisateur
                </div>
                <div>Clôturer par Tickets</div>
                <div class="border-top">
                    ${tickets.join("")}
                </div>
                </div>
            </div>

            </body>
        </html>

      `;
  return html;
};

/**
 *
 * @param {*} ticket
 */
export const ticketPrint = (
  ticket,
  complex,
  ticketInfos = [],
  number = null,
  with_details = false,
  info = null
) => {
  let currency = localStorage.getItem("currency");
  let amount_ht = 0;
  let vat_amount = 0;
  let articles = ticket.articles
    .map((it) => {
      amount_ht = parseFloat(amount_ht) + parseFloat(it.amount_ht);
      vat_amount = parseFloat(vat_amount) + parseFloat(it.vat_amount);
      let articleDiscount =
        getArticleDiscount(ticket, it.id) > 0
          ? ` <span style="width: 20%; margin : 1%; text-align: right;">
          Remise
        </span>
        <span style="width: 77%; text-align: right;">
          -${getArticleDiscount(ticket, it.id)}
        </span>
      `
          : ``;

      return `
      <span style="width: 52%; margin : 1%;">
        ${it.name}
      </span>
      <span style="width: 10%; margin : 1%; text-align: right;">
        ${parseFloat(it.total_price).toFixed(2)}
      </span>
      <span style="width: 9%; margin : 1%; text-align: right;">
        x${it.article_nb}
      </span>
      <span style="width: 14%; margin : 1%; text-align: right;">
        ${(parseFloat(it.total_price) * parseInt(it.article_nb)).toFixed(2)}
      </span>
      <span style="width: 5%; margin : 1%; text-align: right;">
        ${it.vat.acronym}
      </span>
      ${articleDiscount}
    `;
    })
    .join("");

  let details = with_details
    ? `<dl>
        <span style="width: 52%; margin : 1%;">
          Article
        </span>
        <span style="width: 10%; margin : 1%; text-align: right;">
          P.U
        </span>
        <span style="width: 9%; margin : 1%; text-align: right;">
          Qté
        </span>
        <span style="width: 14%; margin : 1%; text-align: right;">
          EUR
        </span>
        <span style="width: 5%; text-align: right;">
        </span>
        ${articles}
        <br/>
        <hr>
      </dl>`
    : ``;
  let info_ticket = info ? `Info : ${info}` : ``;

  let informations =
    ticketInfos.length > 0
      ? (ticketInfos[0].address ? ticketInfos[0].address + ", " : " ") +
        (ticketInfos[0].zip ? ticketInfos[0].zip + " " : " ") +
        (ticketInfos[0].city ? ticketInfos[0].city + " " : " ") +
        (ticketInfos[0].country ? ticketInfos[0].country : " ")
      : "";
  let vats = getTicketVat(ticket);
  let tvaList = vats
    .map((vat) => {
      return `
      <dl>
        <span style="width: 8%; margin : 1%;">
          ${vat.vat_acronym}
        </span>
        <span style="width: 20%; margin : 1%; text-align: right;">
          ${vat.vat_percentage}%
        </span>
        <span style="width: 21%; margin : 1%; text-align: right;">
          ${parseFloat(vat.amount).toFixed(2)}
        </span>
        <span style="width: 20%; margin : 1%; text-align: right;">
          ${parseFloat(vat.vat_amount).toFixed(2)}
        </span>
        <span style="width: 21%; text-align: right;">
          ${parseFloat(vat.amount_ht).toFixed(2)}
        </span>
      </dl>
    `;
    })
    .join("");
  let html = `
    <html lang="en" >
    <head>
    <meta charset="UTF-8">
    <title>Receipt</title>
    <link rel='stylesheet prefetch' href='https://fonts.googleapis.com/css?family=VT323'>
      <style> ${style()}</style>
    </head>
    <body>
      <div class='receipt'>
        <div class='paper'>
          <div class='event'>BIENVENUE DANS VOTRE COMPLEXE</div>
          <div class='event center'>${informations}</div>
          <div class='event'>${complex.name}, ${moment()
    .locale("fr", localization)
    .format("lll")}</div>
          <dl style="margin-top : 0.8em">
            <dt>Ticket No:</dt>
            <dd>#${number ? number : ticket.number}</dd>
            <dt>Client:</dt>
            <dd>${
              ticket.user
                ? ticket.user.firstName + " " + ticket.user.lastName
                : "---"
            }</dd>
            <dt>Date d'achat:</dt>
            <dd>${ticket.date}</dd>
            <dt>Adresse:</dt>
            <dd>${nullString(complex.address)}</dd>
          </dl>
          <div class='event' style="margin-top : 0.8em">
            <b>Ticket de vente</b>
          </div>
          ${details}
          ${info_ticket}
          <dl class='total'>
            <dt>Total HT:</dt>
            <dd> ${parseFloat(ticket.amount_ht).toFixed(2) + currency}</dd>
            <dt>TVA:</dt>
            <dd> ${parseFloat(ticket.vat_amount).toFixed(2) + currency}</dd>
            <dt>Remise:</dt>
            <dd> ${parseFloat(ticket.discount).toFixed(2) + currency}</dd>
            <dt>Total:</dt>
            <dd> ${
              (parseFloat(ticket.amount) - parseFloat(ticket.discount)).toFixed(
                2
              ) + currency
            }</dd>
          </dl>
          <dl>
            <span style="width: 8%; margin : 1%;">
              TVA
            </span>
            <span style="width: 20%; margin : 1%; text-align: right;">
              TAUX
            </span>
            <span style="width: 21%; margin : 1%; text-align: right;">
              M.TTC
            </span>
            <span style="width: 20%; margin : 1%; text-align: right;">
              M.TVA
            </span>
            <span style="width: 21%; text-align: right;">
              T.HT
            </span>
          </dl>
            ${tvaList}
          <dl>

          </dl>
        </div>
      </div>
    </body>
  </html>
  `;

  return html;
};
/**
 *
 * @param {*} data
 */
export const invoicePrint = (data) => {
  let invoice = data.data ? data.data : data;
  let ticket = data.ticket;
  let ticketInfos = data.ticketInfos && data.ticketInfos[0];
  let currency = localStorage.getItem("currency");
  let payedArticles = ticket.articles.filter((itm) => itm.status === "payed");
  let articles = [];
  let amount_ht = 0;
  let vat_amount = 0;

  payedArticles.forEach((it, i) => {
    amount_ht = parseFloat(amount_ht) + parseFloat(it.amount_ht);
    vat_amount = parseFloat(vat_amount) + parseFloat(it.vat_amount);
    if (data.showNote) {
      if (i === payedArticles.length - 1)
        articles.push(`
        <tr class="no-border-invoice">
          <td>${nullString(invoice.note)}</td>
          <td></td>
          <td></td>
          <td>
          </td>
        </tr>`);
    } else
      articles.push(`
        <tr>
          <td>${it.name + " " + nullString(it.info)}</td>
          <td>${it.pivot ? it.pivot.article_nb : it.article_nb}</td>
          <td>  ${(
            parseFloat(it.pivot ? it.pivot.amount_ht : it.amount_ht) /
            parseFloat(it.pivot ? it.pivot.article_nb : it.article_nb)
          ).toFixed(2)}${currency}</td>
          <td>
            ${parseFloat(it.pivot ? it.pivot.amount_ht : it.amount_ht).toFixed(
              2
            )}${currency}
          </td>
        </tr>`);
  });
  articles = articles.join("");

  let html = `
    <html lang="en" >
      <head>
        <meta charset="UTF-8">
        <title>Facture</title>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
        <style>
        ${invoiceStyle()}
        </style>
      </head>
      <body>
        <div class='paper'>
          <div class="col">
            <div class="col-6 invoice-complex">
                <h3>${ticketInfos ? nullString(ticketInfos.name) : ""}</h3>
            </div>
            <div class="col-6 invoice-title">
              Facture
            </div>
          </div>
          <div class="col-6 invoice-complex-info">
            <p>Adresse : ${
              ticketInfos ? nullString(ticketInfos.address) : ""
            }</p>
            <p>CP: ${ticketInfos ? nullString(ticketInfos.zip) : ""} Ville : ${
    ticketInfos ? nullString(ticketInfos.city) : ""
  }</p>
            <p>Tel : ${nullString(ticketInfos ? ticketInfos.tel : null)}</p>
            <p>SIREN : ${ticketInfos ? nullString(ticketInfos.siren) : ""}</p>
          </div>
          <div class="col">
            <div class="col-4 invoice-info">
              <p>Référence: #${invoice.id} </p>
              <p>Date: ${ticket.date}</p>
              <p>N° ticket: ${ticket.number}</p>
            </div>
            <div class="col-2"></div>
            <div class="col-6 invoice-client-info">
              <h3>${
                nullString(
                  invoice.user ? invoice.user.lastName : null
                ).toUpperCase() +
                " " +
                nullString(
                  invoice.user ? invoice.user.firstName : null
                ).toLowerCase()
              }</h3>
              <p>${nullString(
                invoice.user ? invoice.user.address : null
              )} ${nullString(invoice.user ? invoice.user.zip : null)}</p>
              <p>${nullString(invoice.user ? invoice.user.email : null)}</p>
              <p>${phoneNumber(
                nullString(
                  invoice.user
                    ? formatCodeCountry(invoice.user.country_code) +
                        phoneNumber(invoice.user.mobile)
                    : null
                )
              )}</p>
              <p>${nullString(invoice.user ? invoice.user.company : null)}</p>
            </div>
          </div>
          <table class="table-items">
            <colgroup>
              ${'<col span="1" style="width: 40%;">'}
              <col span="1" style="width: "${"15%"}>
              <col span="1" style="width: "${"15%"}>
              <col span="1" style="width: "${"15%"}>
            </colgroup>
            <tr>
                ${"<th>Article</th>"}
                <th>Quantité</th>
                <th>Prix ​​unitaire HT</th>
                <th>Prix total HT</th>
            </tr>
            ${articles}
          </table>
          <div class="col">
            <div class="col-6 note">
              <h3>Note</h3>
              <p>${data.showNote ? "" : nullString(invoice.note)}</p>
            </div>
            <table class="col-6 table-total">
              <colgroup>
                <col span="1" style="width: 50%;">
                <col span="1" style="width: 50%;">
              </colgroup>
              ${
                !(
                  ticket &&
                  ticket.articles &&
                  ticket.articles.length > 0 &&
                  ticket.articles[0] &&
                  ticket.articles[0].parent?.is_reload === 1
                )
                  ? `<tr>
                    <td>Total HT</td>
                    <td class="right">${amount_ht.toFixed(2)}${currency}</td>
                  </tr>
                  <tr>
                    <td>TVA</td>
                    <td class="right">${vat_amount.toFixed(2)}${currency}</td>
                  </tr>
                  <tr>
                    <td>Remise</td>
                    <td class="right">${parseFloat(ticket.discount).toFixed(
                      2
                    )}${currency}</td>
                  </tr>`
                  : ``
              }
              
              <tr class="with-border">
                <td>Total TTC</td>
                <td class="right">${(
                  parseFloat(amount_ht) + parseFloat(vat_amount)
                ).toFixed(2)}${currency}
                </td>
              </tr>
            </table>
          </div>
          <div class="col">
            <div class="col-12 invoice-footer">
              <p>SIREN: ${
                ticketInfos ? nullString(ticketInfos.siren) : ""
              } - CODE NAF: ${
    ticketInfos ? nullString(ticketInfos.naf_code) : ""
  } - TVA: ${ticketInfos ? nullString(ticketInfos.vat) : ""}</p>
            </div>
          <div>
        </div>

      </body>
    </html>
  `;
  return html;
};
/**
 *
 * @param {*} data
 */
export const advancePrint = (data, payment_types_list) => {
  let currency = localStorage.getItem("currency");
  let tvaAmount = getHT(data.payed_amount, data.article.vat.value);
  let payment_types = [];
  if (data && data.payments.types) {
    payment_types = data.payments.types
      .map((type, index) => {
        return `
          <div class="col">
            <span class="col-6">${getPaymentName(
              payment_types_list,
              type.payment_type_id
            )}</span>
            <span class="col-6 right">${
              type.pivot
                ? type.pivot.amount
                : type.hasOwnProperty("amount")
                ? type.amount
                : 0
            }${currency}</span>
          </div>
        `;
      })
      .join("");
  }
  let html = `
  <html lang="en" >
      <head>
      <meta charset="UTF-8">
      <title>Receipt</title>
      <link rel='stylesheet prefetch' href='https://fonts.googleapis.com/css?family=VT323'>
      <style> ${concludeStyle()}</style>
      </head>
      <body>
      <div class='receipt'>
        <div class='paper'>
          <h1>Acompte N°${data.advance_number}</h1>
          <br/>
          <div>
            Crée le ${moment(data.created_at)
              .locale("fr", localization)
              .format("LLLL")}
          </div>
          <hr>
          <br/>
          <h4>Client</h4>
          <div class="col">
            <span class="col-4">Prénom</span>
            <span class="col-8 right">${
              data.user ? nullString(data.user.firstName) : ""
            }</span>
          </div>
          <div class="col">
            <span class="col-4">Nom</span>
            <span class="col-8 right">${
              data.user ? nullString(data.user.lastName).toUpperCase() : ""
            }</span>
          </div>
          <div class="col">
            <span class="col-4">Téléphone</span>
            <span class="col-8 right">${
              data.user
                ? formatCodeCountry(data.user.country_code) +
                  phoneNumber(data.user.mobile)
                : "---"
            }</span>
          </div>
          <br/>
          <h4>Montant</h4>
          <div class="col">
            <span class="col-8">Montant total</span>
            <span class="col-4 right">${roundOnlyIfAvailable(
              data.amount
            )}${currency}</span>
          </div>
          <div class="col">
            <span class="col-8">Montant utilisé</span>
            <span class="col-4 right">${roundOnlyIfAvailable(
              data.payed_amount
            )}${currency}</span>
          </div>
             <div class="col">
            <span class="col-8">TVA</span>
            <span class="col-4 right">${roundOnlyIfAvailable(
              (parseFloat(data.payed_amount) - parseFloat(tvaAmount)).toFixed(2)
            )}${currency}</span>
          </div>
          <br/>
          <h4>Article</h4>
          <div class="col">
            <span class="col-4">Nom</span>
            <span class="col-8 right">${data.articles[0].name}</span>
          </div>
          <br/>
          <h4>Types de paiement</h4>
          ${payment_types}
      </div>
    </body>
    </html>
  `;
  return html;
};

export const creditPrint = (data) => {
  let currency = localStorage.getItem("currency");
  let html = `
  <html lang="en" >
      <head>
      <meta charset="UTF-8">
      <title>Receipt</title>
      <link rel='stylesheet prefetch' href='https://fonts.googleapis.com/css?family=VT323'>
      <style> ${concludeStyle()}</style>
      </head>
      <body>
      <div class='receipt'>
        <div class='paper'>
          <h1>Avoir N°${data.credit_number}</h1>
          <br/>
          <div>
            Crée le ${moment(data.created_at)
              .locale("fr", localization)
              .format("LLLL")}
          </div>
          <hr>
          <br/>
          <h4>Montant</h4>
          <div class="col">
            <span class="col-8">Montant total</span>
            <span class="col-4 right">${roundOnlyIfAvailable(
              data.amount
            )}${currency}</span>
          </div>
          <div class="col">
            <span class="col-8">Montant utilisé</span>
            <span class="col-4 right">${roundOnlyIfAvailable(
              data.payed_amount
            )}${currency}</span>
          </div>
          <br/>
      </div>
    </body>
    </html>
  `;
  return html;
};

export const reloadPrint = (data) => {
  let currency = localStorage.getItem("currency");
  let html = `
  <html lang="en" >
      <head>
      <meta charset="UTF-8">
      <title>Receipt</title>
      <link rel='stylesheet prefetch' href='https://fonts.googleapis.com/css?family=VT323'>
      <style> ${concludeStyle()}</style>
      </head>
      <body>
      <div class='receipt'>
        <div class='paper'>
          <h1>Recharge N°${data.number}</h1>
          <br/>
          <div>
            Crée le ${moment(data.created_at)
              .locale("fr", localization)
              .format("LLLL")}
          </div>
          <hr>
          <br/>
          <div class="col">
            <span class="col-6">Client</span>
            <span class="col-6 right">${
              data.user ? nullString(data.user.firstName).toLowerCase() : ""
            } ${
    data.user ? nullString(data.user.lastName).toUpperCase() : ""
  }</span>
          </div>
          <div class="col">
            <span class="col-6">Carte prépayée</span>
            <span class="col-6 right">${
              data.credit_subscriber_detail &&
              data.credit_subscriber_detail.subscriber &&
              data.credit_subscriber_detail.subscriber.subscription &&
              data.credit_subscriber_detail.subscriber.subscription.name
            }</span>
          </div>
          <div class="col">
            <span class="col-6">Recharge</span>
            <span class="col-6 right">${data.amount}${currency}</span>
          </div>
          <br/>
      </div>
    </body>
    </html>
  `;
  return html;
};

export const subscriptionPrint = (data) => {
  let currency = localStorage.getItem("currency");
  let html = `
  <html lang="en" >
      <head>
      <meta charset="UTF-8">
      <title>Receipt</title>
      <link rel='stylesheet prefetch' href='https://fonts.googleapis.com/css?family=VT323'>
      <style> ${concludeStyle()}</style>
      </head>
      <body>
      <div class='receipt'>
        <div class='paper'>
          <h1>Abonnement N°${data.number}</h1>
          <br/>
          <div>
            Crée le ${moment(data.created_at)
              .locale("fr", localization)
              .format("LLLL")}
          </div>
          <hr>
          <br/>
          <div class="col">
            <span class="col-6">Client</span>
            <span class="col-6 right">${
              data.user ? nullString(data.user.firstName).toLowerCase() : ""
            } ${
    data.user ? nullString(data.user.lastName).toUpperCase() : ""
  }</span>
          </div>
          <div class="col">
            <span class="col-12">Abonnement classique</span>
          </div>
          <div class="col">
            <span class="col-6">Montant</span>
            <span class="col-6 right">${data.amount}${currency}</span>
          </div>
          <br/>
      </div>
    </body>
    </html>
  `;
  return html;
};

// css style for the print layout

const style = () => `
* {
  padding:0;
  margin:0;
  box-sizing: border-box;
}
html{
  margin:0;
  padding:0;
}
body {
  display: flex;
  height: 100vh;
  margin: 0;
  justify-content: flex-start;
  align-items:flex-start;
  font: 16px/1.5 VT323, monospace;
  background: gray;
}

.receipt {
  width: 80mm;
  box-shadow: 0 0.2em 1em 0.2em rgba(0, 0, 0, 0.2);
  border: 1px dotted white;
  border-width: 1px 0;
}
.receipt .paper {
  padding: 3em 1.5em;
  background: white;
}
.receipt h1 {
  font-size: 150%;
  margin: 0;
}
.receipt h1,
.receipt .event {
  text-align: center;
  line-height: 1;
}
.center{
  align-self:center;
  text-align:center;
  display:flex;
  align-items:center;
  justify-content:center;
}

.receipt dl {
  display: flex;
  flex-wrap: wrap;
  margin: 0.1em 0 0;
}
.receipt dt{
  margin: 1%;
  width: 40%;
}
.receipt dd {
  width: 58%;
  text-align: right;
}

.receipt .total {
  margin: 0;
}
.receipt .total:before {
  content: '---------------------------------------';
  display: block;
  width: 100%;
  text-align: center;
}
`;
const invoiceStyle = () =>
  `
  :root{

    --width: 210mm;
    --gray:rgb(108,108,108);
    --black:rgb(65,65,65);
    --info:rgb(126,126,126);
    --border:rgb(223,227,233);
    --white:#fff;
    --title:rgb(52,70,78);
    --discount:rgb(249,249,249);
    }

    * {
    padding:0;
    margin:0;
    box-sizing: border-box;
    }

    html{
    margin:0;
    padding:0;
    }

    body {
    display: flex;
    height: 100vh;
    width:var(--width);
    margin: 0;
    align-items: flex-start;
    justify-content: center;
    font: 16px/1.5, monospace;
    background:white;
    font-family: sans-serif;
    }
    .col{
      display: flex;
      flex-wrap: wrap;
    }
    .col-6{
      margin: 0;
      width: 50%;
    }
    .col-4{
      margin: 0;
      width: 30%;
    }
    .col-2{
      margin: 0;
      width: 20%;
    }
    .right{
      text-align: right;
    }
    .paper {
      display:flex;
      flex-direction: column;
      padding: 2em;
      width:210mm;
      min-height: 100%;
      background: var(--white);
      color: var(--black);
    }
    .invoice-complex{
      font-size: 2.5em;
      font-style: italic;
      color: var(--title);
      padding-right: 0.2em;
      font-weight: bold;
    }
    .invoice-complex-info{
      color: var(--title);
    }
    .no-border-invoice td{
      border-color:transparent !important;
    }
    .invoice-title{
      background-color: #cffcfb;
      text-align: center;
      padding: 10px;
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 5px;
    }
    .invoice-info{
      font-size: 18px;
      color: var(--title);
      background-color: #cffcfb;
      padding: 10px 5px;
      margin: 10 0;
    }
    .invoice-client-info{
      padding: 10 0;
    }

    .table-items{
      margin-top: 15px;
      width:100%;
    }
    .table-total{
      margin-top: 20px;
      margin-left: 30px;
      padding-left: 20px;
    }
    .table-items th{
      text-align: left;
      font-size: 14px;
    }
    .table-items td,.table-total td{
      text-align: left;
      padding: 10px;
      font-size:14px;
    }
    table tr,th,td{
      border: 1px solid var(--border) ;
      padding: 5px;
    }
    table{
      border: 1px solid var(--border) ;
      border-collapse: collapse;
    }

    .table-items tr:first-child{
      background-color: #cffcfb;
    }

    .table-total td:nth-child(2){
      font-weight: 900;
    }

    .table-total td{
      padding: 10px !important;
    }

    .invoice-discount{
      display: flex;
      width: 100%;
      padding-top: 10px;
    }

    .table-total{
      flex: 1;
      width:50%;
    }
    .note{
      margin: 20px 0px;
    }
    .footer{
    }
    .invoice-footer{
      text-align : center;
      position: fixed;
      bottom: 2%;
      margin : 0 auto;
      width : 100%;
      font-size: 18px;
    }

    @media print{



    * {
    padding:0;
    margin:0;
    box-sizing: border-box;
    }

    html{
    margin:0;
    padding:0;
    }

    body {
    display: flex;
    height: 100vh;
    width: var(--width);
    margin: 0;
    align-items: flex-start;
    justify-content: flex-start;
    font: 16px/1.5, monospace;
    font-family: 'Montserrat', sans-serif;
    background:white;
    }

    .paper {
    display:flex;
    flex-direction: column;
    padding: 2em;
    width:210mm;
    min-width:210mm;
    min-height: 100%;
    background: var(--white);
    color: var(--black);
    }

    .invoice-header{
    display: flex;
    flex-wrap: wrap;
    }
    .invoice-logo{
    width: 50%;
    display: flex;
    align-items: center;
    }
    .invoice-header h3{
    font-size: 4em;
    font-family: sans-serif;
    font-style: italic;
    color: var(--title);
    padding-right: 0.2em;
    font-weight: bold;
    }
    .invoice-date{
    width: 50%;
    }
    .invoice-date-item{
    display: flex;
    justify-content: space-between;
    padding:4px;
    }
    .invoice-date-item span:first-child{
    font-weight: 900;
    }

    .invoice-status{
    display: flex;
    justify-content: space-between;
    border-radius:4px;
    border: 2px solid var(--black);
    margin:0;
    margin-top: 8px;
    height:44px;
    padding-right: 8px;
    }

    .invoice-status span{
              display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
    font-weight: 900;
    padding-right: 8px;
    font-size: 20px
    }
    .invoice-status span:first-child{
    background: var(--black);
    color: var(--white);
    height: 44px;
    text-align: center;
    border-radius: 4px;
    padding:2px;
    width: 40%;
    font-weight: bold;
    margin:auto 0 ;
    transform: translate(-2px,-2px);
    }

    .invoice-contact{
      width: 50%;
      margin-top: 10px;
      display: flex;
      margin-bottom: 2em;
    }
    .invoice-info{
      display: flex;
      flex-direction: column;
    }
    .table-items{
      width:100%;
    }
    .table-items th{
      text-align: left;
      color: var(--gray);
      font-size: 14px;
      padding: 4px 0;
    }
    .table-items td,.table-total td{
      text-align: left;
      border-top: 1px solid var(--border);
      padding: 10px;
      font-size:14px;
    }

    .table-items tr:last-child td{
      border-bottom: 1px solid var(--border)
    }

    .table-total td:first-child{
      min-width: 32px;
    }

    .table-total td:nth-child(2){
      font-weight: 900;
    }

    .table-total td{
      padding-top: 0 !important;
      border-top: none;
    }

    .invoice-discount{
      display: flex;
      width: 100%;
      padding-top: 10px;
    }

    .table-total{
      flex: 1;
      width:50%;
    }
    .with-border td{
      border-bottom: 1px solid var(--border)
    }

    .invoice-discount-items{
      display: flex;
      padding:10px 20px;
      margin-right: 10px;
      border-radius: 8px;
      align-self: center;
      flex-direction: column;
      background: var(--discount);
      width: 50%;
    }

    .invoice-discount-items span{
      padding-bottom: 4px;
      color:var(--black);
      font-size: 12px;
      font-weight: 600;
      border-bottom: 1px solid var(--border);
      margin-bottom: 4px;
    }

    .invoice-discount-content li{
      margin:10px !important;
      display: flex;
      justify-content: space-between;
      font-size:12px !important;
    }
    .invoice-discount-content li span{
      border:none;
      font-size:  12px;
      font-weight: 300;
    }

    .invoice-payments h3{
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .invoice-payments-content{
      display: flex;
    }
    .invoice-payments-content span{
      font-size: 14px ;
      font-weight: 900;
      color:var(--black);
      padding-right: 4px
    }
    .invoice-payments-items{
      display: flex;
      flex-direction: column;
    }
    .invoice-payments-items span{
    font-weight: 400
    }
    }
  `;

const concludeStyle = () => `
  *{
    padding:0;
    margin:0;
    box-sizing: border-box;
  }
  html{
    margin:0;
    padding:0;
  }
  body {
    display: flex;
    height: 100vh;
    margin: 0;
    justify-content: center;
    align-items: center;
    font: 16px/1.5 VT323, monospace;
    background: gray;
  }

  .receipt {
    width: 80mm;
    box-shadow: 0 0.2em 1em 0.2em rgba(0, 0, 0, 0.2);
    border: 1px dotted white;
    border-width: 1px 0;
  }
  .receipt .paper {
    padding: 3em 1.5em;
    background: white;
  }
  .receipt h1 {
    font-size: 150%;
    margin: 0;
  }
  .receipt h1,
  .receipt .event {
    text-align: center;
    line-height: 1;
  }

  .col{
    display: flex;
    flex-wrap: wrap;
  }
  .col-10{
    margin: 0;
    width: 80%;
  }
  .sub-users{
    margin-left:20px;
  }
  .col-8{
    margin: 0;
    width: 70%;
  }
  .col-6{
    margin: 0;
    width: 50%;
  }
  .col-4{
    margin: 0;
    width: 30%;
  }
  .col-2{
    margin: 0;
    width: 20%;
  }
  .col-1{
    margin: 0;
    width: 12%;
  }
  .right{
    text-align: right;
  }
  .border-top{
    margin-top: 10px;
    border-top: 1px dotted #ccc;
    padding : 10px 0px;
  }

  @media print {
    *{
    padding:0;
    margin:0;
    box-sizing: border-box;
  }
  html{
    margin:0;
    padding:0;
  }
  body {
    display: flex;
    height: 100vh;
    margin: 0;
    justify-content: flex-start;
    align-items: flex-start;
    font: 16px/1.5 VT323, monospace;
    background: transparent;
  }

  .receipt {
    width: 80mm;
    box-shadow: 0 0.2em 1em 0.2em rgba(0, 0, 0, 0.2);
    border: 1px dotted white;
    border-width: 1px 0;
  }
  .receipt .paper {
    padding: 3em 1.5em;
    background: white;
  }
  .receipt h1 {
    font-size: 150%;
    margin: 0;
  }
  .receipt h1,
  .receipt .event {
    text-align: center;
    line-height: 1;
  }

  .col{
    display: flex;
    flex-wrap: wrap;
  }
  .col-10{
    margin: 0;
    width: 80%;
  }
  .col-8{
    margin: 0;
    width: 70%;
  }
  .col-6{
    margin: 0;
    width: 50%;
  }
  .col-4{
    margin: 0;
    width: 30%;
  }
  .col-2{
    margin: 0;
    width: 20%;
  }
  .col-1{
    margin: 0;
    width: 12%;
  }
  .right{
    text-align: right;
  }
  .border-top{
    margin-top: 10px;
    border-top: 1px dotted #ccc;
    padding : 10px 0px;
  }
  `;
