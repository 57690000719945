import React from 'react';
import './ReservationCaisseModal.css';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { Creators as SubscribersCreators } from '../../../store/ducks/subscriber';
import {
  articleToReservationResponse,
  reservationRequest,
  resetReservationState,
} from '../../../helpers/reservationFormatting';
import ReservationModal from '../../ReservationModal/ReservationModal';
import moment from 'moment';
import localization from 'moment/locale/fr';
import { debounce } from '../../../helpers';
import { nullStringNumber } from '../../../helpers/formatting';

class ReservationCaisseModal extends React.Component {

  handleSubmit = debounce(async (isMatch) => {
    let reservationForm = { ...this.state.reservationForm };

    if (reservationForm.information.owner_id === null) {
      reservationForm.information.owner_id = this.props.user.id;
    }

    reservationForm.information.article_id = this.props.article.id;
    reservationForm.information.is_ticket = 1;
    let start = this.props.availableHours.filter(it => parseInt(it.split(':')[0]) % 24 === parseInt(reservationForm.information.start_hour.split(':')[0]));
    let oldDate = reservationForm.information.date;
    let date = reservationForm.information.date;
    if (start.length > 0 && parseInt(start[0].split(':')[0]) >= 24) {
      date = moment(date).add(1, 'day').format('YYYY-MM-DD');
    }

    reservationForm.information.date = date;

    // test if is before closing Time
    reservationForm.information.price = nullStringNumber(this.props.article?.total_price);
    reservationForm.competition.price_per_participant = nullStringNumber(reservationForm.competition.participants_nb) ?
      (nullStringNumber(this.props.article?.total_price) / nullStringNumber(reservationForm.competition.participants_nb)).toFixed(2) : 0;

    let data = reservationRequest({
      ...reservationForm,
      date: oldDate,
      isMatch,
    });
    // reset advance ticket status to 0
    if(data.reservation.advance_ticket) data.reservation.advance_ticket.status = 0;

    this.props.updateReservationCaisse({ data, article_reservation: this.props.article.id });
    if (this.props.currentTicket && this.props.currentTicket.hasOwnProperty('id') && this.props.currentTicket.id)
      await this.props.updateTicket({ ...this.props.currentTicket, useAdvance: true });
    else await this.props.storeTicket({ ...this.props.currentTicket, useAdvance: true});


  }, 100);

  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      ...resetReservationState(this.props.user.id),
    };
  }

  resetState = () => {
    this.setState({ ...resetReservationState(this.props.user.id) });
  };

  componentDidMount() {
    this.setState({ modalAdd: this.props.open });
    if (this.props.teams) {
      this.setState({ teams: this.props.teams });
    }


  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open !== prevProps.open) {
      if (this.props.article) {
        // let allowedHours = [];
        // let article = this.props.article;
        // let menusData = this.props.menusData;
        // let parent = menusData.filter(it => it.id === article.parent_id);
        // if (parent.length) {
        //   let child = parent[0].children.filter(it => it.id === article.child_id);
        //   if (child.length) {
        //     let hourType = child[0].hourTypes.filter(it => it.id === article.hour_type_id);
        //     if (hourType.length) allowedHours = hourType[0].available_hours;
        //   }
        // }
        this.setState({ modalAdd: this.props.open });
      }
    }

    if (this.props.article !== prevProps.article && this.props.article) {
      let reservationForm = articleToReservationResponse(this.state.reservationForm, this.props.article);
      this.setState({ reservationForm: { ...reservationForm } });
    }

    // clozse when modal when no erros has detected
    if (this.props.updated !== prevProps.updated && this.props.updated) {
      this.resetState();
      this.props.onClose();
    }

  }

  render() {
    let { open, t } = this.props;
    return (
      open ? <ReservationModal
        type={'caisse'}
        deleteArticleInReservation={this.props.deleteArticleInReservation}
        title={t('reservation') + ' ' + t('of') + ' ' +
        moment(this.state.date)
          .utc()
          .locale(i18n.language, localization)
          .format('dddd DD MMMM YYYY') + ' ' +
        (this.state.reservationForm.information.start_hour ? t('a').toLowerCase() + ' ' + this.state.reservationForm.information.start_hour : '')}
        state={this.state}
        setState={(obj) => this.setState(obj)}
        article={this.props.article}
        onClose={() => {
          this.resetState();
          this.props.onClose();
        }}
        t={t}
        onSubmit={this.handleSubmit}
      /> : null

    );
  }
}

ReservationModal.defaultProps = {
  open: false,
  onClose: () => {
  },
  currentTicket: null,
  article: null,
  reservation: null,
};

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
  searchPlayer: (page, size, search) => dispatch({ type: 'SEARCH_REQUEST_USER', payload: { page, size, search } }),
  store: (data) => dispatch({ type: 'STORE_CLIENT_REQUEST_USER', payload: { ...data, redirection: false } }),
  reload: (data) => dispatch(SubscribersCreators.indexRequestSubscriberDetail({ ...data })),
  editPlayer: (id) => dispatch({ type: 'EDIT_REQUEST_USER', payload: id }),
  updateReservationCaisse: (payload) => dispatch({ type: 'UPDATE_RESERVATION_RESPONSE', payload }),
  storeTicket: (data) => dispatch({ type: 'STORE_TICKET_REQUEST', payload: data }),
  updateTicket: (data) => dispatch({ type: 'UPDATE_TICKET_REQUEST', payload: data }),
});
const mapStateToProps = (state) => {
  return {
    currentNote: state.caisse.currentNote,
    user: state.auth.user,
    teams: state.team.teams,
    currentTicket: state.caisse.currentTicket,
    menusData: state.caisse.menusData,
    availableHours: state.reservation.availableHours,
    updated: state.reservation.updated,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationCaisseModal);



