import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';
import { getFormData, showFormData } from '../helpers/formDataHandler';

export default {
  subscriptionService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/subscriptions?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_user ? '&search_user=' + search.search_user : '') +
        (search && search.search_number ? '&search_number=' + search.search_number : '') +
        '&complex_id=' +
        localStorage.getItem('complex_id'),
      );
      return result.data;
    },
    indexDetail: async ({ page, size="", search }) => {
      const result = await getMethod(
        '/subscriptionDetails?page=' +
        page +
        ('&size=' + size ) +
        (search && search.search_subscription
          ? '&search_subscription=' + search.search_subscription
          : ''),
      );
      return result.data;
    },
    store: async (data) => {
      let fd = getFormData(data);
      showFormData(fd);
      return postMethod('/subscriptions', fd);
    },
    storeDetail: async (data) => {
      let formData = new FormData();
      formData.append('subscription_id', data.id);
      formData.append('date', data.date_detail);
      formData.append('amount', data.amount_detail);
      formData.append('label', data.label_detail);

      const result = await postMethod('/subscriptionDetails', formData);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/subscriptions/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/subscriptions/' + data.id, data);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/subscriptions/' + id);
      return result.data;
    },
  },
};
