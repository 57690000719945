import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';
import moment from 'moment';
import { TicketRequest } from '../helpers/caisseFormatting';

export default {
  caisseService: {
    //get data (parent+child+hourType+article)
    getData: async () => {
      const result = await getMethod('/parents/data/' + localStorage.getItem('complex_id'));
      return result.data;
    },

    /**
     * ticket
     */

    getTickets: async ({page, size, status = [],
        is_credit = 0, is_advance = 0, is_credit_account = 0, is_reload = 0, is_subscription, all = false,
        date = moment(new Date()).format('YYYY-MM-DD'), parent_id = null,external_list=null
      }) => {
      const result = await getMethod(
        '/tickets?complex_id=' + localStorage.getItem('complex_id') +
        //'&checkout_session_id='+localStorage.getItem('session_id') +
        (date ? '&date=' + date : '') +
        (page ? '&page=' + page : '') +
        (size ? '&size=' + size : '') +
        (external_list ? '&external_list=' + external_list : '') +
        (parent_id ? '&parent_id=' + parent_id : '') +
        '&status=[' +
        status.join(',') +
        ']' +
        (is_credit === 1 ? '&is_credit=' + is_credit : '') +
        (is_advance === 1 ? '&is_advance=' + is_advance : '') +
        (is_reload === 1 ? '&is_reload=' + is_reload : '') +
        (is_subscription === 1 ? '&is_subscription=' + is_subscription : '') +
        (is_credit_account === 1 ? '&is_credit_account=' + is_credit_account : '')+
        (all ? '&all=' + all : ''),
      );

      return result.data;
    },

    storeTicket: async (data) => {
      let response = TicketRequest({ ...data });
      const result = await postMethod('/tickets', response);
      return result.data;
    },

    updateTicket: async (data) => {
      let response = TicketRequest({ ...data });
      const result = await putMethod(
        '/tickets/' + data.id,
        response,
      );
      return result.data;
    },

    getTicket: async (id) => {
      const result = await getMethod(
        '/tickets/' + id,
      );
      return result.data;
    },

    destroyTicket: async (id) => {
      const result = await deleteMethod('/tickets/' + id);
      return result.data;
    },

    getLastTicketId: async () => {
      return getMethod('/ticket/reference/' + localStorage.getItem('complex_id'));
    },

    getCredit: async (id) => {
      const result = await getMethod('/tickets/' + id);
      return result.data;
    },

    checkCreditNumber: async (id) => {
      if (id) {
        let formData = new FormData();
        formData.append('number', id);
        formData.append('complex_id', localStorage.getItem('complex_id'));
        const result = await postMethod('/checkCredit', formData);
        return result.data;
      }
    },

    checkAdvanceNumber: async ({id , reservation_id = null}) => {
      if (id) {
        let formData = new FormData();
        formData.append('number', id);
        formData.append('reservation_id', reservation_id);
        formData.append('complex_id', localStorage.getItem('complex_id'));
        const result = await postMethod('/checkAdvance', formData);
        return result.data;
      }

    },

    /**
     *
     */

    storeInvoice: (data) => {
      return postMethod('/invoices', data);
    },

    updateInvoice: (data) => {
      return putMethod('/invoices', data);
    },
    getInvoices: (data = { page: 1, size: 10, user_id: '' }) => {
      return getMethod(
        '/invoices?complex_id=' +
        localStorage.getItem('complex_id') +
        '&user_id=' +
        data.user_id +
        '&page=' +
        data.page +
        '&size=' +
        data.size,
      );
    },
    /**
     *
     */
  },
};
