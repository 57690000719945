import { valeur } from "@/pages/CaissePage/CaissePage";
import {
  cumulAmountFromArticlePrice,
  cumulAmountHTFromArticles,
  cumulArticlePayedAmountFromPaymentArticle, cumulDiscountArticlesFromParticipant,
  cumulDiscountsFromArticle,
  cumulPayedAmountFromPaymentTypes,
  cumulPaymentTypesFromArticle,
  cumulTotalDiscountFromDiscounts,
  cumulVatAmountFromArticles,
  getArticleDiscount,
  getIndexOf,
  getLastOccurrenceByIdInArticle,
  getTotalArticleNb,
  setArticleStatus
} from "../ticket";
import { parseInt } from "lodash";
import { getActivityAmount } from "../caisseFormatting";
import { exactDivision } from "../operation";
import { getHT } from "./tvaCalcul";


export const defaultNote = () => ({
  note_index: null,
  user_id: null,
  user_index: null,
  name: null,
  amount: 0,
  payed_amount: 0,
  discount: 0,
  articles_nb: 0,
  articles: [],
  participants: [],
  payments: {
    types: [],
    articles: [],
    participants: []
  },
  discountTypes: {
    discounts: [],
    articles: [],
    participants: []
  },
  status: 0
});

/**
 * note request formatting
 * @param {*} currentTicket
 * @returns
 */
export const requestNoteFormatting = (currentTicket) => {
  let notes = [];
  if (currentTicket.notes && currentTicket.notes.length) {
    currentTicket.notes.forEach(n => {
      let note = { ...n };
      let amount = getAmountNote(currentTicket, note.note_index);
      let amount_ht = getAmountHTNote(currentTicket, note.note_index);
      note.amount = amount;
      note.amount_ht = amount_ht;
      note.vat_amount = (parseFloat(amount) - parseFloat(amount_ht)).toFixed(3);
      note.articles_nb = getArticleNbNote(currentTicket, note.note_index);
      note.payed_amount = getPayedAmountNote(currentTicket, note.note_index);
      note.discount = getNoteDiscount(currentTicket, note.note_index);

      if (((parseFloat(note.amount) - parseFloat(note.discount)) > parseFloat(note.payed_amount))) {
        note.status = 0; //not totally payed
      } else {
        note.status = 1; //totally payed
      }
      notes.push(note);
    });
  }
  // notes = notes.filter(itm => itm.articles_nb > 0);
  return notes;
};


/**
 *
 * @param {*} articles
 * @param {*} article_id
 * @returns
 */
export const checkArticleNoteExist = (articles, article_id) => {
  let value = false;
  if (articles.length) {
    articles = articles.map((itm) => itm.article_id);
    if (articles.includes(article_id)) {
      value = true;
    }
  }
  return value;

};

/**
 * note division (transfert discount from ticket to note if exist => use discount of ticket)
 * @param {*} currentTicket
 * @param {*} article_id
 * @param {*} note_index
 * @returns
 */
export const updateTicketDiscount = (currentTicket, article_id, note_index) => {
  if (getArticleDiscount(currentTicket, article_id) === getArticleDiscount(currentTicket, article_id, note_index)) {
    currentTicket.discountTypes.articles.forEach(it => {
      if (it.article_id.toString() === article_id.toString()) {
        it.note_index = note_index;
      }

    });
  }
  //get part of discount
  else {
    let note_discount = [];
    currentTicket.discountTypes.articles.forEach(article_discount => {
      if (article_discount.article_id.toString() === article_id.toString() && !article_discount.note_index) {
        note_discount.push({
          ...article_discount,
          amount: parseFloat(getArticleDiscount(currentTicket, article_id, note_index)).toFixed(2),
          note_index: note_index
        });
        article_discount.amount = (parseFloat(article_discount.amount) - parseFloat(getArticleDiscount(currentTicket, article_id, note_index))).toFixed(2);
      }
    });
    currentTicket.discountTypes.articles = [...currentTicket.discountTypes.articles, ...note_discount];
  }
  return currentTicket;
};

/**
 * note division (separate article related to note and article not related to note) =>two rows
 * @param {*} currentTicket
 * @param {*} id
 * @param {*} nb_occ
 * @param {*} note_index
 * @param {*} is_activity
 * @returns
 */
export const updateTicketArticle = (currentTicket, id, nb_occ, note_index, is_activity = false) => {
  let principalArticle = currentTicket.articles.filter(itm => itm.id.toString() === id.toString());
  //check if there's an already article with same note_index + status!= payed && same part of id
  let secondaryArticle = currentTicket
    .articles
    .filter(itm => itm.status !== "payed" && itm.note_index === note_index && itm.id.toString().split(valeur)[0] === id.toString().split(valeur)[0]);
  let totalArticleNb = principalArticle.length > 0 ? principalArticle[0].article_nb : 0;

  //get total article
  if (nb_occ === totalArticleNb && secondaryArticle.length === 0 || is_activity) {

    let total = 0;
    let totalWithoutstatus = 0;
    let partIds = [];
    currentTicket.participants.forEach(part => {
      if (part.article_id.toString() === id.toString()) {
        total++;
        if (!part.status) {
          if (!part.note_index) {
            partIds.push(part.user_index);
            part.note_index = note_index;
            totalWithoutstatus++;
          } else if (part.note_index && part.note_index === note_index)
            totalWithoutstatus++;
        }
      }
    });
    currentTicket.articles.forEach(it => {
      if (it.id.toString() === id.toString()) {
        if (total === totalWithoutstatus)
          it.note_index = note_index;
        it.amount = nb_occ * it.total_price;
      }
    });

    //update discount articles
    currentTicket.discountTypes.articles.forEach(it => {
      if (it.article_id.toString() === id.toString() && ((is_activity && total === totalWithoutstatus) || !is_activity)) {
        it.note_index = note_index;
      }
    });

    let discountsParticipants = [];

    //update discount participants
    currentTicket.discountTypes.participants.forEach(it => {
      if (partIds.includes(it.user_index)) {
        it.note_index = note_index;
        discountsParticipants.push(it);
      }
    });

    let articleDiscount = currentTicket.discountTypes.articles.filter(itm => itm.article_id !== id);
    currentTicket.discountTypes.articles = [...articleDiscount, ...cumulDiscountArticlesFromParticipant(currentTicket, id)];

    return { ticket: currentTicket };

  } 
  //get part of article
  else {
    let articleRowsToAdd = [];
    let discountArticleRowsToAdd = [];
    let isFinished = false;
    let i = getLastOccurrenceByIdInArticle(currentTicket.articles, id) + 1;
    currentTicket.articles.forEach(item => {
      if (secondaryArticle.length > 0) {
        if (!isFinished && item.id.toString() === secondaryArticle[0].id) {
          item.article_nb = parseInt(item.article_nb) + parseInt(nb_occ);
          item.amount = parseInt(item.article_nb) * parseFloat(item.total_price);
          isFinished = true;
        }
        if (item.id.toString() === id.toString() && !item.note_index) {
          item.article_nb = parseInt(item.article_nb) - parseInt(nb_occ);
          item.amount = parseInt(item.article_nb) * parseFloat(item.total_price);
          item.status = setArticleStatus({ ...currentTicket }, item);
        }
      } else {
        if (item.id.toString() === id.toString() && !item.note_index) {
          articleRowsToAdd.push({
            ...item,
            id: id.toString().split("-")[0] + valeur + i,
            article_nb: parseInt(nb_occ),
            note_index: note_index,
            status: null
          });
          item.article_nb = parseInt(item.article_nb) - parseInt(nb_occ);
          item.amount = parseInt(item.article_nb) * parseFloat(item.total_price);
          item.status = setArticleStatus({ ...currentTicket }, item);
        }
      }

    });
    currentTicket.articles = currentTicket.articles.filter(it => it.article_nb > 0);
    currentTicket.articles = [...currentTicket.articles, ...articleRowsToAdd];

    // fill discount article to add
    currentTicket.discountTypes.articles.forEach(it => {
      let foundDiscount = false;
      if (it.article_id === id) {
        
        let totalAmount = (parseFloat(it.amount) / totalArticleNb).toFixed(2);

        //first item get excess of division (exact division)
        let excess = exactDivision(it.amount,totalAmount,totalArticleNb);
        it.amount = ((parseFloat(totalAmount) * (totalArticleNb - nb_occ)) + parseFloat(excess)).toFixed(2);

        if (secondaryArticle.length > 0){
          currentTicket.discountTypes.articles.forEach(itm => {
            if (itm.article_id === secondaryArticle[0].id && 
              itm.note_index === note_index && it.discount_id === itm.discount_id) {
              itm.amount = parseFloat(itm.amount) + parseFloat((totalAmount * nb_occ).toFixed(2));
              foundDiscount = true;
            }
          });
        }
        if (!foundDiscount){
          //fill discountArticleRowsToAdd
          discountArticleRowsToAdd.push({
            ...it,
            amount: (totalAmount * nb_occ).toFixed(2),
            article_id: secondaryArticle.length > 0 ? secondaryArticle[0].id : id.toString().split("-")[0] + "-" + i,
            note_index
          });
        }
      }
    });

    currentTicket.discountTypes.articles = [...currentTicket.discountTypes.articles, ...discountArticleRowsToAdd];
    currentTicket.discountTypes.articles = currentTicket.discountTypes.articles.filter(it => parseFloat(it.amount) > 0);

    return {
      ticket: currentTicket,
      new_article_id: secondaryArticle.length > 0 ? secondaryArticle[0].id : id.toString().split("-")[0] + "-" + i
    };
  }
};

/**
 * Affect discount to participant note
 * @param {*} currentTicket
 * @param {*} discountsParticipants
 */
export const affectDiscountParticipants = (currentTicket, article_id) => {
  //discount article to add
  let discountsArticles = [];
  //create new discount for activity article
  // discountsParticipants.forEach(it => {
  //   currentTicket.discountTypes.articles.forEach(art => {
  //     if (art.article_id === it.article_id && art.discount_id === it.discount_id) {
  //       art.amount = (parseFloat(art.amount) - (parseFloat(it.amount))).toFixed(2);
  //       // this will check for duplicated in discountArticles if there s we add it else create new one
  //       let index = discountsArticles.reduce(((previousValue, currentValue, currentIndex) => {
  //         if (currentValue.discount_id === art.discount_id) return currentIndex;
  //         else return previousValue;
  //       }), -1);
  //       if (index === -1)
  //         discountsArticles.push({ ...art, note_index: it.note_index, amount: it.amount });
  //       else
  //         discountsArticles[index].amount = (parseFloat(discountsArticles[index].amount) + parseFloat(it.amount)).toFixed(2);
  //     }
  //   });
  // });

  //filter empty amount
  currentTicket.discountTypes.articles = cumulDiscountArticlesFromParticipant(currentTicket, article_id);

};

/**
 * add new article to note
 * @param {*} ticket
 * @param {*} article
 * @param {*} nb_occ
 * @param {*} note_index
 * @returns
 */
export const addArticleNote = (ticket, article, nb_occ, note_index) => {
  let notes = [...ticket.notes];
  if (notes.length === 0) return;

  //update ticket article (separate article related to note and article not related to note)
  let is_activity = article.parent?.is_activity === 1 && article.child?.is_res === 1;
  let ticketUpdated = updateTicketArticle(ticket, article.id, nb_occ, note_index, is_activity);
  ticket = ticketUpdated.ticket;
  let new_article_id = ticketUpdated.new_article_id;
  return { ticket, old_article_id: article.id, new_article_id };
};

/**
 * unselect participant => cancel note
 * @param {*} ticket
 * @param {*} note_index
 * @returns
 */
export const cancelNote = (ticket, note_index) => {
  if (ticket.notes.length > 0) {
    ticket.notes.forEach((note, i) => {
      if ((note_index === null && (i === ticket.notes.length - 1)) || (note_index && (note.note_index === note_index))) {
        let defaultNoteData = { ...defaultNote() };

        defaultNoteData.participants = [];
        defaultNoteData.articles = [];

        defaultNoteData.payments.articles = [];
        defaultNoteData.payments.participants = [];
        defaultNoteData.payments.types = [];

        defaultNoteData.discountTypes.discounts = [];
        defaultNoteData.discountTypes.articles = [];
        defaultNoteData.discountTypes.participants = [];

        ticket.notes[i] = defaultNoteData;
      }
    });
  }
  if (ticket.notes.length === 0) {
    ticket.noteInProgress = false;
  }
  return ticket;
};

/**
 *
 * @param ticket
 * @param note_index
 * @returns {*}
 */
export const removeArticleFromNote = (ticket, article, note_index) => {
  if (ticket.notes.length > 0) {
    ticket.notes.forEach((note, i) => {
      if ((note.note_index === note_index) || (note_index === null && i === ticket.notes.length - 1)) {
        let selectedNote = ticket.notes[i];
        selectedNote.articles = selectedNote.articles.filter(it => it.article_id.toString() !== article.article_id.toString());
        selectedNote.participants = selectedNote.participants.filter(it => it.article_id.toString() !== article.article_id.toString());
        let amount = 0;
        let nb = 0;
        selectedNote.articles.forEach(it => {
          amount += parseFloat(it.amount);
          nb += parseInt(it.article_nb);
        });
        selectedNote.amount = amount;
        selectedNote.articles_nb = nb;
        ticket.notes[i] = selectedNote;
      }
    });
  }

  return ticket;
};


/**
 * get current note
 * @param {*} ticket
 * @param {*} note_index
 * @returns
 */
export const getCurrentNote = (ticket, note_index) => {
  if (ticket && ticket.noteInProgress) {
    if (note_index) {
      let notes = ticket.notes.filter(itm => itm.note_index === note_index);
      if (notes.length > 0) {
        return notes[0];
      }
    }
  }
  return null;
};

/**
 *
 * @param ticket
 * @param note_index
 * @returns {null|*}
 */
export const getCurrentNoteIndex = (ticket, note_index) => {
  let index = 0;
  if (ticket) {
    if (note_index) {
      ticket.notes.forEach((itm, i) => {
        if (itm.note_index === note_index) index = i;
      });
    }
  }
  return index;
};

/**
 * get payed amount of articles that has note_index
 * @param ticket
 * @param note_index
 * @returns {string}
 */
export const getAmountNote = (ticket, note_index = null) => {
  let value = 0;
  if (!ticket) return "0";
  ticket.articles.forEach((item, index) => {
    if (item.note_index === note_index && ((item.parent?.is_activity === 0) || !item.is_activity)) {
      value += parseFloat(item.amount);
    }
  });

  ticket.participants.forEach((item, index) => {
    if (item.note_index === note_index) {
      value += (parseFloat(item.amount));
    }
  });
  return value.toFixed(2);
};

/**
 * get payed amount of articles that has note_index
 * @param ticket
 * @param note_index
 * @returns {number}
 */

export const getAmountHTNote = (ticket, note_index = null) => {
  let value = 0;

  ticket.articles.forEach(it => {
    if ((it.parent && it.parent.is_activity === 1) || it.is_activity === 1) {
      ticket.participants.forEach(part => {
        if (part.article_id === it.article_id && note_index === part.note_index) {
          value = parseFloat(value) + parseFloat(part.amount_ht);
        }
      });
    } else if (it.note_index === note_index) {
      value = parseFloat(value) + parseFloat(it.amount_ht);
    }

  });
  return parseFloat(value).toFixed(3);
};

/**
 * get payed amount of articles that has note_index
 * @param ticket
 * @param note_index
 * @returns {string}
 */
export const getPayedAmountNote = (ticket, note_index = null) => {
  let value = 0;
  ticket.payments.articles.forEach((item, index) => {
    if (item.note_index === note_index) {
      value = parseFloat(value) + parseFloat(item.amount);
    }
  });
  return parseFloat(value).toFixed(2);
};

/**
 *
 * @param {*} ticket
 * @param {*} note_index
 * @returns
 */
export const getNoteDiscount = (ticket, note_index) => {
  let discount = 0;
  //let currentNote = getCurrentNote(ticket, note_index);
  ticket.discountTypes.articles.forEach((item, index) => {
    if (item.note_index === note_index)
      discount = parseFloat(discount) + parseFloat(item.amount);
  });
  return parseFloat(discount).toFixed(2);
};

/**
 *
 * @param {*} ticket
 * @param {*} note_index
 * @returns
 */
export const getArticleNbNote = (ticket, note_index) => {
  let nb = 0;
  ticket.articles.forEach(it => {
    if (it.note_index === note_index || (((it.parent && it.parent.is_activity === 1) || it.is_activity === 1) && hasParticipantsNote(ticket, it.article_id, note_index))) {
      nb++;
    }
  });
  return nb;
};

/**
 *
 * @param {*} ticket
 * @param {*} article_id
 * @returns
 */
export const getPayedAmountArticleNote = (ticket, article_id) => {
  let value = 0;
  ticket.payments.articles.forEach((item, index) => {
    if (item.article_id.toString() === article_id.toString()) {
      value = parseFloat(value) + parseFloat(item.amount);
    }
  });
  return value;
};

/**
 * return article of delete note to ticket
 * @param {*} ticket
 * @param {*} note_index
 * @returns
 */
export const returnArticleNoteToTicket = (ticket, note_index) => {
  let articlesWithNote = ticket.articles.filter(it =>
    it.note_index === note_index || (it.parent && it.parent.is_activity === 1 && hasParticipantsNote(ticket, it.article_id, note_index))
  );
  if (articlesWithNote.length) {
    let articlesWithoutNote = ticket.articles.filter(it => it.note_index !== note_index && !(it.parent && it.parent.is_activity === 1 && hasParticipantsNote(ticket, it.article_id, note_index)));

    let addedArticles = [];
    //we add the articleWithNote to articles
    articlesWithNote.forEach(it => {
      let finished = false;
      articlesWithoutNote.forEach(itm => {
        if (!finished && itm.article_id.toString() === it.article_id.toString() && !itm.note_index && itm.status !== "payed") {
          //not activity
          if (!((itm.parent && itm.parent.is_activity === 1) || itm.is_activity === 1)) {
            itm.article_nb = parseInt(itm.article_nb) + parseInt(it.article_nb);
            itm.amount = itm.total_price * parseInt(it.article_nb);
          }

          finished = true;
        }
      });
      // if (!finished && !((it.parent && it.parent.is_activity === 1) || it.is_activity === 1)) {
      if (!finished) {
        addedArticles.push({
          ...it,
          note_index: null,
          status: "",
          payed_amount: 0
        });
      }
    });

    ticket.articles = [...articlesWithoutNote, ...addedArticles];
    let articleIds = [];
    //activity case
    ticket.participants.forEach(itm => {
      if (itm.note_index === note_index) {
        articleIds.push(itm.article_id);
        itm.note_index = null;
        itm.status = null;
        itm.payed_amount = 0;
      }
    });

    // clear articles
    ticket.articles.forEach(itm => {
      if (articleIds.includes(itm.id)) {
        itm.note_index = null;
      }
    });
    // clear payments without note_index
    ticket.payments.articles = ticket.payments.articles.filter(it => it.note_index !== note_index);
    ticket.payments.participants = ticket.payments.participants.filter(it => it.note_index !== note_index);
    ticket.payments.types = cumulPaymentTypesFromArticle(ticket);

    ticket.articles.forEach(item => {
      item.payed_amount = cumulArticlePayedAmountFromPaymentArticle({ ...ticket }, item.id);
      item.status = setArticleStatus({ ...ticket }, item);
    });

    // clear discounts without note_index

    let partDiscount = ticket.discountTypes.participants.filter(it => it.note_index !== note_index);
    ticket.discountTypes.participants = partDiscount;
    let articleDiscount = ticket.discountTypes.articles.filter(it => it.note_index !== note_index);
    // articleDiscount = [...articleDiscount, ...cumulDiscountArticlesFromParticipant(ticket, this.props.article)];
    ticket.discountTypes.articles = articleDiscount;

    ticket.discountTypes.discounts = cumulDiscountsFromArticle(ticket);


    ticket.amount = cumulAmountFromArticlePrice(ticket);
    ticket.articles_nb = getTotalArticleNb(ticket);
    ticket.payed_amount = cumulPayedAmountFromPaymentTypes(ticket);
    ticket.discount = cumulTotalDiscountFromDiscounts(ticket);

  }
  return ticket;


};

/**
 *
 * @param {*} ticket
 * @param {*} article_id
 * @param {*} note_index
 * @returns
 */
export const hasParticipantsNote = (ticket, article_id, note_index = null) => {
  let result = false;
  if (ticket.participants) {
    for (const it of ticket.participants) {
      if (it.article_id.toString() === article_id.toString() && it.note_index === note_index) {
        result = true;
      }
    }
  }
  return result;
};

export const activityLinkedToNote = (ticket, article_id) => {
  let result = false;
  if (ticket.participants) {
    for (let it of ticket.participants) {
      if (it.article_id.toString() === article_id.toString() && it.note_index) {
        return true;
      }
    }
  }
  return result;

};
/**
 *
 * @param {*} ticket
 * @param {*} article_id
 * @param {*} note_index
 * @returns
 */
export const hasAllParticipantsByNote = (ticket, article_id, note_index = null) => {
  let total = 0;
  let totalWithNote = 0;

  for (const it of ticket.participants) {
    if (it.article_id.toString() === article_id.toString()) {
      total++;
      if (note_index) {
        if (it.note_index === note_index)
          totalWithNote++;
      } else {
        if (it.note_index === null)
          totalWithNote++;
      }
    }
  }

  return total === totalWithNote;
};

/**
 * check if the part of activity linked to note is totally payed or not
 * @param {*} ticket
 * @param {*} article_id
 * @param {*} note_index
 * @returns
 */
export const allParticipantsOfNotePayed = (ticket, article_id, note_index = null) => {
  let result = true;
  for (const it of ticket.participants) {
    if (it.article_id.toString() === article_id.toString() && it.note_index === note_index) {
      if (it.status !== "payed") {
        result = false;
      }
    }
  }

  return result;
};


/**
 * convertTicketToNote convert ticket with note to print in case of currentNote
 * @param {*} ticket
 * @param {*} note_index
 * @returns
 */
export const convertTicketToNote = (ticket, note_index) => {
  let currentTicket = JSON.parse(JSON.stringify(ticket));

  let currentNote = currentTicket.notes.filter(itm => itm.note_index === note_index);
  currentNote = currentNote[0];
  if (currentNote) {

    currentNote.payments = {};
    currentNote.discountTypes = {};
    currentNote.articles = [];
    currentNote.participants = [];

    // clear payments without note_index
    currentNote.payments.participants = currentTicket.payments.participants.filter(it => it.note_index === note_index);
    currentNote.payments.articles = currentTicket.payments.articles.filter(it => it.note_index === note_index);
    currentNote.payments.types = cumulPaymentTypesFromArticle(currentNote);

    // clear discounts without note_index
    let articleDiscount = currentTicket.discountTypes.articles.filter(it => it.note_index === note_index);
    let partDiscount = currentTicket.discountTypes.participants.filter(it => it.note_index === note_index);

    currentNote.discountTypes.articles = articleDiscount;
    currentNote.discountTypes.participants = partDiscount;
    currentNote.discountTypes.discounts = cumulDiscountsFromArticle(currentNote);

    currentNote.articles = currentTicket.articles.filter(it => (it.note_index === note_index || (it.parent && it.parent.is_activity === 1 && hasParticipantsNote(currentTicket, it.article_id, note_index))));
    currentNote.participants = currentTicket.participants.filter(it => (it.note_index === note_index));

    currentNote.articles.forEach(item => {
      if (item.parent && item.parent.is_activity === 1) {
        let activity_amount = parseFloat(getActivityAmount(currentNote, item.id, note_index));
        item.amount = activity_amount;
        item.total_price = activity_amount;
        item.amount_ht = getHT(activity_amount, item.vat.value);
        item.vat_amount = (activity_amount - parseFloat(getHT(activity_amount, item.vat.value))).toFixed(3);
      }
      item.payed_amount = cumulArticlePayedAmountFromPaymentArticle(currentNote, item.id);
      item.status = setArticleStatus(currentNote, item);
    });


    currentNote.amount = cumulAmountFromArticlePrice(currentNote);
    currentNote.amount_ht = cumulAmountHTFromArticles(currentNote);
    currentNote.vat_amount = cumulVatAmountFromArticles(currentNote);
    currentNote.articles_nb = getTotalArticleNb(currentNote);
    currentNote.payed_amount = cumulPayedAmountFromPaymentTypes(currentNote);
    currentNote.discount = cumulTotalDiscountFromDiscounts(currentNote);

    currentNote.date = currentTicket.date;


    return { ...currentNote };
  }

};

/**
 * check if note is totally payed or not
 * @param {*} note
 */
export const noteIsTotallyPayed = (note) => {
  let value = false;
  if (parseFloat(note.amount) - parseFloat(note.discount) <= parseFloat(note.payed_amount)) {
    value = true;
  }
  return value;
};

/**
 *  return last index of the note
 * @param notes
 */
export const getLastIndexNote = (notes) => {
  let lastNote = 0;

  if (notes.length > 0) {
    let lastNoteIndex = notes[notes.length - 1].note_index;
    if (lastNoteIndex && lastNoteIndex.split(valeur)) {
      let arr = lastNoteIndex.split(valeur);
      if (arr.length === 2) {
        lastNote = parseInt(arr[1]) + 1;
      }
    }
  }

  return lastNote;
};

/**
 * check if note has article
 * @param ticket
 * @param note_index
 */
export const noteHasArticles = (ticket, note_index) => {
  for (let i = 0; i < ticket.articles.length; i++) {
    let it = ticket.articles[i];
    if (it.note_index === note_index) return true;
  }

  for (let i = 0; i < ticket.participants.length; i++) {
    let it = ticket.participants[i];
    if (it.note_index === note_index) return true;
  }

  return false;
};

export const removeParticipantFromArticleNote = (ticket, article_id, note_index) => {
  ticket.participants.forEach(it => {
    if (it.note_index === note_index && it.article_id === article_id) {
      it.note_index = null;
      it.payed_amount = 0;
    }
  });

  //discounts
  ticket.discountTypes.participants = ticket.discountTypes.participants.filter(it => !(it.note_index === note_index && it.article_id.toString() === article_id.toString()));
  ticket.discountTypes.articles = ticket.discountTypes.articles.filter(it => !(it.note_index === note_index && it.article_id.toString() === article_id.toString()));
  ticket.discountTypes.discounts = cumulDiscountsFromArticle(ticket);

  //payments
  ticket.payments.participants.filter(it => !(it.note_index === note_index && it.article_id === article_id));
  ticket.payments.articles.filter(it => !(it.note_index === note_index && it.article_id === article_id));
  ticket.payments.types = cumulPaymentTypesFromArticle(ticket);

  ticket.articles.forEach(it => {
    if (it.id === article_id) {
      it.payed_amount = cumulPayedAmountFromPaymentTypes(ticket);
      if (it.note_index === note_index) {
        it.note_index = null;
      }
    }
  });

  ticket.amount = cumulAmountFromArticlePrice(ticket);
  ticket.discount = cumulTotalDiscountFromDiscounts(ticket);

  // change name of note after delete activity
  let currentNote = getCurrentNote(ticket, note_index);
  let currentIndex = getCurrentNoteIndex(ticket, note_index);
  let user_index = ticket.notes[currentIndex].user_index;
  if (currentNote && currentNote.user_index === user_index) {
    ticket.notes = [...ticket.notes];
    // reset name
    ticket.notes[currentIndex].name = "";
    ticket.notes[currentIndex].user_index = null;
    ticket.notes[currentIndex].user_id = null;
  }
  return ticket;
};

export const getPaymentNoteFromTicket = (articlePayment, note_index) => {
  let result = [];
  articlePayment = articlePayment.filter(item => item.note_index === note_index);
  if (articlePayment.length) {
    articlePayment.map((article_payment, i) => {
      let index = getIndexOf(
        result,
        (it) => it.payment_type_id.toString() === article_payment.payment_type_id.toString()
      );
      if (index === -1) {
        result.push({
          payment_type_id: article_payment.payment_type_id,
          amount: parseFloat(article_payment.amount)
        });
      } else {
        result[index].amount = (
          parseFloat(result[index].amount) + parseFloat(article_payment.amount)
        ).toFixed(2);
      }
    });
  }
  return result;
};

export const refreshNote = (currentTicket) => {
  let notes = currentTicket.notes;
  if(notes.length){
    notes = notes.map(note => {
      return {
        ...note,
        amount : getAmountNote(currentTicket, note.note_index),
        articles_nb : getArticleNbNote(currentTicket, note.note_index),
        discount : getNoteDiscount(currentTicket, note.note_index)
      }
    });
    notes = notes.filter(it => it.articles_nb > 0)
  }
  return notes;
}