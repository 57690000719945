import React from "react";
import { Dropdown, Input, Label } from "semantic-ui-react";
import { phoneNumber, reversePhoneNumber } from "../../helpers/formatting";
import { DEFAULT_COUNTRY_CODE, optionsForMobile } from "../../utils/countryCode";
import "./PhoneInput.css";
import { useTranslation } from "react-i18next";

const PhoneInput = (props) => {
  const [t] = useTranslation();
  return (
    <>
      <Input
        {...{ ...props, label: null }}
        onChange={(e) => {
          // e.target.value = e.target.value.trimStart();
          let value = e.target.value.split(" ").join("");
          // 11 11
          if (reversePhoneNumber(value).length > 10) return;

          let hasDot = value.includes(".");
          if ((!isNaN(value) || value === "") && !hasDot) {
            e.target.value = reversePhoneNumber(e.target.value);
            props.onChange(e,
              {
                target: {
                  parent: props.name,
                  name: "country_code",
                  value: props.countrycode
                }
              });
          }
        }}
        max={10}
        type={"tel"}
        value={phoneNumber(props.value)}
        label={<Dropdown
          scrolling={true}
          compact={true}
          autoComplete={"off"}
          value={props.countrycode}
          onChange={(event, data) => {
            props.onChange({
                target: {
                  name: props.name,
                  value: reversePhoneNumber(props.value)
                }
              }, {
                target: {
                  parent: props.name,
                  name: "country_code",
                  value: data.value ? data.value : data.defaultValue
                }
              }
            );
          }}
          options={optionsForMobile}/>}
        labelPosition='left'
        error={props.error || reversePhoneNumber(props.value).length > 10}
        errormsg={(props.error) ? props.errormsg : { content: t("mobile_between_8_10") }}
      />
      {(props.errormsg && props.error) || (reversePhoneNumber(props.value).length > 10) ? (
        props.errormsg && props.errormsg?.content !== "" ? (
          <Label prompt pointing>
            {props.errormsg.content}
          </Label>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};

PhoneInput.defaultProps = {
  countrycode: DEFAULT_COUNTRY_CODE
};

export default PhoneInput;
