import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import reportingService from '../../services/reportingService';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingReporting: ['data'],

  getReportingRequest: ['payload'],
  getReportingResponse: ['data'],

  turnoverReportingRequest: ['payload'],
  turnoverReportingResponse: ['data'],

  reservationReportingRequest: ['payload'],
  reservationReportingResponse: ['data'],

  saleReportingRequest: ['payload'],
  saleReportingResponse: ['data'],

  subscriptionReportingRequest: ['payload'],
  subscriptionReportingResponse: ['data'],

  occupancyRateReportingRequest: ['payload'],
  occupancyRateReportingResponse: ['data'],

});

// Initial state
const INITIAL_STATE = {
  monthArray: [],

  //report
  report: null,


  //turnover - CA
  avgTicketsByMonth: 0,
  ticketsChart: [],
  ticketsByActivity: [],
  ticketsCA: 0,
  ticketsByWeek: [],

  //reservation
  totalRes: 0,
  avgResByMonth: 0,
  resChart: [],
  reservationByActivity: [],
  ca: 0,
  reservationByWeek: [],

  //sales
  avgSalesByMonth: 0,
  salesChart: [],
  salesByPaymentType: [],
  salesNb: 0,
  salesByWeek: [],

  //subscription
  totalSubscription: 0,
  totalClassicSubscription: 0,
  totalPrepaidSubscription: 0,
  subscriptionChart: [],
  subscriptionBySubscriberNb: [],
  subscriptionCA: 0,
  subscriptionByWeek: [],

  //occupancy rate
  terrainsDisponibility: 0,
  terrainsOccupancy: 0,
  occupancyRate: 0,
  occupancyRateChart: [],
  occupancyRateByActivity: [],
  occupancyRateByWeek: [],

  loading: false,
};

function* turnover(action) {
  try {
    yield put(Creators.loadingReporting({ loading: true, monthArray: []}));
    const data = yield call(reportingService.reportingService.turnover, action.payload);
    yield put(Creators.turnoverReportingResponse(data));
    yield put(Creators.loadingReporting({ loading: false }));
  } catch (e) {
    //
  }
}

function* reservation(action) {
  try {
    yield put(Creators.loadingReporting({ loading: true, monthArray: []}));
    const data = yield call(reportingService.reportingService.reservations, action.payload);
    yield put(Creators.reservationReportingResponse(data));
    yield put(Creators.loadingReporting({ loading: false }));
  } catch (e) {
    //
  }
}

function* sale(action) {
  try {
    yield put(Creators.loadingReporting({ loading: true, monthArray: [] }));
    const data = yield call(reportingService.reportingService.sales, action.payload);
    yield put(Creators.saleReportingResponse(data));
    yield put(Creators.loadingReporting({ loading: false, monthArray: [] }));
  } catch (e) {
    //
  }
}

function* subscription(action) {
  try {
    yield put(Creators.loadingReporting({ loading: true, monthArray: [] }));
    const data = yield call(reportingService.reportingService.subscriptions, action.payload);
    yield put(Creators.subscriptionReportingResponse(data));
    yield put(Creators.loadingReporting({ loading: false , monthArray: []}));
  } catch (e) {
    //
  }
}

function* occupancyRate(action) {
  try {
    yield put(Creators.loadingReporting({ loading: true, monthArray: [] }));
    const data = yield call(reportingService.reportingService.occupancyRate, action.payload);
    yield put(Creators.occupancyRateReportingResponse(data));
    yield put(Creators.loadingReporting({ loading: false }));
  } catch (e) {
    //
  }
}

function* report(action) {
  try {
    yield put(Creators.loadingReporting({ reportLoading: true, report: null }));
    const data = yield call(reportingService.reportingService.report, action.payload);
    let report = null;
    if (data) report = data;
    yield put(Creators.loadingReporting({ reportLoading: false, report }));
  } catch (e) {
    yield put(Creators.loadingReporting({ reportLoading: false, report: null }));
    //
  }
}


export default function* mySaga() {
  yield takeLatest(Types.GET_REPORTING_REQUEST, report);
  yield takeLatest(Types.TURNOVER_REPORTING_REQUEST, turnover);
  yield takeLatest(Types.RESERVATION_REPORTING_REQUEST, reservation);
  yield takeLatest(Types.SALE_REPORTING_REQUEST, sale);
  yield takeLatest(Types.SUBSCRIPTION_REPORTING_REQUEST, subscription);
  yield takeLatest(Types.OCCUPANCY_RATE_REPORTING_REQUEST, occupancyRate);
}

//TURNOVER
const turnover_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    avgTicketsByMonth: parseFloat(action.data.avg_tickets_by_month).toFixed(2),
    ticketsChart: action.data.tickets_chart,
    monthArray: action.data.array_month,
    ticketsByActivity: action.data.tickets_by_activity,
    ticketsCA: parseFloat(action.data.tickets_ca).toFixed(2),
    ticketsByWeek: action.data.tickets_by_week,
  };
};

//RESERVATION
const reservation_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    totalRes: action.data.total,
    avgResByMonth: action.data.avg_res_by_month,
    resChart: action.data.res_chart,
    monthArray: action.data.array_month,
    reservationByActivity: action.data.reservation_by_activity,
    reservationByWeek: action.data.reservation_by_week,
  };
};

//SALE
const sale_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    salesNb: action.data.sales_nb,
    avgSalesByMonth: action.data.avg_sales_nb,
    salesChart: action.data.sales_chart,
    monthArray: action.data.array_month,
    salesByPaymentType: action.data.sales_by_payment_type,
    salesByWeek: action.data.sales_by_week,
  };
};

//SUBSCRIPTION
const subscription_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    totalSubscription: action.data.total,
    totalClassicSub: action.data.total_classic_sub,
    totalPrepaidSub: action.data.total_prepaid_sub,
    subscriptionChart: action.data.subscription_chart,
    monthArray: action.data.array_month,
    subscriptionBySubscriberNb: action.data.subscription_by_subscriber_nb,
    subscriptionCA: action.data.subscription_ca,
    subscriptionByWeek: action.data.subscription_by_week,
  };
};

//RESERVATION
const occupancy_rate_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    terrainsDisponibility: action.data.terrains_disponibility,
    terrainsOccupancy: action.data.terrains_occupancy,
    occupancyRate: action.data.occupancy_rate,
    monthArray: action.data.array_month,
    occupancyRateChart: action.data.occupancy_rate_chart,
    occupancyRateByActivity: action.data.occupancy_rate_by_activity,
    occupancyRateByWeek: action.data.occupancy_rate_by_week,
  };
}

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};


// Reducer
export const reportingReducer = createReducer(INITIAL_STATE, {
  [Types.TURNOVER_REPORTING_RESPONSE]: turnover_response,
  [Types.RESERVATION_REPORTING_RESPONSE]: reservation_response,
  [Types.SALE_REPORTING_RESPONSE]: sale_response,
  [Types.SUBSCRIPTION_REPORTING_RESPONSE]: subscription_response,
  [Types.OCCUPANCY_RATE_REPORTING_RESPONSE]: occupancy_rate_response,
  [Types.LOADING_REPORTING]: loading,
});
