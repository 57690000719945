import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { imgUrl } from '@/helpers';
import { convertTicket } from '@/helpers/ticket';
import moment from 'moment';
import i18n from '@/i18n';
import localization from 'moment/locale/fr';
import ModalConfirm from '../../ModalConfirm/ModalConfirm';
import CreditAccountModal from '../PaymentsModal/CreditAccountModal';
import { formatCodeCountry, nullString, phoneNumber } from '../../../helpers/formatting';

class CreditAccountListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      other: 0,
      modalOpen: false,
      cancelCreditAccountModalOpen : false,
      showCreditAccountModal : false,
    };
  }

  renderDefaultPaymentType() {
    let creditAccount = convertTicket(this.props.creditAccount);
    let other = creditAccount.payed_amount;
    if (this.props.paymentTypes) {
      let default_types = this.props.paymentTypes.map((item, index) => {
        if (item.is_show === 1) {
          let payment_type_list = Object.entries(creditAccount.payments.types).length
            ? creditAccount.payments.types.filter((itm) => itm.payment_type_id === item.id)
            : [];
          let amount = Object.entries(payment_type_list).length ? payment_type_list[0].amount : 0;
          other = parseFloat(other - amount) > 0 ? parseFloat(other - amount) : 0;

          return (
            <div
              key={index}
              className="ticket-price"
              style={{ borderRight: '1px dotted rgba(11, 108, 255, 0.65)' }}
            >
              <Image
                style={{ filter: 'invert(80%)' }}
                src={item.icon ? imgUrl + '/payments/' + item.icon : require('@/assets/other.svg')}
              />
              <span>
                {amount}
                {localStorage.getItem('currency')}
              </span>
            </div>
          );
        }
      });
      let other_types = (
        <div
          key={'other'}
          className="ticket-price"
          style={{ borderRight: '1px dotted rgba(11, 108, 255, 0.65)' }}
        >
          <Image src={require('../../../assets/other_white.svg')} style={{ filter: 'invert(80%)' }}/>
          <span>
            {other.toFixed(2)}
            {localStorage.getItem('currency')}
          </span>
        </div>
      );
      return [default_types, other_types];
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  renderUpdateCreditAccount(){
    let creditAccount = this.props.creditAccount;
    return (
      <CreditAccountModal open={this.state.showCreditAccountModal}
          type={"credit"}
          creditAccount={creditAccount}
          onClose={() => this.setState({
            showCreditAccountModal: false,
          })}
      />
    )
  }

  renderCancelCreditAccountModal() {
    return (
      <ModalConfirm
        open={this.state.cancelCreditAccountModalOpen}
        onClose={()=>this.setState({cancelCreditAccountModalOpen : false})}
        title={'Annuler un crédit'}
        message={
            <p>Vous êtes sur le point d'annuler le remboursement d'un crédit</p>
        }
        ok={() => this.cancelCreditAccount()}
        cancel={() => {
          this.setState({cancelCreditAccountModalOpen : false})
        }}
        okText={'Ok'}
      />
    );
  }

  cancelCreditAccount = async () => {
    let creditAccount = this.props.creditAccount;
    creditAccount.status = 2;
    creditAccount.credit_subscriber_detail = null;
    await this.props.updateTicket(creditAccount);
    this.props.caisseRedirection('ticket',2,'credit_account',null);
    this.props.getTickets({page : 1, size: 10, status: [2],is_credit_account : 1,date: null});
    this.setState({cancelCreditAccountModalOpen : false})
  };

  render() {
    let color = 'rgba(11, 108, 255, 0.65)';
    let colorType = {backgroundColor: 'rgb(0, 184, 118)'};
    if(this.props.type === 2){
      colorType = {backgroundColor: '#FF7070'};
    }
    
    let creditAccount = this.props.creditAccount;
    return (
        <div className={"credit_account-list-item"} style={{ color: color }}>
            {this.state.showCreditAccountModal && this.renderUpdateCreditAccount()}
            {this.renderCancelCreditAccountModal()}
            <div className="ticket-family" style={colorType}></div>
            <div className="ticket-information" style={{ borderRight: '1px dotted ' + color }}>
                <b>N° {this.props.creditAccount.number}</b>
                <span>{i18n.t('createdAt')} {moment(this.props.creditAccount.created_at).locale(i18n.language, localization).format('DD-MM-YYYY à HH:mm')}</span>
                {this.props.creditAccount.owner &&
                <span>
                    {i18n.t('createdBy')} {this.props.creditAccount.owner.firstName + ' ' + nullString(this.props.creditAccount.owner.lastName).toUpperCase()}
                </span>
                }
            </div>
            {creditAccount.user_id && creditAccount.user &&
              <div className="ticket-sub-family" style={{ borderRight: '1px dotted ' + color }}>
                <Image className="ticket-avatar avatar-cover"
                      src={creditAccount.user.avatar ?
                        imgUrl + '/users/' + creditAccount.user.avatar :
                        require('../../../assets/avatar.svg')} avatar/>
                <span>
                  {creditAccount.user_id && creditAccount.user && (
                    <>
                      {creditAccount.user.firstName}<br/>
                      {nullString(creditAccount.user.lastName).toUpperCase()}
                      <br/>
                      {formatCodeCountry(creditAccount.user.country_code)  + phoneNumber(creditAccount.user.mobile)}
                    </>
                  )}
                </span>
              </div>
            }
            <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
            <b>Montant</b>
            <span>
                {(parseFloat(this.props.creditAccount.amount)).toFixed(2)}
                {localStorage.getItem('currency')}
            </span>
            </div>

            {this.props.caisse_redirection.value === 'ticket' && this.renderDefaultPaymentType()}
            {parseInt(this.props.type) === 0 &&
              <div className="ticket-buttons">
                <Button className="ticket-btn-detail" onClick={() => this.setState({showCreditAccountModal : true})} content="Modifier"/>
                <Button className="ticket-btn-edit" onClick={() => this.setState({cancelCreditAccountModalOpen : true})} content="Annuler"/>
              </div>
            }
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
  updateTicket: (data) => dispatch({ type: 'UPDATE_TICKET_REQUEST', payload: data }),
  getCancledTicket: (id) => dispatch({ type: 'GET_CANCLED_TICKET_REQUEST', payload: id }),
  article_to_pay: (article, amount, rest) =>
    dispatch({ type: 'ARTICLE_TO_PAY_REQUEST', payload: { article, amount, rest } }),
  getTickets: (data) => dispatch({ type: 'GET_TICKETS_REQUEST', payload: { ...data } }),
  caisseRedirection: (value , status =null ,type = null,date=null) =>
    dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: {value,status,type,date} }),
});

const mapStateToProps = (state) => {
  let paymentTypes = state.paymentMethod.paymentMethods;
  paymentTypes.sort(function(a, b){return a.id-b.id});
  return {
    currentTicket: state.caisse.currentTicket,
    selectArticle: state.caisse.selectArticle,
    article_in_payment: state.caisse.article_in_payment,
    paymentTypes: paymentTypes,
    caisse_redirection: state.caisse.caisse_redirection,
    complex : state.auth.selected,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditAccountListItem));
