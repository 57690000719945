import React from "react";
import {
  Button,
  Checkbox,
  Grid,
  Icon,
  Input,
  Loader,
  Modal,
  Pagination,
} from "semantic-ui-react";
import CustomInput from "../../CustomInput/CustomInput";
import { connect } from "react-redux";
import { throttle } from "../../../helpers";
import { convertTicketToNote } from "@/helpers/caisse/noteFormatting";
import { DEFAULT_COUNTRY_CODE } from "../../../utils/countryCode";
import { invoicePrint, PrintPopup } from "../../../helpers/print";
import i18n from "../../../i18n";
import "../../CustomTable/CustomTable.css";
import { SIZE } from "../../../utils/mock";
import {
  formatCodeCountry,
  nullString,
  phoneNumber,
} from "../../../helpers/formatting";

const invoiceState = {
  user: {
    user_id: null,
    contact_id: null,
    email: "",
    mobile: "",
    country_code: DEFAULT_COUNTRY_CODE,
    firstName: "",
    lastName: "",
    company: "",
    address: "",
    zip: "",
    sendMail: false,
    showNote: false,
  },
  invoice: {
    ticket_id: "",
    complex_id: "",
    note_index: "",
    note: "",
  },
  checkInvoice: false,
};

class InvoiceModal extends React.Component {
  currency = localStorage.getItem("currency");

  constructor(props) {
    super(props);
    this.state = {
      invoice: { ...invoiceState },
      search_player: "",
      tickets: null,
      errors: {
        firstName: null,
        lastName: null,
        email: null,
      },
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open && this.props.open !== prevProps.open) {
      this.props.getTicketInfo();
    }
    // print when invoice is created
    if (this.props.invoice && this.props.invoice !== prevProps.invoice) {
      const note_index = this.props.noteIndex;
      let invoice = {
        ...this.props.invoice,
        showNote: this.state.invoice.user.showNote,
        ticket: note_index
          ? {
              ...convertTicketToNote(this.props.ticket, note_index),
              number: this.props.ticket.number + "-" + note_index.split("-")[1],
            }
          : { ...this.props.ticket },
        ticketInfos: { ...this.props.ticketInfos },
      };
      if (this.props.ticket && Object.keys(this.props.ticket).length > 0) {
        let data = invoicePrint(invoice);
        PrintPopup(data);
      }
      this.setState({
        invoice: { ...invoiceState },
        contentModal: "",
        search_player: "",
      });
      this.props.onClose();
    }
  }

  onUserChange = (e, eventMobile) => {
    let { invoice } = this.state;
    let { user } = invoice;
    user[e.target.name] = e.target.value;
    if (eventMobile) user[eventMobile.target.name] = eventMobile.target.value;
    invoice = { ...invoice, user: { ...user } };
    this.setState({ invoice });
  };

  onInvoiceChange = (e) => {
    let { invoice } = this.state;
    let { invoice: invoiceState } = invoice;
    invoiceState[e.target.name] = e.target.value;
    invoice = { ...invoice, invoice: { ...invoiceState } };
    this.setState({ invoice });
  };

  handleChangeSearch = (event) => {
    if (this.state.modalContent)
      this.setState({
        [event.target.name]: event.target.value,
      });
    else
      this.setState({
        modalContent: "search",
        [event.target.name]: event.target.value,
      });

    this.props.searchPlayer(1, SIZE, event.target.value);
  };

  handlePaginationChange = (e, data) => {
    this.props.searchPlayer(data.activePage, SIZE, this.state.search_player);
  };

  handleInvoiceSubmit = () => {
    let { invoice: data } = this.state;
    let { ticket } = this.props;
    if (
      (data.user.firstName === "" && data.user.lastName === "") ||
      (data.user.mobile === "" && data.user.email === "")
    ) {
      data.checkInvoice = true;
      this.setState({ invoice: { ...data } });
    } else {
      data.checkInvoice = false;
      this.setState({ invoice: { ...data } });
    }
    if (!data.checkInvoice) {
      data.invoice.complex_id = localStorage.getItem("complex_id");
      data.invoice.ticket_id = ticket.id;
      data.invoice.note_index = this.props.noteIndex;
      this.props.storeInvoice(data);
    }
  };

  handleThrottleInvoiceSubmit = throttle(this.handleInvoiceSubmit, 1000);

  renderUserInformation = () => {
    let { checkInvoice } = this.state.invoice;
    return (
      <>
        <div className="invoice-information">
          <Grid centered={true}>
            <Grid.Column width={6} className={"no-padding-grid"}>
              <CustomInput
                name={"lastName"}
                label={i18n.t("last_name")}
                type={"text"}
                value={this.state.invoice.user.lastName}
                onChange={this.onUserChange}
                error={
                  (checkInvoice && this.state.invoice.user.lastName !== "") ||
                  this.props.invoiceError?.lastName
                }
                errormsg={
                  this.props.invoiceError?.lastName
                    ? { content: this.props.invoiceError?.lastName[0] }
                    : {}
                }
              />
            </Grid.Column>
            <Grid.Column width={6} className={"no-padding-grid"}>
              <CustomInput
                name={"firstName"}
                label={i18n.t("first_name")}
                type={"text"}
                value={this.state.invoice.user.firstName}
                onChange={this.onUserChange}
                error={
                  (checkInvoice && this.state.invoice.user.firstName !== "") ||
                  this.props.invoiceError?.firstName
                }
                errormsg={
                  this.props.invoiceError?.firstName
                    ? { content: this.props.invoiceError?.firstName[0] }
                    : {}
                }
              />
            </Grid.Column>
            <Grid.Column width={6} className={"no-padding-grid"}>
              <CustomInput
                name={"mobile"}
                label={i18n.t("mobile")}
                type={"phone"}
                value={this.state.invoice.user.mobile}
                countrycode={this.state.invoice.user.country_code}
                error={
                  (checkInvoice && this.state.invoice.user.mobile !== "") ||
                  this.props.invoiceError?.mobile
                }
                errormsg={
                  this.props.invoiceError?.mobile
                    ? { content: this.props.invoiceError?.mobile[0] }
                    : {}
                }
                onChange={this.onUserChange}
              />
            </Grid.Column>

            <Grid.Column width={6} className={"no-padding-grid"}>
              <CustomInput
                name={"email"}
                label={i18n.t("email")}
                type={"email"}
                value={nullString(this.state.invoice.user.email)}
                onChange={this.onUserChange}
                error={
                  (checkInvoice && this.state.invoice.user.email !== null) ||
                  this.props.invoiceError?.mobile
                }
                errormsg={
                  this.state.errors.email ||
                  (this.props.invoiceError?.email
                    ? { content: this.props.invoiceError?.email[0] }
                    : {})
                }
              />
            </Grid.Column>
            <Grid.Column width={6} className={"no-padding-grid"}>
              <CustomInput
                name={"company"}
                label={i18n.t("company")}
                type={"text"}
                value={nullString(this.state.invoice.user.company)}
                onChange={this.onUserChange}
              />
            </Grid.Column>

            <Grid.Column width={6} className={"no-padding-grid"}>
              <CustomInput
                name={"address"}
                label={i18n.t("address")}
                type={"text"}
                value={this.state.invoice.user.address}
                onChange={this.onUserChange}
              />
            </Grid.Column>

            <Grid.Column width={6} className={"no-padding-grid"}>
              <CustomInput
                name={"zip"}
                label={i18n.t("CP")}
                type={"text"}
                value={this.state.invoice.user.zip}
                onChange={this.onUserChange}
              />
            </Grid.Column>
            <Grid.Column width={6} className={"no-padding-grid"} />

            <Grid.Column width={6}>
              <label>
                <Checkbox
                  name={"showNote"}
                  onChange={(e) => {
                    this.onUserChange({
                      target: {
                        name: "showNote",
                        value: !this.state.invoice.user.showNote,
                      },
                    });
                  }}
                  checked={this.state.invoice.user.showNote}
                />
                <span className={"label-invoice"}>Sans détail</span>
              </label>
            </Grid.Column>
            <Grid.Column width={6}>
              <label>
                <Checkbox
                  name={"sendMail"}
                  onChange={(e) => {
                    this.onUserChange({
                      target: {
                        name: "sendMail",
                        value: !this.state.invoice.user.sendMail,
                      },
                    });
                  }}
                  checked={this.state.invoice.user.sendMail}
                />
                <span className={"label-invoice"}>Envoi par mail</span>
              </label>
            </Grid.Column>

            <Grid.Column width={6}>
              <CustomInput
                name={"note"}
                label={i18n.t("Note")}
                type={"textArea"}
                value={this.state.invoice.invoice.note}
                onChange={this.onInvoiceChange}
                textAreaStyle={{
                  backgroundColor: "white",
                  border: "1px solid rgba(34,36,38,.15)",
                  color: "rgba(0,0,0,.8)",
                  height: "80px",
                  width: "150%",
                }}
              />
            </Grid.Column>

            <Grid.Column width={6} className={"no-padding-grid"} />
          </Grid>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            loading={this.props.invoiceLoading}
            disabled={this.props.invoiceLoading}
            className="player-green-btn"
            onClick={this.handleThrottleInvoiceSubmit}
          >
            {i18n.t("save")}
          </Button>
        </div>
      </>
    );
  };

  renderSearchContent() {
    return this.props.users.map((item, index) => {
      return (
        <div key={index} className="player-row">
          <span>
            {nullString(item.firstName).toLowerCase()}{" "}
            {nullString(item.lastName).toUpperCase()}
          </span>
          <span>
            {formatCodeCountry(item.country_code) + phoneNumber(item.mobile)}
          </span>
          <span>{item.email}</span>
          <span style={{ color: "#7EB5E5" }}>
            <Icon
              name="edit"
              onClick={() => {
                this.setState((prevState) => ({
                  modalContent: "",
                  invoice: {
                    ...prevState.invoice,
                    user: {
                      user_id: item.id,
                      firstName: item.firstName,
                      lastName: item.lastName,
                      company: item.company,
                      zip: item.zip,
                      email: item.email,
                      mobile: item.mobile,
                      country_code: item.country_code,
                      address: item.address,
                    },
                  },
                }));
              }}
            />
          </span>
        </div>
      );
    });
  }

  renderInvoice = () => {
    return (
      <Modal
        open={this.props.open}
        onClose={() => this.props.onClose()}
        size={"large"}
        className="modal-ticket modal-edit-player"
      >
        <Modal.Content className="modal-content">
          <h4 className="modal-content-header">{i18n.t("player_info")}</h4>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={() => this.props.onClose()}
            />
            <div className="search-bloc">
              {
                <>
                  <Input
                    name="search_player"
                    className="search-input-player"
                    icon="search"
                    placeholder="Recherche"
                    onChange={this.handleChangeSearch}
                    value={this.state.search_player}
                  />
                  {
                    <div className="add-player">
                      <Button
                        icon="plus"
                        color="blue"
                        circular
                        size="tiny"
                        onClick={() =>
                          this.setState({
                            modalContent: "",
                            invoice: { ...invoiceState },
                          })
                        }
                      />
                      {i18n.t("add_user")}
                    </div>
                  }
                </>
              }
            </div>
            <div className="player-informations">
              {this.state.modalContent === "" ? (
                <div className="add-player">{this.renderUserInformation()}</div>
              ) : (
                <div className="search-list">
                  {this.props.loading ? <Loader /> : this.renderSearchContent()}
                  {this.state.search_player && (
                    <div className={"ticket-pagination"}>
                      <Pagination
                        activePage={this.props.page}
                        boundaryRange={1}
                        onPageChange={(e, data) =>
                          this.handlePaginationChange(e, data)
                        }
                        size="mini"
                        siblingRange={1}
                        totalPages={this.props.last}
                        ellipsisItem={"..."}
                        firstItem={null}
                        lastItem={null}
                        prevItem={null}
                        nextItem={null}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  render() {
    return this.renderInvoice();
  }
}

InvoiceModal.defaultProps = {
  open: false,
  ticket: null,
  noteIndex: "",
  onClose: () => {},
};

const mapDispatchToProps = (dispatch) => ({
  getTicketInfo: () =>
    dispatch({ type: "INDEX_REQUEST_TICKET_INFO", payload: {} }),
  searchPlayer: (page, size, search) =>
    dispatch({ type: "SEARCH_REQUEST_USER", payload: { page, size, search } }),
  storeInvoice: (value) =>
    dispatch({ type: "STORE_INVOICE_REQUEST", payload: value }),
});

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.user.users,
    page: state.user.page,
    size: state.user.size,
    last: state.user.last,
    loading: state.user.loading,
    invoiceLoading: state.caisse.invoiceLoading,
    invoice: state.caisse.invoice,
    invoiceError: state.caisse.invoiceError,
    ticketInfos: state.ticketInfo.ticketInfos,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModal);
