import i18n from "../../i18n";
import { infoToast } from "../../helpers/customToast";
import history from "../../helpers/history";
import moment from "moment";
import authService from "../../services/authService";

/*
 * middle forcing check if the user session has expired or not when he use
 * the caisse page
 * */

let session_reminder = false;
const sessionMiddleware = (store) => (next) => async (action) => {
  const ticketActionsTypes = [
    "GET_DATA_REQUEST",
    "STORE_TICKET_REQUEST",
    "UPDATE_TICKET_REQUEST",
    "DESTROY_TICKET_REQUEST",
    "SESSION_CHECK_RESPONSE",
    // 'STORE_RESPONSE_CLOSURE',
    // 'GET_PAYMENT_TYPES_REQUEST',
  ];
  if (
    ticketActionsTypes.includes(action.type) &&
    !(action.payload && action.payload.hasOwnProperty("autoLoad"))
  ) {
    const session_id = localStorage.getItem("session_id");
    const msg = session_id ? "your_session_has_expired" : "start_new_session";
    let data =
      action.type === "SESSION_CHECK_RESPONSE"
        ? action.payload?.session
        : store.getState().session.session;

    if (session_id && data) {
      // session found now let's test if still status === 0
      // let data = await getSession(session_id);
      // check if is date expired after 24 hour
      if (data) {
        if (data.status === 0) {
          let diff = moment().diff(
            data.date + "T" + data.hour + ":00",
            "hours"
          );
          let diff_by_minute = moment().diff(
            data.date + "T" + data.hour + ":00",
            "minutes"
          );

          let configurations = store.getState().configuration
            ? store.getState().configuration.configurations
            : [];

          let nextDay = moment(data.date).add(1, "day").format("YYYY-MM-DD");
          let toDay = data.date;

          let isSameOrBefore = false;

          if (configurations.length > 0) {
            let hourBegin = data.hour.split(":")[0];
            let hourClose = configurations[0].close_hour.split(":")[0];
            if (parseInt(hourBegin) <= parseInt(hourClose)) {
              isSameOrBefore = moment(
                toDay + "T" + configurations[0].close_hour + ":00"
              ).isSameOrBefore(moment());
            } else {
              isSameOrBefore = moment(
                nextDay + "T" + configurations[0].close_hour + ":00"
              ).isSameOrBefore(moment());
            }
          }
          // session reminder before 5min of closing (24h - 5min)
          if (
            !(diff >= 24 || isSameOrBefore) &&
            diff_by_minute === 1435 &&
            !session_reminder
          ) {
            infoToast(i18n.t("session_reminder"));
            session_reminder = true;
          }

          return next(action);
        } else {
          store.dispatch({
            type: "RESET_SESSION_REQUEST",
          });
          localStorage.removeItem("session_id");
          redirectionToCheckout(msg);
        }
      } else {
        localStorage.removeItem("session_id");
        redirectionToCheckout(msg);
      }
    } else {
      redirectionToCheckout(msg);
    }
    //redirection to the home page and cancel the action
  } else {
    return next(action);
  }

  //  return result;
};

const checkoutMiddleware = (store) => (next) => async (action) => {
  const ticketActionsTypes = ["CHECK_CHECKOUT_SESSION"];
  if (ticketActionsTypes.includes(action.type)) {
    const msg = "your_session_has_expired";
    let data = action.data;
    if (data && data.hasOwnProperty("status") && data.status === "error") {
      store.dispatch({
        type: "RESET_SESSION_REQUEST",
      });
      localStorage.removeItem("session_id");
      redirectionToCheckout(msg);
    } else {
      return next(action);
    }
  } else {
    return next(action);
  }
};

const redirectionToCheckout = (msg) => {
  console.log("history.location.pathname", history.location.pathname);
  if (
    history.location.pathname !== "/caisse" &&
    history.location.pathname !== "/tickets"
  )
    return;
  history.push({ pathname: "/checkout", search: history.location.search });
  infoToast(i18n.t(msg));
};

const tokenMiddleware = (store) => (next) => async (action) => {
  let expirationDate = localStorage.getItem("expirationDate");
  let token = !!localStorage.getItem("token");
  const ActionsTypes = [
    "LOGIN_REQUEST",
    "SEND_RECOVER_REQUEST",
    "LOGOUT_REQUEST",
  ];
  if (
    token &&
    expirationDate &&
    !ActionsTypes.includes(action.type) &&
    action.type.includes("REQUEST")
  ) {
    if (moment.unix(expirationDate).isAfter(moment())) {
      return next(action);
    } else {
      //expired token
      try {
        let refreshedData = await authService.authService.refresh();
        store.dispatch({
          type: "REFRESH_REQUEST",
          payload: refreshedData.data,
        });
        return next(action);
      } catch (e) {
        return next({
          type: "LOGOUT_REQUEST",
        });
      }
    }
  } else {
    return next(action);
  }
};

export { sessionMiddleware, checkoutMiddleware, tokenMiddleware };
