import React, { useState } from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import './CustomAccordion.css';

const CustomAccordion = (props) => {
  let [show, setShow] = useState(false);

  let { module, t } = props;

  if (props.mode === 'single')
    return (
      <div className={'custom-accordion'}>
        <div className={'main-accordion '}>
          <label className={'accordion-item single-accordion'}>
            <span>{t(`${module.slug}`)}</span>
            <Checkbox
              toggle={true}
              checked={module.checked}
              onChange={() => props.handleChange(module.id, props.index)}
            />
          </label>
        </div>
      </div>
    );
  return (
    <div className={'custom-accordion'}>
      <div className={'main-accordion'} onClick={() => setShow(!show)}>
        <span>{t(`${module.slug}`)}</span>
        <Icon className={`angle ${!show ? ' down' : ' up'}`} onClick={() => setShow(!show)}/>
      </div>
      <div className={'accordion-slider ' + (show ? '' : 'accordion-hide')}>
        <div className={'accordion-container'}>
          <label key={`item-accordion-all`} className={'accordion-item accordion-item-all'}>
            <label>
              {t('selected_all')}
            </label>
            <Checkbox
              toggle={true}
              checked={module.permission.filter(it => it.checked).length === module.permission.length}
              onChange={() => {
                let all = module.permission.filter(it => it.checked).length === module.permission.length;
                module.permission.forEach((it, i) => {
                  props.handleChange(module.id, i, props.name,all);
                });
              }}
            />
          </label>

          {module.permission.map((p, i) => {
            return (
              <label key={`item-accordion-${i}`} className={'accordion-item'}>
                <label>
                  {t(p.slug)}
                </label>
                <Checkbox
                  toggle={true}
                  checked={p.checked}
                  onChange={() => props.handleChange(module.id, i, props.name,null)}
                />
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomAccordion;
