import React from 'react';
import '../../CustomCaisse.css';
import Calculator from './Calculator';
import i18n from '@/i18n';
import { Button, Grid, Icon, Image, Input, Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce, imgUrl } from '@/helpers';
import { customRound, toFloat } from '../../../../helpers/formatting';
import {
  cumulDiscountArticlesFromParticipant,
  cumulDiscountsFromArticle,
  cumulTotalDiscountFromDiscounts,
  getAmountToPay,
  getArticleDiscount,
  getParticipantDiscount,
} from '@/helpers/ticket';
import { getCurrentNote } from '@/helpers/caisse/noteFormatting';
import { warningToast } from '../../../../helpers/customToast';
import { filterDot } from '../../../../helpers/caisseFormatting';
import { exactDivision } from "@/helpers/operation";

class DiscountContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calculator_value: 0,
      discounts_list: [],
    };
  }

  updateDiscountListOfSeletedArticle() {
    let currentNoteIndex = this.props.currentNote;
    let discounts_list = this.props.discounts;
    discounts_list = discounts_list.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        amount: '',
        percentage: '',
        avatar: item.avatar,
        isPercentage: item.value !== null,
        note_index: this.props.currentNote,
      };
    });
    let selectedArticle = Object.keys(this.props.articleToPay)[0] ? Object.keys(this.props.articleToPay)[0] : null;
    
    if (selectedArticle) {
      let selectedParticipant = Object.values(this.props.articleToPay)[0] ? Object.values(this.props.articleToPay)[0] : null;
      let participantNb = this.props.currentTicket.articles.filter(itm => itm.id === selectedArticle);
      participantNb = participantNb.length && participantNb[0].default_participants_nb;
                                        
      //get discount of participants
      if (selectedParticipant.length && selectedParticipant.length < participantNb) {
        let participant_discounts = this.props.currentTicket.discountTypes.participants.filter(itm =>
          selectedParticipant.includes(itm.user_index));
        discounts_list.forEach((item, index) => {
          participant_discounts.forEach((participant_discount, i) => {
            if (participant_discount.discount_id === item.id) {
              item.amount = ((item.amount ? parseFloat(item.amount) : 0) + parseFloat(participant_discount.amount)).toFixed(2);
              item.note_index = participant_discount.note_index;
            }
          });
        });
      }
      //get discount of article
      else {
        let article_discounts = this.props.currentTicket.discountTypes.articles.filter(itm =>
          (itm.article_id).toString() === selectedArticle.toString() && itm.note_index === currentNoteIndex);
        discounts_list.map((item, index) => {
          let amount = article_discounts.filter(itm => itm.discount_id === item.id).reduce(function(previousValue, currentValue) {
            return previousValue + parseFloat(currentValue.amount);
          }, 0);
          article_discounts.map((article_discount, i) => {
            if (article_discount.discount_id === item.id) {
              item['amount'] = parseFloat(amount).toFixed(2);
              item['note_index'] = article_discount.note_index;
            }
          });
        });
      }
    }
    discounts_list = discounts_list.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        amount: item.amount ? item.amount : '',
        percentage: item.percentage ? item.percentage : '',
        avatar: item.avatar,
        isPercentage: item.isPercentage,
        note_index: item.note_index,
      };
    });
    // console.log(discounts_list);
    this.setState({
      discounts_list: discounts_list,
    });
  }

  componentDidMount() {
    this.props.discount_list();
  }

  componentDidUpdate(prevProps) {
    if (this.props.discounts !== prevProps.discounts || this.props.articleToPay !== prevProps.articleToPay) {
      this.updateDiscountListOfSeletedArticle();
    }
  }

  hasAmount = () => {
    if (this.state.discounts_list.length === 0) return false;
    else {
      let discounts = this.state.discounts_list;
      for (let i = 0; i < discounts.length; i++) {
        if ((discounts[i].amount !== '' && discounts[i].amount !== 0) || (discounts[i].percentage !== '')) {
          return true;
        }
      }
    }
    return false;
  };

  changeDiscountInput(e, index) {
    let amountWithoutDiscount = getAmountToPay(this.props.currentTicket, this.props.articleToPay, this.props.currentNote)[2];
    let discounts_list = [...this.state.discounts_list];
    let value = (e.target.value);
    if (value === '.') value = '0.';
    if (isNaN(value) && value !== '0.') return;
    let discountTotal = 0;
    for (let i = 0; i < discounts_list.length; i++) {
      if (index === i) {
        discountTotal += toFloat(value) * Object.entries(this.props.articleToPay).length;
      } else {
        if (discounts_list[i].isPercentage) {
          discountTotal += toFloat(discounts_list[i].amount);
        } else {
          discountTotal += toFloat(discounts_list[i].amount) * Object.entries(this.props.articleToPay).length;
        }
      }

    }

    if (toFloat(amountWithoutDiscount) >= discountTotal) {
      discounts_list[index].amount = value;
      if (value === '0') {
        discounts_list[index].percentage = '';
      }
    } else {
      warningToast('exceded_discount', { position: 'top-right' });
    }
    this.setState({ discounts_list: discounts_list });

  }

  getDiscountAmount(index) {
    let amountWithoutDiscount = getAmountToPay(this.props.currentTicket, this.props.articleToPay, this.props.currentNote)[2];
    let discounts_list = [...this.state.discounts_list];
    let percentage = this.props.discounts[index].value ? this.props.discounts[index].value : '';
    let amount = this.props.discounts[index].value ?
      amountWithoutDiscount * parseFloat(percentage) / 100
      : this.props.discounts[index].amount;

    let discountTotal = 0;
    if (percentage || amount) {
      discounts_list[index].percentage = percentage ? customRound(percentage) : '';
      discounts_list[index].amount = customRound(amount);
    }
    for (let i = 0; i < discounts_list.length; i++) {
      if (discounts_list[i].isPercentage) {
        discountTotal += toFloat(discounts_list[i].amount);
      } else {
        discountTotal += toFloat(discounts_list[i].amount) * Object.entries(this.props.articleToPay).length;
      }

    }

    if (discountTotal > toFloat(amountWithoutDiscount)) {
      discounts_list[index].percentage = '';
      discounts_list[index].amount = '';
      warningToast('exceded_discount', { position: 'top-right' });
    }
    this.setState({ discounts_list: discounts_list });

  }

  renderDiscountTypeInput() {
    if (this.state.discounts_list !== null && this.state.discounts_list.length) {
      return this.state.discounts_list.map((item, index) => {
        return (
          <div className="payment-block-input" key={index}>
            <span className="payment-block-input-left">
                <Input name={item.slug}
                       type={'text'}
                       value={this.state.discounts_list && this.state.discounts_list[index].amount && this.state.discounts_list[index].amount.toString() !== '' ? filterDot(this.state.discounts_list[index].amount) : ''}
                       onFocus={(e) => !item.isPercentage && this.changeDiscountInput(
                         { target: { value: this.state.calculator_value } }, index)
                       }
                       onChange={(e) => {
                         !item.isPercentage && this.changeDiscountInput(e, index);
                       }}
                />
            </span>
            <span className="payment-block-input-right" onClick={() => this.getDiscountAmount(index)}>
                <span>
                  {item.name.length > 13 ? item.name.substr(0, 12) + '...' : item.name}
                </span>
                <Image src={item.avatar ?
                  (imgUrl + '/discounts/' + localStorage.getItem('complex_id') + '/' + item.avatar)
                  : require('@/assets/other_white.svg')}/>
            </span>
            {this.state.discounts_list && this.state.discounts_list[index].amount ?
              <Icon name="close" className="discount-close-btn" onClick={() => {
                this.changeDiscountInput({ target: { value: '' } }, index);
              }}/> : null}
          </div>
        );
      });
    }
  }

  articleDiscount(id, amount, percentage, current_article, note_index) {
    let currentTicket = { ...this.props.currentTicket };
    let articles = { ...currentTicket.discountTypes.articles };
    articles = Object.values(articles);
    // let old_discounts = [];
    // if (articles.length) {
    //   old_discounts = articles.filter(itm => (itm.article_id.toString() === current_article.id.toString()));
    //   old_discounts = old_discounts.map((item, index) => {
    //     return item.discount_id;
    //   });
    // }
    /**
     * note division (discount only selected occurence of article)
     */
    let article_nb = current_article.article_nb;
    if (this.props.currentNote) {
      let currentNote = getCurrentNote(currentTicket, this.props.currentNote);
      let articleNote = currentTicket.articles.filter((itm) => itm.id.toString() === current_article.id.toString());
      if (articleNote.length) {
        article_nb = articleNote[0].article_nb;
      }
    }
    /**
     *
     */
    let totalPrice = parseFloat(current_article.total_price) * parseInt(article_nb);

    articles.push({
      article_id: current_article.id,
      amount: (percentage ?
          customRound(parseFloat((parseFloat(totalPrice) * parseFloat(percentage)) / 100)) :
          customRound(parseFloat(amount))
      ),
      percentage: percentage,
      discount_id: id,
      note_index: note_index,
    });
    currentTicket.discountTypes.articles = articles.filter(itm => parseFloat(itm.amount) > 0);
  }

  participantDiscount(id, amount, percentage, current_participant, participant_nb, index, totalDiscountPercentage, totalDiscountNotPercentage, note_index) {
    let currentTicket = { ...this.props.currentTicket };
    let participants = { ...currentTicket.discountTypes.participants };
    participants = Object.values(participants);

    let total = parseFloat(totalDiscountNotPercentage) + (
      (parseFloat(current_participant.amount) * parseInt(participant_nb) * parseFloat(totalDiscountPercentage)) / 100);
    let divisible = (total % parseInt(participant_nb)) === 0;

    //discount of all participants
    let totalDiscount = percentage ?
      parseFloat((parseFloat(current_participant.amount) * parseInt(participant_nb) * parseFloat(percentage)) / 100) :
      parseFloat(amount);

    let amountPerParticipant = parseFloat(totalDiscount) / parseInt(participant_nb);
    let participantDiscount = amountPerParticipant - ((amountPerParticipant * 100) % 5) / 100;
    let excess = exactDivision(totalDiscount,participantDiscount,participant_nb);

    let lastParticipantDiscount = parseFloat(participantDiscount) + parseFloat(excess);
    participants.push({
      user_id: current_participant.user_id,
      user_index: current_participant.user_index,
      article_id: current_participant.article_id,
      amount: (divisible ? parseFloat(amountPerParticipant).toFixed(2) :
        (index === 0) ? parseFloat(lastParticipantDiscount).toFixed(2) : parseFloat(participantDiscount).toFixed(2)),
      percentage: percentage,
      discount_id: id,
      note_index: note_index,
    });
    currentTicket.discountTypes.participants = participants.filter(itm => parseFloat(itm.amount) > 0);

  }

  refreshParticipantDiscount(id) {
    let participant_discount = this.props.currentTicket.discountTypes.participants.filter(itm => itm.user_index !== id);
    this.props.currentTicket.discountTypes.participants = participant_discount;
  }

  refreshArticleDiscount(id) {
    let article_discount = this.props.currentTicket.discountTypes.articles.filter(itm => (itm.article_id !== id));
    this.props.currentTicket.discountTypes.articles = article_discount;
  }

  availableDiscount() {
    let inc = 0;
    let discounts_list = this.state.discounts_list.filter(itm => itm.amount !== '');
    if (discounts_list.length) {
      let articleToPay = { ...this.props.articleToPay };
      let currentTicket = { ...this.props.currentTicket };
      articleToPay = Object.entries(articleToPay);
      if (articleToPay.length) {
        articleToPay.map((article_to_pay, i) => {
          currentTicket.articles.map((item, index) => {
            if (item.id.toString() === article_to_pay[0].toString()) {
              let discount = 0;
              discounts_list.map((discount_type, index) => {
                discount = parseFloat(discount) + (discount_type.isPercentage ?
                  parseFloat(customRound((parseFloat(item.total_price) * parseInt(item.article_nb) * parseFloat(discount_type.percentage)) / 100)) :
                  parseFloat(customRound(discount_type.amount)));
              });

              if ((parseFloat(item.total_price) * parseInt(item.article_nb)) < parseFloat(discount)) {
                inc += 1;
              }
            }
          });
        });
      }
    }
    if (inc > 0) {
      return false;
    } else {
      return true;
    }
  }

  discountSelectedArticles = async () => {
    let currentNote = this.props.currentNote;

    let discounts_list = this.state.discounts_list.filter(itm => {
      return (itm.amount);
    });
    if (discounts_list.length) {
      let articleToPay = { ...this.props.articleToPay };
      let currentTicket = { ...this.props.currentTicket };
      let selectedArticle = Object.entries(articleToPay);
      let list_participant = [];
      if (selectedArticle.length) {
        selectedArticle.map((article_to_pay, i) => {
          currentTicket.articles.map((item, index) => {
            if (item.id.toString() === article_to_pay[0].toString()) {
              //pay per participant
              if ((item.parent.is_activity === 1 && item.child.is_res) && article_to_pay[1].length) {
                if (Object.entries(currentTicket.participants).length) {
                  list_participant = currentTicket.participants.filter(itm => (article_to_pay[1].includes(itm.user_index)));
                  if (Object.entries(list_participant).length) {
                    list_participant.map((client, ind) => {
                      this.refreshParticipantDiscount(client.user_index, item.id);

                      let totalDiscountPercentage = 0;
                      let totalDiscountNotPercentage = 0;
                      discounts_list.map((discount_type, index) => {
                        if (discount_type.percentage) {
                          totalDiscountPercentage += parseFloat(discount_type.percentage);
                        } else {
                          totalDiscountNotPercentage += parseFloat(discount_type.amount);
                        }
                      });

                      discounts_list.map((discount_type, index) => {
                        this.participantDiscount(discount_type.id, discount_type.amount,
                          discount_type.percentage, client, Object.entries(list_participant).length, ind,
                          totalDiscountPercentage, totalDiscountNotPercentage, discount_type.note_index);
                      });
                      let articleDiscount = currentTicket.discountTypes.articles.filter(itm => itm.article_id !== item.id);

                      articleDiscount = [...articleDiscount, ...cumulDiscountArticlesFromParticipant(currentTicket, item.id)];
                      currentTicket.discountTypes.articles = articleDiscount;

                      if (parseFloat(client.payed_amount) === 0 && parseFloat(getParticipantDiscount(currentTicket, client.user_index)) < parseFloat(client.amount)) {
                        client['status'] = null;
                      } else if (parseFloat(client.payed_amount) + parseFloat(getParticipantDiscount(currentTicket, client.user_index)) < parseFloat(client.amount)) {
                        client['status'] = null;
                      } else {
                        client['status'] = 'payed';
                        delete articleToPay[item.id];
                      }
                    });
                  }
                }
              }
              //pay per article
              else {
                this.refreshArticleDiscount(item.id);
                discounts_list.map((discount_type, index) => {
                  this.articleDiscount(discount_type.id, discount_type.amount, discount_type.percentage, item, discount_type.note_index);
                });
              }
              if (parseFloat(item.payed_amount) === 0 && parseFloat(getArticleDiscount(currentTicket, item.id)) < (parseFloat(item.total_price) * parseInt(item.article_nb))) {
                item['status'] = null;
              } else if ((parseFloat(item.payed_amount) + parseFloat(getArticleDiscount(currentTicket, item.id))) <
                (parseFloat(item.total_price) * parseInt(item.article_nb))) {
                item['status'] = null;
              } else {
                item['status'] = 'payed';
                delete articleToPay[item.id];
              }

            }
          });
        });
        currentTicket.discountTypes.discounts = cumulDiscountsFromArticle(currentTicket);
        currentTicket.discount = cumulTotalDiscountFromDiscounts(currentTicket);
        await this.props.updateCurrentTicket(currentTicket);
        this.props.article_to_pay(articleToPay,
          getAmountToPay(currentTicket, articleToPay, this.props.currentNote)[0],
          getAmountToPay(currentTicket, articleToPay, this.props.currentNote)[1]);
      }
      //reset
      discounts_list = this.props.discounts.map((item, index) => {
        return { id: item.id, name: item.name, amount: '', percentage: '', avatar: item.avatar };
      });
      this.setState({ discounts_list: discounts_list, calculator_value: 0 });
    }
  };

  render() {
    let currency = localStorage.getItem('currency');
    let discounts = 0;
    let { discounts_list } = this.state;
    discounts_list.forEach((it) => {
      if (it.amount !== '') {
        if (it.isPercentage) {
          discounts += parseFloat(it.amount);
        } else {
          discounts += (parseFloat(it.amount) * Object.entries(this.props.articleToPay).length);
        }

      }
    });
    return (
      <>
        <h3 className="payment-title">{i18n.t('discount')}</h3>
        <Grid columns={2} className="payment-content">
          <Grid.Row>
            <Grid.Column className="payment-content-left">
              <Calculator onChange={(value) => this.setState({ calculator_value: value })}/>
            </Grid.Column>
            <Grid.Column className="payment-content-right">
              {this.props.loading ?
                <div className={'loading-input-main'}>
                  <Loader size={'small'} active/>
                </div>
                :
                this.renderDiscountTypeInput()
              }
              <br/>
              <span className="payment-content-total">
                Total {parseFloat(discounts).toFixed(2)}{currency}
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="discount-btn-group">
          <Button
            className={'payment-btn-active payment-btn-green'}
            onClick={debounce(async () => {
              if (this.availableDiscount()) {
                this.props.caisse_redirection('home');
                this.discountSelectedArticles();
              } else {
                warningToast('exceded_discount', { position: 'top-right' });
              }
            }, 1000)
            }
            disabled={!discounts || !(this.hasAmount() && this.props.currentTicket)}

          >{i18n.t('validate')}</Button>
          <Button
            className={'payment-btn-active payment-btn-green'}
            disabled={!discounts ||
            !(this.props.currentTicket && this.hasAmount()) ||
            (this.props.currentTicket && (parseFloat(discounts) >= parseFloat(this.props.amountToPay)))
            }
            onClick={debounce(async () => {
              if (this.availableDiscount()) {
                this.props.caisse_redirection('payment');
                this.discountSelectedArticles();
              } else {
                warningToast('exceded_discount', { position: 'top-right' });
              }
            }, 1000)
            }
          >{i18n.t('validate_and_pay')}</Button>
        </div>
      </>
    );
  }

}

const mapDispatchToProps = (dispatch) => ({
  discount_list: (page = 1, size = 100, search = null) => dispatch({
    type: 'INDEX_REQUEST_DISCOUNT',
    payload: { page, size, search },
  }),
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
  article_to_pay: (article, amount, rest) => dispatch({
    type: 'ARTICLE_TO_PAY_REQUEST',
    payload: { article, amount, rest },
  }),
  caisse_redirection: (value) => dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: { value } }),
});

const mapStateToProps = state => {
  return {
    discounts: state.discount.discounts,
    loading: state.discount.loading,
    currentTicket: state.caisse.currentTicket,
    articleToPay: state.caisse.articleToPay,
    amountToPay: state.caisse.amountToPay,
    restAmountToPay: state.caisse.restAmountToPay,
    currentNote: state.caisse.currentNote,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscountContent));