import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  videoPlanningService: {
    index: async (terrain_id) => {
      const result = await getMethod('/videoPlannings/?terrain_id=' + terrain_id);
      return result.data;
    },

    store: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await postMethod('/videoPlannings', newData);
      return result.data;
    },

    edit: async (id) => {
      const result = await getMethod('/videoPlannings/' + id);
      return result.data;
    },

    update: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await putMethod('/videoPlannings/' + data.id, newData);
      return result.data;
    },

    destroy: async (id) => {
      const result = await deleteMethod('/videoPlannings/' + id);
      return result.data;
    },

    terrains: async (data) => {
      const result = await postMethod('/videos/planning/terrains/', data);
      return result.data;
    },

    hours: async (data) => {
      const result = await postMethod('/videos/planning/hours/', data);
      return result.data;
    },

    list: async (data) => {
      const result = await postMethod('/videos/planning/list/', data);
      return result.data;
    },

  },
};