import React, { useEffect, useRef, useState } from "react";
import "./TimeInput.css";
import { Icon, Loader } from "semantic-ui-react";
import OverlayComponent from "../OverlayComponent/OverlayComponent";

let minutes = Array(60)
  .fill(0)
  .map((it, i) => {
    if (i <= 9) return "0" + i;
    else return "" + i;
  });
let hoursValues = Array(24)
  .fill(0)
  .map((it, i) => {
    if (i <= 9) return "0" + i;
    else return i + "";
  });
const max_visible = 5;
const middle = Math.ceil(max_visible / 2);

const TimeInput = (props) => {
  const refElement = useRef();
  const setValue = (val, arr, def) => {
    if (val === "") return def;
    else if (val === null) return def;
    else {
      return arr.indexOf(val) - Math.floor(middle) + 1;
    }
  };

  const testValue = (val, i) => {
    if (val === null || val === "") return "";
    else return val.split(":")[i];
  };

  let wheelEvent = (e) => {
    e.preventDefault();
  };

  const mainRef = useRef(null);

  const [direction, setDirection] = useState(props.direction); // 0 :down , 1: up
  const [hours, setHours] = useState(
    !props.extends ? hoursValues : [...hoursValues, "24"]
  );
  const [show, setShow] = useState(false);
  const [hourIndex, setHourIndex] = useState(
    setValue(testValue(props.value, 0), hours, 8)
  );
  const [minuteIndex, setMinuteIndex] = useState(
    setValue(testValue(props.value, 1), minutes, 58)
  );

  const hoursVisible = new Array(max_visible);
  const minutesVisible = new Array(max_visible);

  const goMinute = (i) => {
    if (hoursVisible[middle - 1] === "24") return;
    setMinuteIndex(minuteIndex + (i - (middle - 1)));
  };
  const goHour = (i) => {
    setHourIndex(hourIndex + (i - (middle - 1)));
  };
  for (let i = 0; i < max_visible; i++) {
    let indexM = (minutes.length + minuteIndex + i) % minutes.length;
    minutesVisible[i] = minutes[indexM];
  }
  for (let i = 0; i < max_visible; i++) {
    let indexH = (hours.length + hourIndex + i) % hours.length;
    hoursVisible[i] = hours[indexH];
  }

  const clearEvent = () => {
    window.removeEventListener("wheel", wheelEvent);
  };

  useEffect(() => {
    if (show) window.addEventListener("wheel", wheelEvent, { passive: false });
    return () => clearEvent();
  }, [show]);

  useEffect(() => {
    if (props.allowedHours && props.allowedHours.length > 0) {
      setHours(
        props.allowedHours.map((it) => {
          let hours = parseInt(it);
          hours = it % 24;
          if (hours.toString().length === 1) hours = "0" + hours.toString();

          return hours;
        })
      );
    }
  }, [setHours, props.allowedHours]);

  useEffect(() => {
    let bodyRect = document.body.getBoundingClientRect(),
      elemRect = mainRef.current.getBoundingClientRect(),
      offset = elemRect.top - document.body.clientHeight;
    if (show) {
      if (props.direction !== null) setDirection(props.direction);
      else {
        if (Math.abs(offset) >= 380) {
          setDirection(0);
        } else {
          setDirection(1);
        }
      }
    }
  }, [mainRef, show]);

  useEffect(() => {
    if (hoursVisible[middle - 1] === "24") {
      setMinuteIndex(setValue(testValue("", 1), minutes, 58));
    }
  }, [hourIndex]);

  return (
    <div
      ref={mainRef}
      className={
        "timeInput-container custom-input input-bg " +
        (props.error ? " error" : "") +
        (props.disabled ? " timeInput-disabled " : " ") +
        " " +
        props.cssClass
      }
    >
      <span
        className={"timeInput-value"}
        onClick={() => {
          !props.disabled && setShow(true);
        }}
      >
        {!props.value ? "--:--" : props.value}
      </span>
      {props.loading && <Loader size={"tiny"} active={true} />}
      {show && !props.loading && (
        <OverlayComponent onClose={() => setShow(false)}>
          <div
            ref={refElement}
            className={
              "time-input-dropdown " +
              (direction ? "time-input-up" : "time-input-down")
            }
          >
            <div className={"time-input-holder"} />

            <div>
              <div
                className={"time-input-items"}
                onWheel={(e) => {
                  if (e.deltaY > 0)
                    setHourIndex((hourIndex + 1) % hours.length);
                  else setHourIndex((hourIndex - 1) % hours.length);
                }}
              >
                <span
                  className={"time-input-top"}
                  onClick={() => setHourIndex((hourIndex - 1) % hours.length)}
                >
                  <Icon name={"angle up"} />
                </span>
                {hoursVisible.map((it, i) => (
                  <span
                    className={"time-input-item"}
                    key={`hours-${it}-${i}`}
                    onClick={() => goHour(i)}
                  >
                    {it}
                  </span>
                ))}
                <span
                  className={"time-input-bottom"}
                  onClick={() => setHourIndex((hourIndex + 1) % hours.length)}
                >
                  <Icon name={"angle down"} />
                </span>
              </div>
              <div
                className={"time-input-items"}
                style={{
                  opacity: hoursVisible[middle - 1] === "24" ? 0.4 : 1,
                }}
                onWheel={(e) => {
                  if (hoursVisible[middle - 1] === "24") return;

                  if (e.deltaY > 0) setMinuteIndex(minuteIndex + 1);
                  else setMinuteIndex(minuteIndex - 1);
                }}
              >
                <span
                  className={"time-input-top"}
                  onClick={() => {
                    if (hoursVisible[middle - 1] === "24") return;
                    setMinuteIndex(minuteIndex - 1);
                  }}
                >
                  <Icon name={"angle up"} />
                </span>
                {minutesVisible.map((it, i) => (
                  <span
                    className={"time-input-item"}
                    key={`minutes-${it}-${i}`}
                    onClick={() => goMinute(i)}
                  >
                    {it}
                  </span>
                ))}
                <span
                  className={"time-input-bottom"}
                  onClick={() => {
                    if (hoursVisible[middle - 1] === "24") return;

                    setMinuteIndex(minuteIndex + 1);
                  }}
                >
                  <Icon name={"angle down"} />
                </span>
              </div>
            </div>
            <div className={"time-input-control"}>
              <span
                className={"time-input-icon"}
                onClick={() => {
                  setShow(false);
                  if (hoursVisible.length > middle - 1)
                    props.onChange(
                      hoursVisible[middle - 1] +
                        ":" +
                        minutesVisible[middle - 1]
                    );
                }}
              >
                <Icon name={"check"} />
              </span>
              <span
                className={"time-input-icon"}
                onClick={() => {
                  setShow(false);
                  clearEvent();
                }}
              >
                <Icon name={"close"} />
              </span>
            </div>
          </div>
        </OverlayComponent>
      )}
    </div>
  );
};
TimeInput.defaultProps = {
  direction: null,
  disabled: false,
  error: false,
  cssClass: "",
  value: "",
  size: max_visible,
  allowedHours: null,
  extends: false,
  loading: false,
  errormsg: "",
};

export default TimeInput;
