import { getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  terrainTimesService: {
    store: async (data) => {
      const result = await postMethod('/terrainTimes', data);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/terrainTimes/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/terrainTimes/' + data.id, data);
      return result.data;
    },
  },
};
