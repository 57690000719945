import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { getFormData, showFormData } from '../helpers/formDataHandler';
import { exportFile } from '@/helpers/exportDownloader';

export default {
  equipmentService: {
    //index
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/equipment?page=' + page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : ''),
      );
      return result.data;
    },

    //store
    store: async (data) => {
      let {name,icon: img} = data;
      let newData = {name,img};
      let formData = getFormData(newData);

      const result = await postMethod('/equipment', formData);
      return result.data;
    },

    //edit
    edit: async (id) => {
      const result = await getMethod('/equipment/' + id);
      return result.data;
    },

    //update
    update: async (data) => {
      let {id, name, icon: img} = data;
      let newData = {id,name,img};
      let formData = getFormData(newData);
      formData.append('_method', 'put');
      showFormData(formData);

      const result = await postMethod('/equipment/' + data.id, formData);
      return result.data;
    },

    //destroy
    destroy: async (id) => {
      const result = await deleteMethod('/equipment/' + id);
      return result.data;
    },

    //export
    exportExcel: async ({ search }) => {
      const response = await getMethod(
        '/equipmentsExport?complex_id=' + localStorage.getItem('complex_id') +
        (search && search.search_name ? '&search_name=' + search.search_name : ''),
        {responseType: 'blob'}
      );

      exportFile(response, 'Equipement');
    }
  },
};
