import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import discountService from '../../services/discountService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingDiscount: ['data'],

  indexRequestDiscount: ['payload'],
  indexResponseDiscount: ['data'],

  storeRequestDiscount: ['payload'],
  storeResponseDiscount: ['data'],

  editRequestDiscount: ['id'],
  editResponseDiscount: ['data'],

  updateRequestDiscount: ['payload'],
  updateResponseDiscount: ['data'],

  destroyRequestDiscount: ['id'],
  destroyResponseDiscount: ['data'],
});

// Initial state
const INITIAL_STATE = {
  discounts: [],
  discount: null,
  loading: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingDiscount({ loading: true }));
    const data = yield call(discountService.discountService.index, action.payload);
    yield put(Creators.indexResponseDiscount(data));
    yield put(Creators.loadingDiscount({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingDiscount({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingDiscount({ loading: true }));
    const data = yield call(discountService.discountService.store, action.payload);
    yield put(Creators.storeResponseDiscount(data));
    yield put(Creators.loadingDiscount({ loading: false }));
    yield history.push('/discounts');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingDiscount({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingDiscount({ editLoading: true }));
    const data = yield call(discountService.discountService.edit, action.payload);
    yield put(Creators.editResponseDiscount(data));
    yield put(Creators.loadingDiscount({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingDiscount({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingDiscount({ loading: true }));
    const data = yield call(discountService.discountService.update, action.payload);
    yield put(Creators.updateResponseDiscount(data));
    yield put(Creators.loadingDiscount({ loading: false }));
    yield history.push('/discounts');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(
      Creators.loadingDiscount({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingDiscount({ deleteLoading: true }));
    const data = yield call(discountService.discountService.destroy, action.payload);
    yield put(Creators.destroyResponseDiscount(data));
    yield put(Creators.loadingDiscount({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_DISCOUNT, index);
  yield takeLatest(Types.STORE_REQUEST_DISCOUNT, store);
  yield takeLatest(Types.EDIT_REQUEST_DISCOUNT, edit);
  yield takeLatest(Types.UPDATE_REQUEST_DISCOUNT, update);
  yield takeLatest(Types.DESTROY_REQUEST_DISCOUNT, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    discounts: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    discount: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const loading_discount = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const discountReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_DISCOUNT]: index_response,
  [Types.STORE_RESPONSE_DISCOUNT]: store_response,
  [Types.EDIT_RESPONSE_DISCOUNT]: edit_response,
  [Types.UPDATE_RESPONSE_DISCOUNT]: update_response,
  [Types.DESTROY_REQUEST_DISCOUNT]: destroy_request,
  [Types.DESTROY_RESPONSE_DISCOUNT]: destroy_response,
  [Types.LOADING_DISCOUNT]: loading_discount,
});
