import { getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  hourConfigsService: {
    store: async (data) => {
      const result = await postMethod('/hourConfigs', data);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/hourConfigs/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/hourConfigs/' + data.id, data);
      return result.data;
    },
  },
};
