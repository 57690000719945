import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { putMethod } from '../helpers';
import { exportFile } from '@/helpers/exportDownloader';

export default {
  planningTypeService: {
    //index
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/plannings?complex_id=' + localStorage.getItem('complex_id') +
        (page ? '&page=' + page : '') +
        (size ? '&size=' + size : '') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_terrain ? '&search_terrain=' + search.search_terrain : ''),
      );

      return result.data;
    },

    //store
    store: async (data) => {
      let {
        name,
        terrains, activity_id,
      } = data;
      let complex_id = localStorage.getItem('complex_id');

      let newData = {
        name, terrains, complex_id, activity_id,
      };


      const result = await postMethod('/plannings', newData);
      return result.data;
    },

    //edit
    edit: async (id) => {
      const result = await getMethod('/plannings/' + id);
      return result.data;
    },

    //update
    update: async (data) => {
      let {
        id,
        name,
        terrains,
        activity_id,
      } = data;
      let complex_id = localStorage.getItem('complex_id');

      let newData = {
        id,
        name,
        terrains,
        complex_id,
        activity_id,
      };

      const result = await putMethod('/plannings/' + data.id, newData);
      return result.data;
    },

    //destroy
    destroy: async (id) => {
      const result = await deleteMethod('/plannings/' + id);
      return result.data;
    },

    //export
    exportExcel: async ({ search }) => {
      const response = await getMethod(
        '/planningTypesExport?complex_id=' + localStorage.getItem('complex_id') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_terrain ? '&search_terrain=' + search.search_terrain : ''),
        {responseType: 'blob'}
      );

      exportFile(response, 'Types_planification');
    }
  }
};
