import React from 'react';
import i18n from "../../i18n";
import './ErrorBoundary.css';
import { Button } from 'semantic-ui-react';
import MainLayout from '../../Layout/MainLayout/MainLayout';

/**
 * @Component
 * this component handle error happen in the render method
 * this component will be mainly used in routes/index file when import doesn't catch an error
 * ps this will work only in production mode
 *
 * */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }



  render() {
    if (this.state.hasError) {
      return(

        <div className={"error-container"}  style={{backgroundImage:`url(${require("../../assets/fix_bug.svg")})`}}>
          <p>{i18n.t("error-refresh")}</p>
          <Button color={'red'} onClick={()=>{
            document.location.reload();
          }} >{i18n.t("refresh")} </Button>
        </div>

      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
