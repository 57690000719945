import moment from "moment";
import { baseUrl } from "./index";
import store from "../store";

const MAX_REFRESH_MINUTE = 20160; // 14 days

let subscribers = [];
let isFetchingToken = false;

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const notifier = (newTokenObject) => {
  subscribers.filter(callback => callback(newTokenObject));
};


const handleTokenFormatting = (newTokenObject, config, resolve) => {
  localStorage.setItem("token", newTokenObject.access_token);
  localStorage.setItem("expirationDate", newTokenObject.token_payload.exp);
  config.headers.Authorization = `Bearer ${newTokenObject.access_token}`;
  resolve(config);
};

/**
 * handle interpolator in axois
 * check if expirationDate is expired than refresh the token
 * @param config
 * @return {Promise<newConfig>}
 */
const handleInterpolatorRequest = async (config) => {
  let expirationDate = localStorage.getItem("expirationDate");
  let token = localStorage.getItem("token");

  let filteredUrl = [
    "/logout",
    "/resetPassword",
    "/sendPasswordResetLink",
    "/register"
  ];

  let containUrl = filteredUrl.some(it => config.url.includes(it));

  if (
    !containUrl &&
    expirationDate &&
    moment.unix(expirationDate).isBefore(moment()) &&
    Math.abs(moment.unix(expirationDate).diff(moment(), "minutes")) < MAX_REFRESH_MINUTE) {
    if (!isFetchingToken) {
      isFetchingToken = true;
      fetch(baseUrl + "/refresh", {
        method: "get",
        headers: new Headers({
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        })
      })
        .then(data => data.json())
        .then(data => {
          notifier(data);
          isFetchingToken = false;
          subscribers = [];
        })
        .catch(err => {
          isFetchingToken = false;
          subscribers = [];
          store.dispatch({
            type: "LOGOUT_REQUEST"
          });
        });
    }

    let newConfig = await new Promise((resolve) => {
      addSubscriber((newTokenObject) => handleTokenFormatting(newTokenObject, config, resolve));
    });
    return newConfig;
  } else {
    return config;
  }
};


export { handleInterpolatorRequest };