import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import activityService from '../../services/activityService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingActivity: ['data'],

  indexRequestActivity: ['payload'],
  indexResponseActivity: ['data'],

  storeRequestActivity: ['payload'],
  storeResponseActivity: ['data'],

  editRequestActivity: ['id'],
  editResponseActivity: ['data'],

  updateRequestActivity: ['payload'],
  updateResponseActivity: ['data'],

  destroyRequestActivity: ['id'],
  destroyResponseActivity: ['data'],
});

// Initial state
const INITIAL_STATE = {
  activities: [],
  activity: null,
  loading: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingActivity({ loading: true }));
    const data = yield call(activityService.activityService.index, action.payload);
    yield put(Creators.indexResponseActivity(data));
    yield put(Creators.loadingActivity({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingActivity({ loading: true }));
    const data = yield call(activityService.activityService.store, action.payload);
    yield put(Creators.storeResponseActivity(data));
    yield put(Creators.loadingActivity({ loading: false }));
    yield history.push('/configuration/activities');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingActivity({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
    //
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingActivity({ editLoading: true }));
    const data = yield call(activityService.activityService.edit, action.payload);
    yield put(Creators.editResponseActivity(data));
    yield put(Creators.loadingActivity({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingActivity({ editLoading: false }));
    history.push('/error-404');
    //
  }
}

// //update
function* update(action) {
  try {
    yield put(Creators.loadingActivity({ loading: true }));
    const data = yield call(activityService.activityService.update, action.payload);
    yield put(Creators.updateResponseActivity(data));
    yield put(Creators.loadingActivity({ loading: false }));
    yield history.push('/configuration/activities');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(
      Creators.loadingActivity({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
    //
  }
}

// //destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingActivity({ deleteLoading: true }));
    const data = yield call(activityService.activityService.destroy, action.payload);
    yield put(Creators.destroyResponseActivity(data));
    yield put(Creators.loadingActivity({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
    //
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_ACTIVITY, index);
  yield takeLatest(Types.STORE_REQUEST_ACTIVITY, store);
  yield takeLatest(Types.EDIT_REQUEST_ACTIVITY, edit);
  yield takeLatest(Types.UPDATE_REQUEST_ACTIVITY, update);
  yield takeLatest(Types.DESTROY_REQUEST_ACTIVITY, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activities: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activity: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const activityReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_ACTIVITY]: index_response,
  [Types.STORE_RESPONSE_ACTIVITY]: store_response,
  [Types.EDIT_RESPONSE_ACTIVITY]: edit_response,
  [Types.UPDATE_RESPONSE_ACTIVITY]: update_response,
  [Types.DESTROY_REQUEST_ACTIVITY]: destroy_request,
  [Types.DESTROY_RESPONSE_ACTIVITY]: destroy_response,
  [Types.LOADING_ACTIVITY]: loading,
});
