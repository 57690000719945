import React from 'react';
import './TicketList.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TicketListItem from './TicketListItem';
import CreditListItem from './CreditListItem';
import AdvanceListItem from './AdvanceListItem';
import CreditAccountListItem from './CreditAccountListItem';
import ReloadListItem from './ReloadListItem';
import { Grid, GridColumn, Input, Loader, Pagination, Table } from 'semantic-ui-react';
import CustomDropdown from '../../CustomDropdown/CustomDropdown';
import Image from '../../ImageComponent/Image';
import InvoiceListItem from './InvoiceListItem';
import moment from 'moment';
import i18n from '@/i18n';
import { normalDate, nullString } from '../../../helpers/formatting';
import { getTicketType } from '../../../helpers/caisseFormatting';

export const statusOptions = [
  { key: -1, text: 'Tout', value: -1 },
  { key: 0, text: 'cashed', value: 0 },
  { key: 1, text: 'waiting', value: 1 },
  { key: 2, text: 'canceled', value: 2 },
];
export const typeOptions = [
  { key: 'Tout', text: 'Tout', value: 'all' },
  { key: 'ticket', text: 'Ticket', value: 'ticket' },
  { key: 'credit_account', text: 'Remboursement crédit', value: 'credit_account' },
  { key: 'avoir', text: 'Avoir', value: 'credit' },
  { key: 'advance', text: 'Acompte', value: 'advance' },
  { key: 'reload', text: 'Recharge', value: 'reload' },
  { key: 'subscription', text: 'Abonnement classique', value: 'subscription' },
];

class TicketList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      type: null,
      user_id: '',
      date: moment().format('YYYY-MM-DD'),
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
  }

  onChangeHandle(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  getTicketByType = (page) => {
    if (this.state.type === 'ticket') {
      if (this.state.status === 0) {
        this.props.getTickets({
          page, size: this.props.size, status: [0],
          date: this.state.date,
        }); // 0 : ticket
      } else if (this.state.status === 1) {
        this.props.getTickets({
          page, size: this.props.size, status: [1],
          date: this.state.date,
        }); // 1 : pending
      } else if (this.state.status === 2) {
        this.props.getTickets({
          page, size: this.props.size, status: [2],
          date: this.state.date,
        }); // 2 : cancled
      }
    } else if (this.state.type === 'credit_account') {
      if (this.state.status === 0) {
        this.props.getTickets({
          page, size: this.props.size, status: [0],
          is_credit_account: 1,
          date: this.state.date,
        }); // 0 : ticket
      } else if (this.state.status === 2) {
        this.props.getTickets({
          page, size: this.props.size, status: [2],
          is_credit_account: 1,
          date: this.state.date,
        }); // 2 : cancled
      }
    } else if (this.state.type === 'credit') {
      this.props.getTickets({
        page, size: this.props.size, status: [0, 1],
        is_credit: 1,
        date: this.state.date,
      }); // credit
    } else if (this.state.type === 'advance') {
      if (this.state.status === 0) {
        this.props.getTickets({
          page, size: this.props.size, status: [0],
          is_advance: 1,
          date: this.state.date,
        }); // advance
      } else if (this.state.status === 2) {
        this.props.getTickets({
          page, size: this.props.size, status: [2],
          is_advance: 1,
          date: this.state.date,
        }); // cancled
      } else if (this.state.status === 1) {
        this.props.getTickets({
          page, size: this.props.size, status: [1],
          is_advance: 1,
          date: this.state.date,
        }); //pending
      }

    } else if (this.state.type === 'reload') {
      if (this.state.status === 0) {
        this.props.getTickets({
          page, size: this.props.size, status: [0],
          is_reload: 1,
          date: this.state.date,
        }); // pending
      } else if (this.state.status === 1) {
        this.props.getTickets({
          page, size: this.props.size, status: [1],
          is_reload: 1,
          date: this.state.date,
        }); // reload
      }
    } else if (this.state.type === 'subscription') {
      if (this.state.status === 0) {
        this.props.getTickets({
          page, size: this.props.size, status: [0],
          is_subscription: 1,
          date: this.state.date,
        }); // pending
      } else if (this.state.status === 1) {
        this.props.getTickets({
          page, size: this.props.size, status: [1],
          is_subscription: 1,
          date: this.state.date,
        }); // subscription
      }
    }
    else if (this.state.type === 'all') {
      if (this.state.status === -1) {
        this.props.getTickets({
          page, size: this.props.size,
          status: [0,1,2],
          all: true,
          date: this.state.date,
        }); // all
      }
      if (this.state.status === 0) {
        this.props.getTickets({
          page, size: this.props.size,
          status: [0],
          all: true,
          date: this.state.date,
        }); // all
      }
      if (this.state.status === 1) {
        this.props.getTickets({
          page, size: this.props.size,
          status: [1],
          all: true,
          date: this.state.date,
        }); // all
      }
      if (this.state.status === 2) {
        this.props.getTickets({
          page, size: this.props.size,
          status: [2],
          all: true,
          date: this.state.date,
        }); // all
      }
    }

  };

  componentDidMount() {
    if (this.props.caisseRedirection.value) {
      this.setState({
        type: this.props.caisseRedirection.type,
        status: this.props.caisseRedirection.status,
        date: this.props.caisseRedirection.date,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.currentTicket !== prevProps.currentTicket)) {
      let ticket = this.props.currentTicket;
      if(ticket){
        if (ticket.status === 0) {
          this.props.caisse_redirection('ticket details', 0, getTicketType(ticket), moment(normalDate(ticket.date)).format('YYYY-MM-DD'));
        }
        if (ticket.status === 2) {
          this.props.caisse_redirection('ticket details', 2, getTicketType(ticket), moment(normalDate(ticket.date)).format('YYYY-MM-DD'));
        }
        if (ticket.status === 1) {
          this.props.caisse_redirection('edit ticket', 1, getTicketType(ticket), moment(normalDate(ticket.date)).format('YYYY-MM-DD'));
        }
      }
      if (!ticket && this.state.type === 'ticket') {
        let caisseRedirection = this.props.caisseRedirection;
        this.props.caisse_redirection('ticket details', caisseRedirection?.status, caisseRedirection?.type, caisseRedirection?.date);
      }

    }

    if (this.props.caisseRedirection && prevProps.caisseRedirection &&
      (this.props.caisseRedirection.status !== prevProps.caisseRedirection.status)) {
      this.setState({
        type: this.props.caisseRedirection.type ? this.props.caisseRedirection.type : 'ticket',
        status: this.props.caisseRedirection.status ? this.props.caisseRedirection.status : 0,
        date: this.props.caisseRedirection.date ? this.props.caisseRedirection.date : '',
      });
    }

    if (
      (this.state.status !== prevState.status || this.state.type !== prevState.type || this.state.date !== prevState.date) &&
      (nullString(this.props.caisseRedirection.type) === nullString(prevProps.caisseRedirection.type))
    ) {
      this.getTicketByType(1);
    }

    // if (this.state.user_id !== prevState.user_id && this.props.type === 'invoice')
    //   this.props.getInvoice({ page: 1, size: 10, user_id: this.state.user_id });

  }

  renderInvoicesList = () => {
    let { invoices } = this.props;
    return invoices ?
      invoices.map((it, i) => (<InvoiceListItem key={'invoice-' + i} invoice={it}/>)) :
      <Table>
        <Table.Body className={'empty-table-body'}>
          <Table.Row className={'empty-row'}>
            <Table.Cell textAlign={'right'}> <Image
              src={require('../../../assets/empty.png')}/></Table.Cell>
            <Table.Cell textAlign={'left'}><p>{i18n.t('empty_table')}</p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>;
  };

  renderTicketsList() {
    let tickets = this.props.tickets;
    if (Object.entries(tickets).length > 0) {
      return tickets.map((item, index) => {
        if (parseInt(item.is_credit_account) === 0 && parseInt(item.is_credit) === 0 && 
          parseInt(item.is_advance) === 0 && parseInt(item.is_reload) === 0 && parseInt(item.is_subscription) === 0) {
          return <TicketListItem key={'pending' + index} ticket={item} type={item.status} date={this.state.date}/>;
        }
        if (parseInt(item.is_credit_account) === 1){
          return <CreditAccountListItem key={'credit_account' + index} creditAccount={item} type={item.status}/>;
        } 
        if (parseInt(item.is_credit) === 1){
          return <CreditListItem key={'credit' + index} credit={item}/>;
        } 
        if (parseInt(item.is_advance) === 1) {
          return <AdvanceListItem key={'advance' + index} advance={item} type={item.status}/>;
        } 
        if (parseInt(item.is_reload) === 1) {
          return <ReloadListItem key={'reload' + index} ticket={item} type={item.status}/>;
        } 
        if (parseInt(item.is_subscription) === 1) {
          return <ReloadListItem key={'subscription' + index} ticket={item} type={item.status}/>;
        }
      });
    } else {
      return (
        <Table>
          <Table.Body className={'empty-table-body'}>
            <Table.Row className={'empty-row'}>
              <Table.Cell textAlign={'right'}> <Image
                src={require('../../../assets/empty.png')}/></Table.Cell>
              <Table.Cell textAlign={'left'}><p>{i18n.t('empty_table')}</p>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      );
    }
  }

  handlePaginationChange = (e, data) => {
    this.props.type === 'invoice' ?
      this.props.getInvoice({ page: data.activePage, size: 10, user_id: this.state.user_id })
      :
      this.getTicketByType(data.activePage);
  };

  redirectionTest() {
    if (this.props.caisseRedirection.value !== 'ticket') {
      this.props.caisse_redirection('ticket', this.state.status, this.state.type, this.state.date);
    }
  }

  render() {
    return (
      <div className="ticket-list">
        <h3 className="ticket-list-header">
          {this.props.type === 'invoice' ? i18n.t('list-invoices') : i18n.t('list-tickets')}
        </h3>
        <div className="search-block">
          <Grid>
            <Grid.Column width={5}>
              <CustomDropdown
                fetching={false}
                name="type"
                placeholder={i18n.t('type')}
                render={(it) => (
                  <>
                    <span>{i18n.t(it.key)}</span>{' '}
                  </>
                )}
                options={typeOptions}
                keyword={'value'}
                filter={'text'}
                value={this.state.type}
                onChange={async (type) => {
                  await this.setState({
                    type,
                    status: (type === 'ticket') ? 1 : 
                      (type === 'all' || type === 'credit_account' || type === 'advance' || type === 'reload' || type === 'subscription') ? 0 : 
                        this.state.status,
                    date: (type === 'ticket' || type === 'all') ? moment().format('YYYY-MM-DD') : '',
                  });
                  await this.redirectionTest();
                }

                }
              />
            </Grid.Column>
            {(this.state.type === 'all' || this.state.type === 'ticket' || this.state.type === 'credit_account' || this.state.type === 'advance' || this.state.type === 'reload' || this.state.type === 'subscription') &&
            <Grid.Column width={5}>
              <CustomDropdown
                fetching={false}
                name="ticketStatus"
                placeholder={i18n.t('status')}
                keyword={'key'}
                filter={'text'}
                value={this.state.status}
                render={(it) => (
                  <>
                    <span>{i18n.t(it.text)}</span>
                  </>
                )}
                options={(this.state.type === 'all' || this.state.type === 'ticket' || this.state.type === 'advance') ? statusOptions : 
                  (this.state.type === 'reload' ?
                  statusOptions.filter(itm => itm.key !== 2 && itm.key > 0) : statusOptions.filter(itm => (itm.key !== 1 && itm.key > 0)))
                }
                onChange={async (status) => {
                  await this.setState({ status });
                  await this.redirectionTest();
                }}
              />
            </Grid.Column>
            }
            <GridColumn width={2}>
              <Input
                type={'date'}
                name={'date'}
                placeholder={i18n.t('date')}
                value={this.state.date ? this.state.date : '' }
                onChange={async (e) => {
                  await this.onChangeHandle(e);
                  this.redirectionTest();
                }}
                style={{ fontSize: '12px' }}
              />
            </GridColumn>
          </Grid>
          {/* )} */}
        </div>
        <div className="ticket-list-body">
          {this.props.type === 'invoice' ? (
            this.props.invoiceLoading ? (
              <div className={'list-ticket-loading'}>
                <Loader active={true} inline={'centered'}/>
              </div>
            ) : (
              this.renderInvoicesList()
            )
          ) : this.props.loadingTicket ? (
            <div className={'list-ticket-loading'}>
              <Loader active={true} inline={'centered'}/>
            </div>
          ) : (
            this.renderTicketsList()
          )}
        </div>
        <div className={'ticket-pagination'}>
          <Pagination
            activePage={this.props.page}
            boundaryRange={1}
            onPageChange={(e, data) => this.handlePaginationChange(e, data)}
            size="mini"
            siblingRange={1}
            totalPages={this.props.last}
            ellipsisItem={'...'}
            firstItem={null}
            lastItem={null}
            prevItem={null}
            nextItem={null}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  caisse_redirection: (value, status, type, date) =>
    dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: { value, status, type, date } }),
  getTickets: (data) => dispatch({ type: 'GET_TICKETS_REQUEST', payload: { ...data } }),
  getInvoice: (data) =>
    dispatch({ type: 'INDEX_INVOICE_REQUEST', payload: { user_id: '', size: 10, ...data } }),
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
  updateCurrentNote: (id) => dispatch({ type: 'CURRENT_NOTE_REQUEST', payload: id }),

});

const mapStateToProps = (state) => {
  return {
    tickets: state.caisse.tickets,
    loadingTicketById: state.caisse.loadingTicketById,
    invoices: state.caisse.invoices,
    loadingTicket: state.caisse.loadingTicket,
    loadingInvoice: state.caisse.loadingInvoice,
    page: state.caisse.page,
    size: state.caisse.size,
    last: state.caisse.last,
    caisseRedirection: state.caisse.caisse_redirection,
    currentTicket: state.caisse.currentTicket,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TicketList));
