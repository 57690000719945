import React from "react";
import CustomInput from "@/components/CustomInput/CustomInput";
import { Button, Checkbox, Grid, Icon, Image, Input, Modal, Table } from "semantic-ui-react";
import i18n from "@/i18n";
import TicketAccordionItem from "./TicketAccordionItem";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  cumulAmountFromArticlePrice,
  cumulArticlePayedAmountFromPaymentArticle,
  cumulDiscountArticlesFromParticipant,
  cumulDiscountsFromArticle,
  cumulParticipantPayedAmountFromPaymentParticipant,
  cumulPayedAmountFromPaymentTypes,
  cumulPaymentArticlesFromParticipant,
  cumulPaymentTypesFromArticle,
  cumulTotalDiscountFromDiscounts,
  getAmountToPay,
  getArticleDiscount,
  getParentIdIsActivity,
  getParticipantDiscount,
  haveAdvance,
  isSubscriptionPaymentType,
  setArticleStatus,
  setParticipantStatus
} from "@/helpers/ticket";
import {
  activityLinkedToNote,
  addArticleNote,
  allParticipantsOfNotePayed,
  cancelNote,
  getCurrentNote,
  getCurrentNoteIndex,
  getPayedAmountNote,
  hasAllParticipantsByNote,
  noteHasArticles,
  refreshNote,
  removeParticipantFromArticleNote,
  updateTicketArticle
} from "@/helpers/caisse/noteFormatting";
import { ticketMainAccordionStyle } from "../../../helpers/caisse/ticketStyle";
import { getHT } from "@/helpers/caisse/tvaCalcul";
import { valeur } from "../../../pages/CaissePage/CaissePage";
import ModalConfirm from "../../ModalConfirm/ModalConfirm";
import { warningToast } from "@/helpers/customToast";
import { getAdvanceTicket, reservationResponse, resetReservationState } from "../../../helpers/reservationFormatting";
import { Creators as reservationCreators } from "../../../store/ducks/reservation";
import { nullStringForTable, nullStringNumber } from "../../../helpers/formatting";
import Calculator from "../Content/Payment/Calculator";
import { cumulDiscountArticles, getLastOccurrenceByIdInArticle, getQte } from "../../../helpers/ticket";
import { getActivityAmount, getStatusOfArticleByNote } from "../../../helpers/caisseFormatting";
import { exactDivision } from "@/helpers/operation";
import caisseService from "../../../services/caisseService";

class TicketAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      status: null,
      selected_article: false,
      nb_occ_article: 1,
      nb_article: 0,
      nb_participant: this.props.article.default_participants_nb,
      deleteArticleModalOpen: false,
      editParticipantNbModal: false,
      enabledEditParticipantNbModal: false,
      unpayModalOpen: false,
      cancelArticleDiscountModalOpen: false,
      //credit_value: null,
      //confirmCreditModalOpen: false,
      articleNoteQtyModalOpen: false, //note division
      qty: 0,
      clonedTicket: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ status: this.props.status });
    if (this.props.articleToPay && this.props.articleToPay.hasOwnProperty(this.props.article.id)) {
      let totallySelected = this.props.article.default_participants_nb === this.props.articleToPay[this.props.article.id].length;
      if (totallySelected) {
        this.setState({ selected_article: !this.state.selected_article });
      }
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    //select unselect article
    if (this.props.articleToPay !== prevProps.articleToPay) {
      let exist = this.props.articleToPay.hasOwnProperty(this.props.article.id);
      let existPrev = prevProps.articleToPay.hasOwnProperty(this.props.article.id);

      if ((exist !== existPrev) && ((this.state.selected_article && !exist) || (!this.state.selected_article && exist))) {
        let totallySelected = true;
        if (this.props.article.parent?.is_activity === 1 && this.props.article.child?.is_res === 1 && exist) {
          totallySelected = this.props.article.default_participants_nb === this.props.articleToPay[this.props.article.id].length;
        }
        if (totallySelected) {
          this.setState({ selected_article: !this.state.selected_article });
        }
      }
    }
  };


  handleClose = () => {
    this.setState({
      deleteArticleModalOpen: false,
      editParticipantNbModal: false,
      enabledEditParticipantNbModal: false,
      unpayModalOpen: false,
      cancelArticleDiscountModalOpen: false
    });
  };

  /**
   * start select article to pay/unpay
   **/
  unpayArticle = async () => {
    //to check
    let currentTicket = { ...this.props.currentTicket };
    let currentArticle = { ...this.props.article };

    //delete link advance/reservation
    if (currentArticle.parent.is_activity === 1 && currentArticle.child.is_res === 1 &&
      (currentTicket.article_reservation && currentArticle.id.toString() === currentTicket.article_reservation.toString()) &&
      (!currentArticle.note_index || hasAllParticipantsByNote(currentTicket, currentArticle.id, this.props.note_index))
    ) {
      let reservation = currentTicket.reservation;
      let reservationTicket = { ...reservation };
      reservationTicket.is_advance = 0;
      reservationTicket.advance_id = null;
      reservationTicket.advance = null;
      reservationTicket.advance_ticket = null;
      let state = resetReservationState(); //convert reservation
      let reservationData = reservationResponse(state.reservationForm, reservationTicket);
      this.props.updateReservation(reservationData);
    }

    //unpay participants (activity case)
    if (currentArticle.parent.is_activity === 1 && currentArticle.child.is_res === 1) {
      let payment_participants = currentTicket.payments.participants.filter(
        (itm) => !(itm.article_id.toString() === currentArticle.id.toString() && itm.note_index === this.props.note_index)
      );
      currentTicket.payments.participants = payment_participants;
      currentTicket.participants.forEach(item => {
        item.payed_amount = cumulParticipantPayedAmountFromPaymentParticipant(currentTicket, item.user_index);
        item.status = setParticipantStatus(currentTicket, item);
      });
    }

    //unpay article
    let payment_articles = currentTicket.payments.articles.filter(
      (itm) => !(itm.article_id === currentArticle.id && itm.note_index === this.props.note_index)
    );
    currentTicket.payments.articles = payment_articles;

    currentTicket.articles.forEach(item => {
      if (item.id === currentArticle.id) {
        item.payed_amount = cumulArticlePayedAmountFromPaymentArticle(currentTicket, item.id);
        item.status = setArticleStatus(currentTicket, item);
      }
    });

    currentTicket.payments.types = cumulPaymentTypesFromArticle(currentTicket);
    currentTicket.payed_amount = cumulPayedAmountFromPaymentTypes(currentTicket);

    //refresh note
    let index = getCurrentNoteIndex(currentTicket, this.props.note_index);
    if (index < currentTicket.notes.length) {
      currentTicket.notes[index].payed_amount = getPayedAmountNote(currentTicket, this.props.note_index);
    }

    this.props.updateCurrentTicket(currentTicket);
    await this.props.updateTicket(currentTicket);
    //reset
    this.setState({ unpayModalOpen: false });
    this.props.caisse_redirection("home");

  };

  updateArticlePayment = async () => {
    const currentTicket = this.props.currentTicket;

    this.props.updateCurrentNote(this.props.note_index);
    currentTicket.noteInProgress = this.props.note_index ? true : false;
    this.props.updateCurrentTicket({ ...currentTicket });

    await this.props.participant_to_edit_payment(null);
    await this.props.article_to_edit_payment(this.props.article.id);
    await this.props.caisse_redirection("payment");
    this.handleClose();
  };

  renderUnpayArticleModal() {
    return (
      <Modal
        open={this.state.unpayModalOpen}
        onClose={async () => {
          await this.cancelDecrement();
          this.handleClose();
        }}
        size="tiny"
        className="modal-ticket"
      >
        <Modal.Content className="modal-content">
          <h3
            className="modal-content-header">Modifier{(this.props.currentTicket.status === 1 && !this.props.article.note_index) && <>/Annuler</>} paiement</h3>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={async () => {
                await this.cancelDecrement();
                this.handleClose();
              }}
            />
            <p>
              Voulez vous modifier {(this.props.currentTicket.status === 1) && <>ou annuler</>} le
              paiement de cet article?
            </p>
            <span className="btn-group">
              <Button className="modal-delete-btn" onClick={this.updateArticlePayment}>
                Modifier paiement
              </Button>
              {(this.props.currentTicket.status === 1) && (
                <Button className="modal-cancel-btn" onClick={async () => {
                  await this.cancelDecrement();
                  await this.unpayArticle();
                }}>
                  Annuler paiement
                </Button>
              )}
            </span>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  cancelDecrement = async () => {
    let currentTicket = { ...this.props.currentTicket };

    if (this.state.clonedTicket) {
      let ticket = JSON.parse(this.state.clonedTicket);
      await this.props.updateCurrentTicket(ticket);
      await this.props.decrement_occ_nb(null);
      this.setState({
        clonedTicket: null
      });
    } else if (this.props.decrementOccNb) {
      currentTicket.articles.map((item, index) => {
        if (item.id.toString() === this.props.article.id.toString()) {
          item.article_nb = parseInt(item.article_nb) + 1;
        }
      });
      currentTicket.amount =
        parseFloat(currentTicket.amount) + parseFloat(this.props.article.total_price);
      currentTicket.articles_nb = parseInt(currentTicket.articles_nb) + 1;
      await this.props.updateCurrentTicket(currentTicket);
      await this.props.decrement_occ_nb(null);
    }
  };


  /**
   * Note division qte modal
   */
  renderArticleNoteQtyModal() {
    let { articleRestAmount, availableArticleNb } = getQte(this.props.currentTicket, this.props.article);
    return (
      <Modal
        open={this.state.articleNoteQtyModalOpen}
        onClose={async () => {
          // await this.selectArticlesToPay();
          this.setState({ articleNoteQtyModalOpen: false });
        }}
        size="tiny"
        className="modal-ticket"
      >
        <Modal.Content className="modal-content qty-modal-note">
          <h3 className="modal-content-header">{i18n.t("note_division")}</h3>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={async () => {
                await this.selectArticlesToPay();
                this.setState({ articleNoteQtyModalOpen: false });
              }
              }
            />
            <Grid textAlign="center" style={{ margin: "auto" }}>
              <Grid.Column width={2}>
                <Input
                  name="nb_occ_article"
                  className="badge-qte badge-qte-main"
                  value={availableArticleNb}
                  disabled={true}
                />
              </Grid.Column>
              <Grid.Column width={9} className="note-item-label">
                {this.props.article.name +
                " " +
                (this.props.article.info ? this.props.article.info + " " : "") +
                (this.props.article.duration
                  ? this.props.article.duration.duration.replace(":", "H") + " "
                  : "") +
                (this.props.article.default_participants_nb !== null
                  ? this.props.article.default_participants_nb + " pers"
                  : "")
                }
              </Grid.Column>
              <Grid.Column className="note-item-label" width={2}>
                {((parseFloat(this.props.article.total_price) * availableArticleNb) - parseFloat(getArticleDiscount(this.props.currentTicket, this.props.article.id, this.props.currentNote))).toFixed(2)}
                {localStorage.getItem("currency")}
              </Grid.Column>
            </Grid>
            <p className="choose-qty">Choisir la quantité</p>
            <Calculator withOperator={false}
                        default={this.state.qty}
                        onChange={(value) => {
                          this.setState({ qty: value });
                        }}
            />
            <Button className="modal-delete-btn"
                    disabled={this.state.qty === 0 || availableArticleNb < this.state.qty}
                    onClick={() => {
                      this.pushArticleToNote(this.state.qty);
                      this.setState({ articleNoteQtyModalOpen: false });
                      this.props.article_to_pay([], 0, 0);
                    }}
            >
              OK
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  /**
   * Add article to note ,this function called when article is selected (selectArticle) or inside modal division
   *
   */
  pushArticleToNote = (qty) => {
    let { ticket, old_article_id, new_article_id } = addArticleNote(this.props.currentTicket, this.props.article, qty, this.props.currentNote);
    this.props.updateCurrentTicket({ ...ticket });
    let articleToPay = { ...this.props.articleToPay };
    delete articleToPay[old_article_id];
    articleToPay[new_article_id] = [];
    let articleToPartialPayment = [...this.props.articleToPartialPayment];
    let amountToPay = getAmountToPay(ticket, articleToPay, this.props.currentNote)[0];
    let restAmountToPay = getAmountToPay(ticket, articleToPay, this.props.currentNote)[1];
    this.props.article_to_pay(articleToPay, amountToPay, restAmountToPay);

    // cas article note_index
    this.props.article_to_partial_payment(articleToPartialPayment);
    this.props.caisse_redirection("note_division");
  };

  /**
   *
   */
  selectArticlesToPay = async () => {
    let ticket = { ...this.props.currentTicket };
    let notes = [...this.props.currentTicket.notes];
    let currentNote = getCurrentNote(ticket, this.props.currentNote);

    //unpay article
    if ((this.props.article.status === "payed" ||
      (this.props.note_index && this.props.article.parent && this.props.article.parent.is_activity === 1
        && this.props.article.child.is_res === 1 &&
        allParticipantsOfNotePayed(this.props.currentTicket, this.props.article.id, this.props.note_index))) &&
      parseFloat(this.props.article.payed_amount) > 0
    ) {
      this.setState({ unpayModalOpen: true });
    }
    //select to pay
    else {
      if (this.props.caisseRedirection.value === "discount") {
        this.props.caisse_redirection("home");
      }
      let articleToPay = { ...this.props.articleToPay };
      let articleToPartialPayment = [...this.props.articleToPartialPayment];

      if (this.props.note_index) {
        const currentTicket = this.props.currentTicket;
        if (this.props.currentNote !== this.props.note_index) {
          articleToPay = {};
          articleToPartialPayment = [];
        }
        this.props.updateCurrentNote(this.props.note_index);
        currentTicket.noteInProgress = this.props.note_index ? true : false;
        this.props.updateCurrentTicket({ ...currentTicket });
      }
      /**
       * reset participantToPartialPayment
       * select partial participant + select activity => deselect participant
       * in this case we should reset participantToPartialPayment
       */
      let participantIds = ticket.participants.filter(itm => itm.article_id === this.props.article.id).map(itm => itm.user_index);
      let participantToPartialPayment = this.props.participantToPartialPayment.filter(
        (itm) => !participantIds.includes(itm)
      );
      this.props.participant_to_partial_payment(participantToPartialPayment);

      //select article
      if (!this.state.selected_article && (!articleToPay.hasOwnProperty(this.props.article.id) && !articleToPartialPayment.includes(this.props.article.id))) {
        let list_participants = [];
        delete articleToPay[this.props.article.id];
        if (this.props.article.parent.is_activity === 1 && this.props.article.child.is_res === 1) {
          //get not payed participant of selected activity
          this.props.currentTicket.participants.map((participant, i) => {
            if (
              participant.article_id.toString() === this.props.article.id.toString() &&
              participant.status !== "payed" && 
              participant.note_index === this.props.note_index
            ) {
              list_participants.push(participant.user_index);
            }
          });
        }
        //partial payment case
        if (this.props.status === "partial") {
          if (!articleToPartialPayment.includes(this.props.article.id)) {
            articleToPartialPayment.push(this.props.article.id);
          }
          this.props.currentTicket.participants.map((participant, i) => {
            if (
              participant.article_id.toString() === this.props.article.id.toString() && 
              participant.note_index === this.props.note_index
            ) {
              list_participants.push(participant.user_index);
            }
          });
        }
        articleToPay[this.props.article.id] = list_participants;
      }
      //unselect article
      else {
        if (this.props.status === "partial") {
          articleToPartialPayment = articleToPartialPayment.filter(
            (itm) => itm !== this.props.article.id
          );
        }
        delete articleToPay[this.props.article.id];
      }
      //note
      if (ticket.noteInProgress && !this.props.article.note_index) {
        if (notes.length) {
          //create note
          if (!currentNote) {
            let index = (notes.length) - 1;
            if (notes[index].user_index === null) {
              await this.props.updateCurrentNote("note-" + index);
              notes[index].note_index = "note-" + index;
              ticket.notes = notes;
              currentNote = notes[index];
              this.props.updateCurrentTicket(ticket);
            }
          }
          //add article to note
          if ((this.props.article?.parent.is_activity === 1 && !this.props.note_index) ||
            (!this.props.article?.parent.is_activity && !this.props.article.note_index)) {
            if (this.props.article.note_index === null) {
              delete articleToPay[this.props.article.id];
            }
            if (this.props.article && parseInt(this.props.article.article_nb) === 1) {
              if (this.props.article.status !== "partial") {
                this.pushArticleToNote(1);
              }
            } else {
              let articleRestAmount = (parseFloat(this.props.article.total_price) * parseFloat(this.props.article.article_nb)) - parseFloat(this.props.article.payed_amount);
              let availableArticleNb = parseFloat(this.props.article.total_price) === 0 ?
                parseFloat(articleRestAmount) :
                parseInt(parseFloat(articleRestAmount) / parseFloat(this.props.article.total_price));
              this.setState({ articleNoteQtyModalOpen: true, qty: availableArticleNb });
            }
          }
        }
      }


      //get amountToPay / restAmountToPay
      let amountToPay = getAmountToPay(ticket, articleToPay, this.props.note_index)[0];
      let restAmountToPay = getAmountToPay(ticket, articleToPay, this.props.note_index)[1];
      this.props.article_to_pay(articleToPay, amountToPay, restAmountToPay);
      this.props.article_to_partial_payment(articleToPartialPayment);
    }
  };

  /**
   * end select article to pay/unpay
   **/
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  /**
   * start delete article
   **/
  cancelArticleDiscount() {
    let currentTicket = { ...this.props.currentTicket };
    if (this.props.article.parent.is_activity === 1) {
      let participantsDiscount = currentTicket.discountTypes.participants.filter(item => item.article_id.toString() !== this.props.article.id.toString());
      currentTicket.discountTypes.participants = participantsDiscount;

      //set status
      currentTicket.participants.map((item, index) => {
        item.status = setParticipantStatus(currentTicket, item);
      });
    }

    let articleDiscount = currentTicket.discountTypes.articles.filter(itm => itm.article_id.toString() !== this.props.article.id.toString());
    articleDiscount = [...articleDiscount, ...cumulDiscountArticlesFromParticipant(currentTicket, this.props.article)];
    currentTicket.discountTypes.articles = articleDiscount;

    currentTicket.articles.map((item, index) => {
      if (item.id === this.props.article.id && item.status === "payed") {
        item["status"] = null;
      }
    });

    currentTicket.discountTypes.discounts = cumulDiscountsFromArticle(currentTicket);
    currentTicket.discount = cumulTotalDiscountFromDiscounts(currentTicket);
    this.props.updateCurrentTicket(currentTicket);
    if (!this.state.deleteArticleModalOpen) {
      this.props.caisse_redirection("home");
      this.props.select_family(getParentIdIsActivity(this.props.menusData));
      this.props.select_sub_family(0);
      this.props.select_hour_type(0);
    }
  }

  deleteArticle = async () => {
    let article = this.props.article;
    let currentTicket = { ...this.props.currentTicket };
    let discount = parseFloat(getArticleDiscount(currentTicket, this.props.article.id, article.note));
    currentTicket.discount = currentTicket.discount - discount;
    currentTicket.articles = currentTicket.articles.filter((item) => {
      return item.id !== this.props.article.id;
    });
    currentTicket.participants = currentTicket.participants.filter(
      (itm) => itm.article_id !== this.props.article.id
    );
    if (!article.note_index || !this.props.note_index) {
      currentTicket.amount = currentTicket.amount - parseFloat(this.props.article.total_price * this.state.nb_occ_article);
      currentTicket.participants_nb = currentTicket.participants_nb -
        (this.props.article.default_participants_nb ? this.props.article.default_participants_nb : 0);
      currentTicket.articles_nb = parseInt(currentTicket.articles_nb) - this.state.nb_occ_article;
    } else {
      this.decrementNbOccArticleNote(currentTicket, article, true);
    }

    this.cancelArticleDiscount();
    if (this.props.currentTicket.reservation && this.props.currentTicket.reservation.article_id.toString() === this.props.article.id.toString().split(valeur)[0]) {
      //delete reservation activity case
      this.props.destoryReservation({ id: this.props.currentTicket.reservation.id });
      currentTicket.reservation_id = null;
      currentTicket.reservation = null;
      currentTicket.user_id = null;
      currentTicket.user = null;
      await this.props.setValues({ reservationTicket: null });
    }
    await this.props.article_to_pay([], 0, 0);
    await this.props.updateCurrentTicket(currentTicket);
    this.handleClose();
  };

  renderDeleteArticleModal() {
    return (
      <ModalConfirm
        open={this.state.deleteArticleModalOpen}
        onClose={this.handleClose}
        title={"Suppression d'un article"}
        message={
          <p>{this.props.currentTicket.articles_nb === 1 ?
            "Vous êtes sur le point de supprimer le ticket" : "Vous êtes sur le point de supprimer un article"}
          </p>}
        ok={async () => {
          await this.deleteArticle();
        }}
        cancel={this.handleClose}
        okText={"Supprimer"}
      />
    );
  }

  /**
   * end delete article
   */

  /**
   * set participant number
   */
  cancelParticipantDiscount = async (id) => {
    let currentTicket = { ...this.props.currentTicket };
    currentTicket.discountTypes.participants.map((participant_discount, index) => {
      if (participant_discount.user_index === id) {
        currentTicket.discountTypes.discounts.map((type, i) => {
          if (type.discount_id === participant_discount.discount_id) {
            type["amount"] = parseFloat(type.amount) - parseFloat(participant_discount.amount);
          }
        });
        currentTicket.discountTypes.articles.map((article, i) => {
          if (article.discount_id === participant_discount.discount_id) {
            article["amount"] =
              parseFloat(article.amount) - parseFloat(participant_discount.amount);
          }
        });
      }
    });
    //status payed -> null
    currentTicket.participants.map((item, index) => {
      if (item.user_index === id && item.status === "payed") {
        item["status"] = null;
      }
    });
    let discounts = currentTicket.discountTypes.discounts.filter((itm) => itm.amount !== 0);
    let discount_articles = currentTicket.discountTypes.articles.filter((itm) => itm.amount !== 0);
    let discount_participant = currentTicket.discountTypes.participants.filter(
      (itm) => itm.user_index !== id
    );
    currentTicket.discountTypes.discounts = discounts;
    currentTicket.discountTypes.articles = discount_articles;
    currentTicket.discountTypes.participants = discount_participant;
    currentTicket.discount = cumulTotalDiscountFromDiscounts(currentTicket);
    await this.props.updateCurrentTicket(currentTicket);
  };


  unpayParticipant = async (id, article_id) => {
    let currentTicket = { ...this.props.currentTicket };
    let participant = currentTicket.participants.filter((itm) => itm.user_index === id);
    //set ticket
    currentTicket["payed_amount"] = (
      parseFloat(currentTicket.payed_amount) - parseFloat(participant[0].amount)
    ).toFixed(2);
    //set article
    let article = currentTicket.articles.filter((itm) => itm.id === article_id);
    article[0].payed_amount =
      parseFloat(article[0].payed_amount) - parseFloat(participant[0].amount);
    if (
      parseFloat(article[0].payed_amount) +
      parseFloat(getArticleDiscount(currentTicket, article_id)) <=
      article[0].amount
    ) {
      article[0].status = null;
    }
    //set participant
    participant[0].status = null;
    participant[0].amount = 0;

    await currentTicket.payments.participants.map((item, index) => {
      if (item.user_index === id) {
        currentTicket.payments.types.map((type, i) => {
          if (type.payment_type_id === item.payment_type_id) {
            type["amount"] = parseFloat(type.amount) - parseFloat(item.amount);
          }
        });
        currentTicket.payments.articles.map((article, i) => {
          if (article.payment_type_id === item.payment_type_id) {
            article["amount"] = parseFloat(article.amount) - parseFloat(item.amount);
          }
        });
      }
    });

    // let payment_articles = currentTicket.payments.articles.filter(itm=>(itm.amount !== 0));
    let payment_participant = currentTicket.payments.participants.filter(
      (itm) => itm.user_index !== id
    );
    currentTicket.payments.participants = payment_participant;
    let payment_articles = currentTicket.payments.articles.filter(
      (itm) => itm.article_id !== article_id
    );
    currentTicket.payments.articles = payment_articles;

    payment_articles = [
      ...payment_articles,
      ...cumulPaymentArticlesFromParticipant(currentTicket, article_id)
    ];
    //vat for article payment ticket (vat exact)
    payment_articles.map((it, i) => {
      if (it.article_id.toString() === article_id.toString()) {
        it.amount_ht = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
          getHT(parseFloat(it.amount), article[0].vat.value);
        it.vat_amount = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
          (parseFloat(it.amount) - parseFloat(getHT(parseFloat(it.amount), article[0].vat.value))).toFixed(3);
        it.vat_id = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? null : article[0].vat_id;
      }
    });
    currentTicket.payments.articles = payment_articles;
    currentTicket.payments.types = cumulPaymentTypesFromArticle(currentTicket);
    currentTicket["payed_amount"] = cumulPayedAmountFromPaymentTypes(currentTicket);

    await this.props.updateCurrentTicket(currentTicket);
    await this.props.updateTicket(currentTicket);
  };


  autoPaymentWithPendingAdvance(ticket, article_id) {
    let article = ticket.articles.filter(itm => itm.article_id.toString() === article_id.toString());
    if (article.length > 0) {
      let payments = ticket.payments;

      let pendingAdvance = this.props.pendingAdvance;

      let paymentTypeId = this.props.paymentTypes.filter((it) => it.is_advance === 1);
      let advanceAmount = parseFloat(pendingAdvance.amount);
      let participants = ticket.participants.filter(itm => itm.article_id.toString() === article_id.toString());
      let participantPayments = [...payments.participants];

      participants.forEach(participant => {
        if (advanceAmount > 0) {
          participantPayments.push({
            user_id: null,
            user_index: participant.user_index,
            payment_type_id: paymentTypeId.length > 0 ? paymentTypeId[0].id : null,
            article_id: participant.article_id,
            credit_id: null,
            subscriber_id: null,
            subscriber_detail_id: null,
            credit_account_id: null,
            credit_user_id: null,
            advance_id: pendingAdvance.id,
            amount: parseFloat(participant.amount) > parseFloat(advanceAmount) ?
              parseFloat(advanceAmount).toFixed(2) :
              parseFloat(participant.amount).toFixed(2),
            amount_ht: parseFloat(getHT(parseFloat(participant.amount), article[0].vat.value)).toFixed(3),
            vat_amount: (
              parseFloat(participant.amount) -
              parseFloat(getHT(parseFloat(participant.amount), article[0].vat.value))
            ).toFixed(3),
            vat_id: article[0].vat.id,
            note_index: null
          });
          advanceAmount = parseFloat(advanceAmount) - parseFloat(participant.amount);
        }

      });

      ticket.payments.participants = participantPayments;
      ticket.participants.forEach(itm => {
        itm.payed_amount = cumulParticipantPayedAmountFromPaymentParticipant(ticket, itm.user_index);
        itm.status = setParticipantStatus(ticket, itm);
      });


      let articlePayments = ticket.payments.articles;
      articlePayments = [
        ...articlePayments,
        ...cumulPaymentArticlesFromParticipant(ticket, article[0].id)
      ];

      ticket.payments.articles = articlePayments;

      ticket.articles.forEach(itm => {
        if (itm.id === article[0].id) {
          itm.payed_amount = cumulArticlePayedAmountFromPaymentArticle(ticket, itm.id);
          itm.status = setArticleStatus(ticket, itm);
        }
      });

      ticket.payments.types = cumulPaymentTypesFromArticle(ticket);
      ticket["payed_amount"] = cumulPayedAmountFromPaymentTypes(ticket);
      ticket.pendingAdvance = null;
      this.props.updatePendingAdvance(null);
    }

    return ticket;
  };

  editNbParticipant = async () => {
    let article = { ...this.props.article };
    let data = { ...this.props.currentTicket };
    let currentNote = getCurrentNote(data, this.props.currentNote);
    let participants = [...data.participants];

    let pendingAdvance = this.props.pendingAdvance;

    let selected_participants = data.participants.filter((itm) => itm.article_id === article.id);
    let old_participant_nb = Object.entries(selected_participants).length;
    let diff = this.state.nb_participant - old_participant_nb;
    let price_per_participant = article.price_per_participant;
    let price_per_participant_ht = article.price_per_participant_ht;
    let excess = 0;
    let excess_ht = 0;
    data.articles.forEach((item, index) => {
      if (item.id.toString() === article.id.toString()) {
        if (article.details) {
          let article_details = article.details.filter(detail =>
            (parseInt(detail.participant_nb) === parseInt(this.state.nb_participant)));
          if (article_details.length) {
            let article_price = null;
            
            item.default_participants_nb = parseInt(this.state.nb_participant);
            article_details.forEach((detail, i) => {
              article_price = parseFloat(detail.total_price);
              item.price_per_participant = parseFloat(detail.price_per_participant);
              item.price_per_participant_ht = parseFloat(detail.price_per_participant_ht);
              item.total_price = parseFloat(detail.total_price);
              item.amount_ht = parseFloat(detail.amount_ht);
              item.vat_amount = parseFloat(detail.vat_amount);
              item.article_detail_id = detail.id;
              price_per_participant = parseFloat(detail.price_per_participant);
              price_per_participant_ht = parseFloat(detail.price_per_participant_ht);
              //first item get excess of division (exact division)
              excess = exactDivision(detail.total_price, detail.price_per_participant, detail.participant_nb);
              excess_ht = exactDivision(detail.amount_ht, detail.price_per_participant_ht, detail.participant_nb);
            });

            //set old participant
            data.participants.forEach((participant, index) => {
              let amount = (index === 0) ?
                (parseFloat(price_per_participant) + parseFloat(excess)).toFixed(2) : parseFloat(price_per_participant).toFixed(2);
              let amount_ht = (index === 0) ?
                (parseFloat(price_per_participant_ht) + parseFloat(excess_ht)).toFixed(2) : parseFloat(price_per_participant_ht).toFixed(3);
              if (participant.article_id === article.id) {
                participant["amount"] = amount;
                participant["amount_ht"] = amount_ht;
                participant["vat_amount"] = (parseFloat(amount) - parseFloat(amount_ht)).toFixed(3);
              }
            });
            //add participants
            if (diff > 0) {
              for (let index = 0; index < Math.abs(diff); index++) {
                participants.push({
                  user_id: "",
                  user_index: article.id + "-" + (old_participant_nb + index + 1),
                  article_id: article.id,
                  amount: parseFloat(price_per_participant).toFixed(2),
                  payed_amount: 0,
                  amount_ht: price_per_participant_ht,
                  vat_amount: (parseFloat(price_per_participant) - parseFloat(price_per_participant_ht)).toFixed(3),
                  vat_id: article.vat_id,
                  status: null,
                  note_index: null
                });
              }
            }
            //delete participants
            else {
              for (let index = 0; index < Math.abs(diff); index++) {
                let participantToDeleted = selected_participants[selected_participants.length - 1];
                //note division case (delete participant related to note)
                if (data.noteInProgress && currentNote && participantToDeleted) {
                  if (currentNote.user_index === participantToDeleted.user_index) {
                    cancelNote(data, this.props.currentNote);
                    this.props.updateCurrentNote(null);
                  }
                }
                data["discount"] = (parseFloat(data.discount) - parseFloat(getParticipantDiscount(data, participantToDeleted.user_index))).toFixed(2);
                this.cancelParticipantDiscount(participantToDeleted.user_index);
                if (participantToDeleted.status === "payed") {
                  this.unpayParticipant(
                    participantToDeleted.user_index,
                    participantToDeleted.article_id
                  );
                }
                participants = participants.filter(
                  (itm) => itm.user_index !== participantToDeleted.user_index
                );
                selected_participants = selected_participants.filter(
                  (itm) => itm.user_index !== participantToDeleted.user_index
                );
              }
            }
            data.amount = cumulAmountFromArticlePrice(data);
            data.participants = participants;
            data.participants_nb = Object.entries(data.participants).length;

            //restore pending advance
            if (pendingAdvance) {
              data = this.autoPaymentWithPendingAdvance(data, article.id);
            }

            //leisure case 
            if (data.reservation && data.reservation.is_leisure === 1) {
              data.created_reservation = { ...data.reservation };
              let nb_missing_part = parseInt(this.state.nb_participant) - data.reservation.participants.length || 0;
              data.created_reservation.match.participants_nb = parseInt(this.state.nb_participant);
              data.created_reservation.match.missing_participants_nb = nb_missing_part;
              data.created_reservation.match.real_missing_participants_nb = nb_missing_part;
              data.created_reservation.match.price_per_participant = price_per_participant;
              data.created_reservation.price = article_price;

              let participantIds = data.participants.map(itm=> {return itm.user_id});
              data.created_reservation.participants = data.created_reservation.participants.filter(
                itm => participantIds.includes(itm.id));
  
            }

            this.props.updateCurrentTicket(data);
          } else {
            warningToast("Le nombre des participants saisi n'existe pas dans le détail de cet article");
            if (pendingAdvance) {
              data = this.autoPaymentWithPendingAdvance(data, article.id);
            }
          }
        }

      }
    });


    
    this.handleClose();
  };


  renderEnabledEditParticipantNbModal() {
    let getAdvanceFromPaymentList = haveAdvance(this.props.currentTicket, this.props.article.id);

    return (
      <Modal
        open={this.state.enabledEditParticipantNbModal}
        onClose={this.handleClose}
        size="tiny"
        className="modal-ticket"
      >
        <Modal.Content className="modal-content">
          <h3 className="modal-content-header">Modifier le nombre de participant</h3>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={this.handleClose}
            />
            <p>
              Pour modifier le nombre de participant, vous devez annuler le paiement de cet article
            </p>
            <span className="btn-group">
              {this.props.currentTicket.status !== 0 && (
                <Button
                  className="modal-delete-btn"
                  onClick={async () => {
                    await this.unpayArticle();
                    //in case of edit participant number & the activity is payed by advance => we have to save this advance
                    if (getAdvanceFromPaymentList) {
                      this.props.updatePendingAdvance(getAdvanceFromPaymentList);
                    }
                    this.setState({ enabledEditParticipantNbModal: false });
                    this.setState({ editParticipantNbModal: true });
                  }}
                >
                  Annuler paiement
                </Button>
              )}

              <Button
                className="modal-cancel-btn"
                onClick={() => {
                  this.handleClose();
                  this.setState({ enabledEditParticipantNbModal: false });
                }}
              >
                Annuler
              </Button>
            </span>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  renderEditParticipantNbModal() {
    return (
      <Modal
        open={this.state.editParticipantNbModal}
        onClose={this.handleClose}
        size="small"
        className="modal-ticket modal-add-participant"
      >
        <Modal.Content className="modal-content">
          <h3 className="modal-content-header">Modification d'un article</h3>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={()=>{
                //restore pending advance
                if (this.props.pendingAdvance) {
                  let ticket = this.autoPaymentWithPendingAdvance(this.props.currentTicket, this.props.article.id);
                  this.props.updateCurrentTicket(ticket);
                }
                this.handleClose()
              }}
            />
            <div className="edit-participant-input">
              <label>Nb de participant</label>
              <CustomInput
                name={"nb_participant"}
                type={"number"}
                value={this.state.nb_participant}
                onChange={this.handleChange}
              />
            </div>
            <p>Vous êtes sur le point de modifier un article</p>
            <span className="btn-group">
              <Button className="modal-delete-btn" onClick={() => this.editNbParticipant()}>
                {i18n.t("edit")}
              </Button>
              <Button className="modal-cancel-btn" onClick={() => {
                //restore pending advance
                if (this.props.pendingAdvance) {
                  let ticket = this.autoPaymentWithPendingAdvance(this.props.currentTicket, this.props.article.id);
                  this.props.updateCurrentTicket(ticket);
                }
                this.handleClose();
              }}
              >
                {i18n.t("cancel")}
              </Button>
            </span>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  /**
   * end set participant number
   */
  renderListParticipant() {
    let currentTicket = this.props.currentTicket;
    let article = this.props.article;

    if (currentTicket) {
      let participants = currentTicket.participants.filter((itm) =>
        itm.article_id.toString() === article.article_id.toString()
      );
      if (Object.entries(participants).length) {
        participants = participants.map((item, index) => {
          if (
            (this.props.note_index === item.note_index)
          ) {
            return (
              <TicketAccordionItem
                key={item.user_index}
                id={item.user_index}
                article={article}
                participant={item}
                article_id={item.article_id}
                user_id={item.user_id ? item.user_id : ""}
                subscriber_id={item.subscriber_id}
                status={item.status}
                payment_type={item.type_payment_id}
                name={item.user_id ? nullStringForTable(item.firstName).toLowerCase() + " " + nullStringForTable(item.lastName).toUpperCase() : "Joueur " + (index + 1)}
                price={item.amount}
                type={item.user_id ? "client" : ""}
                payed_amount={item.payed_amount}
                //createCredit = {(credit_value)=>this.props.createCredit(credit_value)}
                is_note={this.props.is_note}
                note_index={this.props.note_index}
              />
            );
          }
        });
      }
      return participants;
    }
  }

  updateArticleQte = async (qte) => {
    let data = { ...this.props.currentTicket }; //update current ticket
    let article = { ...this.props.article };
    let oldValue = 0;
    let didIncrement = false;
    if (data && data.articles && data.articles.length) {
      for (let it of data.articles) {
        if (
          it.id.toString().split(valeur)[0] === article.id.toString().split(valeur)[0] &&
          it.note_index === null
        ) {
          article = { ...it };
          if (it.status !== "payed") {
            oldValue = parseInt(it["article_nb"]);
            it["article_nb"] = parseInt(qte);
            it["payed_amount"] = it.status === "partial" ? it.payed_amount : 0;
            it["status"] = it.status === "partial" ? "partial" : null;
            data.amount = parseFloat(data.amount) + (parseFloat(it.total_price) * (qte - oldValue));
            data.amount = parseFloat(data.amount).toFixed(2);
            data.articles_nb = parseInt(data.articles_nb) + (qte - oldValue);
            didIncrement = true;
            break;
          }
        }
      }
    }

    if (!didIncrement) {
      let i = article.id.toString().split(valeur);
      article["article_nb"] = 1;
      article["payed_amount"] = 0;
      article["status"] = null;
      if (article.parent.is_activity === 0 || (article.parent.is_activity === 1 && article.child.is_res === 0)) article.id = i[0] + valeur + (parseInt(i[1]) + 1);
      data.articles = [...data.articles, article];
      data.amount = parseFloat(data.amount) + (parseFloat(article.total_price));
      data.amount = parseFloat(data.amount).toFixed(2);
      data.articles_nb = parseInt(data.articles_nb) + 1;
    }

    this.props.updateCurrentTicket(data);
    this.props.article_to_edit_payment(null);
    this.props.participant_to_edit_payment(null);
    if (data.status === 0) {
      await this.props.duplicateTicket(1);
    }
  };

  async incrementNbOccArticle() {
    let article = this.props.article;
    let ticket = { ...this.props.currentTicket };
    if (article.note_index) {
      ticket = this.incrementNbOccArticleNote(ticket, article);
      this.props.updateCurrentTicket({ ...ticket });
    } else {
      if (article.status !== "payed") {
        await this.updateArticleQte(parseInt(article.article_nb) + 1);
      } else {
        let incArticle = this.props.currentTicket.articles.filter(it =>
          it.id.toString().split(valeur)[0] === article.id.toString().split(valeur)[0] &&
          !it.note_index &&
          it.status !== "payed");
        if (incArticle.length) {
          await this.updateArticleQte(parseInt(incArticle[0].article_nb) + 1);
        } else
          await this.updateArticleQte(1);
      }
    }
  }


  decrementNbOccArticle = async () => {
    let currentTicket = { ...this.props.currentTicket };
    let amountToPay = this.props.amountToPay;
    let restAmountToPay = this.props.restAmountToPay;
    let currentArticle = this.props.article;

    //let articleToPay = {...this.props.articleToPay};
    if (parseInt(this.props.article.article_nb) === 1) {
      //unpay article
      if (this.props.article.status === "payed" || this.props.article.status === "partial") {
        this.setState({ unpayModalOpen: true });
      }
      //delete article
      if (this.props.article.status === null) {
        if (this.props.currentNote) {
          this.deleteArticle();
        } else {
          this.setState({ deleteArticleModalOpen: true });
        }
      }
    } else if (getArticleDiscount(currentTicket, this.props.article.id) > 0) {
      //cancel discount before decrement article
      this.setState({ cancelArticleDiscountModalOpen: true });
    } else if (this.props.article.status === "payed") {
      //decrement payed article (trop percu)
      let clonedTicket = JSON.stringify(currentTicket);
      currentTicket.articles.forEach((item, index) => {
        if (item.id === this.props.article.id) {
          item.article_nb = parseInt(item.article_nb) - 1;
          item.amount = parseInt(item.article_nb) * item.total_price;
        }
      });

      if (!currentArticle.note_index || !this.props.note_index) {
        currentTicket.amount = parseFloat(currentTicket.amount) - parseFloat(this.props.article.total_price);
        currentTicket.articles_nb = parseInt(currentTicket.articles_nb) - 1;
      } else {
        this.decrementNbOccArticleNote(currentTicket, this.props.article);
      }
      let alreadyDecremented = this.props.decrementOccNb?.article_id === this.props.article.id;
      this.setState({ unpayModalOpen: alreadyDecremented ? false : true, clonedTicket });
      let nbOcc = this.props.decrementOccNb?.nbOcc ? this.props.decrementOccNb?.nbOcc : 1;
      if (alreadyDecremented)
        nbOcc += 1;

      this.props.decrement_occ_nb({ article_id: this.props.article.id, nbOcc });
    } else {
      //unpay article
      if (this.props.article.status === "partial") {
        this.setState({ unpayModalOpen: true });
      } else {
        currentTicket.articles.forEach((item, index) => {
          if (item.id === this.props.article.id) {
            item.article_nb = parseInt(item.article_nb) - 1;
            item.amount = parseInt(item.article_nb) * item.total_price;
          }
        });

        if (!currentArticle.note_index || !this.props.note_index) {
          if (this.props.article.status === "payed" || this.props.article.status === "partial") {
            this.setState({ unpayModalOpen: true });
          } else {
            currentTicket.amount = parseFloat(currentTicket.amount) - parseFloat(this.props.article.total_price);
            currentTicket.articles_nb = parseInt(currentTicket.articles_nb) - 1;
          }
        } else {
          this.decrementNbOccArticleNote(currentTicket, this.props.article);
        }

        if (this.props.articleToPay.hasOwnProperty(this.props.article.id)) {
          amountToPay =
            amountToPay !== 0
              ? parseFloat(amountToPay) - parseFloat(this.props.article.total_price)
              : 0;
          restAmountToPay =
            restAmountToPay !== 0
              ? parseFloat(restAmountToPay) - parseFloat(this.props.article.total_price)
              : 0;
        }
        this.props.article_to_pay(this.props.articleToPay, amountToPay, restAmountToPay);
      }

    }
    currentTicket.amount = parseFloat(currentTicket.amount).toFixed(2);
    await this.props.updateCurrentTicket({ ...currentTicket });
  };

  editNbOccArticle = async (event) => {
    let currentTicket = { ...this.props.currentTicket };
    if (this.props.article.parent.is_activity === 0 ||
      (this.props.article.parent.is_activity === 1 && this.props.article.child.is_res === 0)) {
      let old_nb_occ_article = parseInt(this.props.article.article_nb);
      let value = parseInt(event.target.value);
      if (Number.isInteger(value) && value >= 0) {
        if (value === 0) {
          //unpay article
          if (this.props.article.status) {
            this.setState({ unpayModalOpen: true });
          } else {
            this.setState({ deleteArticleModalOpen: true, nb_occ_article: old_nb_occ_article });
          }
        } else {
          //let val = value - old_nb_occ_article >= 0 ? value - old_nb_occ_article : old_nb_occ_article - value;
          if (value !== 0) {
            this.updateArticleQte(value);
          }
        }
      }
    }
    //await this.props.updateCurrentTicket(currentTicket);
    //duplicate ticket
    if (currentTicket.status === 0) {
      this.props.duplicateTicket(1);
    }
  };

  /**
   * cancel article discount
   */

  renderCancelArticleDiscountModal() {
    return (
      <ModalConfirm
        open={this.state.cancelArticleDiscountModalOpen}
        onClose={this.handleClose}
        title={"Annuler la remise d'un article"}
        message={
          <p>Voulez vous annuler la remise de cet article?
          </p>
        }
        okText={"Oui"}
        ok={async () => {
          await this.cancelArticleDiscount();
          this.handleClose();
        }}
        cancelText={"Non"}
        cancel={this.handleClose}
      />
    );
  }

  /**
   * @empty
   * decrementNbOccArticleNote: we decrement the article inside the note from ticket parent
   *  we add it to the parent;
   *
   * */
  /** */
  decrementNbOccArticleNote = (currentTicket, article, deleted = false) => {
    // article with note_index
    let newArticle = null;

    let hasArticle = currentTicket.articles.filter(it => (!it.note_index &&
        it.id.toString().split(valeur)[0] === article.id.toString().split(valeur)[0] &&
        it.status !== "payed" && it.note_index === null
      )
    );

    //get old account
    let oldQty = deleted ? 1 : article.article_nb + 1;
    let discountArticles = currentTicket.discountTypes.articles.filter(it => it.note_index === article.note_index && it.article_id.toString() === article.id.toString());
    if (hasArticle.length > 0) {
      let articles = currentTicket.articles.map((it, i) => {
        if (hasArticle.length > 0) {
          if (it.id === hasArticle[0].id) {
            //update discount->Articles
            discountArticles.forEach((art) => {
              let finishedEvent = false;
              let totalAmount = (parseFloat(art.amount) / oldQty).toFixed(2);
              currentTicket.discountTypes.articles.forEach(d => {
                if (!finishedEvent && d.article_id === hasArticle[0].id && d.discount_id === art.discount_id) {
                  d.amount = (parseFloat(d.amount) + parseFloat(totalAmount)).toFixed(2);
                  finishedEvent = true;
                }
              });
              if (!finishedEvent) {
                currentTicket.discountTypes.articles.push({
                  ...art,
                  note_index: null,
                  amount: nullStringNumber(totalAmount).toFixed(2),
                  article_id: hasArticle[0].id
                });
                finishedEvent = true;
              }
              // remove discount from note article
              if (finishedEvent) art.amount = (parseFloat(art.amount) - parseFloat(totalAmount)).toFixed(2);
            });
            // update article in main
            it.article_nb = parseInt(it.article_nb) + 1;
            it.amount = parseInt(it.article_nb) * it.total_price;
            it.status = null;
          }
        }
        return it;
      });
      currentTicket.articles = articles;
    } else {
      let lastIndex = getLastOccurrenceByIdInArticle(currentTicket.articles, article.id);
      lastIndex = parseInt(lastIndex) + 1;
      newArticle = {
        ...article,
        article_nb: 1,
        amount: article.total_price,
        payed_amount: 0,
        id: article.article_id.toString() + valeur + lastIndex,
        note_index: null,
        status: null
      };
    }
    if (newArticle) {
      currentTicket.articles.push(newArticle);
      //update discount->Articles
      discountArticles.forEach((art) => {
        let finishedEvent = false;
        let totalAmount = parseFloat(art.amount) / oldQty;
        if (!finishedEvent) {
          currentTicket.discountTypes.articles.push({
            ...art,
            note_index: null,
            amount: totalAmount.toFixed(3),
            article_id: newArticle.id
          });
          finishedEvent = true;
        }
        // remove discount from note article
        if (finishedEvent) art.amount = (parseFloat(art.amount) - parseFloat(totalAmount)).toFixed(2);
      });

    }
    let resultArticleDiscount = cumulDiscountArticles(currentTicket);
    currentTicket.discountTypes.articles = resultArticleDiscount;

    currentTicket.discountTypes.discounts = cumulDiscountsFromArticle(currentTicket);
    currentTicket.discount = cumulTotalDiscountFromDiscounts(currentTicket);

    currentTicket.articles = currentTicket.articles.filter(it => parseInt(it.article_nb) > 0);

    //refresh note
    currentTicket.notes = refreshNote(currentTicket);
    this.props.updateCurrentNote(null);
    currentTicket.noteInProgress = false;
    this.props.caisse_redirection("home", null, null);
    this.props.updateCurrentTicket(currentTicket);
  };

  /**
   * incrementNbOccArticleNote: we increment the article inside the note from ticket parent
   * */
  incrementNbOccArticleNote = (currentTicket, article) => {
    // article without note_index
    let hasArticle = currentTicket.articles.filter(it => (
        it.id.toString().split(valeur)[0] === article.id.toString().split(valeur)[0] &&
        it.status !== "payed" && it.note_index === null
      )
    );
    let is_activity = article.parent?.is_activity === 1 && article.child?.is_res === 1;
    if (hasArticle.length > 0) {
      let ticketUpdated = updateTicketArticle(currentTicket, hasArticle[0].id, 1, article.note_index, is_activity);
      currentTicket = ticketUpdated.ticket;
    }

    return currentTicket;
  };

  render() {
    let currentNote = getCurrentNote(this.props.currentTicket, this.props.currentNote);
    let show_note = currentNote && currentNote.status === 1;
    let { module } = this.props;
    let isActivity = (this.props.article.parent?.is_activity === 1 && this.props.article.child?.is_res === 1);
    let status = getStatusOfArticleByNote(this.props.currentTicket, this.props.article.id, this.props.note_index, isActivity);

    if (this.props.mode === "single")
      return (
        <div className={"custom-accordion"}>
          <div className={"main-accordion "}>
            <label className={"accordion-item single-accordion"}>
              <span>{module.name}</span>
              <Checkbox
                toggle={true}
                checked={module.checked}
                onChange={() => this.props.handleChange(module.id, this.props.index)}
              />
            </label>
          </div>
        </div>
      );
    if (this.props.article && this.props.article.hasOwnProperty("id") && this.props.article.id !== "undefined" &&
      this.props.article !== "undefined" && this.props.article.hasOwnProperty("parent")) {
      return (
        <div className={"main-accordion"}>
          {this.state.deleteArticleModalOpen && this.renderDeleteArticleModal()}
          {this.state.editParticipantNbModal && this.renderEditParticipantNbModal()}
          {this.state.unpayModalOpen && this.renderUnpayArticleModal()}
          {this.state.enabledEditParticipantNbModal && this.renderEnabledEditParticipantNbModal()}
          {this.state.cancelArticleDiscountModalOpen && this.renderCancelArticleDiscountModal()}
          {this.state.articleNoteQtyModalOpen && this.renderArticleNoteQtyModal()}
          <div>
            <Table className="ticket-table" basic="very">
              <Table.Body>
                <Table.Row
                  style={ticketMainAccordionStyle[
                    (status === "payed" ||
                      (this.props.note_index &&
                        this.props.article.parent && this.props.article.parent.is_activity === 1 &&
                        this.props.article.child?.is_res === 1 &&
                        allParticipantsOfNotePayed(this.props.currentTicket, this.props.article.id, this.props.note_index))
                    ) ?
                      (this.props.articleToEditPayment && this.props.note_index === this.props.currentNote && this.props.articleToEditPayment.toString() === this.props.article.id.toString() ?
                        "edited" : "payed") :
                      this.state.selected_article ? "selected" :
                        status === null ? "default" : status
                    ]}
                >
                  <Table.Cell collapsing width={1}>
                    <Button
                      className="badge-qte-minus"
                      size="mini"
                      icon="minus"
                      disabled={this.props.currentTicket.status !== 1}
                      onClick={async () => {
                        if (isActivity && this.props.note_index && !status) {
                          let ticket = { ...this.props.currentTicket };
                          ticket = removeParticipantFromArticleNote(ticket, this.props.article.id, this.props.note_index);
                          //delete note is empty
                          if (!noteHasArticles(ticket, this.props.note_index)) {
                            ticket.notes = ticket.notes.filter(it => it.note_index !== this.props.note_index);
                            ticket.noteInProgress = false;
                            this.props.updateCurrentNote(null);
                          }
                          this.props.updateCurrentTicket(ticket);
                        } else
                          await this.decrementNbOccArticle();
                      }}
                    />
                    <Input
                      name="nb_occ_article"
                      className="badge-qte"
                      value={this.props.article.article_nb}
                      disabled={this.props.currentTicket.status === 0 ||
                      (this.props.article.parent?.is_activity === 1 && this.props.article.child?.is_res === 1) ||
                      this.props.article.parent?.is_advance === 1 ||
                      this.props.article.parent?.is_reload === 1 ||
                      this.props.article.parent?.is_credit === 1
                      }
                      onChange={(event) => !this.props.article.note_index && this.editNbOccArticle(event)}
                    />
                    <Button
                      className="badge-qte-plus"
                      size="mini"
                      icon="plus"
                      disabled={this.props.currentTicket.status !== 1 ||
                      (this.props.article.parent?.is_activity === 1 && this.props.article.child?.is_res === 1) ||
                      this.props.article.parent?.is_advance === 1 ||
                      this.props.article.parent?.is_reload === 1 ||
                      this.props.article.parent?.is_credit === 1
                      }
                      onClick={async () => {
                        if (this.props.decrementOccNb === null)
                          await this.incrementNbOccArticle();
                        else {
                          this.props.caisse_redirection("home");
                        }
                      }}
                    />

                  </Table.Cell>
                  <Table.Cell collapsing className="accordion-label" style={{ paddingLeft: "2%" }}

                              onClick={async () => {
                                await this.selectArticlesToPay();
                              }}
                  >
                    {this.props.article.name + " " +
                    (this.props.article.info ? this.props.article.info + " " : "") +
                    (this.props.article.duration
                      ? this.props.article.duration.duration.replace(":", "H") + " "
                      : "") +
                    (this.props.article.default_participants_nb !== null
                      ? this.props.article.default_participants_nb + " " + i18n.t("pers")
                      : "")}
                  </Table.Cell>
                  {getArticleDiscount(this.props.currentTicket, this.props.article.id, isActivity ? this.props.note_index : this.props.article.note_index) > 0 &&
                  <Table.Cell className="accordion-discount" width={2} collapsing textAlign="right"
                              onClick={() => {
                                this.props.is_note === null && parseFloat(this.props.article.payed_amount) === 0 && this.setState({ cancelArticleDiscountModalOpen: true });
                              }}
                  >
                    <Image
                      src={require("../../../assets/remise.svg")}
                      style={{ width: "30px" }}
                    />
                    {parseFloat(
                      getArticleDiscount(this.props.currentTicket, this.props.article.id, isActivity ? this.props.note_index : this.props.article.note_index)
                    ).toFixed(2)}
                    {localStorage.getItem("currency")}
                  </Table.Cell>
                  }
                  <Table.Cell className="accordion-label" collapsing width={3}>
                    {((isActivity ?
                      getActivityAmount(this.props.currentTicket, this.props.article.id, this.props.note_index) :
                      parseFloat((this.props.article.total_price * this.props.article.article_nb))) -
                      parseFloat(getArticleDiscount(this.props.currentTicket, this.props.article.id, this.props.article.note_index))).toFixed(2)
                    }
                    {localStorage.getItem("currency")}
                  </Table.Cell>
                  <Table.Cell collapsing width={1}>
                    {this.props.article &&
                    this.props.article.hasOwnProperty("parent") && this.props.article.parent.is_activity === 1 &&
                    this.props.article.hasOwnProperty("child") && this.props.article.child.is_res === 1 ? (
                      <Icon circular={true}
                            className={`angle ${!this.state.show ? " down" : " up"}`}
                            onClick={() => this.setState({ show: !this.state.show })}
                      />
                    ) : (
                      <Icon/>
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          {this.props.article.parent.is_activity === 1 && this.props.article.child.is_res === 1 && (
            <div className={"accordion-slider " + (this.state.show ? "" : "accordion-hide")}>
              <div className={"accordion-container"}>
                {this.renderListParticipant()}
                {!this.props.currentNote && this.props.is_note === null &&
                <Button
                  className="participant-btn"
                  onClick={() => {
                    if (activityLinkedToNote(this.props.currentTicket, this.props.article.id)) {
                      warningToast("Pour modifier le nombre de participant, vous devez annuler la note");
                    } else {
                      if (this.props.article.status !== null) {
                        this.setState({ enabledEditParticipantNbModal: true });
                      } else if (getArticleDiscount(currentNote ? currentNote : this.props.currentTicket, this.props.article.id)) {
                        this.setState({ cancelArticleDiscountModalOpen: true });
                      } else {
                        this.setState({ editParticipantNbModal: true });
                      }
                    }

                  }}
                >
                  <span className="plus">+</span>
                  <span className="separator">|</span>
                  <span className="minus">-</span>
                </Button>
                }

              </div>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTicket: (data) => dispatch({ type: "UPDATE_CURRENT_TICKET_REQUEST", payload: data }),
  caisse_redirection: (value) => dispatch({ type: "CAISSE_REDIRECTION_REQUEST", payload: { value } }),
  article_to_pay: (article, amount, rest) =>
    dispatch({ type: "ARTICLE_TO_PAY_REQUEST", payload: { article, amount, rest } }),
  article_to_edit_payment: (value) =>
    dispatch({ type: "ARTICLE_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  participant_to_edit_payment: (value) =>
    dispatch({ type: "PARTICIPANT_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  article_to_partial_payment: (value) =>
    dispatch({ type: "ARTICLE_TO_PARTIAL_PAYMENT_REQUEST", payload: value }),
  participant_to_partial_payment: (value) =>
    dispatch({ type: "PARTICIPANT_TO_PARTIAL_PAYMENT_REQUEST", payload: value }),
  duplicateTicket: (id) => dispatch({ type: "DUPLICATE_TICKET_REQUEST", payload: id }),
  decrement_occ_nb: (value) => dispatch({ type: "DECREMENT_OCC_NB_REQUEST", payload: value }),
  storeTicket: (data) => dispatch({ type: "STORE_TICKET_REQUEST", payload: data }),
  updateTicket: (data) => dispatch({ type: "UPDATE_TICKET_REQUEST", payload: data }),
  setValues: (data) => dispatch({ type: "SET_VALUES", data }),
  updateReservation: (data) => dispatch(reservationCreators.updateRequestReservation(data)),
  destoryReservation: (payload) => dispatch({ type: "DESTROY_REQUEST_RESERVATION", payload }),
  destroyTicket: (id) => dispatch({ type: "DESTROY_TICKET_REQUEST", payload: id }),
  select_family: (value) => dispatch({ type: "SELECT_FAMILY_REQUEST", payload: value }),
  select_sub_family: (value) => dispatch({ type: "SELECT_SUB_FAMILY_REQUEST", payload: value }),
  select_hour_type: (value) => dispatch({ type: "SELECT_HOUR_TYPE_REQUEST", payload: value }),
  updateCurrentNote: (id) => dispatch({ type: "CURRENT_NOTE_REQUEST", payload: id }),
  updatePendingAdvance: (data) => dispatch({ type: "PENDING_ADVANCE_REQUEST", payload: data })
});

const mapStateToProps = (state) => {
  return {
    articleToPay: state.caisse.articleToPay,
    amountToPay: state.caisse.amountToPay,
    restAmountToPay: state.caisse.restAmountToPay,
    articleToEditPayment: state.caisse.articleToEditPayment,
    currentTicket: state.caisse.currentTicket,
    articleToPartialPayment: state.caisse.articleToPartialPayment,
    participantToPartialPayment: state.caisse.participantToPartialPayment,
    caisseRedirection: state.caisse.caisse_redirection,
    decrementOccNb: state.caisse.decrementOccNb,
    deleteLoading: state.reservation.deleteLoading,
    menusData: state.caisse.menusData,
    currentNote: state.caisse.currentNote,
    pendingAdvance: state.caisse.pendingAdvance,
    paymentTypes: state.paymentMethod.paymentMethods
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TicketAccordion));
