import React from "react";
import "./SubscriptionModal.css";
import { Button, Form, Grid, Icon, Input, Loader, Modal, Pagination } from "semantic-ui-react";
import { formatCodeCountry, normalDate, nullString, phoneNumber, randomstring, formattingDate } from "../../../helpers/formatting";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import CustomInput from "../../CustomInput/CustomInput";
import creditUserService from "@/services/creditUserService";
import { url , throttle} from "@/helpers";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import CustomTab from "@/components/CustomTab/CustomTab";
import { getParentIdIsActivity, getPaymentSlug } from "@/helpers/ticket";
import moment from "moment";
import localization, * as fr from "moment/locale/fr";
import { Creators as SubscribersCreators } from "../../../store/ducks/subscriber";
import Image from "../../ImageComponent/Image";
import { SIZE } from "../../../utils/mock";
import { DEFAULT_COUNTRY_CODE } from "../../../utils/countryCode";

class CreditAccountModal extends React.Component {

  constructor(props) {
    super(props);
    let somePassword = randomstring();
    this.state = {
      modalContent: null,
      search_player: "",
      id: "",
      firstName: "",
      lastName: "",
      mobile: "",
      country_code: DEFAULT_COUNTRY_CODE,
      email: "",
      payment_type_list: [],
      teams: [],
      capitaine: [],
      password: somePassword,
      password_confirmation: somePassword,
      pending_ticket: "",
      solde: "",
      amount: "",
      payment_type_id: null,
      selectedPaymentType: null,
      subscriber_id: null,
      subscriber: null,
      subscription_credit_id: null,
      date: moment().format("YYYY-MM-DD"),
      credit: '',
      errors: {
        firstName: null,
        subscriber_id: null,
        lastName: null,
        email: null
      },
      successModalOpen: false,
      modalMsg: ""
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.handleChangeFloat = this.handleChangeFloat.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  async componentDidMount() {
    if (this.props.creditAccount) {
      this.setState({
        ...this.state,
        id: this.props.creditAccount.user && this.props.creditAccount.user.id,
        firstName: this.props.creditAccount.user && this.props.creditAccount.user.firstName,
        lastName: this.props.creditAccount.user && this.props.creditAccount.user.lastName,
        email: this.props.creditAccount.user && this.props.creditAccount.user.email,
        mobile: this.props.creditAccount.user && this.props.creditAccount.user.mobile,
        country_code: this.props.creditAccount.user && this.props.creditAccount.user.country_code,
        solde: await creditUserService.creditUserService.getCreditUserSolde({
          complex_id: localStorage.getItem("complex_id"),
          user_id: this.props.creditAccount.user && this.props.creditAccount.user.id
        }),
        amount: this.props.creditAccount.amount,
        payment_type_id: this.props.creditAccount.payments.types[0] && this.props.creditAccount.payments.types[0].payment_type_id,
        subscriber_id: this.props.creditAccount.credit_subscriber_detail && this.props.creditAccount.credit_subscriber_detail.subscriber_id,
        modalContent: "affect credit"

      });
    }
    if (this.props.autoSelected && this.props.autoSelected.user_id && this.props.input && !this.props.input.credit_user_id) {
      this.props.editPlayer(this.props.autoSelected.user_id);
    }
    if (this.props.input && this.props.input.credit_user_id) {
      this.props.editPlayer(this.props.input.credit_user_id);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.user && prevProps.user !== this.props.user) {
      this.setState({
        ...this.state,
        id: this.props.user.id,
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        email: this.props.user.email,
        mobile: this.props.user.mobile,
        country_code: this.props.user.country_code,
        solde: await creditUserService.creditUserService.getCreditUserSolde({
          complex_id: localStorage.getItem("complex_id"),
          user_id: this.props.user.id
        }),
        amount: this.props.input ? this.props.input.amount : "",
        modalContent: "affect credit",
        payment_type_id: null,
        subscriber_id: null,
        subscriber: null,
        subscription_credit_id: null
      });
    }
    if (!this.props.loading && JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)) {
      let errors = {
        firstName: null,
        lastName: null,
        email: null
      };
      for (let index in this.props.errors) {
        errors[index] = { content: this.props.errors[index][0] };
      }
      this.setState({ errors });
    }
  }

  onChangeHandle(event, mobileEvent = null) {
    this.setState({
      [event.target.name]: event.target.value
    });

    if (mobileEvent)
      this.setState({
        [mobileEvent.target.name]: mobileEvent.target.value
      });
  }

  handleChangeFloat = (event) => {
    let val = parseFloat(event.target.value).toFixed(2);
    if (val >= 0 || event.target.value === "") {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  handlePaymentTypeChange = (e, data) => {
    this.setState({ payment_type_id: e.target.value, selectedPaymentType: data });
  };

  renderSuccessModal() {
    return (
      <Modal
        open={this.state.successModalOpen}
        onClose={() => this.setState({ successModalOpen: false })}
        size="mini"
        className="advance-modal withOpacity center-modal"
        centered

      >
        <span>{this.state.modalMsg}</span>
      </Modal>
    );
  }

  async createCreditAccount() {
    let credit_subscriber_detail = null;
    if (this.state.subscriber_id) {
      credit_subscriber_detail = {
        date: moment(new Date()).format("DD-MM-YYYY"),
        expense: parseFloat(this.state.amount),
        label: "",
        payment_type_id: this.state.payment_type_id,
        subscriber_id: this.state.subscriber_id
      };
      if (this.props.creditAccount && this.props.creditAccount.credit_subscriber_detail) {
        credit_subscriber_detail["id"] = this.props.creditAccount.credit_subscriber_detail.id;
      }
    }

    let creditTicket = {
      id: this.props.creditAccount ? this.props.creditAccount.id : null,
      user_id: this.state.id,
      amount: parseFloat(this.state.amount),
      payed_amount: parseFloat(this.state.amount),
      articles_nb: 0,
      date: moment(new Date()).format("DD-MM-YYYY"),
      hour: moment(new Date()).locale("fr", localization).format("LT"),
      complex_id: localStorage.getItem("complex_id"),
      status: 0,
      is_credit_account: 1,
      is_credit: 0,
      is_advance: 0,
      owner_id: this.props.owner.id,
      articles: [],
      discountTypes: {
        articles: [],
        participants: [],
        discounts: []
      },
      payments: {
        types: [
          {
            payment_type_id: this.state.payment_type_id,
            amount: parseFloat(this.state.amount)
          }
        ],
        articles: [],
        participants: []
      },
      credit_subscriber_detail: credit_subscriber_detail,
      conclude: 1
    };
    if (this.props.creditAccount) {
      this.props.updateTicket(creditTicket);
    } else {
      this.props.storeTicket(creditTicket);
    }


    //reset

    this.setState({
      successModalOpen: true,
      modalMsg: this.props.creditAccount ? i18n.t("credit-edit") : i18n.t("credit-generated")
    });
    setTimeout(() => {
        this.setState({ successModalOpen: false, modalMsg: "" });
        if (!this.props.creditAccount) {
          this.props.caisse_redirection("home");
          this.props.select_family(getParentIdIsActivity(this.props.menusData));
          this.props.select_sub_family(0);
        } else {
          this.props.getTickets({ page: 1, size: 10, status: [0], is_credit_account: 1, date: "" });
        }
        this.props.onClose();
      },
      1000
    );

  }

  onChangeSubscriber = (id, data) => {
    if (!id) {
      this.setState({ subscriber: null, subscriber_id: null });
    } else {
      this.setState({ subscriber: data, subscriber_id: id });
    }
  };

  renderCreditAccount(){
    let currency = localStorage.getItem("currency");
    let creditsOptions = [];
    let restAmount = parseFloat(this.props.restAmount);
    if (restAmount < 0) restAmount = 0;
    let item = this.state.subscriber;
    if (item && item.subscription)
      creditsOptions = item.subscription.credit_bonuses.map((it) => {
        return {
          text:
            "credit: " +
            it.credit +
            currency + (it.bonus ?
            " + " +
            (it.bonus ? "bonus:" + it.bonus + currency : "") : ""),
          key: it.id,
          value: it.id
        };
      });
    let val = parseFloat(this.props.restAmount);
    if (val < 0) val = 0;
    return (
      <Form>
        {this.props.editLoading ? (
          <Loader/>
        ) : (
          <>
            <Grid columns={2}>
              <Grid.Column className="player-input">
                <CustomInput
                  name={"lastName"}
                  label={i18n.t("last_name")}
                  type={"text"}
                  value={this.state.lastName}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  name={"firstName"}
                  label={i18n.t("first_name")}
                  type={"text"}
                  value={this.state.firstName}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  name={"mobile"}
                  label={i18n.t("mobile")}
                  type={"phone"}
                  value={this.state.mobile}
                  countrycode={this.state.country_code}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  name={"email"}
                  label={i18n.t("email")}
                  type={"text"}
                  value={this.state.email}
                  onChange={this.onChangeHandle}
                />
                {this.props.currentTicket && this.props.type === "expense" &&
                <CustomInput
                  name={"pending_ticket"}
                  label={i18n.t("pending_ticket")}
                  type={"text"}
                  value={this.props.currentTicket.number ? this.props.currentTicket.number : ""}
                />
                }
                <CustomInput
                  name={"solde"}
                  label={"Solde"}
                  type={"number"}
                  value={this.state.solde}
                />
              </Grid.Column>
              <Grid.Column className="player-input">
                {this.props.type === "credit" &&
                <div className="form">
                  <Form.Field>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={4} verticalAlign="middle">
                          <label className="form-label">{i18n.t("payment-type")}</label>
                        </Grid.Column>
                        <Grid.Column width={12}>
                          <CustomDropdown
                            className={"date-dropdown regulation-input"}
                            condition={it => it && it.is_credit_account === 0 && it.is_subscription === 0 && it.is_advance === 0 && it.is_credit === 0}
                            url={"/paymentTypes"}
                            // param={'&slug=["especes","cb","carte","cheque"]'}
                            onChange={(value, data) => {
                              if (!data.is_card) {
                                this.setState({ subscriber_id: null });
                              }
                              this.setState({ payment_type_id: value, selectedPaymentType: data });
                            }}
                            value={this.state.payment_type_id}
                            render={it => (
                              <>
                                <Image className={it.icon ? "filter-img" : "no-filter-img"}
                                       src={url + "/uploads/payments/" + it.icon} type={"item"}/>
                                <span>{it.name}</span>
                              </>
                            )}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                  {!!this.state.payment_type_id && !!this.state.selectedPaymentType?.is_card &&
                  <Grid.Column className="player-input">
                    <CustomInput name={"abonnement"}
                                 get={"/subscribers"}
                                 url={"/subscribers"}
                                 param={"&search_user=" + this.state.id + "&is_reload=1&is_expired=0"}
                                 label={i18n.t("subscriptions")}
                                 type={"custom-dropdown"}

                                 render={(it) => (
                                   <>
                                     <span>{it.subscription.name}</span>
                                   </>
                                 )}
                                 value={this.state.subscriber_id}
                                 onInitial={(data) => {
                                   data && this.setState({ subscriber: data });
                                   if (data && !this.state.id && (!this.state.email || !this.state.mobile)) {
                                     this.setState({
                                       ...this.state,
                                       id: data.user.id,
                                       firstName: data.user.firstName,
                                       lastName: data.user.lastName,
                                       email: data.user.email,
                                       mobile: data.user.mobile
                                     });
                                   }

                                 }}
                                 onChange={this.onChangeSubscriber}
                    />
                    {this.state.subscriber &&
                    <span className="amount-bloc">
                            <span>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width={6} verticalAlign={"middle"}>
                                    <label className="form-label">{i18n.t("solde")}</label>
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <Input
                                      name="solde"
                                      type={"text"}
                                      value={
                                        this.state.subscriber
                                          ? (this.state.subscriber.total_balance).toFixed(2) + " " + currency
                                          : ""
                                      }
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </span>
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width={6} verticalAlign={"middle"}>
                                  <label className="form-label">{i18n.t("expire")}</label>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                  <Input
                                    name="expire"
                                    type={"text"}
                                    value={this.state.subscriber ? this.state.subscriber.expiry_date : ""}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </span>
                    }
                    {this.state.subscriber &&
                    moment(normalDate(this.state.subscriber.expiry_date)).diff(moment(), "days") <
                    0 &&
                    <span className={"error-msg"}>{i18n.t("subscription-expired")}</span>
                    }
                    <br/>
                  </Grid.Column>
                  }
                </div>
                }
                <CustomInput
                  name={"amount"}
                  label={this.props.type === "expense" ? i18n.t("amount") + " (" + (val.toFixed(2)) + currency + ")" : "Montant"}
                  type={"number"}
                  value={this.state.amount ? this.state.amount : ""}
                  onChange={(e) => {
                    if (this.props.type === "expense") {
                      this.props.onChange(e.target.value);
                    }
                    this.handleChangeFloat(e);
                  }}
                />
                {
                  (parseFloat(this.props.restAmount) < 0 ||
                    (this.state.subscriber && getPaymentSlug(this.props.paymentTypes, this.state.payment_type_id) === "carte" &&
                      parseFloat(this.state.subscriber.total_balance.toFixed(2)) < parseFloat(this.state.amount))) &&
                  <p className={"modal-p-error"}>{i18n.t("sold-insufficient")}</p>
                }
              </Grid.Column>
            </Grid>
            <div className="group-btn-edit-player">
              {this.props.type === "client" ? (
                <Button
                  className="btn-select"
                  content={i18n.t("cancel")}
                  onClick={() => {
                    this.handleClose();
                  }}
                />
              ) : ""}

              <Button
                disabled={!this.state.amount || parseFloat(this.state.amount) <= 0 || parseFloat(this.props.restAmount) < 0 ||
                (this.state.payment_type_id === null && this.props.type === "credit") ||
                (this.state.payment_type_id && this.state.selectedPaymentType?.is_card && !this.state.subscriber_id) ||
                (this.state.subscriber && getPaymentSlug(this.props.paymentTypes, this.state.payment_type_id) === "carte" &&
                  parseFloat(this.state.subscriber.total_balance.toFixed(2)) < parseFloat(this.state.amount))
                }
                className="btn-inline-icon btn-payment"
                onClick={async () => {
                  if (this.props.type === "expense") {
                    this.props.onPay({ expense: parseFloat(this.state.amount), user_id: this.state.id });
                  } else {
                    await this.createCreditAccount();
                  }
                }}
              >
                {i18n.t("pay")} &nbsp;
                <Image src={require("../../../assets/payer.svg")}/>
              </Button>
            </div>
          </>
        )}
      </Form>
    )
  }

  renderCredit(){
    return (
      <Form>
        {this.props.editLoading ? (
          <Loader/>
        ) : (
          <>
            <Grid columns={2}>
              <Grid.Column className="player-input">
                <CustomInput
                  name={"lastName"}
                  label={i18n.t("last_name")}
                  type={"text"}
                  value={this.state.lastName}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  name={"firstName"}
                  label={i18n.t("first_name")}
                  type={"text"}
                  value={this.state.firstName}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  name={"mobile"}
                  label={i18n.t("mobile")}
                  type={"phone"}
                  value={this.state.mobile}
                  countrycode={this.state.country_code}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  name={"email"}
                  label={i18n.t("email")}
                  type={"text"}
                  value={this.state.email}
                  onChange={this.onChangeHandle}
                />
                {this.props.currentTicket && this.props.type === "expense" &&
                <CustomInput
                  name={"pending_ticket"}
                  label={i18n.t("pending_ticket")}
                  type={"text"}
                  value={this.props.currentTicket.number ? this.props.currentTicket.number : ""}
                />
                }
                <CustomInput
                  name={"solde"}
                  label={"Solde"}
                  type={"number"}
                  value={this.state.solde}
                />
              </Grid.Column>
              <Grid.Column className="player-input">
                <CustomInput
                  type={"date"}
                  name={"date"}
                  label={i18n.t("date")}
                  value={this.state.date}
                  onChange={this.onChangeHandle}
                />
                <CustomInput
                  type={"number"}
                  name={"credit"}
                  label={i18n.t("credit")}
                  value={this.state.credit}
                  onChange={this.onChangeHandle}
                />
              </Grid.Column>
            </Grid>
            <div className="group-btn-edit-player">
              <Button
                disabled={this.props.loadingDetail || !this.state.credit}
                loading={this.props.loadingDetail}
                className="btn-inline-icon btn-payment"
                onClick={throttle(() => {
                  if (this.state.credit && this.state.date) {
                    let data = {
                      date: formattingDate(this.state.date),
                      credit: this.state.credit,
                      user_id: this.state.id,
                      complex_id: localStorage.getItem("complex_id")
                    };
                    this.props.storeCreditDetails(data);
                    //reset
                    this.setState({
                      successModalOpen: true,
                      modalMsg: this.props.creditAccount ? i18n.t("credit-edit") : i18n.t("credit-generated")
                    });
                    setTimeout(() => {
                        this.setState({ successModalOpen: false, modalMsg: "" });
                        if (!this.props.creditAccount) {
                          this.props.caisse_redirection("home");
                          this.props.select_family(getParentIdIsActivity(this.props.menusData));
                          this.props.select_sub_family(0);
                        } else {
                          this.props.getTickets({ page: 1, size: 10, status: [0], is_credit_account: 1, date: "" });
                        }
                        this.props.onClose();
                      },
                      1000
                    );
                  }
                },2000)
                }
              >
                {i18n.t("save")}
              </Button>
            </div>
          </>
        )}
      </Form>
    )
  }

  renderAffectCredit = () => {
    return (
      <CustomTab
          panes={[
            {
              menuItem: i18n.t("credit_account"),
              render: () => this.renderCreditAccount(),
            },
            {
              menuItem: i18n.t("credit"),
              render: () => this.renderCredit(),
            },
          ]}
        />
    );
  };

  handleChangeSearch = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      modalContent: null
    });

    this.props.searchClient(this.props.page, SIZE - 2, event.target.value);
  };


  handlePaginationChange = (e, data) => {
    this.props.searchClient(data.activePage, SIZE - 2, this.state.search_player);
  };

  renderSearchContent() {
    return this.props.users.map((item, index) => {
      return (
        <div key={index} className="player-row">
            <span>
              {nullString(item.firstName).toLowerCase()} {nullString(item.lastName).toUpperCase()}
            </span>
          <span>{formatCodeCountry(item.country_code)  + phoneNumber(item.mobile)}</span>
          <span>{item.email}</span>
          <span style={{ color: "#7EB5E5" }}>
              <Icon
                name="edit"
                onClick={() => {
                  this.setState({
                    modalContent: "affect credit", id: item.id,
                    solde: "",
                    amount: "",
                    payment_type_id: null,
                    subscriber_id: null,
                    subscriber: null,
                    subscription_credit_id: null
                  });
                  this.props.editPlayer(item.id);
                }}
              />
            </span>
        </div>
      );
    });

  }


  render() {
    return (
      <>
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
          size="large"
          className="modal-ticket modal-edit-player"
        >
          <Modal.Content className="modal-content">
            <h3
              className="modal-content-header">{i18n.t("credit")}</h3>
            <div className="modal-content-body">

              <Button
                icon="close"
                color="red"
                className="modal-btn-close"
                circular
                size="tiny"
                onClick={this.props.onClose}
              />
              <div className="search-bloc">
                {this.state.modalContent !== "add player" && (
                  <>
                    <Input
                      name="search_player"
                      className="search-input-player"
                      icon="search"
                      placeholder="Recherche"
                      onChange={this.handleChangeSearch}
                      value={this.state.search_player}
                    />
                  </>
                )}
              </div>
              <div className="player-informations">
                {this.state.modalContent === null && this.state.search_player !== "" && (
                  <div className="search-list">
                    {this.props.loading ? <Loader/> : this.renderSearchContent()}
                    <div className={"ticket-pagination"}>
                      <Pagination
                        activePage={this.props.page}
                        boundaryRange={1}
                        onPageChange={(e, data) => this.handlePaginationChange(e, data)}
                        size="mini"
                        siblingRange={1}
                        totalPages={this.props.last}
                        ellipsisItem={"..."}
                        firstItem={null}
                        lastItem={null}
                        prevItem={null}
                        nextItem={null}
                      />
                    </div>
                  </div>
                )}
                {this.state.modalContent === "affect credit" && (
                  <div className="edit-player-bloc">{this.renderAffectCredit()}</div>
                )}
              </div>
            </div>
          </Modal.Content>
        </Modal>
        {this.renderSuccessModal()}
      </>
    );
  }
}

CreditAccountModal.defaultProps = {
  open: true,
  onClose: () => {
  }
};

const mapDispatchToProps = (dispatch) => ({
  select_family: (value) => dispatch({ type: "SELECT_FAMILY_REQUEST", payload: value }),
  select_sub_family: (value) => dispatch({ type: "SELECT_SUB_FAMILY_REQUEST", payload: value }),
  caisse_redirection: (value) => dispatch({ type: "CAISSE_REDIRECTION_REQUEST", payload: { value } }),
  searchClient: (page, size, search) => dispatch({ type: "SEARCH_REQUEST_USER", payload: { page, size, search } }),
  store: (data) => dispatch({ type: "STORE_CLIENT_REQUEST_USER", payload: { ...data, redirection: false } }),
  editPlayer: (id) => dispatch({ type: "EDIT_REQUEST_USER", payload: id }),
  storeCreditDetails: (data) => dispatch({ type: "STORE_REQUEST_CREDIT", payload: data }),
  storeTicket: (data) => dispatch({ type: "STORE_TICKET_REQUEST", payload: data }),
  updateTicket: (data) => dispatch({ type: "UPDATE_TICKET_REQUEST", payload: data }),
  getTickets: (data) => dispatch({ type: "GET_TICKETS_REQUEST", payload: { ...data } }),
  reload: (data) => dispatch(SubscribersCreators.indexRequestSubscriberDetail({ ...data }))
});
const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    client: state.user.client,
    page: state.user.page,
    last: state.user.last,
    size: state.user.size,
    loading: state.user.loading,
    role: state.role,
    currentTicket: state.caisse.currentTicket,
    errors: state.user.error,
    user: state.user.selected,
    editLoading: state.user.editLoading,
    credit: state.caisse.credit,
    loadingDetail: state.subscriber.loadingDetail,
    menusData: state.caisse.menusData,
    owner: state.auth.user,
    paymentTypes: state.paymentMethod.paymentMethods
  };
};


CreditAccountModal.defaultProps = {
  subscriber_id: null
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditAccountModal);



