import { deleteMethod, getMethod } from '@/helpers/index';
import { postMethod, putMethod } from '../helpers';

export default {
  bookingConfigurationService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/bookingConfigurations?page=' + page +
        (size ? '&size=' + size : '') +
        '&complex_id=' + localStorage.getItem('complex_id'),
      );
      return result.data;
    },

    edit: async (id) => {
      const result = await getMethod('/bookingConfigurations/' + id);
      return result.data;
    },

    store: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await postMethod('/bookingConfigurations/', newData);
      return result.data;
    },

    update: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await putMethod('/bookingConfigurations/' + newData.id, newData);
      return result.data;
    },

    destroy: async (id) => {
      const result = await deleteMethod('/bookingConfigurations/' + id);
      return result.data;
    },
  },
};