import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import resolutionService from '../../services/resolutionService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingResolution: ['data'],

  indexRequestResolution: ['payload'],
  indexResponseResolution: ['data'],

  storeRequestResolution: ['payload'],
  storeResponseResolution: ['data'],

  editRequestResolution: ['id'],
  editResponseResolution: ['data'],

  updateRequestResolution: ['payload'],
  updateResponseResolution: ['data'],

  destroyRequestResolution: ['id'],
  destroyResponseResolution: ['data'],
});

// Initial state
const INITIAL_STATE = {
  resolutions: [],
  resolution: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingResolution({ loading: true }));
    const data = yield call(resolutionService.resolutionService.index, action.payload);
    yield put(Creators.indexResponseResolution(data));
    yield put(Creators.loadingResolution({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingResolution({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingResolution({ loading: true }));
    const data = yield call(resolutionService.resolutionService.store, action.payload);
    yield put(Creators.storeResponseResolution(data));
    yield put(Creators.loadingResolution({ loading: false }));
    yield history.push('/configuration/resolutions');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingResolution({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingResolution({ editLoading: true }));
    const data = yield call(resolutionService.resolutionService.edit, action.payload);
    yield put(Creators.editResponseResolution(data));
    yield put(Creators.loadingResolution({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingResolution({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingResolution({ loading: true }));
    const data = yield call(resolutionService.resolutionService.update, action.payload);
    yield put(Creators.updateResponseResolution(data));
    yield put(Creators.loadingResolution({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/resolutions');
  } catch (e) {
    yield put(
      Creators.loadingResolution({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingResolution({ deleteLoading: true }));
    const data = yield call(resolutionService.resolutionService.destroy, action.payload);
    yield put(Creators.destroyResponseResolution(data));
    yield put(Creators.loadingResolution({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_RESOLUTION, index);
  yield takeLatest(Types.STORE_REQUEST_RESOLUTION, store);
  yield takeLatest(Types.EDIT_REQUEST_RESOLUTION, edit);
  yield takeLatest(Types.UPDATE_REQUEST_RESOLUTION, update);
  yield takeLatest(Types.DESTROY_REQUEST_RESOLUTION, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    resolutions: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    resolution: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};
// Reducer
export const resolutionReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_RESOLUTION]: index_response,
  [Types.STORE_RESPONSE_RESOLUTION]: store_response,
  [Types.EDIT_RESPONSE_RESOLUTION]: edit_response,
  [Types.UPDATE_RESPONSE_RESOLUTION]: update_response,
  [Types.DESTROY_REQUEST_RESOLUTION]: destroy_request,
  [Types.DESTROY_RESPONSE_RESOLUTION]: destroy_response,
  [Types.LOADING_RESOLUTION]: loading,
});
