import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import hourTypeService from '../../services/hourTypeService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingHourType: ['data'],

  indexRequestHourType: ['payload'],
  indexResponseHourType: ['data'],

  storeRequestHourType: ['payload'],
  storeResponseHourType: ['data'],

  editRequestHourType: ['id'],
  editResponseHourType: ['data'],

  updateRequestHourType: ['payload'],
  updateResponseHourType: ['data'],

  destroyRequestHourType: ['id'],
  destroyResponseHourType: ['data'],
});

// Initial state
const INITIAL_STATE = {
  hourTypes: [],
  hourType: null,
  last: 0,
  page: 0,
  total: 0,
  size: 10,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingHourType({ loading: true }));
    const data = yield call(hourTypeService.hourTypeService.index, action.payload);
    yield put(Creators.indexResponseHourType(data));
    yield put(Creators.loadingHourType({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingHourType({ loading: true }));
    const data = yield call(hourTypeService.hourTypeService.store, action.payload);
    yield put(Creators.storeResponseHourType(data));
    yield put(Creators.loadingHourType({ loading: false }));
    yield history.push('/configuration/hourTypes');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingHourType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingHourType({ editLoading: true }));
    const data = yield call(hourTypeService.hourTypeService.edit, action.payload);
    yield put(Creators.editResponseHourType(data));
    yield put(Creators.loadingHourType({ editLoading: false }));
  } catch (e) {
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingHourType({ loading: true }));
    const data = yield call(hourTypeService.hourTypeService.update, action.payload);
    yield put(Creators.updateResponseHourType(data));
    yield put(Creators.loadingHourType({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/hourTypes');
  } catch (e) {
    yield put(
      Creators.loadingHourType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingHourType({ deleteLoading: true }));
    const data = yield call(hourTypeService.hourTypeService.destroy, action.payload);
    yield put(Creators.destroyResponseHourType(data));
    yield put(Creators.loadingHourType({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_HOUR_TYPE, index);
  yield takeLatest(Types.STORE_REQUEST_HOUR_TYPE, store);
  yield takeLatest(Types.EDIT_REQUEST_HOUR_TYPE, edit);
  yield takeLatest(Types.UPDATE_REQUEST_HOUR_TYPE, update);
  yield takeLatest(Types.DESTROY_REQUEST_HOUR_TYPE, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    hourTypes: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    hourType: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};
// Reducer
export const hourTypeReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_HOUR_TYPE]: index_response,
  [Types.STORE_RESPONSE_HOUR_TYPE]: store_response,
  [Types.EDIT_RESPONSE_HOUR_TYPE]: edit_response,
  [Types.UPDATE_RESPONSE_HOUR_TYPE]: update_response,
  [Types.DESTROY_REQUEST_HOUR_TYPE]: destroy_request,
  [Types.DESTROY_RESPONSE_HOUR_TYPE]: destroy_response,
  [Types.LOADING_HOUR_TYPE]: loading,
});
