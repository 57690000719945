import { useEffect, useState } from 'react';
import Echo from 'laravel-echo';
import SocketIo from 'socket.io-client';

export const listeners = (user_id, complex_id) => [
  // handle notification panel
  { link: 'remove-main-reservation-' + complex_id + '-' + user_id, name: 'UpdateMainReservationEvent' },
  { link: 'update-main-reservation-' + complex_id + '-' + user_id, name: 'RemoveReservationEvent' },
  { link: 'create-main-reservation-' + complex_id + '-' + user_id, name: 'CreateReservationEvent' },

  // handle planning for normal reservation and leisure
  { link: 'store-reservation-' + complex_id, name: '.StoreReservationEvent' },
  { link: 'update-reservation-' + complex_id, name: '.UpdateReservationEvent' },
  { link: 'destroy-reservation-' + complex_id, name: '.DestroyReservationEvent' },
  { link: 'recover-reservation-' + complex_id, name: '.RecoverReservationEvent' },

  // handle opening and closing of session
  { link: 'checkout-session-' + complex_id, name: 'CheckoutSessionEvent' }, // opening
  { link: 'closure-' + complex_id, name: 'ClosureEvent' }, // closing

  // handle support notification
  { link: 'send-support-message-' + user_id, name: 'SendSupportMessageEvent' },
];

// handle connect to Ws
export default (props) => {
  const [echo, setEcho] = useState(null);
  useEffect(() => {
    if (props.link && props.token) {
      setEcho(new Echo({
        broadcaster: 'socket.io',
        host: props.link,
        client: SocketIo,
        // authEndpoint: '/api/broadcasting/auth',
        transports: ['websocket'],
        auth: {
          headers: {
            Authorization: 'Bearer ' + props.token,
            Accept: 'application/json',
          },
        },
      }));
    }
    return () => setEcho(null);

  }, [props.link, props.token]);

  return echo;
};