import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import './WithLoader.css';

export default (props) => {
  return props.loading ? (
    <div className={'withloader-container'}>
      {/*<Loader  inline="centered"  inverted={true} active={true} size={'medium'} className={"with-loader-container"} />*/}
      <Segment>
        <Dimmer className={'with-loader-dimmer'} active inverted>
          <Loader/>
        </Dimmer>

      </Segment>

    </div>
  ) : (
    props.children
  );
};
