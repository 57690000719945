import Calendar from "react-calendar";
import i18n from "../../i18n";
import moment from "moment";
import { Button, Checkbox, Divider, Icon, Transition } from "semantic-ui-react";
import { reservationStatus } from "../../helpers/reservationFormatting";
import React from "react";

const PlanningSide = (props) => {
  return (
    <div className={"planning-container-left"}>
      <Calendar
        locale={i18n.language}
        formatShortWeekday={(locale, date) =>
          moment(date).locale(locale).format("dd")
        }
        prev2Label={false}
        next2Label={false}
        onChange={props.onChange}
        value={moment(props.date).toDate()}
      />
      <Divider />
      {props.terrains.length > 0 && (
        <div className={"planning-terrain"}>
          <span className={"planning-terrain-header"}>
            {i18n.t("terrains")}
          </span>
          <div className={"planning-terrain-items"}>
            {props.terrains.map((it, i) => {
              return (
                <div key={`terr-${i}`} className={"planning-terrain-item"}>
                  <label htmlFor={"ter-" + i}>
                    <div>
                      <span
                        className={"terrain-round"}
                        style={{
                          backgroundColor: it.color,
                        }}
                      />
                      <span className={"terrain-title"}>{it.name}</span>
                    </div>
                  </label>
                  <Checkbox
                    id={"ter-" + i}
                    checked={it.hidden === 0}
                    onChange={() => props.onShowHandler(i)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {props.terrains.length > 0 && <Divider />}
      <div className={"planning-terrain"}>
        <span className={"planning-terrain-header"}>{i18n.t("status")}</span>
        <div className={"planning-terrain-items"}>
          {reservationStatus.map((it, i) => {
            if ((props.leisure && i < 5) || !props.leisure)
              return (
                <div key={`status-${i}`} className={"planning-terrain-item"}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className={i > 5 ? "stats-round" : "terrain-round"}
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 35,
                        backgroundColor: it.color,
                        color: it.fontColor ? it.fontColor : "white",
                      }}
                    >
                      {it.is_recurrence ? (
                        <img
                          style={{ width: 20, height: 20 }}
                          src={require("../../assets/icon_recurrence.svg")}
                          alt={"recurrence"}
                        />
                      ) : i > 5 ? (
                        localStorage.getItem("currency")
                      ) : (
                        ""
                      )}
                    </span>
                    <span className={"terrain-title"}>{i18n.t(it.name)}</span>
                  </div>
                </div>
              );
            else return null;
          })}
        </div>
      </div>
    </div>
  );
};

const PlanningLeft = (props) => {
  return (
    <Transition visible={props.toggle} animation={"scale"} duration={200}>
      <div className={"planning-left"}>
        <div className={"planning-header header-res"}>
          <Button className={"round-btn ajourdhui"} onClick={props.setDate}>
            {i18n.t("today")}
          </Button>
        </div>
        <span className={"planning-header-toggle"}>
          <span>{i18n.t("calendar")}</span>
          <Icon
            name={"angle left"}
            color={"black"}
            size={"small"}
            onClick={() => props.change()}
          />
        </span>

        <PlanningSide
          date={props.date}
          onChange={props.onChange}
          terrains={props.terrains}
          onShowHandler={props.onShowHandler}
          lesiure={props.leisure}
        />
      </div>
    </Transition>
  );
};

export default PlanningLeft;
