import React from 'react';
import { Button } from 'semantic-ui-react';
import '../CustomCaisse.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { imgUrl } from '@/helpers';

const ChildMenuItem = (props) => {
  let background = props.background ? 
    imgUrl + '/checkout/children/' + localStorage.getItem('complex_id') + '/' + props.background : '';
  //active button
  let style = {
    backgroundImage: 'url(' + background + ')',
    border: '3px solid #FFFFFF',
  };
  if (props.id == props.selectSubFamily) {
    style = {
      backgroundImage: 'url(' + background + ')',
      border: '3px solid #7EB5E5',
    };
  }
  //
  return (
    <Button
      className="child-menu-item"
      style={style}
      onClick={() => props.select_sub_family(props.id)}
    >
      <span>{props.name}</span>
    </Button>
  );
};
const mapDispatchToProps = (dispatch) => ({
  select_sub_family: (value) => dispatch({ type: 'SELECT_SUB_FAMILY_REQUEST', payload: value }),
});

const mapStateToProps = (state) => {
  return {
    selectFamily: state.caisse.selectFamily,
    selectSubFamily: state.caisse.selectSubFamily,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChildMenuItem));
