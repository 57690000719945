import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import sensorService from '@/services/sensorService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingSensor: ['payload'],

  indexRequestSensor: null,
  indexResponseSensor: ['data'],

  storeRequestSensor: ['payload'],
  storeResponseSensor: ['data'],

  editRequestSensor: ['id'],
  editResponseSensor: ['data'],

  updateRequestSensor: ['payload'],
  updateResponseSensor: ['data'],

  destroyRequestSensor: ['id'],
  destroyResponseSensor: ['data'],
});

// Initial state
const INITIAL_STATE = {
  sensors: [],
  sensor: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  loading: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingSensor({ loading: true }));
    const data = yield call(sensorService.sensorService.index, action.payload);
    yield put(Creators.indexResponseSensor(data));
    yield put(Creators.loadingSensor({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingSensor({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingSensor({ loading: true }));
    const data = yield call(sensorService.sensorService.store, action.payload);
    yield put(Creators.storeResponseSensor(data));
    yield put(Creators.loadingSensor({ loading: false }));
    yield history.push('/configuration/sensors');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingSensor({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingSensor({ editLoading: true }));
    const data = yield call(sensorService.sensorService.edit, action.payload);
    yield put(Creators.editResponseSensor(data));
    yield put(Creators.loadingSensor({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingSensor({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingSensor({ loading: true }));
    const data = yield call(sensorService.sensorService.update, action.payload);
    yield put(Creators.updateResponseSensor(data));
    yield put(Creators.loadingSensor({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/sensors');
  } catch (e) {
    yield put(Creators.loadingSensor({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingSensor({ deleteLoading: true }));
    const data = yield call(sensorService.sensorService.destroy, action.payload);
    yield put(Creators.destroyResponseSensor(data));
    yield put(Creators.loadingSensor({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_SENSOR, index);
  yield takeLatest(Types.STORE_REQUEST_SENSOR, store);
  yield takeLatest(Types.EDIT_REQUEST_SENSOR, edit);
  yield takeLatest(Types.UPDATE_REQUEST_SENSOR, update);
  yield takeLatest(Types.DESTROY_REQUEST_SENSOR, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sensors: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sensor: action.data.data,
  };
};
//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding_sensor = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const sensorReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_SENSOR]: index_response,
  [Types.STORE_RESPONSE_SENSOR]: store_response,
  [Types.EDIT_RESPONSE_SENSOR]: edit_response,
  [Types.UPDATE_RESPONSE_SENSOR]: update_response,
  [Types.DESTROY_RESPONSE_SENSOR]: destroy_response,
  [Types.LOADING_SENSOR]: laoding_sensor,
});
