import { createActions, createReducer } from "reduxsauce";
import { call, put, takeLatest } from "redux-saga/effects";
import * as service from "../../services/bookingService";
import {
  formattingReservationWithLogin,
  formattingReservationWithRegister,
} from "../../helpers/bookingFomatting";
import history from "../../helpers/history";
import { errorToast } from "../../helpers/customToast";

export const { Types, Creators } = createActions({
  getComplexBySlugRequest: ["payload"],
  getActivitiesBookingRequest: ["payload"],
  getSlotsBookingRequest: ["payload"],
  loginBookingRequest: ["payload"],
  registerBookingRequest: ["payload"],
  confirmBookingRequest: ["payload"],
  resetBookingResponse: [""],
  bookingRequest: ["payload"],
  bookingResponse: ["payload"],
});

const INITIAL_STATE = {
  complex: null,
  activities: [],
  slots: [],
  errors: null,
  data: null,
  confirm: null,
  confirmLoading: false,
  authLoading: false,
  loadingActivities: false,
  loadingComplex: false,
  loadingSlots: false,
};

function* complexBySlug(action) {
  try {
    yield put(Creators.bookingResponse({ loadingComplex: true }));
    let data = yield call(service.getComplexBySlug, action.payload);
    if (data.data.success === false) history.push("/error-404");
    else {
      let formattedResult = { complex: data.data.data };
      yield put(Creators.bookingResponse({ ...formattedResult }));
    }

    yield put(Creators.bookingResponse({ loadingComplex: false }));
  } catch (e) {
    yield put(Creators.bookingResponse({ loadingComplex: false }));
  }
}

function* getActivities(action) {
  try {
    yield put(
      Creators.bookingResponse({ loadingActivities: true, activities: [] })
    );

    let data = yield call(service.getActivities, action.payload);
    let formattedResult = { activities: data.data.data };

    yield put(Creators.bookingResponse({ ...formattedResult }));
    yield put(Creators.bookingResponse({ loadingActivities: false }));
  } catch (e) {
    yield put(Creators.bookingResponse({ loadingActivities: false }));
  }
}

function* getSlots(action) {
  try {
    yield put(Creators.bookingResponse({ loadingSlots: true, slots: [] }));

    let data = yield call(service.getSlots, action.payload);
    let formattedResult = { slots: data.data.data };

    yield put(Creators.bookingResponse({ ...formattedResult }));
    yield put(Creators.bookingResponse({ loadingSlots: false }));
    // yield put(Creators.changeTheme({ ...action.payload }))
  } catch (e) {
    yield put(Creators.bookingResponse({ loadingSlots: false }));
  }
}

// Authentification Booking

function* loginReservation(action) {
  try {
    yield put(
      Creators.bookingResponse({ authLoading: true, data: null, errors: null })
    );

    let payload = yield call(formattingReservationWithLogin, action.payload);
    let data = yield call(service.loginWithReservation, payload);
    if (data.data?.message && data.data.status === "error")
      errorToast(data.data.message);

    yield put(Creators.bookingResponse({ data: { ...data.data } }));
    yield put(Creators.bookingResponse({ authLoading: false }));
  } catch (e) {
    yield put(
      Creators.bookingResponse({
        authLoading: false,
        errors: { message: "something went wrong please you connexion" },
      })
    );
  }
}

function* confirmReservation(action) {
  try {
    yield put(
      Creators.bookingResponse({
        confirm: null,
        confirmLoading: true,
        errors: null,
      })
    );

    let payload = yield call(formattingReservationWithRegister, action.payload);
    let data = yield call(service.confirmReservation, payload);

    if (data.data && data.data.status === "success")
      yield put(Creators.bookingResponse({ data: { ...data.data } }));
    else {
      if (data.data?.message) errorToast(data.data.message);
      yield put(Creators.bookingResponse({ confirm: { ...data.data } }));
    }
    yield put(Creators.bookingResponse({ confirmLoading: false }));
  } catch (e) {
    yield put(
      Creators.bookingResponse({
        confirmLoading: false,
        errors: { message: "something went wrong please you connexion" },
      })
    );
  }
}

function* registerUser(action) {
  try {
    yield put(
      Creators.bookingResponse({ loading: true, data: null, errors: null })
    );
    let data = yield call(service.registerUser, { ...action.payload });
    data = data.data;
    let errors = {};
    if (!data.success) {
      let message = data.message;
      for (let er in message) errors = { ...errors, [er]: message[er][0] };
    }
    if (data.data?.message && data.data.status === "error")
      errorToast(data.data.message);

    //yield put(Creators.changeTheme({ ...action.payload }))
    if (Object.keys(errors).length > 0)
      yield put(Creators.bookingResponse({ errors }));
    else yield put(Creators.bookingResponse({ data: { ...data } }));
    yield put(Creators.bookingResponse({ loading: false }));
  } catch (e) {
    yield put(
      Creators.bookingResponse({
        loading: false,
        errors: { message: "something went wrong please you connexion" },
      })
    );
  }
}

/*
  loginBookingRequest: ["payload"],
  registerBookingRequest: ["payload"],
  confirmBookingRequest: ["payload"],
 */

export default function* mySaga() {
  yield takeLatest(Types.GET_COMPLEX_BY_SLUG_REQUEST, complexBySlug);
  yield takeLatest(Types.GET_ACTIVITIES_BOOKING_REQUEST, getActivities);
  yield takeLatest(Types.GET_SLOTS_BOOKING_REQUEST, getSlots);

  yield takeLatest(Types.LOGIN_BOOKING_REQUEST, loginReservation);
  yield takeLatest(Types.REGISTER_BOOKING_REQUEST, registerUser);
  yield takeLatest(Types.CONFIRM_BOOKING_REQUEST, confirmReservation);
}

// Reducers Handler :

const bookingResponse = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const resetResponse = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errors: null,
    data: null,
    confirm: null,
    authLoading: false,
  };
};

export const bookingReducer = createReducer(INITIAL_STATE, {
  [Types.BOOKING_RESPONSE]: bookingResponse,
  [Types.RESET_BOOKING_RESPONSE]: resetResponse,
});
