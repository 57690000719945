import { createActions, createReducer } from "reduxsauce";
import { call, put, select, takeLatest } from "redux-saga/effects";
import competitionService from "../../services/competitionService";
import history from "@/helpers/history";
import { errorToast, validToast } from "../../helpers/customToast";
import i18n from "../../i18n/index";

// Action types and creators
export const { Types, Creators } = createActions({
  competitionUpdates: ["data"],

  indexRequestCompetition: ["payload"],
  indexResponseCompetition: ["data"],

  storeRequestCompetition: ["payload"],
  storeResponseCompetition: ["data"],

  editRequestCompetition: ["id"],
  editResponseCompetition: ["data"],

  updateRequestCompetition: ["payload"],
  updateResponseCompetition: ["data"],

  destroyRequestCompetition: ["id"],
  destroyResponseCompetition: ["data"],

  getRequestMatch: ["payload"],
  getResponseMatch: ["data"],

  planningRequestCompetition: ["payload"],
  planningResponseCompetition: ["data"],

  matchCompositionRequestCompetition: ["payload"],
  matchCompositionResponseCompetition: ["data"],

  matchReportRequestCompetition: ["payload"],
  matchReportResponseCompetition: ["data"],

  forfeitRequestCompetition: ["payload"],
  forfeitResponseCompetition: ["data"],

  resetRequestCompetition: ["payload"],
  resetResponseCompetition: ["data"],

  rankingRequestCompetition: ["id"],
  rankingResponseCompetition: ["data"],

  statsRequestCompetition: ["id"],
  statsResponseCompetition: ["data"],

  clearStatsRequestCompetition: [],

  statsTeamRequestCompetition: ["id"],
  statsTeamResponseCompetition: ["data"],

  archiveRequestCompetition: ["id"],
  archiveResponseCompetition: ["data"],

  unarchiveRequestCompetition: ["id"],
  unarchiveResponseCompetition: ["data"],

  updateCurrentCompetition: ["data"],
});

// Initial state
const INITIAL_STATE = {
  competitions: [],
  competition: null,
  loading: false,
  loadingForfait: false,
  loadingReset: false,
  errors: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  match: null,
  matchLoading: false,
  matchCompositionLoading: false,
  matchReportLoading: false,
  statTeamLoading: false,
  planningLoading: false,
  editLoading: false,
  successStore: false,
  successForfait: false,
  successReset: false,
  successUpdate: false,
  successDestroy: false,
  successPlanning: false,
  successMatchComposition: false,
  successMatchReport: false,
  ranking: [],
  stats: null,
  statsTeam: null,
};

export const getCompetition = (state) => {
  return state.competition.competition;
};

export const getStatsTeam = (state) => {
  return state.competition.statsTeam;
};

//index
function* index(action) {
  try {
    yield put(Creators.competitionUpdates({ loading: true }));
    const data = yield call(
      competitionService.competitionService.index,
      action.payload
    );
    yield put(Creators.indexResponseCompetition(data));
    yield put(Creators.competitionUpdates({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.competitionUpdates({ loading: true }));
    const data = yield call(
      competitionService.competitionService.store,
      action.payload
    );
    yield put(Creators.storeResponseCompetition(data));
    yield put(Creators.competitionUpdates({ loading: false }));
    yield history.push("/competition");
    validToast(i18n.t("successful_store"));
  } catch (e) {
    yield put(
      Creators.competitionUpdates({
        loading: false,
        errors: e.response.data.errors,
      })
    );
    errorToast(i18n.t("failed_store"));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.competitionUpdates({ editLoading: true }));
    const data = yield call(
      competitionService.competitionService.edit,
      action.payload
    );
    yield put(Creators.editResponseCompetition(data));
    yield put(Creators.competitionUpdates({ editLoading: false }));
  } catch (e) {
    history.push("/error-404");
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.competitionUpdates({ loading: true }));
    const data = yield call(
      competitionService.competitionService.update,
      action.payload
    );
    yield put(Creators.updateResponseCompetition(data));
    yield put(Creators.competitionUpdates({ loading: false }));
    yield history.push("/competition");
    validToast(i18n.t("successful_update"));
  } catch (e) {
    yield put(
      Creators.competitionUpdates({
        loading: false,
        errors: e.response.data.errors,
      })
    );
    errorToast(i18n.t("failed_update"));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(
      Creators.competitionUpdates({
        deleteLoading: true,
        successDestroy: false,
      })
    );
    const data = yield call(
      competitionService.competitionService.destroy,
      action.payload
    );
    yield put(Creators.destroyResponseCompetition(data));
    yield put(
      Creators.competitionUpdates({
        deleteLoading: false,
        successDestroy: true,
      })
    );
    validToast(i18n.t("successful_delete"));
  } catch (e) {
    errorToast(i18n.t("failed_delete"));
  }
}

//get match
function* getMatch(action) {
  try {
    yield put(Creators.competitionUpdates({ matchLoading: true }));
    const data = yield call(
      competitionService.competitionService.getMatch,
      action.payload
    );
    yield put(Creators.getResponseMatch(data));
    yield put(Creators.competitionUpdates({ matchLoading: false }));
  } catch (e) {}
}

//planning
function* planning(action) {
  try {
    yield put(Creators.competitionUpdates({ planningLoading: true }));
    const data = yield call(
      competitionService.competitionService.planning,
      action.payload
    );
    yield put(Creators.planningResponseCompetition(data));
    yield put(Creators.competitionUpdates({ planningLoading: false }));
    yield history.push(
      "/competition/championnat/" + action.payload.competition_id + "/calendar"
    );
    validToast(i18n.t("successful_store"));
  } catch (e) {
    yield put(Creators.competitionUpdates({ planningLoading: false }));
    errorToast(i18n.t("failed_store"));
  }
}

//match report
function* matchComposition(action) {
  try {
    yield put(
      Creators.competitionUpdates({
        successMatchComposition: false,
        matchCompositionLoading: true,
      })
    );
    const data = yield call(
      competitionService.competitionService.matchComposition,
      action.payload
    );
    yield put(Creators.matchCompositionResponseCompetition(data));

    yield put(Creators.competitionUpdates({ matchCompositionLoading: false }));
    validToast(i18n.t("successful_store"));
  } catch (e) {
    yield put(Creators.competitionUpdates({ matchCompositionLoading: false }));
    errorToast(i18n.t("failed_store"));
  }
}

//match report
function* matchReport(action) {
  try {
    yield put(
      Creators.competitionUpdates({
        successMatchReport: false,
        matchReportLoading: true,
      })
    );
    const data = yield call(
      competitionService.competitionService.matchReport,
      action.payload
    );
    yield put(Creators.matchReportResponseCompetition(data));

    //general calendar (update goals)
    let competition = yield select(getCompetition);

    if (
      competition &&
      competition.matches &&
      competition.matches.length &&
      action.payload.calendar === "general"
    ) {
      competition.matches.forEach((match, index) => {
        if (data.data.match.id === match.id) {
          match.but_a = data.data.match.but_a;
          match.but_b = data.data.match.but_b;
        }
      });
      yield put(
        Creators.competitionUpdates({ competition: { ...competition } })
      );
    }

    //team calendar (update goals)
    let statsTeam = yield select(getStatsTeam);
    if (
      statsTeam &&
      statsTeam.matches &&
      statsTeam.matches.length &&
      action.payload.calendar === "team"
    ) {
      statsTeam.matches.forEach((match, index) => {
        if (data.data.match.id === match.id) {
          match.but_a = data.data.match.but_a;
          match.but_b = data.data.match.but_b;
        }
      });

      yield put(Creators.competitionUpdates({ statsTeam: { ...statsTeam } }));
    }

    yield put(Creators.competitionUpdates({ matchReportLoading: false }));
    validToast(i18n.t("successful_store"));
  } catch (e) {
    yield put(Creators.competitionUpdates({ matchReportLoading: false }));
    errorToast(i18n.t("failed_store"));
  }
}

//ranking
function* ranking(action) {
  try {
    yield put(Creators.competitionUpdates({ loading: true }));
    const data = yield call(
      competitionService.competitionService.ranking,
      action.payload
    );
    yield put(Creators.rankingResponseCompetition(data));
    yield put(Creators.competitionUpdates({ loading: false }));
  } catch (e) {
    //
  }
}

//stats
function* stats(action) {
  try {
    yield put(Creators.competitionUpdates({ loading: true }));
    const data = yield call(
      competitionService.competitionService.stats,
      action.payload
    );
    yield put(Creators.statsResponseCompetition(data));
    yield put(Creators.competitionUpdates({ loading: false }));
  } catch (e) {
    //
  }
}

//clear stat
function* clearStats(action) {
  try {
    yield put(Creators.competitionUpdates({ stats: null }));
  } catch (e) {
    //
  }
}

//stats
function* statsTeam(action) {
  try {
    yield put(Creators.competitionUpdates({ statTeamLoading: true }));
    const data = yield call(
      competitionService.competitionService.statsTeam,
      action.payload
    );
    yield put(Creators.statsTeamResponseCompetition(data));
    yield put(Creators.competitionUpdates({ statTeamLoading: false }));
  } catch (e) {
    //
  }
}

//archive
function* archive(action) {
  try {
    yield put(Creators.competitionUpdates({ loading: true }));
    const data = yield call(
      competitionService.competitionService.archive,
      action.payload
    );
    yield put(Creators.archiveResponseCompetition(data));
    yield put(Creators.competitionUpdates({ loading: false }));
    yield history.push("/competition");
  } catch (e) {
    //
  }
}

//unarchive
function* unarchive(action) {
  try {
    yield put(Creators.competitionUpdates({ loading: true }));
    const data = yield call(
      competitionService.competitionService.unarchive,
      action.payload
    );
    yield put(Creators.unarchiveResponseCompetition(data));
    yield put(Creators.competitionUpdates({ loading: false }));
    yield history.push("/competition");
  } catch (e) {
    //
  }
}

function* forfeit(action) {
  try {
    yield put(
      Creators.competitionUpdates({
        loadingForfait: true,
        successForfait: false,
      })
    );
    const response = yield call(
      competitionService.competitionService.forfeitMatch,
      action.payload
    );
    yield put(Creators.forfeitResponseCompetition(response.data));
    yield put(
      Creators.competitionUpdates({
        loadingForfait: false,
        successForfait: true,
      })
    );
    validToast(i18n.t("successful_update"));
  } catch (e) {
    yield put(
      Creators.competitionUpdates({
        loadingForfait: false,
      })
    );
    errorToast(i18n.t("failed_update"));
  }
}

function* reset(action) {
  try {
    yield put(
      Creators.competitionUpdates({
        loadingReset: true,
        successReset: false,
      })
    );
    const response = yield call(
      competitionService.competitionService.resetMatch,
      action.payload
    );
    yield put(Creators.forfeitResponseCompetition(response.data));
    yield put(
      Creators.competitionUpdates({
        loadingReset: false,
        successReset: true,
      })
    );
    validToast(i18n.t("successful_update"));
  } catch (e) {
    yield put(
      Creators.competitionUpdates({
        loadingReset: false,
      })
    );
    errorToast(i18n.t("failed_update"));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_COMPETITION, index);
  yield takeLatest(Types.STORE_REQUEST_COMPETITION, store);
  yield takeLatest(Types.EDIT_REQUEST_COMPETITION, edit);
  yield takeLatest(Types.UPDATE_REQUEST_COMPETITION, update);
  yield takeLatest(Types.DESTROY_REQUEST_COMPETITION, destroy);
  yield takeLatest(Types.GET_REQUEST_MATCH, getMatch);
  yield takeLatest(Types.PLANNING_REQUEST_COMPETITION, planning);
  yield takeLatest(
    Types.MATCH_COMPOSITION_REQUEST_COMPETITION,
    matchComposition
  );
  yield takeLatest(Types.MATCH_REPORT_REQUEST_COMPETITION, matchReport);
  yield takeLatest(Types.RANKING_REQUEST_COMPETITION, ranking);
  yield takeLatest(Types.STATS_REQUEST_COMPETITION, stats);
  yield takeLatest(Types.CLEAR_STATS_REQUEST_COMPETITION, clearStats);
  yield takeLatest(Types.STATS_TEAM_REQUEST_COMPETITION, statsTeam);
  yield takeLatest(Types.ARCHIVE_REQUEST_COMPETITION, archive);
  yield takeLatest(Types.UNARCHIVE_REQUEST_COMPETITION, unarchive);
  yield takeLatest(Types.FORFEIT_REQUEST_COMPETITION, forfeit);
  yield takeLatest(Types.RESET_REQUEST_COMPETITION, reset);
}

// Reducer handlers
const competition_updates = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    competitions: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    competition: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

//get match
const get_match_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    match: action.data.data,
  };
};

//planning
const planning_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successPlanning: true,
  };
};

//match composition
const match_composition_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successMatchComposition: true,
  };
};

//match report
const match_report_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successMatchReport: true,
  };
};

//ranking
const ranking_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    competition: action.data.competition,
    ranking: action.data.teams,
  };
};

//stats
const stats_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    competition: action.data.competition,
    stats: action.data,
  };
};

//stats team
const stats_team_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    competition: action.data.competition,
    statsTeam: action.data.team,
  };
};

//arhive
const archive_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    competition: action.data.competition,
  };
};

//unarhive
const unarchive_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    competition: action.data.competition,
  };
};

const update_current_competition = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    competition: action.data,
  };
};
const forfeit_response = (state = INITIAL_STATE, action) => {
  if (!state.competition)
    return {
      ...state,
    };
  let match = action.data.match;
  let competition = { ...state.competition };
  let matches = [...competition.matches];
  if (competition?.matches) {
    matches.forEach((it, i) => {
      if (it.id === match.id) matches[i] = match;
    });
    competition.matches = matches;
  }
  return {
    ...state,
    competition,
  };
};

// Reducer
export const competitionReducer = createReducer(INITIAL_STATE, {
  [Types.COMPETITION_UPDATES]: competition_updates,
  [Types.INDEX_RESPONSE_COMPETITION]: index_response,
  [Types.STORE_RESPONSE_COMPETITION]: store_response,
  [Types.EDIT_RESPONSE_COMPETITION]: edit_response,
  [Types.UPDATE_RESPONSE_COMPETITION]: update_response,
  [Types.DESTROY_REQUEST_COMPETITION]: destroy_request,
  [Types.GET_RESPONSE_MATCH]: get_match_response,
  [Types.PLANNING_RESPONSE_COMPETITION]: planning_response,
  [Types.MATCH_COMPOSITION_RESPONSE_COMPETITION]: match_composition_response,
  [Types.MATCH_REPORT_RESPONSE_COMPETITION]: match_report_response,
  [Types.RANKING_RESPONSE_COMPETITION]: ranking_response,
  [Types.STATS_RESPONSE_COMPETITION]: stats_response,
  [Types.STATS_TEAM_RESPONSE_COMPETITION]: stats_team_response,
  [Types.ARCHIVE_RESPONSE_COMPETITION]: archive_response,
  [Types.FORFEIT_RESPONSE_COMPETITION]: forfeit_response,
  [Types.UNARCHIVE_RESPONSE_COMPETITION]: unarchive_response,
  [Types.UPDATE_CURRENT_COMPETITION]: update_current_competition,
});
