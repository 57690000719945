import React from "react";
import {
  Button,
  Checkbox,
  Grid,
  Icon,
  Image,
  Loader,
  Modal,
  Table,
} from "semantic-ui-react";
import TicketMenuItem from "./TicketMenuItem";
import TicketAccordion from "./TicketAccordion";
import FinalTicketAccordion from "./FinalTicketAccordion";
import "./Ticket.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import i18n from "@/i18n";
import { url } from "../../../helpers";
import { debounce, imgUrl } from "@/helpers";
import {
  convertTicket,
  cumulArticlePayedAmountFromPaymentArticle,
  cumulPayedAmountFromPaymentTypes,
  cumulPaymentArticlesFromParticipant,
  cumulPaymentTypesFromArticle,
  cumulSameUsedCreditIndex,
  getArticleDiscount,
  getCreditNumber,
  getNextTicketId,
  getParentIdIsActivity,
  getParticipantDiscount,
  isSubscriptionPaymentType,
  setArticleStatus,
  setArticleWithoutNoteStatus,
  totallyPayedByAvoirOrAdvance,
} from "@/helpers/ticket";
import { getHT } from "@/helpers/caisse/tvaCalcul";
import { infoToast, warningToast } from "@/helpers/customToast";
import {
  advancePrint,
  PrintPopup,
  reloadPrint,
  subscriptionPrint,
  ticketPrint,
} from "../../../helpers/print";
import ModalConfirm from "../../ModalConfirm/ModalConfirm";
import { valeur } from "@/pages/CaissePage/CaissePage";
import caisseService from "@/services/caisseService";
import {
  reservationResponse,
  resetReservationState,
} from "../../../helpers/reservationFormatting";
import { Creators as reservationCreators } from "../../../store/ducks/reservation";
import ChooseUserAdvanceModal from "../PaymentsModal/ChooseUserAdvanceModal";
import ChooseUserReloadModal from "../PaymentsModal/ChooseUserReloadModal";
import moment from "moment";
import {
  formatCodeCountry,
  nullString,
  phoneNumber,
} from "../../../helpers/formatting";
import {
  convertTicketToNote,
  defaultNote,
  getAmountNote,
  getCurrentNote,
  getCurrentNoteIndex,
  getNoteDiscount,
  getPayedAmountNote,
  hasAllParticipantsByNote,
  hasParticipantsNote,
  returnArticleNoteToTicket,
} from "@/helpers/caisse/noteFormatting";
import { getTotalAmountForNote } from "../../../helpers/caisseFormatting";
import {
  getLastIndexNote,
  getPaymentNoteFromTicket,
  noteHasArticles,
} from "../../../helpers/caisse/noteFormatting";
import { add } from "@/helpers/operation";
import InvoiceModal from "../Modal/InvoiceModal";

class Ticket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      number: null,
      loadingNumber: false,
      errors: {
        firstName: null,
        lastName: null,
        email: null,
      },
      search_player: "",
      modalContent: null,
      deleteTicketModalOpen: false,
      all_article_checked: false,
      pendingTicket: [],
      successPaymentModalOpen: false,
      successConcludeTicketModalOpen: false,
      successModalOpen: false,
      modalMsg: "",
      editPaymentModalOpen: false,
      factureModalOpen: false,
      checkedArticleList: [],
      checkedArticleIdList: [],
      cancelTicketModalOpen: false,
      credit_value: 0,
      reset_credit_amount: 0,
      userAdvanceCallback: () => {},
      creditModalOpen: false,
      confirmCreditModalOpen: false,
      cancelArticlesModalOpen: false,
      invoiceModal: false,
      loadingCancelArticle: false,
      userAdvanceModalOpen: false,
      userReloadModalOpen: false,
      userReloadCallback: () => {},
      cancledMethod: null,
      totalDiscount: false,
      totalAvoirOrSubscription: false,
      confirmCreditTransferModalOpen: false,
      payAdvanceFromReservationModalOpen: false,
      paymentLoader: false,
      deleteNoteModalOpen: false,
      note_index: null,
      showArticlesNote: [],
      printTicketModalOpen: false,
    };
  }

  renderSuccessModal() {
    return (
      <Modal
        open={this.state.successModalOpen}
        onClose={this.handleClose}
        size="mini"
        className="advance-modal withOpacity"
      >
        <span>{this.state.modalMsg}</span>
      </Modal>
    );
  }

  renderSuccessPaymentModal() {
    return (
      <Modal
        open={this.state.successPaymentModalOpen}
        onClose={this.handleClose}
        size="mini"
        className="success-modal withOpacity"
      >
        <span>{i18n.t("payed")}</span>
      </Modal>
    );
  }

  async componentDidMount() {
    let user = null;
    if (
      this.props.currentTicket &&
      this.props.currentTicket.user_id &&
      this.props.currentTicket.user
    ) {
      user = {
        firstName: this.props.currentTicket.user.firstName,
        lastName: this.props.currentTicket.user.lastName,
        email: this.props.currentTicket.user.email,
        mobile: this.props.currentTicket.user.mobile,
        country_code: this.props.currentTicket.user.country_code,
        avatar: this.props.currentTicket.user.avatar,
      };
      this.setState({ user: user });
    }
    if (this.props.currentTicket && this.props.currentTicket.reservation) {
      user = {
        firstName: this.props.currentTicket.reservation.user.firstName,
        lastName: this.props.currentTicket.reservation.user.lastName,
        email: this.props.currentTicket.reservation.user.email,
        mobile: this.props.currentTicket.reservation.user.mobile,
        country_code: this.props.currentTicket.reservation.user.country_code,
        avatar: this.props.currentTicket.reservation.user.avatar,
      };
      this.setState({ user: user });
    }

    this.setState({ all_article_checked: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.currentTicket !== prevProps.currentTicket &&
      this.props.currentTicket &&
      !this.props.currentTicket.number &&
      !this.state.number
    ) {
      if (!this.state.loadingNumber) {
        this.setState({ loadingNumber: true });
        getNextTicketId()
          .then((number) => this.setState({ number }))
          .finally(() => this.setState({ loadingNumber: false }));
      }
    }

    // assign number=null if currecTicket  === null
    if (
      this.props.currentTicket !== prevProps.currentTicket &&
      !this.props.currentTicket &&
      this.state.number
    ) {
      this.setState({ number: null });
    }

    if (this.props.pendingTicket !== prevProps.pendingTicket) {
      this.setState({
        ...this.state,
        pendingTicket: this.props.pendingTicket,
      });
    }
    if (
      this.props.currentTicket !== prevProps.currentTicket &&
      this.props.currentTicket &&
      this.props.currentTicket.status === 0
    ) {
      let checkedArticleList = [];
      this.props.currentTicket.articles.forEach((item, index) => {
        checkedArticleList[index] = false;
      });
      this.setState({
        checkedArticleList: checkedArticleList,
        checkedArticleIdList: [],
      });
      this.props.participant_to_edit_payment(null);
      this.props.article_to_edit_payment(null);
    }

    if (
      this.props.currentTicket &&
      this.props.currentTicket !== prevProps.currentTicket
    ) {
      let user = null;
      if (this.props.currentTicket.user_id && this.props.currentTicket.user) {
        user = {
          firstName: this.props.currentTicket.user.firstName,
          lastName: this.props.currentTicket.user.lastName,
          email: this.props.currentTicket.user.email,
          mobile: this.props.currentTicket.user.mobile,
          country_code: this.props.currentTicket.user.country_code,
          avatar: this.props.currentTicket.user.avatar,
        };
      }
      this.setState({ all_article_checked: false, user: user });
    }

    if (
      this.props.currentTicket &&
      prevProps.currentTicket &&
      this.props.currentTicket.reservation &&
      this.props.currentTicket.reservation !==
        prevProps.currentTicket.reservation
    ) {
      let user = {
        firstName: this.props.currentTicket.reservation.user.firstName,
        lastName: this.props.currentTicket.reservation.user.lastName,
        email: this.props.currentTicket.reservation.user.email,
        country_code: this.props.currentTicket.reservation.user.country_code,
        mobile: this.props.currentTicket.reservation.user.mobile,
        avatar: this.props.currentTicket.reservation.user.avatar,
      };
      this.setState({ user: user });
    }

    if (
      this.props.currentTicket &&
      prevProps.currentTicket &&
      !this.props.currentTicket.user_id &&
      !this.props.currentTicket.reservation &&
      this.props.currentTicket.reservation !==
        prevProps.currentTicket.reservation
    ) {
      this.setState({ user: null });
    }

    if (
      this.props.currentTicket &&
      this.props.currentTicket !== prevProps.currentTicket
    ) {
      if (
        this.state.cancledMethod === "article" &&
        this.props.currentTicket.status === 2
      ) {
        this.props.updateCurrentTicket(null);
        this.props.caisse_redirection("ticket", 2, "ticket");
        this.props.getTickets({ page: 1, size: 10, status: [2] });
      }
      //delete last article -> delete ticket
      if (
        this.props.currentTicket &&
        this.props.currentTicket.articles_nb === 0 &&
        prevProps.currentTicket &&
        prevProps.currentTicket.articles_nb > 0
      ) {
        if (this.props.currentTicket.id) {
          await this.props.destroyTicket(this.props.currentTicket.id);
        }
        await this.props.updateCurrentTicket(null);
      }
    }

    if (
      this.props.currentTicket &&
      prevProps.currentTicket &&
      this.props.currentTicket.articles.length >
        prevProps.currentTicket.articles.length
    ) {
      // scroll to end when article add
      // document
      //   .querySelector('.ticket-content').scrollTo(0, document.querySelector('.ticket-content')?.scrollHeight);
    }

    //redirection to home if nothing is selected (unselect last article/participant)
    // this will redirect us to home if we still in payment page
    if (
      Object.entries(prevProps.articleToPay).length > 0 &&
      Object.entries(this.props.articleToPay).length === 0 &&
      this.props.articleToPay !== prevProps.articleToPay
    ) {
      this.props.caisse_redirection("home");
    }

    //generate avoir after cancel reload (subscription)
    if (
      this.props.successCancelReload &&
      this.props.successCancelReload !== prevProps.successCancelReload
    ) {
      this.setState({ confirmCreditModalOpen: false });
      if (this.props.credit) {
        this.setState({ creditModalOpen: true });
      }
    }
  }

  renderCreditModal() {
    return (
      <ModalConfirm
        open={this.state.creditModalOpen}
        onClose={() => this.setState({ creditModalOpen: false })}
        title={i18n.t("avoir")}
        message={
          <p>
            {i18n.t("an-avoir")}{" "}
            {this.props.credit && this.props.credit.credit_number}{" "}
            {i18n.t("has-amount")}{" "}
            {this.props.credit &&
              parseFloat(this.props.credit.amount).toFixed(2)}
            {localStorage.getItem("currency")} {i18n.t("has-generate")}
          </p>
        }
        ok={() => {
          this.setState({ creditModalOpen: false });
          this.props.updateCurrentTicket(null);
          this.props.caisse_redirection("ticket", 0, "reload");
        }}
      />
    );
  }

  async generateCredit() {
    //cancel article
    let articlesToCancel = this.props.currentTicket.articles.filter(
      (itm) => itm.status !== "cancled"
    );
    let checkedArticleIdList = this.state.checkedArticleIdList;
    if (this.state.cancledMethod === "article") {
      //all article selected
      if (checkedArticleIdList.length === articlesToCancel.length) {
        await this.cancelArticles(true);
      } else {
        await this.cancelArticles(false);
      }
    }
    //generate credit
    await this.props.createCredit(
      this.state.credit_value,
      this.state.cancledMethod
    );

    //reset
    this.setState({
      confirmCreditModalOpen: false,
      totalDiscount: false,
      totalAvoirOrSubscription: false,
    });
  }

  renderConfirmCreditModal() {
    return (
      <ModalConfirm
        open={this.state.confirmCreditModalOpen}
        onClose={() =>
          this.setState({
            confirmCreditModalOpen: false,
            totalDiscount: false,
            totalAvoirOrSubscription: false,
          })
        }
        loading={this.props.cancelReloadLoading}
        disabled={this.props.cancelReloadLoading}
        title={
          this.state.totalAvoirOrSubscription
            ? "Pas d'avoir"
            : this.state.totalDiscount
            ? "Annuler les articles sélectionnés"
            : "Confirmation avoir"
        }
        message={
          this.state.totalAvoirOrSubscription ? (
            <p>
              Pas d'avoir à générer pour un paiement par avoir et/ou abonnement
              classique.
            </p>
          ) : this.state.totalDiscount ? (
            <p>
              Pas d'avoir à générer. Les articles sélectionnés sont payés par
              une remise de 100%.
            </p>
          ) : this.state.cancledMethod === "reload" ? (
            <p>Cette action va annuler la recharge et générer un avoir</p>
          ) : (
            <p>Cette action va générer un avoir</p>
          )
        }
        ok={() => {
          if (this.state.cancledMethod === "reload") {
            this.cancelReload();
          } else {
            this.generateCredit();
          }
        }}
        cancel={() => {
          if (this.state.cancledMethod === "article") {
            this.setState({
              cancelArticlesModalOpen: false,
              checkedArticleIdList: [],
              checkedArticleList: this.unCheckedArticleList(),
              all_article_checked: false,
            });
          } else {
            if (this.state.cancledMethod !== "reload") {
              this.props.caisse_redirection("ticket", 2, "ticket");
              this.props.getTickets({ page: 1, size: 10, status: [2] });
            }
            this.props.updateCurrentTicket(null);
          }

          this.props.restored_credit_list(null);
          this.setState({
            confirmCreditModalOpen: false,
            totalDiscount: false,
            totalAvoirOrSubscription: false,
            cancledMethod: null,
          });
        }}
      />
    );
  }

  handleClose = () => {
    this.setState({
      deleteTicketModalOpen: false,
      successConcludeTicketModalOpen: false,
      successPaymentModalOpen: false,
      successModalOpen: false,
      editPaymentModalOpen: false,
      modalMsg: "",
    });
  };

  /***
   * Restore credit or advance
   */
  restoreCreditOrAdvance = async (payments_type, id) => {
    for (let payment_type of payments_type) {
      if (payment_type.article_id.toString() === id.toString()) {
        if (payment_type.credit_id) {
          let creditTicket = await caisseService.caisseService.getCredit(
            payment_type.credit_id
          );
          creditTicket = creditTicket.data;
          creditTicket.payed_amount =
            parseFloat(creditTicket.payed_amount) -
            parseFloat(payment_type.amount);
          await caisseService.caisseService.updateTicket(creditTicket);
        }
        if (payment_type.advance_id) {
          let advanceTicket = await caisseService.caisseService.getCredit(
            payment_type.advance_id
          );
          advanceTicket = convertTicket(advanceTicket.data);
          advanceTicket.payed_amount =
            parseFloat(advanceTicket.payed_amount) -
            parseFloat(payment_type.amount);
          await caisseService.caisseService.updateTicket(advanceTicket);
        }
      }
    }
  };

  deleteTicket = async () => {
    let currentTicket = { ...this.props.currentTicket };
    let { reservation_id } = currentTicket;

    this.props.setValueReservationTicket({ reservationTicket: null });
    if (currentTicket.id) {
      //destroy reservation
      if (
        currentTicket &&
        reservation_id &&
        currentTicket.reservation &&
        currentTicket.reservation.is_ticket === 1
      )
        await this.props.destoryReservation({ id: reservation_id });

      await this.props.destroyTicket(currentTicket.id);
    }

    this.props.decrement_occ_nb(null);
    this.props.caisse_redirection("home");
    this.props.updateCurrentTicket(null);
    this.props.updateCurrentNote(null);
    this.props.article_to_pay([], 0, 0);
    this.props.select_family(getParentIdIsActivity(this.props.menusData));
    this.props.select_sub_family(0);
    this.props.select_hour_type(0);
    this.setState({
      all_article_checked: false,
      user: null,
      userAdvanceCallback: () => {},
      userReloadCallback: () => {},
    });
    this.handleClose();
  };

  renderDeleteTicketModal() {
    return (
      <ModalConfirm
        open={this.state.deleteTicketModalOpen}
        onClose={this.handleClose}
        title={"Suppression d'un ticket"}
        message={
          this.props.currentTicket &&
          parseFloat(this.props.currentTicket.payed_amount) === 0 ? (
            <p>
              Vous êtes sur le point de supprimer
              <br />
              un ticket en cours
            </p>
          ) : (
            <p>
              Vous êtes sur le point de supprimer <br />
              un ticket déjà payé.
            </p>
          )
        }
        ok={() => this.deleteTicket()}
        cancel={() => {
          this.handleClose();
          this.props.decrement_occ_nb(null);
        }}
        okText={"Supprimer"}
      />
    );
  }

  renderDefaultPaymentButton() {
    if (this.props.paymentTypes !== null) {
      return this.props.paymentTypes.map((item, index) => {
        if (item.is_show === 1) {
          // let [amountToPay] = getAmountToPay(this.props.currentTicket, this.props.articleToPay,this.props.currentNote);
          return (
            <Button
              key={index}
              disabled={
                this.props.appLoading ||
                this.state.paymentLoader ||
                this.props.caisseRedirection.value === "payment" ||
                this.props.caisseRedirection.value === "discount" ||
                !Object.entries(this.props.articleToPay).length > 0 ||
                parseFloat(this.props.amountToPay) === 0 ||
                this.props.articleToEditPayment ||
                this.props.participantToEditPayment
                // Object.entries(this.props.articleToPartialPayment).length > 0 ||
                // Object.entries(this.props.participantToPartialPayment).length > 0)
              }
              className={"ticket-menu-item btn-grey-on"}
              onClick={debounce(async () => {
                //pay advance
                if (
                  this.props.currentTicket &&
                  this.props.currentTicket.articles[0] &&
                  this.props.currentTicket.articles[0].parent.is_advance ===
                    1 &&
                  !this.state.user
                ) {
                  this.setState({
                    userAdvanceModalOpen: true,
                    userAdvanceCallback: () => {
                      this.props.article_to_pay(
                        { [this.props.currentTicket.articles[0].id]: [] },
                        this.props.currentTicket.articles[0].total_price,
                        parseFloat(
                          this.props.currentTicket.articles[0].total_price
                        ) -
                          parseFloat(
                            this.props.currentTicket.articles[0].payed_amount
                          )
                      );
                      setTimeout(() => {
                        this.props.caisseRedirection.value !== "payment" &&
                          this.props.caisseRedirection.value !== "discount" &&
                          Object.entries(this.props.articleToPay).length &&
                          parseFloat(this.props.amountToPay) !== 0 &&
                          !this.props.articleToEditPayment &&
                          !this.props.participantToEditPayment &&
                          this.props.articleToPartialPayment.length === 0 &&
                          this.props.participantToPartialPayment.length === 0 &&
                          this.paySelectedArticles(item.id);
                      }, 200);
                    },
                  });
                }
                //pay reload
                else if (
                  this.props.currentTicket &&
                  this.props.currentTicket.articles[0] &&
                  this.props.currentTicket.articles[0].parent.is_reload === 1 &&
                  !this.state.user
                ) {
                  this.setState({
                    userReloadModalOpen: true,
                    userReloadCallback: () => {
                      this.props.article_to_pay(
                        { [this.props.currentTicket.articles[0].id]: [] },
                        this.props.currentTicket.articles[0].total_price,
                        parseFloat(
                          this.props.currentTicket.articles[0].total_price
                        ) -
                          parseFloat(
                            this.props.currentTicket.articles[0].payed_amount
                          )
                      );
                      setTimeout(() => {
                        this.props.caisseRedirection.value !== "payment" &&
                          this.props.caisseRedirection.value !== "discount" &&
                          Object.entries(this.props.articleToPay).length &&
                          parseFloat(this.props.amountToPay) !== 0 &&
                          !this.props.articleToEditPayment &&
                          !this.props.participantToEditPayment &&
                          this.props.articleToPartialPayment.length === 0 &&
                          this.props.participantToPartialPayment.length === 0 &&
                          this.paySelectedArticles(item.id);
                      }, 200);
                    },
                  });
                } else {
                  this.props.caisseRedirection.value !== "payment" &&
                    this.props.caisseRedirection.value !== "discount" &&
                    Object.entries(this.props.articleToPay).length &&
                    parseFloat(this.props.amountToPay) !== 0 &&
                    !this.props.articleToEditPayment &&
                    !this.props.participantToEditPayment &&
                    // this.props.articleToPartialPayment.length === 0 &&
                    // this.props.participantToPartialPayment.length === 0 &&
                    this.paySelectedArticles(item.id);
                }
              }, 500)}
            >
              <div className="text-center btn-inline-icon">
                {item.name}
                <Image
                  src={
                    item.icon
                      ? imgUrl + "/payments/" + item.icon
                      : require("../../../assets/other_white.svg")
                  }
                />
              </div>
            </Button>
          );
        }
      });
    }
  }

  renderArticleToTicket() {
    let ticket = this.props.currentTicket;
    let articlesList = [];
    if (ticket && ticket.articles.length) {
      ticket.articles.forEach((item, index) => {
        if (
          (item.parent &&
            (item.parent.is_activity === 0 || item.child?.is_res === 0) &&
            item.note_index === null) || //article case
          (item.note_index === null &&
            item.parent &&
            item.parent.is_activity === 1 &&
            !hasAllParticipantsByNote(ticket, item.id, null) &&
            hasParticipantsNote(ticket, item.id, null)) || //all participants linked to notes (activity case)
          (item.parent &&
            item.parent.is_activity === 1 &&
            hasParticipantsNote(ticket, item.id, null))
        )
          if (ticket.status !== 1) {
            //has +one participant linked to notes (activity case)
            //final ticket or cancled ticket
            articlesList.push(
              <FinalTicketAccordion
                key={"final-article-" + item.article_id + "-" + index}
                article={item}
                is_checked={
                  this.state.checkedArticleList[index] ||
                  this.state.all_article_checked
                }
                onChange={() => {
                  let checkedArticleIdList = [
                    ...this.state.checkedArticleIdList,
                  ];
                  let checkedArticleList = [...this.state.checkedArticleList];
                  checkedArticleList[index] = !checkedArticleList[index];
                  if (checkedArticleList[index]) {
                    checkedArticleIdList.push(item.id);
                  } else {
                    checkedArticleIdList = checkedArticleIdList.filter(
                      (itm) => itm !== item.id
                    );
                  }
                  // if (checkedArticleList.length > 0 && checkedArticleList.includes(false)) {
                  //   this.setState({ all_article_checked: false });
                  // } else {
                  //   this.setState({ all_article_checked: true });
                  // }
                  this.setState({
                    checkedArticleList: checkedArticleList,
                    checkedArticleIdList: checkedArticleIdList,
                  });
                }}
                is_note={null}
                note_index={null}
              />
            );
          }
          //pending ticket
          else {
            articlesList.push(
              <TicketAccordion
                key={"pending-article-" + item.article_id + "-" + index}
                article={item}
                //all_checked={this.state.all_article_checked}
                status={item.status}
                payed_amount={item.payed_amount}
                createCredit={(credit_value) =>
                  this.props.createCredit(credit_value)
                }
                // restoreCreditOrAdvance={() => this.restoreCreditOrAdvance(ticket.payments.articles, item.id)}
                is_note={null}
                note_index={null}
              />
            );
          }
      });
    }
    return articlesList;
  }

  /** Note */
  renderDeleteNoteModal() {
    return (
      <ModalConfirm
        open={this.state.deleteNoteModalOpen}
        onClose={() =>
          this.setState({ deleteNoteModalOpen: false, note_index: null })
        }
        title={"Suppression d'une note"}
        message={<p>Vous êtes sur le point de supprimer une note</p>}
        ok={() => {
          let ticket = { ...this.props.currentTicket };
          ticket.notes = ticket.notes.filter(
            (it) => it.note_index !== this.props.currentNote
          );
          ticket.noteInProgress = false;
          if (this.props.currentNote) {
            ticket = returnArticleNoteToTicket(ticket, this.props.currentNote);
          }
          this.props.updateCurrentTicket({ ...ticket });
          this.props.updateCurrentNote(null);
          this.props.article_to_pay([], 0, 0);
          this.setState({ deleteNoteModalOpen: false, note_index: null });
          infoToast("La division de la note est supprimée");
          this.props.caisse_redirection("home", null, null);
        }}
        cancel={() => {
          this.setState({ deleteNoteModalOpen: false, note_index: null });
        }}
        okText={"Supprimer"}
      />
    );
  }

  renderListNote() {
    let ticket = this.props.currentTicket;
    let listNote = [];
    let currentNote = this.props.currentNote;
    if (ticket && ticket.notes.length) {
      listNote.push(
        <div className="ticket-grey-block" key={"header"}>
          <span>liste note</span>
        </div>
      );
      ticket.notes.forEach((note, index) => {
        listNote.push(
          <div
            key={"header-note-" + note.note_index}
            className={"header-note-list"}
          >
            {note.note_index === currentNote && <hr />}
            <div className={"header-note"}>
              <span style={{ flex: 0.2 }}>
                {note.note_index === currentNote && (
                  <Button
                    onClick={(e) => {
                      this.props.article_to_pay([], 0, 0);
                      this.props.updateCurrentNote(null);
                      this.props.updateNoteInProgress(false);
                      this.props.caisse_redirection("home");
                    }}
                    className={"edit-note-botton"}
                    icon={"arrow left"}
                    circular={true}
                  />
                )}
                <span style={{ paddingRight: "5px" }}> Note {index + 1}</span>

                {note.note_index !== currentNote && ticket.status === 1 && (
                  <Button
                    onClick={(e) => {
                      this.props.updateCurrentNote(note.note_index);
                      this.props.updateNoteInProgress(true);
                      this.props.caisse_redirection("note_division");
                    }}
                    className={"edit-note-botton"}
                    icon={"pencil"}
                    circular={true}
                  />
                )}

                {note.note_index === currentNote && ticket.status === 1 && (
                  <Button
                    onClick={(e) => {
                      this.setState({
                        deleteNoteModalOpen: true,
                        note_index: note.note_index,
                      });
                    }}
                    icon={"trash"}
                    circular={true}
                    className={"delete-note-botton"}
                  />
                )}
              </span>
              <div
                style={{
                  display: "flex",
                  fontSize: 12,
                  justifyContent: "space-between",
                  flex: 0.64,
                  padding: "0px 4px",
                }}
              >
                <span>
                  {note.name
                    ? note.name
                    : nullString(note.user?.firstName) +
                      " " +
                      nullString(note.user?.lastName)}
                </span>
                <span>
                  {(
                    parseFloat(getTotalAmountForNote(ticket, note.note_index)) -
                    parseFloat(getNoteDiscount(ticket, note.note_index))
                  ).toFixed(2) + localStorage.getItem("currency")}
                </span>
              </div>
              <div style={{ flex: 0.1 }}>
                <span className={"ticket-icon-note"}>
                  <Icon
                    key={"hideList-" + index}
                    circular={true}
                    className={`angle ${
                      this.state.showArticlesNote[index] ? " down" : " up"
                    }`}
                    onClick={() => {
                      let showNote = this.state.showArticlesNote;
                      showNote[index] = !this.state.showArticlesNote[index];
                      this.setState({ showArticlesNote: showNote });
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        );
        if (!this.state.showArticlesNote[index]) {
          ticket.articles.forEach((item, i) => {
            if (
              item.note_index === note.note_index ||
              (item.parent &&
                item.parent.is_activity === 1 &&
                hasParticipantsNote(ticket, item.id, note.note_index)) //activity case
            ) {
              //finalized ticket + canceled ticket
              if (ticket.status !== 1) {
                listNote.push(
                  <FinalTicketAccordion
                    key={
                      "final_article_note_" +
                      nullString(
                        item.note_index ? item.note_index : listNote.length
                      ) +
                      "-" +
                      item.id
                    }
                    article={item}
                    is_checked={
                      this.state.checkedArticleList[index] ||
                      this.state.all_article_checked
                    }
                    onChange={() => {
                      let checkedArticleIdList = [
                        ...this.state.checkedArticleIdList,
                      ];
                      let checkedArticleList = [
                        ...this.state.checkedArticleList,
                      ];
                      checkedArticleList[index] = !checkedArticleList[index];
                      if (checkedArticleList[index]) {
                        checkedArticleIdList.push(item.article_id);
                      } else {
                        checkedArticleIdList = checkedArticleIdList.filter(
                          (itm) => itm !== item.article_id
                        );
                      }
                      if (
                        checkedArticleList.length > 0 &&
                        checkedArticleList.includes(false)
                      ) {
                        this.setState({ all_article_checked: false });
                      } else {
                        this.setState({ all_article_checked: true });
                      }
                      this.setState({
                        checkedArticleList: checkedArticleList,
                        checkedArticleIdList: checkedArticleIdList,
                      });
                    }}
                    is_note={note}
                    note_index={note.note_index}
                  />
                );
              }
              //pending ticket
              else {
                listNote.push(
                  <TicketAccordion
                    key={
                      "pending_article_note_" +
                      nullString(
                        item.note_index ? item.note_index : listNote.length
                      ) +
                      "-" +
                      item.id
                    }
                    article={item}
                    all_checked={this.props.articleToPay.hasOwnProperty(
                      item.id
                    )}
                    status={item.status}
                    payed_amount={item.payed_amount}
                    createCredit={(credit_value) =>
                      this.props.createCredit(credit_value)
                    }
                    // restoreCreditOrAdvance={() => this.restoreCreditOrAdvance(ticket.payments.articles, item.id)}
                    is_note={note}
                    note_index={note.note_index}
                  />
                );
              }
            }
          });
        }
      });
    }
    return listNote;
  }

  putTicketOnPending = async () => {
    let currentTicket = { ...this.props.currentTicket };
    if (currentTicket && currentTicket.articles_nb !== 0) {
      currentTicket.pending = true;
      if (currentTicket.id) {
        await this.props.updateTicket(currentTicket);
      } else {
        await this.props.storeTicket({
          ...currentTicket,
          owner_id: this.props.user.id,
        });
      }
      this.props.caisse_redirection("home");
      this.props.select_family(getParentIdIsActivity(this.props.menusData));
      this.props.select_sub_family(0);
      this.props.select_hour_type(0);
    }
  };

  checkAllArticleToPay = async () => {
    let checkedArticleList = [];
    let checkedArticleIdList = [];
    let currentTicket = { ...this.props.currentTicket };
    if (this.props.currentTicket === null) return;
    let articleToPartialPayment = [];
    let participantToPartialPayment = [];
    if (
      currentTicket
      //&& (parseFloat(currentTicket.amount) > parseFloat(currentTicket.payed_amount) || currentTicket.status === 0)
    ) {
      await this.setState({
        all_article_checked: !this.state.all_article_checked,
      });
      let articleToPay = {};
      let amountToPay = 0;
      let restAmountToPay = 0;
      if (this.state.all_article_checked) {
        currentTicket.articles.map((article, index) => {
          let participantPartial = [];
          if (
            article.status !== "payed" &&
            (article.note_index === this.props.currentNote ||
              hasParticipantsNote(
                this.props.currentTicket,
                article.id,
                this.props.currentNote
              ))
          ) {
            let list_participants = [];
            if (
              article.parent.is_activity === 1 &&
              article.child.is_res === 1
            ) {
              //get not payed participant of selected activity
              currentTicket.participants.map((item, i) => {
                if (
                  item.article_id === article.id &&
                  item.status !== "payed" &&
                  item.note_index === this.props.currentNote
                ) {
                  if (item.status === "partial")
                    participantPartial.push(item.user_index);
                  list_participants.push(item.user_index);
                  amountToPay = (
                    parseFloat(amountToPay) +
                    parseFloat(article.price_per_participant) -
                    parseFloat(
                      getParticipantDiscount(currentTicket, item.user_index)
                    )
                  ).toFixed(2);
                  restAmountToPay = (
                    parseFloat(amountToPay) - parseFloat(item.payed_amount)
                  ).toFixed(2);
                }
              });
            } else {
              amountToPay = (
                parseFloat(amountToPay) +
                parseFloat(article.total_price) * parseInt(article.article_nb) -
                parseFloat(
                  getArticleDiscount(
                    currentTicket,
                    article.id,
                    article.note_index
                  )
                )
              ).toFixed(2);
              restAmountToPay = (
                parseFloat(restAmountToPay) +
                (parseFloat(article.total_price) -
                  parseFloat(article.payed_amount)) *
                  parseInt(article.article_nb) -
                parseFloat(
                  getArticleDiscount(
                    currentTicket,
                    article.id,
                    article.note_index
                  )
                )
              ).toFixed(2);
            }

            if (
              article.parent.is_activity === 1 &&
              article.child.is_res === 1
            ) {
              if (list_participants.length > 0)
                articleToPay[article.id] = list_participants;
            } else {
              articleToPay[article.id] = list_participants;
            }

            //partial case
            if (article.status === "partial") {
              if (
                article.parent.is_activity === 1 &&
                article.child.is_res === 1
              ) {
                if (
                  !articleToPartialPayment.includes(article.id) &&
                  list_participants.length > 0
                ) {
                  //articleToPartialPayment.push(article.id);
                  if (participantPartial.length) {
                    participantToPartialPayment = [
                      ...participantToPartialPayment,
                      participantPartial,
                    ];
                    participantToPartialPayment = participantToPartialPayment.flat();
                  }
                }
              } else {
                if (!articleToPartialPayment.includes(article.id)) {
                  articleToPartialPayment.push(article.id);
                }
              }
            }
          } else if (currentTicket.status === 0) {
            checkedArticleList[index] = true;
            checkedArticleIdList.push(article.id);
          }
        });
      } else {
        currentTicket.articles.forEach((item, index) => {
          checkedArticleList[index] = false;
        });
        checkedArticleIdList = [];
      }
      this.setState({
        checkedArticleList: checkedArticleList,
        checkedArticleIdList: checkedArticleIdList,
      });
      this.props.article_to_pay(articleToPay, amountToPay, restAmountToPay);
      this.props.article_to_partial_payment(articleToPartialPayment);
      this.props.participant_to_partial_payment(participantToPartialPayment);
    }
  };

  paymentIsCredit = (payment_type_id) => {
    let value = false;
    this.props.paymentTypes.map((itm, i) => {
      if (itm.id === payment_type_id && itm.is_credit === 1) {
        value = true;
      }
    });
    return value;
  };

  getPaymentName(payment_type_id) {
    let name = "";
    this.props.paymentTypes.map((itm, i) => {
      if (itm.id === payment_type_id) {
        name = itm.name;
      }
    });
    return name;
  }

  renderCreditList = () => {
    let { currentTicket } = this.props;
    if (currentTicket && currentTicket.usedCredits) {
      let usedCredits = currentTicket.usedCredits;
      //cumul credit with same credit number
      if (!this.props.currentNote) {
        usedCredits = cumulSameUsedCreditIndex(usedCredits);
      }
      return usedCredits.map((usedCredit, i) => {
        if (
          usedCredit.note_index === this.props.currentNote ||
          !this.props.currentNote
        ) {
          return (
            <div key={"credit-" + i}>
              <span>
                En Avoir {parseFloat(usedCredit.amount).toFixed(2)}
                {localStorage.getItem("currency")} ({usedCredit.credit_number})
              </span>
              <br />
            </div>
          );
        }
      });
    }
  };

  renderPaymentAction = () => {
    let currentTicket = { ...this.props.currentTicket };
    let currentNote = getCurrentNote(currentTicket, this.props.currentNote);

    let payments = currentTicket.payments.types;
    if (currentNote) {
      payments = getPaymentNoteFromTicket(
        currentTicket.payments.articles,
        this.props.currentNote
      );
    }
    if (payments.length) {
      return payments.map((item, index) => {
        return (
          <span key={"payment-" + index}>
            {this.paymentIsCredit(item.payment_type_id) ? (
              <>{this.renderCreditList()}</>
            ) : (
              <div>
                <span>
                  En {this.getPaymentName(item.payment_type_id)}{" "}
                  {parseFloat(item.amount).toFixed(2)}
                  {localStorage.getItem("currency")}
                </span>
                <br />
              </div>
            )}
          </span>
        );
      });
    }
  };

  /**
   * simple payment
   */
  articlePayment(type_payment_id, current_article) {
    let currentTicket = { ...this.props.currentTicket };
    let articles = { ...currentTicket.payments.articles };
    articles = Object.values(articles);
    let old_article_payment = [];
    let article_nb = current_article.article_nb;

    let article_price =
      parseFloat(current_article.total_price) * parseInt(article_nb) -
      parseFloat(
        getArticleDiscount(
          currentTicket,
          current_article.id,
          current_article.note_index
        )
      ) -
      parseFloat(current_article.payed_amount);
    //get exist payment type
    if (Object.entries(currentTicket.payments.articles).length) {
      old_article_payment = currentTicket.payments.articles.filter(
        (itm) => itm.article_id === current_article.id
      );
      old_article_payment = old_article_payment.map((item, index) => {
        return item.payment_type_id;
      });
    }
    if (old_article_payment && old_article_payment.includes(type_payment_id)) {
      currentTicket.payments.articles.map((article_item, ii) => {
        if (
          article_item.payment_type_id === type_payment_id &&
          article_item.article_id === current_article.id
        ) {
          article_item["amount"] =
            parseFloat(article_item.amount) + parseFloat(article_price);
        }
      });
    } else {
      let article = {};
      article["article_id"] = current_article.id;
      article["payment_type_id"] = type_payment_id;
      article["amount"] = parseFloat(article_price).toFixed(2);
      article["amount_ht"] = parseFloat(
        getHT(parseFloat(article_price), current_article.vat.value)
      ).toFixed(3);
      article["vat_amount"] = (
        parseFloat(article_price) -
        parseFloat(getHT(parseFloat(article_price), current_article.vat.value))
      ).toFixed(3);
      article["vat_id"] = current_article.vat_id;
      article["note_index"] = this.props.currentNote;

      //add credit to susbcription details (create and pay reload)
      if (current_article.parent.is_reload === 1 && currentTicket.subscriber) {
        article["subscriber_id"] = currentTicket.subscriber.id;
        article["subscriber_detail"] = {
          date: currentTicket.date,
          article_id: current_article.id,
          subscriber_id: currentTicket.subscriber.id,
          payment_type_id: type_payment_id,
          id: null,
          ticket_id: null,
          reservation_id: null,
          label: current_article.length > 0 ? current_article.name : "",
          credit: parseFloat(current_article.total_price).toFixed(2),
        };
      }
      articles.push(article);
      currentTicket.payments.articles = articles;
    }
    return articles;
  }

  participantPayment(type_payment_id, current_participant) {
    let currentTicket = { ...this.props.currentTicket };
    let current_article = currentTicket.articles.filter(
      (it) =>
        it.id.toString().split("-")[0] ===
        current_participant.article_id.toString().split(valeur)[0]
    );
    let participants = { ...currentTicket.payments.participants };
    participants = Object.values(participants);
    let participant_price = (
      parseFloat(current_participant.amount) -
      parseFloat(
        getParticipantDiscount(currentTicket, current_participant.user_index)
      ) -
      parseFloat(current_participant.payed_amount)
    ).toFixed(2);
    let participant = {
      user_id: current_participant.user_id,
      user_index: current_participant.user_index,
      article_id: current_participant.article_id,
      amount: parseFloat(participant_price).toFixed(2),
      amount_ht: parseFloat(
        getHT(parseFloat(participant_price), current_article[0].vat.value)
      ).toFixed(3),
      vat_amount: (
        parseFloat(participant_price) -
        parseFloat(
          getHT(parseFloat(participant_price), current_article[0].vat.value)
        )
      ).toFixed(3),
      vat_id: current_participant.vat_id,
      payment_type_id: type_payment_id,
      note_index: this.props.currentNote,
    };
    participants.push(participant);
    currentTicket.payments.participants = participants;
  }

  paySelectedArticles = async (type_payment_id) => {
    this.setState({ paymentLoader: true });
    let { articleToPay } = { ...this.props };
    let currentTicket = { ...this.props.currentTicket };

    let returnedArticles = [];
    articleToPay = Object.entries(articleToPay);
    let list_participant = [];
    if (articleToPay.length) {
      articleToPay.map((article_to_pay, i) => {
        currentTicket.articles.map((item, index) => {
          if (item.id.toString() === article_to_pay[0].toString()) {
            //pay per participant
            if (
              item.parent.is_activity === 1 &&
              item.child.is_res === 1 &&
              article_to_pay[1].length
            ) {
              if (Object.entries(currentTicket.participants).length) {
                list_participant = currentTicket.participants.filter((itm) =>
                  article_to_pay[1].includes(itm.user_index)
                );
                if (Object.entries(list_participant).length) {
                  list_participant.map((client, ind) => {
                    this.participantPayment(type_payment_id, client);
                    client["status"] = "payed";
                    client["payed_amount"] = parseFloat(client.amount);

                    //transfert discount from ticket to note if exist => use discount of ticket)
                    if (this.props.currentNote) {
                      currentTicket.discountTypes.participants.forEach((it) => {
                        if (it.user_index === client.user_index) {
                          it.note_index = this.props.currentNote;
                        }
                      });
                    }
                  });
                }
                //update
                let articlePayment = currentTicket.payments.articles.filter(
                  (itm) => itm.article_id !== item.id
                );
                articlePayment = [
                  ...articlePayment,
                  ...cumulPaymentArticlesFromParticipant(
                    currentTicket,
                    item.id
                  ),
                ];

                //vat for article payment ticket (vat exact)
                articlePayment.map((it, i) => {
                  if (it.article_id === item.id) {
                    it.amount_ht = isSubscriptionPaymentType(
                      this.props.paymentTypes,
                      it.payment_type_id
                    )
                      ? 0
                      : getHT(parseFloat(it.amount), item.vat.value);
                    it.vat_amount = isSubscriptionPaymentType(
                      this.props.paymentTypes,
                      it.payment_type_id
                    )
                      ? 0
                      : (
                          parseFloat(it.amount) -
                          parseFloat(
                            getHT(parseFloat(it.amount), item.vat.value)
                          )
                        ).toFixed(3);
                    it.vat_id = isSubscriptionPaymentType(
                      this.props.paymentTypes,
                      it.payment_type_id
                    )
                      ? null
                      : item.vat_id;
                  }
                });
                currentTicket.payments.articles = articlePayment;
              }
            }
            //pay per article
            else {
              returnedArticles = this.articlePayment(type_payment_id, item);
              currentTicket = {
                ...currentTicket,
                payments: {
                  ...currentTicket.payments,
                  articles: [...returnedArticles],
                },
              };
            }
            item.payed_amount = cumulArticlePayedAmountFromPaymentArticle(
              currentTicket,
              item.id
            );
            item.status =
              item.parent.is_activity === 1 && item.child.is_res === 1
                ? setArticleWithoutNoteStatus(currentTicket, item)
                : setArticleStatus(currentTicket, item);
          }
        });
      });
      currentTicket.payments.types = cumulPaymentTypesFromArticle(
        currentTicket
      );
      currentTicket["payed_amount"] = cumulPayedAmountFromPaymentTypes(
        currentTicket
      );
      //create advance
      if (currentTicket.articles[0].parent.is_advance === 1) {
        currentTicket.is_advance = 1;
        currentTicket.status = 1;
        currentTicket.payed_amount = 0;
        currentTicket.article_id = currentTicket.articles[0].id
          .toString()
          .split(valeur)[0];
        currentTicket.advance = true;
      }
      //create reload
      if (currentTicket.articles[0].parent.is_reload === 1) {
        currentTicket.is_reload = 1;
        currentTicket.status = 1;
        currentTicket.payed_amount = currentTicket.articles[0].total_price;
        currentTicket.article_id = currentTicket.articles[0].id
          .toString()
          .split(valeur)[0];
        currentTicket.reload = true;
        delete currentTicket.subscriber;
      }

      //save current ticket
      if (currentTicket.id) {
        await this.props.updateTicket({ ...currentTicket });
      } else {
        await this.props.storeTicket({
          ...currentTicket,
          owner_id: this.props.user.id,
        });
      }

      await this.setState({
        successPaymentModalOpen: true,
        paymentLoader: false,
      });
      this.props.article_to_pay([], 0, 0);
      this.props.article_to_partial_payment([]);
      this.props.participant_to_partial_payment([]);
      await this.setState({
        amountToPay: 0,
        restAmountToPay: "",
        totalAmount: 0,
      });
      await setTimeout(
        function () {
          this.setState({ successPaymentModalOpen: false });
        }.bind(this),
        1000
      );
      this.props.caisse_redirection("home");
    }
  };

  /**
   * end simple payment
   **/

  /**
   * start conclude ticket
   */
  renderSuccessConcludeTicketModal() {
    return (
      <Modal
        open={this.state.successConcludeTicketModalOpen}
        onClose={this.handleClose}
        size="mini"
        className="success-modal withOpacity"
      >
        <span>Encaissé</span>
      </Modal>
    );
  }

  concludeTicketPayment = async () => {
    let currentTicket = { ...this.props.currentTicket };
    currentTicket.status = 0;
    currentTicket.conclude = true;
    if (currentTicket.id) {
      await this.props.updateTicket(currentTicket);
    } else {
      await this.props.storeTicket({
        ...currentTicket,
        owner_id: this.props.user.id,
      });
    }
    this.props.caisse_redirection("home");
    this.props.select_family(getParentIdIsActivity(this.props.menusData));
    this.props.select_sub_family(0);
    this.props.select_hour_type(0);
    await this.setState({
      successConcludeTicketModalOpen: true,
      checkedArticleIdList: [],
    });
    setTimeout(
      function () {
        this.setState({ successConcludeTicketModalOpen: false });
      }.bind(this),
      1000
    );
  };

  /**
   * end conclude ticket
   */

  disabilityOfDiscountBtn() {
    let discount = 0;
    let withoutDiscount = 0;
    let articleToPay = { ...this.props.articleToPay };
    let currentTicket = { ...this.props.currentTicket };
    articleToPay = Object.entries(articleToPay);

    let list_participant = [];
    if (Object.entries(currentTicket).length) {
      //more then article with discount
      if (articleToPay.length > 1) {
        articleToPay.map((article_to_pay, i) => {
          currentTicket.articles.map((item, index) => {
            if (item.id.toString() === article_to_pay[0].toString()) {
              //select one participant
              if (article_to_pay[1].length === 1) {
                if (
                  getParticipantDiscount(currentTicket, article_to_pay[1][0]) >
                  0
                ) {
                  discount += 1;
                } else {
                  withoutDiscount += 1;
                }
              }
              //select participants
              if (item.default_participants_nb >= article_to_pay[1].length) {
                if (
                  getArticleDiscount(currentTicket, item.id, item.note_index) >
                  0
                ) {
                  //activity have discount
                  let nb_participant_without_discount =
                    article_to_pay[1].length;
                  list_participant = currentTicket.participants.filter((itm) =>
                    article_to_pay[1].includes(itm.user_index)
                  );
                  if (Object.entries(list_participant).length) {
                    list_participant.map((client, ind) => {
                      if (
                        getParticipantDiscount(
                          currentTicket,
                          client.user_index
                        ) > 0
                      ) {
                        nb_participant_without_discount =
                          nb_participant_without_discount - 1;
                      }
                    });
                  }

                  if (
                    nb_participant_without_discount !== 0 && // activity not totaly discount
                    nb_participant_without_discount !== article_to_pay[1].length
                  ) {
                    //activity have partial discount
                    discount += 1;
                    withoutDiscount += 1;
                  }
                } else {
                  withoutDiscount += 1;
                }
              }
              //select article (not activity)
              else if (article_to_pay[1].length === 0) {
                if (
                  getArticleDiscount(
                    currentTicket,
                    article_to_pay[0],
                    item.note_index
                  ) > 0
                ) {
                  discount += 1;
                } else {
                  withoutDiscount += 1;
                }
              }
            }
          });
        });
      }
      //select one activity and more then participant with discount
      if (articleToPay.length === 1) {
        articleToPay.map((article_to_pay, i) => {
          currentTicket.articles.map((item, index) => {
            if (item.id.toString() === article_to_pay[0].toString()) {
              if (item.parent.is_activity === 1 && item.child.is_res === 1) {
                //select some participants from activity
                if (item.default_participants_nb > article_to_pay[1].length) {
                  if (Object.entries(currentTicket.participants).length) {
                    list_participant = currentTicket.participants.filter(
                      (itm) => article_to_pay[1].includes(itm.user_index)
                    );
                    if (Object.entries(list_participant).length) {
                      list_participant.map((client, ind) => {
                        if (
                          getParticipantDiscount(
                            currentTicket,
                            client.user_index
                          )
                        ) {
                          discount += 1;
                        } else {
                          withoutDiscount += 1;
                        }
                      });
                    }
                  }
                }
                //select activity (all participant)
                else {
                  if (
                    getArticleDiscount(
                      currentTicket,
                      item.id,
                      item.note_index
                    ) > 0
                  ) {
                    //activity have discount
                    let nb_participant_without_discount =
                      article_to_pay[1].length;
                    list_participant = currentTicket.participants.filter(
                      (itm) => article_to_pay[1].includes(itm.user_index)
                    );
                    if (Object.entries(list_participant).length) {
                      list_participant.map((client, ind) => {
                        if (
                          getParticipantDiscount(
                            currentTicket,
                            client.user_index
                          ) > 0
                        ) {
                          nb_participant_without_discount =
                            nb_participant_without_discount - 1;
                        }
                      });
                    }

                    if (
                      nb_participant_without_discount !== 0 && // activity not totaly discount
                      nb_participant_without_discount !==
                        article_to_pay[1].length
                    ) {
                      //activity have partial discount
                      discount += 1;
                      withoutDiscount += 1;
                    }
                  }
                }
              }
            }
          });
        });
      }

      if (
        discount > 1 ||
        (discount > 0 && withoutDiscount > 0) ||
        articleToPay.length === 0 ||
        this.props.articleToPartialPayment.length > 0 ||
        this.props.participantToPartialPayment.length > 0
      ) {
        return true;
      }
    }
  }

  /***
   * ticket detail actions (invoice/credit/edit/cancel)
   */
  unCheckedArticleList() {
    let checkedArticleList = [];
    if (
      this.props.currentTicket &&
      Object.entries(this.props.currentTicket.articles).length
    ) {
      this.props.currentTicket.articles.map((item, index) => {
        checkedArticleList[index] = false;
      });
    }

    return checkedArticleList;
  }

  cancelArticles = async (allSelected) => {
    let status = 2;
    let currentTicket = { ...this.props.currentTicket };
    this.setState({ loadingCancelArticle: true });
    let articles = [];
    let creditValue = 0;
    let totalArticleAmount = 0;
    let restoredCreditList = [];
    for (let article of currentTicket.articles) {
      if (article.status !== "cancled") {
        if (
          this.state.checkedArticleIdList.includes(article.id) ||
          allSelected
        ) {
          totalArticleAmount += parseFloat(article.payed_amount);
          for (let article_payment of currentTicket.payments.articles) {
            if (
              article_payment.article_id.toString() === article.id.toString()
            ) {
              //restore avoir
              if (article_payment.credit_id) {
                creditValue += parseFloat(article_payment.amount);
                let restoredCreditListIds = restoredCreditList.length
                  ? restoredCreditList.map((itm) => {
                      return itm.id;
                    })
                  : [];
                if (
                  !restoredCreditListIds.includes(article_payment.credit_id)
                ) {
                  restoredCreditList.push({
                    id: article_payment.credit_id,
                    credit_number: getCreditNumber(
                      currentTicket,
                      article_payment.credit_id
                    ),
                    credit_value: parseFloat(article_payment.amount),
                  });
                } else {
                  restoredCreditList.map((itm, i) => {
                    if (itm.id === article_payment.credit_id) {
                      itm.credit_value =
                        parseFloat(itm.credit_value) +
                        parseFloat(article_payment.amount);
                    }
                  });
                }
              }

              //classic subscriber case
              if (
                article_payment.subscriber &&
                article_payment.subscriber.subscription &&
                article_payment.subscriber.subscription.subscription_type &&
                article_payment.subscriber.subscription.subscription_type
                  .is_reload === 0
              ) {
                totalArticleAmount -= parseFloat(article_payment.amount);
              }
            }
          }
          article.status = "cancled";
        }
        //all articles cancled ->  ticket cancled
        if (article.status !== "cancled") {
          status = 0;
        }
      }
      articles.push(article);
    }

    currentTicket.articles = articles;
    currentTicket.status = status;
    if (allSelected) {
      currentTicket.status = 2;
      let articles = currentTicket.articles.map((article, index) => {
        return { ...article, status: "cancled" };
      });
      currentTicket.articles = articles;
    }
    creditValue = parseFloat(totalArticleAmount) - parseFloat(creditValue);

    this.setState({
      credit_value: creditValue,
      loadingCancelArticle: false,
      checkedArticleIdList: [],
      checkedArticleList: this.unCheckedArticleList(),
      all_article_checked: false,
    });
    this.props.restored_credit_list(restoredCreditList);
    currentTicket.restoredCreditList = restoredCreditList;
    await this.props.updateTicket(currentTicket);

    if (
      allSelected ||
      (currentTicket.reservation &&
        this.state.checkedArticleIdList.includes(currentTicket.article_id))
    ) {
      if (currentTicket.reservation && currentTicket.reservation_id) {
        if (currentTicket.reservation.is_ticket === 1) {
          // ticket create from caisse
          // delete reservation
          this.props.destoryReservation({
            id: currentTicket.reservation_id,
            all: false,
          });
        } else {
          // reservation create by planning
          let reservation = { ...currentTicket.reservation };
          let reservationTicket = { ...reservation };
          let state = resetReservationState();
          let reservationData = reservationResponse(
            state.reservationForm,
            reservationTicket
          );
          reservationData.information.conclusion = "A valider";
          reservationData.information.price = null;
          this.props.updateReservation(reservationData);
        }
      }
    }
  };

  renderCancledTicketModal() {
    return (
      <ModalConfirm
        open={this.state.cancelTicketModalOpen}
        onClose={() => this.setState({ cancelTicketModalOpen: false })}
        title={
          "Suppression d'un " +
          (this.props.currentTicket && this.props.currentTicket.is_advance === 1
            ? "acompte"
            : "ticket")
        }
        message={
          <p>
            Vous êtes sur le point de supprimer un{" "}
            {this.props.currentTicket &&
            this.props.currentTicket.is_advance === 1
              ? "acompte"
              : "ticket"}
          </p>
        }
        okText={"Supprimer"}
        loading={this.state.loadingCancelArticle}
        ok={async () => {
          await this.cancelArticles(true);
          this.props.decrement_occ_nb(null);
          this.setState({
            cancelTicketModalOpen: false,
            confirmCreditModalOpen: true,
            cancledMethod:
              this.props.currentTicket &&
              this.props.currentTicket.is_advance === 0
                ? "ticket"
                : "advance",
          });
        }}
        cancel={() => {
          let checkedArticleList = [];
          this.props.currentTicket.articles.map((item, index) => {
            checkedArticleList[index] = false;
          });
          this.setState({
            cancelTicketModalOpen: false,
            checkedArticleIdList: [],
            checkedArticleList: this.unCheckedArticleList(),
            all_article_checked: false,
          });
        }}
      />
    );
  }

  updateArticlePayment = async () => {
    let selectedArticle = this.state.checkedArticleIdList[0];
    await this.props.caisse_redirection("payment");
    await this.props.participant_to_edit_payment(null);
    await this.props.article_to_edit_payment(selectedArticle);
    await this.setState({ editPaymentModalOpen: false });
  };

  renderEditPaymentModal() {
    return (
      <ModalConfirm
        open={this.state.editPaymentModalOpen}
        onClose={() => {
          this.props.decrement_occ_nb(null);
          this.setState({ editPaymentModalOpen: false });
        }}
        title={"Modification d'un règlement"}
        message={<p>Vous êtes sur le point de modifier un règlement?</p>}
        ok={this.updateArticlePayment}
        cancel={() => {
          this.props.decrement_occ_nb(null);
          this.setState({ editPaymentModalOpen: false });
        }}
      />
    );
  }

  renderFactureModal() {
    return (
      <ModalConfirm
        open={this.state.factureModalOpen}
        onClose={this.handleClose}
        title={"Facture"}
        message={
          <p>
            Saisissez les informations souhaitées. <br />
            Cette action va générer une facture
          </p>
        }
        ok={() => {
          this.setState({ invoiceModal: true, factureModalOpen: false });
          // this.props.getTicketInfo();
        }}
        cancel={() => this.setState({ factureModalOpen: false })}
      />
    );
  }

  renderTicketCreditList() {
    if (Object.entries(this.props.currentTicket.credits).length) {
      return this.props.currentTicket.credits.map((credit, index) => {
        return (
          <div key={"credit-" + index}>
            {credit.credit_number} - {credit.amount}
            {localStorage.getItem("currency")}
          </div>
        );
      });
    }
  }

  /**
   * Advance
   */
  renderPayAdvanceFromReservationModal() {
    return (
      <ModalConfirm
        open={this.state.payAdvanceFromReservationModalOpen}
        onClose={() =>
          this.setState({ payAdvanceFromReservationModalOpen: false })
        }
        title={"Paiement de l'acompte"}
        message={<p>Vous êtes sur le point de payer l'acompte?</p>}
        ok={() => {
          this.updateArticlePayment();
          this.setState({ payAdvanceFromReservationModalOpen: false });
        }}
        cancel={() => {
          this.props.decrement_occ_nb(null);
          this.setState({ payAdvanceFromReservationModalOpen: false });
        }}
      />
    );
  }

  renderChooseUserAdvanceModal() {
    return (
      <ChooseUserAdvanceModal
        open={this.state.userAdvanceModalOpen}
        onClose={() => {
          this.setState({
            userAdvanceModalOpen: false,
            userAdvanceCallback: () => {},
          });
        }}
        onChange={(selectedUser) => {
          let user = {
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            mobile: selectedUser.mobile,
            country_code: selectedUser.country_code,
            avatar: selectedUser.avatar,
          };
          this.setState({ user: user }, () => {
            this.state.userAdvanceCallback();
            this.setState({
              userAdvanceModalOpen: false,
              userAdvanceCallback: () => {},
            });
          });
        }}
      />
    );
  }

  async createAdvance() {
    this.setState({ paymentLoader: true });
    let advance = this.props.currentTicket;
    if (advance.amount) {
      advance.status = 0;
      await this.props.updateTicket(advance);
    }
    //reset
    await this.props.caisse_redirection("home");
    await this.props.updateCurrentTicket(null);
    this.props.select_family(getParentIdIsActivity(this.props.menusData));
    this.props.select_sub_family(0);
    this.props.select_hour_type(0);
    this.setState({
      successModalOpen: true,
      user: null,
      modalMsg: "Acompte généré",
      paymentLoader: false,
    });
    setTimeout(
      function () {
        this.setState({ successModalOpen: false, modalMsg: "" });
      }.bind(this),
      1500
    );
  }

  renderConfirmCreditTransferModal() {
    return (
      <ModalConfirm
        open={this.state.confirmCreditTransferModalOpen}
        onClose={() => this.setState({ confirmCreditTransferModalOpen: false })}
        title={"Confirmer le transfère"}
        message={<p>{i18n.t("transfer-advance")} </p>}
        ok={() => {
          this.transferAdvance();
          this.setState({ confirmCreditTransferModalOpen: false });
        }}
        cancel={() => this.setState({ confirmCreditTransferModalOpen: false })}
      />
    );
  }

  transferAdvance = async () => {
    let advance = this.props.currentTicket;

    let credit = parseFloat(advance.amount) - parseFloat(advance.payed_amount);
    advance.payed_amount = parseFloat(advance.amount);
    await this.props.updateTicket(advance);
    this.props.getTickets({ page: 1, size: 10, status: [0], is_advance: 1 });
    //generate an avoir
    this.props.createCredit(credit);

    //transfer advance to credit accompte
    // let detail = {
    //   user_id: advance.user_id,
    //   credit: parseFloat(advance.amount) - parseFloat(advance.payed_amount),
    //   is_advance: 1,
    //   complex_id: localStorage.getItem('complex_id'),
    // };
    // await this.props.storeCreditDetails(detail);
    // advance.payed_amount = parseFloat(advance.amount);
    // await this.props.updateTicket(advance);
    // //reset
    // this.props.getTickets({ page: 1, size: 10, status: [0, 1], is_advance: 1 });
    // await this.setState({ successModalOpen: true, modalMsg: 'Crédit transféré' });
    // setTimeout(
    //   function() {
    //     this.setState({ successModalOpen: false });
    //   }.bind(this),
    //   1500,
    // );
  };

  /**
   * Reload
   */
  checkIsActivity = () => {
    let activities = [];
    if (this.props.currentTicket.articles.length > 0) {
      activities.push(this.props.currentTicket.articles[0].child.activity_id);
    }
    return activities;
  };

  renderChooseUserReloadModal() {
    return (
      <ChooseUserReloadModal
        open={this.state.userReloadModalOpen}
        activity_id={this.checkIsActivity()}
        onClose={() => {
          this.setState({
            userReloadModalOpen: false,
            userReloadCallback: () => {},
          });
        }}
        onChange={(selectedUser) => {
          let user = {
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            mobile: selectedUser.mobile,
            country_code: selectedUser.country_code,
            avatar: selectedUser.avatar,
          };
          this.setState({ user: user }, () => {
            this.state.userReloadCallback();
            this.setState({
              userReloadModalOpen: false,
              userReloadCallback: () => {},
            });
          });
        }}
      />
    );
  }

  async createReload() {
    this.setState({ paymentLoader: true });
    let reload = this.props.currentTicket;
    if (reload.amount) {
      reload.status = 0;
      await this.props.updateTicket(reload);
    }
    //reset
    await this.props.caisse_redirection("home");
    await this.props.updateCurrentTicket(null);
    this.props.select_family(getParentIdIsActivity(this.props.menusData));
    this.props.select_sub_family(0);
    this.props.select_hour_type(0);
    this.setState({
      successModalOpen: true,
      user: null,
      modalMsg: i18n.t("cashed"),
      paymentLoader: false,
    });
    setTimeout(
      function () {
        this.setState({ successModalOpen: false, modalMsg: "" });
      }.bind(this),
      1500
    );
  }

  cancelReload() {
    let detail = { ...this.props.currentTicket.credit_subscriber_detail };
    let expense = parseFloat(detail.credit) + parseFloat(detail.bonus);
    if (parseFloat(detail.subscriber.total_balance) >= parseFloat(expense)) {
      let subscriber_detail = {
        date: moment().format("YYYY-MM-DD"),
        label: "Recharge recovery of " + detail.date,
        credit: 0,
        bonus: 0,
        payment_type_id: null,
        expense: parseFloat(expense),
        subscriber_id: detail.subscriber_id,
        checkout_session_id: this.props.session.id,
      };

      this.props.cancelReload(detail.id, {
        is_ticket: true,
        subscriber_detail: subscriber_detail,
        date: moment().format("YYYY-MM-DD"),
        hour: moment().format("hh:mm"),
        amount: parseFloat(expense),
        is_credit: 1,
        payed_amount: 0,
        status: 0,
        owner_id: this.props.user.id,
        parent_id: null,
        complex_id: localStorage.getItem("complex_id"),
        articles: [],
        participants: [],
        checkout_session_id: this.props.session.id,
        payments: { articles: [], participants: [] },
        discountTypes: { articles: [], participants: [] },
        notes: [],
      });
    } else {
      warningToast(i18n.t("warning-msg-insufficient-sold"));
      this.setState({ confirmCreditModalOpen: false });
    }
  }

  /**
   * print
   */
  printTicket(with_details) {
    let cloneTicket = JSON.parse(JSON.stringify(this.props.currentTicket)); //copy of table without reference (deep copy)
    let i = getCurrentNoteIndex(cloneTicket, this.props.currentNote);
    let ticket = convertTicketToNote(cloneTicket, this.props.currentNote);
    let complex = this.props.complex;
    let number = cloneTicket.number + "/N" + (i + 1);

    let receipt = ticketPrint(
      ticket,
      complex,
      this.props.ticketInfos,
      number,
      with_details
    );
    PrintPopup(receipt);
    this.setState({ printTicketModalOpen: false });
  }

  renderPrintTicketModal() {
    return (
      <ModalConfirm
        open={this.state.printTicketModalOpen}
        onClose={() => this.setState({ printTicketModalOpen: false })}
        title={i18n.t("print")}
        message={<p>Voulez vous imprimer le ticket avec détails?</p>}
        okText={i18n.t("yes")}
        cancelText={i18n.t("no")}
        ok={() => this.printTicket(true)}
        cancel={() => this.printTicket(false)}
      />
    );
  }

  render() {
    let currentNote = getCurrentNote(
      this.props.currentTicket,
      this.props.currentNote
    );
    let show_note = currentNote && currentNote.status === 1;

    return (
      <div
        className={
          "ticket" +
          (this.props.appLoading || this.props.loadingTicketById
            ? " ticket-loading"
            : "")
        }
      >
        {this.renderDeleteTicketModal()}
        {this.renderSuccessConcludeTicketModal()}
        {this.renderSuccessPaymentModal()}
        {this.renderCancledTicketModal()}
        {this.renderFactureModal()}
        {this.renderConfirmCreditModal()}
        {/* {this.renderCancelArticlesModal()} */}
        {this.renderEditPaymentModal()}
        {this.renderSuccessModal()}
        {this.renderConfirmCreditTransferModal()}
        {this.renderPayAdvanceFromReservationModal()}
        {this.state.userAdvanceModalOpen && this.renderChooseUserAdvanceModal()}
        {this.state.userReloadModalOpen && this.renderChooseUserReloadModal()}
        {this.state.deleteNoteModalOpen && this.renderDeleteNoteModal()}
        {this.state.printTicketModalOpen && this.renderPrintTicketModal()}
        {this.state.creditModalOpen && this.renderCreditModal()}
        {(this.props.appLoading || this.props.loadingTicketById) && (
          <Loader
            className={"ticket-loading-loader"}
            active={true}
            inline={"centered"}
            inverted={false}
          />
        )}
        <InvoiceModal
          ticket={this.props.currentTicket}
          open={this.state.invoiceModal}
          onClose={() => this.setState({ invoiceModal: false })}
        />
        {/*************************************Ticket Header***************************************/}
        <div className={"ticket-info"}>
          {this.props.currentTicket &&
            parseInt(this.props.currentTicket.articles_nb) !== 0 && (
              <>
                <span>
                  {show_note && "Note / "}
                  Ticket
                  <br />
                  <b>
                    {this.props.currentTicket &&
                    this.props.currentTicket.articles[0] &&
                    this.props.currentTicket.articles[0].parent.is_advance === 1
                      ? this.props.currentTicket.advance_number
                      : this.props.currentTicket.number
                      ? this.props.currentTicket.number
                      : this.state.number}
                  </b>
                </span>
                {show_note ? (
                  <>
                    <Image
                      className="ticket-avatar avatar-cover"
                      src={
                        currentNote.user && currentNote.user.avatar
                          ? url + "/uploads/users/" + currentNote.user.avatar
                          : require("../../../assets/avatar.svg")
                      }
                      avatar
                    />
                    <span>
                      {currentNote.user && (
                        <b>
                          {nullString(currentNote.user.firstName) +
                            " " +
                            nullString(currentNote.user.lastName).toUpperCase()}
                          <br />
                          {formatCodeCountry(currentNote.user.country_code) +
                            phoneNumber(currentNote.user.mobile)}
                        </b>
                      )}
                    </span>
                  </>
                ) : (
                  <>
                    <Image
                      className="ticket-avatar avatar-cover"
                      src={
                        this.state.user && this.state.user.avatar
                          ? url + "/uploads/users/" + this.state.user.avatar
                          : require("../../../assets/avatar.svg")
                      }
                      avatar
                      onClick={() => {
                        if (
                          this.props.currentTicket &&
                          this.props.currentTicket.articles[0] &&
                          this.props.currentTicket.articles[0].parent
                            .is_advance === 1 &&
                          this.props.currentTicket.payments.types.length === 0
                        ) {
                          this.setState({ userAdvanceModalOpen: true });
                        }
                        if (
                          this.props.currentTicket &&
                          this.props.currentTicket.articles[0] &&
                          this.props.currentTicket.articles[0].parent
                            .is_reload === 1 &&
                          this.props.currentTicket.payments.types.length === 0
                        ) {
                          this.setState({ userReloadModalOpen: true });
                        }
                      }}
                    />
                    <span>
                      {this.state.user ? (
                        <b>
                          {nullString(this.state.user.firstName) +
                            " " +
                            nullString(this.state.user.lastName).toUpperCase()}
                          <br />
                          {formatCodeCountry(this.state.user.country_code) +
                            phoneNumber(this.state.user.mobile)}
                        </b>
                      ) : (
                        (this.props.currentTicket.articles[0].parent
                          .is_advance === 1 ||
                          // this.props.currentTicket.articles[0].parent.is_reload === 1 ||
                          this.props.currentTicket.articles[0].parent
                            .is_credit === 1) && (
                          <span>
                            Cliquez sur la photo <br /> pour choisir
                            l'utilisateur
                          </span>
                        )
                      )}
                    </span>
                  </>
                )}
                <span>
                  {this.props.currentTicket &&
                    this.props.currentTicket.reservation &&
                    this.props.currentTicket.reservation.activity && (
                      <>
                        <Image
                          src={
                            this.props.currentTicket &&
                            !this.props.currentTicket.reservation.activity &&
                            !this.props.currentTicket.reservation.activity
                              .avatar
                              ? url +
                                "/uploads/activities/" +
                                localStorage.getItem("complex_id") +
                                "/" +
                                this.props.currentTicket.reservation.activity
                                  .avatar
                              : require("../../../assets/activity.svg")
                          }
                        />
                        {this.props.currentTicket &&
                        this.props.currentTicket.reservation.activity
                          ? this.props.currentTicket.reservation.activity.name
                          : ""}
                      </>
                    )}
                </span>
                <span>
                  {this.props.currentTicket &&
                  this.props.currentTicket.reservation
                    ? this.props.currentTicket.reservation.date
                    : this.props.currentTicket.date}
                  {this.props.currentTicket.reservation &&
                    this.props.currentTicket.reservation.terrain && (
                      <>
                        <br />
                        {this.props.currentTicket &&
                        this.props.currentTicket.reservation
                          ? this.props.currentTicket.reservation.terrain.name
                          : this.props.currentTicket.reservation_id && (
                              <>
                                <br />
                                Terrain
                              </>
                            )}
                      </>
                    )}
                  <br />
                  {this.props.currentTicket &&
                  this.props.currentTicket.reservation &&
                  this.props.currentTicket.reservation.start_hour &&
                  this.props.currentTicket.reservation.end_hour
                    ? this.props.currentTicket.reservation.start_hour
                        .split(":")
                        .join("H") +
                      " - " +
                      this.props.currentTicket.reservation.end_hour
                        .split(":")
                        .join("H")
                    : this.props.currentTicket.hour}
                </span>
              </>
            )}
        </div>

        <div className="ticket-block">
          {!show_note &&
          this.props.currentTicket &&
          this.props.currentTicket.articles.length > 0 &&
          this.props.currentTicket.status === 1 ? (
            <Button
              icon="close"
              color="red"
              className="close-btn"
              circular
              size="tiny"
              onClick={() => {
                this.setState({ deleteTicketModalOpen: true });
              }}
            />
          ) : (
            ""
          )}
          {/*********************************Ticket Content******************************************/}
          <div className="ticket-content">
            <div className="ticket-content-header">
              <Table className="ticket-table" basic="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell collapsing></Table.Cell>
                    <Table.Cell collapsing>
                      <Checkbox
                        className={"qty"}
                        name={"all_article_checked"}
                        label={i18n.t("qty")}
                        disabled={this.props.currentTicket?.status === 0}
                        checked={this.state.all_article_checked}
                        onChange={() => this.checkAllArticleToPay()}
                      />
                    </Table.Cell>
                    <Table.Cell width={10} style={{ paddingLeft: "4%" }}>
                      {i18n.t("wording")}
                    </Table.Cell>
                    <Table.Cell collapsing></Table.Cell>
                    <Table.Cell width={2} collapsing textAlign="left">
                      {i18n.t("price")}
                    </Table.Cell>
                    <Table.Cell collapsing width={2}>
                      {this.props.currentTicket &&
                      this.props.currentTicket.status === 1 &&
                      this.props.currentTicket.articles.length &&
                      this.props.currentTicket.articles[0].parent.is_reload ===
                        0 &&
                      this.props.currentTicket.articles[0].parent.is_advance ===
                        0 ? (
                        // <Image
                        //   className={this.props.currentTicket.noteInProgress ? 'img-division-inverse' : 'img-division'}
                        //   src={require('../../../assets/division.svg')}
                        //   onClick={() => {
                        //     this.props.caisse_redirection('note_division');
                        //     let ticket = { ...this.props.currentTicket };
                        //     let index = (ticket.notes.length);
                        //     if (!show_note) {
                        //       if (!ticket.noteInProgress) {
                        //         ticket.noteInProgress = true;
                        //         this.props.article_to_pay([], 0, 0);
                        //         let noteDivision = { ...defaultNote() };
                        //         noteDivision.note_index = 'note-' + (index === -1 ? 0 : index).toString();
                        //         ticket.notes.push(noteDivision);
                        //         this.props.updateCurrentNote(noteDivision.note_index);
                        //       } else if (index && ticket.notes[index - 1].note_index === null) {
                        //         ticket.noteInProgress = false;
                        //         ticket.notes.pop();
                        //         this.props.caisse_redirection('home');
                        //       }
                        //       this.props.updateCurrentTicket(ticket);
                        //     }
                        //
                        //   }}
                        // />

                        <span
                          className={
                            "note-btn " +
                            (this.props.currentTicket.noteInProgress
                              ? "note-active"
                              : " ")
                          }
                          onClick={() => {
                            this.props.caisse_redirection("note_division");
                            let ticket = { ...this.props.currentTicket };
                            let index = ticket.notes.length;
                            if (!show_note) {
                              if (!ticket.noteInProgress) {
                                ticket.noteInProgress = true;
                                this.props.article_to_pay([], 0, 0);
                                if (
                                  index > 0 &&
                                  ticket.notes[index - 1].note_index !== null &&
                                  !noteHasArticles(
                                    ticket,
                                    ticket.notes[index - 1].note_index
                                  )
                                ) {
                                  this.props.updateCurrentNote(
                                    ticket.notes[index - 1].note_index
                                  );
                                } else {
                                  let noteDivision = { ...defaultNote() };
                                  noteDivision.note_index =
                                    "note-" +
                                    getLastIndexNote(ticket.notes).toString();
                                  ticket.notes.push(noteDivision);
                                  this.props.updateCurrentNote(
                                    noteDivision.note_index
                                  );
                                }
                              } else if (
                                index &&
                                ticket.notes[index - 1].note_index === null
                              ) {
                                ticket.noteInProgress = false;
                                ticket.notes.pop();
                                this.props.caisse_redirection("home");
                              }
                              this.props.updateCurrentTicket(ticket);
                            }
                          }}
                        >
                          Note
                        </span>
                      ) : (
                        // <Image
                        //   className={'img-division no-opacity'}
                        //   src={require('../../../assets/division.svg')}
                        // />
                        //
                        <span className={"note-btn  no-opacity"}>Note</span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            {this.renderArticleToTicket()}
            {this.renderListNote()}
          </div>
          {/*********************************Ticket Footer******************************************/}
          <div className="ticket-footer">
            <div className="ticket-grey-block">
              <span>{i18n.t("total_ttc")}</span>
              <span className="ticket-grey-block-price">
                {show_note ? (
                  <>
                    {currentNote &&
                      parseFloat(
                        getAmountNote(
                          this.props.currentTicket,
                          this.props.currentNote
                        )
                      ).toFixed(2)}
                    {localStorage.getItem("currency")}
                  </>
                ) : (
                  <>
                    {this.props.currentTicket &&
                      Object.entries(this.props.currentTicket.articles)
                        .length &&
                      parseFloat(this.props.currentTicket.amount).toFixed(2)}
                    {localStorage.getItem("currency")}
                  </>
                )}
              </span>
            </div>
            {this.props.currentTicket &&
            (Object.entries(this.props.currentTicket.payments.types).length ||
              Object.entries(this.props.currentTicket.discountTypes.discounts)
                .length) ? (
              <div>
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <div className="payment-action-list">
                        {this.props.currentTicket.payments.types.length ? (
                          <>
                            <span>
                              <b>
                                {i18n.t("payment")}{" "}
                                {this.props.currentNote
                                  ? "- Note " +
                                    (getCurrentNoteIndex(
                                      this.props.currentTicket,
                                      this.props.currentNote
                                    ) +
                                      1)
                                  : ""}
                              </b>
                            </span>
                            <br />
                            {this.renderPaymentAction()}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column width={1}>
                      <div className="payment-remainder-label">
                        {(!this.props.currentNote &&
                          parseFloat(this.props.currentTicket.discount) > 0) ||
                        getNoteDiscount(
                          this.props.currentTicket,
                          this.props.currentNote
                        ) > 0 ? (
                          <>
                            <span>
                              <b>{i18n.t("discount")}</b>
                            </span>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {this.props.currentTicket.credits &&
                        this.props.currentTicket.credits.length ? (
                          <span>
                            <b>{i18n.t("avoir")}</b>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <div className="payment-remainder">
                        <span>
                          {currentNote &&
                          parseFloat(
                            getNoteDiscount(
                              this.props.currentTicket,
                              this.props.currentNote
                            )
                          ) > 0
                            ? parseFloat(
                                getNoteDiscount(
                                  this.props.currentTicket,
                                  this.props.currentNote
                                )
                              ).toFixed(2) +
                              " " +
                              localStorage.getItem("currency")
                            : parseFloat(this.props.currentTicket.discount) >
                                0 && !currentNote
                            ? parseFloat(
                                this.props.currentTicket.discount
                              ).toFixed(2) +
                              " " +
                              localStorage.getItem("currency")
                            : ""}
                        </span>
                        <br />
                        {this.props.currentTicket.credits &&
                        this.props.currentTicket.credits.length
                          ? this.renderTicketCreditList()
                          : ""}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <div className="ticket-grey-block">
                  <span>{i18n.t("rest_to_pay")}</span>
                  <span className="ticket-grey-block-price">
                    {show_note
                      ? currentNote &&
                        (
                          parseFloat(
                            getAmountNote(
                              this.props.currentTicket,
                              this.props.currentNote
                            )
                          ) -
                          parseFloat(
                            getNoteDiscount(
                              this.props.currentTicket,
                              this.props.currentNote
                            )
                          ) -
                          parseFloat(
                            getPayedAmountNote(
                              this.props.currentTicket,
                              this.props.currentNote
                            )
                          )
                        ).toFixed(2) + localStorage.getItem("currency")
                      : this.props.currentTicket &&
                        (
                          parseFloat(this.props.currentTicket.amount) -
                          (parseFloat(
                            cumulPayedAmountFromPaymentTypes(
                              this.props.currentTicket
                            )
                          ) +
                            parseFloat(this.props.currentTicket.discount))
                        ).toFixed(2) +
                          " " +
                          localStorage.getItem("currency")}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            {/******************** Button for ticket **********************************/}
            <div className="ticket-menu">
              {/* ticket menu for advance && reload */}
              {this.props.currentTicket &&
                this.props.currentTicket.articles[0] &&
                (this.props.currentTicket.articles[0].parent.is_advance === 1 ||
                  this.props.currentTicket.articles[0].parent.is_reload ===
                    1) && (
                  <div>
                    {/*details*/}
                    {this.props.currentTicket.status === 0 &&
                    this.props.caisseRedirection.value === "ticket details" ? (
                      <>
                        {this.props.currentTicket.articles[0].parent
                          .is_reload === 1 && (
                          <Button
                            className="details-btn purple-details-btn"
                            content={i18n.t("invoice")}
                            onClick={() => {
                              this.setState({ factureModalOpen: true });
                            }}
                          />
                        )}
                        {this.props.currentTicket.articles[0].parent
                          .is_advance === 1 && (
                          <Button
                            className="details-btn purple-details-btn"
                            content={i18n.t("transfer")}
                            disabled={
                              parseFloat(this.props.currentTicket.amount) ===
                              parseFloat(this.props.currentTicket.payed_amount)
                            }
                            onClick={() =>
                              this.setState({
                                confirmCreditTransferModalOpen: true,
                              })
                            }
                          />
                        )}
                        {!(
                          this.props.currentTicket.articles[0].parent
                            .is_advance === 1 &&
                          parseFloat(
                            this.props.currentTicket.articles[0].payed_amount
                          ) !== 0
                        ) && (
                          <Button
                            className="details-btn orange-details-btn"
                            content={i18n.t("edit")}
                            onClick={() => {
                              let checkedArticleIdList = this.state
                                .checkedArticleIdList;
                              checkedArticleIdList.push(
                                this.props.currentTicket.articles[0].id
                              );
                              this.setState({
                                editPaymentModalOpen: true,
                                checkedArticleIdList: checkedArticleIdList,
                              });
                            }}
                          />
                        )}
                        {this.props.currentTicket.articles[0].parent
                          .is_advance === 1 &&
                          parseFloat(
                            this.props.currentTicket.articles[0].payed_amount
                          ) === 0 && (
                            <Button
                              className="details-btn red-details-btn"
                              content={i18n.t("cancel")}
                              onClick={() => {
                                this.setState({ cancelTicketModalOpen: true });
                              }}
                            />
                          )}
                        <Button
                          className="details-btn green-details-btn"
                          content={i18n.t("printing")}
                          onClick={() => {
                            let receipt = null;
                            if (
                              this.props.currentTicket.articles[0].parent
                                .is_advance === 1
                            ) {
                              receipt = advancePrint(
                                this.props.currentTicket,
                                this.props.paymentTypes
                              );
                            }
                            if (
                              this.props.currentTicket.articles[0].child
                                .is_card === 1
                            ) {
                              receipt = reloadPrint(this.props.currentTicket);
                            }
                            if (
                              this.props.currentTicket.articles[0].child
                                .is_subscription === 1
                            ) {
                              receipt = subscriptionPrint(
                                this.props.currentTicket
                              );
                            }
                            PrintPopup(receipt);
                          }}
                        />
                        {this.props.currentTicket.articles[0].child.is_card ===
                          1 && (
                          <Button
                            className="details-btn red-details-btn"
                            content={i18n.t("avoir")}
                            onClick={() =>
                              this.setState({
                                confirmCreditModalOpen: true,
                                cancledMethod: "reload",
                              })
                            }
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {this.renderDefaultPaymentButton()}
                        <TicketMenuItem
                          name={i18n.t("other")}
                          type="1"
                          onClick={() => {
                            if (
                              this.props.currentTicket &&
                              this.props.currentTicket.articles[0] &&
                              this.props.currentTicket.articles[0].parent
                                .is_advance === 1 &&
                              !this.state.user
                            ) {
                              this.setState({
                                userAdvanceModalOpen: true,
                                userAdvanceCallback: () => {
                                  this.props.article_to_pay(
                                    {
                                      [this.props.currentTicket.articles[0]
                                        .id]: [],
                                    },
                                    this.props.currentTicket.articles[0]
                                      .total_price,
                                    parseFloat(
                                      this.props.currentTicket.articles[0]
                                        .total_price
                                    ) -
                                      parseFloat(
                                        this.props.currentTicket.articles[0]
                                          .payed_amount
                                      )
                                  );
                                  this.props.caisseRedirection.value !==
                                    "discount" &&
                                    Object.entries(this.props.articleToPay)
                                      .length &&
                                    this.props.caisse_redirection("payment");
                                },
                              });
                            } else if (
                              this.props.currentTicket &&
                              this.props.currentTicket.articles[0] &&
                              this.props.currentTicket.articles[0].parent
                                .is_reload === 1 &&
                              // this.props.currentTicket.articles[0].parent.is_reload === 1 &&
                              !this.state.user
                            ) {
                              this.setState({
                                userReloadModalOpen: true,
                                userReloadCallback: () => {
                                  this.props.article_to_pay(
                                    {
                                      [this.props.currentTicket.articles[0]
                                        .id]: [],
                                    },
                                    this.props.currentTicket.articles[0]
                                      .total_price,
                                    parseFloat(
                                      this.props.currentTicket.articles[0]
                                        .total_price
                                    ) -
                                      parseFloat(
                                        this.props.currentTicket.articles[0]
                                          .payed_amount
                                      )
                                  );
                                  this.props.caisseRedirection.value !==
                                    "discount" &&
                                    Object.entries(this.props.articleToPay)
                                      .length &&
                                    this.props.caisse_redirection("payment");
                                },
                              });
                            } else {
                              this.props.caisseRedirection.value !==
                                "discount" &&
                                Object.entries(this.props.articleToPay)
                                  .length &&
                                this.props.caisse_redirection("payment");
                            }
                          }}
                          disabled={this.props.appLoading}
                        />
                        <Button
                          className={
                            ((this.props.currentTicket &&
                              this.props.currentTicket.articles[0] &&
                              this.props.currentTicket.articles[0].parent
                                .is_advance === 1) ||
                              (this.props.currentTicket &&
                                this.props.currentTicket.articles[0] &&
                                this.props.currentTicket.articles[0].parent
                                  .is_reload === 1)) &&
                            Object.entries(
                              this.props.currentTicket.payments.types
                            ).length &&
                            !this.props.appLoading &&
                            !this.state.paymentLoader &&
                            this.props.caisseRedirection.value !== "payment"
                              ? "ticket-menu-item ticket-menu-cash-on"
                              : "ticket-menu-item ticket-menu-cash-off"
                          }
                          onClick={async () => {
                            if (
                              !this.props.appLoading &&
                              !this.state.paymentLoader
                            ) {
                              if (
                                this.props.currentTicket &&
                                this.props.currentTicket.articles[0] &&
                                this.props.currentTicket.articles[0].parent
                                  .is_advance === 1 &&
                                !this.state.user
                              ) {
                                warningToast("Aucun client est sélectionné");
                              } else {
                                if (
                                  Object.entries(
                                    this.props.currentTicket.payments.types
                                  ).length
                                ) {
                                  if (
                                    this.props.currentTicket.articles[0].parent
                                      .is_advance === 1
                                  ) {
                                    await this.createAdvance();
                                  } else if (
                                    this.props.currentTicket.articles[0].parent
                                      .is_reload === 1
                                  ) {
                                    await this.concludeTicketPayment();
                                  }
                                }
                              }
                            }
                          }}
                          disabled={
                            this.props.appLoading || this.state.paymentLoader
                          }
                        >
                          <span>
                            {this.props.currentTicket.articles[0].parent
                              .is_reload === 1
                              ? i18n.t("validate")
                              : "Acompte"}
                          </span>
                          <Image
                            className="ticket-menu-item-img"
                            src={require("../../../assets/remainder.svg")}
                          />
                        </Button>
                        {this.props.currentTicket &&
                          this.props.currentTicket.id &&
                          this.props.currentTicket.is_advance === 1 &&
                          this.props.currentTicket.status === 1 && (
                            <Button
                              className="ticket-menu-item ticket-menu-cash-on"
                              onClick={() => {
                                let receipt = null;
                                if (
                                  this.props.currentTicket.articles[0].parent
                                    .is_advance === 1
                                ) {
                                  receipt = advancePrint(
                                    this.props.currentTicket,
                                    this.props.paymentTypes
                                  );
                                }
                                if (
                                  this.props.currentTicket.articles[0].child
                                    .is_card === 1
                                ) {
                                  receipt = reloadPrint(
                                    this.props.currentTicket
                                  );
                                }
                                if (
                                  this.props.currentTicket.articles[0].child
                                    .is_subscription === 1
                                ) {
                                  receipt = subscriptionPrint(
                                    this.props.currentTicket
                                  );
                                }
                                PrintPopup(receipt);
                              }}
                            >
                              <span>{i18n.t("printing")}</span>
                              <Image
                                className="ticket-menu-item-img"
                                src={require("../../../assets/print.svg")}
                              />
                            </Button>
                          )}
                      </>
                    )}
                  </div>
                )}
              {/* menu for ticket*/}
              {this.props.currentTicket &&
                this.props.currentTicket.articles[0] &&
                this.props.currentTicket.articles[0].parent.is_advance === 0 &&
                this.props.currentTicket.articles[0].parent.is_credit === 0 &&
                this.props.currentTicket.articles[0].parent.is_reload === 0 && (
                  <>
                    {this.props.caisseRedirection.value === "ticket details" &&
                    this.props.currentTicket &&
                    this.props.currentTicket.status !== 2 ? (
                      // menu for ticket details
                      <div>
                        {!show_note && (
                          <>
                            <Button
                              className="details-btn purple-details-btn"
                              content={i18n.t("invoice")}
                              onClick={() => {
                                this.setState({ factureModalOpen: true });
                              }}
                            />
                            {this.props.currentTicket &&
                            this.props.currentTicket.hasOwnProperty(
                              "show_btn"
                            ) ? (
                              <>
                                {this.props.currentTicket.show_btn &&
                                ((this.props.currentTicket.payments.types &&
                                  Object.entries(
                                    this.props.currentTicket.payments.types
                                  ).length) ||
                                  parseFloat(
                                    this.props.currentTicket.discount
                                  ) ===
                                    parseFloat(
                                      this.props.currentTicket.amount
                                    )) ? ( // 100% discount case
                                  <>
                                    <Button
                                      className="details-btn orange-details-btn"
                                      content={i18n.t("avoir")}
                                      onClick={() => {
                                        if (
                                          this.state.checkedArticleIdList.length
                                        ) {
                                          //check payment of article (payment / discount 100%)
                                          let totalPayment = 0;
                                          this.state.checkedArticleIdList.forEach(
                                            (element) => {
                                              this.props.currentTicket.payments.articles.map(
                                                (itm, i) => {
                                                  if (
                                                    itm.article_id.toString() ===
                                                    element.toString()
                                                  ) {
                                                    totalPayment += parseFloat(
                                                      itm.amount
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                          );
                                          //chek if payment of article totally payed by avoir/classic subscriber
                                          if (
                                            totallyPayedByAvoirOrAdvance(
                                              this.props.currentTicket,
                                              this.state.checkedArticleIdList
                                            )
                                          ) {
                                            this.setState({
                                              totalAvoirOrSubscription: true,
                                            });
                                          }
                                          if (totalPayment === 0) {
                                            this.setState({
                                              confirmCreditModalOpen: true,
                                              cancledMethod: "article",
                                              totalDiscount: true,
                                            });
                                          } else {
                                            this.setState({
                                              confirmCreditModalOpen: true,
                                              cancledMethod: "article",
                                            });
                                          }
                                        } else {
                                          warningToast(
                                            "Aucun article est sélectionné"
                                          );
                                        }
                                      }}
                                    />
                                    <Button
                                      className="details-btn green-details-btn"
                                      content={i18n.t("edit-ticket")}
                                      disabled={
                                        parseFloat(
                                          this.props.currentTicket.discount
                                        ) ===
                                        parseFloat(
                                          this.props.currentTicket.amount
                                        )
                                      }
                                      onClick={() => {
                                        if (
                                          this.state.checkedArticleIdList.length
                                        ) {
                                          if (
                                            this.state.checkedArticleIdList
                                              .length === 1
                                          ) {
                                            this.setState({
                                              editPaymentModalOpen: true,
                                            });
                                          } else {
                                            warningToast(
                                              "Sélectionnez un seul article svp!"
                                            );
                                          }
                                        } else {
                                          warningToast(
                                            "Aucun article est selectionné"
                                          );
                                        }
                                      }}
                                    />
                                    <Button
                                      className="details-btn red-details-btn"
                                      content={i18n.t("cancel-ticket")}
                                      onClick={() => {
                                        if (
                                          parseFloat(
                                            this.props.currentTicket.discount
                                          ) ===
                                          parseFloat(
                                            this.props.currentTicket.amount
                                          )
                                        ) {
                                          this.setState({
                                            totalDiscount: true,
                                          });
                                        }
                                        if (
                                          totallyPayedByAvoirOrAdvance(
                                            this.props.currentTicket
                                          )
                                        ) {
                                          this.setState({
                                            totalAvoirOrSubscription: true,
                                          });
                                        }
                                        this.setState({
                                          cancelTicketModalOpen: true,
                                        });
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                {this.props.currentTicket.date ===
                                  moment(new Date()).format("DD-MM-YYYY") &&
                                this.props.currentTicket.payments.types &&
                                Object.entries(
                                  this.props.currentTicket.payments.types
                                ).length ? (
                                  <>
                                    <Button
                                      className="details-btn orange-details-btn"
                                      content={i18n.t("avoir")}
                                      onClick={() => {
                                        if (
                                          this.state.checkedArticleIdList.length
                                        ) {
                                          this.setState({
                                            cancelArticlesModalOpen: true,
                                          });
                                        } else {
                                          warningToast(
                                            "Aucun article est selectionné"
                                          );
                                        }
                                      }}
                                    />
                                    <Button
                                      className="details-btn green-details-btn"
                                      content={i18n.t("edit-ticket")}
                                      onClick={() => {
                                        if (
                                          this.state.checkedArticleIdList.length
                                        ) {
                                          if (
                                            this.state.checkedArticleIdList
                                              .length === 1
                                          ) {
                                            this.setState({
                                              editPaymentModalOpen: true,
                                            });
                                          } else {
                                            warningToast(
                                              "Sélectionnez un seul article svp!"
                                            );
                                          }
                                        } else {
                                          warningToast(
                                            "Aucun article est selectionné"
                                          );
                                        }
                                      }}
                                    />
                                    <Button
                                      className="details-btn red-details-btn"
                                      content={i18n.t("cancel-ticket")}
                                      onClick={() => {
                                        this.setState({
                                          cancelTicketModalOpen: true,
                                        });
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      // ticket menu for ticket payment
                      <>
                        {this.props.currentTicket &&
                          this.props.currentTicket.status !== 2 && (
                            <>
                              {this.renderDefaultPaymentButton()}
                              <TicketMenuItem
                                name={i18n.t("other")}
                                type="1"
                                onClick={() => {
                                  this.props.caisseRedirection.value !==
                                    "discount" &&
                                    Object.entries(this.props.articleToPay)
                                      .length &&
                                    this.props.caisse_redirection("payment");
                                }}
                                disabled={this.props.appLoading}
                              />
                              {this.props.currentTicket &&
                                this.props.currentTicket.status !== 0 && (
                                  <Button
                                    className="ticket-menu-item btn-white"
                                    disabled={this.disabilityOfDiscountBtn()}
                                    onClick={() =>
                                      this.props.caisse_redirection("discount")
                                    }
                                  >
                                    <span>{i18n.t("discount")}</span>
                                    <Image
                                      className="ticket-menu-item-img"
                                      src={require("../../../assets/remise.svg")}
                                    />
                                  </Button>
                                )}
                              {this.props.currentTicket &&
                                this.props.currentTicket.status !== 0 && (
                                  <TicketMenuItem
                                    name={i18n.t("pending")}
                                    image="pending.svg"
                                    type="2"
                                    onClick={() => this.putTicketOnPending()}
                                    disabled={
                                      this.props.appLoading ||
                                      this.props.caisseRedirection.value ===
                                        "payment"
                                    }
                                  />
                                )}
                              {!show_note ? (
                                <Button
                                  className={
                                    this.props.currentTicket &&
                                    parseFloat(
                                      this.props.currentTicket.amount
                                    ) !== 0 &&
                                    add(
                                      parseFloat(
                                        this.props.currentTicket.payed_amount
                                      ),
                                      parseFloat(
                                        this.props.currentTicket.discount
                                      )
                                    ) >=
                                      parseFloat(
                                        this.props.currentTicket.amount
                                      ) &&
                                    this.props.caisseRedirection.value !==
                                      "payment"
                                      ? "ticket-menu-item ticket-menu-cash-on"
                                      : "ticket-menu-item ticket-menu-cash-off"
                                  }
                                  onClick={() => {
                                    this.props.currentTicket &&
                                      add(
                                        parseFloat(
                                          this.props.currentTicket.payed_amount
                                        ),
                                        parseFloat(
                                          this.props.currentTicket.discount
                                        )
                                      ) >=
                                        parseFloat(
                                          this.props.currentTicket.amount
                                        ) &&
                                      parseFloat(
                                        this.props.currentTicket.amount
                                      ) !== 0 &&
                                      this.props.caisseRedirection.value !==
                                        "payment" &&
                                      this.concludeTicketPayment();
                                  }}
                                  disabled={this.props.appLoading}
                                >
                                  {this.props.currentTicket &&
                                  this.props.currentTicket.status !== 0
                                    ? i18n.t("cashed")
                                    : i18n.t("validate")}
                                  {this.props.currentTicket &&
                                  this.props.currentTicket.status !== 0 ? (
                                    <Image
                                      className="ticket-menu-item-img"
                                      src={require("../../../assets/cash_white.svg")}
                                    />
                                  ) : (
                                    <Image
                                      className="ticket-menu-item-img"
                                      src={require("../../../assets/check.svg")}
                                    />
                                  )}
                                </Button>
                              ) : (
                                <Button
                                  className="ticket-menu-item ticket-menu-cash-on"
                                  onClick={async () => {
                                    this.setState({
                                      printTicketModalOpen: true,
                                    });
                                  }}
                                >
                                  <span>{i18n.t("printing")}</span>
                                  <Image
                                    className="ticket-menu-item-img"
                                    src={require("../../../assets/print.svg")}
                                  />
                                </Button>
                              )}
                            </>
                          )}
                      </>
                    )}
                  </>
                )}
            </div>
            {/*************************************************************************/}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchPlayer: (page, size, search) =>
    dispatch({ type: "SEARCH_REQUEST_USER", payload: { page, size, search } }),
  select_family: (value) =>
    dispatch({ type: "SELECT_FAMILY_REQUEST", payload: value }),
  select_sub_family: (value) =>
    dispatch({ type: "SELECT_SUB_FAMILY_REQUEST", payload: value }),
  select_hour_type: (value) =>
    dispatch({ type: "SELECT_HOUR_TYPE_REQUEST", payload: value }),
  updateCurrentTicket: (data) =>
    dispatch({ type: "UPDATE_CURRENT_TICKET_REQUEST", payload: data }),
  article_to_pay: (article, amount, rest) =>
    dispatch({
      type: "ARTICLE_TO_PAY_REQUEST",
      payload: { article, amount, rest },
    }),
  caisse_redirection: (value, status = null, type = null, date = null) =>
    dispatch({
      type: "CAISSE_REDIRECTION_REQUEST",
      payload: { value, status, type, date },
    }),
  storeTicket: (data) =>
    dispatch({ type: "STORE_TICKET_REQUEST", payload: data }),
  updateTicket: (data) =>
    dispatch({ type: "UPDATE_TICKET_REQUEST", payload: data }),
  destroyTicket: (id) =>
    dispatch({ type: "DESTROY_TICKET_REQUEST", payload: id }),
  setValueReservationTicket: (data) => dispatch({ type: "SET_VALUES", data }),
  article_to_edit_payment: (value) =>
    dispatch({ type: "ARTICLE_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  participant_to_edit_payment: (value) =>
    dispatch({ type: "PARTICIPANT_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  storeInvoice: (value) =>
    dispatch({ type: "STORE_INVOICE_REQUEST", payload: value }),
  decrement_occ_nb: (value) =>
    dispatch({ type: "DECREMENT_OCC_NB_REQUEST", payload: value }),
  restored_credit_list: (data) =>
    dispatch({ type: "RESTORED_CREDIT_LIST_REQUEST", payload: data }),
  getTickets: (data) =>
    dispatch({ type: "GET_TICKETS_REQUEST", payload: { ...data } }),
  updateReservation: (data) =>
    dispatch(reservationCreators.updateRequestReservation(data)),
  destoryReservation: (payload) =>
    dispatch({ type: "DESTROY_REQUEST_RESERVATION", payload }),
  storeCreditDetails: (data) =>
    dispatch({ type: "STORE_REQUEST_CREDIT", payload: data }),
  article_to_partial_payment: (value) =>
    dispatch({ type: "ARTICLE_TO_PARTIAL_PAYMENT_REQUEST", payload: value }),
  participant_to_partial_payment: (value) =>
    dispatch({
      type: "PARTICIPANT_TO_PARTIAL_PAYMENT_REQUEST",
      payload: value,
    }),
  getTicketInfo: () =>
    dispatch({ type: "INDEX_REQUEST_TICKET_INFO", payload: {} }),
  updateNoteInProgress: (payload) =>
    dispatch({ type: "NOTE_IN_PROGRESS_RESPONSE", payload }),
  updateCurrentNote: (id) =>
    dispatch({ type: "CURRENT_NOTE_REQUEST", payload: id }),
  cancelReload: (id, data) =>
    dispatch({
      type: "CANCEL_RELOAD_REQUEST_SUBSCRIBER",
      payload: { id, data },
    }),
});

const mapStateToProps = (state) => {
  let paymentTypes = state.paymentMethod.paymentMethods;
  paymentTypes.sort(function (a, b) {
    return a.id - b.id;
  });
  return {
    user: state.auth.user,
    users: state.user.users,
    page: state.user.page,
    size: state.user.size,
    last: state.user.last,
    loading: state.user.loading,
    paymentTypes: paymentTypes,
    currentTicket: state.caisse.currentTicket,
    pendingTicket: state.caisse.pendingTicket,
    articleToPay: state.caisse.articleToPay,
    caisseRedirection: state.caisse.caisse_redirection,
    reservationTicket: state.caisse.reservationTicket,
    duplicateTicket: state.caisse.duplicateTicket,
    articleToEditPayment: state.caisse.articleToEditPayment,
    participantToEditPayment: state.caisse.participantToEditPayment,
    articleToPartialPayment: state.caisse.articleToPartialPayment,
    participantToPartialPayment: state.caisse.participantToPartialPayment,
    successTicketStore: state.caisse.successTicketStore,
    successTicketUpdate: state.caisse.successTicketUpdate,
    loadingTicketById: state.caisse.loadingTicketById,
    appLoading: state.caisse.appLoading,
    menusData: state.caisse.menusData,
    ticketInfos: state.ticketInfo.ticketInfos,
    currentNote: state.caisse.currentNote,
    amountToPay: state.caisse.amountToPay,
    complex: state.auth.selected,
    session: state.session.session,
    user: state.auth.user,
    cancelReloadLoading: state.subscriberDetails.cancelReloadLoading,
    successCancelReload: state.subscriberDetails.successCancelReload,
    credit: state.subscriberDetails.credit,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ticket));
