import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import ticketInfoService from '../../services/ticketInfoService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingTicketInfo: ['data'],

  indexRequestTicketInfo: ['payload'],
  indexResponseTicketInfo: ['data'],

  storeRequestTicketInfo: ['payload'],
  storeResponseTicketInfo: ['data'],

  editRequestTicketInfo: ['id'],
  editResponseTicketInfo: ['data'],

  updateRequestTicketInfo: ['payload'],
  updateResponseTicketInfo: ['data'],

  destroyRequestTicketInfo: ['id'],
  destroyResponseTicketInfo: ['data'],
});

// Initial state
const INITIAL_STATE = {
  ticketInfos: [],
  ticketInfo: null,
  loading: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingTicketInfo({ loading: true ,ticketInfos: []}));
    const data = yield call(ticketInfoService.ticketInfoService.index, action.payload);
    yield put(Creators.indexResponseTicketInfo(data));
    yield put(Creators.loadingTicketInfo({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingTicketInfo({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingTicketInfo({ loading: true }));
    const data = yield call(ticketInfoService.ticketInfoService.store, action.payload);
    yield put(Creators.storeResponseTicketInfo(data));
    yield put(Creators.loadingTicketInfo({ loading: false }));
    yield history.push('/ticketInfos');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingTicketInfo({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingTicketInfo({ editLoading: true }));
    const data = yield call(ticketInfoService.ticketInfoService.edit, action.payload);
    yield put(Creators.editResponseTicketInfo(data));
    yield put(Creators.loadingTicketInfo({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingTicketInfo({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingTicketInfo({ loading: true }));
    const data = yield call(ticketInfoService.ticketInfoService.update, action.payload);
    yield put(Creators.updateResponseTicketInfo(data));
    yield put(Creators.loadingTicketInfo({ loading: false }));
    yield history.push('/ticketInfos');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(
      Creators.loadingTicketInfo({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingTicketInfo({ deleteLoading: true }));
    const data = yield call(ticketInfoService.ticketInfoService.destroy, action.payload);
    yield put(Creators.destroyResponseTicketInfo(data));
    yield put(Creators.loadingTicketInfo({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_TICKET_INFO, index);
  yield takeLatest(Types.STORE_REQUEST_TICKET_INFO, store);
  yield takeLatest(Types.EDIT_REQUEST_TICKET_INFO, edit);
  yield takeLatest(Types.UPDATE_REQUEST_TICKET_INFO, update);
  yield takeLatest(Types.DESTROY_REQUEST_TICKET_INFO, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ticketInfos: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ticketInfo: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const ticketInfoReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_TICKET_INFO]: index_response,
  [Types.STORE_RESPONSE_TICKET_INFO]: store_response,
  [Types.EDIT_RESPONSE_TICKET_INFO]: edit_response,
  [Types.UPDATE_RESPONSE_TICKET_INFO]: update_response,
  [Types.DESTROY_REQUEST_TICKET_INFO]: destroy_request,
  [Types.DESTROY_RESPONSE_TICKET_INFO]: destroy_response,
  [Types.LOADING_TICKET_INFO]: loading,
});
