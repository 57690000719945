import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import planningLeisureTypeService from '../../services/planningLeisureTypeService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingPlanningLeisureType: ['data'],

  indexRequestPlanningLeisureType: ['payload'],
  indexResponsePlanningLeisureType: ['data'],

  storeRequestPlanningLeisureType: ['payload'],
  storeResponsePlanningLeisureType: ['data'],

  editRequestPlanningLeisureType: ['id'],
  editResponsePlanningLeisureType: ['data'],

  updateRequestPlanningLeisureType: ['payload'],
  updateResponsePlanningLeisureType: ['data'],

  destroyRequestPlanningLeisureType: ['id'],
  destroyResponsePlanningLeisureType: ['data'],
});

// Initial state
const INITIAL_STATE = {
  planningLeisureTypes: [],
  planningLeisureType: null,
  loading: false,
  errors: null,
  size: 10,
  page: 0,
  last: 0,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingPlanningLeisureType({ loading: true, planningLeisureTypes: [] }));
    const data = yield call(planningLeisureTypeService.planningLeisureTypeService.index, action.payload);
    yield put(Creators.indexResponsePlanningLeisureType(data));
    yield put(Creators.loadingPlanningLeisureType({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingPlanningLeisureType({ loading: true }));
    const data = yield call(planningLeisureTypeService.planningLeisureTypeService.store, action.payload);
    yield put(Creators.storeResponsePlanningLeisureType(data));
    yield put(Creators.loadingPlanningLeisureType({ loading: false }));
    yield history.push('/configuration/planningLeisureTypes');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingPlanningLeisureType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingPlanningLeisureType({ editLoading: true }));
    const data = yield call(planningLeisureTypeService.planningLeisureTypeService.edit, action.payload);
    yield put(Creators.editResponsePlanningLeisureType(data));
    yield put(Creators.loadingPlanningLeisureType({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingPlanningLeisureType({ editLoading: false }));
    history.push('/error-404');
  }
}

// //update
function* update(action) {
  try {
    yield put(Creators.loadingPlanningLeisureType({ loading: true }));
    const data = yield call(planningLeisureTypeService.planningLeisureTypeService.update, action.payload);
    yield put(Creators.updateResponsePlanningLeisureType(data));
    yield put(Creators.loadingPlanningLeisureType({ loading: false }));
    yield history.push('/configuration/planningLeisureTypes');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(
      Creators.loadingPlanningLeisureType({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

// //destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingPlanningLeisureType({ deleteLoading: true }));
    const data = yield call(planningLeisureTypeService.planningLeisureTypeService.destroy, action.payload);
    yield put(Creators.destroyResponsePlanningLeisureType(data));
    yield put(Creators.loadingPlanningLeisureType({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_PLANNING_LEISURE_TYPE, index);
  yield takeLatest(Types.STORE_REQUEST_PLANNING_LEISURE_TYPE, store);
  yield takeLatest(Types.EDIT_REQUEST_PLANNING_LEISURE_TYPE, edit);
  yield takeLatest(Types.UPDATE_REQUEST_PLANNING_LEISURE_TYPE, update);
  yield takeLatest(Types.DESTROY_REQUEST_PLANNING_LEISURE_TYPE, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    planningLeisureTypes: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    planningLeisureType: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

// Reducer
export const planningLeisureTypeReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_PLANNING_LEISURE_TYPE]: index_response,
  [Types.STORE_RESPONSE_PLANNING_LEISURE_TYPE]: store_response,
  [Types.EDIT_RESPONSE_PLANNING_LEISURE_TYPE]: edit_response,
  [Types.UPDATE_RESPONSE_PLANNING_LEISURE_TYPE]: update_response,
  [Types.DESTROY_REQUEST_PLANNING_LEISURE_TYPE]: destroy_request,
  [Types.DESTROY_RESPONSE_PLANNING_LEISURE_TYPE]: destroy_response,
  [Types.LOADING_PLANNING_LEISURE_TYPE]: loading,
});
