import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  resolutionService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/resolutions?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_resolution
          ? '&search_resolution=' + search.search_resolution
          : ''),
      );
      return result.data;
    },
    store: async (data) => {
      const result = await postMethod('/resolutions', data);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/resolutions/' + id);
      return result.data;
    },
    update: async (data) => {
      const result = await putMethod('/resolutions/' + data.id, data);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/resolutions/' + id);
      return result.data;
    },
  },
};
