import { useEffect, useState } from 'react';
import { errorToast, loadingToast, validToast } from '../../helpers/customToast';
import { useTranslation } from 'react-i18next';
import ExcelExport, { alignment, defaultDataType } from 'export-xlsx';
import { capitalize } from '../../helpers/formatting';

// Export settings
export const settingGenerator = (data, id) => {

  let headers = [];
  let headerDefinition = [];
  if (data.length > 0) {
    headers = Object.keys(data[0]);
  }

  if (headers.length > 0) {
    headerDefinition = headers.map(it => (
      {
        name: capitalize(it),
        key: it,
        width: 28,
      }
    ));
  }

  return ({
    // Table settings
    fileName: id,
    workSheets: [
      {
        sheetName: id,
        startingRowNumber: 1,
        gapBetweenTwoTables: 0,
        tableSettings: {
          data: {
            importable: false,
            tableTitle: null,
            notification: null,
            headerGroups: [],
            headerDefinition,
          },
        },
      },
    ],
  });
};


const GlobalExport = (props) => {
  const { t } = useTranslation();
  // object={api,formatting}
  const [queue, setQueue] = useState([]);
  const [loading, setLoading] = useState(false);

  let listenerObject = null;

  //set up event listener for export
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    listenerObject = document.addEventListener('export', (e) => {
      setQueue([...queue, e.detail]);
      loadingToast(t('add_export_queue'), {});
    });

    return document.removeEventListener('export', () => {
      setQueue([]);
    });
  }, []);

  // trigger action
  useEffect(() => {
    if (!loading && queue.length > 0) {
      setLoading(true);
      (async () => {
        try {
          let selectQueue = queue[0];
          let response = await selectQueue.api();
          //if export from front
          if(!selectQueue.fromBack){
            let data = response.data.hasOwnProperty('data') ? response.data.data : response.data;
            let filteredData = [];
            filteredData = selectQueue.formatting(data);
            const excelExport = new ExcelExport();
            let setting = settingGenerator(filteredData, selectQueue.id);
            await excelExport.downloadExcel(setting, [{ data: filteredData }]);
          }
        
          validToast(t('your_download_is_ready'), {});

          if(selectQueue.cancelLoader){
            selectQueue.cancelLoader();
          }
          setQueue(queue.splice(0));
        } catch (e) {
          errorToast(t('your_download_is_canceled'), {});
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [queue]);


  return null;
};


export default GlobalExport;