import React from 'react';
import ChildMenuItem from './ChildMenuItem';
import '../CustomCaisse.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ContentMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  renderSubFamily() {
    if (this.props.menusData) {
      let selectedFamily = this.props.menusData.filter(itm => itm.id === this.props.selectFamily);
      if (selectedFamily) {
        let subfamilyMenu = selectedFamily[0].children.map(
          (item, i) => {
            return <ChildMenuItem key={i} id={item.id} name={item.name} background={item.avatar}/>;
          },
        );
        return subfamilyMenu;
      }
    }
  }

  render() {
    return <div className={'caisse-main-menu-second'}>{this.renderSubFamily()}</div>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  select_sub_family: (value) => dispatch({ type: 'SELECT_SUB_FAMILY_REQUEST', payload: value }),
});

const mapStateToProps = (state) => {
  return {
    menusData: state.caisse.menusData,
    selectFamily: state.caisse.selectFamily,
    selectSubFamily: state.caisse.selectSubFamily,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentMenu));
