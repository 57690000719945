import React, { useCallback, useEffect, useRef, useState,useMemo } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import './CustomDropdown.css';
import OverlayComponent from '../OverlayComponent/OverlayComponent';
import { getMethod } from '../../helpers';
import i18next from 'i18next';
import { nullString } from '../../helpers/formatting';

const size = 10;
const loadingMsg = 'Chargement...';

/**
 *
 *
 <CustomDropdown
 placeholder={"Selectionner un utilisateur"}
 url={"/users"}
 value={this.state.value}
 onChange={(value)=>this.setState({value})}
 multiple={true}
 error={false}
 render={(item)=>{
        return(
            <>
                <img src={imgUrl+"/teams/45/"+item.avatar}  alt={""}/>
                <span>{item.firstName}</span>
            </>
        )
    }}
 />
 **/
const CustomDropdown = (props) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(props.multiple ? [] : null);
  const [data, setData] = useState(props.fetching ? [] : props.options);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [last, setLast] = useState(1);
  const [direction, setDirection] = useState(0); // 0 :down , 1: up

  const [minLength, setMinLength] = useState(0);

  const mainRef = useRef(null);

  useEffect(() => {
    if (props.url === '/clients/search')
      setMinLength(3);
  }, [props.url]);


  let getData = useCallback(async () => {
    try {
      if (last >= page) {
        setLoading(true);
        if (search !== '')
          await getMethod(
            props.url +
            '?complex_id=' +
            localStorage.getItem('complex_id') +
            '&page=' +
            page +
            '&size=' +
            size +
            '&' +
            props.filter +
            '=' +
            search +
            props.param,
          ).then((reponse) => {
            if (reponse.data.data) {
              if (page > 1) setData([...data, ...reponse.data.data]);
              else setData([...reponse.data.data]);
              setLast(reponse.data.last_page);
              // if(reponse.data.last_page > page )
              setPage(page + 1);
            }
            setLoading(false);
          });
        else
          await getMethod(
            props.url +
            '?complex_id=' +
            localStorage.getItem('complex_id') +
            '&page=' +
            page +
            '&size=' +
            size +
            props.param,
          ).then((reponse) => {
            if (reponse.data.data) {
              if (page > 1) setData([...data, ...reponse.data.data]);
              else setData([...reponse.data.data]);

              setLast(reponse.data.last_page);
              //if(reponse.data.last_page > page )
              setPage(page + 1);
            }
            setLoading(false);
          });
      }
    } catch (e) {
      setLoading(false);
    }
  }, [last, page, search, props.url, props.filter, props.param]);

  useEffect(() => {
    let id = props.value;
    if (id && props.options.length) {
      if (Array.isArray(id)) {
        let values = [];
        for (let i = 0; i < id.length; i++) {
          let selected = props.options.filter(
            (it) => it[props.keyword].toString() === id[i].toString(),
          );
          if (selected.length > 0) values.push(selected[0]);
        }

        setValue(values);
      } else {
        let selected = props.options.filter(
          (it) => it[props.keyword].toString() === id.toString(),
        );
        if (selected.length > 0) {
          setValue(selected[0]);
        }
      }
    }
  }, [props.value, props.options]);


  // reload data when props.options is changed
  useEffect(() => {
    if (!props.disabled)
      setData(props.options);
  }, [props.options, props.disabled]);

  const getDataById = async (id) => {
    try {
      let url = props.url;
      if (props.get !== '') url = props.get;
      setLoading(true);
      return await getMethod(url + '/' + id).then((response) => {
        setLoading(false);
        if (props.dataName)
          return response.data[props.dataName];
        else
          return response.data;
      });
    } catch (e) {
      setLoading(false);
    }
  };


  const element = useRef(null);

  // let load = useRef(getData);


  // useEffect(() => {
  //
  //
  //   observer.current = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].isIntersecting) {
  //         load.current();
  //       }
  //     },
  //     {
  //       threshold: 0.9,
  //     },
  //   );
  // }, []);

  // useEffect(() => {
  //   setPage(1);
  // }, [props.param, search]);

  useEffect(() => {
    const currentElement = element;
    const currentObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && (search.length >= minLength)) getData();
      },
      {
        threshold: 0.9,
      },
    );

    if (currentElement.current) {
      currentObserver.observe(currentElement.current);
    }
    return () => {
      if (currentElement.current) currentObserver.unobserve(currentElement.current);
    };
  }, [element, show, getData]);

  //  handle the search input
  useEffect(() => {
    if (show) {
      if (props.fetching) {
        setData([]);
        setPage(1);
        setLast(1);
      } else {
        let filterResult = props.options;
        if (search !== '')
          filterResult = props.options.filter((it) =>
            nullString(it[props.filter]).toLowerCase().includes(nullString(search).toLowerCase()),
          );
        setData(filterResult);
      }
    }
  }, [show, search, props.param, props.fetching, props.options, props.filter]);

  // useEffect(() => {
  //   load.current = getData;
  // }, [search, page, last,getData]);

  // set selected value when value is changed  for single and multiple options
  useEffect(() => {
    let id = props.value;
    if (id === '' || id === null) {
      setValue(props.multiple ? [] : null);
    } else {
      if (props.multiple) {
        let newValue = [];
        for (let i = 0; i < id.length; i++) {
          let selectedItem = data.filter((it) => it[props.keyword] === id[i]);
          if (selectedItem.length > 0) newValue = [...newValue, selectedItem[0]];
          else {
            let selectedItem = value.filter((it) => it[props.keyword] === id[i]);
            if (selectedItem.length > 0) newValue = [...newValue, selectedItem[0]];
          }
        }

        setValue([...newValue]);
      } else {
        let selectedItem = data.filter((it) => it[props.keyword] === id);
        if (selectedItem.length > 0) setValue(selectedItem[0]);
      }
    }
  }, [props.value, data, props.multiple]);

  // load data when this component is mounted
  useEffect(() => {
    let id = props.value;
    if (data && data.length && !props.refresh)
      return;

    if (props.fetching) {
      if (id) {
        if (Array.isArray(id)) {
          let values = [];
          const fetching = async () => {
            for (let i = 0; i < id.length; i++)
              await getDataById(id[i]).then((data) => {
                if (!data) return;
                props.onInitial(data);
                values.push(data);
              });
          };
          const settingUp = async () => {
            await fetching();
             //props.onInitial(data);
             setValue(values);
          };
          (async () => {
            await settingUp();
          })();
        } else {
          getDataById(id).then((data) => {
            if (!data) return;
            props.onInitial(data);
            setValue(data);
          });
        }
      }
    } else {
      if (id) {
        if (Array.isArray(id)) {
          let values = [];
          for (let i = 0; i < id.length; i++) {
            let selected = props.options.filter(
              (it) => it[props.keyword].toString() === id[i].toString(),
            );
            if (selected.length > 0) values.push(selected[0]);
          }

          props.onInitial(values);
          setValue(values);
        } else {
          let selected = props.options.filter(
            (it) => it[props.keyword].toString() === id.toString(),
          );
          if (selected.length > 0) {
            props.onInitial(selected[0]);
            setValue(selected[0]);
          }
        }
      }
    }
  }, [
    // props.url,
    // props.key,
    // props.param,
    props.value,
    // props.options,
    props.refresh,
  ]);

  useEffect(() => {
    let bodyRect = document.body.getBoundingClientRect(),
      elemRect = mainRef.current.getBoundingClientRect(),
      offset = elemRect.top - document.body.scrollHeight;

    if (show) {
      if (props.direction !== null)
        setDirection(props.direction);
      else {
        if (Math.abs(offset) >= 380) {
          setDirection(0);
        } else {
          setDirection(1);
        }
      }
    }

  }, [mainRef, show]);

  const filteredData = useMemo(() => {
    if(Array.isArray(data) && props.condition){
      return data.filter(props.condition)
    }
    return [];
  }, [data]);

  return (
    <div
      ref={mainRef}
      key={'custom-dropdown-' + props.name}
      className={
        props.className +
        ' custom-dropdown-container ' +
        (props.error ? 'error-custom-dropdown' : '') +
        (show ? ' custom-dropdown-focused' : '') +
        (props.disabled ? ' custom-dropdown-disabled' : '')
      }
    >
      <OverlayComponent onClose={() => !props.disabled && setShow(false)}>
        <div
          className={'custom-dropdown-main'}
          onClick={() => {
            if (!show) !props.disabled && setShow(true);
          }}
        >
          {value !== null ? (
            Array.isArray(value) && value.length > 0 ? (
              <div
                className={'dropdown-custom-items'}
                onClick={() => !props.disabled && setShow(true)}
              >
                {[...value].reverse().map((it, i) => {
                  return (
                    <div
                      key={'dropdown-selected-' + props.name + '-' + i}
                      className={'custom-dropdown-selected'}
                    >
                      {props.render(it)}
                      {props.value.length > 0 && !props.disabled && (
                        <Icon
                          name={'x'}
                          onClick={() => {
                            props.onChange(
                              props.value.filter((t) => t !== it[props.keyword]),
                              value.filter((t) => t[props.keyword] !== it[props.keyword]),
                            );
                            props.onSearch('');
                            setSearch('');
                          }}
                        />
                      )}
                    </div>
                  );
                })}
                {/*<input*/}
                {/*  onFocus={() => setShow(true)}*/}
                {/*  className={'custom-dropdown-input-search'}*/}
                {/*  value={search}*/}
                {/*  placeholder={props.placeholder}*/}
                {/*  onChange={(e) => {*/}
                {/*    props.onSearch(e.target.value);*/}
                {/*    setSearch(e.target.value);*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            ) : !Array.isArray(value) ? (
              <div
                key={'selected-element-' + props.name}
                className={'custom-dropdown-selected'}
                onClick={() => !props.disabled && setShow(true)}
              >
                {props.render(value)}
              </div>
            ) : (
              <input
                autoComplete={'off'}
                disabled={props.disabled}
                onFocus={() => setShow(true)}
                className={'custom-dropdown-input'}
                value={search}
                placeholder={loading ? props.loadingMsg : props.placeholder}
                onChange={(e) => {
                  props.onSearch(e.target.value);
                  setSearch(e.target.value);
                }}
              />
            )
          ) : (
            <input
              disabled={props.disabled}
              onFocus={() => setShow(true)}
              className={'custom-dropdown-input'}
              value={search}
              placeholder={loading ? loadingMsg : props.placeholder}
              onChange={(e) => {
                props.onSearch(e.target.value);
                setSearch(e.target.value);
              }}
            />
          )}
          {loading || props.loading ? (
            <Loader size={'tiny'} active={true} className={'custom-dropdown-loading'}/>
          ) : (
            <div className={'custom-dropdown-control-last'}>
              {!props.disabled &&
              ((Array.isArray(props.value) && props.value.length > 0) ||
                (!Array.isArray(props.value) && props.value !== '' && props.value !== null)) && (
                <Icon
                  name={'x'}
                  onClick={() => {
                    props.onChange(props.multiple ? [] : null, props.multiple ? [] : null, value);
                    setValue(props.multiple ? [] : null);
                    setSearch('');
                    props.onSearch('');
                  }}
                />
              )}
              {props.dropdown && (
                <Icon
                  name={'angle ' + (show ? 'up' : 'down')}
                  onClick={() => !props.disabled && setShow(!show)}
                />
              )}
            </div>
          )}
        </div>
        {show && (
          <div
            className={
              'custom-dropdown-items ' + (direction ? 'custom-dropdown-up' : 'custom-dropdown-down')
            }
          >
            {filteredData.length > 0
              ? filteredData.map((item, i) => {
                if (
                  (props.multiple &&
                    !props.value.includes(item[props.keyword]) &&
                    !props.prevent.includes(item[props.keyword])) ||
                  (!props.multiple &&
                    !(props.value === item[props.keyword]) &&
                    !props.prevent.includes(item[props.keyword]))
                ) {
                  if (item && item !== undefined)
                    return (
                      <div
                        key={'dropdown-item-' + i}
                        className={
                          'custom-dropdown-item ' +
                          (props.value === item[props.keyword]
                            ? 'custom-dropdown-item-selected'
                            : '')
                        }
                        onClick={() => {
                          let el = props.multiple
                            ? [item[props.keyword], ...props.value]
                            : item[props.keyword];
                          let values = props.multiple ? [item, ...value] : item;

                          props.onChange(el, values);
                          setSearch('');
                          if (!props.multiple) !props.disabled && setShow(false);
                          props.onSearch('');
                        }}
                      >
                        {props.render(item)}
                      </div>
                    );
                  else return null;
                } else return null;
              })
              : !loading &&
              <p className={'custom-dropdown-not-found'}>{
                (minLength > 0 && search.length < minLength) ?
                  i18next.t('search_min_three')
                  :
                  props.noData}
              </p>
            }

            {props.fetching && last >= page && (
              <div ref={element} className={'custom-dropdown-loading'}>
                {loading && (
                  <Loader inverted={true} className={'custom-dropdown-loader'} size={'small'}/>
                )}
              </div>
            )}
          </div>
        )}
      </OverlayComponent>
    </div>
  );
};

CustomDropdown.defaultProps = {
  multiple: false,
  error: false,
  dropdown: true,
  className: '',
  name: '',
  param: '',
  keyword: 'id',
  get: '',
  prevent: [],
  disabled: false,
  onSearch: () => {
  },
  onInitial: () => {
  },
  fetching: true,
  options: [],
  loading: false,
  filter: 'search',
  maxHeight: 380,
  refresh: false,
  direction: null,
  condition: (it) => true,
  loadingMsg: 'Chargement...',
  noData: 'Aucune donnée',
  dataName: 'data',
};

export default CustomDropdown;
