import React from "react";
import "../../CustomCaisse.css";
import Calculator from "./Calculator";
import PaymentInput from "./PaymentInput";
import { Button, Grid, Image, Input, Loader, Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import i18n from "@/i18n";
import {
  articleIsActivity,
  cumulArticlePayedAmountFromPaymentArticle,
  cumulParticipantPayedAmountFromPaymentParticipant,
  cumulPayedAmountFromPaymentTypes,
  cumulPaymentArticlesFromParticipant,
  cumulPaymentTypeListPartial,
  cumulPaymentTypesFromArticle,
  getAmountToPay,
  getArticleDiscount,
  getParentIdIsActivity,
  getParticipantDiscount,
  haveAdvance,
  haveNotActivitySelected,
  isSubscriptionPaymentType,
  onlyOneParticipantSelected,
  setArticleStatus,
  setArticleWithoutNoteStatus,
  setParticipantStatus,
  cumulSamePaymentParticipant
} from "@/helpers/ticket";
import { getHT } from "@/helpers/caisse/tvaCalcul";
import { debounce, imgUrl } from "@/helpers";
import { warningToast } from "@/helpers/customToast";
import ModalConfirm from "../../../ModalConfirm/ModalConfirm";
import caisseService from "../../../../services/caisseService";
import SubscriptionModal from "../../PaymentsModal/SubscriptionModal";
import CreditAccountModal from "../../PaymentsModal/CreditAccountModal";
import { Creators as SubscribersCreators } from "../../../../store/ducks/subscriber";
import { valeur } from "../../../../pages/CaissePage/CaissePage";
import { getCurrentNote } from "@/helpers/caisse/noteFormatting";
import { add,sub,toFloatNumber } from "../../../../helpers/operation";
import { cumulPayedUserIndex, onlyOneParticipantWhenMultipleArticleSelected } from "../../../../helpers/ticket";
import { nullStringNumber } from "../../../../helpers/formatting";

class PaymentContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amountToPay: 0,
      restAmountToPay: "",
      remainder: 0, //rendu
      oldPaymentArticle: [],
      totalAmount: 0,
      payment_type_list: [],
      paymentModalOpen: false,
      returnArticleModalOpen: false,
      credit_number: "",
      credit_value: "",
      credit_id: null,
      payWithCreditModalOpen: false,
      advance_number: "",
      advance_value: "",
      advance_id: null,
      payWithAdvanceModalOpen: false,
      credit_amount: null,
      confirmCreditModalOpen: false,
      inputIndex: null,
      // subscriberDetailsToDelete: [],
      showSubscriptionModal: false,
      subscriptionModalOption: "subscription",
      inputSubscriptionIndex: 0,
      showCreditAccountModal: false,
      inputCreditAccountIndex: 0,
      showAdvanceModal: false,
      inputAdvanceIndex: 0,
      // creditDetailsToDelete: [],
      disabledRestorePaymentTypeList: false,
      player: null
    };
    //this.handleChange = this.handleChange.bind(this);
    this.handleChangeFloat = this.handleChangeFloat.bind(this);
  }

  updatePaymentTypeListArticle() {
    let payedAmount = 0;
    let note_index = this.props.currentNote;
    let is_activity = articleIsActivity(this.props.currentTicket, this.props.articleToEditPayment);
    this.props.currentTicket.payments.articles.map((itm, i) => {
      if (itm.article_id.toString() === this.props.articleToEditPayment.toString() && itm.note_index === note_index) {
        payedAmount = parseFloat(payedAmount) + parseFloat(itm.amount);
        note_index = itm.note_index;
      }
    });
    let payment_type_list = [];

    for (let paymentType of this.props.paymentTypes) {
      let article_payment = this.props.currentTicket.payments.articles.filter(
        (itm) => (
          itm.article_id.toString() === this.props.articleToEditPayment.toString() &&
          itm.note_index === note_index &&
          itm.payment_type_id === paymentType.id)
      );
      if (article_payment.length) {
        article_payment.map((itm, i) => {
          payment_type_list.push({
              ...paymentType,
              amount: itm.amount > 0 ? parseFloat(itm.amount) : "",
              credit_id: itm.credit_id ? itm.credit_id : null,
              subscriber_id: itm.subscriber_id ? itm.subscriber_id : null,
              subscriber_detail_id: is_activity ? [] : itm.subscriber_detail_id,
              credit_account_id: is_activity ? [] : itm.credit_account_id,
              credit_user_id: itm.credit_user_id ? itm.credit_user_id : null,
              advance_id: itm.advance_id ? itm.advance_id : null,
              note_index: itm.note_index,
              article_id: itm.article_id,
              payed_user_index: [],
              payed_article_id : []
            }
          );
        });
      } else {
        payment_type_list.push({
            ...paymentType,
            amount: "",
            credit_id: null,
            subscriber_id: null,
            subscriber_detail_id: null,
            credit_account_id: null,
            credit_user_id: null,
            advance_id: null,
            note_index: note_index,
            article_id: null,
            payed_user_index: [],
            payed_article_id : []
          }
        );
      }
    }
    //
    if (is_activity) {
      this.props.currentTicket.payments.participants.forEach((item, i) => {
        if ((item.subscriber_id && item.subscriber_detail_id) || (item.credit_user_id && item.credit_account_id)) {
          for (let paymentType of payment_type_list) {
            if (item.subscriber_id && paymentType.subscriber_id === item.subscriber_id) {
              paymentType.subscriber_detail_id.push({ [item.user_index]: item.subscriber_detail_id });
            }
            if (item.credit_user_id && paymentType.credit_user_id === item.credit_user_id) {
              paymentType.credit_account_id.push({ [item.user_index]: item.credit_account_id });
            }

            //for activity parial payment case
            if (paymentType.id === item.payment_type_id && paymentType.amount > 0) {
              if (item.subscriber_id || item.credit_user_id || item.credit_id || item.advance_id) {
                if (item.subscriber_id && item.subscriber_detail_id && paymentType.subscriber_id === item.subscriber_id) {
                  paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                } else if (item.credit_user_id && item.credit_account_id && paymentType.credit_user_id === item.credit_user_id) {
                  paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                } else if (item.credit_id && paymentType.credit_id === item.credit_id) {
                  paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                } else if (item.advance_id && paymentType.advance_id === item.advance_id) {
                  paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                }
              } else {
                paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
              }

              //cumulate double payment with same participant index
              let grouped = cumulPayedUserIndex(paymentType.payed_user_index);
              paymentType.payed_user_index = grouped;

            }
          }
        }
      });
    }
    let totalAmount = 0;
    for (let i = 0; i < payment_type_list.length; i++) {
      if (payment_type_list[i].amount !== "" && payment_type_list[i].amount !== 0)
        totalAmount = parseFloat(totalAmount) + parseFloat(payment_type_list[i].amount);
    }
    this.setState({
      payment_type_list: payment_type_list,
      amountToPay: payedAmount,
      totalAmount: totalAmount
    });
  }

  updatePaymentTypeListParticipant() {
    let payedAmount = 0;
    let note_index = this.props.currentNote;
    this.props.currentTicket.payments.participants.map((itm, i) => {
      if (itm.user_index === this.props.participantToEditPayment) {
        payedAmount =
          parseFloat(payedAmount) + parseFloat(itm.amount) + (itm.remainder ? itm.remainder : 0);
        note_index = itm.note_index;
      }
    });

    let payment_type_list = [];
    for (let item of this.props.paymentTypes) {

      let participant_payment = this.props.currentTicket.payments.participants.filter(
        (itm) =>
          itm.user_index === this.props.participantToEditPayment &&
          itm.payment_type_id === item.id
      );

      if (participant_payment.length) {
        participant_payment.forEach((itm, i) => {
          payment_type_list.push({
            ...item,
            amount: itm.amount > 0 ? parseFloat(itm.amount) : "",
            credit_id: itm.credit_id ? itm.credit_id : null,
            subscriber_id: itm.subscriber_id ? itm.subscriber_id : null,
            subscriber_detail_id: itm.subscriber_detail_id,
            credit_account_id: itm.credit_account_id,
            credit_user_id: itm.credit_user_id ? itm.credit_user_id : null,
            advance_id: itm.advance_id ? itm.advance_id : null,
            note_index: itm.note_index,
            article_id: itm.article_id,
            payed_user_index: []
          });
        });

      } else {
        payment_type_list.push({
            ...item,
            amount: "",
            credit_id: null,
            subscriber_id: null,
            subscriber_detail_id: null,
            credit_account_id: null,
            credit_user_id: null,
            advance_id: null,
            note_index: note_index,
            article_id: null,
            payed_user_index: []
          }
        );
      }
    }

    let totalAmount = 0;
    for (let i = 0; i < payment_type_list.length; i++) {
      if (payment_type_list[i].amount !== "" && payment_type_list[i].amount !== 0)
        totalAmount = parseFloat(totalAmount) + parseFloat(payment_type_list[i].amount);
    }
    this.setState({
      payment_type_list: payment_type_list,
      amountToPay: payedAmount,
      totalAmount: totalAmount
    });
  }

  getPaymentTypeListPartialPayment() {
    let payment_type_list = [];
    //reset
    let paymentTypes = this.props.paymentTypes.map((item, index) => {
      return {
        ...item, amount: "",
        credit_id: null,
        subscriber_detail_id: null, subscriber_id: null,
        credit_account_id: null, credit_user_id: null,
        advance_id: null,
        note_index: this.props.currentNote,
        payed_user_index: [],
        payed_article_id : []
      };
    });

    //partial article case
    if (this.props.articleToPartialPayment.length) {
      this.props.articleToPartialPayment.map((article, index) => {
        let is_activity = articleIsActivity(this.props.currentTicket, article);
        paymentTypes.map((item, index) => {
          let article_payment = this.props.currentTicket.payments.articles.filter(
            (itm) => itm.article_id.toString() === article.toString() &&
              itm.note_index === this.props.currentNote &&
              itm.payment_type_id === item.id
          );
          if (article_payment.length) {
            article_payment.map((itm, i) => {
              let exists = payment_type_list.filter(it => it.id === item.id && it.amount === "").length > 0;
              if (!exists) {
                payment_type_list.push({
                    ...item,
                    amount: "",
                    credit_id: null,
                    subscriber_id: null,
                    subscriber_detail_id: null,
                    credit_account_id: null,
                    credit_user_id: null,
                    advance_id: null,
                    note_index: this.props.currentNote,
                    article_id: null,
                    payed_user_index: [],
                    payed_article_id : []
                  }
                );
              }
              payment_type_list.push({
                ...item,
                amount: (item.amount ? parseFloat(item.amount) : 0) + (itm.amount ? parseFloat(itm.amount) : 0),
                credit_id: itm.credit_id ? itm.credit_id : null,
                subscriber_id: itm.subscriber_id ? itm.subscriber_id : null,
                subscriber_detail_id: is_activity ? [] : itm.subscriber_detail_id,
                credit_account_id: is_activity ? [] : itm.credit_account_id,
                credit_user_id: itm.credit_user_id ? itm.credit_user_id : null,
                advance_id: itm.advance_id ? itm.advance_id : null,
                note_index: itm.note_index,
                article_id: itm.article_id,
                payed_user_index: [],
                payed_article_id : []
              });


            });
          } else {
            payment_type_list.push({
                ...item,
                amount: "",
                credit_id: null,
                subscriber_id: null,
                subscriber_detail_id: null,
                credit_account_id: null,
                credit_user_id: null,
                advance_id: null,
                note_index: this.props.currentNote,
                article_id: null,
                payed_user_index: [],
                payed_article_id : []
              }
            );
          }
        });

        //save old payment payment type to keep order of payment
        if (is_activity) {
          this.props.currentTicket.payments.participants.forEach((item, i) => {
            if (item.article_id.toString() === article.toString()) {
              for (let paymentType of payment_type_list) {
                if (item.subscriber_id && item.subscriber_detail_id && paymentType.subscriber_id === item.subscriber_id) {
                  paymentType.subscriber_detail_id.push({ [item.user_index]: item.subscriber_detail_id });
                }
                if (item.credit_user_id && item.credit_account_id && paymentType.credit_user_id === item.credit_user_id) {
                  paymentType.credit_account_id.push({ [item.user_index]: item.credit_account_id });
                }
                //for activity parial payment case
                if (paymentType.id === item.payment_type_id && paymentType.amount > 0) {
                  if (item.subscriber_id || item.credit_user_id || item.credit_id || item.advance_id) {
                    if (item.subscriber_id && item.subscriber_detail_id && paymentType.subscriber_id === item.subscriber_id) {
                      paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                    } else if (item.credit_user_id && item.credit_account_id && paymentType.credit_user_id === item.credit_user_id) {
                      paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                    } else if (item.credit_id && paymentType.credit_id === item.credit_id) {
                      paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                    } else if (item.advance_id && paymentType.advance_id === item.advance_id) {
                      paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                    }
                  } else {
                    paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                  }

                  //cumulate double payment with same participant index
                  let grouped = cumulPayedUserIndex(paymentType.payed_user_index);
                  paymentType.payed_user_index = grouped;

                }
              }
            }

          });
        }
        else{
          this.props.currentTicket.payments.articles.forEach((item, i) => {
            if (item.article_id.toString() === article.toString()) {
              for (let paymentType of payment_type_list) {
                if (paymentType.id === item.payment_type_id && paymentType.amount > 0) {
                  if (item.subscriber_id || item.credit_user_id || item.credit_id || item.advance_id) {
                    if (item.subscriber_id && item.subscriber_detail_id && paymentType.subscriber_id === item.subscriber_id) {
                      paymentType.payed_article_id.push({ [item.article_id]: parseFloat(item.amount) });
                    } else if (item.credit_user_id && item.credit_account_id && paymentType.credit_user_id === item.credit_user_id) {
                      paymentType.payed_article_id.push({ [item.article_id]: parseFloat(item.amount) });
                    } else if (item.credit_id && paymentType.credit_id === item.credit_id) {
                      paymentType.payed_article_id.push({ [item.article_id]: parseFloat(item.amount) });
                    }
                  } else {
                    paymentType.payed_article_id.push({ [item.article_id]: parseFloat(item.amount) });
                  }
                }
              }
            }

          });
        }
      });
    }
    //partial participtant case
    if (this.props.participantToPartialPayment.length) {
      this.props.participantToPartialPayment.map((participant, index) => {
        paymentTypes.map((item, index) => {
          if (this.props.participantToPartialPayment.length) {
            let participant_payment = this.props.currentTicket.payments.participants.filter(
              (itm) =>
                itm.user_index.toString() === participant.toString() &&
                itm.note_index === this.props.currentNote &&
                itm.payment_type_id === item.id
            );

            if (participant_payment.length) {
              participant_payment.map((itm, i) => {
                let exist = payment_type_list.filter(it => it.id === item.id && it.amount === "").length > 0;
                if (!exist) {
                  payment_type_list.push({
                      ...item,
                      amount: "",
                      credit_id: null,
                      subscriber_id: null,
                      subscriber_detail_id: null,
                      credit_account_id: null,
                      credit_user_id: null,
                      advance_id: null,
                      note_index: this.props.currentNote,
                      article_id: null,
                      payed_user_index: []
                    }
                  );
                }
                payment_type_list.push({
                  ...item,
                  amount: (item.amount ? parseFloat(item.amount) : 0) + parseFloat(itm.amount),
                  credit_id: itm.credit_id,
                  subscriber_detail_id: itm.subscriber_detail_id,
                  subscriber_id: itm.subscriber_id,
                  credit_account_id: itm.credit_account_id ? itm.credit_account_id : null,
                  credit_user_id: itm.credit_user_id ? itm.credit_user_id : null,
                  advance_id: itm.advance_id ? itm.advance_id : null,
                  note_index: itm.note_index ? itm.note_index : this.props.currentNote,
                  user_index: itm.user_index ? itm.user_index : null,
                  payed_user_index: []
                });
              });
            } else {
              payment_type_list.push({
                ...item,
                amount: "",
                credit_id: null,
                subscriber_id: null,
                subscriber_detail_id: null,
                credit_account_id: null,
                credit_user_id: null,
                advance_id: null,
                note_index: this.props.currentNote,
                article_id: null,
                payed_user_index: [],
                payed_article_id : []
              });
            }
          }

        });

        //keep order of payment type
        this.props.currentTicket.payments.participants.forEach((item, i) => {
          if (item.user_index === participant) {
            for (let paymentType of payment_type_list) {
              if (paymentType.id === item.payment_type_id && paymentType.amount > 0) {
                if (item.subscriber_id || item.credit_user_id || item.credit_id || item.advance_id) {
                  if (item.subscriber_id && item.subscriber_detail_id && paymentType.subscriber_id === item.subscriber_id) {
                    paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                  } else if (item.credit_user_id && item.credit_account_id && paymentType.credit_user_id === item.credit_user_id) {
                    paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                  } else if (item.credit_id && paymentType.credit_id === item.credit_id) {
                    paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                  } else if (item.advance_id && paymentType.advance_id === item.advance_id) {
                    paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                  }
                } else {
                  paymentType.payed_user_index.push({ [item.user_index]: parseFloat(item.amount) });
                }

                //cumulate double payment with same participant index
                let grouped = cumulPayedUserIndex(paymentType.payed_user_index);
                paymentType.payed_user_index = grouped;
                
              }
            }
          }
        });
      });
    }

    //order same payment type by amount (the empty is the last one)
    payment_type_list = payment_type_list.sort(function(a, b) {
      return (a.id - b.id);
    });
    
    payment_type_list = payment_type_list.sort(function(a, b) {
      return (b.id === a.id && b.amount - a.amount);
    });

    let totalAmount = 0;
    for (let i = 0; i < payment_type_list.length; i++) {
      if (payment_type_list[i].amount !== "" && payment_type_list[i].amount !== 0)
        totalAmount = parseFloat(totalAmount) + parseFloat(payment_type_list[i].amount);
    }

    // console.log(cumulPaymentTypeListPartial(payment_type_list));
    this.setState({
      payment_type_list: cumulPaymentTypeListPartial(payment_type_list),
      totalAmount: totalAmount
    });
  }
  
  componentDidMount() {
    //init
    let payment_type_list = this.props.paymentTypes.map((item, index) => {
      return {
        ...item, amount: "",
        credit_id: null,
        subscriber_id: null, subscriber_detail_id: null,
        credit_account_id: null, credit_user_id: null,
        advance_id: null,
        note_index: this.props.currentNote,
        payed_user_index: [],
        payed_article_id : [],
      };
    });
    this.setState({ payment_type_list: payment_type_list });

    if (this.props.articleToEditPayment) {
      this.updatePaymentTypeListArticle();
    }
    if (this.props.participantToEditPayment) {
      this.updatePaymentTypeListParticipant();
    }
    if (
      this.props.articleToPartialPayment.length !== 0 ||
      this.props.participantToPartialPayment.length !== 0
    ) {
      this.getPaymentTypeListPartialPayment();
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    //Generate credit after Store or update ticket
    if (
      (this.props.successTicketStore !== prevProps.successTicketStore && this.props.successTicketStore) ||
      (this.props.successTicketUpdate !== prevProps.successTicketUpdate && this.props.successTicketUpdate)
    ) {
      let credit_amount = 0;
      let totalAmount = 0;
      let payment_type_list = this.state.payment_type_list.filter((itm) => itm.amount !== "");
      if (payment_type_list.length) {
        payment_type_list.map((itm, index) => {
          if (itm.amount !== "") {
            totalAmount += parseFloat(itm.amount);
          }
        });

        credit_amount = parseFloat(totalAmount) - parseFloat(this.state.remainder);
        //TO DO
        if (parseFloat(credit_amount) > 0) {
          await this.setState({ credit_amount: credit_amount, confirmCreditModalOpen: true });
        }
      }
      if (parseFloat(credit_amount) <= 0) {
        await this.props.caisse_redirection("home");
      }
    }

    //pay with credit
    if (this.props.creditChecked && this.props.creditChecked !== prevProps.creditChecked) {
      if (parseFloat(this.props.creditChecked.amount) > parseFloat(this.props.creditChecked.payed_amount)) {
        this.setState({
          credit_id: this.props.creditChecked.id
        });
      } else {
        warningToast(i18n.t("avoir-expired"));
      }
    }
    //pay with advance
    if (this.props.advanceChecked && this.props.advanceChecked !== prevProps.advanceChecked) {
      if (parseFloat(this.props.advanceChecked.payed_amount) === 0 && this.props.advanceChecked.status === 0) {
        let articleToPayIds = Object.keys(this.props.articleToPay);
        let selectedActivity = this.props.currentTicket.articles.filter(item => 
            item.parent.is_activity === 1 && 
            ((articleToPayIds && articleToPayIds.includes(item.id)) || 
              (this.props.articleToEditPayment && item.id === this.props.articleToEditPayment)
            )
        );
        if (selectedActivity.length && 
          this.props.advanceChecked.article.child.activity_id === selectedActivity[0].child.activity_id) 
        {
          this.setState({ advance_id: this.props.advanceChecked.id });
        } else {
          warningToast(i18n.t("acompte-does-not-associate-activity"));
        }
      } else {
        warningToast(i18n.t("acompte-expired"));
      }
    }

    if (!this.state.disabledRestorePaymentTypeList &&
      ((this.props.participantToPartialPayment.length !== prevProps.participantToPartialPayment.length ||
        this.props.articleToPartialPayment !== prevProps.articleToPartialPayment) &&
        (this.props.articleToPartialPayment.length === 0 && prevProps.articleToPartialPayment.length !== 0 ||
          this.props.participantToPartialPayment.length === 0 && prevProps.participantToPartialPayment.length !== 0))
    ) {
      let payment_type_list = this.props.paymentTypes.map((item, index) => {
        return {
          ...item, amount: "",
          credit_id: null,
          subscriber_id: null, subscriber_detail_id: null,
          credit_account_id: null, credit_user_id: null,
          advance_id: null,
          note_index: this.props.currentNote,
          payed_user_index: [],
          payed_article_id : []
        };
      });
      await this.setState({ payment_type_list: payment_type_list });
    }
    //get payment type list (article edit)
    if ((this.props.articleToEditPayment &&
      this.props.articleToEditPayment !== prevProps.articleToEditPayment) || (this.props.articleToEditPayment && this.props.currentNote !== prevProps.currentNote)) {
      this.updatePaymentTypeListArticle();
    }
    //get paymet type list (participant edit)
    if (this.props.participantToEditPayment &&
      this.props.participantToEditPayment !== prevProps.participantToEditPayment) {
      this.updatePaymentTypeListParticipant();
    }
    //get payment type list (partial payment article / participant)
    if (
      (this.props.articleToPartialPayment !== prevProps.articleToPartialPayment &&
        this.props.articleToPartialPayment.length !== 0) ||
      (this.props.participantToPartialPayment !== prevProps.participantToPartialPayment &&
        this.props.participantToPartialPayment.length !== 0)
    ) {
      this.getPaymentTypeListPartialPayment();
    }
    //reset paymentType
    if ((!this.props.articleToEditPayment &&
      this.props.articleToEditPayment !== prevProps.articleToEditPayment) ||
      (!this.props.participantToEditPayment &&
        this.props.participantToEditPayment !== prevProps.participantToEditPayment)
    ) {
      let payment_type_list = this.state.payment_type_list.map((item, index) => {
        return {
          ...item, amount: "",
          credit_id: null,
          subscriber_id: null, subscriber_detail_id: null,
          credit_account_id: null, credit_user_id: null,
          advance_id: null,
          note_index: this.props.currentNote,
          payed_user_index: [],
          payed_article_id : []
        };
      });
      this.setState({ payment_type_list: payment_type_list });
    }
    //get credit number (edit payment with credit)
    if (this.state.credit_id && this.state.credit_id !== prevState.credit_id &&
      !this.state.credit_number && this.state.payWithCreditModalOpen) {
      let data = await caisseService.caisseService.getCredit(this.state.credit_id);
      this.props.add_to_check_credit(data);
      data = data.data;
      if (data) {
        this.setState({ credit_number: data.credit_number });
      }
    }
    //get advance number (edit payment with advance)
    if (this.state.advance_id && this.state.advance_id !== prevState.advance_id &&
      !this.state.advance_number && this.state.payWithAdvanceModalOpen) {
      let data = await caisseService.caisseService.getCredit(this.state.advance_id);
      this.props.add_to_check_advance(data);
      data = data.data;
      if (data) {
        this.setState({ advance_number: data.advance_number });
      }
    }


    //cancel payment with classic subscriber if participant is deselected (not confirmed payment) 
    if(!this.props.pendingClassicSub && this.props.pendingClassicSub !== prevProps.pendingClassicSub){
      let payment_type_list = this.state.payment_type_list.map(item=>{
        if(item.is_subscription === 1 && item.amount !== ''){
          return {
            ...item, 
            amount: '',
            credit_id: null,
            subscriber_id: null, subscriber_detail_id: null,
            credit_account_id: null, credit_user_id: null,
            advance_id: null,
            note_index: this.props.currentNote,
            payed_user_index: []

          }
        }else{
          return {...item}
        }
      });
      this.setState({payment_type_list : payment_type_list})
       
    }
    // console.log(this.state.payment_type_list);
  };

  async componentWillUnmount() {
    //rest
    await this.props.decrement_occ_nb(null);
    this.props.article_to_edit_payment(null);
    this.props.participant_to_edit_payment(null);
  }

  handleClose = () => {
    this.setState({
      paymentModalOpen: false,
      returnArticleModalOpen: false,
      payWithCreditModalOpen: false,
      payWithAdvanceModalOpen: false,
      inputIndex: null
    });
  };

  renderSuccessPaymentModal() {
    return (
      <Modal
        open={this.state.paymentModalOpen}
        onClose={this.handleClose}
        size="mini"
        className="payment-modal withOpacity"
      >
        <span>{i18n.t("payed")}</span>
      </Modal>
    );
  }

  /**
   * generate credit
   */
  renderConfirmCreditModal() {
    return (
      <ModalConfirm
        open={this.state.confirmCreditModalOpen}
        onClose={() => {
          this.setState({ confirmCreditModalOpen: false });
          this.props.caisse_redirection("home");
        }}
        closeBtn={() => {
          this.setState({ confirmCreditModalOpen: false });
          this.props.caisse_redirection("home");
        }}
        title={i18n.t("confirm-avoir")}
        message={<p>{i18n.t("generate-avoir")}</p>}
        ok={() => {
          this.props.createCredit(this.state.credit_amount);
          this.setState({ confirmCreditModalOpen: false });
          this.props.caisse_redirection("home");
        }}
        cancel={() => {
          this.setState({ confirmCreditModalOpen: false });
          this.props.caisse_redirection("home");
        }}
      />
    );
  };


  /**
   * Payment Inputs
   */
  hasAmount = () => {
    let value = false;
    let currentTicket = this.props.currentTicket;

    let payment_type_list = this.state.payment_type_list;

    for (let i = 0; i < payment_type_list.length; i++) {
      if (payment_type_list[i].amount !== "" && payment_type_list[i].amount !== 0)
        value = true;
    }

    if (currentTicket && parseFloat(currentTicket.articles[0].total_price) !== parseFloat(this.state.totalAmount) &&
      currentTicket.articles[0].parent.is_advance === 1) {
      value = false;
    }
    if (currentTicket && parseFloat(currentTicket.articles[0].total_price) !== parseFloat(this.state.totalAmount) &&
      currentTicket.articles[0].parent.is_reload === 1) {
      value = false;
    }


    let articleToPay = { ...this.props.articleToPay };
    if (this.props.articleToEditPayment) {
      let participantToEdit = currentTicket.participants.filter(itm => itm.article_id.toString() === this.props.articleToEditPayment && itm.note_index === this.props.currentNote);
      articleToPay[this.props.articleToEditPayment] = participantToEdit.map(it => it.user_index);
    }
    if (this.props.participantToEditPayment) {
      let participantToEdit = currentTicket.participants.filter(itm => itm.user_index === this.props.participantToEditPayment);
      if (participantToEdit.length > 0 && participantToEdit[0].note_index === this.props.currentNote) {
        articleToPay[participantToEdit[0].article_id] = [this.props.participantToEditPayment];
      }
    }
    let [restAmountToPay] = getAmountToPay(currentTicket, articleToPay, this.props.currentNote, this.props.articleToPartialPayment);

    //note amount without credit
    if (this.props.currentNote) {
      if (parseFloat(restAmountToPay) < sub(parseFloat(this.state.totalAmount) , parseFloat(this.state.remainder))) {
        value = false;
      }
    }

    return value;
  };

  disabledPaymentBtn = (restAmount)=>{
    if(this.props.currentTicket?.status === 0){
      return !(restAmount===0)
    }else{
      return !(this.hasAmount() && this.props.currentTicket)
    }
  }

  handleChangeFloat = (event) => {
    let val = parseFloat(event.target.value).toFixed(2);
    if (val >= 0 || event.target.value === "") {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  changePaymentInput = async (e, index) => {
    let amountToPay = this.props.restAmountToPay ? this.props.restAmountToPay : this.state.amountToPay;
    let value = e.target.value;
    if (value === ".") value = "0.";
    if (isNaN(value) && value !== "0.") return;
    let hasDot = value.split("").filter((it) => it === ".");
    if (hasDot.length > 1) {
      let delted = value.split("");
      delted.pop();
      value = delted.join("");
    }
    //partial case
    this.props.paymentTypes.map((item, index) => {
      //article case
      if (this.props.articleToPartialPayment.length) {
        let articles_payment = this.props.currentTicket.payments.articles.filter(
          (itm) =>
            this.props.articleToPartialPayment.includes(itm.article_id) &&
            itm.payment_type_id === item.id
        );
        if (Object.entries(articles_payment).length) {
          articles_payment.map((article_payment, index) => {
            amountToPay = add(parseFloat(amountToPay) , parseFloat(article_payment.amount));
          });
        }
      }
      //participant case
      if (this.props.participantToPartialPayment.length) {
        let participants_payment = this.props.currentTicket.payments.participants.filter(
          (itm) =>
            this.props.participantToPartialPayment.includes(itm.user_index) &&
            itm.payment_type_id === item.id
        );
        if (Object.entries(participants_payment).length) {
          participants_payment.map((participant_payment, index) => {
            amountToPay = add(parseFloat(amountToPay) , parseFloat(participant_payment.amount));
          });
        }
      }
    });
    if (amountToPay > 0) {
      let remainder = 0;
      let totalAmount = 0;
      let cashAmount = 0;
      let payment_type_list = [...this.state.payment_type_list];
      if (Object.entries(payment_type_list).length) {
        let formattedValue = value === "" ? value : parseFloat(parseFloat(value).toFixed(2));
        if (formattedValue.toString().split("").includes("-") || value.toString().split("").includes("e") || formattedValue < 0)
          return;
        payment_type_list[index].amount = value;
        payment_type_list.map((itm, index) => {
          if (itm.amount !== "") {
            if (itm.is_cash === 1) {
              cashAmount = parseFloat(itm.amount);
            }
            totalAmount = add(parseFloat(totalAmount), parseFloat(itm.amount));
          }
        });
        await this.setState({
          payment_type_list: payment_type_list,
          totalAmount: totalAmount ? totalAmount : 0
        });
        if (cashAmount > 0) {
          remainder = sub(parseFloat(this.state.totalAmount),parseFloat(amountToPay));
          this.setState({
            remainder:
              remainder > 0 ?
                parseFloat(remainder) < parseFloat(cashAmount) ? remainder : cashAmount
                : 0
          });
        } else {
          this.setState({
            remainder: 0
          });
        }
      }
    }
  };

  renderPaymentTypeInput() {
    let currentTicket = this.props.currentTicket;
    let currentNote = this.props.currentNote;
    if (currentTicket && this.state.payment_type_list !== null) {
      return this.state.payment_type_list.map((item, index) => {
        let hidden = (item.note_index !== currentNote); //hide payments of notes from ticket
        if (!hidden) {
          if (item.is_subscription === 0 && item.is_card === 0 && item.is_credit === 0 &&
            item.is_credit_account === 0 && item.is_advance === 0) {
            return (
              <PaymentInput key={index}
                            getValue={parseFloat(this.state.restAmountToPay).toFixed(2)}
                            name={item.slug}
                            title={item.name.length > 13 ? item.name.substr(0, 12) + "..." : item.name}
                            image={item.icon}
                            value={
                              this.state.payment_type_list &&
                              this.state.payment_type_list[index] &&
                              this.state.payment_type_list[index].amount
                                ? this.state.payment_type_list[index].amount.toString()
                                : ""
                            }
                            onChange={(e) => this.changePaymentInput(e, index)}
              />
            );
          }
          if (item.is_card === 1 && currentTicket.articles[0].parent.is_reload === 0) {
            return (
              <div
                key={index}
                className="payment-block-input"
                style={{ backgroundColor: "#80B3FF" }}
                onClick={() => {
                  // if (!isDisabled)
                  if (item.amount) return;
                  this.setState({
                    showSubscriptionModal: true,
                    subscriptionModalOption: "card",
                    inputSubscriptionIndex: index,
                    player: onlyOneParticipantWhenMultipleArticleSelected(currentTicket, this.props.articleToPay, this.props.participantToEditPayment).user
                  });
                }
                }
              >
                <span className="payment-block-input-left">
                  <Input
                    type="text"
                    min={1}
                    name={"credit"}
                    value={this.state.payment_type_list &&
                    this.state.payment_type_list[index] &&
                    this.state.payment_type_list[index].amount
                      ? this.state.payment_type_list[index].amount.toString() +
                      localStorage.getItem("currency") : ""
                    }
                    disabled={true}
                  />
                  {item.amount &&
                  <Button onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let paymentList = [...this.state.payment_type_list];
                    let filterElements = paymentList.filter(it => it.subscriber_id && it.is_card === 1);
                    if (filterElements.length > 1) {
                      paymentList.splice(index, 1);
                    } else {
                      paymentList[index].amount = "";
                      paymentList[index].subscriber_id = null;
                      paymentList[index].subscriber_detail_id = null;
                    }
                    let total = parseFloat(this.getTotalAmountFromPaymentType()).toFixed(2);
                    this.setState({ payment_type_list: paymentList, totalAmount: total });
                  }} icon={"trash"} circular={true} className={"payment-block-remove"}
                  />
                  }
                </span>
                <span className="payment-block-input-right">
                  <span>{item.name.length > 13 ? item.name.substr(0, 12) + "..." : item.name}</span>
                  <Image src={item.icon ? imgUrl + "/payments/" + item.icon : require("@/assets/other.svg")}/>
                </span>
              </div>
            );
          }
          if ((item.is_subscription === 1 && item.amount !== "") ||
            (item.is_subscription === 1 && onlyOneParticipantSelected(currentTicket, this.props.articleToPay, this.props.participantToEditPayment).value)
          ) {
            return (
              <div
                key={index}
                className="payment-block-input"
                style={{ backgroundColor: "#80B3FF" }}
                onClick={() => {
                  if (item.amount) return;
                  this.setState({
                    showSubscriptionModal: true,
                    subscriptionModalOption: "subscription",
                    inputSubscriptionIndex: index,
                    player: onlyOneParticipantSelected(currentTicket, this.props.articleToPay, this.props.participantToEditPayment).user
                  });
                }}
              >
                <span className="payment-block-input-left">
                  <Input
                    type="text"
                    min={1}
                    name={"credit"}
                    value={this.state.payment_type_list &&
                    this.state.payment_type_list[index] &&
                    this.state.payment_type_list[index].amount
                      ? this.state.payment_type_list[index].amount.toString() +
                      localStorage.getItem("currency") : ""
                    }
                    disabled={true}
                  />
                  {item.amount &&
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      let paymentList = [...this.state.payment_type_list];
                      let filterElements = paymentList.filter(it => it.subscriber_id && it.is_subscription === 1);
                      if (filterElements.length > 1) {
                        paymentList.splice(index, 1);
                      } else {
                        paymentList[index].amount = "";
                        paymentList[index].subscriber_id = null;
                        paymentList[index].subscriber_detail_id = null;
                      }
                      let total = parseFloat(this.getTotalAmountFromPaymentType()).toFixed(2);
                      if(this.props.pendingClassicSub){
                        this.props.updatePendingClassicSub(null);
                      }
                      this.setState({ payment_type_list: paymentList, totalAmount: total });
                    }} icon={"trash"} circular={true} className={"payment-block-remove"}
                  />
                  }
                </span>
                <span className="payment-block-input-right">
                  <span>{item.name.length > 13 ? item.name.substr(0, 12) + "..." : item.name}</span>
                  <Image src={item.icon ? imgUrl + "/payments/" + item.icon : require("@/assets/other.svg")}/>
                </span>
              </div>
            );
          }
          if (item.is_credit_account === 1) {
            return (
              <div
                key={index}
                className="payment-block-input"
                style={{ backgroundColor: "#97AFD2" }}
                onClick={() => {

                  this.setState({
                    showCreditAccountModal: true,
                    inputCreditAccountIndex: index
                  });
                }}
              >
                <span className="payment-block-input-left">
                  <Input
                    type="text"
                    min={1}
                    name={"credit"}
                    value={
                      this.state.payment_type_list &&
                      this.state.payment_type_list[index] &&
                      this.state.payment_type_list[index].amount
                        ? this.state.payment_type_list[index].amount.toString() +
                        localStorage.getItem("currency")
                        : ""
                    }
                    disabled={true}
                  />
                  {item.amount &&
                  <Button onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let paymentList = [...this.state.payment_type_list];
                    let filterElements = paymentList.filter(it => it.is_credit_account === 1);
                    if (filterElements.length > 1) {
                      paymentList.splice(index, 1);
                    } else {
                      paymentList[index].amount = "";
                      paymentList[index].credit_account_id = null;
                      paymentList[index].credit_user_id = null;
                    }
                    let total = parseFloat(this.getTotalAmountFromPaymentType()).toFixed(2);
                    this.setState({ payment_type_list: paymentList, totalAmount: total });
                  }} icon={"trash"} circular={true} className={"payment-block-remove"}
                  />
                  }
                </span>
                <span className="payment-block-input-right">
                  <span>{item.name.length > 13 ? item.name.substr(0, 12) + "..." : item.name}</span>
                  <Image src={item.icon ? imgUrl + "/payments/" + item.icon : require("@/assets/other_white.svg")}/>
                </span>
              </div>
            );
          }
          if (item.is_credit === 1) {
            return (
              <div
                key={index}
                className="payment-block-input margin-block-input"
                style={{ backgroundColor: "#97AFD2" }}
                onClick={() => {
                  if(!item.amount){
                    this.setState({
                      payWithCreditModalOpen: true,
                      inputIndex: index,
                      credit_value: item.amount,
                      credit_id: item.credit_id
                    });
                  }
                }
                }
              >
                <span className="payment-block-input-left">
                    <Input type="text"
                           min={1}
                           name={"credit"}
                           value={
                             this.state.payment_type_list &&
                             this.state.payment_type_list[index] &&
                             this.state.payment_type_list[index].amount
                               ? this.state.payment_type_list[index].amount.toString() +
                               localStorage.getItem("currency")
                               : ""
                           }
                           disabled={true}
                    />
                  {item.amount &&
                  <Button onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let paymentList = [...this.state.payment_type_list];
                    let filterElements = paymentList.filter(it => it.credit_id);
                    if (filterElements.length > 1) {
                      paymentList.splice(index, 1);
                    } else {
                      paymentList[index].amount = "";
                      paymentList[index].credit_id = null;
                    }

                    this.setState({ credit_number: "", credit_value: "", credit_id: null });
                    this.props.check_credit_number(null);
                    let total = parseFloat(this.getTotalAmountFromPaymentType()).toFixed(2);
                    this.setState({ payment_type_list: paymentList, totalAmount: total });
                  }}
                          icon={"trash"} circular={true}
                          className={"payment-block-remove"}
                  />
                  }
                </span>
                <span className="payment-block-input-right">
                  <span>{item.name.length > 13 ? item.name.substr(0, 12) + "..." : item.name}</span>
                  <Image src={item.icon ? imgUrl + "/payments/" + item.icon : require("@/assets/other.svg")}/>
                </span>
              </div>
            );
          }
          if ((item.is_advance === 1 && item.amount !== "") ||
            (item.is_advance === 1 &&
              (((Object.entries(this.props.articleToPay).length === 1 &&
                  articleIsActivity(currentTicket, Object.keys(this.props.articleToPay)[0]) && !haveAdvance(currentTicket)) ||

                  this.props.articleToEditPayment && articleIsActivity(currentTicket, this.props.articleToEditPayment) &&
                  !haveAdvance(currentTicket, this.props.articleToEditPayment) ||

                  this.props.participantToEditPayment && !haveAdvance(currentTicket, this.props.participantToEditPayment) ||

                  this.props.articleToPartialPayment.length === 1 &&
                  articleIsActivity(currentTicket, this.props.articleToPartialPayment[0]) &&
                  haveAdvance(currentTicket, this.props.articleToPartialPayment[0]) && item.amount !== "" ||

                  (this.props.participantToPartialPayment.length === 1 && this.props.participantToPartialPayment[0] &&
                    !haveAdvance(currentTicket, this.props.participantToPartialPayment[0])) &&
                  !haveAdvance(currentTicket, this.props.participantToPartialPayment[0].split(valeur)[0]))
              )
            )
          ) {
            return (
              <div
                key={index}
                className="payment-block-input margin-block-input"
                style={{ backgroundColor: "#97AFD2" }}
                onClick={() => {
                  if (!item.amount && !(this.props.articleToPartialPayment[0] &&
                    articleIsActivity(currentTicket, this.props.articleToPartialPayment[0]) &&
                    haveNotActivitySelected(currentTicket, this.props.articleToPay,
                      this.props.articleToEditPayment, this.props.articleToPartialPayment))) {
                    this.setState({
                      payWithAdvanceModalOpen: true,
                      inputIndex: index,
                      advance_value: item.amount,
                      advance_id: item.advance_id
                    });
                  }
                }
                }
              >
                <span className="payment-block-input-left">
                    <Input
                      type="text"
                      min={1}
                      name={"advance"}
                      value={
                        this.state.payment_type_list &&
                        this.state.payment_type_list[index] &&
                        this.state.payment_type_list[index].amount
                          ? this.state.payment_type_list[index].amount.toString() +
                          localStorage.getItem("currency")
                          : ""
                      }
                      disabled={true}
                    />
                  {item.amount &&
                  <Button disabled={this.props.articleToPartialPayment[0] &&
                  articleIsActivity(currentTicket, this.props.articleToPartialPayment[0]) &&
                  haveNotActivitySelected(currentTicket, this.props.articleToPay,
                    this.props.articleToEditPayment, this.props.articleToPartialPayment)}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            let paymentList = [...this.state.payment_type_list];
                            this.setState({ advance_number: "", advance_value: "", advance_id: null });
                            this.props.check_advance_number(null);
                            let filterElements = paymentList.filter(it => it.advance_id);
                            if (filterElements.length > 1)
                              paymentList.splice(index, 1);
                            else
                              paymentList[index].amount = "";
                            paymentList[index].advance_id = null;
                            //paymentList[index].advance_rest_amount = null;
                            paymentList[index].credit_user_id = null;
                            let total = parseFloat(this.getTotalAmountFromPaymentType()).toFixed(2);
                            this.setState({ payment_type_list: paymentList, totalAmount: total });
                          }}
                          icon={"trash"} circular={true}
                          className={"payment-block-remove"}
                  />
                  }
                </span>
                <span className="payment-block-input-right">
                  <span>{item.name.length > 13 ? item.name.substr(0, 12) + "..." : item.name}</span>
                  <Image src={item.icon ? imgUrl + "/payments/" + item.icon : require("@/assets/other_white.svg")}/>
                </span>
              </div>
            );
          }
        }

      });
    }
  }

  /**
   * pay with credits
   */
  checkCreditNumber() {
    this.props.check_credit_number(this.state.credit_number);
  }

  payWithCredit() {
    let { inputIndex } = this.state;
    let payment_type_list = [...this.state.payment_type_list];
    let totalAmount = this.state.totalAmount;
    if (Object.entries(payment_type_list).length && inputIndex) {
      payment_type_list[inputIndex].amount = this.state.credit_value;
      payment_type_list[inputIndex].credit_id = this.state.credit_id;
      totalAmount = add(parseFloat(totalAmount) , parseFloat(this.state.credit_value));
    }
    this.setState({
      payment_type_list: payment_type_list,
      totalAmount: totalAmount
    });
    this.handleClose();
  }

  renderPayWithCreditModal(restAmount, totalsAmount) {
    let currency = localStorage.getItem("currency");
    let availableAmount = 0;
    if (this.props.creditChecked)
      availableAmount = nullStringNumber((nullStringNumber(this.props.creditChecked.amount) - nullStringNumber(this.props.creditChecked.payed_amount)).toFixed(2));
    return (
      <Modal
        open={this.state.payWithCreditModalOpen}
        onClose={this.handleClose}
        size="tiny"
        className="modal-ticket"
      >
        <Modal.Content className="modal-content">
          <h3 className="modal-content-header">{i18n.t("pay-with-avoir")}
            ({restAmount > 0 ? parseFloat(restAmount).toFixed(2) : 0}{currency})</h3>
          <div className="block-credit-modal">
            <h5>{i18n.t("add-number-avoir")}</h5>
            <div className="btn-group">
              <Input
                type="text"
                name={"credit_number"}
                value={this.state.credit_number}
                onChange={(event) => {
                  this.setState({
                    [event.target.name]: event.target.value
                  });
                }}
              />
              <Button className="modal-ok-btn" onClick={() => this.checkCreditNumber()}>
                Ok
              </Button>
            </div>
          </div>
          <div className="block-credit-modal">
            <h5>{i18n.t("indicate-amount-to-use")}</h5>
            <span>{this.props.creditChecked && this.state.credit_number ?
              (parseFloat(this.props.creditChecked.amount) > parseFloat(this.props.creditChecked.payed_amount) ?
                (availableAmount.toFixed(2) + currency) : 0)
              : ""}
            </span>
            <Input
              className="credit-input"
              type="number"
              name={"credit_value"}
              value={this.state.credit_value}
              onChange={this.handleChangeFloat}
            />
          </div>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={() => {
                this.handleClose();
                this.props.check_credit_number(null);
              }}
            />
            <span className="btn-group">
              <Button
                className="modal-delete-btn"
                disabled={
                  !(this.state.credit_value > 0 &&
                    this.props.creditChecked &&
                    parseFloat(this.state.credit_value) <= availableAmount &&
                    parseFloat(this.state.credit_value) <= parseFloat(restAmount)
                  )
                }
                onClick={() => this.payWithCredit()}
              >
                {i18n.t("validate")}
              </Button>
              <Button className="modal-cancel-btn" onClick={() => {
                this.handleClose();
                this.props.check_credit_number(null);
              }}>
                {i18n.t("cancel")}
              </Button>
            </span>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  /**
   * pay with advance
   */
  checkAdvanceNumber() {
    this.setState({ advance_id: null });
    this.props.check_advance_number(this.state.advance_number , this.props.currentTicket.reservation_id);
  }

  payWithAdvance() {
    let { inputIndex } = this.state;
    let payment_type_list = [...this.state.payment_type_list];
    let totalAmount = this.state.totalAmount;
    if (Object.entries(payment_type_list).length && inputIndex) {
      payment_type_list[inputIndex].amount = this.state.advance_value;
      payment_type_list[inputIndex].advance_id = this.state.advance_id;
      payment_type_list[inputIndex].credit_user_id = this.props.advanceChecked.user_id;
      //payment_type_list[inputIndex].advance_rest_amount = parseFloat(this.props.advanceChecked.amount) - parseFloat(this.state.advance_value);
      totalAmount = add(parseFloat(totalAmount) , parseFloat(this.state.advance_value));
    }
    this.setState({
      payment_type_list: payment_type_list,
      totalAmount: totalAmount
    });
    this.handleClose();
  }

  renderPayWithAdvanceModal(restAmount, totalsAmount) {
    let currency = localStorage.getItem("currency");
    return (
      <Modal
        open={this.state.payWithAdvanceModalOpen}
        onClose={this.handleClose}
        size="tiny"
        className="modal-ticket"
      >
        <Modal.Content className="modal-content">
          <h3 className="modal-content-header">{i18n.t("pay-with-acompte")}
            ({restAmount > 0 ? parseFloat(restAmount).toFixed(2) : 0}{currency})</h3>
          <div className="block-credit-modal">
            <h5>{i18n.t("add-number-acompte")}</h5>
            <div className="btn-group">
              <Input
                type="text"
                name={"advance_number"}
                value={this.state.advance_number}
                onChange={(event) => {
                  this.setState({
                    [event.target.name]: event.target.value
                  });
                }}
              />
              <Button className="modal-ok-btn" onClick={() => this.checkAdvanceNumber()}>
                {i18n.t("ok")}
              </Button>
            </div>
          </div>
          <div className="block-credit-modal">
            <h5>{i18n.t("indicate-amount-to-use")}</h5>
            <span>
              {this.props.advanceChecked && parseFloat(this.props.advanceChecked.payed_amount) === 0 &&
              this.state.advance_id && this.state.advance_number &&
              (parseFloat(this.props.advanceChecked.amount)) + currency
              }
            </span>
            <Input
              className="credit-input"
              type="number"
              name={"advance_value"}
              value={this.state.advance_value}
              onChange={this.handleChangeFloat}
              disabled={!(this.state.advance_id && this.props.advanceChecked)}
            />
          </div>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={() => {
                this.handleClose();
                this.props.check_advance_number(null);
              }}
            />
            <span className="btn-group">
              <Button
                className="modal-delete-btn"
                disabled={
                  !(this.state.advance_value &&
                    this.state.advance_id &&
                    this.props.advanceChecked &&
                    parseFloat(this.state.advance_value) <= parseFloat(this.props.advanceChecked.amount) &&
                    parseFloat(this.state.advance_value) <= parseFloat(restAmount)
                  )
                }
                onClick={() => this.payWithAdvance()}
              >
                {i18n.t("validate")}
              </Button>
              <Button className="modal-cancel-btn" onClick={() => {
                this.handleClose();
                this.props.check_advance_number(null);
              }}>
                {i18n.t("cancel")}
              </Button>
            </span>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  /**
   * pay with subscriber
   */
  payWithSubscriptions = (subscriber, subscriber_detail) => {
    let { inputSubscriptionIndex } = this.state;
    let payment_type_list = [...this.state.payment_type_list];
    let totalAmount = this.state.totalAmount;
    if (Object.entries(payment_type_list).length) {
      payment_type_list[inputSubscriptionIndex].amount = subscriber_detail.expense;
      payment_type_list[inputSubscriptionIndex].subscriber_id = subscriber.id;
      payment_type_list[inputSubscriptionIndex].subscriber_detail = subscriber_detail;
      totalAmount = add(parseFloat(totalAmount) , parseFloat(subscriber_detail.expense));
    }
    this.props.updatePendingClassicSub(subscriber.id);
    this.setState({
      payment_type_list: payment_type_list,
      totalAmount: totalAmount,
      showSubscriptionModal: false,
      inputSubscriptionIndex: 0
    });

  };

  /**
   * pay with credit account
   */
  payWithCreditAccount = (credit_account_detail) => {
    let { inputCreditAccountIndex } = this.state;
    let payment_type_list = [...this.state.payment_type_list];
    let totalAmount = this.state.totalAmount;
    if (Object.entries(payment_type_list).length) {
      payment_type_list[inputCreditAccountIndex].amount = credit_account_detail.expense;
      payment_type_list[inputCreditAccountIndex].credit_detail = credit_account_detail;
      payment_type_list[inputCreditAccountIndex].credit_user_id = credit_account_detail.user_id;
      totalAmount = add(parseFloat(totalAmount) , parseFloat(credit_account_detail.expense));
    }
    this.setState({
      payment_type_list: payment_type_list,
      totalAmount: totalAmount,
      showCreditAccountModal: false,
      inputCreditAccountIndex: 0
    });


  };

  /**
   *  multi payment
   **/

  handleArticlePayment(paymentType, current_article, ticket = null) {
    // console.log("current_article",current_article);
    // console.log(("paymentType",paymentType))
    let currentTicket = ticket ? ticket : { ...this.props.currentTicket };
    let articles = { ...currentTicket.payments.articles };
    let article_nb = current_article.article_nb;
    
    //keep same order of payment (partial payment of article not activity) 
    let old_amount = null;
    if(current_article.parent.is_activity === 0){
      if (paymentType.payed_article_id && paymentType.payed_article_id.length > 0) {
        let checkCompatibility = paymentType.payed_article_id.filter(it =>
          Object.keys(it).includes(current_article.id));
        if (checkCompatibility.length === 0) {
          // console.log('exit');
          let rest = parseFloat(paymentType.amount);
          return rest;
        } else {
          //old amount must be < amount of payment type
          if(Object.values(checkCompatibility[0]) <= parseFloat(paymentType.amount)){
            old_amount = parseFloat(Object.values(checkCompatibility[0]));
          }
          else{
            old_amount = parseFloat(paymentType.amount);
          }
        }
      }
    }
    

    articles = Object.values(articles);
    let total_price =
      (parseFloat(current_article.total_price) * parseFloat(article_nb)) -
      parseFloat(getArticleDiscount(currentTicket, current_article.id, current_article.note_index));
    //get article amount
    let payment_type_amount = 0;
    //get sum amount of old payment type of article
    let sum_old_payed_amount = 0;
    let paymentTypesArticle = currentTicket.payments.articles.filter(
      (itm) => itm.article_id.toString() === current_article.id.toString()
    );

    paymentTypesArticle.map((item, index) => {
      sum_old_payed_amount = parseFloat(sum_old_payed_amount) + parseFloat(item.amount);
    });
    //article not totaly payed
    if (sum_old_payed_amount < total_price) {
      let rest_amout_to_pay = parseFloat(total_price) - parseFloat(sum_old_payed_amount);
      if (rest_amout_to_pay > parseFloat(paymentType.amount)) {
        payment_type_amount = parseFloat(paymentType.amount);
      } else {
        payment_type_amount = parseFloat(rest_amout_to_pay);
      }

      //keep payment order partial payment of activity
      if (old_amount != null) {
        payment_type_amount = parseFloat(old_amount);
      }
    }
    if (payment_type_amount > 0) {
      let article = {};
      article["article_id"] = current_article.id;
      article["payment_type_id"] = paymentType.id;
      article["amount"] = (parseFloat(payment_type_amount)).toFixed(2);
      article["amount_ht"] = (parseFloat(getHT(parseFloat(payment_type_amount), current_article.vat.value))).toFixed(3);
      article["vat_amount"] = (parseFloat(payment_type_amount) -
        parseFloat(getHT(parseFloat(payment_type_amount), current_article.vat.value))).toFixed(3);
      article["vat_id"] = current_article.vat_id;
      article["credit_id"] = paymentType.credit_id;
      article["note_index"] = paymentType.note_index;

      //payment with subcriber
      article["subscriber_id"] = paymentType.subscriber_id;
      if (paymentType.subscriber_id) {
        article["subscriber_detail"] = {
          date: currentTicket.date,
          reservation_id: currentTicket.reservation_id,
          article_id: current_article.id,
          subscriber_id: paymentType.subscriber_id,
          payment_type_id: paymentType.id,
          id: null,
          ticket_id: currentTicket.id === "undefined" ? null : currentTicket.id,
          label: current_article.length > 0 ? current_article.name : "",
          expense: parseFloat(payment_type_amount).toFixed(2)
        };
      }
      if (!Array.isArray(paymentType.subscriber_detail_id) && paymentType.subscriber_detail_id) {
        article["subscriber_detail_id"] = paymentType.subscriber_detail_id;
        article.subscriber_detail.id = paymentType.subscriber_detail_id;
      }
      //payment with credit account
      if (paymentType.credit_user_id) {
        article["credit_detail"] = {
          user_id: paymentType.credit_user_id,
          expense: parseFloat(payment_type_amount).toFixed(2),
          is_account: 0,
          complex_id: localStorage.getItem("complex_id"),
          id: null
        };
        article["credit_user_id"] = paymentType.credit_user_id;

      }
      if (!Array.isArray(paymentType.credit_account_id) && paymentType.credit_account_id) {
        article["credit_account_id"] = paymentType.credit_account_id;
        article.credit_detail.id = paymentType.credit_account_id;
      }

      //add credit to susbcription details (create and pay reload)
      if (current_article.parent.is_reload === 1 && currentTicket.subscriber) {
        article["subscriber_id"] = currentTicket.subscriber.id;
        article["subscriber_detail"] = {
          date: currentTicket.date,
          article_id: current_article.id.toString(),
          subscriber_id: currentTicket.subscriber.id,
          payment_type_id: paymentType.id,
          id: null,
          ticket_id: null,
          reservation_id: null,
          label: current_article.length > 0 ? current_article.name : "",
          credit: parseFloat(current_article.total_price).toFixed(2)
        };
        //end
      }
      articles.push(article);
    }
    currentTicket.payments.articles = articles;
    let rest = (parseFloat(paymentType.amount) - parseFloat(payment_type_amount)).toFixed(2);
    // console.log('rest',rest)
    return rest;
  };

  handleParticipantPayment(paymentType, current_participant, ticket = null) {
    // console.log('participant',current_participant.user_index);
    // console.log('paymentType',paymentType)

    let currentTicket = ticket ? ticket : { ...this.props.currentTicket };
    let article = currentTicket.articles.filter(it => (it.id.toString() === current_participant.article_id.toString()));


    //skip payment type if payed_user_index empty and payed_article_id not empty (partial article + partial participant case)
    if(paymentType.payed_user_index && paymentType.payed_user_index.length === 0 && 
      paymentType.payed_article_id && paymentType.payed_article_id.length > 0) {
      let rest = parseFloat(paymentType.amount);
      return { currentTicket, rest };
    }

    //keep same order of payment (edit payment/partial payment of activity) 
    let old_amount = null;
    if (paymentType.payed_user_index && paymentType.payed_user_index.length > 0) {
      // //restore old payment
      // let totalOldAmountOfParticipant =  totalAmountOfPayedUserIndex(paymentType,current_participant.user_index);
      // // console.log('totalOldAmountOfParticipant',totalOldAmountOfParticipant);
      // let clonedParticipantPayment = currentTicket.payments.participants;
      // let payedAmountOfParticipant = clonedParticipantPayment.filter(it=> 
      //   it.user_index === current_participant.user_index && it.payment_type_id.toString() === paymentType.id.toString());
      
      // //if participant not payed / not complitly payed with this payment type
      // if(parseFloat(totalOldAmountOfParticipant) !== 0 && 
      //   ((payedAmountOfParticipant.length && 
      //     parseFloat(totalOldAmountOfParticipant) < parseFloat(payedAmountOfParticipant[0].amount)) || 
      //     payedAmountOfParticipant.length === 0)){
        let checkCompatibility = paymentType.payed_user_index.filter(it =>
          Object.keys(it).includes(current_participant.user_index));
        if (checkCompatibility.length === 0) {
          // console.log('exit');
          let rest = parseFloat(paymentType.amount);
          return { currentTicket, rest };
        } else{
          //old amount must be < amount of payment type
          if(Object.values(checkCompatibility[0]) <= parseFloat(paymentType.amount)){
            old_amount = parseFloat(Object.values(checkCompatibility[0]));
          }
          else{
            old_amount = parseFloat(paymentType.amount);
          }
        }
      // }
    }
    
    let participants = { ...currentTicket.payments.participants };
    participants = Object.values(participants);
    //get participant amount
    let payment_type_amount = 0;
    //get sum amount of old payment type of participant
    let sum_old_payed_amount = 0;
    let paymentTypesParticipant = currentTicket.payments.participants.filter(
      (itm) => itm.user_index === current_participant.user_index
    );
    paymentTypesParticipant.map((item, index) => {
      sum_old_payed_amount = parseFloat(sum_old_payed_amount) + parseFloat(item.amount);
    });
    //payment not totaly payed
    let total_price = parseFloat(current_participant.amount) -
      parseFloat(getParticipantDiscount(currentTicket, current_participant.user_index));
    if (parseFloat(sum_old_payed_amount) < parseFloat(total_price)) {
      let rest_amount_to_pay = parseFloat(total_price) - parseFloat(sum_old_payed_amount);
      if (rest_amount_to_pay > paymentType.amount) {
        payment_type_amount = parseFloat(paymentType.amount);
      } else {
        payment_type_amount = parseFloat(rest_amount_to_pay);
      }
      //keep payment order partial payment of activity
      if (old_amount != null) {
        payment_type_amount = parseFloat(old_amount);
      }
    }
    //payment_type_amount = parseFloat(payment_type_amount) ;
    if (parseFloat(payment_type_amount) > 0) {
      let participant = {
        user_id: current_participant.user_id,
        user_index: current_participant.user_index,
        article_id: current_participant.article_id,
        amount: parseFloat(payment_type_amount).toFixed(2),
        amount_ht: paymentType.is_subscription == 0 ? (parseFloat(getHT(parseFloat(payment_type_amount), article[0].vat.value))).toFixed(3) : 0,
        vat_amount: paymentType.is_subscription == 0 ? (parseFloat(payment_type_amount) -
          parseFloat(getHT(parseFloat(payment_type_amount), article[0].vat.value))).toFixed(3) : 0,
        vat_id: paymentType.is_subscription == 0 ? current_participant.vat_id : null,
        payment_type_id: paymentType.id,
        credit_id: paymentType.credit_id,
        subscriber_id: paymentType.subscriber_id,
        advance_id: paymentType.advance_id,
        credit_user_id: paymentType.credit_user_id,
        note_index: current_participant.note_index
      };
      //payment with subscriber
      if (paymentType.subscriber_id) {
        participant.subscriber_detail = {
          date: currentTicket.date,
          reservation_id: currentTicket.reservation_id,
          article_id: current_participant.article_id.toString().split(valeur)[0],
          subscriber_id: paymentType.subscriber_id,
          payment_type_id: paymentType.id,
          expense: parseFloat(payment_type_amount).toFixed(2),
          label: article.length > 0 ? article[0].name : "",
          id: null,
          ticket_id: currentTicket.id === "undefined" ? null : currentTicket.id

        };

        if (paymentType.subscriber_detail_id) {
          if (Array.isArray(paymentType.subscriber_detail_id) && Object.values(paymentType.subscriber_detail_id).length > 0) {
            let value = paymentType.subscriber_detail_id.filter(itm => Object.keys(itm).includes(current_participant.user_index));
            if (value.length > 0) {
              participant.subscriber_detail_id = value[0][current_participant.user_index];
              participant.subscriber_detail.id = value[0][current_participant.user_index];
            }
          } else {
            participant.subscriber_detail_id = paymentType.subscriber_detail_id;
            participant.subscriber_detail.id = paymentType.subscriber_detail_id;
          }
        }
      }

      //payment with credit
      if (paymentType.credit_user_id && !paymentType.advance_id) {
        participant.credit_detail = {
          user_id: paymentType.credit_user_id,
          expense: parseFloat(payment_type_amount).toFixed(2),
          is_advance: 0,
          complex_id: localStorage.getItem("complex_id"),
          id: null
        };
        
        if (paymentType.credit_account_id) {
          if (Array.isArray(paymentType.credit_account_id) && Object.values(paymentType.credit_account_id).length > 0) {
            let value = paymentType.credit_account_id.filter(itm => Object.keys(itm).includes(current_participant.user_index));
            if (value.length > 0) {
              participant.credit_account_id = value[0][current_participant.user_index];
              participant.credit_detail.id = value[0][current_participant.user_index];
            }
          } else {
            participant.subscriber_detail_id = paymentType.credit_account_id;
            participant.credit_detail.id = paymentType.credit_account_id;
          }
        }

        participant.credit_user_id = paymentType.credit_user_id ? paymentType.credit_user_id : null;
      }

      participants.push(participant);
    }

    currentTicket.payments.participants = participants;
    let rest = (parseFloat(paymentType.amount) - parseFloat(payment_type_amount)).toFixed(2);

    // console.log('rest',rest);
    return { currentTicket, rest };
  };

  /**
   * participant have old payment + new payment => with same payment type
   * we must separate payment type on 2 lines (old payment / new payment) 
   * example:
   * 
   * {name : cb,amount : 10,payed_user_index:[{15-1:3},{15-3:4}]}
   * => {name : cb ,amount : 7,payed_user_index:[{15-1:3},{15-3:4}]} //old payment
   *    {name : cb ,amount : 3,payed_user_index:[]} //new payment
   * 
   * objective : not to lose the NEW payment of the SAME payment type (skip the exit)
   * 
   * @param {*} paymentTypeList 
   * @param {*} user_index 
   * @returns 
   */
  separateOldNewPaymentType(paymentTypeList){
    let data = [];
    if(paymentTypeList && paymentTypeList.length){
      paymentTypeList.forEach(item=>{
        let totalOldAmountOfParticipant = item.payed_user_index.length > 0 ? item.payed_user_index.reduce((acc, it) => {
          return (acc + parseFloat(Object.values(it)[0]))
        }, 0) : 0;
        let totalOldAmountOfArticle = item.payed_article_id.length > 0 ? item.payed_article_id.reduce((acc, it) => {
          return (acc + parseFloat(Object.values(it)[0]))
        }, 0) : 0;

        //partial activity or partial participants
        if(item.payed_user_index.length > 0 && item.payed_article_id.length === 0){
          if(parseFloat(totalOldAmountOfParticipant) < parseFloat(item.amount)){
            data.push({
              ...item,
              amount : parseFloat(item.amount) - parseFloat(totalOldAmountOfParticipant),
              payed_user_index : [],
            })
            item.amount = parseFloat(totalOldAmountOfParticipant);
          }
        } 
        //partial article not activity
        else if(item.payed_user_index.length === 0 && item.payed_article_id.length > 0){
          if(parseFloat(totalOldAmountOfArticle) < parseFloat(item.amount)){
            data.push({
              ...item,
              amount : parseFloat(item.amount) - parseFloat(totalOldAmountOfArticle),
              payed_article_id : [],
            })
            item.amount = parseFloat(totalOldAmountOfArticle);
          }
        }
        //partial article(s) + partial activity || partial article(s) + partial participant(s)
        else if(item.payed_user_index.length > 0 && item.payed_article_id.length > 0){
          let total = totalOldAmountOfArticle + totalOldAmountOfParticipant;
          if(parseFloat(total) < parseFloat(item.amount)){
            data.push({
              ...item,
              amount : parseFloat(item.amount) - parseFloat(total),
              payed_article_id : [],
              payed_user_index : [],
            })
            item.amount = parseFloat(total);
          }
        } 
      })
      return [...paymentTypeList,...data];
    }
  }

  paySelectedArticles = async () => {
    let articleToPay = { ...this.props.articleToPay };
    articleToPay = Object.entries(articleToPay);
    let currentTicket = { ...this.props.currentTicket };

    let payment_type_list = this.state.payment_type_list.filter((itm) => itm.amount !== "");
    if (payment_type_list.length) {
      //partial payment (multiple)
      payment_type_list = this.separateOldNewPaymentType(payment_type_list);
      this.setState({payment_type_list : payment_type_list});

      if (this.props.participantToPartialPayment.length) {
        for (let participant of this.props.participantToPartialPayment) {
          currentTicket = await this.editParticipantPayment(participant, currentTicket, true);
        }
      }
      if (this.props.articleToPartialPayment.length) {
        for (let article of this.props.articleToPartialPayment) {
          currentTicket = await this.editArticlePayment(article, currentTicket, true);
        }
      }

      //simple payment (article/participant)
      let list_participant = [];
      if (articleToPay.length !== 0) {
        articleToPay.map((article_to_pay, i) => {
          currentTicket.articles.map((item, index) => {
            if (item.id.toString() === article_to_pay[0].toString() &&
              !this.props.articleToPartialPayment.includes(item.id)
            ) {
              //pay per participant
              if (item.parent.is_activity === 1 && item.child.is_res === 1 && article_to_pay[1].length) {
                if (Object.entries(currentTicket.participants).length) {
                  list_participant = currentTicket.participants.filter((itm) =>
                    article_to_pay[1].includes(itm.user_index) &&
                    //!this.props.participantToPartialPayment.includes(itm.user_index) &&
                    itm.status === null
                  );
                  if (Object.entries(list_participant).length) {
                    list_participant.map((client, ind) => {
                      payment_type_list.map((payment_type, index) => {
                        let { rest } = this.handleParticipantPayment(
                          payment_type,
                          client
                        );
                        payment_type["amount"] = rest;
                      });
                      client["payed_amount"] = cumulParticipantPayedAmountFromPaymentParticipant(currentTicket, client.user_index);
                      client.status = setParticipantStatus(currentTicket, client);
                    });

                    let articlePayment = currentTicket.payments.articles.filter(
                      (itm) => itm.article_id.toString() !== item.id.toString()
                    );
                    articlePayment = [
                      ...articlePayment,
                      ...cumulPaymentArticlesFromParticipant(currentTicket, item.id)
                    ];
                    //vat for article payment ticket (vat exact)
                    articlePayment.map((it, i) => {
                      if (it.article_id === item.id) {
                        it.amount_ht = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
                          getHT(parseFloat(it.amount), item.vat.value);
                        it.vat_amount = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
                          (parseFloat(it.amount) - parseFloat(getHT(parseFloat(it.amount), item.vat.value))).toFixed(3);
                        it.vat_id = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? null : item.vat_id;
                      }
                    });
                    currentTicket.payments.articles = articlePayment;
                  }
                }
              }
              //pay per article
              else {
                payment_type_list.forEach((payment_type, index) => {
                  let rest_amount = this.handleArticlePayment(payment_type,item,currentTicket);
                  payment_type["amount"] = rest_amount;
                });
              }
              item.payed_amount = cumulArticlePayedAmountFromPaymentArticle(currentTicket, item.id);
              item.status = (item.parent.is_activity === 1 && item.child.is_res === 1) ? setArticleWithoutNoteStatus(currentTicket, item) : setArticleStatus(currentTicket, item);
            }
          });
        });
        currentTicket.payments.types = cumulPaymentTypesFromArticle(currentTicket);
        currentTicket["payed_amount"] = cumulPayedAmountFromPaymentTypes(currentTicket);
        //create advance
        if (currentTicket.articles[0] && currentTicket.articles[0].parent.is_advance === 1) {
          currentTicket.is_advance = 1;
          currentTicket.status = 1;
          currentTicket.payed_amount = 0;
          currentTicket.article_id = currentTicket.articles[0].id.toString().split(valeur)[0];
          currentTicket.advance = true;
        }
        //create reload
        if (currentTicket.articles[0] && currentTicket.articles[0].parent.is_reload === 1) {
          currentTicket.is_reload = 1;
          currentTicket.status = 1;
          currentTicket.payed_amount = currentTicket.articles[0].total_price;
          currentTicket.article_id = currentTicket.articles[0].id.toString().split(valeur)[0];
          currentTicket.reload = true;
          delete currentTicket.subscriber;
        }
      }

      let notes = [...currentTicket.notes];

      //save current ticket
      if (currentTicket.id) {
        this.props.updateTicket({ ...currentTicket, notes });
      } else {
        this.props.storeTicket({ ...currentTicket, notes, owner_id: this.props.user.id });
      }

      await this.setState({
        paymentModalOpen: true,
        credit_id: null, credit_number: null, credit_value: null,
        advance_id: null, advance_number: null, advance_value: null
      });

      setTimeout(this.handleClose, 1800);
      //this.props.article_to_pay([], 0, 0);
      this.props.check_credit_number(null);
      this.props.check_advance_number(null);

      if(this.props.pendingClassicSub){
        this.props.updatePendingClassicSub(null);
      }
    }
  };

  /**
   * Edit payments / partial Payment
   */
  editArticlePayment = async (id = null, ticket = null, is_partial = false) => {
    this.setState({ disabledRestorePaymentTypeList: true });
    let currentTicket = ticket ? ticket : { ...this.props.currentTicket };
    let article_id = (id !== null) ? id : this.props.articleToEditPayment;
    let article = null;
    for (let index = 0; index < currentTicket.articles.length; index++) {
      if (currentTicket.articles[index].id.toString() === article_id.toString()) {
        article = currentTicket.articles[index];
      }
    }

    //delete old payment data
    article["status"] = null;
    if (article.parent.is_activity === 1 && article.child.is_res === 1) {
      if (Object.entries(currentTicket.participants).length) {
        currentTicket.participants.map((itm, i) => {
          if (itm.article_id.toString() === article_id.toString() && itm.note_index === this.props.currentNote) {
            itm["payed_amount"] = 0;
            itm["status"] = null;
          }
        });
      }
      let participant_payments = currentTicket.payments.participants.filter(
        (itm) => !(itm.article_id.toString() === article_id.toString() && itm.note_index === this.props.currentNote)
      );
      currentTicket.payments.participants = participant_payments;
    }
    let article_payments = currentTicket.payments.articles.filter(
      (itm) => !(itm.article_id.toString() === article_id.toString() && itm.note_index === this.props.currentNote)
    );
    currentTicket.payments.articles = article_payments;

    let payment_type_list = this.state.payment_type_list.filter((itm) => itm.amount !== "" || itm.note_index !== this.props.currentNote);

    payment_type_list = payment_type_list.sort(function(a, b) {
      return ((b.payed_user_index.length > 0) - (a.payed_user_index.length > 0));
    });

    if (article.parent.is_activity === 1 && article.child.is_res === 1) {
      if (Object.entries(currentTicket.participants).length) {
        let list_participant = currentTicket.participants.filter(
          (itm) => article_id.toString() === itm.article_id.toString() && itm.note_index === this.props.currentNote
        );
        if (Object.entries(list_participant).length) {
          list_participant.forEach((client, ind) => {
            payment_type_list.map((payment_type, index) => {
              let res = this.handleParticipantPayment(payment_type,client,currentTicket);
              payment_type.amount = res.rest;
              currentTicket = res.currentTicket;
            });
            client.payed_amount = cumulParticipantPayedAmountFromPaymentParticipant(currentTicket, client.user_index);
            client.status = setParticipantStatus(currentTicket, client);
          });
          let articlePayment = currentTicket.payments.articles.filter(
            (itm) => !(itm.article_id.toString() === article.article_id.toString())
          );
          articlePayment = [
            ...articlePayment,
            ...cumulPaymentArticlesFromParticipant(currentTicket, article.id)
          ];
          //vat for article payment ticket (vat exact)
          articlePayment.map((it, i) => {
            if (it.article_id === article.id) {
              it.amount_ht = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
                getHT(parseFloat(it.amount), article.vat.value);
              it.vat_amount = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
                (parseFloat(it.amount) - parseFloat(getHT(parseFloat(it.amount), article.vat.value))).toFixed(3);
              it.vat_id = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? null : article.vat_id;
            }
          });
          currentTicket.payments.articles = articlePayment;
        }
      }
    }
    //pay per article
    else {
      payment_type_list.sort((a, b) => {
        if (a.article_id === article_id) return 1;
        else if (b.article_id === article_id) return -1;
        else return 0;
      });
      payment_type_list.forEach((payment_type, index) => {
        let rest_amount = this.handleArticlePayment(payment_type, { ...article }, currentTicket);
        payment_type.amount = rest_amount;
      });
    }
    //update status
    article.payed_amount = cumulArticlePayedAmountFromPaymentArticle(currentTicket, article.id);

    currentTicket.articles.forEach(
      (itm, i) => {
        if (itm.id.toString() === article_id.toString()) {
          currentTicket.articles[i] = article;
          itm = article;
          itm.status = (itm.parent.is_activity === 1 && itm.child.is_res === 1) ? 
          setArticleWithoutNoteStatus(currentTicket, itm) : 
          setArticleStatus(currentTicket, itm);
        }
      }
    );

    currentTicket.payments.participants = cumulSamePaymentParticipant(currentTicket.payments.participants);
    currentTicket.payments.types = cumulPaymentTypesFromArticle(currentTicket);
    currentTicket.payed_amount = cumulPayedAmountFromPaymentTypes(currentTicket);

    //save ticket
    if (currentTicket.id && !is_partial) {
      await this.props.updateTicket({ ...currentTicket });
    }

    //reset
    if (this.props.currentNote) {
      let currentNote = getCurrentNote(currentTicket, this.props.currentNote);
      if (currentNote && currentNote.status === 1) {
        this.props.updateCurrentNote(null);
      }
    }

    await this.setState({
      credit_id: null, credit_number: null, credit_value: null,
      advance_id: null, advance_number: null, advance_value: null
    });
    //this.props.article_to_pay([], 0, 0);
    
    this.props.check_credit_number(null);
    this.props.check_advance_number(null);
    if(this.props.pendingClassicSub){
      this.props.updatePendingClassicSub(null);
    }
    if (is_partial) {
      return currentTicket;
    }
    //keep rest of payment type to generate an avoir
    this.setState({ disabledRestorePaymentTypeList: false });
  };

  editParticipantPayment = async (id, ticket = null, is_partial = false) => {
    this.setState({ disabledRestorePaymentTypeList: true });
    let currentTicket = ticket ? ticket : { ...this.props.currentTicket };
    let participant_id = id ? id : this.props.participantToEditPayment;
    let participant = currentTicket.participants.filter((itm) => itm.user_index === participant_id);
    let article = currentTicket.articles.filter(
      (itm) => itm.id.toString() === participant[0].article_id.toString()
    );
    //delete old payment data
    currentTicket.payments.participants.forEach((participant_payment, i) => {
      if (participant_payment.user_index.toString() === participant_id.toString()) {
        currentTicket.articles.forEach((item, index) => {
          if (item.id.toString() === participant[0].article_id.toString()) {
            item["payed_amount"] = parseFloat(item.payed_amount) - parseFloat(participant_payment.amount);
          }
        });
        currentTicket.participants.forEach((client, i) => {
          if (client.user_index.toString() === participant_id.toString()) {
            client["status"] = null;
            client["payed_amount"] = 0;
          }
        });
      }
    });

    let participant_payments = currentTicket.payments.participants.filter(
      (itm) => itm.user_index.toString() !== participant_id.toString()
    );

    currentTicket.payments.participants = participant_payments;

    //payment participant
    let payment_type_list = this.state.payment_type_list.filter((itm) => itm.amount !== "");
  
    //sort the payment type list to handel the old payment before the new one
    payment_type_list = payment_type_list.sort(function(a, b) {
      return ((b.payed_user_index.length > 0) - (a.payed_user_index.length > 0));
    });

    let participant_payed_amount = 0;
    let participants = currentTicket.participants.map((client, i) => {
      if (client.user_index === participant_id) {
        payment_type_list.forEach(async (payment_type, index) => {
          let res = this.handleParticipantPayment(payment_type,client,currentTicket);
          payment_type.amount = res.rest;
          currentTicket = res.currentTicket;
        });
        client.payed_amount = cumulParticipantPayedAmountFromPaymentParticipant(currentTicket, client.user_index);
        participant_payed_amount = parseFloat(client.payed_amount);
        client.status = setParticipantStatus(currentTicket, client);
      }
      return client;
    });
    currentTicket.participants = participants;

    let articles = currentTicket.articles.map((article, index) => {
      if (article.id.toString() === participant[0].article_id.toString()) {
        article.payed_amount = (parseFloat(article.payed_amount) + parseFloat(participant_payed_amount)).toFixed(2);
      }
      return article;
    });

    currentTicket.articles = articles;

    // fix article status after changing payment in participants
    currentTicket.articles.forEach(
      (itm, i) => {
        itm.status = (itm.parent.is_activity === 1 && itm.child.is_res === 1) ? 
        setArticleWithoutNoteStatus(currentTicket, itm) : 
        setArticleStatus(currentTicket, itm);
      }
    );

    let articlePayment = currentTicket.payments.articles.filter(
      (itm) => itm.article_id.toString() !== article[0].article_id.toString()
    );
    articlePayment = [
      ...articlePayment,
      ...cumulPaymentArticlesFromParticipant(currentTicket, article[0].article_id)
    ];
    //vat for article payment ticket (vat exact)
    articlePayment.map((it, i) => {
      if (it.article_id === article[0].article_id) {
        it.amount_ht = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
          getHT(parseFloat(it.amount), article[0].vat.value);
        it.vat_amount = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? 0 :
          (parseFloat(it.amount) - parseFloat(getHT(parseFloat(it.amount), article[0].vat.value))).toFixed(3);
        it.vat_id = isSubscriptionPaymentType(this.props.paymentTypes, it.payment_type_id) ? null : article[0].vat_id;
      }
    });

    currentTicket.payments.participants = cumulSamePaymentParticipant(currentTicket.payments.participants);
    currentTicket.payments.articles = articlePayment;
    currentTicket.payments.types = cumulPaymentTypesFromArticle(currentTicket);
    currentTicket.payed_amount = cumulPayedAmountFromPaymentTypes(currentTicket);

    //save ticket
    if (currentTicket.id && !is_partial) {
      await this.props.updateTicket({ ...currentTicket });
    }

    //reset
    if (this.props.currentNote) {
      let currentNote = getCurrentNote(currentTicket, this.props.currentNote);
      if (currentNote && currentNote.status === 1) {
        this.props.updateCurrentNote(null);
      }
    }

    await this.setState({
      credit_id: null, credit_number: null, credit_value: null,
      advance_id: null, advance_number: null, advance_value: null,
    });
    this.props.check_credit_number(null);
    this.props.check_advance_number(null);
    if(this.props.pendingClassicSub){
      this.props.updatePendingClassicSub(null);
    }
    if (is_partial) {
      return currentTicket;
    }
    //keep rest of payment type to generate an avoir
    this.setState({ disabledRestorePaymentTypeList: false });
  };

  handleSubmit = debounce(async () => {
    this.props.articleToEditPayment && this.editArticlePayment(); //edit article payment
    this.props.participantToEditPayment && this.editParticipantPayment(); //edit participant payment
    !this.props.articleToEditPayment && !this.props.participantToEditPayment && this.paySelectedArticles(); //payment or partial payment
    await this.props.decrement_occ_nb(null);
  }, 1000);

  /**
   * return article (article/participant)
   */
  returnArticle = async () => {
    let totalAmountToPay = this.props.amountToPay;
    let currentTicket = { ...this.props.currentTicket };
    //destroy pending ticket (reservation case)
    if (currentTicket.reservation_id !== null) {
      this.props.setValueReservationTicket({ reservationTicket: null });
      if (currentTicket.id) {
        //destroy reservation
        if (currentTicket && currentTicket.reservation_id) {
          await this.props.destoryReservation({ id: currentTicket.reservation_id });
        }
        await this.props.destroyTicket(currentTicket.id);

      }
      currentTicket.id = null;
    }

    this.props.updateCurrentTicket(currentTicket);
    if (totalAmountToPay) {
      await this.props.createCredit(totalAmountToPay);
    }


    //rest
    this.props.article_to_pay([], 0, 0);
    this.props.select_family(getParentIdIsActivity(this.props.menusData));
    this.props.select_sub_family(0);
    this.props.select_hour_type(0);
    this.handleClose();
    this.props.caisse_redirection("home");
    this.props.updateCurrentTicket(null);
  };

  renderReturnArticleModal() {
    return (
      <ModalConfirm
        open={this.state.returnArticleModalOpen}
        onClose={this.handleClose}
        title={i18n.t("return-to-client")}
        message={<p>{i18n.t("generate-an-avoir")}</p>}
        okText={i18n.t("validate")}
        ok={() => this.returnArticle()}
        cancel={() => this.setState({ returnArticleModalOpen: false })}
      />
    );
  }

  /**
   *
   */

  getTotalAmountFromPaymentType() {
    const { payment_type_list } = this.state;
    return payment_type_list.reduce((value, it) => (value + parseFloat(it.amount !== "" && it.amount !== null ? it.amount : 0)), 0);
  }

  checkIsActivity = (articleSelected) => {
    let activities = [];
    if (!this.props.currentTicket) {
      return activities;
    }
    let { articles } = this.props.currentTicket;
    if (!articles) {
      return activities;
    }
    articleSelected.forEach(it => it = it);
    for (let i = 0; i < articles.length; i++) {
      if (articleSelected.includes(articles[i].id.toString()) && articles[i].parent.is_activity === 1 && articles[i].child.is_res === 1) {
        activities.push(articles[i].child.activity_id);
      }
    }
    return activities;
  };

  render() {
    let articleToPay = { ...this.props.articleToPay };
    if (this.props.articleToEditPayment) {
      let participantToEdit = this.props.currentTicket.participants.filter(itm => itm.article_id.toString() === this.props.articleToEditPayment && itm.note_index === this.props.currentNote);
      articleToPay[this.props.articleToEditPayment] = participantToEdit.map(it => it.user_index);
    }
    if (this.props.participantToEditPayment) {
      let participantToEdit = this.props.currentTicket.participants.filter(itm => itm.user_index === this.props.participantToEditPayment);
      if (participantToEdit.length > 0 && participantToEdit[0].note_index === this.props.currentNote) {
        articleToPay[participantToEdit[0].article_id] = [this.props.participantToEditPayment];
      }
    }
    let [amountToPay, totalsAmount] = getAmountToPay(this.props.currentTicket, articleToPay, this.props.currentNote, this.props.articleToPartialPayment);
    let restAmount = (amountToPay - this.getTotalAmountFromPaymentType()).toFixed(2);
    restAmount = parseFloat(restAmount);
    let currency = localStorage.getItem("currency");
    return (
      <>
        {this.props.appLoading ?
          <div className={"load-div"} style={{ width: "50vw", height: "50vh" }}>
            <Loader active={true} inline={"centered"}/>
          </div> :
          <>
            {this.state.showSubscriptionModal &&
            <SubscriptionModal open={this.state.showSubscriptionModal}
              //isActivity={this.state.subscriptionModalOption === 'subscription' ? isActivity : false}
                               player={this.state.player}
                               subscriptionModalOption={this.state.subscriptionModalOption}
                               selectedArticles={Object.keys(articleToPay)}
                               activities={this.checkIsActivity(Object.keys(articleToPay))}
                               input={this.state.payment_type_list[this.state.inputSubscriptionIndex]}
                               restAmount={(parseFloat(restAmount)).toFixed(2)}
                               onPay={this.payWithSubscriptions}
                               onChange={(v) => {
                                 let { payment_type_list, inputSubscriptionIndex } = this.state;
                                 payment_type_list[inputSubscriptionIndex].amount = v;
                                 this.setState({ payment_type_list: [...payment_type_list] });
                               }}
                               reload={this.props.reload}
                               onClose={() => this.setState({
                                 showSubscriptionModal: false,
                                 inputSubscriptionIndex: 0
                               })}
            />
            }
            {this.state.showCreditAccountModal &&
            <CreditAccountModal open={this.state.showCreditAccountModal}
                                type={"expense"}
                                input={this.state.payment_type_list[this.state.inputCreditAccountIndex]}
                                restAmount={parseFloat(restAmount).toFixed(2)}
                                onPay={this.payWithCreditAccount}
                                onChange={(v) => {
                                  let { payment_type_list, inputCreditAccountIndex } = this.state;
                                  payment_type_list[inputCreditAccountIndex].amount = v;
                                  this.setState({ payment_type_list: [...payment_type_list] });
                                }}
                                onClose={() => this.setState({
                                  showCreditAccountModal: false,
                                  inputCreditAccountIndex: 0
                                })}
            />
            }

            {this.renderSuccessPaymentModal()}
            {this.renderReturnArticleModal()}
            {this.renderPayWithCreditModal(restAmount, totalsAmount)}
            {this.renderPayWithAdvanceModal(restAmount, totalsAmount)}
            {this.renderConfirmCreditModal()}
            <h3 className="payment-title">
              {i18n.t("payment")}
              <span className="payment-sub-title">
                {i18n.t("rest_to_pay")} {restAmount > 0 ? (restAmount).toFixed(2) : 0}{currency}
              </span>
            </h3>
            <Grid columns={2} className="payment-content">
              <Grid.Row>
                <Grid.Column className="payment-content-left">
                  <Calculator
                    amount={parseFloat(this.state.restAmountToPay).toFixed(2)}
                    default={this.props.amountToPay}
                    onChange={(value) => this.setState({ restAmountToPay: value })}
                  />
                  <div className="center-btn">
                    <Button
                      className="solde-btn"
                      content={i18n.t("solde")}
                      onClick={() =>
                        this.setState({
                          restAmountToPay: parseFloat(this.props.restAmountToPay).toFixed(2)
                        })
                      }
                    />
                  </div>
                  {this.props.currentTicket && this.props.currentTicket.articles.length &&
                  this.props.currentTicket.articles[0].parent.is_advance !== 1 &&
                  <div className="payment-content-left-bottom">
                    <div className="payment-block-input return-article-block">
                          <span onClick={() => {
                            if (this.props.currentTicket.payments.types.length === 0) {
                              this.setState({ returnArticleModalOpen: true });
                            }
                          }}

                          >
                            {i18n.t("return-to-client")}
                          </span>
                    </div>
                    <div className="payment-block-input orange-block">
                        <span className="payment-block-input-left">
                          <Input
                            type="number"
                            name={"avoir"}
                            value={this.state.remainder}
                            readOnly={true}
                          />
                        </span>
                      <span className="payment-block-input-right">
                          <span>{i18n.t("return-amount")}</span>
                        </span>
                    </div>
                  </div>
                  }
                </Grid.Column>
                <Grid.Column className="payment-content-right">
                  <div className="payment-block-input-group">
                    {this.renderPaymentTypeInput()}
                  </div>

                  <br/>
                  <span className="payment-content-total">
                    {i18n.t("total")} {parseFloat(this.state.totalAmount).toFixed(2)}{currency}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className="center-btn">
              <Button
                loading={this.props.appLoading}
                className={"payment-btn-active payment-btn-green"}
                onClick={this.handleSubmit}
                disabled={this.disabledPaymentBtn(restAmount)}
                content={i18n.t("validate")}
              />
            </div>
          </>
        }

      </>
    );
  }



}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTicket: (data) => dispatch({ type: "UPDATE_CURRENT_TICKET_REQUEST", payload: data }),
  article_to_pay: (article, amount, rest) =>
    dispatch({ type: "ARTICLE_TO_PAY_REQUEST", payload: { article, amount, rest } }),
  article_to_edit_payment: (value) =>
    dispatch({ type: "ARTICLE_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  participant_to_edit_payment: (value) =>
    dispatch({ type: "PARTICIPANT_TO_EDIT_PAYMENT_REQUEST", payload: value }),
  caisse_redirection: (value) => dispatch({ type: "CAISSE_REDIRECTION_REQUEST", payload: { value } }),
  select_family: (value) => dispatch({ type: "SELECT_FAMILY_REQUEST", payload: value }),
  select_sub_family: (value) => dispatch({ type: "SELECT_SUB_FAMILY_REQUEST", payload: value }),
  select_hour_type: (value) => dispatch({ type: "SELECT_HOUR_TYPE_REQUEST", payload: value }),
  storeTicket: (data) => dispatch({ type: "STORE_TICKET_REQUEST", payload: data }),
  updateTicket: (data) => dispatch({ type: "UPDATE_TICKET_REQUEST", payload: data }),
  reload: (data) => dispatch(SubscribersCreators.indexRequestSubscriberDetail({ ...data })),
  check_credit_number: (id) => dispatch({ type: "CHECK_CREDIT_NUMBER_REQUEST", payload: id }),
  add_to_check_credit: (data) => dispatch({ type: "CHECK_CREDIT_NUMBER_RESPONSE", data }),
  decrement_occ_nb: (value) => dispatch({ type: "DECREMENT_OCC_NB_REQUEST", payload: value }),
  check_advance_number: (id , reservation_id = null) => dispatch({ type: "CHECK_ADVANCE_NUMBER_REQUEST", payload: {id,reservation_id} }),
  add_to_check_advance: (data) => dispatch({ type: "CHECK_ADVANCE_NUMBER_RESPONSE", data }),
  destroyTicket: (id) => dispatch({ type: "DESTROY_TICKET_REQUEST", payload: id }),
  setValueReservationTicket: (data) => dispatch({ type: "SET_VALUES", data }),
  destoryReservation: (payload) => dispatch({ type: "DESTROY_REQUEST_RESERVATION", payload }),
  updateCurrentNote: (id) => dispatch({ type: "CURRENT_NOTE_REQUEST", payload: id }),
  updatePendingClassicSub: (value) => dispatch({ type: "PENDING_CLASSIC_SUB_REQUEST", payload: value }),
});

const mapStateToProps = (state) => {
  let paymentTypes = state.paymentMethod.paymentMethods;
  paymentTypes.sort(function(a, b) {
    return a.id - b.id;
  });
  return {
    user: state.auth.user,
    paymentTypes: paymentTypes,
    articleToPay: state.caisse.articleToPay,
    amountToPay: state.caisse.amountToPay,
    restAmountToPay: state.caisse.restAmountToPay,
    articleToEditPayment: state.caisse.articleToEditPayment,
    participantToEditPayment: state.caisse.participantToEditPayment,
    articleToPartialPayment: state.caisse.articleToPartialPayment,
    participantToPartialPayment: state.caisse.participantToPartialPayment,
    currentTicket: state.caisse.currentTicket,
    successTicketStore: state.caisse.successTicketStore,
    successTicketUpdate: state.caisse.successTicketUpdate,
    credit: state.caisse.credit,
    creditChecked: state.caisse.creditChecked,
    advanceChecked: state.caisse.advanceChecked,
    menusData: state.caisse.menusData,
    appLoading: state.caisse.appLoading,
    currentNote: state.caisse.currentNote,
    pendingClassicSub : state.caisse.pendingClassicSub
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentContent));