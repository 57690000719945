import { useEffect } from 'react';
import { useSelector } from 'react-redux';

let bloop = new Audio(require('../assets/effect/Blop.mp3'));
bloop.volume = 0.4;

const useTitleNotification = () => {

  let notification = useSelector((state) => state.notification.totalUnread);

  const getTitleWithoutNotification = () => {
    let title = document.title.split(' ');
    if (title[0][0] === '(')
      title.shift();

    return title.join(' ');
  };
  const changeFavicon = () => {
    if (notification) {
      //  document.title = '(' + (notification < 10 ? '0' + notification : notification) + ') ' + getTitleWithoutNotification();
      // bloop.play().catch(e => {
      //   //
      // });
    } else {
     // document.title = getTitleWithoutNotification();
      bloop.pause();
    }
  };

  useEffect(() => {
    changeFavicon();
  }, [notification]);


};


export default useTitleNotification;