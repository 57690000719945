import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { imgUrl } from '@/helpers';

const TicketMenuItem = (props) => {
  switch (props.type) {
    case '1':
      return (
        <Button className={'ticket-menu-item btn-grey-on'}
          onClick={props.onClick}
          disabled={props.disabled || 
            (props.caisseRedirection.value === 'payment' ||
            props.caisseRedirection.value === 'discount' ||
            !(Object.entries(props.articleToPay).length))
          }
        >
          <div className="text-center btn-inline-icon">
            {props.name}
            <Image
              src={props.image ? imgUrl + '/payments/' + props.image : require('../../../assets/other_white.svg')}/>
          </div>

        </Button>
      );

    //case '2':
    default:
      return (
        <Button className="ticket-menu-item btn-white" 
          onClick={props.onClick}
          disabled={props.disabled}
        >
          <span>{props.name}</span>
          <Image className="ticket-menu-item-img" src={require('../../../assets/' + props.image)}/>
        </Button>
      );
  }

};


const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = state => {
  return {
    caisseRedirection: state.caisse.caisse_redirection,
    articleToPay: state.caisse.articleToPay,
    articleToEditPayment: state.caisse.articleToEditPayment,
    participantToEditPayment: state.caisse.participantToEditPayment,
    articleToPartialPayment: state.caisse.articleToPartialPayment,
    participantToPartialPayment: state.caisse.participantToPartialPayment,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TicketMenuItem));
