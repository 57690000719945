import React from 'react';
import './SubscriptionModal.css';
import { Button, Form, Grid, Icon, Image, Input, Loader, Modal, Pagination } from 'semantic-ui-react';
import { formatCodeCountry, normalDate, nullString, phoneNumber, randomstring } from "../../../helpers/formatting";
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import CustomInput from '../../CustomInput/CustomInput';
import moment from 'moment';
import { SIZE } from '../../../utils/mock';
import { DEFAULT_COUNTRY_CODE } from '../../../utils/countryCode';

class ChooseUserReloadModal extends React.Component {

  constructor(props) {
    super(props);
    let somePassword = randomstring();
    this.state = {
      modalContent: null,
      search_player: '',
      user: {
        id: '',
        firstName: '',
        lastName: '',
        mobile: '',
        country_code: DEFAULT_COUNTRY_CODE,
        email: '',
        avatar: '',
      },
      payment_type_list: [],
      teams: [],
      capitaine: [],
      password: somePassword,
      password_confirmation: somePassword,
      subscriber: null,
      subscriber_id: null,
      errors: {
        firstName: null,
        lastName: null,
        email: null,
      },
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  componentDidMount() {
    if (this.props.currentTicket && this.props.currentTicket.user_id) {
      this.props.editClient(this.props.currentTicket.user_id);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user !== this.props.user) {
      let user = this.state.user;
      user.id = this.props.user.id;
      user.firstName = this.props.user.firstName;
      user.lastName = this.props.user.lastName;
      user.email = this.props.user.email;
      user.mobile = this.props.user.mobile;
      user.country_code = this.props.user.country_code;
      user.avatar = this.props.user.avatar;
      this.setState({ user: user });

    }
    if (!this.props.loading && JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)) {
      let errors = {
        firstName: null,
        lastName: null,
        email: null,
      };
      for (let index in this.props.errors) {
        errors[index] = { content: this.props.errors[index][0] };
      }
      this.setState({ errors });
    }
  }

  onChangeHandle(event,eventMobile) {
    let user = { ...this.state.user };
    user[event.target.name] = event.target.value;
    if(eventMobile)
    user[eventMobile.target.name] = eventMobile.target.value;
    this.setState({
      user: user,
    });
  }

  onChangeSubscriber = (id, data) => {
    if (!id) {
      this.setState({ subscriber: null, subscriber_id: null });
    } else {
      this.setState({ subscriber: data, subscriber_id: id });
    }
  };

  renderAffectPlayerModal = () => {
    let currency = localStorage.getItem('currency');
    return (
      <Form>
        {this.props.editLoading ? (
          <Loader/>
        ) : (
          <>
            <Grid columns={2}>
              <Grid.Column className="player-input">
                <CustomInput name={'lastName'}
                             label={i18n.t('last_name')}
                             type={'text'}
                             value={this.state.user.lastName}
                             onChange={this.onChangeHandle}
                />
                <CustomInput name={'firstName'}
                             label={i18n.t('first_name')}
                             type={'text'}
                             value={this.state.user.firstName}
                             onChange={this.onChangeHandle}
                />
                <CustomInput name={'mobile'}
                             label={i18n.t('mobile')}
                             type={'phone'}
                             value={this.state.user.mobile}
                             countrycode={this.state.user.country_code}
                             onChange={this.onChangeHandle}
                />
                <CustomInput name={'email'}
                             label={i18n.t('email')}
                             type={'text'}
                             value={this.state.user.email}
                             onChange={this.onChangeHandle}
                />
              </Grid.Column>
              <Grid.Column className="player-input">
                <CustomInput name={'abonnement'}
                             get={'/subscribers'}
                             url={'/subscribers'}
                             param={
                               '&search_activities=[' +
                               this.props.activity_id.join(',') + ']' +
                               '&search_user=' + this.state.user.id +
                               '&is_reload=1&is_expired=0'
                             }
                             label={i18n.t('subscriptions')}
                             noData={i18n.t('empty_data')}
                             type={'custom-dropdown'}
                             render={(it) => (
                               <>
                                 <span>{it.subscription.name}</span>
                               </>
                             )}
                             value={this.state.subscriber_id}
                             refresh={this.props.reloadUpdated}
                             onInitial={(data) => {
                               data && this.setState({ subscriber: data });
                               if (
                                 data &&
                                 !this.state.user.id &&
                                 (!this.state.user.email || !this.state.user.mobile)
                               ) {
                                 let user = {
                                   id: data.user.id,
                                   firstName: data.user.firstName,
                                   lastName: data.user.lastName,
                                   mobile: data.user.mobile,
                                   country_code: data.user.country_code,
                                   email: data.user.email,
                                 };
                                 this.setState({
                                   user: user,
                                 });
                               }
                             }}
                             onChange={this.onChangeSubscriber}
                />
                <span className="amount-bloc">
                  {this.state.subscriber &&
                  this.state.subscriber.subscription.subscription_type.is_reload === 1 && (
                    <>
                        <span>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width={6} verticalAlign={'middle'}>
                                <label className="form-label">{i18n.t('solde')}</label>
                              </Grid.Column>
                              <Grid.Column width={10}>
                                <Input
                                  name="solde"
                                  type={'text'}
                                  value={
                                    this.state.subscriber
                                      ? parseFloat(this.state.subscriber.solde).toFixed(2) +
                                      ' ' +
                                      currency
                                      : ''
                                  }
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </span>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={6} verticalAlign={'middle'}>
                            <label className="form-label">{i18n.t('expire')}</label>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            <Input
                              name="expire"
                              type={'text'}
                              value={
                                this.state.subscriber ? this.state.subscriber.expiry_date : ''
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </>
                  )}
                </span>
                {this.state.subscriber &&
                moment(normalDate(this.state.subscriber.expiry_date)).diff(moment(), 'days') <
                0 && <span className={'error-msg'}>your subscription is expired !</span>}
                <br/>
              </Grid.Column>
            </Grid>
            <div className="group-btn-edit-player">
              <Button
                className="btn-select"
                content={i18n.t('select')}
                disabled={
                  (!this.state.subscriber_id ||
                    (this.state.subscriber && moment(normalDate(this.state.subscriber.expiry_date)).diff(moment(), 'days') < 0))
                }
                onClick={() => {
                  let currentTicket = this.props.currentTicket;
                  currentTicket.user_id = this.state.id;
                  currentTicket.user = this.state.user;
                  currentTicket.subscriber = this.state.subscriber;
                  this.props.updateCurrentTicket(currentTicket);
                  this.props.onChange(this.state.user);
                }}
              />
              <Button
                className="btn-select"
                content={i18n.t('cancel')}
                onClick={() => {
                  this.props.onClose();
                }}
              />
            </div>
          </>
        )}
      </Form>
    );
  };

  handleChangeSearch = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      modalContent: '',
    });

    this.props.searchClient(this.props.page, SIZE - 2, event.target.value, true, 1);
  };

  handlePaginationChange = (e, data) => {
    this.props.searchClient(data.activePage, SIZE - 2, this.state.search_player, true, 1);
  };

  renderSearchContent() {
    return this.props.users.map((item, index) => {
      return (
        <div key={index} className="player-row">
            <span>
              {nullString(item.firstName).toLowerCase()} {nullString(item.lastName).toUpperCase()}
            </span>
          <span>{formatCodeCountry(item.country_code)  + phoneNumber(item.mobile)}</span>
          <span>{item.email}</span>
          <span style={{ color: '#7EB5E5' }}>
              <Icon
                name="edit"
                onClick={() => {
                  this.setState({
                    modalContent: 'affect player', id: item.id,
                    subscriber: null,
                    subscriber_id: null,
                  });
                  this.props.editClient(item.id);
                }}
              />
            </span>
        </div>
      );
    });
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        size="large"
        className="modal-ticket modal-edit-player"
      >
        <Modal.Content className="modal-content">
          <h3 className="modal-content-header">Information client</h3>
          <div className="modal-content-body">
            <Button
              icon="close"
              color="red"
              className="modal-btn-close"
              circular
              size="tiny"
              onClick={this.props.onClose}
            />
            <div className="search-bloc">
              {this.state.modalContent !== 'add player' && (
                <>
                  <Input
                    name="search_player"
                    className="search-input-player"
                    icon="search"
                    placeholder="Recherche"
                    onChange={this.handleChangeSearch}
                    value={this.state.search_player}
                  />
                </>
              )}
            </div>
            <div className="player-informations">
              {this.state.modalContent === '' && this.state.search_player !== '' && (
                <div className="search-list">
                  {this.props.loading ? <Loader/> : this.renderSearchContent()}
                  <div className={'ticket-pagination'}>
                    <Pagination
                      activePage={this.props.page}
                      boundaryRange={1}
                      onPageChange={(e, data) => this.handlePaginationChange(e, data)}
                      size="mini"
                      siblingRange={1}
                      totalPages={this.props.last}
                      ellipsisItem={'...'}
                      firstItem={null}
                      lastItem={null}
                      prevItem={null}
                      nextItem={null}
                    />
                  </div>
                </div>
              )}
              {this.state.modalContent === 'affect player' && (
                <div style={{ margin: ' 50px 90px' }}>{this.renderAffectPlayerModal()}</div>
              )}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchClient: (page, size, search, search_subscribers, is_reload) => dispatch({
    type: 'SEARCH_REQUEST_USER', payload: { page, size, search, search_subscribers, is_reload },
  }),
  store: (data) => dispatch({ type: 'STORE_CLIENT_REQUEST_USER', payload: { ...data, redirection: false } }),
  editClient: (id) => dispatch({ type: 'EDIT_REQUEST_USER', payload: id }),
  updateCurrentTicket: (data) => dispatch({ type: 'UPDATE_CURRENT_TICKET_REQUEST', payload: data }),
});
const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    client: state.user.client,
    page: state.user.page,
    size: state.user.size,
    last: state.user.last,
    loading: state.user.loading,
    role: state.role,
    currentTicket: state.caisse.currentTicket,
    errors: state.user.error,
    user: state.user.selected,
    editLoading: state.user.editLoading,
    credit: state.caisse.credit,
    loadingDetail: state.subscriber.loadingDetail,
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ChooseUserReloadModal);



