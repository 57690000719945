import React from 'react';
import { Button, Modal , Table} from 'semantic-ui-react';


/**
 * Modal confirmation is used in Caisse for confirmation or showing  information
 *
 * */
const renderRow = (data)=>{
  let currency = localStorage.getItem('currency');
  let row=[],i=0;
  if(data && data.checkoutStats && data.checkoutStats.tickets){
        for(let attr in  data.checkoutStats.tickets){
          row.push(
            <Table.Row key={'ticket-'+i}>
              <Table.Cell>{attr}</Table.Cell>
              <Table.Cell>{Object.values(data.checkoutStats.tickets[attr]).reduce((t,v)=>v+t,0) + currency}</Table.Cell>
              <Table.Cell>1</Table.Cell>
            </Table.Row>
          );
        i++;
        }

        
    }

  return row;
    
};

const ModalListTicket = (props) => {
  return (
    <Modal open={props.open} onClose={props.onClose}
        size="small"
        closeIcon
    >
        <Modal.Header>Liste des tickets</Modal.Header>
        <Modal.Content className="modal-content">
            {/*<h2>{props.session && props.session.start_date}-{props.session && props.session.closing_date}</h2>*/}
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Reference</Table.HeaderCell>
                        <Table.HeaderCell>Montant payé</Table.HeaderCell>
                        <Table.HeaderCell>Nb d'articles</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {renderRow(props.session)}
                </Table.Body>
            </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading = {props.loading}
            content="Impression"
            labelPosition='right'
            icon='checkmark'
            onClick={props.print}
            positive
          />
        </Modal.Actions>
    </Modal>
  );
};

ModalListTicket.defaultProps={
  
};

  
export default ModalListTicket;

