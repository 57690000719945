import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { sessionMiddleware,checkoutMiddleware } from "./middleware";
import rootSaga, { rootReducer } from "@/store/ducks/index";


const sagaMiddleware = createSagaMiddleware(rootSaga);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sessionMiddleware,checkoutMiddleware, sagaMiddleware)));

export const initSaga = () => {
  sagaMiddleware.run(rootSaga);
};


export default store;