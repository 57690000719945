import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { getFormData, showFormData } from '../helpers/formDataHandler';

export default {
  childService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/checkoutChildren?complex_id=' + localStorage.getItem('complex_id')+
        (page ? '&page=' + page  :'')+
        (size ? '&size=' + size : '') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_parent ? '&search_parent=' + search.search_parent : '')
      );

      return result.data;
    },

    store: async (data) => {
      let { name, participants_nb, price_per_participant, activity_id, avatar: img, parent_id, vat_id ,is_res} = data;
      let newData = { name, participants_nb, price_per_participant, activity_id, img, parent_id, vat_id,is_res };

      let formData = getFormData(newData);
      formData.append('complex_id', localStorage.getItem('complex_id'));

      const result = await postMethod('/checkoutChildren', formData);
      return result.data;
    },

    edit: async (id) => {
      const result = await getMethod('/checkoutChildren/' + id);
      return result.data;
    },
    update: async (data) => {
      let { id, name, participants_nb, price_per_participant, activity_id, avatar: img, parent_id, vat_id,is_res } = data;
      let newData = { id, name, participants_nb, price_per_participant, activity_id, img, parent_id, vat_id,is_res };

      let formData = getFormData(newData);

      formData.append('complex_id', localStorage.getItem('complex_id'));
      formData.append('_method', 'put');

      showFormData(formData);

      const result = await postMethod('/checkoutChildren/' + data.id, formData);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/checkoutChildren/' + id);
      return result.data;
    },
  },
};
