import { deleteMethod, getMethod } from "@/helpers/index";
import { postMethod, putMethod } from "../helpers";

export default {
  index: async ({ page, size }) => {
    const result = await getMethod(
      "/planningConfigurations?page=" +
        page +
        (size ? "&size=" + size : "") +
        "&complex_id=" +
        localStorage.getItem("complex_id")
    );
    return result.data;
  },
  edit: async (id) => {
    const result = await getMethod("/planningConfigurations/" + id);
    return result.data;
  },
  store: async (data) => {
    const result = await postMethod("/planningConfigurations/", data);
    return result.data;
  },
  update: async (data) => {
    const result = await putMethod("/planningConfigurations/" + data.id, data);
    return result.data;
  },
  destroy: async (id) => {
    const result = await deleteMethod("/planningConfigurations/" + id);
    return result.data;
  },
};
