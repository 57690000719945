import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { getFormData, showFormData } from '../helpers/formDataHandler';
import { exportFile } from '@/helpers/exportDownloader';

export default {
  activityService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/activities?complex_id=' + localStorage.getItem('complex_id') +
        (page ? '&page=' + page : '') +
        (size ? '&size=' + size : '') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_terrain ? '&search_terrain=' + search.search_terrain : ''),
      );

      return result.data;
    },

    store: async (data) => {
      let {
        name,
        participants_nb,
        icon: img,
        is_default,
        is_match,
        is_sensor,
        is_booking,
        terrains,
        durations,
        activity_type_id,
        defaultDuration,
      } = data;
      let complex_id = localStorage.getItem('complex_id');
      let newDurations = {};

      for (let i = 0; i < durations.length; i++) {
        let obj = {
          [durations[i]]: {
            is_default: defaultDuration === durations[i] ? '1' : '0',
            complex_id,
          },
        };
        newDurations = { ...newDurations, ...obj };
      }
      let newData = {
        name,
        participants_nb,
        img,
        is_default: is_default ? '1' : '0',
        is_match: is_match ? '1' : '0',
        is_booking: is_booking ? '1' : '0',
        is_sensor: is_sensor ? '1' : '0',
        complex_id,
        activity_type_id,
        durations: newDurations,
      };
      let formData = getFormData(newData);

      for (let i = 0; i < terrains.length; i++) {
        formData.append('terrains[' + data.terrains[i] + ']', 'complex_id');
        formData.append('terrains[' + data.terrains[i] + '][complex_id]', complex_id);
      }


      const result = await postMethod('/activities', formData);
      return result.data;
    },

    edit: async (id) => {
      const result = await getMethod('/activities/' + id);
      return result.data;
    },
    
    update: async (data) => {
      let {
        id,
        name,
        participants_nb,
        icon: img,
        is_default,
        is_match,
        is_sensor,
        is_booking,
        terrains,
        durations,
        activity_type_id,
        defaultDuration,
      } = data;
      let complex_id = localStorage.getItem('complex_id');

      let newDurations = {};

      for (let i = 0; i < durations.length; i++) {
        let obj = {
          [durations[i]]: {
            is_default: defaultDuration === durations[i] ? '1' : '0',
            complex_id,
          },
        };
        newDurations = { ...newDurations, ...obj };
      }
      let newData = {
        id,
        name,
        participants_nb,
        img,
        is_default: is_default ? 1 : 0,
        is_match: is_match ? 1 : 0,
        is_sensor: is_sensor ? 1 : 0,
        is_booking: is_booking ? 1 : 0,
        complex_id,
        activity_type_id,
        durations: newDurations,
      };
      let formData = getFormData(newData);

      for (let i = 0; i < terrains.length; i++) {
        formData.append('terrains[' + data.terrains[i] + ']', 'complex_id');
        formData.append('terrains[' + data.terrains[i] + '][complex_id]', complex_id);
      }


      formData.append('_method', 'put');

      showFormData(formData);

      const result = await postMethod('/activities/' + data.id, formData);
      return result.data;
    },

    //destroy
    destroy: async (id) => {
      const result = await deleteMethod('/activities/' + id);
      return result.data;
    },

    //export
    exportExcel: async ({ search }) => {
      const response = await getMethod(
        '/activitiesExport?complex_id=' + localStorage.getItem('complex_id') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_terrain ? '&search_terrain=' + search.search_terrain : ''),
        {responseType: 'blob'}
      );

      exportFile(response, 'Activités');
    }

  }
};
