import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import CustomTable from '@/components/CustomTable/CustomTable';
import { connect } from 'react-redux';
import moment from 'moment';
import * as fr from 'moment/locale/fr';
import * as en from 'moment/locale/en-ca';
import { withRouter } from 'react-router-dom';
import { capitalize } from '../../helpers/formatting';

class ReservationTable extends React.Component {


  componentDidMount() {
    this.props.index(1, 10, { search_user: this.props.client });
  }

  renderRow() {
    const { t } = this.props;
    let localisation = localStorage.getItem('lang') === 'fr' ? fr : en;
    let row = this.props.data.map((item, index) => {
      return (
        <Table.Row key={item.id}>
          <Table.Cell className="td-text">#{index + this.props.from}</Table.Cell>
          <Table.Cell className="td-text">
            {capitalize(moment(item.date)
              .locale(localStorage.getItem('lang'), localisation)
              .format('dddd DD/MM/YYYY'))}
            <br/>
            {item.start_hour} {t('to')} {item.end_hour}
          </Table.Cell>
          <Table.Cell className="td-text">{item.terrain.name}</Table.Cell>
          <Table.Cell className="td-text">{item.activity.name}</Table.Cell>
          <Table.Cell className="td-text">{item.duration.duration}</Table.Cell>
          <Table.Cell className="td-text">{item.account}</Table.Cell>
          <Table.Cell className="td-text">{item.user.company}</Table.Cell>
          <Table.Cell className="td-text tools">
            <Button className={'round-btn'}
                    onClick={() => {
                      this.props.history.push({
                        pathname: '/reservation',
                        search: '?date=' + item.date + '&id=' + item.id,
                      });
                    }}
            >{t('details')}</Button>
          </Table.Cell>
        </Table.Row>
      );
    });
    return row;
  }

  render() {
    const { t, client } = this.props;
    return (
      <div className={'table-container'}>
        <CustomTable
          footer
          loading={this.props.loading}
          loadMore={this.props.index}
          size={10}
          page={this.props.page}
          search={{ search_user: client }}
          last={this.props.last}
          total={this.props.total}
          header={[
            'N°',
            t('date'),
            t('terrain'),
            t('activity'),
            t('duration'),
            t('amount'),
            t('society'),
            '',
          ]}
        >
          <Table.Body>{this.renderRow()}</Table.Body>
        </CustomTable>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  index: (page, size, search) =>
    dispatch({
      type: 'INDEX_REQUEST_RESERVATION',
      payload: { page, size, search },
    }),
});

const mapStateToProps = (state) => {
  return {
    data: state.reservation.reservations,
    page: state.reservation.page,
    size: state.reservation.size,
    total: state.reservation.total,
    last: state.reservation.last,
    from: state.reservation.from,
    loading: state.reservation.loading,
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReservationTable));
