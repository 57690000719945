import React, { useCallback, useEffect } from "react";
import { listeners } from "./useEcho";
import { Creators } from "../store/ducks/notification";
import { Creators as SessionCreators } from "../store/ducks/session";
import { Creators as reservationCreators } from "../store/ducks/reservation";
import { Creators as SupportCreators } from "../store/ducks/support";
import { useDispatch, useSelector } from "react-redux";
import { nullString } from "../helpers/formatting";
import { warningToast } from "../helpers/customToast";
import history from "../helpers/history";

const useListener = ({ user, echo, selected }) => {
  const dispatch = useDispatch();

  const addToNotification = useCallback(
    (data) => dispatch(Creators.addNotificationResponse(data)),
    [dispatch]
  );

  const resetSession = useCallback(
    () => dispatch(SessionCreators.resetSessionRequest()),
    [dispatch]
  );

  const updateSession = useCallback(
    (data) => dispatch(SessionCreators.sessionResponse(data)),
    [dispatch]
  );

  const addReservation = useCallback(
    (data) => dispatch(reservationCreators.addReservationRequest(data)),
    [dispatch]
  );

  const updateReservation = useCallback(
    (data) => dispatch(reservationCreators.updateReservationRequest(data)),
    [dispatch]
  );

  const deleteReservation = useCallback(
    (data) => dispatch(reservationCreators.deleteReservationRequest(data)),
    [dispatch]
  );

  const refreshSupportMessages = useCallback(
    (data) => dispatch(SupportCreators.refreshResponseSupportMessages(data)),
    [dispatch]
  );

  const handleNotificationEvent = (event) => {
    let data = event;
    if (data.data && data.data?.app_name !== "player") {
      let myNotification = {
        data: JSON.stringify(data),
      };
      addToNotification([myNotification]);
    }
  };

  const handleClosureEvent = (event) => {
    //close session when closure status === 1 === closure Z
    if (event?.data && nullString(event.data.status).toString() === "1") {
      resetSession();
      let userOfClosure = event.data.checkout_session?.user;
      if (user && user.id !== userOfClosure.id)
        warningToast(
          `${userOfClosure.firstName} ${userOfClosure.firstName} a éffectué un clôture Z`
        );
    }
  };

  const handleCheckoutSessionEvent = (event) => {
    if (event.data) {
      updateSession({
        ComplexSession: event.data.parent,
      });
      if (event.data.user_id === user.id) {
        updateSession({
          session: event.data,
        });
        localStorage.setItem("session_id", event.data.id);
      }
    }
  };

  const handleReservation = (event, name) => {
    // if url doesn't contain /reservation || /caisse || /leisure
    if (
      history.location.pathname !== "/caisse" &&
      history.location.pathname !== "/reservation" &&
      history.location.pathname !== "/leisure"
    ) {
      return;
    }

    // if we are in the leisure page we should only accept reservation with is_leisure = 1
    if (
      history.location.pathname === "/leisure" &&
      event.reservation &&
      event.reservation.is_leisure === 0
    ) {
      return;
    }

    if (
      name === ".StoreReservationEvent" ||
      name === ".RecoverReservationEvent"
    ) {
      addReservation(event.reservation);
    }

    if (name === ".UpdateReservationEvent") {
      updateReservation(event.reservation);
    }

    if (name === ".DestroyReservationEvent") {
      deleteReservation(event.reservation);
    }
  };

  const handleSupportMessage = (event, name) => {
    //conversation realTime
    if(event.data.supportTicket){
      refreshSupportMessages(event.data)
    }

    const inSuportPage = history.location.pathname.includes("/support/"+ event.data.support_ticket_id +"/details");
    // if url doesn't contain /support
    if (inSuportPage) {
      return;
    }

    handleNotificationEvent(event);
  };

  const eventHandler = (event, name) => {
    switch (name) {
      case "UpdateReservationEvent":
      case "RemoveReservationEvent":
      case "CreateReservationEvent":
        handleNotificationEvent(event);
        break;
      case "CheckoutSessionEvent":
        handleCheckoutSessionEvent(event);
        break;
      case "ClosureEvent":
        handleClosureEvent(event);
        break;
      case ".StoreReservationEvent":
      case ".DestroyReservationEvent":
      case ".UpdateReservationEvent":
      case ".RecoverReservationEvent":
        handleReservation(event, name);
        break;
      case "SendSupportMessageEvent":
        handleSupportMessage(event, name);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // get notification token
    // here we set up our connect to room
    if (echo !== null && user !== null && selected) {
      let complex_id = localStorage.getItem("complex_id");
      // setting up the channels
      let ids = listeners(user.id, complex_id);
      ids.forEach((it) => {
        echo.channel(it.link).listen(it.name, (event) => {
          //Handle event
          eventHandler(event, it.name);
        });
      });
    }
  }, [echo, user, selected]);
};

export default useListener;
