import React from 'react';
import ContentMenuItem from './ContentMenuItem';
import '../../CustomCaisse.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ContentMenu extends React.Component {

  componentDidMount() {
  }

  renderArticle() {
    if (this.props.menusData) {
      let selectedFamily = this.props.menusData.filter(itm=> itm.id === this.props.selectFamily);
      if(selectedFamily){
        let articleList = selectedFamily[0].children.map(
          (item, index) => {
            if (item.id == this.props.selectSubFamily) {
              //is activity
              if (selectedFamily[0].is_activity === 1 && item.is_res === 1 ) {
                let hourType = item.hourTypes.map((it, i) => {
                  if (it.id == this.props.selectHourType) {
                    let articles = it.articles.map((article, ind) => {
                      return <ContentMenuItem key={ind} article={article}/>;
                    });
                    return articles;
                  }
                });
                return hourType;
              }
              //is not activity
              else {
                let articles = item.articles.map((article, i) => {
                  return <ContentMenuItem key={i} article={article}/>;
                });
                return articles;
              }
            }
          },
        );

        return articleList;
      }
    }
  }


  render() {
    let { selectFamily, menusData, selectSubFamily } = this.props;
    let data = null;
    let filterMenu = null;
    let childSelected = null;
    if (menusData !== null) {
      filterMenu = menusData.filter(it => it.id === selectFamily);
      if (filterMenu.length > 0) {
        data = filterMenu[0].children.filter(it => it.id === selectSubFamily);
      }
    }
    if (data && Array.isArray(data) && data.length > 0) childSelected = data[0];

    return (
      <div
        className={
          (filterMenu && filterMenu.length > 0 && childSelected && childSelected.is_res === 1)
            ? 'caisse-main-menu-middle-activity'
            : 'caisse-main-menu-middle'
        }
      >
        <div className="content-menu">
          {this.props.selectSubFamily !== 0 && this.renderArticle()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => {
  return {
    menusData: state.caisse.menusData,
    selectFamily: state.caisse.selectFamily,
    selectSubFamily: state.caisse.selectSubFamily,
    selectHourType: state.caisse.selectHourType,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentMenu));
