import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import teamService from '../../services/teamService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingTeam: ['data'],

  indexRequestTeam: ['payload'],
  indexResponseTeam: ['data'],

  storeRequestTeam: ['payload'],
  storeResponseTeam: ['data'],

  editRequestTeam: ['id'],
  editResponseTeam: ['data'],

  updateRequestTeam: ['payload'],
  updateResponseTeam: ['data'],

  compositionRequestTeam: ['payload'],
  compositionResponseTeam: ['data'],

  destroyRequestTeam: ['id'],
  destroyResponseTeam: ['data'],
});

// Initial state
const INITIAL_STATE = {
  teams: [],
  team: null,
  last: 0,
  page: 0,
  total: 0,
  size: 10,
  from: 1,
  loading: false,
  editLoading: false,
  compositionLoading : false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successComposition: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingTeam({ loading: true }));
    const data = yield call(teamService.teamService.index, action.payload);
    yield put(Creators.indexResponseTeam(data));
    yield put(Creators.loadingTeam({ loading: false }));
  } catch (e) {
    //
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingTeam({ loading: true }));
    const data = yield call(teamService.teamService.store, action.payload);
    yield put(Creators.storeResponseTeam(data));
    yield put(Creators.loadingTeam({ loading: false }));
    yield history.push('/configuration/teams');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingTeam({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingTeam({ editLoading: true }));
    const data = yield call(teamService.teamService.edit, action.payload);
    yield put(Creators.editResponseTeam(data));
    yield put(Creators.loadingTeam({ editLoading: false }));
  } catch (e) {
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingTeam({ loading: true, successUpdate: false}));
    const data = yield call(teamService.teamService.update, action.payload);
    yield put(Creators.updateResponseTeam(data));
    yield put(Creators.loadingTeam({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/configuration/teams');
  } catch (e) {
    yield put(Creators.loadingTeam({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//update
function* composition(action) {
  try {
    yield put(Creators.loadingTeam({ compositionLoading: true , successComposition:false }));
    const data = yield call(teamService.teamService.update, action.payload);
    yield put(Creators.compositionResponseTeam(data));
    yield put(Creators.loadingTeam({ compositionLoading: false }));
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(Creators.loadingTeam({ compositionLoading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingTeam({ deleteLoading: true }));
    const data = yield call(teamService.teamService.destroy, action.payload);
    yield put(Creators.destroyResponseTeam(data));
    yield put(Creators.loadingTeam({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_TEAM, index);
  yield takeLatest(Types.STORE_REQUEST_TEAM, store);
  yield takeLatest(Types.EDIT_REQUEST_TEAM, edit);
  yield takeLatest(Types.UPDATE_REQUEST_TEAM, update);
  yield takeLatest(Types.COMPOSITION_REQUEST_TEAM, composition);
  yield takeLatest(Types.DESTROY_REQUEST_TEAM, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    teams: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    team: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//update
const composition_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successComposition: true,
  };
};


//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};
const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};
// Reducer
export const teamReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_TEAM]: index_response,
  [Types.STORE_RESPONSE_TEAM]: store_response,
  [Types.EDIT_RESPONSE_TEAM]: edit_response,
  [Types.UPDATE_RESPONSE_TEAM]: update_response,
  [Types.COMPOSITION_RESPONSE_TEAM]: composition_response,
  [Types.DESTROY_REQUEST_TEAM]: destroy_request,
  [Types.DESTROY_RESPONSE_TEAM]: destroy_response,
  [Types.LOADING_TEAM]: loading,
});
