import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';
import { exportFile } from '@/helpers/exportDownloader';

export default {
  terrainService: {
    //index
    index: async ({ size, page = '', search }) => {
      const result = await getMethod(
        '/terrains?page=' +
        page +
        (size ? '&size=' + size : '') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        (search && search.search_activity ? '&search_activity=' + search.search_activity : '') +
        '&complex_id=' +
        localStorage.getItem('complex_id'),
      );
      return result.data;
    },

    //store
    store: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      newData.show_global = newData.show_global ? 1 : 0;
      
      const result = await postMethod('/terrains', newData);
      return result.data;
    },

    //edit
    edit: async (id) => {
      const result = await getMethod('/terrains/' + id);
      return result.data;
    },

    //update
    update: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      newData.show_global = newData.show_global ? 1 : 0;

      const result = await putMethod('/terrains/' + data.id, newData);
      return result.data;
    },

    //destroy
    destroy: async (id) => {
      const result = await deleteMethod('/terrains/' + id);
      return result.data;
    },

    //export
    exportExcel: async ({ search }) => {
      const response = await getMethod(
        '/terrainsExport?complex_id=' + localStorage.getItem('complex_id') +
        (search && search.search_name ? '&search_name=' + search.search_name : ''),
        {responseType: 'blob'}
      );

      exportFile(response, 'Terrains');
    }
  },
};
