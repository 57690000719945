import { deleteMethod, getMethod, postMethod } from '@/helpers/index';
import { getFormData } from '../helpers/formDataHandler';
import { exportFile } from '@/helpers/exportDownloader';

export default {
  activityTypesService: {
    //index
    index: async ({ page='', size, search }) => {
      const result = await getMethod(
        '/activityTypes?page=' + page +
          (size ? '&size=' + size :'')+
          (search && search.search_name ? '&search_name=' + search.search_name : '') +
          ('&is_booking=[0,1]') ,
      );
      return result.data;
    },

    //store
    store: async (data) => {
      let newData = {
        ...data,
        is_booking:data.is_booking ? 1 : 0
      };
      newData.img = newData.icon;
      newData.background_img = newData.background;

      delete newData.icon;
      delete newData.imgPreview;
      delete newData.background;
      delete newData.backgroundPreview;

      let fd = getFormData(newData);
      const result = await postMethod('/activityTypes', fd);
      return result.data;
    },

    //edit
    edit: async (id) => {
      const result = await getMethod('/activityTypes/' + id);
      return result.data;
    },

    //update
    update: async (data) => {
      let newData = {
        ...data,
        is_booking:data.is_booking ? 1 : 0
      };
      newData.img = newData.icon;
      newData.background_img = newData.background;

      delete newData.icon;
      delete newData.imgPreview;
      delete newData.background;
      delete newData.backgroundPreview;

      let fd = getFormData(newData);
      fd.append('_method', 'put');
      const result = await postMethod('/activityTypes/' + data.id, fd);
      return result.data;
    },

    //destroy
    destroy: async (id) => {
      const result = await deleteMethod('/activityTypes/' + id);
      return result.data;
    },

    //export
    exportExcel: async ({ search }) => {
      const response = await getMethod(
        '/activityTypesExport?complex_id=' + localStorage.getItem('complex_id') +
        (search && search.search_name ? '&search_name=' + search.search_name : ''),
        {responseType: 'blob'}
      );

      exportFile(response, 'Types_activité');
    }

  },
};
