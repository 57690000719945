import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import subscriptionService from '@/services/subscriptionService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingSubscription: ['payload'],
  loadingSubscriptionDetail: ['payload'],

  indexRequestSubscription: ['payload'],
  indexResponseSubscription: ['data'],

  indexRequestSubscriptionDetail: ['payload'],
  indexResponseSubscriptionDetail: ['data'],

  storeRequestSubscription: ['payload'],
  storeResponseSubscription: ['data'],

  storeRequestSubscriptionDetail: ['payload'],
  storeResponseSubscriptionDetail: ['data'],

  editRequestSubscription: ['id'],
  editResponseSubscription: ['data'],

  updateRequestSubscription: ['payload'],
  updateResponseSubscription: ['data'],

  destroyRequestSubscription: ['id'],
  destroyResponseSubscription: ['data'],
});

// Initial state
const INITIAL_STATE = {
  subscriptions: [],
  subscription: null,
  details: [],
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  lastDetail: 0,
  pageDetail: 0,
  sizeDetail: 4,
  totalDetail: 0,
  fromDetail: 1,
  loading: false,
  loadingDetail: false,
  editLoading: false,
  errors: null,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingSubscription({ loading: true }));
    const data = yield call(subscriptionService.subscriptionService.index, action.payload);
    yield put(Creators.indexResponseSubscription(data));
    yield put(Creators.loadingSubscription({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingSubscription({ loading: false }));
  }
}

//index detail
function* indexDetail(action) {
  try {
    yield put(Creators.loadingSubscriptionDetail({ loadingDetail: true }));
    const data = yield call(subscriptionService.subscriptionService.indexDetail, action.payload);
    yield put(Creators.indexResponseSubscriptionDetail(data));
    yield put(Creators.loadingSubscriptionDetail({ loadingDetail: false }));
  } catch (e) {
    yield put(Creators.loadingSubscriptionDetail({ loadingDetail: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingSubscription({ loading: true }));
    const data = yield call(subscriptionService.subscriptionService.store, action.payload);
    //yield put(Creators.storeResponseSubscription(data));
    yield put(Creators.loadingSubscription({ loading: false }));
    yield history.push('/subscription');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingSubscription({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//store subscription detail
function* storeDetail(action) {
  try {
    yield put(Creators.loadingSubscriptionDetail({ loadingDetail: true }));
    const data = yield call(subscriptionService.subscriptionService.storeDetail, action.payload);
    yield put(Creators.storeResponseSubscriptionDetail(data));
    yield put(Creators.loadingSubscriptionDetail({ loadingDetail: false }));
    yield history.push('/subscription');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(
      Creators.loadingSubscriptionDetail({
        loadingDetail: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_store'));
  }
}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingSubscription({ editLoading: true }));
    const data = yield call(subscriptionService.subscriptionService.edit, action.payload);
    yield put(Creators.editResponseSubscription(data));
    yield put(Creators.loadingSubscription({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingSubscription({ editLoading: false }));
    history.push('/error-404');
  }
}

//update
function* update(action) {
  try {
    yield put(Creators.loadingSubscription({ loading: true }));
    const data = yield call(subscriptionService.subscriptionService.update, action.payload);
    yield put(Creators.updateResponseSubscription(data));
    yield put(Creators.loadingSubscription({ loading: false }));
    validToast(i18n.t('successful_update'));
    yield history.push('/subscription');
  } catch (e) {
    yield put(
      Creators.loadingSubscription({
        loading: false,
        errors: e.response.data.errors,
      }),
    );
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingSubscription({ deleteLoading: true }));
    const data = yield call(subscriptionService.subscriptionService.destroy, action.payload);
    yield put(Creators.destroyResponseSubscription(data));
    yield put(Creators.loadingSubscription({ deleteLoading: false }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    yield put(Creators.loadingSubscription({ deleteLoading: false }));
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_SUBSCRIPTION, index);
  yield takeLatest(Types.INDEX_REQUEST_SUBSCRIPTION_DETAIL, indexDetail);
  yield takeLatest(Types.STORE_REQUEST_SUBSCRIPTION, store);
  yield takeLatest(Types.STORE_REQUEST_SUBSCRIPTION_DETAIL, storeDetail);
  yield takeLatest(Types.EDIT_REQUEST_SUBSCRIPTION, edit);
  yield takeLatest(Types.UPDATE_REQUEST_SUBSCRIPTION, update);
  yield takeLatest(Types.DESTROY_REQUEST_SUBSCRIPTION, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    subscriptions: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//index detail
const index_detail_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    details: [...action.data.data],
    lastDetail: action.data.last_page,
    pageDetail: action.data.current_page,
    totalDetail: action.data.total,
    sizeDetail: action.data.per_page,
    fromDetail: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//store detail
const store_detail_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    subscription: action.data.data,
  };
};
//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: true,
  };
};

const laoding = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const laoding_detail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

// Reducer
export const subscriptionReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_SUBSCRIPTION]: index_response,
  [Types.INDEX_RESPONSE_SUBSCRIPTION_DETAIL]: index_detail_response,
  [Types.STORE_RESPONSE_SUBSCRIPTION]: store_response,
  [Types.STORE_RESPONSE_SUBSCRIPTION_DETAIL]: store_detail_response,
  [Types.EDIT_RESPONSE_SUBSCRIPTION]: edit_response,
  [Types.UPDATE_RESPONSE_SUBSCRIPTION]: update_response,
  [Types.DESTROY_RESPONSE_SUBSCRIPTION]: destroy_response,
  [Types.LOADING_SUBSCRIPTION]: laoding,
  [Types.LOADING_SUBSCRIPTION_DETAIL]: laoding_detail,
});
