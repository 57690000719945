import React from 'react';
import CustomTable from '@/components/CustomTable/CustomTable';
import CustomInput from '@/components/CustomInput/CustomInput';
import { Button, Dropdown, Form, Icon, Image, Modal, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Creators } from '@/store/ducks/subscriber';
import { imgUrl, splash, url } from '../../helpers';
import { formattingDate, nullStringForTable, phoneNumber } from '../../helpers/formatting';
import { getCreditStatus } from '../../helpers/SubscriberFormatting';
import history from '../../helpers/history';
import '../../pages/ClientsPage/ClientsPage.css';
import '../../pages/SubscribersPage/SubscribersPage.css';

class SubscriptionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkReload: false,
      modalOpen: false,
      modalDeadlineOpen: false,
      item: null,
      date: '',
      label: '',
      payment_type_id: '',
      subscriber_id: '',
      credit_bonus_id: '',
      credit: '',
      bonus: '',
      deletedId: null,
      subscription_credit_id: '',
      search_start_date: '',
      search_user: '',
      errors: {
        payment_type_id: null,
        credit: null,
        date: null,
      },
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onSearchHandle = this.onSearchHandle.bind(this);
  }

  componentDidMount() {
    this.setState({ search_user: this.props.client });
    this.props.index(this.props.page, this.props.size, { search_user: this.props.client });
  }

  handleOpen = (item) => {
    if (!this.props.session || this.props.session.status.toString() === '1') {
      this.toggleSessionModal(true);
      return;
    }
    this.setState({ item: item });
    item.subscription.subscription_type.is_reload === 1 ? this.setState({ modalOpen: true }) : this.setState({ modalDeadlineOpen: true });
  };

  handleDropdownChange = (e, d) => {
    this.setState({ [d.name]: d.value });
  };

  handleClose = () =>
    this.setState({
      modalOpen: false,
      modalDeadlineOpen: false,
      item: null,
      date: '',
      label: '',
      payment_type_id: '',
      subscriber_id: '',
      credit: '',
      subscription_credit_id: '',
      bonus: '',
      errors: {
        payment_type_id: null,
        credit: null,
        date: null,
      },
    });

  handleDelete = (id) => {
    this.props.destroy(id);
  };

  checkReloadModal = () => {
    let { date, subscription_credit_id } = this.state;
    return date === '' || subscription_credit_id === '';
  };

  componentDidUpdate(prevProps) {
    if (this.props.updated !== prevProps.updated) {
      this.handleClose();
      this.props.index(this.props.page, this.props.size, this.state);
    }
  }

  onChangeHandle(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onSearchHandle(event) {
    event.preventDefault();
    this.props.index(1, this.props.size, this.state);
  }

  toggleSessionModal = (modalCheckoutOpen) => {
    this.setState({
      modalCheckoutOpen,
    });
  };

  renderDeleteModal() {
    const { t } = this.props;
    return (
      <Modal open={this.state.modalOpen} onClose={this.handleClose} size="tiny">
        <Modal.Header>{t('confirm-delete')}</Modal.Header>
        <Modal.Content>
          <p>{t('msg-confirm-delete-client')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t('cancel')} onClick={this.handleClose}/>
          <Button
            content={t('confirm')}
            className="negative"
            loading={this.props.deleteLoading}
            onClick={() => this.handleDelete(this.state.deletedId)}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  renderNoCheckoutModal() {
    const { t } = this.props;
    return (
      <Modal open={this.state.modalCheckoutOpen} onClose={this.handleClose} size="tiny">
        <Modal.Header>{t('no-checkout-session')}</Modal.Header>
        <Modal.Content>
          <p>{t('msg-checkout-open-session')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t('cancel')} onClick={this.handleClose}/>
          <Button
            content={t('open-session')}
            className="negative"
            onClick={() => {
              history.push('/checkout');
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  renderModal() {
    const { t } = this.props;
    let { item, checkReload } = this.state;
    let currency = localStorage.getItem('currency');
    let creditOptions = [];

    if (item && item.subscription)
      creditOptions = item.subscription.credit_bonuses.map((it) => {
        return {
          text:
            'credit: ' +
            it.credit +
            currency +
            ' + ' +
            (it.bonus ? 'bonus:' + it.bonus + currency : ''),
          key: it.id,
          value: it.id,
        };
      });

    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="large"
        className={'modal-reload-container'}
      >
        <Modal.Header>
          <div className={'modal-reload-header'}>
            <Button className={'modal-close-btn'} onClick={this.handleClose}>
              X
            </Button>
            <div className={'modal-reload-complex'}>
              <img src={require('../../assets/park.svg')} alt={'complex'}/>

              <span className={'modal-reload-complex-name'}>
                <h3>{item && item.subscription.name}</h3>
                <h6>N° {item && item.id}</h6>
                <span className={'modal-reload-credit'}>
                  <span
                    className={'round-credit'}
                    style={{
                      backgroundColor:
                        item && getCreditStatus(item.subscription, item.solde, item.expiry_date),
                    }}
                  />
                  <h3>{item && item.solde} {t('credits')}</h3>
                </span>
              </span>
            </div>
            <div className={'modal-reload-user'}>
              <img
                src={item && item.user.avatar ? url + '/uploads/users/' + item.user.avatar : splash}
                alt={'user'}
              />
              <span className={'modals-reload-name'}>
                {item && item.user.firstName + ' ' + item.user.lastName}
              </span>
            </div>
            <div className={'payment-date-container'}>
              <div className={'modal-reload-date'}>
                      <span className={'modal-reload-slug'}>
                          {t('start_date')}
                      </span>
                <span className={'modal-reload-date'}>
                          {item && item.start_date}
                      </span>
              </div>

              <div className={'modal-reload-date'}>
                      <span className={'modal-reload-slug'}>
                          {t('expiry_date')}
                      </span>
                <span className={'modal-reload-date'}
                      style={{ color: item && getCreditStatus(item.subscription, item.solde, item.expiry_date) }}>
                          {item && item.expiry_date}
                      </span>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Content className={'modal-reload-main'}>
          <h3>{t('reload')}</h3>
          <Form>
            <CustomInput
              type={'date'}
              className={'input-bg'}
              name={'date'}
              label={t('date')}
              value={this.state.date}
              error={checkReload && this.state.date === ''}
              onChange={this.onChangeHandle}
            />
            <CustomInput
              className="input-bg"
              name={'label'}
              label={t('reload')}
              mode="inline"
              value={this.state.label}
              onChange={this.onChangeHandle}
            />

            <CustomInput
              className="input-bg"
              name={'subscription_credit_id'}
              error={
                checkReload &&
                (this.state.subscription_credit_id === '' ||
                  this.state.subscription_credit_id === null)
              }
              type="dropdown"
              compact
              search
              selection
              clearable
              options={creditOptions}
              placeholder={t('select-montant')}
              label={t('montant')}
              mode="inline"
              value={this.state.subscription_credit_id}
              onChange={(e, d) => this.handleDropdownChange(e, d)}
            />

            <Button
              loading={this.props.loadingDetail}
              className={'modal-btn-save'}
              onClick={() => {
                let valid = this.checkReloadModal();
                this.setState({ checkReload: valid });
                if (!valid) {
                  let selected = item.subscription.credit_bonuses.filter(
                    (it) => it.id === this.state.subscription_credit_id,
                  );
                  let credit_bonus = { bonus: 0, credit: 0 };
                  if (selected.length > 0)
                    credit_bonus = {
                      credit: selected[0].credit,
                      bonus: selected[0].bonus,
                      id: selected[0].id
                    };

                  let data = {
                    date: formattingDate(this.state.date),
                    label: this.state.label,
                    credit: credit_bonus.credit,
                    bonus: credit_bonus.bonus,
                    credit_bonus_id: credit_bonus.id,
                    payment_type_id: item.payment_type.id,
                    subscriber_id: item.id,
                    checkout_session_id: this.props.session.id,
                  };

                  this.props.reload(data);
                }
              }}
            >
              {t('save')}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }

  renderDeadlinesModal() {
    const { t } = this.props;
    let { item } = this.state;
    let currency = localStorage.getItem('currency');
    let creditOptions = [];

    return (
      <Modal
        open={this.state.modalDeadlineOpen}
        onClose={this.handleClose}
        size="large"
        className={'modal-reload-container'}
      >
        <Modal.Header>
          <div className={'modal-reload-header'}>
            <Button className={'modal-close-btn'} onClick={this.handleClose}>
              X
            </Button>
            <div className={'modal-reload-complex'}>
              <img src={require('../../assets/park.svg')} alt={'complex'}/>

              <span className={'modal-reload-complex-name'}>
                <h3>{item && item.subscription.name}</h3>
                <h6>N° {item && item.id}</h6>
              </span>
            </div>
            <div className={'modal-reload-user'}>
              <img
                src={item && item.user.avatar ? url + '/uploads/users/' + item.user.avatar : splash}
                alt={'user'}
              />
              <span className={'modals-reload-name'}>
                {item && item.user.firstName + ' ' + item.user.lastName}
              </span>
            </div>
            <div className={'payment-date-container'}>
              <div className={'modal-reload-date'}>
                      <span className={'modal-reload-slug'}>
                          {t('start_date')}
                      </span>
                <span className={'modal-reload-date'}>
                          {item && item.start_date}
                      </span>
              </div>

              <div className={'modal-reload-date'}>
                      <span className={'modal-reload-slug'}>
                          {t('expiry_date')}
                      </span>
                <span className={'modal-reload-date'}
                      style={{ color: item && getCreditStatus(item.subscription, item.solde, item.expiry_date) }}>
                          {item && item.expiry_date}
                      </span>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Content>
          <h2 style={{ textAlign: 'center' }}>{t('deadlines')}</h2>
          <div className={'table-container'}>
            <Table fixed={true} className={'no-overflow'}>
              <Table.Header>
                <Table.Row className={'payment-table-header'}>
                  <Table.HeaderCell/>
                  <Table.HeaderCell>
                    <div className={'payment-cell'}>{t('date')}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className={'payment-cell'}>{t('amount')}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className={'payment-cell'}>{t('payed')}</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell/>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {item && item.deadlines && item.deadlines.map((it, i) => {
                  return (
                    <Table.Row className={'payment-row'}>
                      <Table.Cell className={'payement-index'}>
                        <div>{i + 1}</div>
                      </Table.Cell>
                      <Table.Cell>
                        <div>{it.date}</div>
                      </Table.Cell>
                      <Table.Cell>
                        <div>{it.amount}</div>
                      </Table.Cell>
                      <Table.Cell>
                        <div>
                          <span className={'round-credit'}
                                style={{ background: it.payed === 0 ? '#FF1818' : '#72F286' }}/>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          style={{
                            borderRadius: '20px',
                            backgroundColor: '#72F286',
                            color: 'white',
                            fontSize: 12,
                          }}
                          disabled={it.payed === 0 ? '' : 'true'}
                          //loading='true'
                          onClick={() => {
                            let data = {
                              id: it.id,
                              payed: 1,
                            };
                            this.props.updateDeadline(data);
                          }
                          }
                        >
                          {t('pay')}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    const { t, subscribers } = this.props;
    return (
      <div>
        <div className={'table-container'}>
          <CustomTable
            loading={this.props.loading}
            loadMore={this.props.index}
            size={10}
            page={this.props.page}
            last={this.props.last}
            total={this.props.total}
            search={this.state}
            header={this.props.showClient ? [
                'N°',
                '',
                t('last_name'),
                t('first_name'),
                t('email'),
                t('mobile'),
                t('Abonnement'),
                t('Date expiration'),
                '',
                '',
              ] :
              [
                'N°',
                t('Abonnement'),
                t('Date expiration'),
                '',
                '',
              ]
            }
            footer
          >
            <Table.Body>
              {subscribers.map((it, i) => {
                return (
                  <Table.Row key={`client-${i}`}>
                    <Table.Cell className="td-text">#{i + this.props.from}</Table.Cell>
                    {this.props.showClient &&
                    <Table.Cell className="td-text">
                      <span className={'td-img'}>
                        <Image
                          className='avatar-cover'
                          src={it.user.avatar ? imgUrl + '/users/' + it.user.avatar : splash}
                          circular
                        />{' '}
                      </span>
                    </Table.Cell>
                    }
                    {this.props.showClient &&
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.user.lastName)}
                    </Table.Cell>
                    }
                    {this.props.showClient &&
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.user.firstName)}
                    </Table.Cell>}
                    {this.props.showClient &&
                    <Table.Cell className="td-text">{nullStringForTable(it.user.email)}</Table.Cell>
                    }
                    {this.props.showClient &&
                    <Table.Cell className="td-text">
                      {nullStringForTable(phoneNumber(it.user.mobile))}
                    </Table.Cell>}
                    <Table.Cell className="td-text">
                      {it.subscription && it.subscription.subscription_type ? nullStringForTable(it.subscription.subscription_type.name) : nullStringForTable(null)}
                    </Table.Cell>
                    <Table.Cell className="td-text">
                      {nullStringForTable(it.expiry_date)}
                    </Table.Cell>

                    <Table.Cell width={1}>
                      <span
                        className={'round-credit'}
                        style={{
                          backgroundColor: getCreditStatus(
                            it.subscription,
                            it.solde,
                            it.expiry_date,
                          ),
                        }}
                      />
                    </Table.Cell>

                    <Table.Cell className="td-text tools">
                      <Dropdown
                        direction={'left'}
                        className="icon"
                        icon={null}
                        floating
                        trigger={<Icon name={'ellipsis vertical'}/>}
                      >
                        <Dropdown.Menu>
                          {it.subscription && (Object.keys(it.deadlines).length !== 0 || it.subscription.subscription_type.is_reload === 1) ?
                            <Dropdown.Item
                              icon={it.subscription.subscription_type.is_reload === 1 ? 'refresh' : 'payment'}
                              text={it.subscription.subscription_type.is_reload === 1 ? t('reload') : t('pay')}
                              onClick={() => this.handleOpen(it)}
                            /> : ''}
                          <Dropdown.Item
                            icon="folder"
                            text={t('folder')}
                            onClick={() => history.push('/subscriber/' + it.id)}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </CustomTable>
        </div>
        {this.renderModal()}
        {this.state.modalCheckoutOpen && this.renderNoCheckoutModal()}
        {this.renderDeadlinesModal()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  index: (page, size, search) => dispatch(Creators.indexRequestSubscriber({ page, size, search })),
  reload: (data) => dispatch(Creators.indexRequestSubscriberDetail({ ...data })),
  updateDeadline: (data) => dispatch(Creators.updateRequestSubscriberDeadline(data)),
  destroy: (id) => dispatch(Creators.destoryRequestUser(id)),
  caisseRedirection: (value, status = null, type = null, date = null) =>
    dispatch({ type: 'CAISSE_REDIRECTION_REQUEST', payload: { value, status, type, date } }),
});

SubscriptionTable.defaultProps = {
  showClient: true,
};

const mapStateToProps = (state) => {
  return {
    subscribers: state.subscriber.subscribers,
    deadline: state.subscriber.deadline,
    session: state.session.session,
    page: state.subscriber.page,
    last: state.subscriber.last,
    total: state.subscriber.total,
    size: state.subscriber.size,
    from: state.subscriber.from,
    loading: state.subscriber.loading,
    loadingDetail: state.subscriber.loadingDetail,
    updated: state.subscriber.updated,
    updatedDeadline: state.subscriber.updatedDeadline,
    deleteLoading: state.subscriber.deleteLoading,
    successDestroy: state.subscriber.deleteSuccess,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionTable);
