import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import articleService from '../../services/articleService';
import history from '@/helpers/history';
import { errorToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';

// Action types and creators
export const { Types, Creators } = createActions({
  loadingArticle: ['data'],

  indexRequestArticle: ['payload'],
  indexResponseArticle: ['data'],

  storeRequestArticle: ['payload'],
  storeResponseArticle: ['data'],

  editRequestArticle: ['id'],
  editResponseArticle: ['data'],

  updateRequestArticle: ['payload'],
  updateResponseArticle: ['data'],

  destroyRequestArticle: ['id'],
  destroyResponseArticle: ['data'],

});

// Initial state
const INITIAL_STATE = {
  articles: [],
  article: null,
  loading: false,
  errors: null,
  last: 0,
  page: 0,
  size: 10,
  total: 0,
  from: 1,
  editLoading: false,
  successStore: false,
  successUpdate: false,
  successDestroy: false,
};

//index
function* index(action) {
  try {
    yield put(Creators.loadingArticle({ loading: true }));
    const data = yield call(articleService.articleService.index, action.payload);
    yield put(Creators.indexResponseArticle(data));
    yield put(Creators.loadingArticle({ loading: false }));
  } catch (e) {
    yield put(Creators.loadingArticle({ loading: false }));
  }
}

//store
function* store(action) {
  try {
    yield put(Creators.loadingArticle({ loading: true }));
    const data = yield call(articleService.articleService.store, action.payload);
    yield put(Creators.storeResponseArticle(data));
    yield put(Creators.loadingArticle({ loading: false }));
    yield history.push('/articles');
    validToast(i18n.t('successful_store'));
  } catch (e) {
    yield put(Creators.loadingArticle({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_store'));
  }

}

//edit
function* edit(action) {
  try {
    yield put(Creators.loadingArticle({ editLoading: true }));
    const data = yield call(articleService.articleService.edit, action.payload);
    yield put(Creators.editResponseArticle(data));
    yield put(Creators.loadingArticle({ editLoading: false }));
  } catch (e) {
    yield put(Creators.loadingArticle({ editLoading: false }));
    history.push('/error-404');
  }

}

//update
function* update(action) {
  try {
    yield put(Creators.loadingArticle({ loading: true }));
    const data = yield call(articleService.articleService.update, action.payload);
    yield put(Creators.updateResponseArticle(data));
    yield put(Creators.loadingArticle({ loading: false }));
    yield history.push('/articles');
    validToast(i18n.t('successful_update'));
  } catch (e) {
    yield put(Creators.loadingArticle({ loading: false, errors: e.response.data.errors }));
    errorToast(i18n.t('failed_update'));
  }
}

//destroy
function* destroy(action) {
  try {
    yield put(Creators.loadingArticle({ deleteLoading: true, successDestroy: false }));
    const data = yield call(articleService.articleService.destroy, action.payload);
    yield put(Creators.destroyResponseArticle(data));
    yield put(Creators.loadingArticle({ deleteLoading: false, successDestroy: true }));
    validToast(i18n.t('successful_delete'));
  } catch (e) {
    yield put(Creators.loadingArticle({ deleteLoading: false }));
    errorToast(i18n.t('failed_delete'));
  }
}

export default function* mySaga() {
  yield takeLatest(Types.INDEX_REQUEST_ARTICLE, index);
  yield takeLatest(Types.STORE_REQUEST_ARTICLE, store);
  yield takeLatest(Types.EDIT_REQUEST_ARTICLE, edit);
  yield takeLatest(Types.UPDATE_REQUEST_ARTICLE, update);
  yield takeLatest(Types.DESTROY_REQUEST_ARTICLE, destroy);
}

// Reducer handlers
//index
const index_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    articles: [...action.data.data],
    last: action.data.last_page,
    page: action.data.current_page,
    total: action.data.total,
    size: action.data.per_page,
    from: action.data.from,
  };
};

//store
const store_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successStore: true,
  };
};

//edit
const edit_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    article: action.data.data,
  };
};

//update
const update_response = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successUpdate: true,
  };
};

//destroy
const destroy_request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    successDestroy: false,
  };
};

const loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};
// Reducer
export const articleReducer = createReducer(INITIAL_STATE, {
  [Types.INDEX_RESPONSE_ARTICLE]: index_response,
  [Types.STORE_RESPONSE_ARTICLE]: store_response,
  [Types.EDIT_RESPONSE_ARTICLE]: edit_response,
  [Types.UPDATE_RESPONSE_ARTICLE]: update_response,
  [Types.DESTROY_REQUEST_ARTICLE]: destroy_request,
  [Types.LOADING_ARTICLE]: loading,
});

