import React, { useEffect, useRef, useState } from 'react';
import packageJson from '../package.json';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import i18n from './i18n';

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  return momLatestDateTime.isAfter(momCurrentDateTime);
};
// 5 minutes
const timer = 60000 * 5;

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(true);
    const intervalRef = useRef(null);
    useEffect(() => {
      if (!window.location.href.includes('localhost')) {
        intervalRef.current = setInterval(() => {
          fetch('/meta.json', { cache: 'no-cache' })
            .then((response) => response.json())
            .then((meta) => {
              const latestVersionDate = meta.buildDate;
              const currentVersionDate = packageJson.buildDate;

              const shouldForceRefresh = buildDateGreaterThan(
                latestVersionDate,
                currentVersionDate,
              );

              if (shouldForceRefresh) {
                // setIsLatestBuildDate(false);
                refreshCacheAndReload();
              } else {
                // setIsLatestBuildDate(true);
              }

            });
        }, timer);
      }
      return () => {
        if (intervalRef.current)
          clearInterval(intervalRef.current);
      };
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    };

    return (
      <React.Fragment>
        <Component {...props} />
        {!isLatestBuildDate &&
        <div className={'version-update'}>
          <span>{i18n.t('update_version')}</span>
          <Button
            size={'tiny'}
            primary
            onClick={() => {
              refreshCacheAndReload();
            }}
          >{i18n.t('update')}</Button>
        </div>
        }
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;