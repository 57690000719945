import React from 'react';
import { Button, Grid, Modal } from 'semantic-ui-react';
import Calculator from '../CustomCaisse/Content/Payment/Calculator';

/**
 * Modal confirmation is used in Caisse for confirmation or showing  information
 *
 * */
const ModalOpenCloseSession = (props) => {
  return (
    <Modal open={props.open} onClose={props.onClose}
           size="tiny"
           closeIcon
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content className="modal-content">
        <Grid>
          {props.isSold && <Grid.Column width={8}>
            <div className={'sold'}>
              Solde théorique {props.soldTh}{localStorage.getItem('currency')}
            </div>
          </Grid.Column>}
          <Grid.Column width={8}>
            <div className={'sold'}>
              Solde saisi {props.soldS}{localStorage.getItem('currency')}
            </div>
          </Grid.Column>
        </Grid>
        <div style={{ margin: '20px 30px' }}><Calculator onChange={props.onChangeCalculator}/></div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={props.loading}
          loading={props.loading}
          content="Valider la clôture"
          labelPosition='right'
          icon='checkmark'
          onClick={props.conclude}
          negative={props.isSold}
          positive={!props.isSold}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalOpenCloseSession.defaultProps = {
  title: 'Clôturer la session de caisse',
  isSold: false,
};

export default ModalOpenCloseSession;
