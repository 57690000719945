import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button ,Image } from 'semantic-ui-react';
import { invoicePrint, PrintPopup } from '../../../helpers/print';
import { imgUrl } from '@/helpers';
import { formatCodeCountry, nullString, phoneNumber } from '../../../helpers/formatting';

class InvoiceListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    let color = 'rgba(11, 108, 255, 0.65)';
    if (this.props.invoice && this.props.invoice.ticket)
      return (
        <div className={'invoices-list-item'} style={{ color: color }}>
          <div className="ticket-family" style={{ backgroundColor: color }}>
          </div>
          <div className="ticket-information" style={{ borderRight: '1px dotted ' + color }}>
              <b>N° {this.props.invoice.id}</b>
          </div>
          <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
              <b>Ticket </b>
              <span> N° {this.props.invoice.ticket.number}</span>
          </div>
          {this.props.invoice.user && (
            <div className="ticket-sub-family" style={{ borderRight: '1px dotted ' + color }}>
              <Image className="ticket-avatar avatar-cover" style={{margin : '10px'}}
                src={this.props.invoice.user.avatar ? 
                imgUrl + '/users/' + this.props.invoice.user.avatar : 
                require('../../../assets/avatar.svg')} avatar/>

              {
                <span>
                  {this.props.invoice.user_id && this.props.invoice.user && (
                    <>
                      {this.props.invoice.user.firstName + ' ' + nullString(this.props.invoice.user.lastName).toUpperCase()}
                      <br/>
                      {formatCodeCountry(this.props.invoice.user.country_code)  + phoneNumber(this.props.invoice.user.mobile)}
                    </>
                  )}
                </span>
              }
            </div>
          )}
          
          <div className="ticket-amount" style={{ borderRight: '1px dotted ' + color }}>
            <b>Montant</b>
            <span>
              {parseFloat(this.props.invoice.ticket.amount).toFixed(2)}{localStorage.getItem("currency")}
            </span>
          </div>
          <div className="ticket-buttons">
            <Button className="ticket-btn-edit"
              onClick={async () => {
                let invoice = this.props.invoice;
                let receipt = await invoicePrint(invoice);
                await PrintPopup(receipt);
              }}
            >
              Imprimer
            </Button>
          </div>
        </div>
        
      );
    else return null;
  }
}


export default withRouter(InvoiceListItem);
