import React from 'react';

export class PreventUpdate extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !(nextProps.isMobile !== this.props.isMobile || nextProps.width !== this.props.width);
  }

  render() {
    return <>{this.props.children}</>;
  }
}
