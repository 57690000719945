import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import authService from '@/services/authService';
import history from '../../helpers/history';
import { errorToast, infoToast, validToast } from '../../helpers/customToast';
import i18n from '../../i18n/index';
import { getComplexTime } from '../../helpers/complexFormatting';
import { Creators as CreatorsNotification } from './notification';

// Action types and creators
export const { Types, Creators } = createActions({
  changePasswordRequest: ['payload'],
  sendRecoverRequest: ['payload'],
  refreshRequest: ['payload'],
  refreshSuccess: ['data'],
  loginRequest: ['payload'],
  loginSuccess: ['data'],
  loginFailed: [],
  logoutRequest: null,
  logoutSuccess: [],
  meRequest: [],
  meSuccess: ['data'],
  responseSuccess: ['payload'],
  meFailed: [],

  changeComplexRequest: ['payload'],
  changeComplexResponse: ['payload'],
});

// Initial state
const INITIAL_STATE = {
  loading: false,
  resetFinish: false,
  recoverLoading: false,
  resetLoading: false,
  updateLoading: false,
  token: null,
  error: false,
  meLoading: true,
  success: null,
  recover: null,
  logoutStatus: false,
  meError: false,
  reset: null,
  user: {
    id: '',
    firstName: '',
    lastName: '',
  },
  roles: null,
  complexes: null,
  modules: [
    {
      slug: 'unauthorized',
      permission: [
        {
          slug: 'login-access',
        },
      ],
    },
    {
      slug: 'extra',
      permission: [
        {
          slug: 'extra-access',
        },
      ],
    },
  ],
  selected: {
    modules: [
      {
        slug: 'unauthorized',
        permissions: [
          {
            slug: 'login-access',
          },
        ],
      },
      {
        slug: 'extra',
        permission: [
          {
            slug: 'extra-access',
          },
        ],
      },
    ],
  },
  all_modules: [],
};

function* send(action) {
  try {
    yield put(Creators.responseSuccess({ recover: null, recoverLoading: true }));
    const data = yield call(authService.authService.recover, action.payload);
    yield put(Creators.responseSuccess({ recover: data.data, recoverLoading: false }));
    if (data.data.status === 'success') {
      validToast(data.data.message);
    } else {
      errorToast(data.data.message);
    }
  } catch (e) {
    if (e.response.data?.status === 'error') {
      errorToast(e.response.data.message);
    }
    yield put(Creators.responseSuccess({ recover: e.response.data, recoverLoading: false }));
  }
}

function* change(action) {
  try {
    yield put(Creators.responseSuccess({ reset: null, resetLoading: true, resetFinish: false }));
    const data = yield call(authService.authService.change, action.payload);
    yield put(Creators.responseSuccess({ reset: data.data, resetLoading: false }));
    yield put(Creators.responseSuccess({ reset: data.data, resetLoading: false }));
    if (data.data.status === 'success') {
      validToast(data.data.message);
      yield put(Creators.responseSuccess({ resetFinish: true }));
    } else {
      if (data.data.hasOwnProperty('message'))
        errorToast(data.data.message);
    }
  } catch (e) {
    if (e.response.data?.status === 'error') {
      errorToast(e.response.data.message);
    }
    yield put(Creators.responseSuccess({ reset: e.response.data, resetLoading: false }));
  }
}


function* login(action) {
  try {
    const data = yield call(authService.authService.login, action.payload);
    if (data.data.hasOwnProperty('access_token')) {
      localStorage.setItem('token', data.data.access_token);
      localStorage.setItem('expirationDate', data.data.token_payload.exp);
      localStorage.setItem('complex_id', data.data.user.default_complex[0].id);
      localStorage.setItem('complex_time', JSON.stringify(getComplexTime(data.data.user.default_complex[0].complex_time)));

      localStorage.setItem(
        'currency',
        data.data.user.default_complex[0].currency
          ? data.data.user.default_complex[0].currency.symbol
          : '€',
      );
      yield put(Creators.loginSuccess(data));
      history.push('/');
      validToast(i18n.t('welcome_back'));
    } else {
      yield put(Creators.loginFailed());
    }

  } catch (e) {
    // check 500 error :token expired
    if (e.response && e.response.status === 500) {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationDate');
    }

    yield put(Creators.loginFailed());
  }
}


function* refresh(action) {
  try {
    let data = action.payload;
    if (data.hasOwnProperty('access_token')) {
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('expirationDate', data.token_payload.exp);
      yield put(Creators.refreshSuccess(data));
      validToast(i18n.t('refresh_token'));
    } else {
      yield put(Creators.loginFailed());
    }

  } catch (e) {
    // check 500 error :token expired
    if (e.response && e.response.status === 500) {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationDate');
    }
    yield put(Creators.loginFailed());
  }
}

function* me(action) {
  try {
    const data = yield call(authService.authService.me);
    let complex_id = localStorage.getItem('complex_id');
    if (!localStorage.getItem('complex_id')) {
      localStorage.setItem('complex_id', data.data.user.default_complex[0].id);
      localStorage.setItem(
        'currency',
        data.data.user.default_complex[0].currency
          ? data.data.user.default_complex[0].currency.symbol
          : '€',
      );
    }
    localStorage.setItem('complex_time', JSON.stringify(getComplexTime(data.data.user.default_complex[0].complex_time)));
    localStorage.setItem('expirationDate', data.data.token_payload.exp);

    yield put(Creators.meSuccess(data));
  } catch (e) {
    // check 500 error :token expired
    if (e.response && e.response.status === 500) {
      //localStorage.removeItem('token');
    }
    yield put(Creators.meFailed());
  }
}

function* changeComplex(action) {
  try {
    let id = action.payload;
    localStorage.removeItem('session_id');
    yield put(Creators.changeComplexResponse(id));
    yield put(CreatorsNotification.resetNotificationRequest());
  } catch (e) {
  } 
}

function* logout(action) {
  try {
    yield call(authService.authService.logout);
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    // history.push("/");
    yield put(Creators.logoutSuccess());
    yield put(CreatorsNotification.resetNotificationRequest());
    infoToast('logout_success');
    history.push('/login');
  } catch (e) {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    yield put(Creators.logoutSuccess());
    yield put(CreatorsNotification.resetNotificationRequest());
    infoToast('logout_success');
    history.push('/login');
  }
}

export default function* mySaga() {
  yield takeLatest(Types.REFRESH_REQUEST, refresh);
  yield takeLatest(Types.LOGIN_REQUEST, login);
  yield takeLatest(Types.LOGOUT_REQUEST, logout);
  yield takeLatest(Types.ME_REQUEST, me);
  yield takeLatest(Types.CHANGE_COMPLEX_REQUEST, changeComplex);
  //sendRecoverRequest
  yield takeLatest(Types.SEND_RECOVER_REQUEST, send);
  //  [Types.CHANGE_PASSWORD_REQUEST]: change,
  yield takeLatest(Types.CHANGE_PASSWORD_REQUEST, change);
}

// Reducer handlers
const request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

const response = (state = INITIAL_STATE, action) => {
  let user = { ...action.data.data.user };
  let roles = action.data.data.user.roles;
  let modules = action.data.data.user.modules;
  let all_modules = action.data.data.user.all_modules;
  delete user.default_complex;
  delete user.complexes;
  delete user.modules;
  delete user.all_modules;
  delete user.roles;

  let selected = { ...action.data.data.user.default_complex[0] };
  selected.modules.push({
    slug: 'dashboard',
    permissions: [{ slug: 'dashboard-access' }],
  });

  selected.modules.push({
    slug: 'extra',
    permissions: [{ slug: 'extra-access' }],
  });

  return {
    ...state,
    token: action.data.data.access_token,
    expirationDate: action.data.data.token_payload.exp,
    user,
    selected,
    complexes: action.data.data.user.complexes,
    modules: modules,
    all_modules,
    roles,
    success: true,
    loading: false,
    error: false,
  };
};

const refreshResponse = (state = INITIAL_STATE, action) => {

  return {
    ...state,
    token: action.data.access_token,
    expirationDate: action.data.token_payload.exp,
  };
};

const response_handler = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const logout_request = (state = INITIAL_STATE, action) => {
  return {
    ...INITIAL_STATE,
    meLoading: false,
  };
};
const login_failed = (state = INITIAL_STATE, action) => {
  return {
    ...INITIAL_STATE,
    meLoading: false,
    error: true,
  };
};

const meRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    meError: false,
    meLoading: true,
  };
};

const meSuccess = (state = INITIAL_STATE, action) => {
  let user = { ...action.data.data.user };
  let modules = action.data.data.user.modules;
  let roles = action.data.data.user.roles;
  let all_modules = action.data.data.user.all_modules;

  delete user.default_complex;
  delete user.complexes;
  delete user.modules;
  delete user.all_modules;
  delete user.roles;

  let selected = null;
  let complexes = [...action.data.data.user.complexes];
  let complexId = localStorage.getItem('complex_id');

  for (let i = 0; i < complexes.length; i++) {
    if (complexes[i].id.toString() === complexId.toString()) {
      selected = { ...complexes[i] };
      break;
    }
  }

  if (!selected) {
    selected = { ...action.data.data.user.default_complex[0] };
    localStorage.setItem('complex_id', selected.id);
    localStorage.setItem('complex_time', JSON.stringify(getComplexTime(selected.complex_time)));
    localStorage.setItem('currency', selected.currency ? selected.currency.symbol : '€');
  } else {
    localStorage.setItem('complex_time', JSON.stringify(getComplexTime(selected.complex_time)));
  }

  selected.modules.push({
    slug: 'dashboard',
    permissions: [{ slug: 'dashboard-access' }],
  });
  selected.modules.push({
    slug: 'extra',
    permissions: [{ slug: 'extra-access' }],
  });

  return {
    ...state,
    token: action.data.data.access_token,
    expirationDate: action.data.data.token_payload.exp,
    user,
    selected,
    complexes: [...action.data.data.user.complexes],
    modules,
    roles,
    all_modules,
    success: true,
    meLoading: false,
    error: false,
  };
};

const meFailed = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    meLoading: false,
    meError: true,
  };
};

const changeSelectedComplex = (state = INITIAL_STATE, action) => {
  let filteredComplex = state.complexes.filter((it) => it.id === action.payload);

  if (filteredComplex.length > 0) {
    localStorage.setItem('complex_id', filteredComplex[0].id);
    localStorage.setItem('complex_time', JSON.stringify(getComplexTime(filteredComplex[0].complex_time)));
    localStorage.setItem(
      'currency',
      filteredComplex[0].currency ? filteredComplex[0].currency.symbol : '€',
    );
    let selected = { ...filteredComplex[0] };
    selected.modules.push({
      slug: 'dashboard',
      permissions: [{ slug: 'dashboard-access' }],
    });
    selected.modules.push({
      slug: 'extra',
      permissions: [{ slug: 'extra-access' }],
    });

    return {
      ...state,
      selected,
    };
  }

  return state;
};

// Reducer
export const authReducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: response,
  [Types.REFRESH_SUCCESS]: refreshResponse,
  [Types.LOGIN_FAILED]: login_failed,
  [Types.LOGOUT_SUCCESS]: logout_request,
  [Types.ME_REQUEST]: meRequest,
  [Types.ME_SUCCESS]: meSuccess,
  [Types.RESPONSE_SUCCESS]: response_handler,
  [Types.ME_FAILED]: meFailed,

  [Types.CHANGE_COMPLEX_RESPONSE]: changeSelectedComplex,
});
