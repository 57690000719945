import React, { useEffect } from 'react';
import useTitleNotification from '../../hooks/useTitleNotification';

/**
 * this is a standard layout doesnt contain anything
 * best suitable for login , register , caisse
 *
 * <FullLayout>
 *     <YourComponent/>
 * </FullLayout>
 *
 * */


const FullLayout = React.memo((props) => {

  const notification = useTitleNotification();

  return (
    <div className={props.className}>{props.children}</div>

  );
});

FullLayout.defaultProps = {
  className: 'container',
};


export default FullLayout;
