import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

const CustomLoader = () => {
  return (
    <div className={'custom-loader-container'}
      // style={{background:"url("+para+")"}}
    >
      <Segment basic>
        <Dimmer active inverted>
          <Loader active inverted/>
        </Dimmer>
      </Segment>
    </div>

  );
};

export default CustomLoader;
