import { deleteMethod, getMethod, postMethod, putMethod } from '@/helpers/index';

export default {
  vatService: {
    index: async ({ page, size, search }) => {
      const result = await getMethod(
        '/vats?page=' +
        page +
        (size ? '&size=' + size :'') +
        (search && search.search_name ? '&search_name=' + search.search_name : '') +
        '&complex_id=' +
        localStorage.getItem('complex_id'),
      );
      return result.data;
    },
    store: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await postMethod('/vats', newData);
      return result.data;
    },
    edit: async (id) => {
      const result = await getMethod('/vats/' + id);
      return result.data;
    },
    update: async (data) => {
      let newData = { ...data, complex_id: localStorage.getItem('complex_id') };
      const result = await putMethod('/vats/' + data.id, newData);
      return result.data;
    },
    destroy: async (id) => {
      const result = await deleteMethod('/vats/' + id);
      return result.data;
    },
  },
};
