import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Image extends Component {
  constructor(props) {
    super(props);

    let src = props.src.split('/');
    if (src[src.length - 1] === 'null')
      src =
        props.type === 'user'
          ? require('../../assets/placeholder/user.jpg')
          : require('../../assets/placeholder/item.jpg');
    else src = props.src;

    this.state = {
      src,
      errored: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.src !== this.props.src) this.setState({ src: this.props.src });
  }

  onError = (e) => {
    if (!this.state.errored) {
      this.setState({
        src:
          this.props.type === 'user'
            ? require('../../assets/placeholder/user.jpg')
            : require('../../assets/placeholder/item.jpg'),
        errored: true,
      });
    }
  };

  render() {
    const { src } = this.state;
    const { src: _1, fallbackSrc: _2, style, className, circular, onClick } = this.props;
    let nStyle = { ...style };
    if (circular) nStyle = { ...nStyle, borderRadius: '50%' };

    return <img draggable={false} alt={''} src={src} onClick={onClick}
                onError={this.onError} {...{ className }} style={{ ...nStyle }}/>;
  }
}

export default Image;

Image.propTypes = {
  src: PropTypes.string,
  type: PropTypes.string,
};

Image.defaultProps = {
  type: 'user',
  circular: false,
  style: {},
  onClick: () => {
  },

};
