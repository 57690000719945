export const levels = [
  { id: 0, name: 'Tous' },
  { id: 1, name: 'Débutant' },
  { id: 2, name: 'Amateur' },
  { id: 3, name: 'Pro' },
];

export const humans = [
  { id: 0, name: 'Femme' },
  { id: 1, name: 'Homme' },
  { id: 2, name: 'Tous' },
];

export const privates = [
  { id: 0, label: 'Privé', title: 'Cercle privé ', show: 'Partie privée' },
  { id: 1, label: 'Amis', title: 'Amis et leurs amis', show: 'Partie avec amis' },
  { id: 2, label: 'Publique', title: 'ouvert à tous', show: 'Partie publique' },
];

export const sizes = [
  { label: '4', title: 'sans rebond' },
  { label: '5', title: 'léger rebond' },
];